import React, { Component } from 'react';
import { Configuration, MandatoryFormFields, getDataTable, updateTemplateFile } from '../Configuration';
import { Modal, Spin } from 'antd';
import '../ORDER/OrderMaster/style.css'
import "antd/dist/antd.css";
import AutoCompleteInput from '../Actions/Controls/AutoComplete/autoComplete';
import AutoCompleteCascad from '../Actions/Controls/AutoComplete/autoComplete.cascad';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import TFilter from './../IMAGE/MODAL/frmTemplateFilter'

const dataBase = "IMAGEDB";
let files = ''
var tb = ''
export default class frmUpdateAttributeAliasing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginId: 0,
            visible: false,
            file: null,
            loading: false,
            aData: [],
            tptype: '0',
            options: [],
            stsdup: 0,
            btType: 'B2C',
            fldt: [],
            alwCustomSheetval: 0,
            IsFreez : 0
        };
    }
    async componentDidMount() {
        let rs = await getDataTable("select orgid from users where userid = " + Configuration.USER_ID)
        await this.setState({
            loginId: Configuration.USER_ID,
            orgid: rs[0].orgid
        });
    }
    myChangeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }
    onAfterSelect(selectedOptions, id, name, index = 0) {
        if (selectedOptions.length > 0) {
            this.setState({
                [id]: selectedOptions[0].DisplayId,
                [name]: selectedOptions[0].DisplayName
            })
        }
        else {
            this.setState({
                [id]: '',
                [name]: ''
            })
        }
        if (id === 'ValueID') {
            this.changeAlias(index, selectedOptions)
        }
    }
    async UploadFile() {
        if (this.state.alwCustomSheetval === 1) {
            await this.updateTemplatewithfile()
            return;
        }
        this.setState({
            loading: true,
            aData: []
        })
        let MandatoryArray = [
            { "portal": this.state.portalid },
            { "BLOCK": this.state.catid1 },
            { "TEMPLATE": this.state.tempId }
        ]
        let check = MandatoryFormFields(MandatoryArray);
        if (check == false) {
            this.setState({ loading: false })
            return false;
        }
        var formdata = new FormData();
        formdata.append("file", files);
        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        let que0 = " SELECT   TemplateID, Is_freez  FROM TemplateMaster WHERE TemplateID = "+ this.state.tempId 
        let dt0 = await getDataTable(que0)
        this.setState({
            IsFreez : dt0[0]['Is_freez']
        })
        
        let que = "SELECT TemplateDetails.Priority,TemplateDetails.HeaderName AS [key], TemplateDetails.mandatory,ISNULL(AttributeMaster.AID,0) AS AttributeID, AttributeMaster.AttributeName AS AttributeName FROM TemplateDetails LEFT OUTER JOIN AttributeMaster ON TemplateDetails.AttributeID = AttributeMaster.AID WHERE (TemplateDetails.templateID = " + this.state.tempId + ") order by TemplateDetails.Priority"
        let dt = await getDataTable(que)
        await this.GetdataAliasing(dt)

        let que1 = "SELECT AttributeName, Value, AttributeID AS #AID, ValueID AS #AVID, Condition FROM TemplateFilterData INNER JOIN AttributeMaster ON TemplateFilterData.AttributeID = AttributeMaster.AID WHERE TemplateID = " + this.state.tempId
        this.setState({ fldt: await getDataTable(que1) })
    }

    async updateTemplatewithfile() {
        this.setState({
            loading: true,
            aData: []
        })
        let MandatoryArray = [
            { "Files": files },
            { "portal": this.state.portalid },
            { "BLOCK": this.state.catid1 },
            { "TEMPLATE": this.state.tempId },
            { "sheet no": this.state.sindex },
            { "row no": this.state.rindex }
        ]

        let que0 = " SELECT   TemplateID, Is_freez  FROM TemplateMaster WHERE TemplateID = "+ this.state.tempId 
        let dt0 = await getDataTable(que0)
        this.setState({
            IsFreez : dt0[0]['Is_freez']
        })
        let check = MandatoryFormFields(MandatoryArray);
        if (check === false) {
            this.setState({ loading: false })
            return false;
        }
        var formdata = new FormData();
        formdata.append("file", files);
        formdata.append("sindex", this.state.sindex)
        formdata.append("rindex", this.state.rindex)
        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };
        let rs = await fetch("https://api.blumangoes.ai:6143/GetSheetAttribute", requestOptions)
        let dt = await rs.json()
        await this.getAlreadyAliasedData(dt.data)
        let que1 = "SELECT AttributeName, Value, AttributeID AS #AID, ValueID AS #AVID, Condition FROM TemplateFilterData INNER JOIN AttributeMaster ON TemplateFilterData.AttributeID = AttributeMaster.AID WHERE TemplateID = " + this.state.tempId
        this.setState({ fldt: await getDataTable(que1) })
    }


    async getAlreadyAliasedData(dt) {

        for (let i = 0; i < dt.length; i++) {
            let Que = "select AttributeID,AttributeName,mandatory from AttributeMaster inner join TemplateDetails on TemplateDetails.AttributeID = aid "
            Que += " where HeaderName = '" + dt[i].key + "' and templateID = " + this.state.tempId
            Que += " order by tempDetailID desc"
            // Que += "select AID,AttributeName from AttributAlias inner join AttributeMaster on AttributeMaster.AID = AttributAlias.AttributeID where PortalID = " + this.state.portalid + " and aliasName = '" + dt[i].key + "' and dis = 0"
            let rs = await getDataTable(Que)
            if (rs.length > 0) {
                dt[i].AttributeID = rs[0].AttributeID
                dt[i].AttributeName = rs[0].AttributeName
                dt[i].mandatory = rs[0].mandatory
                dt[i].Priority = i + 1
            }
            else {
                let Que0 = "SELECT AID, AttributeName FROM AttributeMaster inner join AttributeCategoryMap on AttributeCategoryMap.AttributeID = "
                Que0 += "AttributeMaster.AID where dis = 0 and AttributeName='" + dt[i].key + "' and CatID = " + this.state.catid1
                let rs0 = await getDataTable(Que0)
                if (rs0.length > 0) {
                    dt[i].AttributeID = rs0[0].AID
                    dt[i].AttributeName = rs0[0].AttributeName
                    dt[i].mandatory = 0
                    dt[i].Priority = i + 1
                }
                else {
                    dt[i].AttributeID = 0
                    dt[i].AttributeName = ''
                    dt[i].mandatory = 0
                    dt[i].Priority = i + 1
                }
            }
        }

        await this.GetdataAliasing(dt)

    }

    async GetdataAliasing(dt) {

        let Que1 = "SELECT TamplateName, Discription, CellAddress, TemplateType FROM TemplateMaster WHERE TemplateID = " + this.state.tempId
        let rs1 = await getDataTable(Que1)
        this.setState({
            tempName: rs1[0].TamplateName,
            cellAddress: rs1[0].CellAddress,
            tempDiscrption: rs1[0].Discription
        })
        if (rs1[0].TemplateType) {
            this.setState({ btType: rs1[0].TemplateType })
        }

        var mySelect = document.getElementById('mySelect');

        for (var i, j = 0; i = mySelect.options[j]; j++) {
            if (i.value == this.state.btType) {
                mySelect.selectedIndex = j;
                break;
            }
        }
        this.setState({
            loading: false,
            aData: dt
        })
    }

    async CreateAttribute(i) {
        let pData = this.state.aData[i]
        this.setState({ Aname: pData['key'] })
        Modal.info({
            title: 'CREATE ATTRIBUTE',
            okText: 'CLOSE',
            width: '700px',
            closable: true,
            footer: null,
            bodyStyle: { maxHeight: '300px' },
            style: { padding: '0px' },
            centered: true,
            maskClosable: false,
            content: (
                <div className='row'>
                    <div>
                        <div className="col-xs-6 col-sm-6 col-md-4 margintop">
                            <label>Add New Attribute</label><span className="vcode">*</span>
                            <div className="form-group">
                                <input name="Aname" id="Aname" value={pData['key']} defaultValue={pData['key']} onChange={this.myChangeHandler} autocomplete="off" placeholder="Enter FieldName" tabIndex={1} className="form-control" />
                            </div>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-md-4 margintop">
                            <label>Value Type</label><span className="vcode">*</span>
                            <div className="form-group">
                                <select name="val" onChange={this.myChangeHandler} id="val" tabIndex={1} className="form-control">
                                    <option selected="true" disabled="disabled">Value Type</option>
                                    <option value="number">Numeric</option>
                                    <option value="Alpha-numeric">Alpha Numeric</option>
                                    <option value="date">Date</option>
                                    <option value="option">Option</option>
                                    <option value="yes/no">yes/no</option>
                                    <option value="NULL">No Value</option>
                                    <option value="url">URL</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                            <label>&nbsp;</label>
                            <div className="form-group">
                                <input type='button' value='Save Field' onClick={this.SaveAttributeWithMap.bind(this)} className='btn btn-block btn-primary' />
                            </div>
                        </div>
                    </div>
                </div>
            )
        });
    }

    async SaveAttributeWithMap() {
        this.setState({ loading: true })
        let MandatoryArray = [
            { "Value Type": this.state.val }
        ]
        let check = MandatoryFormFields(MandatoryArray);
        if (check == false) {
            this.setState({ loading: false })
            return false;
        }
        let Que = "Select * from AttributeMaster where AttributeName = '" + this.state.Aname + "' and dis = 0"
        let rs = await getDataTable(Que)
        if (rs.length > 0) {
            Swal.fire({
                position: 'top-end',
                icon: 'info',
                title: 'Already Exist',
                showConfirmButton: false,
                timer: 1500
            })
            this.setState({ loading: false })
            return false;
        }
        let Query = "INSERT INTO  AttributeMaster  (AttributeName, LoginID, Logdate,Dis,Validation) Output Inserted.AID VALUES ('" + this.state.Aname + "'," + this.state.loginId + ",cast(getdate() as date),0,'" + this.state.val + "')"
        let res = await getDataTable(Query)
        if (res[0].AID) {
            let Query1 = "INSERT INTO AttributeCategoryMap (CatID, AttributeID, loginID, logdate) VALUES (" + this.state.catid1 + "," + res[0].AID + "," + this.state.loginId + ",cast(getdate() as date))"
            let res1 = await getDataTable(Query1)
            this.setState({ loading: false })
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Successfully Saved',
                showConfirmButton: false,
                timer: 1500
            })
        }
        else {
            this.setState({ loading: false })
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Some Issue Occured',
                showConfirmButton: false,
                timer: 1500
            })
        }
    }

    // hide show div
    hideShowDiv(id, childId) {
        let div = document.getElementById(id);
        if (div !== null) {
            if (document.getElementById(id).style.display == "block") {
                document.getElementById(id).style.display = "none"
                let ele = document.getElementById(childId);
                ele.className = "fa fa-plus";
            }
            else {
                document.getElementById(id).style.display = "block"
                let ele = document.getElementById(childId);
                ele.className = "fa fa-minus";
            }
        }
    };

    myFileChangeHandler = (event) => {
        files = event.target.files[0];
    }

    async alwCustomSheet(e) {
        if (e.target.checked) {
            this.setState({ alwCustomSheetval: 1 })
        }
        else {
            this.setState({ alwCustomSheetval: 0 })
        }
    }

    async handleFreedge() {
        let MandatoryArray = [
            { "Type": this.state.IsFreez}
        ]
        let check = MandatoryFormFields(MandatoryArray);
        if (check == false) {
            this.setState({ loading: false })
            return false;
        }

        let que = "UPDATE templateMaster  SET  Is_freez =" + this.state.IsFreez + "where TemplateID ="+ this.state.tempId
        let que1 = getDataTable(que)
        if(que1){
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Successfully Saved',
                showConfirmButton: false,
                timer: 1500
            })
        }
        else {
            this.setState({ loading: false })
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Some Issue Occured',
                showConfirmButton: false,
                timer: 1500
            })
        }
    }

    async ShowhandleFreedge(){
        let MandatoryArray = [
            { "Portal": this.state.portalid},
            { "Block" : this.state.catid1},
            { "Template" : this.state.tempId}
        ]
        let check = MandatoryFormFields(MandatoryArray);
        if (check == false) {
            this.setState({ loading: false })
            return false;
        }

        Modal.info({
            title: 'MARK FRIDGE OR UNFRIDGE',
            okText: 'CLOSE',
            width: '700px',
            closable: true,
            footer: null,
            bodyStyle: { maxHeight: '300px' },
            style: { padding: '0px' },
            centered: true,
            maskClosable: false,
            content: (
                <div className='row'>
                    <div>
                        <div className="col-xs-6 col-sm-6 col-md-4 margintop">
                            <label>Please Select</label><span className="vcode">*</span>
                            <div className="form-group">
                                <select name="IsFreez" onChange={this.myChangeHandler} id="IsFreez" tabIndex={1} className="form-control">
                                    <option selected="true" disabled="disabled">Please Select</option>
                                    <option value="1">FRIDGE</option>
                                    <option value="0">UNFRIDGE</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                            <label>&nbsp;</label>
                            <div className="form-group">
                                <input type='button' value='Save Field' onClick={this.handleFreedge.bind(this)} className='btn btn-block btn-primary' />
                            </div>
                        </div>
                    </div>
                    <div>
                    <label>Selected Template: {this.state.tempName}</label>
                    </div>
                </div>
            )
        });
    }

    render() {
        return (
            <div className="content-wrapper" style={{ maxHeight: '100vh' }}>
                <div className='loader' style={{ display: this.state.loading ? "block" : "none" }}>
                    <div className='loader-item'>
                        <Spin />
                    </div>
                </div>
                <section className="content">
                    <div id="ctl00_CPHMaincontent_div" className="box box-success" style={{ marginTop: '-13px' }}>
                        <div className="box-header with-border">
                            <h3 className="box-title">UPDATE PORTAL TEMPLATE
                                <span id="ctl00_CPHMaincontent_DivOrdNo" style={{ display: 'inline' }}>
                                </span>
                            </h3>
                            {
                            (this.state.loginId === "1" || this.state.loginId === "10") 
                            &&
                            <div className='pull-right'>
                                <button className="btn btn-block btn-primary" onClick={this.ShowhandleFreedge.bind(this)}>FRIDGE ALIASING</button>
                            </div>
                            }
                        </div>
                        <div className="box-body" id="orderDetails" style={{ display: 'block' }}>
                            <div className="row">
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                    <label>Select Portal</label>
                                    <AutoCompleteInput
                                        id="portalid"
                                        frmNm="FRMATTRIBUTEALIASE"
                                        quryNm="FILLPORTAL"
                                        db="IMAGEDB"
                                        placeholder="Please Select Portal"
                                        onAfterSelect={(e) => this.onAfterSelect(e, "portalid", "portalname")}
                                        isValid={this.state.isValid}
                                    ></AutoCompleteInput>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                    <label>Select Block</label>
                                    <AutoCompleteCascad
                                        id="catid"
                                        frmNm="FRMALLDATA"
                                        quryNm="FILLORGBLOCKS"
                                        db="IMAGEDB"
                                        filter1="orgid"
                                        filterId1={this.state.orgid}
                                        filter2=""
                                        filterId2=""
                                        placeholder="Please Select Block"
                                        onAfterSelect={(e) => this.onAfterSelect(e, "catid1", "catname1")}
                                        isValid={this.state.isValid}
                                    ></AutoCompleteCascad>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                    <label>Select Template</label><span className='vcode'>*&nbsp;<input type='checkbox' onClick={this.alwCustomSheet.bind(this)} name='alwCustomSheetval'></input>Update file</span>
                                    <AutoCompleteCascad
                                        id="catid"
                                        frmNm="FRMUPDATEATTRIBUTEALIASING"
                                        quryNm="FILLTEMPLATE"
                                        db="IMAGEDB"
                                        filter1='CatID'
                                        filterId1={this.state.catid1}
                                        filter2='PortalID'
                                        filterId2={this.state.portalid}
                                        placeholder="Please Select Template"
                                        onAfterSelect={(e) => this.onAfterSelect(e, "tempId", "tempName")}
                                        isValid={this.state.isValid}
                                    ></AutoCompleteCascad>
                                </div>
                                {this.state.alwCustomSheetval === 1 && <div>
                                    <div className="col-xs-4 col-sm-4 col-md-2 margintop">
                                        <label>Sheet Name</label><span className='vcode'>*</span>
                                        <div className='form-group'>
                                            <input type='Text' id='sindex' value={this.state.sindex} defaultValue={this.state.sindex} autoComplete='off' name='sindex' onChange={this.myChangeHandler} className="form-control" ></input>
                                        </div>
                                    </div>
                                    <div className="col-xs-4 col-sm-4 col-md-1 margintop">
                                        <label>Row no.</label><span className='vcode'>*</span>
                                        <div className='form-group'>
                                            <select className='form-control' onChange={this.myChangeHandler} name='rindex'>
                                                <option value=''>Select Value</option>
                                                <option value='1'>1</option>
                                                <option value='2'>2</option>
                                                <option value='3'>3</option>
                                                <option value='4'>4</option>
                                                <option value='5'>5</option>
                                                <option value='6'>6</option>
                                                <option value='7'>7</option>
                                                <option value='8'>8</option>
                                                <option value='9'>9</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                        <label>Upload File</label><span className="vcode">*</span>
                                        <div className="form-group">
                                            <input name="fldname" type='file' accept=".xls,.xlsx,.xlsm" id="tp1" onChange={this.myFileChangeHandler} placeholder="Enter FieldName" tabIndex={1} className="form-control" />
                                        </div>
                                    </div>
                                </div> }
                                <div className="col-xs-6 col-sm-6 col-md-1 margintop">
                                    <label>&nbsp;</label><span className='vcode'><input type='checkbox' onClick={this.alwduplicate.bind(this)} checked={this.state.stsdup === 1} defaultChecked={this.state.stsdup === 1} name='chkduplicate'></input><label>Allow Duplicates</label></span>
                                    <div className="form-group">
                                        <input type='button' value='GET DATA' onClick={this.UploadFile.bind(this)} className='btn btn-block btn-primary' />
                                    </div>
                                </div>

                                <div className="col-xs-12 col-sm-12 col-md-12 margintop">
                                    {this.state.aData.length > 0 &&
                                        this.storeImageTable()
                                    }
                                </div>
                                <div style={{ display: this.state.aData.length > 0 ? 'Block' : 'none' }}>
                                    <div className='col-xs-6 col-sm-4 col-md-2 margintop'>
                                        <label>Template Name</label><span className="vcode">*</span>
                                        <div className="form-group">
                                            <input type='Text' id='tempName' value={this.state.tempName} defaultValue={this.state.tempName}  name='tempName' onChange={this.myChangeHandler} className="form-control" />
                                        </div>
                                    </div>

                                    <div className="col-xs-6 col-sm-6 col-md-1 margintop">
                                        <label>Template Type</label><span className='vcode'>*</span>
                                        <div className='form-group'>
                                            <select id='mySelect' name='btType' onChange={this.myChangeHandler}
                                                className='form-control'>
                                                <option value="B2C" selected="selected">B2C</option>
                                                <option value="B2B">B2B</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className='col-xs-2 col-sm-2 col-md-1 margintop'>
                                        <label>Cell Address</label>
                                        <div className="form-group">
                                            <input type='Text' id='cellAddress' value={this.state.cellAddress} defaultValue={this.state.cellAddress} autoComplete='off' name='cellAddress' onChange={this.myChangeHandler} className="form-control" ></input>
                                        </div>
                                    </div>
                                    <div className='col-xs-6 col-sm-4 col-md-4 margintop'>
                                        <label>Description</label>
                                        <div className="form-group">
                                            <input type='Text' id='tempDiscrption' value={this.state.tempDiscrption} defaultValue={this.state.tempDiscrption} autoComplete='off' name='tempDiscrption' onChange={this.myChangeHandler} className="form-control" ></input>
                                        </div>
                                    </div>
                                    <div className='col-xs-6 col-sm-3 col-md-1 margintop' >
                                        <label>&nbsp;</label>
                                        <div className='form-group'>
                                            <TFilter width='800px' data={this.state.fldt} logid={this.state.loginId} catid={this.state.catid1} />
                                        </div>
                                    </div>
                                    <div className='col-xs-6 col-sm-3 col-md-2 margintop' >
                                        <label>&nbsp;</label>
                                        <div className='form-group'>
                                            <button className='btn btn-primary' onClick={this.SaveTemplate.bind(this)}>Save Template</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }

    async alwduplicate(e) {
        if (e.target.checked) {
            this.setState({ stsdup: 1 })
        }
        else {
            this.setState({ stsdup: 0 })
        }
    }

    storeImageTable() {
        return (
          <div>
            <div className="pull-right" style={{ marginTop: '-30px' }}>
            </div>
            <div className="table-responsive" style={{ maxHeight: '66vh' }}>
              <table id="ft" className="table table-hover">
                <thead style={{ position: "sticky" }}>
                  <tr>
                    <th>SR NO.</th>
                    <th>MANDATORY</th>
                    <th>PORTAL KEY</th>
                    <th>MAPPED WITH</th>
                    <th>CHANGE MAPPING</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.aData.map((value, index) => {
                   const showChangeMapping =
  (this.state.loginId === "1" || this.state.loginId === "10") ||
  (value.AttributeID === 0 && this.state.IsFreez === 0) ||
  (this.state.loginId !== "1" && this.state.loginId !== "10" && this.state.IsFreez === 0);

      
                    return (
                      <tr key={index}>
                        <td>
                          {index + 1}&nbsp;&nbsp;
                          <i
                            className="fa fa-trash vcode"
                            onClick={(e) => this.deleteRow(index)}
                          ></i>
                          &nbsp;&nbsp;
                          <i
                            className="fa fa-save"
                            style={{ color: 'blue' }}
                            onClick={(e) => this.CreateAttribute(index)}
                          ></i>
                        </td>
                        <td>
                          <input
                            type='checkbox'
                            id={index}
                            defaultChecked={value.mandatory === 1}
                            onClick={this.changemandatory.bind(this, index)}
                            name={value.key}
                          ></input>
                        </td>
                        <td>{value.key}</td>
                        <td>{value.AttributeName}</td>
                        <td>
                          {showChangeMapping && (
                            <AutoCompleteCascad
                              id="ValueID"
                              frmNm="FRMCREATETEMPLATE"
                              quryNm="FILLATTRIBUTE"
                              db="IMAGEDB"
                              filter1="CatID"
                              filterId1={this.state.catid1}
                              filter2=""
                              filterId2=""
                              placeholder="Please Select Attribute"
                              onAfterSelect={(e) =>
                                this.onAfterSelect(e, "ValueID", "FValue", index)
                              }
                            ></AutoCompleteCascad>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        );
      }
      
      

    changeAlias(index, values) {
        let st = this.state.aData
        if (values.length > 0) {
            for (let i = 0; i < st.length; i++) {
                const element = st[i];
                if (element.AttributeID === values[0].DisplayId && this.state.stsdup === 0) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'info',
                        title: 'Attribute Already Assinged To Another Header',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    return false;
                }
            }
            st[index].AttributeName = values[0].DisplayName
            st[index].AttributeID = values[0].DisplayId
        }
        else {
            st[index].AttributeName = ''
            st[index].AttributeID = 0
        }
        this.setState({ aData: st })
    }


    changemandatory(index, e) {
        let st = this.state.aData
        if (e.target.checked) {
            st[index].mandatory = 1
        }
        else {
            st[index].mandatory = 0
        }
        this.setState({ aData: st })
    }

    deleteRow(index) {
        let st = this.state.aData
        st.splice(index, 1);
        this.setState({ groupedImg: st })
    }
    async SaveTemplate() {

        this.setState({ loading: true })
        let MandatoryArray = [
            { "BLOCK": this.state.catid1 },
            { "portal": this.state.portalid }
        ]
        let check = MandatoryFormFields(MandatoryArray);
        if (check == false) {
            this.setState({ loading: false })
            return false;
        }

        let tempdata = this.state.aData
        let tempdata1 = this.state.fldt //filter data


        for (let j = 0; j < this.state.aData.length; j++) {
            const element = this.state.aData[j];
            if (element.AttributeID === '0' || element.AttributeID === 0 || element.AttributeID === undefined) {
                Swal.fire({
                    icon: 'error',
                    title: "PLEASE COMPLETE MAPPING OR REMOVE UN-MAPPED ATTRIBUTE",
                    showConfirmButton: false,
                    timer: 1500
                })
                this.setState({ loading: false })
                return false;
            }
        }
        let dtstr = JSON.stringify(tempdata)
        let que0 = "EXEC frmTemplateAlising_SaveUpdateTemplatedetails @dtJSON = '" + dtstr + "' , @templateID = " + this.state.tempId + " , @loginID = " + this.state.loginId
        let dt = await getDataTable(que0)
        // for (let i = 0; i < tempdata.length; i++) {
        //     let que1 = "INSERT INTO TemplateDetails (templateID, AttributeID, LoginID, Logdate,Priority,mandatory,HeaderName) VALUES (" + this.state.tempId + "," + tempdata[i].AttributeID + "," + this.state.loginId + ",cast(getdate() as date)," + (i + 1) + "," + tempdata[i].mandatory + ",'" + tempdata[i].key + "')"
        //     let dt1 = await getDataTable(que1)
        // }
        let que1 = "Update TemplateMaster SET TamplateName = '" + this.state.tempName + "', Discription = '" + this.state.tempDiscrption + "', CellAddress = '" + this.state.cellAddress + "', TemplateType = '" + this.state.btType + "' WHERE TemplateID = " + this.state.tempId
        let rs1 = await getDataTable(que1)
        if (this.state.alwCustomSheetval === 1) {
            await getDataTable("Update TemplateMaster SET SheetName = '" + this.state.sindex + "' WHERE TemplateID = " + this.state.tempId)
            let stsnew = await updateTemplateFile(files, this.state.portalname, this.state.tempId)
        }
        await Swal.fire({
            icon: 'success',
            title: "ALIASING UPDATED",
            showConfirmButton: false,
            timer: 1500
        })
        let arr = ["10", "6", "1", "10019"]
        if (arr.includes(this.state.loginId)) {

            // const cndtn = [...new Set(tempdata1.map(item => item.Condition))];
            let q1 = "EXEC frmupdateTemplate_DeleteFilters @TemplateID = " + this.state.tempId + " , @LoginID = " + this.state.loginId
            let r1 = await getDataTable(q1)

            let filterSTR = JSON.stringify(tempdata1)
            let q = "EXEC SaveTemplateFilters @loginId = " + this.state.loginId + ", @dtJSON = '" + filterSTR + "' , @tid = " + this.state.tempId



            let r = await getDataTable(q)
            if (r[0]["Status"] == "Done") {
                Swal.fire({
                    icon: 'success',
                    title: "Filters Updated",
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }


        this.setState({ loading: false, aData: [] })
    }
}