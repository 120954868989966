import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { span, withRouter, useHistory } from "react-router-dom";
import { APIService } from "../../../Config/action/apiAction";
import { TokenService } from "../../../Config/action/tokenAction";
import PageTitle from "../../Common/pageTitle";
import { Spin } from "antd";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAuthorization } from "../../../Configuration";
import CryptoService from "../../../Config/action/crypto";

function PricingDetail(props) {
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState([]);
  const [charges, setCharges] = useState([]);
  const [title, setTitle] = useState("");
  const [currencyList, setCurrencyList] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState("");

  const history = useHistory();
  useEffect(() => {
    defaultUseEffect();
  }, []);

  const defaultUseEffect = () => {
    setLoading(true);
    let payload = {
      query: `Exec PricingModule_GetPlanDetailsOrgWise @orgID = ${props.match.params.id}`,
    };
    props
      .dispatch(APIService("DYNAMIC_POST", "", payload))
      .then((data) => {
        setLoading(false);
        if (data.status === 200 && data.data.status === 1000) {
          setPlans(data.data.data);
          setSelectedCurrency(data.data.data[0].CurrencyID);
          setTitle(
            data.data.data.length > 0
              ? data.data.data[0]["OrganizationName"]
              : ""
          );
          fetchCharges();
        } else {
          setPlans([]);
          fetchCharges();
        }
      })
      .catch((err) => {
        fetchCharges();
        setLoading(false);
      });
  };

  const fetchCharges = () => {
    setLoading(true);
    let payload = {
      query: `Exec PricingModule_GetChargesDetailsOrgWise @orgID = ${props.match.params.id}`,
    };
    props
      .dispatch(APIService("DYNAMIC_POST", "", payload))
      .then((data) => {
        setLoading(false);
        if (data.status === 200 && data.data.status === 1000) {
          setCharges(data.data.data);
          getCurrencyList();
        } else {
          setPlans([]);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handlePlanChange = (key, value, index) => {
    let plan = plans;
    plan[index][key] = value;
    setPlans([...plan]);
  };

  const handleChargeChange = (key, value, index) => {
    let charge = charges;
    charge[index][key] = value;
    setCharges([...charge]);
  };

  const updatePlan = (e, plan) => {
    e.preventDefault();
    e.stopPropagation();
    let userId = JSON.parse(localStorage.getItem("auth_user")).ID;
    setLoading(true);
    let payload = {
      query: `Exec PricingModule_UpdateOrgPlanDetails @orgID = ${plan.OrgID},@NewPrice = ${plan["NewPrice"]},@PlanID = ${plan.PlanID},@loginID = ${userId}`,
    };
    props
      .dispatch(APIService("DYNAMIC_POST", "", payload))
      .then((data) => {
        setLoading(false);
        if (data.status === 200 && data.data.status === 1000) {
          showToast(
            CryptoService.decrypt(getAuthorization()).isAdmin
              ? "Plan updated successfully"
              : "Update plan request have been created to Admin."
          );
          defaultUseEffect();
        } else {
          setPlans([]);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const updateCharge = (e, charge) => {
    e.preventDefault();
    e.stopPropagation();
    let userId = JSON.parse(localStorage.getItem("auth_user")).ID;
    setLoading(true);
    let payload = {
      query: `Exec PricingModule_UpdateOrgChargeDetails @orgID = ${charge.OrgID},@NewCharge = ${charge.newcharge},@ChargeID = ${charge.chargeID},@loginID = ${userId}`,
    };
    props
      .dispatch(APIService("DYNAMIC_POST", "", payload))
      .then((data) => {
        setLoading(false);
        if (data.status === 200 && data.data.status === 1000) {
          showToast(
            CryptoService.decrypt(getAuthorization()).isAdmin
              ? "Charge updated successfully"
              : "Update charge request have been created to Admin."
          );
          fetchCharges();
        } else {
          setPlans([]);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const showToast = (message) => {
    toast(message, {
      position: "top-right",
      type: "success",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  };

  const addPlan = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let newPlan = {
      NewPrice: "",
      OrgID: props.match.params.id,
      OrganizationName: title,
      PlanID: Date.now(),
      PlanName: "",
      new: true,
    };
    let p = [...plans];
    p.push(newPlan);
    setPlans(p);
  };

  const createPlan = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    let userId = JSON.parse(localStorage.getItem("auth_user")).ID;
    let payload = {
      query: `PricingModule_CreateNewplan @NewPlanName = ${item.PlanName}, @Price = ${item["NewPrice"]}, @timeDuration = 2, @loginID = ${userId}`,
    };
    props
      .dispatch(APIService("DYNAMIC_POST", "", payload))
      .then((data) => {
        setLoading(false);
        if (data.status === 200 && data.data.status === 1000) {
          showToast(
            CryptoService.decrypt(getAuthorization()).isAdmin
              ? "New plan created successfully"
              : "New plan request have been created to Admin."
          );
          defaultUseEffect();
        } else {
          setPlans([]);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const addCharge = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let newCharge = {
      newcharge: "",
      chargetype: "",
      OrgID: props.match.params.id,
      OrganizationName: title,
      chargeID: Date.now(),
      new: true,
    };
    let c = [...charges];
    c.push(newCharge);
    setCharges(c);
  };

  const createCharge = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    let userId = JSON.parse(localStorage.getItem("auth_user")).ID;
    let payload = {
      query: `PricingModule_CreateNewCharge @Newchargetype = ${item.chargetype}, @totalcharge = ${item.newcharge}, @loginID = ${userId}`,
    };
    setLoading(true);
    props
      .dispatch(APIService("DYNAMIC_POST", "", payload))
      .then((data) => {
        setLoading(false);
        if (data.status === 200 && data.data.status === 1000) {
          showToast(
            CryptoService.decrypt(getAuthorization()).isAdmin
              ? "New charge created successfully"
              : "New charge request have been created to Admin."
          );
          fetchCharges();
        } else {
          setCharges([]);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const removePlan = (e, plan) => {
    e.preventDefault();
    e.stopPropagation();
    if (plan.new) {
      let p = [];
      plans.map((item) => {
        if (item.PlanID != plan.PlanID) {
          p.push(item);
        }
      });
      setPlans(p);
    } else {
      let userId = JSON.parse(localStorage.getItem("auth_user")).ID;
      let payload = {
        query: `PricingModule_PlanDisAPI @planid = ${plan.PlanID}, @loginId = ${userId}`,
      };
      setLoading(true);
      props
        .dispatch(APIService("DYNAMIC_POST", "", payload))
        .then((data) => {
          setLoading(false);
          if (data.status === 200 && data.data.status === 1000) {
            showToast("Plan deleted successfully");
            defaultUseEffect();
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const removeCharge = (e, charge) => {
    e.preventDefault();
    e.stopPropagation();
    if (charge.new) {
      let c = [];
      charges.map((item) => {
        if (item.chargeID != charge.chargeID) {
          c.push(item);
        }
      });
      setCharges(c);
    } else {
      let userId = JSON.parse(localStorage.getItem("auth_user")).ID;
      let payload = {
        query: `PricingModule_ChargeDisAPI @chargeid = ${charge.chargeID}, @loginId = ${userId}`,
      };
      setLoading(true);
      props
        .dispatch(APIService("DYNAMIC_POST", "", payload))
        .then((data) => {
          setLoading(false);
          fetchCharges();
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const getCurrencyList = () => {
    let payload = {
      query: "exec PricingModule_GetAllCurrencyAPI",
    };
    setLoading(true);
    props
      .dispatch(APIService("DYNAMIC_NEW_POST", "", payload))
      .then((data) => {
        setLoading(false);
        if (data.status === 200 && data.data.status === 1000) {
          setCurrencyList(data.data.data);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const updateCurrency = (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log(e.currentTarget.value);
    if (e.currentTarget.value) {
      let userId = JSON.parse(localStorage.getItem("auth_user")).ID;
      let payload = {
        query: `exec PricingModule_UpdateCurrencyOrgWise @currencyID = ${e.currentTarget.value}, @orgID = ${props.match.params.id}, @loginID = ${userId}`,
      };
      setLoading(true);
      props
        .dispatch(APIService("DYNAMIC_NEW_POST", "", payload))
        .then((data) => {
          setLoading(false);
          if (data.status === 200 && data.data.status === 1000) {
            showToast("Currency updated successfully");
            setSelectedCurrency(data.data.data[0].currencyId);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  return (
    <div className="content-wrapper">
      <div className="loader" style={{ display: loading ? "block" : "none" }}>
        <div className="loader-item">
          <Spin />
        </div>
      </div>
      <section className="content" style={{ background: "white" }}>
        <div
          id="ctl00_CPHMaincontent_divclient"
          className="box box-success"
          style={{ marginTop: "-13px" }}
        >
          <div className="box-header with-border">
            <h3 className="box-title">
              Pricing
              {/* <sup>
                (<span className="vcode">*</span> Fields are mandatory)
              </sup> */}
              <span
                id="ctl00_CPHMaincontent_DivOrdNo"
                style={{ display: "inline" }}
              ></span>
            </h3>
            <div className="pull-right" style={{ marginLeft: "10px" }}>
              <button
                onClick={() => history.push("/Pricing/organization")}
                className="btn btn-primary"
              >
                Go Back
              </button>
            </div>{" "}
            <div className="pull-right">
              <select
                className="currency-select"
                value={selectedCurrency}
                onChange={(e) => updateCurrency(e)}
                style={{ height: "33px" }}
              >
                <option value="">Currency</option>
                {currencyList.length > 0 ? (
                  <Fragment>
                    {currencyList.map((item, index) => {
                      return (
                        <option key={index} value={item.currencyid}>
                          {item.currencyname + " (" + item.currencysymbol + ")"}
                        </option>
                      );
                    })}
                  </Fragment>
                ) : null}
              </select>
            </div>
          </div>
          <div
            className="box-body"
            id="orderDetails"
            style={{ display: "block" }}
          >
            <label>Plan Details</label>
            <div className="row">
              {plans.length >= 0 &&
                plans.map((item, index) => (
                  <>
                    <div
                      key={index}
                      className="d-flex inputClassHandler"
                      style={{ marginBottom: "10px" }}
                    >
                      <div
                        className="form-group"
                        style={{ marginRight: "10px" }}
                      >
                        <input
                          name="Plan"
                          id="Plan"
                          onChange={(e) =>
                            handlePlanChange(
                              "PlanName",
                              e.currentTarget.value,
                              index
                            )
                          }
                          value={item.PlanName || ""}
                          // onChange={this.myChangeHandler}
                          placeholder="Enter Plan"
                          tabIndex={1}
                          className="form-control"
                        />
                      </div>
                      <div
                        className="form-group"
                        style={{ marginRight: "10px" }}
                      >
                        <input
                          name="Plan"
                          id="Plan"
                          onChange={(e) =>
                            handlePlanChange(
                              "NewPrice",
                              e.currentTarget.value,
                              index
                            )
                          }
                          value={item["NewPrice"] || ""}
                          // onChange={this.myChangeHandler}
                          placeholder="Enter Price"
                          tabIndex={1}
                          className="form-control"
                        />
                      </div>
                      {item.new ? (
                        <button
                          onClick={(e) => createPlan(e, item)}
                          className="btn btn-primary"
                        >
                          Save
                        </button>
                      ) : (
                        <button
                          onClick={(e) => updatePlan(e, item)}
                          className="btn btn-primary"
                        >
                          Update
                        </button>
                      )}
                      <button
                        onClick={(e) => removePlan(e, item)}
                        style={{ marginLeft: "15px" }}
                        className="btn btn-danger"
                      >
                        Remove
                      </button>
                    </div>
                  </>
                ))}

              <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                <div className="form-group">
                  <button
                    onClick={(e) => addPlan(e)}
                    className="btn btn-primary"
                  >
                    Create
                  </button>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div
            className="box-body"
            id="orderDetails"
            style={{ display: "block" }}
          >
            <label>Charge Details</label>
            <div className="row">
              {charges.length >= 0 &&
                charges.map((item, index) => (
                  <>
                    <div
                      key={index}
                      className="d-flex inputClassHandler"
                      style={{ marginBottom: "10px" }}
                    >
                      <div
                        className="form-group"
                        style={{ marginRight: "10px" }}
                      >
                        <input
                          name="Type"
                          id="Type"
                          onChange={(e) =>
                            handleChargeChange(
                              "chargetype",
                              e.currentTarget.value,
                              index
                            )
                          }
                          value={item["chargetype"] || ""}
                          // onChange={this.myChangeHandler}
                          placeholder="Enter Type"
                          tabIndex={1}
                          className="form-control"
                        />
                      </div>
                      <div
                        className="form-group"
                        style={{ marginRight: "10px" }}
                      >
                        <input
                          name="New Charge"
                          id="New Charge"
                          onChange={(e) =>
                            handleChargeChange(
                              "newcharge",
                              e.currentTarget.value,
                              index
                            )
                          }
                          value={item["newcharge"] || ""}
                          // onChange={this.myChangeHandler}
                          placeholder="Enter New Charge"
                          tabIndex={1}
                          className="form-control"
                        />
                      </div>
                      {item.new ? (
                        <button
                          onClick={(e) => createCharge(e, item)}
                          className="btn btn-primary"
                        >
                          Save
                        </button>
                      ) : (
                        <button
                          onClick={(e) => updateCharge(e, item)}
                          className="btn btn-primary"
                        >
                          Update
                        </button>
                      )}
                      <button
                        onClick={(e) => removeCharge(e, item)}
                        style={{ marginLeft: "15px" }}
                        className="btn btn-danger"
                      >
                        Remove
                      </button>
                    </div>
                  </>
                ))}

              <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                <div className="form-group">
                  <button
                    onClick={(e) => addCharge(e)}
                    className="btn btn-primary"
                  >
                    Create
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: () => {
      dispatch({
        type: "loginUser",
      });
    },
    logoutUser: () => {
      dispatch({
        type: "logoutUser",
      });
    },
    dispatch,
  };
};

export default connect(null, mapDispatchToProps)(withRouter(PricingDetail));
