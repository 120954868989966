import React, { Component } from 'react';
import Table from './MODAL/table'
import AutoCompleteInput from '../Actions/Controls/AutoComplete/autoComplete';
import { Configuration, getDataTable, MandatoryFormFields } from '../Configuration';
import { getQueryStr_Comma } from '../CommanFunc';
import { Spin } from 'antd';
import '../ORDER/OrderMaster/style.css'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'


export default class frmproduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginId: 0,
            productdata: [],
            adata: [],
            atList: [],
            loading: false,
        };
    }
    async componentDidMount() {
        await this.setState({
            loginId: Configuration.USER_ID,
          });
    }
    async getdata() {
        let pName = getQueryStr_Comma('productName',this.state.prdname)
        let MandatoryArray = [
            {"Category":this.state.catid},
            { "Attribute": this.state.atList.length },
            {"Product":pName}
        ]
        let check = MandatoryFormFields(MandatoryArray);
        if (check == false) {
            return false;
        }
         
        let list = ''
        for (let i = 0; i < this.state.atList.length; i++) {
            if (list === '') {
                list = "[" + this.state.atList[i] + "]"
            }
            else {
                list = list + ",[" + this.state.atList[i] + "]"
            }
        }
        console.log(list);
        let Que = "EXEC GetProductData_DetailsMul @loginid=" + this.state.loginId + " ,@CatID=" + this.state.catid + " ,@fields='" + list + "', @filter='"+pName+"'"
        let rs = await getDataTable(Que);
        if (rs.length > 0) {
            this.setState({ productdata: rs })
        }
        else{
            alert("No Data Found")
            return;
        }
    }
    myChangeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }
    async onAfterSelect(selectedOptions, id, name) {
        if (selectedOptions.length > 0) {
            this.setState({
                [id]: selectedOptions[0].DisplayId,
                [name]: selectedOptions[0].DisplayName
            })
            if (id === "catid") {
                await this.GetAttributes(selectedOptions[0].DisplayId);
            }
        }
        else {
            this.setState({
                [id]: '',
                [name]: '',
                productdata: [],
                adata: [],
                atList: []
            })
        }
    }
    async SaveProduct() {
         
        this.setState({ loading: true })
        let arr = []
        let tbl = document.getElementById('prdt')
        let inputs = tbl.getElementsByTagName('input')
        let selects = tbl.getElementsByTagName('select')
        for (let i = 0; i < inputs.length; i++) {
            let val = inputs[i].value
            let idval = inputs[i].id
            let productid = inputs[i].name

            if (val) {
                arr.push({ id: idval, fldval: val, pid: productid })
            }
        }
        for (let j = 0; j < selects.length; j++) {
            let val = selects[j].value
            let idval = selects[j].id
            let productid = selects[j].name
            if (val) {
                arr.push({ id: idval, fldval: val, pid: productid })
            }
        }
    
        for (let k = 0; k < arr.length; k++) {
            var Que = "EXEC SaveDataSingleProduct @pid=" + arr[k].pid + ", @atid=" + arr[k].id + " , @loginid=" + this.state.loginId + ", @fldvalue='" + arr[k].fldval + "'"
            let rs = await getDataTable(Que)
        }
        Swal.fire({
            position: 'top-end',
            icon: 'info',
            title: 'Updated Successfully',
            showConfirmButton: false,
            timer: 1500
        })
        this.setState({ loading: false,productdata:[] })
        return;
    }
    render() {
        return (
            <div className="content-wrapper">
                <div className='loader' style={{ display: this.state.loading ? "block" : "none" }}>
                    <div className='loader-item'>
                        <Spin />
                    </div>
                </div>
                <section className="content">
                    <div id="ctl00_CPHMaincontent_divclient" className="box box-success" style={{ marginTop: '-13px' }}>
                        <div className="box-header with-border">
                            <h3 className="box-title">Product Master
               <span id="ctl00_CPHMaincontent_DivOrdNo" style={{ display: 'inline' }}>
                                </span>
                            </h3>
                        </div>
                        <div className="box-body" id="orderDetails" style={{ display: 'block' }}>
                            <div className="row">
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                    <label>Select Block</label><span className='vcode'>*</span>
                                    <AutoCompleteInput
                                        id="articleid"
                                        frmNm="FRMCATPRODUCTMASTER"
                                        quryNm="FILLCAT"
                                        db="IMAGEDB"
                                        placeholder="Please Select Block"
                                        onAfterSelect={(e) => this.onAfterSelect(e, "catid", "catname")}
                                        isValid={this.state.isValid}
                                    ></AutoCompleteInput>
                                    {this.state.adata.length > 0 && this.renderFileInTable()}
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                    <label>Enter ProductName</label>
                                    <div className='form-group'>
                                        <input type='text' name='prdname' onChange={this.myChangeHandler.bind(this)} className='form-control' />
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                    <label>&nbsp;</label>
                                    <div className='form-group'>
                                        <input value='Get Data' type='button' onClick={this.getdata.bind(this)} className='btn btn-block btn-primary' />
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop" style={{ display: this.state.productdata.length > 0 ? 'inline' : 'none' }}>
                                    <label>&nbsp;</label>
                                    <div className='form-group'>
                                        <input value='Update Data' type='button' onClick={this.SaveProduct.bind(this)} className='btn btn-block btn-success' />
                                    </div>
                                </div>
                                <div>
                                    <div className="col-xs-12 col-sm-12 col-md-12 margintop">
                                        {
                                            this.state.productdata != undefined && this.state.productdata.length > 0 &&
                                            <Table id='prdt' data={this.state.productdata} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
    renderFileInTable() {
        return (
            <div style={{ display: this.state.productdata.length > 0 ? 'none' : 'inline' }}>
                <div className="table-responsive" style={{ maxHeight: '40vh' }}>
                    <table id="alisttable" className="table table-hover">
                        <thead style={{ position: "sticky" }}>
                            <tr> <th>SR NO.<span>&nbsp;<input type="checkbox" onChange={this.SelectAllFN.bind(this)}></input></span></th>
                                <th>ATTRIBUTENAME</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.adata.map((value, index) => {
                                return <tr key={index}>
                                    <td>{index + 1}&nbsp;<input type="checkbox" value={value.AttributeName} onChange={this.SelectFN.bind(this, value)}></input></td>
                                    <td>{value.AttributeName}</td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
    SelectFN(e, f) {
        let alist = this.state.atList
        if (f.target.checked) {
            alist.push(e.AttributeName)
        }
        else {
            var index = alist.indexOf(e.AttributeName);
            if (index > -1) {
                alist.splice(index, 1);
            }
        }
        this.setState({
            atList: alist
        })
    }
    async GetAttributes(id) {
        let data = [];
        let Que = "SELECT b.AID,b.AttributeName FROM AttributeCategoryMap as a INNER JOIN AttributeMaster as b ON a.AttributeID = b.AID WHERE (a.CatID = " + id + ")"
        if (this.state.tempID > 0) {
            Que = "SELECT AttributeMaster.AID, AttributeMaster.AttributeName FROM TemplateDetails INNER JOIN AttributeMaster ON TemplateDetails.AttributeID = AttributeMaster.AID WHERE (TemplateDetails.templateID = " + this.state.tempID + ")"
        }
        let rs = await getDataTable(Que)
        if (rs.length > 0) {
            this.setState({
                adata: rs
            })
        }
        else {
            alert("No Data Found")
            this.setState({
                adata: []
            })
        }
    }
    SelectAllFN(e) {
        let alist = []
        var tbl = document.getElementById('alisttable');
        var inputs = tbl.getElementsByTagName('input')
        if (e.target.checked) {
            for (let i = 1; i < inputs.length; i++) {
                alist.push(inputs[i].value)
                inputs[i].checked = "true"
            }
        }
        else {
            for (let i = 1; i < inputs.length; i++) {
                inputs[i].checked = false
            }
        }
        this.setState({
            atList: alist
        })
    }
}
