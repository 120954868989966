import { Modal } from 'antd';
import React, { Component } from 'react';
import '../../ORDER/OrderMaster/style.css'
import Table from '../../Actions/Controls/Table/AntDTable'
import { Configuration, MandatoryFormFields, getDataTable, arrUnique } from '../../Configuration';
import AutoCompleteCascad from '../../Actions/Controls/AutoComplete/autoComplete.cascad';
import AutoCompleteInput from '../../Actions/Controls/AutoComplete/autoComplete';
import 'sweetalert2/src/sweetalert2.scss'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { swal } from 'sweetalert2/dist/sweetalert2';

export default class frmAmazonParent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            modal1Visible: false,
            modal2Visible: false,
            loginId: 0,
            fldt: [],
            headerList: [],
            portalid: ''
        };
    }

    async componentDidMount() {
        let rs = await getDataTable("select orgid from users where userid = " + Configuration.USER_ID)
        await this.setState({
            loginId: Configuration.USER_ID,
            orgid: rs[0].orgid,
            headerList: []
        });
    }

    setModal1Visible(modal1Visible) {
        this.setState({ modal1Visible });
    }

    setModal2Visible(modal2Visible) {
        this.setState({ modal2Visible });
    }

    async GetList(id) {
        this.setState({ headerList: [] })
        let que = "EXEC frmAmazonParent_getHeaderList @BlockID = " + (this.state.catid1 ? this.state.catid1 : id) + " , @PortalID = " + this.state.portalid
        let rs = await getDataTable(que)
        this.setState({
            headerList: rs
        })
    }

    async onAfterSelect(selectedOptions, id, name) {
        if (selectedOptions.length > 0) {
            this.setState({
                [id]: selectedOptions[0].DisplayId,
                [name]: selectedOptions[0].DisplayName,
            })
        }
        else {
            this.setState({
                [id]: '',
                [name]: '',
            })
        }
        if (this.state.portalid) {
            if (id == "catid1" && selectedOptions.length > 0) {
                await this.GetList(selectedOptions[0].DisplayId, this)
            }
            else if (id == "catid1" && selectedOptions.length <= 0) {
                this.setState({
                    headerList: []
                })
            }
        }
    }


    myChangeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    async SaveHeader(e) {
        let MandatoryArray = [
            { "Block": this.state.catid1 },
            { "HEADER": this.state.HEADERNAME },
            { "PORTAL": this.state.portalid }
        ]
        let check = MandatoryFormFields(MandatoryArray);
        if (check == false) {
            return false;
        }
        let que = ""
        que += "EXEC frmAmazonParent_CreateParentHeader @BlockID = " + this.state.catid1
        que += " ,@PortalHeader = '" + this.state.HEADERNAME + "'"
        que += " , @loginID = " + this.state.loginId
        que += " , @PortalID = " + this.state.portalid
        let rs = await getDataTable(que)
        await Swal.fire({
            icon: rs[0]['alert'],
            title: rs[0]['msg'],
            showConfirmButton: false,
            timer: 1500
        })
        await this.GetList(this, this.state.catid1)
    }

    async deleteRow(e) {
        if (this.state.loginId == "1" || this.state.loginId == "10" || this.state.loginId == "6") {
            let st = await Swal.fire({
                title: 'Do you want to delete from parent?',
                showCancelButton: true,
                confirmButtonText: 'YES',
            })
            if (st.isConfirmed) {
                let que = ""
                que += " DELETE FROM AmazonParentAllowed where tblid = " + e['#tblid']
                let rs = await getDataTable(que)
                await Swal.fire({
                    icon: 'success',
                    title: 'SUCCESSFULLY DELETED',
                    showConfirmButton: false,
                    timer: 2000
                })
                await this.GetList(this, this.state.catid1)
            }
            else {
                return false;
            }
        }
        else {
            await Swal.fire({
                icon: 'info',
                title: 'YOU ARE NOT ALLOWED TO DELETE',
                showConfirmButton: false,
                timer: 2000
            })
        }
    }

    render() {

        return (
            <div>
                <button type="button" onClick={() => this.setModal2Visible(true)} className="btn btn-success btn-block">PARENT HEADER</button>

                <Modal
                    title="SET PARENT HEADER"
                    width={this.props.width}
                    visible={this.state.modal2Visible}
                    onOk={() => this.setModal2Visible(false)}
                    onCancel={() => this.setModal2Visible(false)}
                    destroyOnClose={true}
                    maskClosable={true}
                    footer={null}
                >
                    <div className='row'>
                        <div className="col-xs-6 col-sm-6 col-md-3 margintop">
                            <label>Select Portal</label>
                            <AutoCompleteInput
                                id="portalid"
                                frmNm="FRMAMAZONPARENT"
                                quryNm="FILLPORTAL"
                                db="IMAGEDB"
                                placeholder="Please Select Portal"
                                onAfterSelect={(e) => this.onAfterSelect(e, "portalid", "portalname")}
                                isValid={this.state.isValid}
                            ></AutoCompleteInput>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-md-3 margintop">
                            <label>Select Block</label>
                            <AutoCompleteCascad
                                id="catid"
                                frmNm="FRMALLDATA"
                                quryNm="FILLORGBLOCKS"
                                db="IMAGEDB"
                                filter1="orgid"
                                filterId1={this.state.orgid}
                                filter2=""
                                filterId2=""
                                placeholder="Please Select Block"
                                onAfterSelect={(e) => this.onAfterSelect(e, "catid1", "catname1")}
                                isValid={this.state.isValid}
                            ></AutoCompleteCascad>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-md-3 margintop">
                            <label>SELECT HEADER</label>
                            <AutoCompleteCascad
                                id="headid"
                                frmNm="FRMAMAZONPARENT"
                                quryNm="FILLPORTALHEADER"
                                db="IMAGEDB"
                                filter1='CatID'
                                filterId1={this.state.catid1}
                                filter2='portalID'
                                filterId2={this.state.portalid}
                                placeholder="Please Select Template"
                                onAfterSelect={(e) => this.onAfterSelect(e, "HEADID", "HEADERNAME")}
                                isValid={this.state.isValid}
                            ></AutoCompleteCascad>
                        </div>
                        <div className='col-xs-6 col-sm-3 col-md-2 margintop' >
                            <label>&nbsp;</label>
                            <div className='form-group'>
                                <button className='btn btn-primary' onClick={this.SaveHeader.bind(this)}>Save Header</button>
                            </div>
                        </div>
                        {
                            this.state.headerList.length > 0 &&
                            <div className='col-xs-12 col-sm-12 col-md-12 margintop' >
                                <Table
                                    data={[...this.state.headerList]}
                                    Delete="true"
                                    DeleteFn={(e) => this.deleteRow(e)}
                                ></Table>
                            </div>
                        }
                    </div>
                </Modal>
            </div>
        )
    }

}