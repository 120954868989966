import { Modal, Button } from 'antd';

import React, { Component } from 'react';
import AutoCompleteInput from '../../src/Actions/Controls/AutoComplete/autoComplete';
import AutoCompletemulti from '../../src/Actions/Controls/AutoComplete/autoCompleteMultiple';
import AutoCompleteCascad from '../../src/Actions/Controls/AutoComplete/autoComplete.cascad';
import '../ORDER/OrderMaster/style.css'
import { Configuration, MandatoryFormFields, getDataTable } from '../Configuration';
import Swal, { swal } from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
let groupList = [];
let AID = [];
export default class SelectAttributeUpdate extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loginId: 0,
            modal1Visible: false,
            modal2Visible: false,
            AttributeName: "",
            AID: "",
        };
    }


    async componentDidMount() {
        this.setState({
            loginId: Configuration.USER_ID,
        });
        await this.fillPortaldata(this);
    }

    async fillPortaldata() {
        let data = [];
        let Query = "select distinct AttributeName from NamshiUpdateAttributes"
        data = await getDataTable(Query)
        groupList = data
        this.forceUpdate();
    }

    setModal2Visible(modal2Visible) {
        this.setState({ modal2Visible });
    }

    myChangeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
        this.forceUpdate();
    }

    async SavePortal() {
        for (let i = 0; i < AID.length; i++) {
            let attributeName = AID[i];
            let existingQuery = "Select * from NamshiUpdateAttributes where AttributeName = '" + attributeName + "' and isAllowed = 1";
            let existingDt = await getDataTable(existingQuery);

            if (existingDt.length > 0) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'info',
                    title: 'Attribute ' + attributeName + ' Already Exist with isAllowed = 1',
                    showConfirmButton: false,
                    timer: 1500
                });
                continue;
            }

            let Que =
                "Update NamshiUpdateAttributes set isAllowed = 1 output inserted.attributeid where AttributeName = '" +
                attributeName +
                "'";
            let rs = await getDataTable(Que);
            debugger
            if (rs.length > 0) {
                Swal.fire({
                    position: "top-end",
                    icon: "info",
                    title: "Successfully Saved",
                    showConfirmButton: false,
                    timer: 1500,
                });
            } else {
                alert("Some Error Occurred");
                this.setState({ loading: false });
            }
        }

        await this.fillPortaldata();
        this.forceUpdate();
    }



    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({ [nam]: val });

        this.forceUpdate();
    }

    onAfterSelect(selectedOptions, id, name, index = 0) {
        if (selectedOptions.length > 0) {
            this.setState({
                [id]: selectedOptions[0].DisplayId,
                [name]: selectedOptions[0].DisplayName
            })
        }
        else {
            this.setState({
                [id]: '',
                [name]: ''
            })
        }
    }

    chkFacChange(evt) {

        let facIds = AID;
        if (evt.target.checked) {

            facIds.push(evt.target.value);
            AID = facIds

        }
        else {
            facIds.pop(evt.target.value);;
            AID = facIds
        }
        this.forceUpdate();
    }

    render() {
        return (
            <div>
                <button type="button" onClick={() => this.setModal2Visible(true)} className="btn btn-primary">SELECT ATTRIBUTES</button>

                <Modal
                    title="Select Attributes"
                    width={this.props.width}
                    visible={this.state.modal2Visible}
                    onOk={() => this.setModal2Visible(false)}
                    onCancel={() => this.setModal2Visible(false)}
                    destroyOnClose={true}
                    maskClosable={false}
                    footer={null}
                >
                    <div className='row'>
                        <div className="col-md-12 form-group">
                            <div className="table table-hover datatable table-responsive hover" style={{ height: "200px" }}>
                                <table className="table table-border table-fixed datatable" id="tblFactoryList">
                                    <thead>
                                        <tr>
                                            <th>Action</th>
                                            <th>AttributeName</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {groupList.map((fac, key) => {
                                            return (<tr key={key}>
                                                <td><input key={key} value={fac.AttributeName} id={fac.AttributeName} type="checkbox" onChange={this.chkFacChange.bind(this)}></input>&nbsp;{key + 1}</td>
                                                <td>{fac.AttributeName}</td>
                                            </tr>)
                                        })}
                                    </tbody>
                                    <div className='col-md-1' >
                                        <label>&nbsp;</label>
                                        <button className='btn btn-primary' onClick={this.SavePortal.bind(this)}>Save Attributes</button>
                                    </div>
                                </table>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}