import React, { Component, Fragment } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
// import NavBar from '../../Common/navbar';
// import PageTitle from '../../Common/pageTitle';
// import Sidenav from '../../Common/sidenav';
import PricingDetail from "./pricingDetail";
import PricingList from "./pricingList";
import userListing from "./userListing";
import { getAuthorization } from "../../../Configuration";
import CryptoService from "../../../Config/action/crypto";

class Pricing extends Component {
  render() {
    return (
      <Fragment>
        <div style={{ backgroundColor: "#f4f6f9", minHeight: "100vh" }}>
          <Switch>
            <Route exact path="/Pricing/organization" component={PricingList} />
            <Route
              exact
              path="/Pricing/organization/:id"
              component={PricingDetail}
            />
            {CryptoService.decrypt(getAuthorization()).isAdmin ? (
              <Route exact path="/Pricing/user" component={userListing} />
            ) : (
              <Redirect from="/Pricing" to="/Pricing/organization" />
            )}
            <Redirect from="/Pricing" to="/Pricing/organization" />
          </Switch>
          {/* <PricingDetail /> */}
        </div>
      </Fragment>
    );
  }
}

export default Pricing;
