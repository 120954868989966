import React, { Component } from 'react';
import { Configuration, getDataTable } from './Configuration';
class Menu extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }
  state = {
    menuItems: [],
    menuList: [],
    isLoaded: false,
    orgname:''

  }

  async componentDidMount() {
    await this.setState({
      loginId: Configuration.USER_ID
    });
    await this.handleClick(Configuration.USER_ID)

  }
  async handleClick(val) {
    let Query = ""
    if (val === "1") {
       Query = "SELECT DISTINCT MenuId, MenuName, Path AS url, 'ALL' AS Rights, loginID AS userid,Priority FROM Menu where Dis = 0 order by Priority";
    } else {
      Query = "SELECT distinct Menu.MenuId,Menu.MenuName, Menu.Path as url,UserRights.Rights, UserRights.userID,Priority FROM UserRights INNER JOIN Menu ON UserRights.menuID = Menu.MenuId WHERE (UserRights.userID = " + val + ") and Rights <> 'None' and Menu.DIS = 0 order by Priority";
    }
    const response = await getDataTable(Query)
    if (response.length > 0) {
      this.setState({ menuList: response, loading: false });
    }
    else {
      this.setState({ menuList: [], loading: false });
    }
  }

  async myFunction(e) {
    var input, filter, ul, li, a, i, txtValue;
    input = document.getElementById("myInput");
    filter = e.target.value.toUpperCase();
    ul = document.getElementById("myUL");
    li = ul.getElementsByTagName("li");
    for (i = 1; i < li.length; i++) {
        a = li[i].getElementsByTagName("a")[0];
        txtValue = a.textContent || a.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
            li[i].style.display = "";
        } else {
            li[i].style.display = "none";
        }
    }
}

  render() {
    // var {items , loading} = this.state;
    var it = this.state.menuItems;
    var list = this.state.menuList;
    const menuArray = Object.keys(it).map(i => it[i])
    const menuLists = Object.keys(list).map(k => list[k])
    const loading = this.state.loading;
    //console.log(menuArray);    
    if (loading) { return <span></span> }
    else {
      return (
        <div>
          <aside className="main-sidebar">
            <section className="sidebar">
              <div className="user-panel">
                <div className="pull-left image">
                  <img src="dist/img/avatar5.png" className="img-circle" alt="User" />
                </div>
                <div className="pull-left info">
                  <p>{Configuration.USER_NAME}</p>
                  {/* <p>{this.state.orgname}</p> */}
                  <a><i className="fa fa-circle text-success" />Online</a>
                </div>
              </div>

              <form  method="get" className="sidebar-form">
                <div className="input-group">
                  <input type="text" name="q" onKeyUp={this.myFunction.bind(this)} autoComplete='off' className="form-control" placeholder="Search..." />
                  <span className="input-group-btn">
                    <button type="submit" name="search" id="search-btn" className="btn btn-flat">
                      <i className="fa fa-search" />
                    </button>
                  </span>
                </div>
              </form>
              <ul className="sidebar-menu" id='myUL' data-widget="tree">
                <li className="header">MAIN NAVIGATION</li>
                {this.state.menuList.map((value, index) => {
                  return <li className="box-title small" key={index}><a href={"#/" + value.url}><i ></i>{value.MenuName}</a></li>
                })}
              </ul>
            </section>
          </aside>
        </div>
      )
    }
  }
}

export default Menu;