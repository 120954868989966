import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import { APIService } from "../../../Config/action/apiAction";
import { TokenService } from "../../../Config/action/tokenAction";
import PageTitle from "../../Common/pageTitle";
import { Spin, Table, Card, Button, Select, Space, Modal } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { getAuthorization } from "../../../Configuration";
import CryptoService from "../../../Config/action/crypto";
import { TextField } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function PricingList(props) {
  const { Option } = Select;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [columns, setColumns] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [organizationData, setOrganizationData] = useState([]);
  const [isAdmin, setIsAdmin] = useState(0);
  const [currencyData, setCurrencyData] = useState({
    name: "",
    symbol: "",
    value: "",
  });

  useEffect(() => {
    setLoading(true);
    setIsAdmin(CryptoService.decrypt(getAuthorization()).isAdmin);
    let payload = {
      query: "Exec PricingModule_GetOrgData",
    };
    props
      .dispatch(APIService("DYNAMIC_POST", "", payload))
      .then((data) => {
        setOrganizationData(data.data.data);
        if (data.data.data && data.data.data.length > 0) {
          // Calculating unique values
          let values = {};

          // Unique columns name
          let keys = Object.keys(data.data.data[0]);
          keys.map((col, index) => {
            data.data.data &&
              data.data.data.map((item, pos) => {
                if (values[col] && values[col].indexOf(item[col]) === -1) {
                  values[col].push(item[col]);
                } else if (!values[col]) {
                  values[col] = [item[col]];
                }
              });
          });

          // Defining dynamic columns
          let columns = [];
          keys.map((item, index) => {
            let payload = {
              title: item,
              dataIndex: item,
              key: item,
              ...getColumnSearchProps(item, values),
            };
            columns.push(payload);
          });
          columns.push({
            title: "Action",
            dataIndex: "",
            key: "x",
            render: (text, record) => (
              <button
                onClick={() =>
                  history.push(`/Pricing/organization/${record.OrgID}`)
                }
                className="btn btn-primary"
              >
                View
              </button>
            ),
          });
          setColumns(columns);
          // setTimeout(() => {
          setLoading(false);
          // }, 000);
        } else {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getColumnSearchProps = (dataIndex, val) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Select
          mode="multiple"
          // ref={(node) => {
          //    this.searchInput = node;
          // }}
          allowClear
          style={{ width: 188, marginBottom: 8, display: "block" }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys}
          onChange={(values) => setSelectedKeys([...values])}
        >
          {val &&
            val[dataIndex].map((key, index) => {
              return <Option key={key}>{key}</Option>;
            })}
        </Select>
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      if (record[dataIndex]) {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handleOk = (e) => {
    setLoading(true);
    e.preventDefault();
    e.stopPropagation();
    let userId = JSON.parse(localStorage.getItem("auth_user")).ID;
    let payload = {
      query: `Exec PricingModule_CreateCurrency @currencyname = ${currencyData.name}, @currencysymbol = "${currencyData.symbol}", @inrvalue = ${currencyData.value}, @loginID = ${userId}`,
    };
    props
      .dispatch(APIService("DYNAMIC_NEW_POST", "", payload))
      .then((data) => {
        setLoading(false);
        if (data.status === 200 && data.data.status === 1000) {
          setIsModalVisible(false);
          showToast("Currency created successfully");
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleChange = (e, key, value) => {
    e.stopPropagation();
    e.preventDefault();
    setCurrencyData({
      ...currencyData,
      [key]: value,
    });
  };

  const showToast = (message) => {
    toast(message, {
      position: "top-right",
      type: "success",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  };

  return (
    <div className="content-wrapper">
      <ToastContainer />
      <Modal
        title="New Currency"
        visible={isModalVisible}
        onOk={handleOk}
        okText={"Save"}
        onCancel={handleCancel}
      >
        <div className="row">
          <div
            className="d-flex inputClassHandler"
            style={{ marginBottom: "10px" }}
          >
            <div className="form-group" style={{ marginRight: "10px" }}>
              <input
                name="Currency Name"
                id="Currency Name"
                onChange={(e) => handleChange(e, "name", e.currentTarget.value)}
                value={currencyData.name}
                placeholder="Currency Name"
                tabIndex={1}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <input
                name="Currency Symbol"
                id="Currency Symbol"
                onChange={(e) =>
                  handleChange(e, "symbol", e.currentTarget.value)
                }
                value={currencyData.symbol}
                placeholder="Currency Symbol"
                tabIndex={1}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <input
                name="INR Value"
                id="INR Value"
                onChange={(e) =>
                  handleChange(e, "value", e.currentTarget.value)
                }
                value={currencyData.value}
                placeholder="INR Value"
                tabIndex={1}
                className="form-control"
              />
            </div>
          </div>
        </div>
      </Modal>
      <div className="loader" style={{ display: loading ? "block" : "none" }}>
        <div className="loader-item">
          <Spin />
        </div>
      </div>
      <section className="content">
        <div
          id="ctl00_CPHMaincontent_divclient"
          className="box box-success"
          style={{ marginTop: "-13px" }}
        >
          <div className="box-header with-border">
            <h3 className="box-title">
              Organization Listing
              {/* <sup>
                  (<span className="vcode">*</span> Fields are mandatory)
                </sup> */}
              <span
                id="ctl00_CPHMaincontent_DivOrdNo"
                style={{ display: "inline" }}
              ></span>
            </h3>
            <div className="pull-right">
              <>
                {isAdmin ? (
                  <>
                    <button
                      onClick={() => setIsModalVisible(true)}
                      style={{ marginRight: "10px" }}
                      className="btn btn-primary"
                    >
                      New Currency
                    </button>
                    <button
                      onClick={() => history.push("/Pricing/user")}
                      className="btn btn-primary"
                    >
                      Assign User
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      onClick={() => setIsModalVisible(true)}
                      className="btn btn-primary"
                    >
                      New Currency
                    </button>
                  </>
                )}
              </>
            </div>
          </div>
          <br /> <br />
          <div
            className="box-body"
            id="orderDetails"
            style={{ display: "block" }}
          >
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 margintop">
                {organizationData.length > 0 && organizationData != undefined && (
                  <Table
                    style={{
                      width: "100%",
                      cursor: "pointer",
                    }}
                    rowKey={(record) => record.OrganizationCode}
                    columns={columns}
                    dataSource={organizationData}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: () => {
      dispatch({
        type: "loginUser",
      });
    },
    logoutUser: () => {
      dispatch({
        type: "logoutUser",
      });
    },
    dispatch,
  };
};

export default connect(null, mapDispatchToProps)(withRouter(PricingList));
