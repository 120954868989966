import React, { Component} from 'react';
import './style.css';


// const sortTypes = {
// 	up: {
// 		class: 'sort-up',
// 		fn: (a, b) => a.net_worth - b.net_worth
// 	},
// 	down: {
// 		class: 'sort-down',
// 		fn: (a, b) => b.net_worth - a.net_worth
// 	},
// 	default: {
// 		class: 'sort',
// 		fn: (a, b) => a
// 	}
// };

const RenderRow = (props) =>{
    return props.keys.map((key, index)=>{ 
       
      if(key=="ProductID"){
        return <td data-title={props.data[key]} key={index} style={{backgroundColor:"#96FCB1"}} >{props.data[key]}</td>
      }
      else if(key=="ProductName"){
        return <td data-title={props.data[key]} key={index} style={{backgroundColor:"#96FCB1"}} >{props.data[key]}</td>
      }
      
        let dt = props.data[key]
       
        let pdt = dt.split(",")
        if (pdt[1] === "Alpha-numeric") {
          return <td><input type='text' defaultValue={pdt[2]} style={{minWidth:'100px'}} name={props.data["ProductID"]} id={pdt[0]} className='form-control' /></td>
      }
      if (pdt[1] === "url") {
          return <td><input type='url' defaultValue={pdt[2]} style={{minWidth:'100px'}} name={props.data["ProductID"]} id={pdt[0]} className='form-control' /></td>
      }
      else if (pdt[1] === "number") {
          return <td><input type='number' defaultValue={pdt[2]} style={{minWidth:'100px'}} name={props.data["ProductID"]} id={pdt[0]} className='form-control' /></td>
      }
      else if (pdt[1] === "date") {
        return <td><input type='date' defaultValue={pdt[2]} style={{minWidth:'100px'}} name={props.data["ProductID"]} id={pdt[0]} className='form-control' /></td>
    }
      else if (pdt[1] === "option") {
          return <td><select className='form-control' name={props.data["ProductID"]} style={{minWidth:'100px'}} id={pdt[0]} >
              <option value="">Select Value</option>
              {
                  pdt.map((opn, index) => {
                      if (index > 2) {
                        let d = opn.trim();
                      return (
                          <option value={d} selected={d === pdt[2]} key={index}>{d}</option>
                      )
                      }
                  })
              }
          </select></td>
      }
         // return <td data-title={props.data[key]} key={index}>{props.data[key]}</td>
          
        })
   }



const closest = function(el, selector, rootNode) {
  
    rootNode = rootNode || document.body;
    const matchesSelector =
      el.matches ||
      el.webkitMatchesSelector ||
      el.mozMatchesSelector ||
      el.msMatchesSelector;
    
    while (el) {
      const flagRoot = el === rootNode;
      
      if (flagRoot || matchesSelector.call(el, selector)) {
        if (flagRoot) {
          el = null;
           
        }
        
        break;
      }
      el = el.parentElement;
       
    }
     
    //el.setAttribute('style', 'border: 2px solid lightgray;');
    return el;
  };
export default  class Table extends Component {

    constructor(props) {
        super(props);
        this.state= {
          dragIndex: -1,
          draggedIndex: -1,
          currentSort:"default"
      };
    }

    getKeys = function(){
        return Object.keys(this.props.data[0]);
        }


    getHeader = function(){
      var keys = this.getKeys();
      return keys.map((key, index)=>{
     if (!key.startsWith("#")) {
      return <th  key={key}>
      {key.toUpperCase()}
      <div className="pull-right" style={{padding:'0px',width:'auto',display:'inline'}} key={key}>
     
      <input type="text" className="form-control input" style={{height:"17px",padding:'0px',width:'50px',display:'none'}}  
              id={"myInput"+index+1} onKeyUp={(e)=>this.myFunction(e,index+1)}  
               title={"TYPE IN "+key.toUpperCase()}></input>
               
               &nbsp;<span>
               <i id={"S"+index+1} className="fa fa-search" onClick={(e)=>this.ShoeFilterTextBox(e,index+1)}></i>
               <i id={"R"+index+1} className="fa fa-remove" 
               style={{display:'none',color:'red',fontSize:'15px' }} onClick={(e)=>this.hideFilterTextBox(e,index+1)}></i>
               </span>
           </div>    
      </th>
     }
      })
    }

    hideFilterTextBox(e,textBoxId){
      var table,th,SearchIcon,RemoveIcon,txtBox;
      table = document.getElementById(this.props.id);
      th = table.getElementsByTagName("th");

      for (let index = 1; index < th.length; index++) { 
        SearchIcon = th[index].getElementsByTagName("i")[0];
        RemoveIcon = th[index].getElementsByTagName("i")[1];
         txtBox = th[index].getElementsByTagName("input")[0];
          if(index===textBoxId){
            txtBox.value="";
            txtBox.style.display = "none";
            RemoveIcon.style.display = "none";
            SearchIcon.style.display = "inline";
          }  
             
      }
      this.onHideFilter(textBoxId)

    }

    ShoeFilterTextBox(e,textBoxId){
      var table,th,SearchIcon,RemoveIcon,txtBox;
      table = document.getElementById(this.props.id);
      th = table.getElementsByTagName("th");
      
      for (let index = 1; index < th.length; index++) {
     
          txtBox = th[index].getElementsByTagName("input")[0];
          SearchIcon = th[index].getElementsByTagName("i")[0];
          RemoveIcon = th[index].getElementsByTagName("i")[1];
           if(index===textBoxId){
             txtBox.style.display = "inline";
             RemoveIcon.style.display = "inline";
             SearchIcon.style.display = "none";
             txtBox.focus();
           } 
           else{
           txtBox.style.display = "none"; 
           RemoveIcon.style.display = "none";
           SearchIcon.style.display = "inline";
           }
        }
             
       

      
    }

    onHideFilter(colIndex) { 
      var  filter, table, tr, td, i, txtValue; 
      filter = "";
      table = document.getElementById(this.props.id);
      tr = table.getElementsByTagName("tr");
      for (i = 1; i < tr.length; i++) {
        td = tr[i].getElementsByTagName("td")[colIndex];
        if (td) {
          txtValue = td.textContent || td.innerText;
          if (txtValue.toUpperCase().indexOf(filter) > -1) {
            tr[i].style.display = "";
          } else {
            tr[i].style.display = "none";
          }
        }       
      }
    }

    myFunction(e,colIndex) { 
      var input, filter, table, tr, td, i, txtValue;
      input = document.getElementById("myInput");
      filter = e.target.value.toUpperCase();
      table = document.getElementById(this.props.id);
      tr = table.getElementsByTagName("tr");
      for (i = 1; i < tr.length; i++) {
        td = tr[i].getElementsByTagName("td")[colIndex];
        if (td) {
          txtValue = td.textContent || td.innerText;
          if (txtValue.toUpperCase().indexOf(filter) > -1) {
            tr[i].style.display = "";
          } else {
            tr[i].style.display = "none";
          }
        }       
      }
    }

    getRowsData = function(){ 
      var items = this.props.data;
      var keys = this.getKeys();
      return items.map((row, index)=>{
      return <tr draggable="false"
      onMouseDown={(e)=>this.props.Drag=="true"?this.onMouseDown:e} key={index}><td>{index+1} 
       &nbsp;&nbsp;{ this.props.Selection=="true" && <input type="checkbox" onChange={this.onCheckChange.bind(this,row)}></input>}     
      &nbsp;&nbsp;{ this.props.deleteRow=="YES" && <i className="fa fa-trash vcode" onClick={(e)=>this.deleteRow(index)}></i>}
      &nbsp;&nbsp;{ this.props.deleteRow1=="YES" && <i className="fa fa-trash vcode" onClick={(e)=>this.props.DeleteRowFn(row)}></i>}
      &nbsp;&nbsp;{ this.props.EditRow=="true" && <i className="fa fa-edit" style={{color:'green'}} onClick={(e)=>this.props.EditRowFn(row)}></i>}
                                            
      </td>
      <RenderRow key={index} data={row} keys={keys}/>
      
      </tr>
      })
    }


 onMouseDown=(e)=> {
    console.log('onMouseDown');
    const target = this.getTrNode(e.target);
    if (target) {
      target.setAttribute('draggable', true);
      target.ondragstart = this.onDragStart;
      target.ondragend = this.onDragEnd;
    }
  }

  onDragStart=(e)=> {
    console.log('onDragStart');
    const target = this.getTrNode(e.target);
    if (target) {
      //       e.dataTransfer.setData('Text', '');
      target.setAttribute('style', 'border: 2px solid lightgray;');
      e.dataTransfer.effectAllowed = 'move';
      console.log('target.parentElement:', target.parentElement);
      target.parentElement.ondragenter = this.onDragEnter;
      target.parentElement.ondragover = function(ev) {
        //         console.log('Tbody ondragover:',ev)
        //         ev.target.dataTransfer.effectAllowed = 'none'
        ev.preventDefault();
        return true;
      };
      const dragIndex = target.rowIndex - 1;
      console.log('dragIndex:', dragIndex);
      this.setState({ dragIndex, draggedIndex: dragIndex });
    }
  }

  onDragEnter=(e)=> {
    const target = this.getTrNode(e.target);
    this.setState({
      draggedIndex: target ? target.rowIndex - 1 : -1,
    });
  }

  onDragEnd=(e)=> {
    const target = this.getTrNode(e.target);
    if (target) {      
      target.setAttribute('draggable', false);
      target.setAttribute('style', 'border: none');
      target.ondragstart = null;
      target.ondragend = null;
      target.parentElement.ondragenter = null;
      target.parentElement.ondragover = null;
      this.changeRowIndex();
      
    }
  }

  getTrNode=(target)=> {
    //     console.log('dragContainer:', this.refs.dragContainer)
    //     return closest(target, 'tr', this.refs.dragContainer.tableNode);
    return closest(target, 'tr');
  }

  changeRowIndex() {
    const result = {};
    const currentState = this.state;
    console.log('currentState:', currentState);
    result.dragIndex = result.draggedIndex = -1;
    if (
      currentState.dragIndex >= 0 &&
      currentState.dragIndex !== currentState.draggedIndex
    ) {
      const { dragIndex, draggedIndex} = currentState;
      const data = this.props.data;
      const item = data.splice(dragIndex, 1)[0];
      data.splice(draggedIndex, 0, item);
      result.data = data;
      result.dragIndex = -1;
      result.draggedIndex = -1;
    }
    this.setState(result);
  }

  onSort(sortKey,event){
    const data = this.props.data;
    data.sort((a,b) => a[sortKey].localeCompare(b[sortKey]))
    this.setState({data})
  }

  onSortChange = () => {
		const { currentSort } = this.state;
		let nextSort;

		if (currentSort === 'down') nextSort = 'up';
		else if (currentSort === 'up') nextSort = 'default';
		else if (currentSort === 'default') nextSort = 'down';

		this.setState({
			currentSort: nextSort
		});
	};

  deleteRow(index){ 
    this.props.data.splice(index,1);
    this.forceUpdate();

}

onCheckChange(param,event){
  this.props.SelectFn(param,event)

}
        render() {
          
         
            return (
             
              <div> 
                
                                        {this.props.exportXL==="true" && this.props.id!==undefined &&
                <div className="pull-right" style={{marginTop:'-23px'}}>
                {/* <ReactHTMLTableToExcel
                    id={"btn" + this.props.id}
                    className="btn btn-info downloadBtn"
                    table={this.props.id}
                    filename={"table" + this.props.id}
                    sheet="Sheet" 
                    buttonText={<DownloadOutlined style={{fontSize:'14px'}}/>}
                    /> */}
                    </div>
                    } 
                
                <div className="table table-hover datatable table-responsive hover" style={{maxHeight:this.props.height,margin:0}}>
               
               {this.props.data.length >0 && this.props.data!=undefined &&
               
                <table  id={this.props.id} className="table table-hover table-responsive table-bordered" style={{marginBottom:'0px'}}>

                <thead style={{position:"sticky"}}>
                <tr><th>#</th>{this.getHeader()}</tr>
                </thead>
                <tbody>
                {this.getRowsData()}
                </tbody>
                </table>
         } </div>
                
                </div>
                  

            )}

}
