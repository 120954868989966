import React, { Component } from 'react';
import _, { keyBy } from 'lodash';
import { Configuration, MandatoryFormFields, getDataTable, json2xlsx, table2xlsx, sleep } from '../Configuration';

import { ProcessAIColor } from '../Mltfunc';
import '../ORDER/OrderMaster/style.css'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { Progress, Modal, Spin } from 'antd';
import 'antd/dist/antd.css';
let portaldata = []
//let uploadfiles = ''
let fields = { AttributeToBeShowToUser: 1 }
let Anglers = []
let SubCategories = []
export default class frmcolorcheck extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginId: 0,
            ImageData: [],
            loading: false,
            loadingspin: false,
            prs: '',
            ldsts: '',
            portals: [],
            portlsData: [],
            accesstoken: '',
            stImages: [],
            RejectedImage: [],
            finalImg: [],
            cmbdigit: 'All',
            cmbprsctype: '2',
            profileID: []
        };
    }
    async componentDidMount() {
       
    }

    myChangeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    async FileHandler(e) {
        this.setState({
            ImageData:[]
        })
        if (e.target.files.length > 40) {
            Swal.fire({
                position: 'top-end',
                icon: 'info',
                title: 'CAN NOT UPLOAD MORE THEN 350 IMAGES',
                showConfirmButton: false,
                timer: 1500
            })
            document.getElementById('imgUpload').value = ''
            return;
        }

        if (e.target.files.length > 0) {
            let st = e.target.files
            let img = []
            let RejImg = []
            for (let i = 0; i < st.length; i++) {
                let name = st[i].webkitRelativePath
                let sts = name.split("/");
                if (st[i].type.startsWith("image")) {
                        img.push({ "file": st[i]})
                }
            }
            this.setState({ stImages: img, RejectedImage: RejImg })
            document.getElementById('imgUpload').value = ''
        }

        else { this.setState({ stImages: [], RejectedImage: [] }) }
    }


    hideShowDiv(id, childId) {
        let div = document.getElementById(id);
        if (div !== null) {
            if (document.getElementById(id).style.display == "block") {
                document.getElementById(id).style.display = "none"
                let ele = document.getElementById(childId);
                ele.className = "fa fa-plus";
            }
            else {
                document.getElementById(id).style.display = "block"
                let ele = document.getElementById(childId);
                ele.className = "fa fa-minus";
            }
        }
    };


    // function To get Image Width and Height
    async GetImageDimension(src, type) {
        return new Promise((resolve, reject) => {
            let img = new Image()
            img.onload = () => resolve(img[type])
            img.onerror = reject
            img.src = src
        })
    }






    async CompleteProcess() {
        if (this.state.stImages.length < 1) {
            Swal.fire({
                position: 'top-end',
                icon: 'info',
                title: 'No Image Folder selected',
                showConfirmButton: false,
                timer: 1500
            })
            return;
        }
        this.setState({
            loading:true
        })
        let FinalImages = []
        let uploadfiles = this.state.groupedImg
        this.setState({
            loadingspin:true
        })
                let rs = await ProcessAIColor(
                    this.state.stImages
                    )
                if (rs !== undefined) {
                    const keys = Object.keys(rs);
                    keys.forEach((key, index) => {
                        FinalImages.push(rs[key])
                    });
                }
        this.setState({
            ImageData: FinalImages,
            loadingspin:false
        })

    }


    help() {
        Modal.info({
            title: 'INSTRUCTION WHILE IMAGE IDENTIFICATION',
            okText: 'CLOSE',
            width: '600px',
            closable: true,
            footer: null,
            bodyStyle: { maxHeight: '500px' },
            style: { padding: '0px' },
            centered: true,
            maskClosable: true,
            content: (
                <div>
                    <ul>
                        <li>Upload folder of images of a particular product</li>
                        <li>Check <strong>Auto Correction</strong> to resize images with processing</li>
                        <li>Images should be in <strong>JPEG,JPG</strong></li>
                        <li>Image background color should be <strong>white</strong></li>
                        <li><span className='vcode'>*</span>Indentification of Sub category Still under dovelopment</li>
                        <li>Uploaded images should be between 500kb - 2MB</li>
                        <li>Uploaded images should not be less the (800 X 800) dimension</li>
                        <li>User can not upload more then 350 images at once</li>
                    </ul>
                </div>
            )
        });
    }

    async downloadRectifiedImage() {
        let ImgData = this.state.ImageData
        for (let i = 0; i < ImgData.length; i++) {
                var a = document.createElement("a");
                a.href = ImgData[i]['data'];
                a.download = ImgData[i].image_file;
                a.click();
                await sleep(1000)
        }
    }




    render() {
        return (
            <div className="content-wrapper">
               
                <div className='loader' style={{ display: this.state.loadingspin ? "block" : "none" }}>
                    <div className='loader-item'>
                        <Spin />
                    </div>
                </div>
                <section className="content">
                    <div id="ctl00_CPHMaincontent_divclient" className="box box-success" style={{ marginTop: '-13px' }}>
                        <div className="box-header with-border">
                            <h3 className="box-title">IMAGE COLOR DETECTION
                                <span id="ctl00_CPHMaincontent_DivOrdNo" style={{ display: 'inline' }}>
                                </span>
                            </h3> 
                        </div>
                        <div className="box-body" id="orderDetails" style={{ display: 'block' }}>
                            <div className="row">
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                    <label>Select Image folder</label><span className='vcode'>*</span>
                                    <div className='form-group'>
                                        <input accept="image/*" name="imgUpload" webkitdirectory="" directory="" className="form-control" type="file" id="imgUpload" onChange={this.FileHandler.bind(this)} multiple />
                                    </div>
                                </div>
                                <div className='col-xs-6 col-sm-6 col-md-2 margintop'>
                                    <label>&nbsp;</label>
                                    <div className='form-group'>
                                        <input type='button' value='UPLOAD IMAGES' onClick={this.CompleteProcess.bind(this)} className='btn btn-block btn-primary' />
                                    </div>
                                </div>   
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop pull-right">
                                    {this.state.loadingspin === false && this.state.ImageData.length > 0 && <button onClick={this.downloadRectifiedImage.bind(this)} className='btn btn-block btn-success'>Download Processed Image</button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }

    async dataURLtoFile(dataurl, filename) {
 
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
            
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        
        return new File([u8arr], filename, {type:mime});
    }


   


}
