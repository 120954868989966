import CryptoJS from "../../../node_modules/crypto-js";

const CryptoService = {
    encrypt: function(value) {
        //inspect the value
        return CryptoJS.AES.encrypt(JSON.stringify(value), 'bLuM@ngOe$').toString();
    },
    
    decrypt: function(value) {
        //inspect the value
        var bytes = CryptoJS.AES.decrypt(value, 'bLuM@ngOe$');
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
};

export default CryptoService;