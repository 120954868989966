import React, {Component, Fragment} from 'react';
// import HistoryDetail from './historyDetail';
import HistoryBasic from './basic';

class History extends Component{
    render(){
        return(
            <Fragment>
                <div style={{backgroundColor:'#f4f6f9', minHeight: '100vh'}}>
                    <HistoryBasic />
                </div>
            </Fragment>
        )
    }
}

export default History;