import React, { Component } from "react";
import {
  Configuration,
  MandatoryFormFields,
  getDataTable,
} from "../Configuration";
import AntD from "../Actions/Controls/Table/AntDTable";
import { Spin } from "antd";
import "../ORDER/OrderMaster/style.css";
import "antd/dist/antd.css";
import AutoCompleteInput from "../Actions/Controls/AutoComplete/autoComplete";
import AutoCompleteCascad from "../Actions/Controls/AutoComplete/autoComplete.cascad";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import AutoCompletemulti from "../Actions/Controls/AutoComplete/autoCompleteMultipleCascad";


export default class frmDefaultValueMaster extends Component {
  constructor() {
    super();
    this.state = {
      loginId: 0,
      loading: false,
      adata: [],
      tempId: []
      //   defaultValue: "",
    };
  }

  async componentDidMount() {
    let rs = await getDataTable(
      "select orgid from users where userid = " + Configuration.USER_ID
    );
    await this.setState({
      loginId: Configuration.USER_ID,
      orgid: rs[0].orgid,
    });
    await this.getData();
  }

  myChangeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };


  //For selecting one option
  onAfterSelect(selectedOptions, id, name) {
    if (selectedOptions.length > 0) {
      this.setState({
        [id]: selectedOptions[0].DisplayId,
        [name]: selectedOptions[0].DisplayName,
      });
    } else {
      this.setState({
        [id]: "",
        [name]: ""
      });
    }
  }

  //For selecting multiple option
  async onAutoCOmpletMultiPleSelect(selectedOptions, name) {
    if (selectedOptions.length > 0) {
      this.setState({
        [name]: selectedOptions,
      });
    } else {
      this.setState({
        [name]: "",
      });
    }
  }

  async getData() {
    this.setState({ loading: true });

    let Que = "EXEC frmDefaultValueMaster_GetDefaultValueList";

    let rs = await getDataTable(Que);

    this.setState({ loading: false, adata: rs });
  }

  async SaveData() {
    this.setState({
      loading: true,
    });
    let MandatoryArray = [
      { "BLOCK": this.state.catid1 },
      { "portal": this.state.portalid },
      { "Portal Header": this.state.PNAME },
      { "Default Value": this.state.defaultvalue },
    ];
    let check = MandatoryFormFields(MandatoryArray);
    if (check === false) {
      this.setState({ loading: false, adata: [] });
      return false;
    }

    let tmpstr = "";
    for (let index = 0; index < this.state.tempId.length; index++) {
      var element = this.state.tempId[index];
      if (tmpstr === "") {
        tmpstr = element.DisplayId;
      } else {
        tmpstr = tmpstr + "," + element.DisplayId;
      }
    }
    if (tmpstr === "") {
      tmpstr = "0"
    }
    let que1 =
      "EXEC frmDefaultValueMaster_SaveAndGetDefaultValue " +
      "@blockid= " +
      this.state.catid1 +
      ", @pheader= '" +
      this.state.PNAME +
      "',  @portalid= " +
      this.state.portalid +
      ",  @pvalue= '" +
      this.state.defaultvalue +
      "',  @tempid= '" +
      tmpstr +
      "' , @loginId =" +
      this.state.loginId + " , @orgId = "+(this.state.fOrgID ? this.state.fOrgID : 0);

    let rs1 = await getDataTable(que1);

    await this.getData(this);
    await Swal.fire({
      icon: rs1[0]["tag"],
      title: rs1[0]["Status"],
      showConfirmButton: false,
      timer: 2000,
    });
    this.setState({ loading: false });
    this.forceUpdate();
  }

  async deleteRow(e) {
    if (
      this.state.loginId === "1" ||
      this.state.loginId === "10" ||
      this.state.loginId === "6"
    ) {
      let st = await Swal.fire({
        title: "ARE YOU SURE WANT TO DELETE?",
        showCancelButton: true,
        confirmButtonText: "YES",
        cancelButtonText: "NO",
      });
      if (!st.isConfirmed) {
        return false;
      }
      this.setState({
        loading: true,
      });

      let Que2 = ""
      let rs = []
      if(e['ORG'] === ""){
      Que2 =  "delete from DefaultValueMaster output deleted.* where TableID = " + e["#tableID"];
      rs = await getDataTable(Que2);
      }
      else{
        Que2 =  "delete from DefaultValueMasterOrgWise output deleted.* where TableID = " + e["#tableID"];
        rs = await getDataTable(Que2);
      }
      if (rs.length > 0) {

        Swal.fire({
          icon: "info",
          title: "Successfully Deleted",
          showConfirmButton: false,
          timer: 1500,
        });
        await this.getData()
      } else {
        alert("Some Error Occurred");
        this.setState({
          loading: false,
        });
      }
    } else {
      alert("You are not allowed to delete");
      return;
    }

  }


  render() {
    return (
      <div className="content-wrapper">
        <div
          className="loader"
          style={{ display: this.state.loading ? "block" : "none" }}
        >
          <div className="loader-item">
            <Spin />
          </div>
        </div>
        <section className="content">
          <div
            id="ctl00_CPHMaincontent_divclient"
            className="box box-success"
            style={{ marginTop: "-13px" }}
          >
            <div className="box-header with-border">
              <h3 className="box-title">
                Default Value Master
                <span
                  id="ctl00_CPHMaincontent_DivOrdNo"
                  style={{ display: "inline" }}
                ></span>
              </h3>
            </div>

            <div
              className="box-body"
              id="orderDetails"
              style={{ display: "block" }}
            >
              <div className="row">
                <div className="col-xs-6 col-sm-5 col-md-2 margintop">
                  <label>SELECT ORGANIZATION</label>
                  <AutoCompleteCascad
                    id="articleid"
                    frmNm="HEADER"
                    quryNm="FILLORGNIZATION"
                    db="IMAGEDB"
                    filter2=""
                    filterId2=''
                    filter1="UserID"
                    filterId1={this.state.loginId}
                    placeholder="Please Select Organization"
                    onAfterSelect={(e) => this.onAfterSelect(e, "fOrgID", "FOrgName")}
                    isValid={this.state.isValid}
                  ></AutoCompleteCascad>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                  <label>Select Block &nbsp;</label>
                  <span className="vcode">*</span>
                  <AutoCompleteInput
                    id="catid"
                    frmNm="FRMCATPRODUCTMASTER"
                    quryNm="FILLCAT"
                    db="IMAGEDB"
                    placeholder="Please Select Block"
                    onAfterSelect={(e) =>
                      this.onAfterSelect(e, "catid1", "catname1")
                    }
                    isValid={this.state.isValid}
                  ></AutoCompleteInput>
                </div>

                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                  <label>Select Portal &nbsp;</label>
                  <span className="vcode">*</span>
                  <AutoCompleteInput
                    id="portalid"
                    frmNm="FRMATTRIBUTEALIASE"
                    quryNm="FILLPORTAL"
                    db="IMAGEDB"
                    placeholder="Please Select Portal"
                    onAfterSelect={(e) =>
                      this.onAfterSelect(e, "portalid", "portalname")
                    }
                    isValid={this.state.isValid}
                  ></AutoCompleteInput>
                </div>

                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                  <label>Select Template &nbsp;</label>
                  <AutoCompletemulti
                    id="catid"
                    frmNm="FRMUPDATEATTRIBUTEALIASING"
                    quryNm="FILLTEMPLATE"
                    db="IMAGEDB"
                    filter1="PortalID"
                    filterId1={this.state.portalid}
                    filter2="CatID"
                    filterId2={this.state.catid1}
                    placeholder="Please Select Template"
                    onAfterSelect={(e) =>
                      this.onAutoCOmpletMultiPleSelect(e, "tempId")
                    }
                    isValid={this.state.isValid}
                  ></AutoCompletemulti>
                </div>

                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                  <label>Select Portal Attribute</label>
                  <span className="vcode">*</span>
                  <AutoCompleteCascad
                    id="AttribiteID"
                    frmNm="FRMDEFAULTVALUEMASTER"
                    quryNm="FILLPORTALATTRIBUTE"
                    db="IMAGEDB"
                    filter1="PortalID"
                    filterId1={this.state.portalid}
                    filter2=""
                    filterId2=""
                    placeholder="Please Select Attribute"
                    onAfterSelect={(e) =>
                      this.onAfterSelect(e, "PAID", "PNAME")
                    }
                  ></AutoCompleteCascad>
                </div>

                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                  <label>Default Value</label>
                  <span className="vcode">*</span>
                  <div className="form-group">
                    <input
                      name="defaultvalue"
                      id="defaultvalue"
                      defaultValue={this.state.defaultvalue}
                      value={this.state.defaultvalue}
                      onChange={this.myChangeHandler}
                      placeholder="Enter Default Value"
                      tabIndex={1}
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-sm-6 col-md-1 margintop">
                  <label>&nbsp;</label>
                  <div className="form-group">
                    <input
                      type="button"
                      value="Save"
                      onClick={this.SaveData.bind(this)}
                      className="btn btn-block btn-primary"
                    />
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12 margintop">
                  {this.state.adata.length > 0 &&
                    this.state.adata !== undefined && (
                      <AntD
                        data={[...this.state.adata]}
                        exportXL="true"
                        Delete="true"
                        DeleteFn={(e) => this.deleteRow(e)}

                      ></AntD>
                    )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
