import React, { Component } from "react";
import {
  Configuration,
  getDataTable,
  MandatoryFormFields,
  exceltodb,
  GetNewDrpSheet,
} from "../../Configuration";
import Table from "../../Actions/Controls/Table/AntDTable";
import "../../ORDER/OrderMaster/style.css";
import Swal, { swal } from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import AutoCompleteInput from "../../Actions/Controls/AutoComplete/autoComplete";
import AutoCompleteCascad from "../../Actions/Controls/AutoComplete/autoComplete.cascad";
import AutoCompletemulti from "../../Actions/Controls/AutoComplete/autoCompleteMultiple";
import { Spin } from "antd";
import "react-datepicker/dist/react-datepicker.css";
const dataBase = "IMAGEDB";
let tb = "";
let files = "";

export default class frmlotMasternew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginId: 0,
      loading: false,
      productList: [],
      orgid: "",
      LotId: "",
      merge: "0",
      ProductName: "",
      ArticleNumber: "",
      CountryId: [],
      StyleCode: "",
      BlockID: "",
      AID: "",
      VName1: "",
      List: [],
      selectedrows: [],
      status: "Not Live",
      PortalID: [],
      excelData: [],
      tblname: "",
      upload: 0,
    };
  }

  async componentDidMount() {
    let rs = await getDataTable(
      "SELECT orgid FROM users WHERE userid = " + Configuration.USER_ID
    );
    await this.setState({
      loginId: Configuration.USER_ID,
      orgid: rs[0].orgid,
    });
  }

  myChangeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleIncludeDiscountChange = (e) => {
    this.setState({
      includeDiscount: e.target.checked,
    });
  };

  myFileChangeHandler = (event) => {
    files = event.target.files[0];
  };

  onAfterSelect(selectedOptions, id, name, index = 0) {
    if (selectedOptions.length > 0) {
      this.setState({
        [id]: selectedOptions[0].DisplayId,
        [name]: selectedOptions[0].DisplayName,
      });
    } else {
      this.setState({
        [id]: "",
        [name]: "",
      });
    }
  }

  async onAutoCOmpletMultiPleSelect(selectedOptions, name) {
    if (selectedOptions.length > 0) {
      this.setState({
        [name]: selectedOptions,
      });
    } else {
      this.setState({
        [name]: [],
      });
    }
  }

  async selectedDataFN(e) {
    if (e.length > 0) {
      this.setState({ selectedrows: e });
    } else {
      this.setState({ selectedrows: [] });
    }
  }

  async GetProductData() {
    let MandatoryArray = [
      { Block: this.state.BlockID },
      { "Search Type": this.state.merge },
    ];

    let check = MandatoryFormFields(MandatoryArray);

    if (check === false) {
      this.setState({ loading: false });
      return false;
    }

    this.setState({
      productList: [],
      loading: true,
    });

    let st = [];
    let que = "";

    if (this.state.merge === "1") {
      que =
        " select ProductID as [#pid], ProductName, c.AttributeValue as article, e.AttributeValue as [#Mrp], ";
      que +=
        " l.AttributeValue as [#category] , g.AttributeValue as [#color] , ";
      que +=
        " h.AttributeValue as [#gender] , i.AttributeValue as [#Department], ";
      que +=
        " j.AttributeValue as [#sizing] , k.AttributeValue as [#unisex] from ";
      que +=
        " ProductMaster outer apply (select AttributeValue from ProductDetails ";
      que +=
        " where ProductID= ProductMaster.ProductID  and AttributeID = 245) c ";
      que += " outer apply (select AttributeValue from ProductDetails ";
      que +=
        " where ProductID = ProductMaster.ProductID and AttributeID = 41) e ";
      que += " outer apply (select AttributeValue from ProductDetails ";
      que +=
        " where ProductID = ProductMaster.ProductID and AttributeID = 854) l ";
      que += " outer apply (select AttributeValue from ProductDetails ";
      que +=
        " where ProductID = ProductMaster.ProductID and AttributeID = 848) g ";
      que += " outer apply (select AttributeValue from ProductDetails ";
      que +=
        " where ProductID = ProductMaster.ProductID and AttributeID = 851) h ";
      que += " outer apply (select AttributeValue from ProductDetails ";
      que +=
        " where ProductID = ProductMaster.ProductID and AttributeID = 852) i ";
      que += " outer apply (select AttributeValue from ProductDetails ";
      que +=
        " where ProductID = ProductMaster.ProductID and AttributeID = 867) j ";
      que += " outer apply (select AttributeValue from ProductDetails ";
      que +=
        " where ProductID = ProductMaster.ProductID and AttributeID = 874) k ";
      que += " where CategoryId = " + this.state.BlockID;
      que += " and OrgID = " + this.state.orgid;
      que += " and ProductName like '" + this.state.ProductName + "%' ";
      st = await getDataTable(que, "IMAGEDB");
    } else if (this.state.merge === "2") {
      que =
        " select productid as [#pid], ProductName , c.AttributeValue as article , e.AttributeValue as [#Mrp] , ";
      que +=
        " l.AttributeValue as [#category] , g.AttributeValue as [#color] , ";
      que +=
        " h.AttributeValue as [#gender] , i.AttributeValue as [#Department], ";
      que +=
        " j.AttributeValue as [#sizing], k.AttributeValue as [#unisex] from ";
      que +=
        " ProductMaster outer apply (select attributevalue from ProductDetails ";
      que +=
        " where ProductID = ProductMaster.ProductID and AttributeID = 245 )c ";
      que += " outer apply (select attributevalue from ProductDetails ";
      que +=
        " where ProductID = ProductMaster.ProductID and AttributeID = 41 )e ";
      que += " outer apply (select AttributeValue from ProductDetails ";
      que +=
        " where ProductID = ProductMaster.ProductID and AttributeID = 854) l ";
      que += " outer apply (select AttributeValue from ProductDetails ";
      que +=
        " where ProductID = ProductMaster.ProductID and AttributeID = 848) g ";
      que += " outer apply (select AttributeValue from ProductDetails ";
      que +=
        " where ProductID = ProductMaster.ProductID and AttributeID = 851) h ";
      que += " outer apply (select AttributeValue from ProductDetails ";
      que +=
        " where ProductID = ProductMaster.ProductID and AttributeID = 852) i ";
      que += " outer apply (select AttributeValue from ProductDetails ";
      que +=
        " where ProductID = ProductMaster.ProductID and AttributeID = 867) j ";
      que += " outer apply (select AttributeValue from ProductDetails ";
      que +=
        " where ProductID = ProductMaster.ProductID and AttributeID = 874) k ";
      que += " where OrgId = " + this.state.orgid;
      que += " and c.AttributeValue = '" + this.state.ArticleNumber + "'";
      st = await getDataTable(que, "IMAGEDB");
    } else if (this.state.merge === "3") {
      que =
        " select ProductMaster.productid as [#pid], ProductName , d.AttributeValue as article , c.AttributeValue as [#styleCode] , ";
      que = " e.AttributeValue as [#Mrp] , l.AttributeValue as [#category] , ";
      que = " g.AttributeValue as [#color] , h.AttributeValue as [#gender] , ";
      que =
        " i.AttributeValue as [#Department] , j.AttributeValue as [#sizing], ";
      que = " k.AttributeValue as [#unisex] from ";
      que =
        " ProductMaster outer apply (select ProductID , AttributeValue from ProductDetails ";
      que =
        " where ProductID = ProductMaster.ProductID and AttributeID = 95 )c outer apply ";
      que = " (select attributevalue from ProductDetails ";
      que = " where ProductID = c.ProductID and AttributeID = 245 )d ";
      que = " outer apply (select attributevalue from ProductDetails ";
      que =
        " where ProductID = ProductMaster.ProductID and AttributeID = 41 )e ";
      que += " outer apply (select AttributeValue from ProductDetails ";
      que +=
        " where ProductID = ProductMaster.ProductID and AttributeID = 854) l ";
      que = " outer apply (select AttributeValue from ProductDetails ";
      que =
        " where ProductID = ProductMaster.ProductID and AttributeID = 848) g ";
      que = " outer apply (select AttributeValue from ProductDetails ";
      que =
        " where ProductID = ProductMaster.ProductID and AttributeID = 851) h ";
      que = " outer apply (select AttributeValue from ProductDetails ";
      que =
        " where ProductID = ProductMaster.ProductID and AttributeID = 852) i ";
      que = " outer apply (select AttributeValue from ProductDetails ";
      que =
        " where ProductID = ProductMaster.ProductID and AttributeID = 867) j ";
      que = " outer apply (select AttributeValue from ProductDetails ";
      que =
        " where ProductID = ProductMaster.ProductID and AttributeID = 874) k ";
      que = " where OrgId = " + this.state.orgid;
      que = " and c.AttributeValue = '" + this.state.StyleCode + "'";
      st = await getDataTable(que, "IMAGEDB");
    } else if (this.state.merge === "4") {
      que =
        " SELECT distinct LotDetails.productID as [#pid], ProductMaster.ProductName , c.AttributeValue as article , ";
      que += " e.AttributeValue as [#Mrp] , l.AttributeValue as [#category] , ";
      que += " g.AttributeValue as [#color] , h.AttributeValue as [#gender] , ";
      que +=
        " i.AttributeValue as [#Department], j.AttributeValue as [#sizing], ";
      que +=
        " k.AttributeValue as [#unisex] FROM LotDetails INNER JOIN ProductMaster ON ";
      que += " LotDetails.productID = ProductMaster.ProductID ";
      que += " outer apply (select attributevalue from ProductDetails where ";
      que += " productID = ProductMaster.ProductID and AttributeID = 245)c ";
      que += " outer apply (select attributevalue from ProductDetails where ";
      que += " productID = ProductMaster.ProductID and AttributeID = 41)e ";
      que += " outer apply (select AttributeValue from ProductDetails ";
      que +=
        " where ProductID = ProductMaster.ProductID and AttributeID = 854) l ";
      que += " outer apply (select AttributeValue from ProductDetails ";
      que +=
        " where ProductID = ProductMaster.ProductID and AttributeID = 848) g ";
      que += " outer apply (select AttributeValue from ProductDetails ";
      que +=
        " where ProductID = ProductMaster.ProductID and AttributeID = 851) h ";
      que += " outer apply (select AttributeValue from ProductDetails ";
      que +=
        " where ProductID = ProductMaster.ProductID and AttributeID = 852) i ";
      que += " outer apply (select AttributeValue from ProductDetails ";
      que +=
        " where ProductID = ProductMaster.ProductID and AttributeID = 867) j ";
      que += " outer apply (select AttributeValue from ProductDetails ";
      que +=
        " where ProductID = ProductMaster.ProductID and AttributeID = 874) k ";
      que += " WHERE ProductMaster.CategoryId = " + this.state.BlockID;
      que += " and LotDetails.LotID = " + this.state.LotId;
      st = await getDataTable(que, "IMAGEDB");
    }

    this.setState({
      productList: st,
      loading: false,
    });

    // if (st.length > 0) {
    //   console.log("get Table");
    // } else {
    //   Swal.fire({
    //     position: "top-end",
    //     icon: "error",
    //     title: "Product Not Found",
    //     showConfirmButton: false,
    //     timer: 1500,
    //   });
    // }
  }

  async AddData() {
    if (this.state.selectedrows.length === 0) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Please select data then press add",
        showConfirmButton: false,
        timer: 1500,
      });
    }

    let dt = this.state.List;
    this.setState({ loading: true });

    for (var i = 0; i < this.state.selectedrows.length; i++) {
      if (dt.includes(this.state.selectedrows[i])) {
      } else {
        dt.push(this.state.selectedrows[i]);
      }
    }

    this.setState({
      List: dt,
      loading: false,
    });
  }

  async handleExportButtonClick() {
    let MandatoryArray = [
      { Category: this.state.BlockID },
      { LOT: this.state.LotId },
    ];
    let check = MandatoryFormFields(MandatoryArray);
    if (check == false) {
      return false;
    }
    this.setState({ loading: true });
    let que =
      "EXEC ExportMasterDataPricing @loginID = " +
      this.state.loginId +
      " ,@catid = " +
      this.state.BlockID +
      " ,@lotID = " +
      this.state.LotId;
    let rs = await getDataTable(que);
    debugger;
    await GetNewDrpSheet(this.state.catID, rs, "Master Data.xlsx");
    this.setState({ loading: false });
  }

  async UploadFile() {
    let MandatoryArray = [{ Category: this.state.BlockID }];
    let check = MandatoryFormFields(MandatoryArray);
    if (check == false) {
      return false;
    }

    tb = "";
    if (files) {
      this.setState({ loading: true });
      tb = await exceltodb(files, dataBase, this.state.loginId);
      debugger;
      if (tb) {
        let queCheck =
          "EXEC frmProductDetails_checkSKULimit @tblName = '" +
          tb +
          "' , @loginID = " +
          this.state.loginId;
        let rschk = await getDataTable(queCheck);
        if (rschk[0]["RES"] != "OK") {
          Swal.fire({
            icon: "error",
            title: rschk[0]["RES"],
            showConfirmButton: false,
            timer: 2000,
          });
          this.setState({ loading: false });
          return;
        }
        debugger;
        let Que =
          "EXEC SaveProductImportDetailsforTest @loginId = " +
          this.state.loginId +
          ",@tblname = '" +
          tb +
          "' , @categoryid=" +
          this.state.BlockID;
        let rs = await getDataTable(Que);

        if (Array.isArray(rs)) {
          if (rs.length > 0) {
            this.setState({
              productdetailsdata: rs,
              visible: true,
              loading: false,
            });
            await this.SaveData(this);
          } else {
            Swal.fire({
              icon: "error",
              title: "File not Imported Properly",
              showConfirmButton: false,
              timer: 1500,
            });
            //alert("File not contain Proper Data")
            this.setState({ loading: false });
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "File not Imported Properly",
            showConfirmButton: false,
            timer: 1500,
          });
          //alert("File not contain Proper Data")
          this.setState({ loading: false });
        }
        document.getElementById("tp1").value = "";
      } else {
        tb = "";
        this.setState({ loading: false });
        return;
      }
    } else {
      Swal.fire({
        icon: "info",
        title: "File not contain Proper Data",
        showConfirmButton: false,
        timer: 1500,
      });
      this.setState({ loading: false });
      tb = "";
      return false;
    }
  }

  async SaveData() {
    if (!tb) {
      Swal.fire({
        icon: "info",
        title: "File not contain Proper Data",
        showConfirmButton: false,
        timer: 1500,
      });
      //alert("No Data To Save")
    }

    let ltque =
      " SELECT ImportLotID, CAST(ImportLotNo as nvarchar)+'-'+ ImportLotName as LotName FROM  PriceLotMaster  ";
    ltque +=
      " OUTER APPLY ( select distinct CategoryId from PriceLotDetails inner join ProductMaster on  ";
    ltque +=
      " 	ProductMaster.ProductID = PriceLotDetails.ProductID where ImportLotID = PriceLotMaster.ImportLotID ) C (blckid) ";
    ltque +=
      " WHERE OrgID = " +
      this.state.orgid +
      " and (DATEDIFF(week,logDate, getDate())<10) and c.blckid =  " +
      this.state.BlockID;
    let lt = await getDataTable(ltque);
    debugger;
    let inputoption = {};
    lt.forEach((ele) => {
      inputoption[ele["ImportLotID"]] = ele["LotName"];
    });
    let ltname = "";
    let ltid = 0;
    let sel = 0;
    if (lt.length <= 0) {
      await Swal.fire({
        title: "ENTER LOT NAME",
        input: "text",
        allowOutsideClick: false,
      }).then(function (result) {
        if (result.isConfirmed) {
          ltname = result.value;
        }
      });
    } else {
      let mrgcnf = await Swal.fire({
        title: "Do you want to merge the data into existing lot?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        allowOutsideClick: false,
      });
      if (mrgcnf.isConfirmed) {
        sel = 1;
        await Swal.fire({
          title: "SELECT LOT NAME",
          input: "select",
          inputOptions: inputoption,
          allowOutsideClick: false,
        }).then(function (result) {
          if (result.isConfirmed) {
            ltid = result.value;
          }
        });
      } else {
        await Swal.fire({
          title: "ENTER LOT NAME",
          input: "text",
          allowOutsideClick: false,
        }).then(function (result) {
          if (result.isConfirmed) {
            ltname = result.value;
          }
        });
      }
    }

    if (sel == 0 && ltname == "") {
      Swal.fire({
        icon: "error",
        title: "Please Enter Lot Name",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }

    /////////////////////////////////////////////

    let Queery =
      "EXEC PriceLotcreateandmerge @loginid = " +
      this.state.loginId +
      ",@tblname = '" +
      tb +
      "' , @LotName = '" +
      ltname +
      "' , @Lotid = " +
      ltid;
    this.setState({ loading: true });
    let rss = await getDataTable(Queery);
    debugger;
    let Que =
      "EXEC SavePriceDataInMasterTable @loginid = " +
      this.state.loginId +
      ",@tblname = '" +
      tb +
      "'";
    let rs = await getDataTable(Que);
    if (rs) {
      Swal.fire({
        icon: "success",
        title: "Data Saved SuccessFully",

        showConfirmButton: false,
        timer: 1500,
      });
      //alert("Data Saved SuccessFully")
      this.setState({ loading: false });
    } else {
      Swal.fire({
        icon: "error",
        title: "SOME ERROR OCCURED",
        showConfirmButton: false,
        timer: 1500,
      });
      //alert("Data Saved SuccessFully")
      this.setState({ loading: false });
    }
  }

  render() {
    const { selectedTime, includeDiscount } = this.state;
    return (
      <div className="content-wrapper">
        <div
          className="loader"
          style={{ display: this.state.loading ? "block" : "none" }}
        >
          <div className="loader-item">
            <Spin />
          </div>
        </div>
        <section className="content">
          <div
            id="ctl00_CPHMaincontent_divclient"
            className="box box-success"
            style={{ marginTop: "-13px" }}
          >
            <div className="box-header with-border">
              <h3 className="box-title">
                IMPORT PRICING MASTER
                <span
                  id="ctl00_CPHMaincontent_DivOrdNo"
                  style={{ display: "inline" }}
                ></span>
              </h3>
            </div>
            <div
              className="box-body"
              id="LotMaster"
              style={{ display: "block" }}
            >
              <div className="row">
                <div className="col-xs-6 col-sm-6 col-md-1 margintop">
                  <label>SELECT BLOCK</label>
                  <span className="vcode">*</span>
                  <AutoCompleteCascad
                    id="id"
                    frmNm="FRMALLDATA"
                    quryNm="FILLORGBLOCKS"
                    db="IMAGEDB"
                    filter1="orgid"
                    filterId1={this.state.orgid}
                    filter2=""
                    filterId2=""
                    placeholder="Please Select Block"
                    onAfterSelect={(e) =>
                      this.onAfterSelect(e, "BlockID", "BlockName")
                    }
                  ></AutoCompleteCascad>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-1">
                  <label>SEARCH TYPE</label>
                  <span className="vcode">*</span>
                  <div className="form-group">
                    <select
                      name="merge"
                      onChange={this.myChangeHandler}
                      id=""
                      tabIndex={1}
                      className="form-control"
                    >
                      <option selected="true" disabled="disabled">
                        {" "}
                        No Selection{" "}
                      </option>
                      <option value="1">SKU CODE</option>
                      <option value="2">ARTICLE NUMBER</option>
                      <option value="3">STYLE CODE</option>
                      <option value="4">LOT</option>
                    </select>
                  </div>
                </div>
                <div
                  className="col-xs-6 col-sm-6 col-md-2 margintop"
                  style={{
                    display: this.state.merge === "1" ? "block" : "none",
                  }}
                >
                  <label>SKU CODE</label>
                  <div className="form-group">
                    <input
                      autoComplete="off"
                      name="ProductName"
                      id="ProductName"
                      onChange={this.myChangeHandler}
                      placeholder="Enter Product Name"
                      tabIndex={1}
                      className="form-control"
                    />
                  </div>
                </div>
                <div
                  className="col-xs-6 col-sm-6 col-md-2 margintop"
                  style={{
                    display: this.state.merge === "2" ? "block" : "none",
                  }}
                >
                  <label>ARTICLE NUMBER</label>
                  <div className="form-group">
                    <input
                      autoComplete="off"
                      name="ArticleNumber"
                      id="ArticleNumber"
                      onChange={this.myChangeHandler}
                      placeholder="Enter Article Number"
                      tabIndex={1}
                      className="form-control"
                    />
                  </div>
                </div>
                <div
                  className="col-xs-6 col-sm-6 col-md-2 margintop"
                  style={{
                    display: this.state.merge === "3" ? "block" : "none",
                  }}
                >
                  <label>STYLE CODE</label>
                  <div className="form-group">
                    <input
                      autoComplete="off"
                      name="StyleCode"
                      id="StyleCode"
                      onChange={this.myChangeHandler}
                      placeholder="Enter Style Code"
                      tabIndex={1}
                      className="form-control"
                    />
                  </div>
                </div>
                <div
                  className="col-xs-6 col-sm-6 col-md-2 margintop"
                  style={{
                    display: this.state.merge === "4" ? "block" : "none",
                  }}
                >
                  <label>SELECT LOT</label>
                  <AutoCompleteCascad
                    id="id"
                    name="SelectLot"
                    frmNm="FRMLOTMASTER"
                    quryNm="FILLLOT"
                    db="IMAGEDB"
                    filter1="OrgId"
                    filterId1={this.state.orgid}
                    filter2="CategoryId"
                    filterId2={this.state.BlockID}
                    placeholder="Select Lot"
                    onAfterSelect={(e) =>
                      this.onAfterSelect(e, "LotId", "LotName")
                    }
                    isValid={this.state.isValid}
                  ></AutoCompleteCascad>
                </div>
                <div className="col-xs-3 col-sm-3 col-md-1 margintop">
                  <label>&nbsp;</label>
                  <div className="form-group">
                    <button
                      type="button"
                      onClick={this.GetProductData.bind(this)}
                      className="btn btn-block btn-primary"
                      text=""
                    >
                      SEARCH NOW
                    </button>
                  </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-1 margintop">
                  <label>&nbsp;</label>
                  <div className="form-group">
                    <button
                      onClick={this.AddData.bind(this)}
                      className="btn  btn-block btn-primary"
                      text=""
                    >
                      ADD
                    </button>
                  </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                  <label>&nbsp;</label>
                  <div className="form-group">
                    <button
                      className="btn btn-success"
                      onClick={this.handleExportButtonClick.bind(this)}
                    >
                      {" "}
                      EXPORT TO EXCEL{" "}
                    </button>
                  </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                  <label>UPLOAD FILE</label>
                  <span className="vcode">*</span>
                  <div className="form-group">
                    <input
                      name="fldname"
                      type="file"
                      accept=".xls,.xlsx,.xlsm,.csv"
                      id="tp1"
                      onChange={this.myFileChangeHandler}
                      placeholder="Enter FieldName"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-1 margintop">
                  <label>&nbsp;</label>
                  <div className="form-group">
                    {
                      <input
                        type="button"
                        value="SAVE"
                        onClick={this.UploadFile.bind(this)}
                        className="btn btn-block btn-primary"
                      />
                    }
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-6 margintop">
                  {this.state.productList.length > 0 &&
                    this.state.productList != undefined && (
                      <Table
                        style="float: left"
                        data={[...this.state.productList]}
                        id="fielddata"
                        selected="true"
                        SelectedFn={(e) => this.selectedDataFN(e)}
                      ></Table>
                    )}
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 margintop">
                  {this.state.List.length > 0 && (
                    <Table
                      style="float: left"
                      id="ResultData"
                      data={[...this.state.List]}
                    ></Table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
