import React, {Component, Fragment} from 'react';
import NotificationDetail from './notificationDetail';

class Notifications extends Component{
    constructor(props){
        super();
    }
    render(){
        return(
            <Fragment>
                <div style={{backgroundColor:'#f4f6f9'}}>
                    <NotificationDetail setActiveNotification={this.props.setActiveNotification} />
                </div>
            </Fragment>
        )
    }
}

export default Notifications;