import { TextField } from "@material-ui/core";
import { Spin } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PageTitle from "../../Common/pageTitle";
import Button from "@material-ui/core/Button";
import { APIService } from "../../../Config/action/apiAction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "antd";

function BrandDetail(props) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState("");
  const [userDataId, setUserId] = useState(0);
  const [selectedBrand, setSelectedBrand] = useState(-1);
  const [brandList, setBrandList] = useState([]);
  const [newBrandModal, setNewBrandModal] = useState(false);
  const [newBrand, setNewBrand] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    defaultUseEffect();
  }, []);

  const defaultUseEffect = () => {
    // setLoading(true);
    let orgId = JSON.parse(localStorage.getItem("auth_user")).OrgId;
    // setUserId(userId);
    let payload = {
      query: `exec frmBrandMaster_GetBrandList @orgid = ${orgId}`,
    };
    props
      .dispatch(APIService("DYNAMIC_NEW_POST", "", payload))
      .then((data) => {
        setLoading(false);
        if (data.status === 200 && data.data.status === 1000) {
          setBrandList(data.data.data);
        } else {
          throw 500;
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const fetchPortals = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.currentTarget.value) {
      setLoading(true);
      let userId = JSON.parse(localStorage.getItem("auth_user")).ID;
      setSelectedBrand(e.currentTarget.value);
      let payload = {
        query: `frmBrandMaster_GetPortalBrandData @userid = ${userId}, @BrandID = ${e.currentTarget.value}`,
      };
      props
        .dispatch(APIService("DYNAMIC_NEW_POST", "", payload))
        .then((data) => {
          setLoading(false);
          if (data.status === 200 && data.data.status === 1000) {
            setData(data.data.data);
          } else {
            throw 500;
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const handleInputChange = (e) => {
    let list = [...data];
    list.map((item) => {
      if (item.portalid === parseInt(e.target.name)) {
        item.Brand = e.target.value;
      }
    });
    setData(list);
  };

  const handleSubmit = (e, portalid) => {
    e.preventDefault();
    e.stopPropagation();
    let currentObject = null;
    setLoading(true);
    data.map((item) => {
      if (item.portalid === portalid) {
        currentObject = item;
      }
    });
    if (currentObject != null) {
      let payload = {
        query: `frmBrandMaster_SaveBrandData @portalID=${currentObject.portalid} , @Brand='${currentObject.Brand}' , @userid = ${userDataId}, @BrandID = ${selectedBrand}`,
      };
      props
        .dispatch(APIService("DYNAMIC_NEW_POST", "", payload))
        .then((data) => {
          setLoading(false);
          if (data.status === 200 && data.data.status === 1000) {
            toast("Brand updated successfully", {
              position: "top-right",
              type: "success",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
            });
            defaultUseEffect();
          } else {
            throw 500;
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  const handleOk = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (newBrand) {
      setError(false);
      setLoading(true);
      let auth = JSON.parse(localStorage.getItem("auth_user"));
      console.log(auth);
      setSelectedBrand(e.currentTarget.value);
      let payload = {
        query: `exec frmBrandMaster_createNewBrand @orgid = ${auth.OrgId}, @Brand = '${newBrand}',@loginID = ${auth.ID}`,
      };
      props
        .dispatch(APIService("DYNAMIC_NEW_POST", "", payload))
        .then((data) => {
          setLoading(false);
          if (data.status === 200 && data.data.status === 1000) {
            toast("Brand created successfully", {
              position: "top-right",
              type: "success",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
            });
            setNewBrandModal(false);
            defaultUseEffect();
          } else {
            throw 500;
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      setError(true);
    }
  };

  const openModal = (e) => {
    setNewBrandModal(true);
  };


  const deleteBrand = (e) => {
    setLoading(true)

    
  };

  return (
    <div className="content-wrapper">
      <div className="loader" style={{ display: loading ? "block" : "none" }}>
        <div className="loader-item">
          <Spin />
        </div>
      </div>
      <section className="content" style={{ background: "white" }}>
        <div
          id="ctl00_CPHMaincontent_divclient"
          className="box box-success"
          style={{ marginTop: "-13px" }}
        >
          <div className="box-header with-border">
            <h3 className="box-title">
              Brand Details
              {/* <sup>
              (<span className="vcode">*</span> Fields are mandatory)
            </sup> */}
              <span
                id="ctl00_CPHMaincontent_DivOrdNo"
                style={{ display: "inline" }}
              ></span>
            </h3>
            <div className="pull-right" style={{ marginLeft: "10px" }}>
              <button onClick={() => openModal()} className="btn btn-primary">
                Create Brand
              </button>
            </div>{" "}
            <ToastContainer />
            <Modal
              title="Create Brand"
              visible={newBrandModal}
              onOk={handleOk}
              onCancel={() => setNewBrandModal(false)}
            >
              <div className="form-group" style={{ marginRight: "10px" }}>
                <input
                  name="Name"
                  id="Name"
                  onChange={(e) => setNewBrand(e.currentTarget.value)}
                  value={newBrand || ""}
                  // onChange={this.myChangeHandler}
                  placeholder="Enter Name"
                  tabIndex={1}
                  className="form-control"
                />
              </div>

              {error ? (
                <span style={{ color: "red", paddingTop: "30px" }}>
                  This field is required
                </span>
              ) : (
                <></>
              )}
            </Modal>
          </div>
          <div
            className="box-body"
            id="orderDetails"
            style={{ display: "block" }}
          >
            <label>Select Brand</label>
            <div className="row">
              <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                <select
                  className="currency-select"
                  value={selectedBrand}
                  style={{height: "30px"}}
                  onChange={(e) => fetchPortals(e)}
                >
                  <option value="">Select Brand</option>
                  {brandList.length > 0 && (
                    <Fragment>
                      {brandList.map((item, index) => {
                        return (
                          <option key={index} value={item.BrandID}>
                            {item.Brand}
                          </option>
                        );
                      })}
                    </Fragment>
                  )}
                </select>
                <div className="form-group"></div>
              </div>
              <div className="col-xs-6 col-sm-6 col-md-2 margintop">
              <button onClick={(e) => deleteBrand(e)}  className="btn btn-danger">
                Delete Brand
              </button>
              </div>
            </div>
          </div>
          <br />
          <div
            className="box-body"
            id="orderDetails"
            style={{ display: "block" }}
          >
            <div className="row">
              {data.length ? (
                <>
                  {" "}
                  {data.map((item, index) => (
                    <>
                      <div
                        key={index}
                        className="d-flex inputClassHandler"
                        style={{ marginBottom: "10px" }}
                      >
                        <div
                          className="form-group"
                          style={{ marginRight: "10px" }}
                        >
                          <input
                            type="text"
                            style={{
                              minWidth: "100px",
                              outline: "none",
                              fontWeight: "bold",
                            }}
                            readOnly
                            className="chargesType"
                            value={item.PortalName}
                          />
                        </div>
                        <div
                          className="form-group"
                          style={{ marginRight: "10px" }}
                        >
                          <input
                            name={item.portalid.toString()}
                            id="New Charge"
                            onChange={handleInputChange}
                            value={item.Brand || ""}
                            placeholder="Label"
                            tabIndex={1}
                            className="form-control"
                          />
                        </div>

                        <button
                          onClick={(e) => handleSubmit(e, item.portalid)}
                          style={{ marginLeft: "15px" }}
                          className="btn btn-primary"
                        >
                          Update
                        </button>
                      </div>
                    </>
                  ))}
                </>
              ) : null}

              {/* <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                <div className="form-group">
                  <button
                    //   onClick={(e) => addCharge(e)}
                    className="btn btn-primary"
                  >
                    Create
                  </button>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: () => {
      dispatch({
        type: "loginUser",
      });
    },
    logoutUser: () => {
      dispatch({
        type: "logoutUser",
      });
    },
    dispatch,
  };
};

export default connect(null, mapDispatchToProps)(withRouter(BrandDetail));
