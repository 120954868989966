import React, {Component, Fragment} from 'react';
import TroubleTicketDetail from './troubleTicket';

class TroubleTicket extends Component{
    render(){
        return(
            <Fragment>
                <div style={{backgroundColor:'#f4f6f9', minHeight: '100vh'}}>
                    <TroubleTicketDetail />
                </div>
            </Fragment>
        )
    }
}

export default TroubleTicket;