import React, { Component } from "react";
import { connect } from "react-redux";
import { APIService } from "../../../Config/action/apiAction";
// import TablePage from './table';
import "antd/dist/antd.css";
import { AutoComplete, Button, Table } from "antd";
import moment from "moment";
import { TokenService } from "../../../Config/action/tokenAction";
import Highlighter from "react-highlight-words";
import { Input, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Select } from "antd";
import PageTitle from "../../Common/pageTitle";
import { Spin } from "antd";

const { Option } = Select;

class HistoryBasic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      columns: [],
      searchText: "",
      searchedColumn: "",
      columnValues: {},
      loading: false,
    };
  }

  componentDidMount() {
    this.setState(
      {
        loading: true,
      },
      () => {
        this.props
          .dispatch(TokenService("getToken", "auth_user"))
          .then((token) => {
            this.props
              .dispatch(
                APIService(
                  "POST",
                  "GetPaymentData?query=PAYMENT_HISTORY @ID = " + token.ID
                )
              )
              .then((data) => {
                if (data.data.status === 1000) {
                  this.setState(
                    {
                      data: data.data.data,
                    },
                    () => {
                      if (this.state.data.length > 0) {
                        // Calculating unique values
                        let values = {};

                        // Unique columns name
                        let keys = Object.keys(this.state.data[0]);
                        // keys.map((i) => {
                        //     values[i] = [];
                        // })

                        keys.map((col, index) => {
                          this.state.data.map((item, pos) => {
                            // if(!values[col].includes(item[col])){
                            if (
                              values[col] &&
                              values[col].indexOf(item[col]) === -1
                            ) {
                              values[col].push(item[col]);
                            } else if (!values[col]) {
                              values[col] = [item[col]];
                            }
                          });
                        });

                        this.setState(
                          {
                            ...this.state,
                            columnValues: values,
                          },
                          () => {
                            // Defining dynamic columns
                            let columns = [];
                            keys.map((item, index) => {
                              let payload = {
                                title: item,
                                dataIndex: item,
                                key: item,
                                ...this.getColumnSearchProps(item),
                              };
                              columns.push(payload);
                            });
                            this.setState({
                              ...this.state,
                              columns: columns,
                              loading: false,
                            });
                          }
                        );
                      }
                    }
                  );
                }
              })
              .catch((e) => {
                this.setState({
                  loading: false,
                });
                console.log(e);
              });
          });
      }
    );
  }

  onChange(pagination, filters, sorter, extra) {
    // console.log('params', pagination, filters, sorter, extra);
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Select
          mode="multiple"
          ref={(node) => {
            this.searchInput = node;
          }}
          allowClear
          style={{ width: 188, marginBottom: 8, display: "block" }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys}
          onChange={(values) => setSelectedKeys([...values])}
        >
          {this.state.columnValues[dataIndex].map((key, index) => {
            return <Option key={key}>{key}</Option>;
          })}
        </Select>
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      if (record[dataIndex]) {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    },
    //   record[dataIndex]
    //     ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
    //     : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={
            this.state.searchText && this.state.searchText.length > 0
              ? this.state.searchText
              : []
          }
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys,
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  render() {
    return (
      <div className="content-wrapper">
        <div
          className="loader"
          style={{ display: this.state.loading ? "block" : "none" }}
        >
          <div className="loader-item">
            <Spin />
          </div>
        </div>
        <section className="content">
          <div
            id="ctl00_CPHMaincontent_divclient"
            className="box box-success"
            // style={{ marginTop: "-13px" }}
          >
            <div className="box-header with-border">
              <h3 className="box-title">
                History Listing
                {/* <sup>
                 (<span className="vcode">*</span> Fields are mandatory)
               </sup> */}
                <span
                  id="ctl00_CPHMaincontent_DivOrdNo"
                  style={{ display: "inline" }}
                ></span>
              </h3>
            </div>
            <br /> <br />
            <div
              className="box-body"
              id="orderDetails"
              style={{ display: "block" }}
            >
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 margintop">
                  {this.state.data.length > 0 &&
                    this.state.data != undefined && (
                      <Table
                        style={{ width: "100%" }}
                        rowKey={(record) => record.ORDERID}
                        columns={this.state.columns}
                        dataSource={this.state.data}
                        onChange={this.onChange}
                      />
                    )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: () => {
      dispatch({
        type: "loginUser",
      });
    },
    logoutUser: () => {
      dispatch({
        type: "logoutUser",
      });
    },
    dispatch,
  };
};

export default connect(null, mapDispatchToProps)(HistoryBasic);
