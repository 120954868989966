import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { span, withRouter, useHistory } from "react-router-dom";
import { APIService } from "../../../Config/action/apiAction";
import PageTitle from "../../Common/pageTitle";
import { Spin, Table, Card, Button, Modal, Select, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
// import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function UserListing(props) {
  const { Option } = Select;

  const [columns, setColumns] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [userData, setUserData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [currentUser, setCurrentUser] = useState(-1);
  const [allUserList, setAllUserList] = useState([]);
  const [userError, setUserError] = useState(false);

  const history = useHistory();
  useEffect(() => {
    fetchAllUserList();
  }, []);

  const defaultUseEffect = () => {
    setLoading(true);
    let payload = {
      query: "Exec PricingModule_GetAllUserList",
    };
    props
      .dispatch(APIService("DYNAMIC_POST", "", payload))
      .then((data) => {
        setUserData(data.data.data);
        if (data.data.data && data.data.data.length > 0) {
          // Calculating unique values
          let values = {};

          // Unique columns name
          let keys = Object.keys(data.data.data[0]);

          keys.map((col, index) => {
            data.data.data &&
              data.data.data.map((item, pos) => {
                if (values[col] && values[col].indexOf(item[col]) === -1) {
                  values[col].push(item[col]);
                } else if (!values[col]) {
                  values[col] = [item[col]];
                }
              });
          });

          // Defining dynamic columns
          let columns = [];
          keys.map((item, index) => {
            let payload = {
              title: item,
              dataIndex: item,

              key: item,
              ...getColumnSearchProps(item, values),
            };
            columns.push(payload);
          });
          columns.push({
            title: "Action",
            dataIndex: "",
            key: "x",
            render: (text, record) => (
              <button
                onClick={(e) => handleDelete(e, record)}
                className="btn btn-danger"
              >
                Remove
              </button>
            ),
          });
          setColumns(columns);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        } else {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const fetchAllUserList = async () => {
    setLoading(true);
    let payload = {
      query: "Exec PricingModule_GetUserList",
    };
    let result = await props.dispatch(APIService("DYNAMIC_POST", "", payload));
    setLoading(false);
    if (result.status === 200 && result.data.status === 1000) {
      setAllUserList(result.data.data);
      defaultUseEffect();
    }
  };

  const getColumnSearchProps = (dataIndex, val) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Select
          mode="multiple"
          // ref={(node) => {
          //    this.searchInput = node;
          // }}
          allowClear
          style={{ width: 188, marginBottom: 8, display: "block" }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys}
          onChange={(values) => setSelectedKeys([...values])}
        >
          {val &&
            val[dataIndex].map((key, index) => {
              return <Option key={key}>{key}</Option>;
            })}
        </Select>
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      if (record[dataIndex]) {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    },
  });
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const showModal = () => {
    setCurrentUser(-1);
    setIsModalVisible(true);
  };

  const handleOk = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (currentUser >= 0) {
      setLoading(true);
      setUserError(false);
      let payload = {
        query: `Exec PricingModule_GrantAccess @UserID = ${currentUser}`,
      };
      props
        .dispatch(APIService("DYNAMIC_POST", "", payload))
        .then((data) => {
          setLoading(false);
          if (data.status === 200 && data.data.status === 1000) {
            defaultUseEffect();
            handleCancel();
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else {
      setUserError(true);
    }
  };

  const handleDelete = (e, record) => {
    e.preventDefault();
    setCurrentUser(record.UserId);
    setOpen(true);
  };

  const handleDeleteConfirm = () => {
    setLoading(true);
    let payload = {
      query: `Exec PricingModule_RemoveAccess @UserID = ${currentUser}`,
    };
    props
      .dispatch(APIService("DYNAMIC_POST", "", payload))
      .then((data) => {
        setLoading(false);
        if (data.status === 200 && data.data.status === 1000) {
          defaultUseEffect();
          handleClose();
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleClose = () => {
    setCurrentUser(-1);
    setOpen(false);
  };

  const handleCancel = () => {
    setUserError(false);
    setCurrentUser(-1);
    setIsModalVisible(false);
  };

  return (
    <div className="content-wrapper">
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Warning</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure, you want to remove the access for this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            style={{ fontSize: "12px" }}
          >
            No
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            color="primary"
            style={{ fontSize: "12px" }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <div className="loader" style={{ display: loading ? "block" : "none" }}>
        <div className="loader-item">
          <Spin />
        </div>
      </div>
      <section className="content">
        <div
          id="ctl00_CPHMaincontent_divclient"
          className="box box-success"
          style={{ marginTop: "-13px" }}
        >
          <div className="box-header with-border">
            <h3 className="box-title">
              User Listing
              {/* <sup>
               (<span className="vcode">*</span> Fields are mandatory)
             </sup> */}
              <span
                id="ctl00_CPHMaincontent_DivOrdNo"
                style={{ display: "inline" }}
              ></span>
            </h3>
            <div className="pull-right">
              <button
                onClick={() => history.push("/Pricing/organization")}
                style={{ marginRight: "10px" }}
                className="btn btn-primary"
              >
                Go Back
              </button>
              <button onClick={() => showModal()} className="btn btn-primary">
                Add User
              </button>
            </div>
          </div>
          <br /> <br />
          <div
            className="box-body"
            id="orderDetails"
            style={{ display: "block" }}
          >
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 margintop">
                {userData.length > 0 && userData != undefined && (
                  <Table
                    style={{ width: "100%" }}
                    rowKey={(record) => record.UserName}
                    columns={columns}
                    dataSource={userData}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        title="Grant Access"
        visible={isModalVisible}
        onOk={handleOk}
        okText={"Save"}
        onCancel={handleCancel}
      >
        <Autocomplete
          id="select-user"
          options={allUserList}
          getOptionLabel={(option) => {
            return `${option.username} - ${option.Email}`;
          }}
          onChange={(e, value) => {
            if (value) {
              setCurrentUser(value.userId);
            } else {
              setCurrentUser(-1);
            }
          }}
          style={{ width: "100%", fontSize: "15px" }}
          renderInput={(params) => (
            <TextField {...params} label="Select User" variant="outlined" />
          )}
        />
        {userError && (
          <small className="errClass">
            Please select a user <br />
          </small>
        )}
        <small>
          <b>Note:</b> Clicking on save will give pricing module access to the
          selected user.
        </small>
      </Modal>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: () => {
      dispatch({
        type: "loginUser",
      });
    },
    logoutUser: () => {
      dispatch({
        type: "logoutUser",
      });
    },
    dispatch,
  };
};

export default connect(null, mapDispatchToProps)(withRouter(UserListing));
