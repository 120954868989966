
import React, { useEffect } from 'react';
import { Fragment } from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { span, withRouter } from 'react-router-dom';
import { APIService } from '../../../Config/action/apiAction';
import { TokenService } from '../../../Config/action/tokenAction';
import { Configuration } from '../../../Configuration';
import PageTitle from '../../Common/pageTitle';
import { Spin } from 'antd';
let userid = Configuration.userid
class CategoryDetail extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            plans: null,
            user: null,
            loading: false,
            userid : Configuration.USER_ID
            // JSON.parse(localStorage.getItem('auth_user'))
        }
    }

    componentDidMount(){
        this.setState({
            loading: true
        }, () => {
            this.props.dispatch(TokenService("getToken", "auth_user")).then((token) => {
                this.props.dispatch(APIService('POST', 'GetPaymentData?query=GET_USER_PLAN @userID = '+this.state.userid, "")).then((data) => {
                    console.log(data);
                    if(data.data.status === 1000){
                        this.setState({
                            plans: data.data.data,
                            user: token,
                            loading: false
                        }, () => {
                            let plans = this.state.plans;
                            plans.sort(function(a, b){return b.Price - a.Price});
                            console.log(plans);
                            this.setState({ plans });
                        })
                    }
                }).catch((e) => {
                    console.log(e);
                })
            })
        })
    }
    
    payment = (item) => {
        let payment = {
            name: this.state.user.USERNAME,
            email: this.state.user.Email,
            company: this.state.user.ORGANIZATION,
            amount: item.ChargePrice,
            category: item.PlanName,
            planId: item["PlanID"]
        }
        sessionStorage.setItem('transactionDetails', JSON.stringify(payment));
        this.props.history.push('/Checkout');
    }

    render(){
        return (
            <div className="content-wrapper">
                <div className='loader' style={{ display: this.state.loading ? "block" : "none" }}>
                    <div className='loader-item'>
                        <Spin />
                    </div>
                </div>
                <PageTitle title={"Category"} />
                <section className="content" style={{padding:'20px'}}>
                    <div className="pricing1 py-5 bg-light">
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <div className="col-md-12 text-center">
                                    <h3 className="mt-3 font-weight-medium mb-1" style={{fontWeight:'bold', fontSize:'29px'}}>Plans to make your Work Effective</h3>
                                </div>
                            </div>
                            <div className="row mt-5">
                                
                                {
                                    (this.state.plans && this.state.plans.length > 0) ? (
                                        <Fragment>
                                            {
                                                this.state.plans.map((item, index) => {
                                                    return (
                                                        <div key={index} className="col-lg-4 col-md-4" style={{padding:'15px'}}>
                                                            <div className="card text-center card-shadow on-hover border-0 mb-4">
                                                                <div className="card-body font-14" style={{padding:'25px'}}>
                                                                    <h5 className="mt-3 mb-1 font-weight-medium" style={{fontWeight:'bold', fontSize:'30px'}}>{item.PlanName}</h5>
                                                                    {/* <h6 className="subtitle font-weight-normal">For Team of 3-5 Members</h6> */}
                                                                    <div className="pricing my-3" style={{marginTop:'30px', marginBottom:'30px'}}>
                                                                        <sup style={{color:'black'}}>&#x20B9;</sup>
                                                                        <span className="monthly display-5">{item.Price}</span>
                                                                        <small className="monthly" style={{color:'grey'}}>/ {item.timeDuration}</small><br/>
                                                                        <small className="monthly" style={{color:'grey'}}>GST+convenience fee excluded</small>
                                                                    </div>
                                                                    {
                                                                        item.PlanName === this.state.user["ACTIVE PLAN"] ? (
                                                                            <div className="bottom-btn">
                                                                                <span className="btn btn-danger-gradiant btn-md text-white btn-block" onClick={() => this.payment(item)}><span style={{color:'white'}}>Current Plan</span></span>
                                                                            </div>
                                                                        ) : (
                                                                            <div className="bottom-btn">
                                                                                <span className="btn btn-success-gradiant btn-md text-white btn-block" onClick={() => this.payment(item)}><span style={{color:'white'}}>Choose Plan</span></span>
                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Fragment>
                                    ) : null
                                }
                                
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: () => {
            dispatch({
                type: 'loginUser'
            });
        },
        logoutUser: () => {
            dispatch({
                type: 'logoutUser'
            });
        },
        dispatch
    }
}

export default connect(null, mapDispatchToProps)(withRouter(CategoryDetail));