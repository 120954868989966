import { Modal } from 'antd';
import React, { Component } from 'react';
import '../ORDER/OrderMaster/style.css'
import { Configuration, getDataTable, GetCatogoryAttributeValuesYoox } from '../Configuration';
import Swal, { swal } from 'sweetalert2/dist/sweetalert2.js'
import { Spin } from 'antd';
import 'sweetalert2/src/sweetalert2.scss'
import _, { bind } from 'lodash';
const dataBase = "IMAGEDB";

export default class frmGetDetailsYoox extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            modal1Visible: false,
            modal2Visible: false,
            loading: false,
        };
    }

    async componentDidMount() {
        let rs = await getDataTable("select orgid from users where userid = " + Configuration.USER_ID)
        await this.setState({
            loginId: Configuration.USER_ID,
            orgid: rs[0].orgid
        });
    }


    setModal1Visible(modal1Visible) {
        this.setState({ modal1Visible });
    }
    
    setModal2Visible(modal2Visible) {
        this.setState({ modal2Visible, adata: [] });
    }

    onAfterSelect(selectedOptions, id, name) {
        if (selectedOptions.length > 0) {
            this.setState({
                [id]: selectedOptions[0].DisplayId,
                [name]: selectedOptions[0].DisplayName,
            })
        }
        else {
            this.setState({
                [id]: '',
                [name]: '',
            })
        }
    }

    async fetchCategoryData() {
        this.setState({ loading: true })

        let url = "https://richemontit3-dev.mirakl.net/api/hierarchies"
        try {
            let rs1 = await GetCatogoryAttributeValuesYoox(url);
    
            if (rs1 && rs1.hierarchies && Array.isArray(rs1.hierarchies)) {
                let csvContent = "code,label\n";
                rs1.hierarchies.forEach(item => {
                    csvContent += `"${item.code}","${item.label}"\n`;
                });
    
                const blob = new Blob([csvContent], { type: 'text/csv' });
    
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'Catalog_category.csv';
    
                document.body.appendChild(link);
                link.click();
    
                document.body.removeChild(link);
                await Swal.fire({
                    icon: 'success',
                    title: 'File Downloaded Successfully.',
                    showConfirmButton: false,
                    timer: 2500,
                });
                this.setState({ loading: false })

                return 'Data exported to CSV successfully!';
            } else {
                console.error('Invalid response structure:', rs1);
    
                await Swal.fire({
                    icon: 'error',
                    title: 'Error exporting data - Invalid response structure',
                });
                this.setState({ loading: false })

                return 'Error exporting data - Invalid response structure';
            }
        } catch (error) {
            console.error('Error fetching or exporting data:', error);
    
            await Swal.fire({
                icon: 'error',
                title: 'Error exporting data',
            });
            this.setState({ loading: false })

            return 'Error exporting data';
        }
    }


    async fetchAttributeData() {
        this.setState({ loading: true })

        let url = "https://richemontit3-dev.mirakl.net/api/products/attributes"
        try {
            let rs1 = await GetCatogoryAttributeValuesYoox(url);
    
            if (rs1 && rs1.attributes && Array.isArray(rs1.attributes)) {
                let csvContent = "code,default_value,description,example,hierarchy_code,label\n";
                rs1.attributes.forEach(item => {
                    csvContent += `"${item.code}","${item.default_value}","${item.description}","${item.example}","${item.hierarchy_code}","${item.label}"\n`;
                });
    
                const blob = new Blob([csvContent], { type: 'text/csv' });
    
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'Catalog_attributes.csv';
    
                document.body.appendChild(link);
                link.click();
    
                document.body.removeChild(link);
                await Swal.fire({
                    icon: 'success',
                    title: 'File Downloaded Successfully.',
                    showConfirmButton: false,
                    timer: 2500,
                });
                this.setState({ loading: false })

                return 'Data exported to CSV successfully!';
            } else {
                console.error('Invalid response structure:', rs1);
    
                await Swal.fire({
                    icon: 'error',
                    title: 'Error exporting data - Invalid response structure',
                });
                this.setState({ loading: false })

                return 'Error exporting data - Invalid response structure';
            }
        } catch (error) {
            console.error('Error fetching or exporting data:', error);
    
            await Swal.fire({
                icon: 'error',
                title: 'Error exporting data',
            });
            this.setState({ loading: false })

            return 'Error exporting data';
        }
    }


    async fetchValueseData() {
        this.setState({ loading: true })
        let url = "https://richemontit3-dev.mirakl.net/api/values_lists";
    
        try {
            let rs1 = await GetCatogoryAttributeValuesYoox(url);
    
            if (rs1 && rs1.values_lists && Array.isArray(rs1.values_lists)) {
                let csvContent = "code,label\n";
    
                rs1.values_lists.forEach(list => {
                    if (list.values && Array.isArray(list.values)) {
                        list.values.forEach(item => {
                            csvContent += `"${item.code}","${item.label}"\n`;
                        });
                    }
                });
    
                const blob = new Blob([csvContent], { type: 'text/csv' });
    
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'Catalog_Values.csv';
    
                document.body.appendChild(link);
                link.click();
    
                document.body.removeChild(link);
    
                await Swal.fire({
                    icon: 'success',
                    title: 'File Downloaded Successfully.',
                    showConfirmButton: false,
                    timer: 2500,
                });
    
                this.setState({ loading: false })

    
                return 'Data exported to CSV successfully!';
            } else {
                console.error('Invalid response structure:', rs1);
    
                await Swal.fire({
                    icon: 'error',
                    title: 'Error exporting data - Invalid response structure',
                });
    
                this.setState({ loading: false })

    
                return 'Error exporting data - Invalid response structure';
            }
        } catch (error) {
            console.error('Error fetching or exporting data:', error);
    
            await Swal.fire({
                icon: 'error',
                title: 'Error exporting data',
            });
    
            this.setState({ loading: false })

    
            return 'Error exporting data';
        }
    }


    render() {
        return (
            <div>
                <button type="button" onClick={() => this.setModal2Visible(true)} className="btn btn-primary">GET DATA FROM YOOX</button>
                <Modal
                    title="GET DATA FROM YOOX"
                    width={this.props.width}
                    visible={this.state.modal2Visible}
                    onOk={() => this.setModal2Visible(false)}
                    onCancel={() => this.setModal2Visible(false)}
                    destroyOnClose={true}
                    maskClosable={false}
                    footer={null}
                >
                <div className="box-body" id="orderDetails" style={{ display: 'block' }}>
                <div className='loader' style={{ display: this.state.loading ? "block" : "none" }}>
                            <div className='loader-item'>
                                <Spin />
                            </div>
                        </div>
                    <div className='row'>
                    <div className="col-xs-6 col-sm-6 col-md-3 margintop" >
                                    <label>&nbsp;</label>
                                    <div className='form-group'>
                                        <button className='btn btn-block btn-primary' onClick={this.fetchCategoryData.bind(this)}>GET CATEGORY LIST</button>
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-3 margintop" >
                                    <label>&nbsp;</label>
                                    <div className='form-group'>
                                        <button className='btn btn-block btn-primary' onClick={this.fetchAttributeData.bind(this)}>GET ATTRIBUTES LIST</button>
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-3 margintop" >
                                    <label>&nbsp;</label>
                                    <div className='form-group'>
                                        <button className='btn btn-block btn-primary' onClick={this.fetchValueseData.bind(this)}>GET VALUES LIST</button>
                                    </div>
                                </div>
                        </div>
                        </div>
                </Modal>
            </div>
        )
    }
}