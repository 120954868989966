import React, { Component, useRef } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import "./style.css";
import jQuery from "jquery";
import { Configuration, getDataTable } from "../../../Configuration";

const TOKEN = Configuration.USER_TOKEN.TOKEN;

const API_URL = Configuration.SYSTEM_SETTINGS.API_URL;

//const autoCompleteCascadEl = React.createRef();

const ToggleButton = ({ isOpen, onClick, Fnc }) => (
  <div onClick={Fnc}>
    <button
      className="toggle-button"
      onClick={onClick}
      // onFocus={Fnc}
      onMouseDown={(e) => {
        // Prevent input from losing focus.
        e.preventDefault();
      }}
    >
      {isOpen ? "▲" : "▼"}
    </button>
  </div>
);

export default class AutoCompleteCascad extends Component {
  constructor(props) {
    super(props);
    this.wrapper = React.createRef();
    this.state = {
      cmbData: [{ DisplayId: 1, DisplayName: "No Data" }],
      mainQuery: "",
      count: "20",
    };
  }

  async componentDidMount() {
    //await this.fillData();
  }

  async fillData() {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Auth-Token": TOKEN,
      },
    };

    if (this.props.filterId1 === "" || this.props.filterId1 === undefined) {
      // this.props.filterId1 = 0;
      Swal.fire({
        position: "top-end",
        icon: "info",
        title: "Please Select " + this.props.filter1 + " FIRST",
        showConfirmButton: false,
        timer: 1500,
      });
      return false;
    }
    let filter = "";
    if (this.props.filter2 !== "") {
      filter =
        " and " +
        this.props.filter1 +
        "=''" +
        this.props.filterId1 +
        "'' and " +
        this.props.filter2 +
        "=''" +
        this.props.filterId2 +
        "''";
    } else {
      filter =
        " and " + this.props.filter1 + "=''" + this.props.filterId1 + "''";
    }

    let SearchKey = "";

    let qry =
      "EXEC USP_FILL_COMBO_QUERY @formname=" +
      this.props.frmNm +
      ",@queryname=" +
      this.props.quryNm +
      ",@count=" +
      this.state.count +
      ",@searchtext='" +
      SearchKey.trim() +
      "',@filter='" +
      filter +
      "'";
    if (this.props.tblname) {
      qry += ", @TABLE=" + this.props.tblname;
    }
    let dt = await getDataTable(qry);
    console.log(qry);
    if (dt === false) {
      return false;
    }
    this.setState({ cmbData: dt });
    //let qry = "formname="+this.props.frmNm+"&queryname="+this.props.quryNm+"&count="+this.state.count+"&searchtext="+SearchKey.trim()+"&filter="+filter+"&db="+this.props.db+" " ;

    // const response = await fetch(API_URL+'/api/v1/Common/GetFillComboQuery?'+qry+'',config);
    // const data = await response.json();

    // this.setState({
    // cmbData: data.data
    // })
  }

  async onInputChangeHandler(evt) {
    let filter = "";

    if (this.props.filter2 !== "") {
      filter =
        " and " +
        this.props.filter1 +
        "=''" +
        this.props.filterId1 +
        "'' and " +
        this.props.filter2 +
        "=''" +
        this.props.filterId2 +
        "''";
    } else {
      filter =
        " and " + this.props.filter1 + "=''" + this.props.filterId1 + "''";
    }

    let SearchKey = evt;

    let qry =
      "EXEC USP_FILL_COMBO_QUERY @formname=" +
      this.props.frmNm +
      ",@queryname=" +
      this.props.quryNm +
      ",@count=" +
      this.state.count +
      ",@searchtext='" +
      SearchKey.trim() +
      "',@filter='" +
      filter +
      "'";
    if (this.props.tblname) {
      qry += ", @TABLE=" + this.props.tblname;
    }
    let dt = await getDataTable(qry);
    this.setState({ cmbData: dt });
    // let qry = "formname="+this.props.frmNm+"&queryname="+this.props.quryNm+"&count="+this.state.count+"&searchtext="+SearchKey.trim()+"&filter="+filter+"&db="+this.props.db+" " ;

    // const config = {

    //   headers: {
    //     'Content-Type': 'application/json',
    //     'Auth-Token' :  TOKEN
    //   }
    //   }
    // //this.state.query+" where " + this.props.filterBy +" like '"+SearchKey+"%'";
    // fetch(API_URL+'/api/v1/Common/GetFillComboQuery?'+qry+'',config)
    // .then(res => res.json())
    // .then((data) => {

    //     this.setState({
    //       cmbData:data.data
    //     })
    // })
  }

  onAfterSelect(selectedOptions) {
    if (selectedOptions.length > 0) {
      //this.props.name[this.state.id]=selectedOptions[0].DisplayId;
    }
  }

  render() {
    //const ref = useRef();
    return (
      <Typeahead
        clearButton
        id={this.props.id}
        labelKey="DisplayName"
        onFocus={this.fillData.bind(this)}
        onChange={this.props.onAfterSelect}
        options={this.state.cmbData}
        placeholder={this.props.placeholder}
        onInputChange={this.onInputChangeHandler.bind(this)}
        ref={this.wrapper}
      >
        {({ isMenuShown, toggleMenu }) => (
          <ToggleButton
            isOpen={isMenuShown}
            Fnc={this.fillData.bind(this)}
            onClick={(e) => toggleMenu()}
          />
        )}
      </Typeahead>
    );
  }
}
