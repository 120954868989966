import React, { Component, useRef } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import './style.css';
import { Configuration, getDataTable } from '../../../Configuration';

const TOKEN = Configuration.USER_TOKEN.TOKEN;
const API_URL = Configuration.SYSTEM_SETTINGS.API_URL;

const autoCompleteInputEl = React.createRef(Typeahead);

const ToggleButton = ({ isOpen, onClick }) => (
  <button
    className="toggle-button"
    onClick={onClick}
    onMouseDown={e => {
      // Prevent input from losing focus.
      e.preventDefault();
    }}>
    {isOpen ? '▲' : '▼'}
  </button>
);


export default class AutoCompleteInput extends Component {

  constructor(props) {
    super(props);
    this.myRef = React.createRef(Typeahead);
    this.state = {
      cmbData: [{ "DisplayId": 1, "DisplayName": "No Data" }],
      mainQuery: "",
      count: "15",
      token: ""
    };
  }

  async componentDidMount() {
    let filter = "";
    if (this.props.filter1 !== "" && this.props.filter1 !== undefined) {
      filter = " and " + this.props.filter1 + "=''" + this.props.filterId1 + "''"
    }
    if (this.props.filter2 !== "" && this.props.filter2 !== undefined) {
      filter = " and " + this.props.filter1 + "=''" + this.props.filterId1 + "'' and " + this.props.filter2 + "=''" + this.props.filterId2 + "''"
    }
    let que = "EXEC USP_FILL_COMBO_QUERY @formname=" + this.props.frmNm + ",@queryname=" + this.props.quryNm + ",@count=" + this.state.count + ",@searchtext='',@filter='"+filter+"' ";
    if (this.props.tblname) {
      que += ", @TABLE=" + this.props.tblname
    }
    let dt = await getDataTable(que)
    this.setState({ cmbData: dt })
  }


  async onInputChangeHandler(evt) {

    let SearchKey = evt;
    let filter = "";
    if (this.props.filter1 !== "" && this.props.filter1 !== undefined) {
      filter = " and " + this.props.filter1 + "=''" + this.props.filterId1 + "''"
    }
    if (this.props.filter2 !== "" && this.props.filter2 !== undefined) {
      filter = " and " + this.props.filter1 + "=''" + this.props.filterId1 + "'' and " + this.props.filter2 + "=''" + this.props.filterId2 + "''"
    }
    let que = "EXEC USP_FILL_COMBO_QUERY @formname=" + this.props.frmNm + ",@queryname=" + this.props.quryNm + ",@count=" + this.state.count + ",@searchtext='" + SearchKey.trim() + "',@filter='"+filter+"' ";
    if (this.props.tblname) {
      que += ", @TABLE=" + this.props.tblname
    }
    let dt = await getDataTable(que)
    this.setState({ cmbData: dt })
  }

  async onInputFocus(evt) {
    let SearchKey = evt.target.value;
    let filter = "";
    if (this.props.filter1 !== "" && this.props.filter1 !== undefined) {
      filter = " and " + this.props.filter1 + "=''" + this.props.filterId1 + "''"
    }
    if (this.props.filter2 !== "" && this.props.filter2 !== undefined) {
      filter = " and " + this.props.filter1 + "=''" + this.props.filterId1 + "'' and " + this.props.filter2 + "=''" + this.props.filterId2 + "''"
    }
    let que = "EXEC USP_FILL_COMBO_QUERY @formname=" + this.props.frmNm + ",@queryname=" + this.props.quryNm + ",@count=" + this.state.count + ",@searchtext='" + SearchKey.trim() + "',@filter='"+filter+"' ";
    if (this.props.tblname) {
      que += ", @TABLE=" + this.props.tblname
    }
    let dt = await getDataTable(que)
    this.setState({ cmbData: dt })
  }



  render() {

    const { onAfterSelect, onBlur, id, isValid, placeholder, defaultSelected } = this.props;
    return (
      <Typeahead
        clearButton
        id={id}
        labelKey="DisplayName"
        onChange={onAfterSelect}
        options={this.state.cmbData}
        placeholder={placeholder.toUpperCase()}
        onInputChange={this.onInputChangeHandler.bind(this)}
        onFocus={this.onInputFocus.bind(this)}
        onBlur={onBlur}
        isValid={isValid}
        ref={this.myRef}
        defaultSelected={defaultSelected}
        size='small'
        selectHintOnEnter={true}

      >

        {({ isMenuShown, toggleMenu }) => (
          <ToggleButton isOpen={isMenuShown} onClick={e => toggleMenu()} />
        )}

      </Typeahead>
    );
  }
};

