import React, { Component } from 'react';
import AutoCompletemulti from '../../Actions/Controls/AutoComplete/autoCompleteMultiple';
import AutoCompleteCascad from '../../Actions/Controls/AutoComplete/autoComplete.cascad'
import { Configuration, getDataTable, MandatoryFormFields } from '../../Configuration';
import Table from '../../Actions/Controls/Table/AntDTable'
import '../../ORDER/OrderMaster/style.css'
import { Modal, Spin } from 'antd';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'


export default class frmuser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginId: 0,
            ApiData: [],
            orgid: 0,
            ApiName: '',
            ApiURL: '',
            BodyType: '',
            Method: '',
            portalID: 0,
            Headers: '',
            Frequency: '',
            Paramerters: '',
            Authentication: '',
            token:'',
            Response: ''
        };

    }

    async componentDidMount() {
        let rs = await getDataTable("select orgid from users where userid = " + Configuration.USER_ID)
        await this.setState({
          loginId: Configuration.USER_ID,
          orgID: rs[0].orgid
        });
      }

    async getdata() {
        this.setState({ loading: true })
        let Que = "EXEC ShowApi"
        let rs = await getDataTable(Que)
        this.setState({ ApiData: rs, loading: false })
    }

    myChangeHandler = (event) => {

        let nam = event.target.name;
        let val = event.target.value;
        this.setState({ [nam]: val });

        this.forceUpdate();
    }


    async onAutoCOmpletMultiPleSelect(selectedOptions, name) {
        if (selectedOptions.length > 0) {
            this.setState({
                [name]: selectedOptions
            });
        }
        else {
            this.setState({
                [name]: ""
            })
        }
    }

    async SaveApi() {
        let MandatoryArray = [
            { "Api Name": this.state.ApiName },
            { "Api URL": this.state.ApiURL },
            { "Portal": this.state.portalID },
            { "BodyType": this.state.BodyType },
            { "Headers": this.state.Headers },
            { "Method": this.state.Method },
            { "Parameters": this.state.Paramerters},
            { "Authentication Type": this.state.Authentication},
            { "Respone Type": this.state.Response}
        ]
        let check = MandatoryFormFields(MandatoryArray);
        if (check === false) {
            return false;
        }

        let Que = "Select * from ApiMaster where ApiName = '" + this.state.ApiName + "' and ApiUrl = '" + this.state.ApiURL + "'"
        let rs = await getDataTable(Que)
        if (rs.length > 0) {
            Swal.fire({
                icon: 'info',
                title: 'ALREADY EXIST',
                showConfirmButton: false,
                timer: 1500
            })
            return;
        }
        let Query = "INSERT INTO ApiMaster   (ApiName, ApiURL, ApiPortalId, BodyType, Headers, Method, SchedularFrequency,Parameter,ApiDis,Token,AuthenticationType,ResponseType,loginId,logdate) output inserted.userId VALUES "
        Query += "(" +"'"+ this.state.ApiName + "'"+",'" + this.state.ApiURL + "'," + this.state.portalID + ",'" + this.state.BodyType +"'," +"'"+ this.state.Headers+"',"+"'"+this.state.Method+"'," + this.state.Frequency +",'"+this.state.Paramerters+"',"+'1'+",'"+this.state.token+"',"+"'"+this.state.Authentication+"',"+"'"+this.state.Response +"',1,getdate(),'"+ ")"
        const response = await getDataTable(Query)
        if (response.length > 0) {
            Swal.fire({
                icon: 'success',
                title: 'SUCCESSFULLY SAVED',
                showConfirmButton: false,
                timer: 1500
            })

            // var ddt = document.getElementsByTagName('input')
            // for (let i = 0; i < ddt.length; i++) {
            //     if (ddt[i].className === 'form-control') {
            //         ddt[i].value = '';
            //     }
            // }
            this.setState({
                ApiName: '',
                ApiURL: '',
                portalID: '',
                BodyType: '',
                Headers: '',
                Method: [],
                Frequency: '',
                Paramerters: false
            })

        }
        else {
            Swal.fire({
                position: 'top-end',
                icon: 'warning',
                title: 'Not Saved',
                showConfirmButton: false,
                timer: 1500
            })
            this.setState({ loading: false })
        }
        // console.log(OrderL = dataist);
        await this.getdata(this);
        this.forceUpdate();

    }

    myFunction(e, id) {

        var x = document.getElementById(e);
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
    }

    render() {
        return (
            <div className="content-wrapper">
                <div className='loader' style={{ display: this.state.loading ? "block" : "none" }}>
                    <div className='loader-item'>
                        <Spin />
                    </div>
                </div>
                <section className="content">
                    <div id="ctl00_CPHMaincontent_divclient" className="box box-success" style={{ marginTop: '-13px' }}>
                        <div className="box-header with-border">
                            <h3 className="box-title">API Master<sup>(<span className="vcode">*</span> Fields are mandatory)</sup>
                                <span id="ctl00_CPHMaincontent_DivOrdNo" style={{ display: 'inline' }}>
                                </span>
                            </h3>
                        </div>

                        <div className="box-body" id="orderDetails" style={{ display: 'block' }}>
                            <div className="row">
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                    <label>Api Name</label><span className="vcode">*</span>
                                    <div className="form-group">
                                        <input name="ApiName" id="ApiName" type='text' value={this.state.ApiName} onChange={this.myChangeHandler} placeholder="Enter Api Name" tabIndex={1} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                    <label>Api URL</label><span className="vcode">*</span>
                                    <div className="form-group">
                                        <input name="ApiURL" id="ApiURL" type="URL" value={this.state.ApiURL} onChange={this.myChangeHandler} placeholder="Enter API URL" tabIndex={1} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                    <label>Select Portal</label>
                                    <AutoCompleteCascad
                                        id="portalID"
                                        frmNm="FRMATTRIBUTEALIASE"
                                        quryNm="FILLPORTAL"
                                        db="IMAGEDB"
                                        filter2=""
                                        filterId2=""
                                        filter1="orgid"
                                        filterId1={this.state.orgid}
                                        placeholder="Please Select Portal"
                                        onAfterSelect={(e) => this.onAfterSelect(e, "portalID", "Pname")}
                                    ></AutoCompleteCascad>
                                </div>
                                <div className="col-xs-4 col-sm-4 col-md-2 margintop">
                                    <label>Body Type</label><span className='vcode'>*</span>
                                    <div className='form-group'>
                                        <select className='form-control' onChange={this.myChangeHandler} name='BodyType'>
                                            <option value='Please Select'>Please Select</option>
                                            <option value='JSON'>JSON</option>
                                            <option value='XML'>XML</option>
                                            <option value='HTML'>HTML</option>
                                            <option value='STRING'>STRING</option>
                                            <option value='TEXT'>TEXT</option>
                                            <option value='FILE'>FILE</option>
                                            <option value='OTHER'>OTHER</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                    <label>Headers</label><span className='vcode'>*</span>
                                    <div className="form-group">
                                        <input name="Headers" id="Headers" type="text" value={this.state.Headers} onChange={this.myChangeHandler} placeholder="Enter Headers" tabIndex={1} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                    <label>Method</label><span className='vcode'>*</span>
                                    <div className='form-group'>
                                        <select className='form-control' onChange={this.myChangeHandler} name='Method'>
                                            <option value='Please Select'>Please Select</option>
                                            <option value='POST'>POST</option>
                                            <option value='GET'>GET</option>
                                            <option value='PUT'>PUT</option>
                                            <option value='DELETE'>DELETE</option>
                                            <option value='PATCH'>PATCH</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-3 margintop" style={{ display: this.state.Method == 'GET' ? "block" : "none" }}>
                                    <label>Scheduler Frequency</label><span className='vcode'>*</span>
                                    <div className="form-group">
                                        <input name="Frequency" id="Frequency" type="text" value={this.state.Frequency} onChange={this.myChangeHandler} placeholder="Enter Scheduler Frequency" tabIndex={1} className="form-control" />
                                    </div>
                                </div><div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                    <label>Paramerters</label><span className='vcode'>*</span>
                                    <div className="form-group">
                                        <input name="Paramerters" id="Paramerters" type="text" value={this.state.Paramerters} onChange={this.myChangeHandler} placeholder="Enter Paramerters" tabIndex={1} className="form-control" />
                                    </div>
                                </div><div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                    <label>Authentication Type</label><span className='vcode'>*</span>
                                    <div className='form-group'>
                                        <select className='form-control' onChange={this.myChangeHandler} name='Authentication'>
                                            <option value='Please Select'>Please Select</option>
                                            <option value='TOKEN'>TOKEN</option>
                                            <option value='UserName & Password'>UserName & Password</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                    <label>Response Type</label><span className='vcode'>*</span>
                                    <div className='form-group'>
                                        <select className='form-control' onChange={this.myChangeHandler} name='Response'>
                                            <option value='Please Select'>Please Select</option>
                                            <option value='JSON'>JSON</option>
                                            <option value='XML'>XML</option>
                                            <option value='HTML'>HTML</option>
                                            <option value='STRING'>STRING</option>
                                            <option value='TEXT'>TEXT</option>
                                            <option value='FILE'>FILE</option>
                                            <option value='OTHER'>OTHER</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                    <label>&nbsp;</label>
                                    <div className="form-group">
                                        <button className='btn btn-primary'>Save API</button>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12 margintop">
                                    {this.state.OrgData.length > 0 && this.state.OrgData !== undefined &&
                                        <Table
                                            data={[...this.state.OrgData]}
                                            Delete="true"
                                            ShowRow={this.state.loginId === "1" ? 'true' : 'false'}
                                            EditRow='true'
                                            EditRowFn={(e) => this.EditRowFn(e)}
                                            ShowRowFn={(e) => this.getRowData(e)}
                                            DeleteFn={(e) => this.deleteRow(e)}
                                            height='63vh'
                                            id='fielddata'
                                            exportXL="true">
                                        </Table>
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }


    async deleteRow(e) {
        console.log(e);
        if (this.state.loginId === "1" || this.state.loginId === "10" || this.state.loginId === "6") {
            let st = window.confirm("Are you sure want to delete ?")
            if (!st) {
                return false;
            }
            console.log(e);
            let Que = "update  Users set Active = 0 output inserted.userid where userId = " + e["#userid"]
            let rs = await getDataTable(Que)
            if (rs.length > 0) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'info',
                    title: 'Successfully Deleted',
                    showConfirmButton: false,
                    timer: 1500
                })
                await this.getdata()
            }
            else {
                alert("Some Error Occurred")
            }
        }
        else {
            alert("You are not allowed to delete")
            return;
        }
    }

}
