import React from 'react';
import { createStore, applyMiddleware } from "redux";
import {Provider} from 'react-redux';
import thunkMiddleware from 'redux-thunk';
//import AppReducer from './Reducers';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import reducer from './Reducers/index';
import api from './middleware/api';



///import externApi from './middleware/externalApi'
let createStoreWithMiddleware = applyMiddleware(thunkMiddleware, api)(createStore)
let store = createStoreWithMiddleware(reducer)
ReactDOM.render(
   <Provider store = {store}>
     <App />
     </Provider>
 ,
  document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
