import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import _ from "lodash";
import XLSX from "xlsx";
const nacl = require("tweetnacl");
const utils = require("tweetnacl-util");
const encodeBase64 = utils.encodeBase64;
const nonce = nacl.randomBytes(24);
const PRIVATE_KEY = "_VIROLA_PRIVATE_KEY_WITH_AES_ECY";

export const Configuration = {
  ORGANISATION_SETTINGS: {
    COMPANYNAME: "BLUMANGOES",
  },
  SYSTEM_SETTINGS: {
    SOFTWARE_URL: "",
    API_URL: "https://api.blumangoes.ai:6143/",
  },
  USER_TOKEN: {
    TOKEN:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDMyOS8iLCJhdWQiOiJodHRwczovL2xvY2FsaG9zdDo0NDMyOS8ifQ.j9o8spUTqVaO91cB4pRLmok-gn2aIN34e7aUXb6wok0",
  },

  API_HEADER: {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Headers": "*",
      "Access-Control-Allow-Origin": "*",
      "Auth-Token":
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo0NDMyOS8iLCJhdWQiOiJodHRwczovL2xvY2FsaG9zdDo0NDMyOS8ifQ.j9o8spUTqVaO91cB4pRLmok-gn2aIN34e7aUXb6wok0",
    },
  },
  DB: "IMAGEDB",
  USER_ID: "0",
  USER_NAME: "ADMIN",
  SESSION_ID: "",
  FACTORY_NAME: "",
};

export const GetDycrptIDPassword = (
  encryptUserID,
  sess = "dwtv5sx5odzn5tsi0g41ozdm"
) => {
  let userID = atob(encryptUserID);
  Configuration.USER_ID = userID;
  Configuration.SESSION_ID = sess;
  return userID;
};

export const GetEncryptIDPassword = (encryptUserID) => {
  let userID = btoa(encryptUserID);
  Configuration.USER_ID = userID;
  return userID;
};

// return the user data from the session storage
export const getUser = () => {
  const userStr = localStorage.getItem("user");
  if (userStr) return JSON.parse(userStr);
  else return null;
};

// return the token from the session storage
export const getToken = () => {
  const itemStr = localStorage.getItem("Token");
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }

  Configuration.USER_ID = JSON.parse(localStorage.getItem("UserId")).value;
  Configuration.USER_NAME = JSON.parse(
    localStorage.getItem("UserName")
  ).value.toUpperCase();
  Configuration.AUTH_TOKEN = JSON.parse(localStorage.getItem("Token")).value;
  Configuration.FACTORY = JSON.parse(
    localStorage.getItem("Factory")
  ).value.toUpperCase();

  const item = JSON.parse(itemStr);
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    removeUserSession();
    return null;
  }
  return item.value;
  // return localStorage.getItem('token') || null;
};
// remove the token and user from the session storage
export const removeUserSession = () => {
  localStorage.removeItem("Token");
  localStorage.removeItem("UserName");
  localStorage.removeItem("UserId");
};

// set the token and user from the session storage
export const setUserSession = (key, value, expiryTime) => {
  const now = new Date();
  const SessionItem = {
    value: value,
    expiry: now.getTime() + expiryTime,
  };
  localStorage.setItem(key, JSON.stringify(SessionItem));
};

export const getAuthorization = () => {
  return localStorage.getItem("Authorization")
    ? JSON.parse(localStorage.getItem("Authorization")).value
    : false;
};

// SET INTEGER ON BOOLEAN DATATYPE

export const ConvBoolTOInt = (value) => {
  return value ? 1 : 0;
};

export function OnlyNumberKey(evt) {
  // Only ASCII charactar in that range allowed
  var ASCIICode = evt.which ? evt.which : evt.keyCode;
  if (ASCIICode > 47 && ASCIICode < 58) {
    return true;
  } else {
    evt.preventDefault();
  }
}

export function allowOnlyOneDotOrNum(evt) {
  var ASCIICode = evt.which ? evt.which : evt.keyCode;
  if ((ASCIICode > 47 && ASCIICode < 58) || ASCIICode === 46) {
  } else {
    evt.preventDefault();
    return false;
  }
  var dots = 1;
  var length = evt.target.value.length;
  var text = evt.target.value;
  for (var i = 0; i < length; i++) {
    if (text[i] === ".") dots++;
    if (dots > 1 && ASCIICode === 46) {
      evt.preventDefault();
    }
  }
}

export function MandatoryFormFields(fildsArray) {
  if (fildsArray.length > 0 && fildsArray !== undefined) {
    for (let index = 0; index < fildsArray.length; index++) {
      const element = fildsArray[index];
      let Name = Object.keys(fildsArray[index]);
      if (element[Name] == "" || element[Name] == undefined) {
        Swal.fire({
          icon: "error",
          title: "PLEASE ENTER " + Name[0].toUpperCase(),
          showConfirmButton: false,
          timer: 1500,
        });

        return false;
      }
    }
  }
}

export async function encryptJSON(dt) {
  const secretKey = Buffer.from(PRIVATE_KEY, "utf8");
  const secretData = Buffer.from(JSON.stringify(dt), "utf8");
  const encrypted = nacl.secretbox(secretData, nonce, secretKey);
  const result = `${encodeBase64(nonce)}:${encodeBase64(encrypted)}`;
  return result;
}

export async function getDataTable(Query) {
  let st = [
    {
      db: Configuration.DB,
      query: Query,
    },
  ];
  let dt = await encryptJSON(st);
  const config = {
    method: "POST",
    body: dt,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Headers": "*",
      "Access-Control-Allow-Origin": "*",
    },
  };
  const url =
    Configuration.SYSTEM_SETTINGS.API_URL + "/api/v1/Common/GetDynamicData";
  try {
    //const rs = await fetch(url + '?query=' + Query + '&db=' + Configuration.DB + '', config);
    const rs = await fetch(url, config);
    let dt = await rs.json();
    let artd = dt.data;
    if (Array.isArray(artd)) {
      return artd;
    } else {
      return false;
    }
  } catch (error) {
    Swal.fire({
      icon: "info",
      title: error,
      showConfirmButton: false,
      timer: 1500,
    });
    return false;
  }
}

//Get Max Id From Table
export async function GetMaxId(tblName, fieldName, logInId) {
  let data;
  try {
    let query =
      "exec GetMaxId @tblName = " +
      tblName +
      ",@filedName=" +
      fieldName +
      ",@LogInId=" +
      logInId +
      "";

    let response = await fetch(
      Configuration.SYSTEM_SETTINGS.API_URL +
        "/api/v1/Common/GetDynamicQuery?query= " +
        query +
        "&db=" +
        Configuration.DB,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
          "Auth-Token": Configuration.USER_TOKEN.TOKEN,
        },
      }
    );
    data = await response.json();
  } catch (e) {
    alert(e);
  }
  return data.data[0][fieldName];
}

// Get Max No Perticular Column Name
export async function GetMaxNo(tblName, fieldName) {
  let data;
  try {
    let query =
      "exec GetMaxNo @tblName = " + tblName + ",@filedName=" + fieldName + "";

    let response = await fetch(
      Configuration.SYSTEM_SETTINGS.API_URL +
        "/api/v1/Common/GetDynamicQuery?query= " +
        query +
        "&db=" +
        Configuration.DB,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
          "Auth-Token": Configuration.USER_TOKEN.TOKEN,
        },
      }
    );
    data = await response.json();
  } catch (e) {
    alert(e);
  }
  return data.data[0][fieldName];
}

// CHECK VALUE EXIST IN TABLE OR NOT
export async function checkValueExistOrNotInTable(
  tblName,
  columnName,
  colValue
) {
  let data;
  try {
    let query =
      "exec Check_Value_Exist_Or_Not_In_Table @TableName = '" +
      tblName +
      "',@ColumnName='" +
      columnName +
      "' , @ColumnValue = '" +
      colValue +
      "'";

    let response = await fetch(
      Configuration.SYSTEM_SETTINGS.API_URL +
        "/api/v1/Common/GetDynamicQuery?query= " +
        query +
        "&db=" +
        Configuration.DB,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
          "Auth-Token": Configuration.USER_TOKEN.TOKEN,
        },
      }
    );
    data = await response.json();
  } catch (e) {
    alert(e);
  }

  if (data.data[0][columnName] === 1) {
    return true;
  } else {
    return false;
  }
}

// hide &n show Accordians

export function hideShowDiv(id, childId) {
  let div = document.getElementById(id);
  if (div !== null) {
    if (document.getElementById(id).style.display == "block") {
      document.getElementById(id).style.display = "none";
      let ele = document.getElementById(childId);

      ele.className = "fa fa-plus";
    } else {
      document.getElementById(id).style.display = "block";
      let ele = document.getElementById(childId);
      ele.className = "fa fa-minus";
    }
  }
}

// Excel to db

export async function exceltodb(files, db, loginId) {
  // let workbook = XLSX.readFile(files);
  // let sheet = workbook.Sheets[workbook.SheetNames[0]];
  // let json = XLSX.utils.sheet_to_json(sheet);
  // console.log(json);
  let res = "";
  var formdata = new FormData();
  formdata.append("file", files);
  var d = new Date();
  var timeInMs =
    String(d.getUTCDate()) +
    String(d.getMonth() + 1) +
    String(d.getFullYear()) +
    String(d.getHours()) +
    String(d.getMinutes()) +
    String(d.getSeconds());
  let tablename = String("X_FileImport_" + timeInMs + "_" + loginId);
  var requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };
  await fetch(
    "https://api.blumangoes.ai:6143/SaveFileBM?tblname=" + tablename,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => (res = result))
    .catch((error) => alert("error", error));
  let filess = res;
  if (!filess) {
    alert("Unable To Import File To server");
    return false;
  }

  // let Sql = ""
  // Sql = Sql + " SELECT        *"
  // Sql = Sql + " INTO              " + tablename
  // Sql = Sql + " FROM            OPENROWSET('Microsoft.ACE.OLEDB.16.0', 'Excel 12.0; Database=" + filess + "' "
  // Sql = Sql + " , [Sheet1$]) AS derivedtbl_1 "
  // Sql = Sql + " Select * from " + tablename

  // let rs = await getDataTable(Sql)

  let dt1 = await getDataTable("select top 10 * from " + tablename);
  if (dt1) {
    if (dt1.length > 0) {
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "File Uploaded To Server",
        showConfirmButton: false,
        timer: 1500,
      });
      //alert("File Uploaded To Server")
    } else {
      Swal.fire({
        icon: "info",
        title: "FILE NOT VALID",
        showConfirmButton: false,
        timer: 1500,
      });
      return false;
    }
    return tablename;
  } else {
    Swal.fire({
      icon: "info",
      title: "SOME ERROR OCCURRED",
      showConfirmButton: false,
      timer: 1500,
    });
    return false;
  }
}

export function toCamel(o) {
  var newO, origKey, newKey, value;
  if (o instanceof Array) {
    return o.map(function (value) {
      if (typeof value === "object") {
        value = _.startCase(_.toUpper(value));
      }
      return value;
    });
  } else {
    newO = {};
    for (origKey in o) {
      if (o.hasOwnProperty(origKey)) {
        newKey = (
          origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey
        ).toString();
        value = o[origKey];
        value = _.startCase(_.toUpper(value));
        newO[newKey] = value;
      }
    }
  }
  return newO;
}

// export async function json2xlsx(dt, filename) {
//   var ws = XLSX.utils.json_to_sheet(dt, { raw: true });
//   var wb = XLSX.utils.book_new();
//   XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
//   XLSX.writeFile(wb, filename, { bookType: 'xlsx', type: 'file' }); // generate a nodejs buffer
// }

export async function json2xlsxFK(dt, filename) {
  let newdt = dt;
  var ws = XLSX.utils.json_to_sheet(newdt, { raw: true });
  var wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, filename, { bookType: "xlsx", type: "file" }); // generate a nodejs buffer
}

export async function json2xlsxMultiSheet(dt, dt1, filename) {
  var ws = XLSX.utils.json_to_sheet(dt);
  var ws1 = XLSX.utils.json_to_sheet(dt1);
  var wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.utils.book_append_sheet(wb, ws1, "DataValues");
  XLSX.writeFile(wb, filename, { bookType: "xlsx", type: "file" }); // generate a nodejs buffer
}

export async function table2xlsx(dt, filename) {
  var ws = await XLSX.utils.table_to_book(dt);
  XLSX.writeFile(ws, filename, { bookType: "xlsx", type: "file" }); // generate a nodejs buffer
}

export async function json2csv(dt, filename) {
  var ws = XLSX.utils.json_to_sheet(dt);
  var wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, filename, { bookType: "csv", type: "file" }); // generate a nodejs buffer
}

export function tableToJson(table) {
  var data = [];

  // first row needs to be headers
  var headers = [];
  for (var i = 0; i < table.rows[0].cells.length; i++) {
    headers[i] = table.rows[0].cells[i].innerHTML
      .toLowerCase()
      .replace(/ /gi, "");
  }

  // go through cells
  for (var k = 1; k < table.rows.length; k++) {
    var tableRow = table.rows[k];
    var rowData = {};

    for (var j = 0; j < tableRow.cells.length; j++) {
      rowData[headers[j]] = tableRow.cells[j].innerHTML;
    }

    data.push(rowData);
  }
  return data;
}

export async function arrUnique(arr) {
  var cleaned = [];
  arr.forEach(function (itm) {
    var unique = true;
    cleaned.forEach(function (itm2) {
      if (_.isEqual(itm, itm2)) unique = false;
    });
    if (unique) cleaned.push(itm);
  });
  return cleaned;
}

export async function json2Excelpython(dt, filename) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify(dt);
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  let rs = await fetch(
    "https://api.blumangoes.ai:6143/GetJSON2Excel",
    requestOptions
  );
  let str = await rs.text();
  let link = document.createElement("a");
  link.href =
    "data:application/vndopenxmlformats-officedocumentspreadsheetmlsheet;base64," +
    str;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
}

export async function json2portalExport(
  dt,
  SheetName,
  CellAddress,
  portal,
  template,
  orgid
) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("tempid", template);
  myHeaders.append("portal", portal);
  myHeaders.append("SheetName", SheetName);
  myHeaders.append("Range", CellAddress);
  myHeaders.append("orgid", orgid);
  var raw = JSON.stringify(dt);
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  let rs1 = await fetch(
    "https://api.blumangoes.ai:6143/GetPortalExcel",
    requestOptions
  );
  let dts1 = await rs1.json();
  let link = document.createElement("a");
  link.href =
    "data:application/vndopenxmlformats-officedocumentspreadsheetmlsheet;base64," +
    dts1.FileStr;
  link.setAttribute("download", dts1.FileName);
  document.body.appendChild(link);
  link.click();
}

export async function json2portalExportUpdated(
  que,
  SheetName,
  CellAddress,
  portal,
  template
) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  let st = [
    {
      tempid: template,
      portal: portal,
      SheetName: SheetName,
      Range: CellAddress,
      query: que,
    },
  ];
  let sts = JSON.stringify(st);
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: sts,
    redirect: "follow",
  };
  let rs1 = await fetch(
    "https://api.blumangoes.ai:6143/GetPortalExcelFile",
    requestOptions
  );
  let dts1 = await rs1.json();
  let link = document.createElement("a");
  link.href =
    "data:application/vndopenxmlformats-officedocumentspreadsheetmlsheet;base64," +
    dts1.FileStr;
  link.setAttribute("download", dts1.FileName);
  document.body.appendChild(link);
  link.click();
}

export async function SaveTemplateFile(file, portalName, templateID) {
  var formdata = new FormData();
  formdata.append("file", file);
  var requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };
  try {
    let rs = await fetch(
      "https://api.blumangoes.ai:6143/SaveTemplateFile?templateid=" +
        templateID +
        "&portal=" +
        portalName +
        "",
      requestOptions
    );
    let str = await rs.text();
    return str;
  } catch (error) {
    return "Some Error";
  }
}

export async function updateTemplateFile(file, portalName, templateID) {
  var formdata = new FormData();
  formdata.append("file", file);
  var requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };
  try {
    let rs = await fetch(
      "https://api.blumangoes.ai:6143/updateTemplateFile?templateid=" +
        templateID +
        "&portal=" +
        portalName +
        "",
      requestOptions
    );
    let str = await rs.text();
    return str;
  } catch (error) {
    return "Some Error";
  }
}

export async function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function getnewExcel(data) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify(data);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  let rs1 = await fetch(
    "https://api.blumangoes.ai:6143/GetExcel/WithImage",
    requestOptions
  );
  let dts1 = await rs1.json();
  let link = document.createElement("a");
  link.href =
    "data:application/vndopenxmlformats-officedocumentspreadsheetmlsheet;base64," +
    dts1.FileStr;
  link.setAttribute("download", "New.xlsx");
  document.body.appendChild(link);
  link.click();
}

export async function GetNewDrpSheet(blockid = 1, data, filename) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("BlockID", blockid);
  var raw = JSON.stringify(data);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  let rs1 = await fetch(
    "https://api.blumangoes.ai:6143/GetExcel/GetFilledSheet?BlockID =" +
      blockid,
    requestOptions
  );
  let dts1 = await rs1.json();
  let link = document.createElement("a");
  link.href =
    "data:application/vndopenxmlformats-officedocumentspreadsheetmlsheet;base64," +
    dts1.FileStr;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
}

export async function GetDropdownDynamicNew(blockid = 1, data, filename) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("BlockID", blockid);
  var raw = JSON.stringify(data);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  let rs1 = await fetch(
    "https://api.blumangoes.ai:6143/GetExcel/GetDrpdownDynamic?BlockID =" +
      blockid,
    requestOptions
  );
  let dts1 = await rs1.json();
  let link = document.createElement("a");
  link.href =
    "data:application/vndopenxmlformats-officedocumentspreadsheetmlsheet;base64," +
    dts1.FileStr;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
}

export async function json2xlsx(data, filename) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify(data);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  let rs1 = await fetch(
    "https://api.blumangoes.ai:6143/GetExcel/WithImage",
    requestOptions
  );
  let dts1 = await rs1.json();
  let link = document.createElement("a");
  link.href =
    "data:application/vndopenxmlformats-officedocumentspreadsheetmlsheet;base64," +
    dts1.FileStr;
  link.setAttribute("download", filename + ".xlsx");
  document.body.appendChild(link);
  link.click();
}

export async function postDataToNamshi(data, cred, url) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("cred", cred);
  myHeaders.append("url", url);
  var raw = JSON.stringify(data);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  let rs1 = await fetch(
    "https://api.blumangoes.ai:6143/namshi/postData",
    requestOptions
  );
  let dts1 = await rs1.json();
  return dts1;
}

export async function UpdateNamshiImage(data, cred, url) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("cred", cred);
  myHeaders.append("url", url);
  var raw = JSON.stringify(data);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  let rs1 = await fetch(
    "https://api.blumangoes.ai:6143/namshi/updateImage",
    requestOptions
  );
  let dts1 = await rs1.json();
  return dts1;
}

export async function getNamshiJobDetails(jobID, cred, url) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("JobID", jobID);
  myHeaders.append("cred", cred);
  myHeaders.append("url", url);
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
  };

  let rs1 = await fetch(
    "https://api.blumangoes.ai:6143/namshi/getJobDetails",
    requestOptions
  );
  let dts1 = await rs1.json();
  return dts1;
}

export async function ProductVisiblity(data, cred) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("cred", cred);
  var raw = JSON.stringify(data);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  let rs1 = await fetch(
    "https://api.blumangoes.ai:6143/ProductVisiblity",
    requestOptions
  );
  let dts1 = await rs1.json();
  return dts1;
}

export async function UpdatePrice(data, cred, url, country) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("cred", cred);
  myHeaders.append("url", url);
  myHeaders.append("country", country);
  var raw = JSON.stringify(data);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  let rs1 = await fetch(
    "https://api.blumangoes.ai:6143/namshi/updateprice",
    requestOptions
  );
  let dts1 = await rs1.json();
  return dts1;
}

export async function PriceAndDis(data, token, BaseUrl) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("BearToken", token);
  myHeaders.append("url", BaseUrl);
  var raw = JSON.stringify(data);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  let rs1 = await fetch(
    "https://api.blumangoes.ai:6143/PriceAndDiscount",
    requestOptions
  );
  let dts1 = await rs1.json();
  return dts1;
}

export async function EasyEcomApi(data, token, BaseUrl, cred) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("BearToken", token);
  myHeaders.append("url", BaseUrl);
  myHeaders.append("cred", cred);
  var raw = JSON.stringify(data);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  let rs1 = await fetch(
    "https://api.blumangoes.ai:6143/EasyEcomApi",
    requestOptions
  );
  let dts1 = await rs1.json();
  return dts1;
}

export async function IncreffApi(data, authUsername, authPassword, url) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("authUsername", authUsername);
  myHeaders.append("authPassword", authPassword);
  myHeaders.append("url", url);
  var raw = JSON.stringify(data);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  let rs1 = await fetch(
    "https://api.blumangoes.ai:6143//increff",
    requestOptions
  );
  let dts1 = await rs1.json();
  return dts1;
}

export async function UpdateIncreffApi(data, authUsername, authPassword, url) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("authUsername", authUsername);
  myHeaders.append("authPassword", authPassword);
  myHeaders.append("url", url);
  var raw = JSON.stringify(data);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  let rs1 = await fetch(
    "https://api.blumangoes.ai:6143//increff/updateData",
    requestOptions
  );
  let dts1 = await rs1.json();
  return dts1;
}

export async function CreateListingAmazon(data, token, Url) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("x-amz-access-token", token);
  myHeaders.append("url", Url);
  var raw = JSON.stringify(data);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  let rs1 = await fetch(
    "https://api.blumangoes.ai:6143/CreateListingAmazon",
    requestOptions
  );
  let dts1 = await rs1.json();
  return dts1;
}

export async function GetCatogoryAttributeValuesYoox(Url) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("url", Url);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  let rs1 = await fetch(
    "https://api.blumangoes.ai:6143/GetCatogoryYoox",
    requestOptions
  );
  let dts1 = await rs1.json();
  return dts1;
}

export async function PriceUpdateAmazon(data, token, Url) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("x-amz-access-token", token);
  myHeaders.append("url", Url);
  var raw = JSON.stringify(data);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  let rs1 = await fetch(
    "https://api.blumangoes.ai:6143/PriceUpdateAmazon",
    requestOptions
  );
  let dts1 = await rs1.json();
  return dts1;
}

export async function CreateListingYoox(file, Authorization) {
  const formData = new FormData();
  formData.append("file", file);

  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: Authorization,
    },
    body: formData,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      "https://api.blumangoes.ai:6143/UploadProductYOOX",
      requestOptions
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error; // Rethrow the error for the calling code to handle
  }
}

export async function UpdatePriceYoox(file, Authorization) {
  const formData = new FormData();
  formData.append("file", file);

  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: Authorization,
    },
    body: formData,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      "https://api.blumangoes.ai:6143/UpdatePriceYOOX",
      requestOptions
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error; // Rethrow the error for the calling code to handle
  }
}

export async function flipkartTokenGen(username, password, url) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("username", username);
  myHeaders.append("password", password);
  myHeaders.append("url", url);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
  };

  let rs1 = await fetch(
    "https://api.blumangoes.ai:6143/FlipkartToken",
    requestOptions
  );
  let dts1 = await rs1.json();
  return dts1;
}

export async function getTrendyolAttributes(categoryid) {
  let rs = await fetch(
    `https://api.blumangoes.ai:6143/get_attributes/${categoryid}`
  );
  let dt = await rs.json();
  return dt;
}

// Usage:

/// Note :-Never Set The Static Image/File/Any Document Path..............................
