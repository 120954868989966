//import { combineReducers } from 'redux';
import GetOrderStatusReducer from   './GET_REDUCERS/getOrderStatusReducer';
import GetItemListReducer from './GET_REDUCERS/getItemReducers';
import { combineReducers } from 'redux'


 const rootReducer = combineReducers({
getOrderStatusReducer:GetOrderStatusReducer,
getItemListReducer:GetItemListReducer

 });
 export default rootReducer;