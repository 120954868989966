import {
  FormControl,
  InputLabel,
  Select,
  TextareaAutosize,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PageTitle from "../../Common/pageTitle";
import SendIcon from "@material-ui/icons/Send";
import { APIService } from "../../../Config/action/apiAction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AutoCompletemulti from "../../../Actions/Controls/AutoComplete/autoCompleteMultiple";

function NotificationDetail(props) {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    type: "Security",
    title: "",
  });
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setError(false);
    if (state.title) {
      setLoading(true);
      let payload = {
        query: `BROADCAST_NOTIFICATION @type = ${state.type} , @title = '${state.title}'`,
      };
      props
        .dispatch(APIService("DYNAMIC_NEW_POST", "", payload))
        .then((data) => {
          if (data.status === 200 && data.data.status === 1000) {
            setState({
              ...state,
              type: "Security",
              title: "",
            });
            props.setActiveNotification(true);
            toast("Notification sent successfully", {
              position: "top-right",
              type: "success",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
            });
            setLoading(false);
          } else {
            throw 500;
          }
        })
        .catch((err) => {
          toast("Error Broadcasting Notifications", {
            position: "top-right",
            type: "error",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          setLoading(false);
        });
    } else {
      setError(true);
    }
  };

  return (
    <div className="content-wrapper">
      <div className="loader" style={{ display: loading ? "block" : "none" }}>
        <div className="loader-item">
          <Spin />
        </div>
      </div>
      <section className="content" style={{ background: "white" }}>
        <div
          id="ctl00_CPHMaincontent_divclient"
          className="box box-success"
          style={{ marginTop: "-13px" }}
        >
          <div className="box-header with-border">
            <h3 className="box-title">
              Broadcast Notifications
              {/* <sup>
              (<span className="vcode">*</span> Fields are mandatory)
            </sup> */}
              <span
                id="ctl00_CPHMaincontent_DivOrdNo"
                style={{ display: "inline" }}
              ></span>
            </h3>
          </div>
          <div
            className="box-body"
            id="orderDetails"
            style={{ display: "block", padding: "15px" }}
          >
            <div className="row">
              <div
                className="inputClassHandler"
                style={{ marginTop: "10px" }}
              >
                <div className="form-group">
                  <label for="inputEmail3">Notification Type</label>
                </div>
                <div className="form-group" style={{ marginTop: "10px" }}>
                  <select
                    style={{ width: "250px" }}
                    native
                    value={state.type}
                    onChange={handleChange}
                    label="Type"
                    name="type"
                    inputProps={{
                      name: "type",
                      id: "outlined-age-native-simple",
                    }}
                  >
                    <option value={"Security"}>Security</option>
                    <option value={"Payment"}>Payment</option>
                    <option value={"General"}>General</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className="inputClassHandler"
                style={{ marginTop: "10px" }}
              >
                <div className="form-group">
                  <label for="inputEmail3">Description</label>
                </div>
                <div className="form-group" style={{ marginTop: "10px" }}>
                  <textarea
                    style={{ width: "250px" }}
                    id="w3review"
                    name="w3review"
                    rows="2"
                    cols="40"
                    name="title"
                    onChange={handleChange}
                    value={state.title}
                  ></textarea>
                  {error && (
                    <small className="error_class">
                      This field is required
                    </small>
                  )}
                </div>
              </div>

              <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                <div className="form-group">
                  <button onClick={handleSubmit} style={{marginTop: "10px", width: "100px"}} className="btn btn-primary">
                    Send
                  </button>
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>
      </section>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: () => {
      dispatch({
        type: "loginUser",
      });
    },
    logoutUser: () => {
      dispatch({
        type: "logoutUser",
      });
    },
    dispatch,
  };
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter(NotificationDetail));
