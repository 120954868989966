import React, {Component, Fragment} from 'react';
// import NavBar from '../../Common/navbar';
// import PageTitle from '../../Common/pageTitle';
// import Sidenav from '../../Common/sidenav';
import BrandDetail from './brandDetail';

class Brand extends Component{
    render(){
        return(
            <Fragment>
                <div style={{backgroundColor:'#f4f6f9', minHeight: '100vh'}}>
                    {/* <NavBar />
                    <Sidenav active="Category" /> */}
                    <BrandDetail />
                </div>
            </Fragment>
        )
    }
}

export default Brand;