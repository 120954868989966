import { Modal } from 'antd';
import React, { Component } from 'react';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import AutoCompleteCascad from '../../Actions/Controls/AutoComplete/autoComplete.cascad';
import { Configuration, MandatoryFormFields, getDataTable, arrUnique } from '../../Configuration';

export default class frmcreateValueAliasing extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            modal1Visible: false,
            modal2Visible: false,
            loginId: 0,
            fldt: []
        };
    }
    async componentDidMount() {
        let rs = await getDataTable("select orgid from users where userid = " + Configuration.USER_ID)
        await this.setState({
            loginId: Configuration.USER_ID,
            orgid: rs[0].orgid
        });
    }
    setModal1Visible(modal1Visible) {
        this.setState({ modal1Visible });
    }

    setModal2Visible(modal2Visible) {
        this.setState({ modal2Visible });
    }
    async onAfterSelect(selectedOptions, id, name) {
        if (selectedOptions.length > 0) {
            this.setState({
                [id]: selectedOptions[0].DisplayId,
                [name]: selectedOptions[0].DisplayName,
            })
        }
        else {
            this.setState({
                [id]: '',
                [name]: '',
            })
        }
    }

    async saveAliasing() {
        let MandatoryArray = [
            { "MASTER VALUE": this.state.VAID },
            { "PORTAL HEADER": this.state.PANAME },
            { "PORTAL VALUE": this.state.PVALUE }
        ]
        let check = MandatoryFormFields(MandatoryArray);
        if (check === false) {
            this.setState({ loading: false })
            return false;
        }

        let que = " EXEC FRMCREATEVALUEALIASING_SAVEVALUEALIASING @templateID =  " + this.props.templateID
        que += " , @portalheader = '" + this.state.PANAME + "' , @ValueID = " + this.state.VAID
        que += " , @portalValue = '" + this.state.PVALUE + "' , @loginID =  " + this.state.loginId
debugger
        let rs = await getDataTable(que)
        if (rs.length > 0) {
            await Swal.fire({
                icon: 'success',
                title: 'ALIAISNG UPDATED SUCCESSFULLY',
                showConfirmButton: false,
                timer: 1500
            })
            await this.setModal2Visible(false)
        }
        else {
            await Swal.fire({
                icon: 'info',
                title: 'SOME ERROR OCCURED',
                showConfirmButton: false,
                timer: 1500
            })
        }

    }

    render() {
        return (
            <div>
                <a style={{ color: '#777', marginLeft: '20px' }} onClick={() => this.setModal2Visible(true)}>CREATE ALIASING</a>
                <Modal
                    title="CREATE VALUE ALIASING"
                    width={this.props.width}
                    visible={this.state.modal2Visible}
                    onOk={() => this.setModal2Visible(false)}
                    onCancel={() => this.setModal2Visible(false)}
                    destroyOnClose={true}
                    maskClosable={false}
                    footer={null}
                >
                    <div className='row'>
                        <div className="col-xs-6 col-sm-6 col-md-3 margintop">
                            <label>Select Attribute</label><span className="vcode">*</span>
                            <AutoCompleteCascad
                                id="AttribiteID"
                                frmNm="FRMCREATEALIASING"
                                quryNm="FILLATTRIBUTE"
                                db="IMAGEDB"
                                filter1="CatID"
                                filterId1={this.props.BlockID}
                                filter2=""
                                filterId2=""
                                placeholder="Please Select Attribute"
                                onAfterSelect={(e) => this.onAfterSelect(e, "AID", "ANAME")}
                            ></AutoCompleteCascad>
                        </div>
                        <div className="col-xs-4 col-sm-4 col-md-3 margintop">
                            <label>Select Value</label><span className="vcode">*</span>
                            <AutoCompleteCascad
                                id="tempID"
                                frmNm="FRMVALUEMAPPING"
                                quryNm="FILLVALUES"
                                db="IMAGEDB"
                                filter1="AtrributeID"
                                filterId1={this.state.AID}
                                filter2="BlockID"
                                filterId2={this.props.BlockID}
                                placeholder="Please Select Value"
                                onAfterSelect={(e) => this.onAfterSelect(e, "VAID", "VALUE")}
                            ></AutoCompleteCascad>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-md-3 margintop">
                            <label>Select Portal Attribute</label><span className="vcode">*</span>
                            <AutoCompleteCascad
                                id="AttribiteID"
                                frmNm="FRMCREATEVALUEALIASING"
                                quryNm="FILLPORTALHEADER"
                                db="IMAGEDB"
                                filter1="TemplateID"
                                filterId1={this.props.templateID}
                                filter2=""
                                filterId2=""
                                placeholder="Please Select Attribute"
                                onAfterSelect={(e) => this.onAfterSelect(e, "PAID", "PANAME")}
                            ></AutoCompleteCascad>
                        </div>
                        <div className="col-xs-4 col-sm-4 col-md-3 margintop">
                            <label>Select Portal Value</label><span className="vcode">*</span>
                            <AutoCompleteCascad
                                id="tempID"
                                frmNm="FRMCREATEVALUEALIASING"
                                quryNm="FILLPORTALVALUE"
                                db="IMAGEDB"
                                filter1="TemplateID"
                                filterId1={this.props.templateID}
                                filter2="PortalHeader"
                                filterId2={this.state.PANAME}
                                placeholder="Please Select Value"
                                onAfterSelect={(e) => this.onAfterSelect(e, "PAVID", "PVALUE")}
                            ></AutoCompleteCascad>
                        </div>
                        <div className='col-xs-6 col-sm-3 col-md-2 margintop' >
                            <label>&nbsp;</label>
                            <div className='form-group'>
                                <button className='btn btn-primary' onClick={this.saveAliasing.bind(this)}>Save Aliasing</button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}