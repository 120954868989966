import React, { Component } from "react";
import { Card, Input } from "antd";
import "./troubleTicket.css";
import { Progress } from "antd";
import { Table } from "antd";
import { connect } from "react-redux";
import { APIService } from "../../../Config/action/apiAction";
import { Menu, Dropdown } from "antd";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import LaunchIcon from "@material-ui/icons/Launch";
import Chip from "@material-ui/core/Chip";
import TicketModal from "../../Common/createTicketModal";
import { TokenService } from "../../../Config/action/tokenAction";
import { Fragment } from "react";
import PageTitle from "../../Common/pageTitle/index";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import moment from "moment";
import { Spin } from "antd";

class TroubleTicketDetail extends Component {
  gridStyle = {
    width: "100%",
    backgroundColor: "white",
  };

  openEditModal = (record) => {
    this.setState({
      openEdit: true,
      editRecord: record,
    });
  };

  openNewModal = () => {
    this.setState({
      ...this.state,
      open: true,
      editRecord: "",
    });
  };

  handleClose = (reload) => {
    this.setState(
      {
        open: false,
        editRecord: "",
      },
      () => {
        if (reload) {
          this.getTicketList();
        }
      }
    );
  };

  handleEditClose = (reload) => {
    this.setState(
      {
        openEdit: false,
        editRecord: "",
      },
      () => {
        if (reload) {
          this.getTicketList();
        }
      }
    );
  };

  handleChange = (key, value) => {
    this.setState(
      {
        ...this.state,
        [key]: value,
      }
    );
  };

  handleSearchSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (this.state.searchColumn && this.state.searchValue) {
      let filterdata = this.state.tableData.filter((item, index) => {
        let searchString = this.state.searchValue.toString();
        let columnValue = item[this.state.searchColumn];
        if (columnValue.toString().includes(searchString)) {
          return item;
        }
      });

      this.setState({
        filteredData: filterdata,
      });
    } else if (this.state.searchValue && this.state.searchColumn === "") {
      let filterdata = this.state.tableData.filter((item, index) => {
        if (JSON.stringify(item).includes(this.state.searchValue)) {
          return item;
        }
      });
      this.setState(
        {
          filteredData: filterdata,
        }
      );
    } else if (
      this.state.searchValue === "" &&
      this.state.searchColumn === ""
    ) {
      let tableData = this.state.tableData;
      this.setState({
        filteredData: tableData,
      });
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      filterValues: "",
      user: null,
      filteredData: [],
      editRecord: "",
      open: false,
      openEdit: false,
      searchColumn: "",
      searchValue: "",
      loading: false,
      columns: [
        {
          title: "Ticket ID",
          dataIndex: "TicketID",
          render: (text, record) => {
            return {
              props: {
                style: {
                  fontWeight: "bold",
                },
              },
              children: <div>{text}</div>,
            };
          },
          sorter: (a, b) => parseInt(a.TicketID) - parseInt(b.TicketID),
          sortDirections: ["descend", "ascend"],
        },

        {
          title: "Type",
          dataIndex: "TicketType",
          sorter: (a, b) => a.TicketType.length - b.TicketType.length,
          sortDirections: ["descend", "ascend"],
        },

        {
          title: "Created Date",
          dataIndex: "CREATE DATE",
          render: (date, record) => {
            return <span>{moment(date).format("DD/MM/YYYY hh:mm a")}</span>;
          },
          defaultSortOrder: "descend",
          sorter: (a, b) => {
            let aDate = new Date(a["CREATE DATE"]).getTime();
            let bDate = new Date(b["CREATE DATE"]).getTime();
            return aDate - bDate;
          },
          sortDirections: ["descend", "ascend"],
        },

        {
          title: "Status",
          dataIndex: "Status",
          render: (text, record) => {
            if (text.toLowerCase() === "initialized") {
              return (
                <Chip
                  label="Intialized"
                  style={{
                    backgroundColor: "#FFCC00",
                    color: "white",
                    fontSize: "12px",
                  }}
                />
              );
            } else if (text.toLowerCase() === "completed") {
              return (
                <Chip
                  label="Completed"
                  style={{
                    backgroundColor: "green",
                    color: "white",
                    fontSize: "12px",
                  }}
                />
              );
            } else if (text.toLowerCase() === "close") {
              return (
                <Chip
                  label="Close"
                  style={{
                    backgroundColor: "green",
                    color: "white",
                    fontSize: "12px",
                  }}
                />
              );
            } else if (text.toLowerCase() === "cancel") {
              return (
                <Chip
                  label="Cancel"
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    fontSize: "12px",
                  }}
                />
              );
            } else {
              return (
                <Chip
                  label="Progress"
                  style={{
                    backgroundColor: "#007bff",
                    color: "white",
                    fontSize: "12px",
                  }}
                />
              );
            }
          },
          sorter: (a, b) => a["Status"].length - b["Status"].length,
          sortDirections: ["descend", "ascend"],
        },

        {
          title: "Action",
          dataIndex: "Action",
          render: (text, record) => (
            <LaunchIcon
              style={{ cursor: "pointer" }}
              onClick={() => this.openEditModal(record)}
            />
          ),
        },
      ],
    };
  }

  componentDidMount() {
    this.props.dispatch(TokenService("getToken", "auth_user")).then((token) => {
      this.setState(
        {
          user: token,
        },
        () => {
          this.getTicketList();
        }
      );
    });
  }

  getTicketList = () => {
    this.setState(
      {
        loading: true,
      },
      () => {
        this.props
          .dispatch(
            APIService(
              "POST",
              "GetPaymentData?query=GETTROUBLE_TICKET @USERID = " +
                this.state.user.ID,
              ""
            )
          )
          .then((data) => {
            if ((data.status === 200) & (data.data.data.length > 0)) {
              this.setState(
                {
                  tableData: data.data.data,
                },
                () => {
                  let data = this.state.tableData.filter((item, index) => {
                    // item["CREATE DATE"] = moment(item["CREATE DATE"]).format("DD/MM/YYYY hh:mm a")
                    return item;
                  });
                  this.setState({
                    filteredData: data,
                    loading: false,
                  });
                }
              );
            } else {
              this.setState({ loading: false });
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    );
  };

  render() {
    return (
      <div className="content-wrapper">
        <div
          className="loader"
          style={{ display: this.state.loading ? "block" : "none" }}
        >
          <div className="loader-item">
            <Spin />
          </div>
        </div>
        <section className="content" style={{ background: "white" }}>
          <div
            id="ctl00_CPHMaincontent_divclient"
            className="box box-success"
            style={{ marginTop: "-13px" }}
          >
            <div className="box-header with-border">
              <h3 className="box-title">
                Tickets
                {/* <sup>
                  (<span className="vcode">*</span> Fields are mandatory)
                </sup> */}
                <span
                  id="ctl00_CPHMaincontent_DivOrdNo"
                  style={{ display: "inline" }}
                ></span>
              </h3>
              <div className="pull-right" style={{ marginLeft: "10px" }}>
                <button
                  onClick={this.openNewModal}
                  className="btn btn-primary"
                >
                  Add Ticket
                </button>
                {/* <PageTitle
                  handleClose={this.handleClose}
                  open={this.state.open}
                  openModal={this.openNewModal}
                  title={"Tickets"}
                /> */}
              </div>{" "}
              <TicketModal
                handleClose={this.handleEditClose}
                open={this.state.openEdit}
                currentRecord={this.state.editRecord}
              />
              <TicketModal
                handleClose={this.handleClose}
                open={this.state.open}
                currentRecord={""}
              />
            </div>
            <div
              className="box-body"
              id="orderDetails"
              style={{ display: "block" }}
            >
              <div className="row">
                <div className="col-md-3 col-sm-12 col-xs-12">
                  <Card.Grid hoverable={false} style={this.gridStyle}>
                    <span className="card-title-text">Initialized</span>
                    <h4 className="card-digit-text">
                      {this.state.tableData.length > 0
                        ? this.state.tableData[0].initializeTicketCount
                        : 0}
                    </h4>
                    {/* <Progress percent={20} size="small" showInfo={false} /> */}
                  </Card.Grid>
                </div>

                <div className="col-md-3 col-sm-12 col-xs-12">
                  <Card.Grid hoverable={false} style={this.gridStyle}>
                    <span className="card-title-text">In Progress</span>
                    <h4 className="card-digit-text">
                      {this.state.tableData.length > 0
                        ? this.state.tableData[0].progressTicketCount
                        : 0}
                    </h4>
                    {/* <Progress percent={45} size="small" showInfo={false} /> */}
                  </Card.Grid>
                </div>
                <div className="col-md-3 col-sm-12 col-xs-12">
                  <Card.Grid hoverable={false} style={this.gridStyle}>
                    <span className="card-title-text">Completed</span>
                    <h4 className="card-digit-text">
                      {this.state.tableData.length > 0
                        ? this.state.tableData[0].completedTicketCount
                        : 0}
                    </h4>
                    {/* <Progress percent={75} size="small" showInfo={false} /> */}
                  </Card.Grid>
                </div>

                <div className="col-md-3 col-sm-12 col-xs-12">
                  <Card.Grid hoverable={false} style={this.gridStyle}>
                    <span className="card-title-text">Total Tickets</span>
                    <h4 className="card-digit-text">
                      {this.state.tableData.length > 0
                        ? this.state.tableData[0].completedTicketCount +
                          this.state.tableData[0].progressTicketCount +
                          this.state.tableData[0].initializeTicketCount
                        : 0}
                    </h4>
                    {/* <Progress percent={30} size="small" showInfo={false} /> */}
                  </Card.Grid>
                </div>
              </div>
            </div>
            <br />
            <div
              className="box-body"
              id="orderDetails"
              style={{ display: "block" }}
            >
              <div className="row">
                <div
                  className="d-flex inputClassHandler"
                  style={{ marginBottom: "10px" }}
                >
                  <div className="form-group" style={{ marginRight: "10px" }}>
                    <input
                      name="Search Keyword"
                      id="Search Keyword"
                      value={this.state.searchValue}
                      onChange={(e) =>
                        this.handleChange("searchValue", e.target.value)
                      }
                      placeholder="Search Keyword"
                      tabIndex={1}
                      className="form-control"
                    />
                  </div>
                  <div className="form-group" style={{ marginRight: "10px" }}>
                    <select
                      className="currency-select"
                      onChange={(e) =>
                        this.handleChange("searchColumn", e.target.value)
                      }
                      label="Age"
                      style={{ height: "30px" }}
                    >
                      <option>Select</option>
                      <option value={"TicketType"}>Type</option>
                      <option value={"TicketID"}>Ticket ID</option>
                      <option value={"Status"}>Status</option>
                    </select>
                  </div>
                  <div className="form-group" style={{ marginRight: "10px" }}>
                    <button
                      onClick={this.handleSearchSubmit}
                      className="btn btn-primary"
                    >
                      Search
                    </button>
                  </div>
                </div>

                <div
                  className="col-xs-12 col-sm-12 col-md-12 margintop"
                  style={{ marginTop: "10px" }}
                >
                  <Table
                    style={{ width: "100%" }}
                    rowKey={(record) => record.TicketID}
                    columns={this.state.columns}
                    dataSource={this.state.filteredData}
                    onChange={this.onChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: () => {
      dispatch({
        type: "loginUser",
      });
    },
    logoutUser: () => {
      dispatch({
        type: "logoutUser",
      });
    },
    dispatch,
  };
};

export default connect(null, mapDispatchToProps)(TroubleTicketDetail);
