import React, { Component } from 'react';
import { Configuration, getDataTable, MandatoryFormFields, GetNewDrpSheet } from '../Configuration';
import AutoCompletemulti from '../Actions/Controls/AutoComplete/autoCompleteMultiple';
import Table from '../Actions/Controls/Table/AntDTable'
import '../ORDER/OrderMaster/style.css'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { Spin } from 'antd';
import 'sweetalert2/src/sweetalert2.scss'
import MAP from './MODAL/OrgPortalMapping'

export default class frmorganization extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginId: 0,
            OrgData: [],
            BlockID: [],
            organizationID: '',
            orID: 0,
            orgname: '',
            orgcode: '',
            cntno: '',
            mail: '',
            city: '',
            stt: '',
            pin: '',
            add: '',
            cntry: '',
            loading: false,
            gstno: '',
            drpToken: '',
            AccountStatus: '0'
        };
    }
    async componentDidMount() {
        let rs1 = await getDataTable("select orgid from users where userid = " + Configuration.USER_ID)
        await this.setState({
            loginId: Configuration.USER_ID,
            orID: rs1[0].orgid
        });
        await this.getdata();
    }
    async getdata() {
        this.setState({
            loading: true
        })
        let Que = "EXEC frmOrgnizationMaster_GetOrgList @loginID = " + this.state.loginId
        let rs = await getDataTable(Que)
        await this.setState({
            OrgData: rs,
            loading: false,
            organizationID: '',
            orgname: '',
            orgcode: '',
            add: '',
            city: '',
            stt: '',
            cntry: '',
            pin: '',
            cntno: '',
            mail: '',
            gstno: '',
            BlockID: [],
            drpToken: ''
        })
        this.forceUpdate()
    }
    myChangeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }
    onAfterSelect(selectedOptions, id, name) {
        if (selectedOptions.length > 0) {
            this.setState({
                [id]: selectedOptions[0].DisplayId,
                [name]: selectedOptions[0].DisplayName
            })
        }
        else {
            this.setState({
                [id]: '',
                [name]: ''
            })
        }
    }

    async onAutoCOmpletMultiPleSelect(selectedOptions, name) {
        if (selectedOptions.length > 0) {
            this.setState({
                [name]: selectedOptions
            });
        }
        else {
            this.setState({
                [name]: ""
            })
        }
    }
    async deleteRow(e) {
        if (this.state.loginId === "1" || this.state.loginId === "10") {
            let st = await Swal.fire({ title: 'ARE YOU SURE WANT TO DELETE?', showCancelButton: true, confirmButtonText: 'YES', cancelButtonText: 'NO', })
            if (!st.isConfirmed) {
                return false;
            }
            this.setState({
                loading: true
            })
            let Que = "update  organizationmaster set DIS = 1 output inserted.orgid where orgid = " + e["#orgID"]
            let rs = await getDataTable(Que)
            if (rs.length > 0) {
                let que1 = "EXEC frmOrganizationMaster_updateusers @loginID = " + this.state.loginId + " , @orgid = " + e["#orgID"]
                await getDataTable(que1)
                Swal.fire({
                    icon: 'info',
                    title: 'Successfully Deleted',
                    showConfirmButton: false,
                    timer: 1500
                })
                await this.getdata()
            }
            else {
                alert("Some Error Occurred")
                this.setState({
                    loading: false
                })
            }
        }
        else {
            Swal.fire({
                icon: 'info',
                title: 'YOU ARE NOT ALLOWED TO DELETE !',
                showConfirmButton: false,
                timer: 1500
            })
            return;
        }
    }

    async UpdateData() {
        let que = " Update OrganizationMaster SET OrgCode = '" + this.state.orgcode + "' , OrgName = '" + this.state.orgname + "' , Address = '" + this.state.add + "' , City = '" + this.state.city + "' , State = '" + this.state.stt + "' , Country = '" + this.state.cntry + "' , PIN = '" + this.state.pin + "' , Phone = '" + this.state.cntno + "' , email = '" + this.state.mail + "' , LoginId = " + this.state.loginId + " , Logdate = getdate() , GSTNO = '" + this.state.gstno + "' , AccountStatus = " + this.state.AccountStatus
        que += " output inserted.orgid where orgid = " + this.state.organizationID
        let rs = await getDataTable(que)
        if (rs.length > 0) {

            let que2 = "Delete from DropBoxAccessToken where OrgID = " + this.state.organizationID
            await getDataTable(que2)
            if (this.state.drpToken !== "" || this.state.drpToken !== undefined) {
                let que3 = " INSERT INTO DropBoxAccessToken (OrgID, DrpToken, logDate) VALUES (" + this.state.organizationID + ",'" + this.state.drpToken + "',getdate()) "
                await getDataTable(que3)
            }
            let que1 = " Delete from BlockOrganizationMapping where orgid = " + this.state.organizationID
            await getDataTable(que1)

            for (let i = 0; i < this.state.BlockID.length; i++) {
                const ele = this.state.BlockID[i];
                let que2 = " Insert into BlockOrganizationMapping (Orgid, BlockID, loginID) VALUES (" + this.state.organizationID + "," + ele['DisplayId'] + "," + this.state.loginId + ") "
                await getDataTable(que2)
            }

            this.setState({
                organizationID: '',
                orgname: '',
                orgcode: '',
                add: '',
                city: '',
                stt: '',
                cntry: '',
                pin: '',
                cntno: '',
                mail: '',
                gstno: '',
                BlockID: [],
                drpToken: ''
            })
            await Swal.fire({
                icon: 'success',
                title: 'SUCCESSFULLY UPDATED',
                showConfirmButton: false,
                timer: 2000
            })
            await this.getdata()
        }


    }

    async SaveOrgnization() {
        let MandatoryArray = [
            { "Organization Code": this.state.orgcode }
            , { "Organization Name": this.state.orgname }
            , { "Contact No": this.state.cntno }
            , { "Email": this.state.mail }
            , { "Address": this.state.add }
            , { "Block": this.state.BlockID }
        ]
        let check = MandatoryFormFields(MandatoryArray);
        if (check == false) {
            return false;
        }
        let data = [];

        if (this.state.organizationID !== '') {
            await this.UpdateData();
            return;
        }


        let Que = "Select * from OrganizationMaster where OrgName = '" + this.state.orgname + "' and OrgCode = '" + this.state.orgcode + "'"
        let rs = await getDataTable(Que)
        if (rs.length > 0) {
            Swal.fire({
                position: 'top-end',
                icon: 'info',
                title: 'ALREADY EXIST',
                showConfirmButton: false,
                timer: 1500
            })
            return;
        }
        this.setState({
            loading: true
        })
        let Query = "INSERT INTO OrganizationMaster (OrgCode, OrgName, Address, City, State, Country, PIN, Phone, email, LoginId, Logdate,DIS,GSTNO,AccountStatus) output inserted.orgid VALUES  ('" + this.state.orgcode + "','" + this.state.orgname + "','" + this.state.add + "','" + this.state.city + "','" + this.state.stt + "','" + this.state.cntry + "','" + this.state.pin + "','" + this.state.cntno + "','" + this.state.mail + "'," + this.state.loginId + ",getdate(),0,'" + this.state.gstno + "'," + this.state.AccountStatus + ")"

        let res = await getDataTable(Query)
        if (res.length > 0) {

            let que2 = "Delete from DropBoxAccessToken where OrgID = " + res[0].orgid
            await getDataTable(que2)
            if (this.state.drpToken !== "" || this.state.drpToken !== undefined) {
                let que3 = " INSERT INTO DropBoxAccessToken (OrgID, DrpToken, logDate) VALUES (" + res[0].orgid + ",'" + this.state.drpToken + "',getdate()) "
                await getDataTable(que3)
            }

            for (let i = 0; i < this.state.BlockID.length; i++) {
                const ele = this.state.BlockID[i];
                let que1 = " Insert into BlockOrganizationMapping (Orgid, BlockID, loginID) VALUES (" + res[0].orgid + "," + ele['DisplayId'] + "," + this.state.loginId + ") "
                await getDataTable(que1)
            }
            await Swal.fire({
                icon: 'success',
                title: 'SUCCESSFULLY SAVED',
                showConfirmButton: false,
                timer: 2000
            })

        }
        else {
            Swal.fire({
                position: 'top-end',
                icon: 'warning',
                title: 'SOME ERROR OCCURED',
                showConfirmButton: false,
                timer: 1500
            })
            this.setState({
                loading: false
            })
        }
        // console.log(OrderL = dataist);
        await this.getdata(this);
        this.forceUpdate();
    }

    async clearData() {
        this.setState({
            organizationID: '',
            orgname: '',
            orgcode: '',
            add: '',
            city: '',
            stt: '',
            cntry: '',
            pin: '',
            cntno: '',
            mail: '',
            BlockID: [],
            AccountStatus: '0'
        })
    }

    async EditRowFn(e) {
        let st = window.confirm("Are you sure want to Edit ?")
        if (!st) {
            return false;
        }
        let Que = "select OrganizationMaster.OrgID, OrgCode, OrgName, Address, City, State, Country, PIN, Phone, email,GSTNO,ISNULL(DrpToken,'') as DrpToken,AccountStatus from OrganizationMaster left outer join DropBoxAccessToken ds on ds.OrgID = OrganizationMaster.OrgID where OrganizationMaster.orgid = " + e["#orgID"]
        let rs = await getDataTable(Que)
        if (rs.length > 0) {

            let que1 = " SELECT bm.BlockID as DisplayId, BlockMaster.BlockName as DisplayName FROM BlockOrganizationMapping bm INNER JOIN BlockMaster ON bm.BlockID = BlockMaster.CategoryID WHERE (bm.Orgid = " + rs[0].OrgID + " )"
            let rs1 = await getDataTable(que1)
            debugger
            this.setState({
                organizationID: rs[0].OrgID,
                orgname: rs[0].OrgName,
                orgcode: rs[0].OrgCode,
                add: rs[0].Address,
                city: rs[0].City,
                stt: rs[0].State,
                cntry: rs[0].Country,
                pin: rs[0].PIN,
                cntno: rs[0].Phone,
                mail: rs[0].email,
                gstno: rs[0].GSTNO,
                BlockID: rs1,
                drpToken: rs[0].DrpToken,
                AccountStatus: rs[0]['AccountStatus']
            })
        }
    }

    render() {
        return (
            <div className="content-wrapper">
                <div className='loader' style={{ display: this.state.loading ? "block" : "none" }}>
                    <div className='loader-item'>
                        <Spin />
                    </div>
                </div>
                <section className="content">
                    <div id="ctl00_CPHMaincontent_divclient" className="box box-success" style={{ marginTop: '-13px' }}>
                        <div className="box-header with-border">
                            <h3 className="box-title">Organization Master<sup>(<span className="vcode">*</span> Fields are mandatory)</sup>
                                <span id="ctl00_CPHMaincontent_DivOrdNo" style={{ display: 'inline' }}>
                                </span>
                            </h3>
                            {
                                (this.state.orID === 1 || this.state.loginId == "1") &&
                                <div className='pull-right'>
                                    <MAP width='800px' logid={this.state.loginId} />
                                </div>
                            }
                        </div>
                        <div className="box-body" id="orderDetails" style={{ display: 'block' }}>
                            <div className="row">
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                    <label>Organization Name</label><span className="vcode">*</span>
                                    <div className="form-group">
                                        <input name="orgname" id="orgname" defaultValue={this.state.orgname} value={this.state.orgname} onChange={this.myChangeHandler} placeholder="Enter Organization Name" tabIndex={1} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                    <label>Organization Code</label><span className="vcode">*</span>
                                    <div className="form-group">
                                        <input name="orgcode" id="orgcode" defaultValue={this.state.orgcode} value={this.state.orgcode} onChange={this.myChangeHandler} placeholder="Enter Organization code" tabIndex={1} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                    <label>Contact No.</label><span className="vcode">*</span>
                                    <div className="form-group">
                                        <input name="cntno" id="cntno" type="tel" defaultValue={this.state.cntno} value={this.state.cntno} onChange={this.myChangeHandler} placeholder="Enter Contact No." tabIndex={1} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                    <label>Email</label><span className="vcode">*</span>
                                    <div className="form-group">
                                        <input name="mail" id="mail" type="email" defaultValue={this.state.mail} value={this.state.mail} onChange={this.myChangeHandler} placeholder="Enter Mail Address" tabIndex={1} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                    <label>City</label>
                                    <div className="form-group">
                                        <input name="city" id="city" defaultValue={this.state.city} value={this.state.city} onChange={this.myChangeHandler} tabIndex={1} placeholder="Enter city" className="form-control" />
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                    <label>State</label>
                                    <div className="form-group">
                                        <input name="stt" id="stt" defaultValue={this.state.stt} value={this.state.stt} onChange={this.myChangeHandler} tabIndex={1} placeholder="Enter State" className="form-control" />
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                    <label>PIN Code</label>
                                    <div className="form-group">
                                        <input name="pin" type="pin" id="tp1" defaultValue={this.state.pin} value={this.state.pin} onChange={this.myChangeHandler} placeholder="Enter PIN code" tabIndex={1} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-3 margintop">
                                    <label>Address</label><span className="vcode">*</span>
                                    <div className="form-group">
                                        <input name="add" id="add" defaultValue={this.state.add} value={this.state.add} onChange={this.myChangeHandler} placeholder="Enter Address" tabIndex={1} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                    <label>Country</label>
                                    <div className="form-group">
                                        <input name="cntry" id="cntry" defaultValue={this.state.cntry} value={this.state.cntry} onChange={this.myChangeHandler} placeholder="Enter Country" tabIndex={1} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                    <label>GST No.</label>
                                    <div className="form-group">
                                        <input name="gstno" id="gstno" defaultValue={this.state.gstno} value={this.state.gstno} onChange={this.myChangeHandler} placeholder="Enter GST No." tabIndex={1} className="form-control" />
                                    </div>
                                </div>
                                {this.state.orID === 1 &&
                                    <div className="col-xs-6 col-sm-6 col-md-3 margintop" style={{ marginTop: '2px' }}>
                                        <label>Select Block</label><span className="vcode">*</span>
                                        <AutoCompletemulti
                                            id="BlockID"
                                            frmNm="FRMCATPRODUCTMASTER"
                                            quryNm="FILLCAT"
                                            db="IMAGEDB"
                                            placeholder="Please Select Block"
                                            onAfterSelect={(e) => this.onAutoCOmpletMultiPleSelect(e, "BlockID")}
                                            isValid={this.state.isValid}
                                            defauldVal={this.state.BlockID}
                                        ></AutoCompletemulti>
                                    </div>
                                }
                                <div className="col-xs-6 col-sm-6 col-md-5 margintop">
                                    <label>DropBox Access Token</label>
                                    <div className="form-group">
                                        <input name="drpToken" id="drpToken" defaultValue={this.state.drpToken} value={this.state.drpToken} onChange={this.myChangeHandler} placeholder="Enter GST No." tabIndex={1} className="form-control" />
                                    </div>
                                </div>
                                {this.state.orID === 1 &&
                                    <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                        <label>ACCOUNT STATUS</label><span className='vcode'>*</span>
                                        <div className="form-group">
                                            <select name='AccountStatus' defaultValue={this.state.AccountStatus} value={this.state.AccountStatus} onChange={this.myChangeHandler} className='form-control'>
                                                <option value="1">Normal</option>
                                                <option value="0">Trial</option>
                                            </select>
                                        </div>
                                    </div>
                                }
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                    <label>&nbsp;</label>
                                    <div className="form-group">
                                        <button onClick={this.SaveOrgnization.bind(this)} className='btn btn-primary'>Save Organization</button>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12 margintop">
                                    {this.state.OrgData.length > 0 && this.state.OrgData != undefined &&
                                        <Table
                                            data={[...this.state.OrgData]}
                                            id='fielddata'
                                            Delete={this.state.orID === 1 ? "true" : "false"}
                                            DeleteFn={(e) => this.deleteRow(e)}
                                            EditRow='true'
                                            EditRowFn={(e) => this.EditRowFn(e)} >
                                        </Table>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
