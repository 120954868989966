import { Modal, Button } from 'antd';

import React, { Component } from 'react';
import AutoCompleteInput from '../AutoComplete/autoComplete';
import AutoCompletemulti from '../AutoComplete/autoCompleteMultiple';
import AutoCompleteCascad from '../AutoComplete/autoComplete.cascad';
import '../../../ORDER/OrderMaster/style.css'
import { Configuration,MandatoryFormFields,getDataTable } from '../../../Configuration';
import Swal, { swal } from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

let portalList = [];
let portalid = [];
export default class PortalConfig extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            modal1Visible: false,
            modal2Visible: false,
            fname: "",
            FValue: '',
            portal: "",
            prtl: '',
            profileid:'',
            profilename:'',
            CountryId: []
        };
    }


    async componentDidMount() {
        await this.fillPortaldata(this);
    }

    async fillPortaldata() {
        let data = [];
        let Query = "select portals.PID,PortalName,URL,ProfileName from Portals left outer join ImageProfile on ProfileID = ImageProfile.PID where Dis = 0"
        data = await getDataTable(Query)
        portalList = data
        this.forceUpdate();
    }

    setModal2Visible(modal2Visible) {
        this.setState({ modal2Visible });
    }

    myChangeHandler = (event) => { 
        this.setState({ [event.target.name]: event.target.value });
        this.forceUpdate();
    }

    async SavePortal() {

        let MandatoryArray = [
            { "portal Name": this.state.portal }
            , { "URL": this.state.url }
        ]
        let check = MandatoryFormFields(MandatoryArray);
        if (check == false) {
            return false;
        }
        let data = []; 
        let Que = "Select * from Portals where PortalName = '" + this.state.portal + "'" 
        let dt = await getDataTable(Que)
        
        if (dt.length > 0) {
            Swal.fire({
                position: 'top-end',
                icon: 'info',
                title: 'Profile Already Exist',
                showConfirmButton: false,
                timer: 1500
            })
            return;
        }

        let Query = " INSERT INTO Portals( PortalName, URL,LoginId, LogDate) output inserted.PortalName VALUES ('" + this.state.portal + "','"+this.state.url+"',"+this.props.logid+",cast(getdate() as date))"
        let rs = await getDataTable(Query)        
        if (rs) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Successfully Saved',
                showConfirmButton: false,
                timer: 1500
            })
        }
        else {
            Swal.fire({
                position: 'top-end',
                icon: 'warning',
                title: 'Portal Not Saved',
                showConfirmButton: false,
                timer: 1500
            })
        }
        await this.fillPortaldata(this);
        this.forceUpdate();

    }


    chkFacChange(evt) {
        
        let facIds = portalid;
        if (evt.target.checked) {
            
            facIds.push(evt.target.value);
            portalid = facIds

        }
        else {
            facIds.pop(evt.target.value);;
            portalid = facIds
        }
        this.forceUpdate();
    }

    async updateData() {

        
        if (!this.state.profileid) {
            Swal.fire({
                position: 'top-end',
                icon: 'warning',
                title: 'Select Image Profile First',
                showConfirmButton: false,
                timer: 1500
            })
            return;
        }
        let arr = portalid
        if (arr.length > 0) {
            for (let index = 0; index < arr.length; index++) {
                let Query = "UPDATE       Portals SET  ProfileID = "+this.state.profileid+"  WHERE   (PID = "+arr[index]+")"
                let rs = await getDataTable(Query)
            }

            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Successfully Saved',
                showConfirmButton: false,
                timer: 1500
            })
        }
        else {
            Swal.fire({
                position: 'top-end',
                icon: 'warning',
                title: 'Select Portal first',
                showConfirmButton: false,
                timer: 1500
            })
            return;
        }
        await this.fillPortaldata(this);
        this.forceUpdate();
    }

    onAfterSelect(selectedOptions, id, name) {
        if (selectedOptions.length > 0) {
    
          this.setState({
            [id]: selectedOptions[0].DisplayId,
            [name]: selectedOptions[0].DisplayName
          })
    
        }
        else {
          this.setState({
            [id]: '',
            [name]: ''
          })
        }
        this.forceUpdate();
      }

    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({ [nam]: val });

        this.forceUpdate();
    }
    async onAutoCOmpletMultiPleSelect(selectedOptions, name) {
        if (selectedOptions.length > 0) {
            this.setState({
                [name]: selectedOptions
            });
        } else {
            this.setState({
                [name]: ""
            });
        }
    }


    render() {
        return (
            <div>
                <button type="button" onClick={() => this.setModal2Visible(true)} className="btn btn-info">Portal Configuration</button>

                <Modal
                    title="Portal Configuration"
                    width={this.props.width}
                    visible={this.state.modal2Visible}
                    onOk={() => this.setModal2Visible(false)}
                    onCancel={() => this.setModal2Visible(false)}
                    destroyOnClose={true}
                    maskClosable={false}
                    footer = {null}
                >
                    <div className='row'>
                        <div className='col-xs-6 col-sm-6 col-md-3 margintop'>
                            <label>Add New Portal</label><span className="vcode">*</span>
                            <input type='text' id='portal' name='portal' autoComplete='off' onChange={this.myChangeHandler} className="form-control" ></input>
                        </div>
                        <div className='col-xs-6 col-sm-6 col-md-3 margintop'>
                            <label>URL</label><span className="vcode">*</span>
                            <input type='text' id='url' name='url' autoComplete='off' onChange={this.myChangeHandler} className="form-control" ></input>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-md-3 margintop">
                            <label>SELECT COUNTRY</label><span className="vcode">*</span>
                            <AutoCompletemulti
                                id="PID"
                                frmNm="FRMIMAGE"
                                quryNm="FILLCOUNTRY"
                                db="IMAGEDB"
                                placeholder="Please Select Country"
                                onAfterSelect={(e) => this.onAutoCOmpletMultiPleSelect(e, "CountryId")}
                                isValid={this.state.isValid}
                            ></AutoCompletemulti>
                        </div>
                        <div className='col-md-1' >
                            <label>&nbsp;</label>
                            <button className='btn btn-primary' onClick={this.SavePortal.bind(this)}>Save Portal</button>
                        </div>



                        <div className="col-md-12 form-group">
                            <div className="table table-hover datatable table-responsive hover" style={{ height: "200px" }}>
                                <table className="table table-border table-fixed datatable" id="tblFactoryList">
                                    <thead>
                                        <tr>
                                            <th>Action</th>
                                            <th>portalName</th>
                                            <th>url</th>
                                            <th>Profile</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {portalList.map((fac, key) => {
                                            return (<tr key={key}>
                                                <td><input key={key} value={fac.PID} id={fac.PID} type="checkbox" onChange={this.chkFacChange.bind(this)}></input>&nbsp;{key + 1}</td>
                                                <td>{fac.PortalName}</td>
                                                <td>{fac.URL}</td>
                                                <td>{fac.ProfileName}</td>
                                            </tr>)

                                        })}
                                    </tbody>

                                </table>

                            </div>

                        </div>
                        <div className='col-md-2 col-sm-6' >
                            <label>Select Image Profile</label><span className="vcode">*</span>
                            <AutoCompleteInput
                                id="profileid"
                                frmNm="FRMPORTALCONFIG"
                                quryNm="FILLPROFILE"
                                db="IMAGEDB"
                                placeholder="Please Select Article"
                                onAfterSelect={(e) => this.onAfterSelect(e, "profileid", "profilename")}
                                isValid={this.state.isValid}
                            ></AutoCompleteInput>
                        </div>
                        <div className='col-md-1' >
                            <label>&nbsp;</label>
                            <button className='btn btn-primary' onClick={this.updateData.bind(this)} >Save Value</button>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}