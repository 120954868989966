import React, { Component } from "react";
import Table from "../Actions/Controls/Table/AntDTable";
import axios from "axios";
import AutoCompleteCascad from "../Actions/Controls/AutoComplete/autoComplete.cascad";
import {
  Configuration,
  getDataTable,
  sleep,
  MandatoryFormFields,
  GetNewDrpSheet,
  json2portalExport,
  postDataToNamshi,
  getNamshiJobDetails,
  UpdateNamshiImage,
  json2xlsx,
  EasyEcomApi,
  CreateListingAmazon,
  IncreffApi,
  UpdateIncreffApi,
  CreateListingYoox,
  flipkartTokenGen,
  getTrendyolAttributes,
} from "../Configuration";
import { Modal, Spin } from "antd";
import TEMP from "../IMAGE/MODAL/frmTemplate";
import "antd/dist/antd.css";
import "../ORDER/OrderMaster/style.css";
import Swal, { swal } from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { mapValues, template } from "lodash";
import SELECTEDATTRIBUTES from "./frmSelectAttributeUpdate";
import SETPRIORITY from "./frmSetPriorityImage";
import GETDETAILSYOOX from "./frmGetDetailsYoox";
import { file } from "jszip";
import { OfflinePinRounded } from "@material-ui/icons";
import { encodeBase64 } from "tweetnacl-util";
const NamshiCredStg = "953f8d16-4211-11ec-a267-42010a6a0014";
// const NamshiCredStg = "772a0098-3579-11ec-a267-42010a6a0014";
const NamshiCredPrd_UAE = "b4eb442e-9a18-11ec-98ed-42010a590054";
const NamshiCredPrd_KSA = "c6348e57-87c1-11ef-8822-42010a590025";
const NamshiStgURL = "https://marketplace.namstg.net";
const NamshiPrdURL = "https://marketplace.namshi.net";
const EasyEcomUrl = "https://api.easyecom.io/Products/CreateMasterProduct";
const EasyEcomkey = "214114b0b61c5f09b9bfe11019feed75ee58716b";
const EasyEcomToken =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvbG9hZGJhbGFuY2VyLmVhc3llY29tLmlvXC9hY2Nlc3NcL3Rva2VuIiwiaWF0IjoxNjkzMzg4Nzc5LCJleHAiOjE3MDEyNzI3NzksIm5iZiI6MTY5MzM4ODc3OSwianRpIjoiRFhqbFp2ZUVtc01FOFZmVyIsInN1YiI6MTMwMzI4LCJwcnYiOiJhODRkZWY2NGFkMDExNWQ1ZWNjYzFmODg0NWJjZDBlN2ZlNmM0YjYwIiwidXNlcl9pZCI6MTMwMzI4LCJjb21wYW55X2lkIjoxODcsInJvbGVfdHlwZV9pZCI6MiwicGlpX2FjY2VzcyI6MCwicm9sZXMiOm51bGwsImNfaWQiOjE4NywidV9pZCI6MTMwMzI4LCJsb2NhdGlvbl9yZXF1ZXN0ZWRfZm9yIjoxODd9.5dY_3HoYpEpQ_zOyXICXgug1zcN9hZpHdV5hwxXc-Ks";
const EasyEcomUpdate = "https://api.easyecom.io/Products/UpdateMasterProduct";
const fs = require("fs");
let files = "";

export default class frmExportTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginId: 2,
      orgID: 0,
      catID: 0,
      portalID: 0,
      loading: false,
      lotID: 0,
      adata: [],
      dt: [],
      plist: [],
      artData: [],
      liveArt: [],
      ImgData: [],
      producid: [],
      pId: [],
      file: null,
      trendyolCategories: [],
      trendyolSelectedCategories: [],
      trendyolWorkingCategory: [],
      trendyolTemplatesPresent: [],
      trendyolSelectedTemplatesToPost: [],
      trendyolStoreFronts: [
        { storeFrontName: "Saudi Arabia", storeFrontCode: "SA", vatRate: 15 },
        {
          storeFrontName: "United Arab Emirates",
          storeFrontCode: "AE",
          vatRate: 5,
        },
      ],
      trendyolSelectedStoreFronts: [],
      templateSelectionModalVisible: true,
      adidasChannelSelectedForDiscount: [],
      retailAuth: "",
    };
  }

  async componentDidMount() {
    let rs = await getDataTable(
      "select orgid from users where userid = " + Configuration.USER_ID
    );
    await this.setState({
      loginId: Configuration.USER_ID,
      orgID: rs[0].orgid,
    });

    const urlParams = new URLSearchParams(window.location.search);
    const oauthCode = urlParams.get("spapi_oauth_code");
    const sellerId = urlParams.get("selling_partner_id");

    //console.log("Authorization Code:", oauthCode);
    //console.log("selling_partner_id:", sellerId);
  }

  async onAfterSelect(selectedOptions, id, name) {
    if (selectedOptions.length > 0) {
      this.setState({
        [id]: selectedOptions[0].DisplayId,
        [name]: selectedOptions[0].DisplayName,
        adata: [],
      });
    } else {
      this.setState({
        [id]: 0,
        [name]: "",
        adata: [],
      });
    }
  }

  help() {
    Modal.info({
      title: "INFO",
      okText: "CLOSE",
      width: "600px",
      closable: true,
      footer: null,
      bodyStyle: { maxHeight: "500px" },
      style: { padding: "0px" },
      centered: true,
      maskClosable: true,
      content: (
        <div>
          <ul>
            <li>
              INCOMPLETE SKU: The count of SKU for which Mandatory Data is
              incomplete.
            </li>
            <li>No Template: The SKU data need to match portal values.</li>
          </ul>
        </div>
      ),
    });
  }

  async GetTemplates() {
    this.setState({
      adata: [],
    });

    let MandatoryArray = [
      { Category: this.state.catID },
      { Portal: this.state.portalID },
      { Lot: this.state.lotID },
    ];
    let check = MandatoryFormFields(MandatoryArray);
    if (check === false) {
      this.setState({ adata: [] });
      return false;
    }

    let que1 = "update NamshiUpdateAttributes set isAllowed = 0";
    let res1 = getDataTable(que1);

    this.setState({ loading: true });
    let Spname = "ExportdataTemplateGen";
    let SpnameNykaa = "ExportdataTemplateGenNykaa";

    let qry =
      "EXEC " +
      Spname +
      " @catID=" +
      this.state.catID +
      ",@portalID=" +
      this.state.portalID +
      ",@lotID=" +
      this.state.lotID +
      ",@orgID=" +
      this.state.orgID;
    if (this.state.portalID === 33) {
      qry =
        "EXEC " +
        Spname +
        " @catID=" +
        this.state.catID +
        ",@portalID=" +
        this.state.portalID +
        ",@lotID=" +
        this.state.lotID +
        ",@orgID=" +
        this.state.orgID;
    }
    let rs = await getDataTable(qry);
    debugger;
    if (rs.length > 0) {
      this.setState({
        adata: rs,
        loading: false,
      });
    } else {
      Swal.fire({
        position: "top-end",
        icon: "info",
        title: "NO DATA FOUND",
        showConfirmButton: false,
        timer: 1500,
      });
      this.setState({
        adata: [],
        loading: false,
      });
    }
    console.log(this.state);
  }

  async GetData(e) {
    let prdct = "";
    let list = "";
    let sheet = 1;
    let r = 1;
    let MandatoryArray = [{ Product: this.state.adata.length }];
    let check = MandatoryFormFields(MandatoryArray);
    if (check === false) {
      return false;
    }

    if (e["#tid"] == 0 && this.state.portalID != 5) {
      let pQue =
        "EXEC FrmExportTemplate_NoTemplate @loginId = " +
        this.state.loginId +
        " ,@pid ='" +
        e["#PList"] +
        "' ,@catid =" +
        this.state.catID;
      pQue += " ,@portalid = " + this.state.portalID;
      this.setState({ loading: true });
      let PRs = await getDataTable(pQue);
      if (PRs.length > 0) {
        await GetNewDrpSheet(this.state.catID, PRs, "No Template.xlsx");
      } else {
        Swal.fire({
          position: "top-end",
          icon: "info",
          title: "No Data Found",
          showConfirmButton: false,
          timer: 1500,
        });
      }
      this.setState({ loading: false });
      return false;
    } else if (
      e["#tid"] == 0 &&
      this.state.portalID == 5 &&
      this.state.lotID == 0
    ) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Please select lot to download error sheet",
        showConfirmButton: false,
        timer: 1500,
      });
      return false;
    }

    if (e["#IncompleteList"] && e["#tid"] != -1) {
      await Swal.fire({
        title: "Which template do you want to export?",
        showConfirmButton: true,
        showCancelButton: true,
        showDenyButton: true,
        confirmButtonText: "Portal Template",
        denyButtonText: "Incomplete data",
        cancelButtonText: "Portal Incomplete Sheet",
      }).then((result) => {
        if (result.isConfirmed) {
          if (e["#CompleteList"]) {
            prdct = "(" + e["#CompleteList"] + ")";
          } else {
            Swal.fire({
              position: "top-end",
              icon: "info",
              title: "No Complete Data",
              showConfirmButton: false,
              timer: 1500,
            });
            r = 0;
          }
        } else if (result.isDenied) {
          prdct = e["#IncompleteList"];
          sheet = 0;
        } else if (result.isDismissed) {
          if (result["dismiss"] === "cancel") {
            prdct = "(" + e["#PList"] + ")";
            sheet = 1;
          } else {
            sheet = 10;
          }
        } else {
          r = 0;
        }
      });
    } else if (this.state.portalID != 5 && e["#tid"] != -1) {
      prdct = "(" + e["#CompleteList"] + ")";
    } else if (this.state.portalID != 5 && e["#tid"] == -1) {
      prdct = e["#IncompleteList"];
      sheet = 0;
    } else {
      prdct = e["#PList"];
      sheet = 0;
    }
    if (r == 0) {
      return;
    }

    this.setState({ loading: true });

    if (sheet == 10) {
      this.setState({ loading: false });
      return;
    }

    if (sheet == 1) {
      if (this.state.portalID === 0) {
        const allTempData = e["#Alltemp"];
        const templateProductPairs = allTempData.match(/(\d+)-\(([\d,]+)\)/g);

        if (templateProductPairs) {
          const results = [];
          for (const pair of templateProductPairs) {
            const matches = pair.match(/(\d+)-\(([\d,]+)\)/);
            if (matches) {
              const templateId = parseInt(matches[1], 10);
              const productIdsStr = matches[2];
              const productIdsArr = productIdsStr
                .split(",")
                .map((id) => parseInt(id, 10));

              //console.log("Template ID:", templateId);
              //console.log("Product IDs:", productIdsArr);

              const que1 =
                "EXEC ExportTamplateTest @loginid=" +
                this.state.loginId +
                ", @alist='" +
                list +
                "',@filter='" +
                productIdsArr.join(",") +
                "', @tempID=" +
                templateId +
                " ,@catID=" +
                this.state.catID;

              const res1 = await getDataTable(que1);
              if (Array.isArray(res1)) {
                results.push(res1);
              }
            }
          }

          if (results.length > 0) {
            const combinedResults = results.flat();

            let que1 =
              "Select SheetName,CellAddress from TemplateMaster where TemplateID = " +
              e["#tid"];
            let rs0 = await getDataTable(que1);
            await json2portalExport(
              combinedResults,
              rs0[0].SheetName,
              rs0[0].CellAddress,
              this.state.Pname,
              e["#tid"],
              this.state.orgID
            );

            let que2 =
              "EXEC FrmExportData_ExportLotCreation @loginID = " +
              this.state.loginId +
              ", @tid = " +
              e["#tid"] +
              ", @plist = '" +
              prdct.slice(1, -1) +
              "' , @importLot = '" +
              this.state.LotName +
              "'";
            await getDataTable(que2);

            Swal.fire({
              icon: "info",
              title: "DATA EXPORTED SUCCESSFULLY",
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            Swal.fire({
              icon: "info",
              title: "NO DATA FOUND",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        }
        this.setState({ loading: false });
      } else {
        let Que =
          "EXEC ExportTamplateTest @loginid=" +
          this.state.loginId +
          ", @alist='" +
          list +
          "',@filter='" +
          prdct +
          "', @tempID=" +
          e["#tid"] +
          " ,@catID=" +
          this.state.catID;
        if (
          this.state.portalID === 20 ||
          this.state.portalID === 36 ||
          this.state.portalID === 41 ||
          this.state.portalID === 23 ||
          this.state.portalID === 67
        ) {
          Que =
            "EXEC ExportTamplateNEW @loginid=" +
            this.state.loginId +
            ", @alist='" +
            list +
            "',@filter='" +
            prdct +
            "', @tempID=" +
            e["#tid"] +
            " ,@catID=" +
            this.state.catID;
        }
        if (this.state.portalID === 23 || this.state.portalID === 72) {
          Que =
            "EXEC ExportTamplateNEWShopify @loginid=" +
            this.state.loginId +
            ", @alist='" +
            list +
            "',@filter='" +
            prdct +
            "', @tempID=" +
            e["#tid"] +
            " ,@catID=" +
            this.state.catID;
        }
        let rs = await getDataTable(Que);
        debugger;
        if (Array.isArray(rs)) {
          let que1 =
            "Select SheetName,CellAddress from TemplateMaster where TemplateID = " +
            e["#tid"];
          let rs0 = await getDataTable(que1);
          await json2portalExport(
            rs,
            rs0[0].SheetName,
            rs0[0].CellAddress,
            this.state.Pname,
            e["#tid"],
            this.state.orgID
          );
          let que2 =
            "EXEC FrmExportData_ExportLotCreation @loginID = " +
            this.state.loginId +
            ", @tid = " +
            e["#tid"] +
            ", @plist = '" +
            prdct.slice(1, -1) +
            "' , @importLot = '" +
            this.state.LotName +
            "'";
          await getDataTable(que2);
          Swal.fire({
            icon: "info",
            title: "DATA EXPORTED SUCCESSFULLY",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            icon: "info",
            title: "NO DATA FOUND",
            showConfirmButton: false,
            timer: 1500,
          });
        }
        this.setState({ loading: false });
      }
    } else {
      let errmsg = 0;
      let arr = [
        "10",
        "6",
        "1",
        "10019",
        "20038",
        "20057",
        "20059",
        "20076",
        "20102",
      ];
      if (arr.includes(this.state.loginId) || this.state.orgID === 1) {
        this.setState({ loading: false });
        let stsconfirm = await Swal.fire({
          title: "EXPORT INCOMPLETE SHEET",
          showConfirmButton: true,
          showCancelButton: false,
          showDenyButton: true,
          confirmButtonText: "WITH STATUS",
          denyButtonText: "WITHOUT STATUS",
        });
        if (stsconfirm.isConfirmed) {
          errmsg = 1;
        }
        this.setState({ loading: true });
      }
      let pQue =
        "EXEC FRMEXPORTTEMPLATE_GETTEMPLATEINCOMPLETEDATA @loginId  = " +
        this.state.loginId +
        " ,@pid ='" +
        prdct +
        "' ,@catid =" +
        this.state.catID;
      if (e["#tid"] == -1) {
        pQue += " ,@tid =" + e["#tid"] + ",@portalid = '" + e["#PList"] + "'";
      } else {
        pQue +=
          " ,@tid =" + e["#tid"] + ",@portalid = '" + this.state.portalID + "'";
      }
      pQue += " ,@errormsg =" + errmsg;
      this.setState({ loading: true });

      let PRs = await getDataTable(pQue);
      if (PRs.length > 0) {
        if (this.state.portalID != 5) {
          if (e["#tid"] === -1) {
            await GetNewDrpSheet(
              this.state.catID,
              PRs,
              this.state.Pname + "-Incomplete.xlsx"
            );
          } else {
            await GetNewDrpSheet(
              this.state.catID,
              PRs,
              e["Template Name"] + "-Incomplete.xlsx"
            );
          }
        } else {
          await GetNewDrpSheet(this.state.catID, PRs, "Pending Data.xlsx");
        }
        Swal.fire({
          icon: "info",
          title: "Data Exported Successfully",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        Swal.fire({
          icon: "info",
          title: "No Data Found",
          showConfirmButton: false,
          timer: 1500,
        });
      }
      this.setState({ loading: false });
    }
  }

  async getLotData() {
    let MandatoryArray = [
      { Category: this.state.catID },
      { LOT: this.state.lotID },
    ];
    let check = MandatoryFormFields(MandatoryArray);
    if (check == false) {
      return false;
    }
    this.setState({ loading: true });
    let que1 = await getDataTable(
      "select CustomImportSheetId from LotCustomSheetRelation where LotId = " +
        this.state.lotID
    );
    debugger;
    if (que1.length > 0) {
      let que2 =
        "EXEC FrmExportGetCustomSheetAliasingData @loginID =" +
        this.state.loginId +
        " ,@templateID =" +
        que1[0].CustomImportSheetId +
        ",@lotID =" +
        this.state.lotID +
        ",@catid =" +
        this.state.catID;
      let que3 = await getDataTable(que2);
      await GetNewDrpSheet(this.state.catID, que3, "Master Data.xlsx");
      this.setState({ loading: false });
    } else {
      let que =
        "EXEC ExportMasterData @loginID = " +
        this.state.loginId +
        " ,@catid = " +
        this.state.catID +
        " ,@lotID = " +
        this.state.lotID;
      let rs = await getDataTable(que);
      debugger;
      await GetNewDrpSheet(this.state.catID, rs, "Master Data.xlsx");
      this.setState({ loading: false });
    }
  }

  async getErrorData() {
    let MandatoryArray = [
      { Category: this.state.catID },
      { LOT: this.state.lotID },
    ];
    let check = MandatoryFormFields(MandatoryArray);
    if (check == false) {
      return false;
    }
    this.setState({ loading: true });
    let que =
      "EXEC frmExportData_getOldErrorSheet  @lotID = " + this.state.lotID;
    let rs = await getDataTable(que);
    if (rs.length > 0) {
      await GetNewDrpSheet(
        this.state.catID,
        rs,
        this.state.LotName + "_Error_data.xlsx"
      );
    } else {
      Swal.fire({
        icon: "info",
        title: "ERROR SHEET NOT FOUND",
        showConfirmButton: false,
        timer: 1500,
      });
    }
    this.setState({ loading: false });
  }

  async removeLotData() {
    let MandatoryArray = [{ LOT: this.state.lotID }];
    let check = MandatoryFormFields(MandatoryArray);
    if (check === false) {
      return false;
    }

    // Show a confirmation popup
    const confirmation = await Swal.fire({
      icon: "warning",
      title: "Are you sure you want to remove this lot?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });

    if (confirmation.isConfirmed) {
      this.setState({ loading: true });
      let que = "EXEC frmExportData_RemoveLotData @lotID = " + this.state.lotID;
      let rs = await getDataTable(que);
      await Swal.fire({
        icon: "info",
        title: "LOT REMOVED SUCCESSFULLY",
        showConfirmButton: false,
        timer: 1500,
      });
      this.setState({ loading: false });
    }
  }

  async createDataForNamshi() {
    this.setState({
      loading: true,
    });

    //Query to find productSKUS
    let que =
      " EXEC frmExportData_CreateDataForNamshi @lotid = " +
      this.state.lotID +
      " , @PortalID = " +
      this.state.portalID;
    let dt = await getDataTable(que);
    debugger;
    let InCompleteDt = [];
    let CompleteDt = [];
    //Iterating each SKU and getting data
    for (let i = 0; i < dt.length; i++) {
      let ele = dt[i];
      //Query to get Images for productSKU
      let que1 =
        " EXEC frmExportData_CreateAdditionalDatafornamshi @parent = '" +
        ele["product_id"] +
        "' , @orgid = " +
        this.state.orgID +
        " , @type = 'images' ";
      let dt1 = await getDataTable(que1);
      let arr = [];
      if (dt1.length > 2) {
        dt1.forEach((ele) => {
          arr.push(ele["AttributeValue"]);
        });
      } else {
        InCompleteDt.push(ele);
        continue;
      }

      dt[i]["images"] = arr;

      //Query to get sizes data
      let que2 =
        " EXEC frmExportData_CreateAdditionalDatafornamshi @parent = '" +
        ele["product_id"] +
        "' , @orgid = " +
        this.state.orgID +
        " , @type = 'sizes' ";
      let dt2 = await getDataTable(que2);
      dt[i]["product_lines"] = dt2;
      CompleteDt.push(dt[i]);
    }

    if (CompleteDt.length === 0) {
      await Swal.fire({
        icon: "info",
        title: "IMAGES ARE NOT READY FOR ANY ARTICLE (CAN NOT PUSH)",
        showConfirmButton: false,
        timer: 3500,
      });
      return false;
    }

    let finaldt = { items: CompleteDt };
    //console.log(finaldt);
    let finalRes = await postDataToNamshi(finaldt, NamshiCredStg, NamshiStgURL);
    debugger;
    if (finalRes.status === "success") {
      await sleep(2000);
      this.setState({ loading: false });
      await Swal.fire({
        icon: "info",
        title: "DATA POST SUCCESSFULLY PLEASE CHECK JOB EXCEL FOR MORE DETAILS",
        showConfirmButton: false,
        timer: 3500,
      });
      this.setState({ loading: true });
      let jobdt = await getNamshiJobDetails(
        finalRes.job_id,
        NamshiCredStg,
        NamshiStgURL
      );

      for (let k = 0; k < jobdt["details"]["items"].length; k++) {
        jobdt["details"]["items"][k]["job_id"] = finalRes.job_id;
      }
      debugger;
      await json2xlsx(jobdt["details"]["items"], "JOB STATUS");
      this.setState({ loading: false });
    } else {
      await Swal.fire({
        icon: "info",
        title: "DATA INVALID PLEASE CHECK EXCEL FOR MORE DETAILS",
        showConfirmButton: false,
        timer: 1500,
      });
      await json2xlsx(finalRes["details"], "JOB STATUS");
    }
    this.setState({ loading: false });
  }

  chkArtLive(evt) {
    let facIds = this.state.liveArt;
    if (evt.target.checked) {
      facIds.push(evt.target.value);
      this.setState({
        liveArt: facIds,
      });
    } else {
      facIds.pop(evt.target.value);
      this.setState({
        liveArt: facIds,
      });
    }
    this.forceUpdate();
  }

  async createDataForNamshiLive(f) {
    this.setState({
      loading: true,
    });
    let que =
      " EXEC frmExportData_CreateDataForNamshiProduction @artlive = " +
      "'" +
      this.state.liveArt +
      "'" +
      " , @PortalID = " +
      this.state.portalID;
    let dt = await getDataTable(que);
    debugger;
    let InCompleteDt = [];
    let CompleteDt = [];
    let NamshiCredPrd = "";
    if (f === "UAE") {
      NamshiCredPrd = NamshiCredPrd_UAE;
    } else {
      NamshiCredPrd = NamshiCredPrd_KSA;
    }
    let prlist = "";
    for (let l = 0; l < this.state.liveArt.length; l++) {
      const element = this.state.liveArt[l];
      if (prlist === "") {
        prlist = element;
      } else {
        prlist = prlist + "," + element;
      }
    }

    //console.log(prlist);
    for (let i = 0; i < dt.length; i++) {
      let ele = dt[i];
      let que1 =
        " EXEC frmExportData_CreateAdditionalDatafornamshi @parent = '" +
        ele["product_id"] +
        "' , @orgid = " +
        this.state.orgID +
        " , @type = 'images' ";
      let dt1 = await getDataTable(que1);
      let arr = [];
      if (dt1.length > 2) {
        dt1.forEach((ele) => {
          arr.push(ele["AttributeValue"]);
        });
      } else {
        InCompleteDt.push(ele);
        continue;
      }
      dt[i]["images"] = arr;
      let que2 =
        " EXEC frmExportData_CreateAdditionalDatafornamshi @parent = '" +
        ele["product_id"] +
        "' , @orgid = " +
        this.state.orgID +
        " , @type = 'sizes' ";
      let dt2 = await getDataTable(que2);
      dt[i]["product_lines"] = dt2;

      CompleteDt.push(dt[i]);
    }

    if (CompleteDt.length === 0) {
      await Swal.fire({
        icon: "info",
        title: "IMAGES ARE NOT READY FOR ANY ARTICLE (CAN NOT PUSH)",
        showConfirmButton: false,
        timer: 3500,
      });
      return false;
    }

    let finaldt = { items: CompleteDt };
    let finalRes = await postDataToNamshi(finaldt, NamshiCredPrd, NamshiPrdURL);

    if (finalRes.status === "success") {
      await sleep(2000);
      this.setState({ loading: false });
      await Swal.fire({
        icon: "info",
        title: "DATA POST SUCCESSFULLY PLEASE CHECK JOB EXCEL FOR MORE DETAILS",
        showConfirmButton: false,
        timer: 3500,
      });
      this.setState({ loading: true });
      let jobdt = await getNamshiJobDetails(
        finalRes.job_id,
        NamshiCredPrd,
        NamshiPrdURL
      );
      for (let k = 0; k < jobdt["details"]["items"].length; k++) {
        jobdt["details"]["items"][k]["job_id"] = finalRes.job_id;
        let que3 =
          "EXEC frmExportData_SaveLiveDataforNamshi @pid = " +
          "'" +
          this.state.liveArt +
          "'" +
          " , @artlive = " +
          jobdt["details"]["items"][k].product_id +
          " , @portalid = " +
          this.state.portalID +
          " , @livestatus = " +
          jobdt["details"]["items"][k].status +
          ", @jobid =  " +
          "'" +
          jobdt["details"]["items"][k]["job_id"] +
          "'" +
          ", @Ccode =  " +
          "'" +
          f +
          "'" +
          ", @livestatustype = " +
          "'" +
          "PRODUCTION" +
          "'" +
          ", @loginid = " +
          this.state.loginId;
        await getDataTable(que3);
      }
      debugger;
      await json2xlsx(jobdt["details"]["items"], "JOB STATUS");
      this.setState({ loading: false });
    } else {
      await Swal.fire({
        icon: "info",
        title: "DATA INVALID PLEASE CHECK EXCEL FOR MORE DETAILS",
        showConfirmButton: false,
        timer: 1500,
      });
      await json2xlsx(finalRes["details"], "JOB STATUS");
    }
    this.setState({ loading: false });
  }

  async createDataForTrendyol(type) {
    //console.log("Create JSON");
    //console.log(this.state.adata);

    //Check if there are incomplete articles
    if (this.state.adata[this.state.adata.length - 1]["Incomplete SKU"] > 0) {
      Swal.fire({
        icon: "error",
        title: "Incomplete Data Found",
        showConfirmButton: false,
        timer: 1500,
      });
      this.setState({ loading: false });
      return 0;
    }

    let templateNameList = [];
    for (let i = 0; i < this.state.adata.length; i++) {
      if (
        this.state.adata[i]["Template Name"] !== "TOTAL SKU" ||
        this.state.adata[i]["Template Name"] !== "Trendyol-GenAll"
      ) {
        const getCatDataQuery = `EXEC frmExportData_getTrendyolCategoryData @categoryNames = '${this.state.adata[i]["Template Name"]}'`;
        const categoryData = await getDataTable(getCatDataQuery);
        templateNameList.push(categoryData[0]);
        //console.log(this.state.adata[i]["Template Name"], categoryData);
      }
    }
    this.state.trendyolTemplatesPresent = templateNameList.filter(
      (temp) => temp !== undefined
    );
    //console.log(this.state.trendyolTemplatesPresent);

    this.showTemplateSelectionModal(type);
  }

  showTemplateSelectionModal(type) {
    //console.log(this.state.trendyolTemplatesPresent);
    Modal.info({
      title: "Select Template To Post To API",
      okText: "Close",
      width: "950px",
      closable: true,
      okButtonProps: { style: { display: "none" } },
      footer: null,
      bodyStyle: { maxHeight: "500px" },
      style: { padding: "0px" },
      centered: true,
      maskClosable: true,
      content: (
        <div style={{ maxHeight: "500px", overflowY: "scroll" }}>
          <table className="table table-hover">
            <thead style={{ position: "sticky" }}>
              <tr>
                <th>Sr No.</th>
                <th>Check</th>
                <th>Template Name</th>
                <th>Category Name</th>
                <th>ID</th>
              </tr>
            </thead>
            <tbody>
              {this.state.trendyolTemplatesPresent.map((value, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <input
                        type="checkbox"
                        onChange={(ev) => {
                          let temp = [
                            ...new Set(
                              this.state.trendyolSelectedTemplatesToPost
                            ),
                          ];
                          //console.log(ev);
                          if (ev.target.checked) {
                            temp.push(
                              this.state.trendyolTemplatesPresent[index]
                            );
                          } else {
                            temp = temp.filter(
                              (t) =>
                                t.templateName !==
                                this.state.trendyolTemplatesPresent[index][
                                  "templateName"
                                ]
                            );
                          }

                          this.state.trendyolSelectedTemplatesToPost = temp;
                        }}
                        value={value.productid || []}
                        defaultChecked={value.Status === "YES"}
                      />
                    </td>
                    <td>{index + 1}</td>
                    <td>{value.templateName}</td>
                    <td>{value.category}</td>
                    <td>{value.id}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              onClick={(ev) => {
                // this.templateSelectionModalVisible = false;
                Modal.destroyAll();
                this.showTrendyolStoreFrontSelectionModal(
                  this.state.trendyolStoreFronts,
                  this.state.trendyolSelectedTemplatesToPost,
                  this.state.portalID,
                  this.state.adata,
                  type
                );
              }}
              className="btn btn-primary"
            >
              Update
            </button>
          </div>
        </div>
      ),
      onCancel() {
        Modal.destroyAll(); // Close all modals
      },
    });
  }

  showTrendyolStoreFrontSelectionModal(
    trendyolStoreFronts,
    trendyolSelectedTemplatesToPost,
    portalID,
    adata,
    type
  ) {
    let trendyolSelectedStoreFronts = [];
    Modal.info({
      title: "Select Store Front",
      okText: "Close",
      width: "950px",
      closable: true,
      okButtonProps: { style: { display: "none" } },
      footer: null,
      bodyStyle: { maxHeight: "500px" },
      style: { padding: "0px" },
      centered: true,
      maskClosable: true,
      content: (
        <div style={{ maxHeight: "500px", overflowY: "scroll" }}>
          <table className="table table-hover">
            <thead style={{ position: "sticky" }}>
              <tr>
                <th>Sr No.</th>
                <th>Check</th>
                <th>Store Front Name</th>
                <th>Store Front Code</th>
                <th>Vat Rates</th>
              </tr>
            </thead>
            <tbody>
              {this.state.trendyolStoreFronts.map((value, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <input
                        type="checkbox"
                        onChange={(ev) => {
                          let temp = [...new Set(trendyolSelectedStoreFronts)];
                          //console.log(ev);
                          if (ev.target.checked) {
                            temp.push(trendyolStoreFronts[index]);
                          } else {
                            temp = temp.filter(
                              (t) =>
                                t.templateName !==
                                trendyolStoreFronts[index]["templateName"]
                            );
                          }

                          trendyolSelectedStoreFronts = temp;
                        }}
                        value={value.productid}
                        defaultChecked={value.Status === "YES"}
                      />
                    </td>
                    <td>{index + 1}</td>
                    <td>{value.storeFrontName}</td>
                    <td>{value.storeFrontCode}</td>
                    <td>{value.vatRate}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              onClick={(ev) => {
                // console.log(
                //   trendyolSelectedStoreFronts,
                //   trendyolSelectedTemplatesToPost,
                //   portalID,
                //   adata
                // );
                Modal.destroyAll();
                this.generateTrendyolData(
                  trendyolSelectedStoreFronts,
                  trendyolSelectedTemplatesToPost,
                  portalID,
                  adata,
                  type
                );
              }}
              className="btn btn-primary"
            >
              Select
            </button>
          </div>
        </div>
      ),
      onCancel() {
        Modal.destroyAll(); // Close all modals
      },
    });
  }

  async generateTrendyolData(
    trendyolSelectedStoreFronts,
    templateList,
    portalId,
    adata,
    type
  ) {
    this.setState({ loading: true });
    // console.log(templateList, portalId);
    let trendyolDataObject = {
      //      storeFrontCodes: trendyolSelectedStoreFronts,
      supplierId: 959437,
      dataArray: [],
      type,
      portalId,
      loginId: 1,
    };

    trendyolSelectedStoreFronts = [...new Set(trendyolSelectedStoreFronts)];
    // console.log(trendyolSelectedStoreFronts);
    templateList = [...new Set(templateList)];
    // console.log(templateList);
    for (let z = 0; z < trendyolSelectedStoreFronts.length; z++) {
      for (let i = 0; i < templateList.length; i++) {
        let selectedTemplate = adata.filter(
          (data) => data["Template Name"] === templateList[i].templateName
        )[0];
        if (selectedTemplate["#CompleteList"].split(",").length > 0) {
          let templateName = selectedTemplate["Template Name"];
          let artList = selectedTemplate["#CompleteList"];
          let newDataQuery = `EXEC frmExportData_CreateDataForTrendyol @artNew = '${artList}',
          @PortalID = ${portalId}, @templateName = '${templateName}', @storeFrontCode = '${
            trendyolSelectedStoreFronts[z].storeFrontCode
          }',
          @vatRate = ${trendyolSelectedStoreFronts[z].vatRate}, @type = '${
            type === "new" ? "new" : "existing"
          }'
          `;
          // console.log(newDataQuery);
          let mainData = await getDataTable(newDataQuery);
          const parseJSON = (obj) => {
            for (let key in obj) {
              if (typeof obj[key] === "string") {
                try {
                  obj[key] = JSON.parse(obj[key]);
                } catch (error) {
                  // Do nothing if it's not a valid JSON string
                }
              }
            }
          };

          // Function to modify attributes array based on isCustom
          const modifyAttributes = (attributes) => {
            return attributes.map((attr) => {
              if (attr.isCustom) {
                if (attr.slicer) {
                  return {
                    attributeId: attr.attributeId,
                    customAttributeValue: attr.customAttributeValue,
                    slicer: attr.slicer,
                  };
                } else if (attr.varianter) {
                  return {
                    attributeId: attr.attributeId,
                    customAttributeValue: attr.customAttributeValue,
                    varianter: attr.varianter,
                  };
                } else {
                  return {
                    attributeId: attr.attributeId,
                    customAttributeValue: attr.customAttributeValue,
                  };
                }
              } else {
                if (attr.slicer) {
                  return {
                    attributeId: attr.attributeId,
                    attributeValueId: attr.attributeValueId,
                    slicer: attr.slicer,
                    // Remove isCustom and customAttributeValue
                  };
                } else if (attr.varianter) {
                  return {
                    attributeId: attr.attributeId,
                    attributeValueId: attr.attributeValueId,
                    varianter: attr.varianter,
                    // Remove isCustom and customAttributeValue
                  };
                } else {
                  return {
                    attributeId: attr.attributeId,
                    attributeValueId: attr.attributeValueId,
                    // Remove isCustom and customAttributeValue
                  };
                }
              }
            });
          };

          mainData.forEach((item) => {
            try {
              parseJSON(item);
              item.attributes = modifyAttributes(item.attributes);
            } catch (error) {
              console.log(error);
            }
          });
          trendyolDataObject.dataArray.push({
            storeFrontCode: trendyolSelectedStoreFronts[z],
            data: mainData,
          });
          this.setState({ loading: false });
          const _type = type === "new" ? "new" : "existing";
        }
      }
    }
    // console.log(trendyolDataObject);
    this.showProductModalForTrendyol(trendyolDataObject);
  }

  async showProductModalForTrendyol(mainData, type) {
    // console.log(mainData);
    // console.log(this.state.adata);

    const groupedByCode = mainData.dataArray.reduce((acc, item) => {
      const code = item.storeFrontCode.storeFrontCode;
      if (!acc[code]) {
        acc[code] = [];
      }
      acc[code].push(item);
      return acc;
    }, {});

    const transformedArray = Object.keys(groupedByCode).map((code) => {
      const items = groupedByCode[code];
      const skucount = items.reduce(
        (total, currentItem) => total + currentItem.data.length,
        0
      );
      return { storeFrontCode: code, skucount };
    });

    Modal.info({
      title: "Product Details",
      okText: "Close",
      width: "950px",
      closable: true,
      okButtonProps: { style: { display: "none" } },
      footer: null,
      bodyStyle: { maxHeight: "500px" },
      style: { padding: "0px" },
      centered: true,
      maskClosable: true,
      content: (
        <div style={{ maxHeight: "500px", overflowY: "scroll" }}>
          <table className="table table-hover">
            <thead style={{ position: "sticky" }}>
              <tr>
                <th>Sr No.</th>
                <th>Store Front Code</th>
                <th>SKU Count</th>
              </tr>
            </thead>
            <tbody>
              {transformedArray.map((value, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{value.storeFrontCode}</td>
                    <td>{value.skucount}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div
            style={{
              display: "flex",
              gap: "8px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {type === "new" ? (
              <button
                onClick={async (ev) => {
                  await json2xlsx(mainData, "Trendyol Product Creation");
                }}
                className="btn btn-primary"
              >
                Download Product Create Data
              </button>
            ) : (
              <button
                onClick={async (ev) => {
                  await json2xlsx(mainData, "Trendyol Product Update");
                }}
                className="btn btn-primary"
              >
                Download Product Update Data
              </button>
            )}
            <button
              onClick={async (ev) => {
                Modal.destroyAll();
                const method = type === "new" ? "POST" : "PUT";
                this.setState({ loading: true });
                this.postDataAPITrendyol(mainData, method);
              }}
              className="btn btn-primary"
            >
              Post to API
            </button>
          </div>
        </div>
      ),
      onCancel() {
        Modal.destroyAll(); // Close all modals
      },
    });
  }

  async postDataAPITrendyol(data, method) {
    //Product Create API Call
    const login = "ukqNJYU26z8AgcS3VC70";
    const pasword = "kmSD5MTR67Yuu6teV2FZ";
    const basicAuth = encodeBase64(`${login}:${pasword}`);
    let batchRequestIdList = [];
    //let batchRequestIds = [];
    let batchRequestResponse = [];
    for (let i = 0; i < data.dataArray.length; i++) {
      let productArray = data.dataArray[i];
      let requestData = {
        supplierId: productArray.storeFrontCode === "AE" ? 959437 : 997026, // Replace with actual supplier ID
        method: data.type === "new" ? "POST" : "PUT", // or 'PUT'
        storeFrontCode: productArray.storeFrontCode, // Replace with actual store front code
        dataArray: { items: productArray.data }, // Replace with actual data array
        auth: basicAuth,
      };

      const config = {
        method: "POST",
        body: JSON.stringify(requestData),
        headers: {
          "Content-Type": "application/json",
        },
      };
      try {
        try {
          const response = await fetch(
            "https://api.blumangoes.ai:6143/trendyol_product",
            config
          );
          const data = await response.json();

          if (data.batchRequestId) {
            console.log(`Pushed With Success. ID :${data.batchRequestId}`);

            const statusResponse = await fetch(
              `https://api.blumangoes.ai:6143/trendyol-productStatus?batchRequestId=${data.batchRequestId}&storeFrontCode=${requestData.storeFrontCode}&supplierId=${requestData.supplierId}`,
              {
                method: "GET",
              }
            );

            const statusData = await statusResponse.json();
            // console.log(statusData);
            batchRequestIdList.push(statusData);
          } else {
            console.log("Some Error Occurred");
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }

        // fetch("https://api.blumangoes.ai:6143/trendyol_product", config).then(
        //   (response) =>
        //     response.json().then((data) => {
        //       if (data.batchRequestId) {
        //         console.log(`Pushed With Success. ID :${data.batchRequestId}`);

        //         fetch(
        //           `https://api.blumangoes.ai:6143/trendyol-productStatus?batchRequestId=${data.batchRequestId}&storeFrontCode=${requestData.storeFrontCode}&supplierId=${requestData.supplierId}`,
        //           {
        //             method: "GET",
        //           }
        //         ).then((response) =>
        //           response.json().then((data) => {
        //             console.log(data);
        //             batchRequestIdList.push(data);
        //           })
        //         );
        //       }
        //       else {
        //         console.log("Some Error Occured")
        //       }
        //     })
        //);
      } catch (error) {
        console.log(error);
      }
    }
    this.setState({ loading: false });
    // console.log(batchRequestIdList);
    let batchRequests = [];
    batchRequestIdList.map((request) =>
      batchRequests.push(request.batchRequestId)
    );
    const batchRequestsToString = batchRequestIdList
      .map((x) => `'` + x.batchRequestId + `'`)
      .join(", ");
    let Que = `Select batchRequestId, SKUCode, liveStatus, trendyolResponse, storeFrontCode from LiveStatusTrendyol where batchRequestId in (${batchRequestsToString})`;
    // console.log(Que);
    const pushedData = await getDataTable(Que);
    const lotName = await getDataTable(
      `select 'Lot-'+CAST(LotNo as nvarchar(max)) + '-' + LotName as lotName from LotMaster where lotId = ${this.state.lotID}`
    );
    //const sheetName = `${lotName}-${this.state.selectedTemplate.toString()}_`;
    await json2xlsx(pushedData, `${lotName.data[0].lotName}-Trendyol_response`);
    // this.setState({loading})
  }

  async ShowDataForImageUpdateTrendyol() {}

  async AddPushedDataTest() {
    const jsonObj = {};

    const que = `EXEC frmExportData_addProductStatusTrendyol @json = '${JSON.stringify(
      jsonObj
    )}',
    @orgId = 10103,
    @portalId = 73,
    @supplierId = 12345,
    @logId = 1`;
    const up = await getDataTable(que);
  }

  async updateTrendyolAttributeData() {
    let templateNameList = [];
    for (let i = 0; i < this.state.adata.length; i++) {
      if (
        this.state.adata[i]["Template Name"] !== "TOTAL SKU" ||
        this.state.adata[i]["Template Name"] !== "Trendyol-GenAll"
      ) {
        const getCatDataQuery = `EXEC frmExportData_getTrendyolCategoryData @categoryNames = '${this.state.adata[i]["Template Name"]}'`;
        const categoryData = await getDataTable(getCatDataQuery);
        templateNameList.push(categoryData[0]);
      }
    }
    this.state.trendyolCategories = templateNameList.filter(
      (temp) => temp !== undefined
    );
    // console.log(this.state.trendyolCategories);

    Modal.info({
      title: "Select Category To Update",
      okText: "Close",
      width: "950px",
      closable: true,
      okButtonProps: { style: { display: "none" } },
      footer: null,
      bodyStyle: { maxHeight: "500px" },
      style: { padding: "0px" },
      centered: true,
      maskClosable: true,
      content: (
        <div style={{ maxHeight: "500px", overflowY: "scroll" }}>
          <table className="table table-hover">
            <thead style={{ position: "sticky" }}>
              <tr>
                <th>Sr No.</th>
                <th>Check</th>
                <th>Template Name</th>
                <th>Category Name</th>
                <th>ID</th>
              </tr>
            </thead>
            <tbody>
              {this.state.trendyolCategories.map((value, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <input
                        type="checkbox"
                        onChange={(ev) => {
                          let temp = [
                            ...new Set(this.state.trendyolSelectedCategories),
                          ];
                          // console.log(ev);
                          if (ev.target.checked) {
                            temp.push(this.state.trendyolCategories[index]);
                          } else {
                            temp = temp.filter(
                              (t) =>
                                t.templateName !==
                                this.state.trendyolCategories[index][
                                  "templateName"
                                ]
                            );
                          }

                          this.state.trendyolSelectedCategories = temp;
                        }}
                        value={value.productid}
                        defaultChecked={value.Status === "YES"}
                      />
                    </td>
                    <td>{index + 1}</td>
                    <td>{value.templateName}</td>
                    <td>{value.category}</td>
                    <td>{value.id}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              onClick={() => {
                this.setState({ loading: true });
                functionUpdatedTrendyol(
                  this.state.trendyolSelectedCategories
                ).then((data) => {
                  this.setState({ loading: false });
                });
              }}
              className="btn btn-primary"
            >
              Update
            </button>
          </div>
        </div>
      ),
    });

    async function functionUpdatedTrendyol(selectedCategories) {
      if (!selectedCategories.length > 0) {
        Swal.fire({
          icon: "error",
          title: "No Categories Selected",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        // console.log("Updating");
        for (let i = 0; i < selectedCategories.length; i++) {
          let categoryid = selectedCategories[i].id;

          let url = `https://api.trendyol.com/sapigw/product-categories/${categoryid}/attributes`;
          let finalRes = await getTrendyolAttributes(categoryid);
          let cat = finalRes.id;
          let tempArr = [];
          for (let i = 0; i < finalRes.categoryAttributes.length; i++) {
            let att = finalRes.categoryAttributes[i];
            let tempObj = {};
            function findVariantType(att) {
              if (att.varianter) return "varianter";
              if (att.slicer) return "slicer";
              else return null;
            }
            if (att.allowCustom) {
              tempObj["categoryId"] = cat;
              tempObj["attributeName"] = att.attribute.name;
              tempObj["attributeId"] = att.attribute.id;
              tempObj["isCustom"] = att.allowCustom;
              tempObj["variant"] = findVariantType(att);
              tempObj["value"] = null;
              tempObj["valueId"] = null;
              tempArr.push(tempObj);
              const data = JSON.stringify(tempObj);
              const query = `EXEC trendyolAttributesValuesUpdate @jsonData = N'${data}', @trendyolCategory = 975`;
              // console.log(query);
              const tableResult = await getDataTable(query);
            } else {
              for (let j = 0; j < att.attributeValues.length; j++) {
                let atVal = att.attributeValues[j];
                tempObj["categoryId"] = cat;
                tempObj["attributeName"] = att.attribute.name;
                tempObj["attributeId"] = att.attribute.id;
                tempObj["isCustom"] = att.allowCustom;
                tempObj["variant"] = findVariantType(att);
                tempObj["value"] = atVal.name;
                tempObj["valueId"] = atVal.id;
                tempArr.push(tempObj);
                const data = JSON.stringify(tempObj);
                const query = `EXEC trendyolAttributesValuesUpdate @jsonData = N'${data}', @trendyolCategory = 975`;
                // console.log(query);

                const tableResult = await getDataTable(query);
              }
            }
          }
        }
      }
      Swal.fire({
        icon: "success",
        title: "Attribute Data Updated",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }

  async createImageDataForNamshiLive() {
    this.setState({
      loading: true,
    });

    let que =
      " EXEC frmExportData_CreateDataForUpdateNamshiData @artlive = " +
      "'" +
      this.state.liveArt +
      "'" +
      " , @PortalID = " +
      this.state.portalID;
    let dt = await getDataTable(que);

    let InCompleteDt = [];
    let CompleteDt = [];

    let prlist = this.state.liveArt.join(",");

    for (let i = 0; i < dt.length; i++) {
      let ele = dt[i];

      const imagesCheckbox = document.getElementById("showMrpCheckbox");
      const updateImages = imagesCheckbox.checked;

      if (updateImages) {
        let que1 =
          " EXEC frmExportData_CreateAdditionalDatafornamshi @parent = '" +
          ele["product_id"] +
          "' , @orgid = " +
          this.state.orgID +
          " , @type = 'images' ";
        let dt1 = await getDataTable(que1);
        let arr = [];
        if (dt1.length > 2) {
          dt1.forEach((ele) => {
            arr.push(ele["AttributeValue"]);
          });
        } else {
          InCompleteDt.push(ele);
          continue;
        }
        dt[i]["images"] = arr;
      }

      CompleteDt.push(dt[i]);
    }

    if (CompleteDt.length === 0) {
      await Swal.fire({
        icon: "info",
        title: "IMAGES ARE NOT READY FOR ANY ARTICLE (CAN NOT PUSH)",
        showConfirmButton: false,
        timer: 3500,
      });
      this.setState({ loading: false });
      return false;
    }

    let finaldt = { items: CompleteDt };
    debugger;
    let finalRes = await UpdateNamshiImage(
      finaldt,
      NamshiCredPrd_UAE,
      NamshiPrdURL
    );

    if (finalRes.status === "success") {
      await sleep(2000);
      this.setState({ loading: false });
      await Swal.fire({
        icon: "info",
        title: "DATA POST SUCCESSFULLY PLEASE CHECK JOB EXCEL FOR MORE DETAILS",
        showConfirmButton: false,
        timer: 3500,
      });
      this.setState({ loading: true });
      let jobdt = await getNamshiJobDetails(
        finalRes.job_id,
        NamshiCredPrd_UAE,
        NamshiPrdURL
      );

      for (let k = 0; k < jobdt["details"]["items"].length; k++) {
        jobdt["details"]["items"][k]["job_id"] = finalRes.job_id;
        let que3 =
          "EXEC SaveDataForNamshiImageUpdate @artlive = " +
          jobdt["details"]["items"][k].product_id +
          " , @portalid = " +
          this.state.portalID +
          " , @status = " +
          jobdt["details"]["items"][k].status +
          ", @jobid =  " +
          "'" +
          jobdt["details"]["items"][k]["job_id"] +
          "'" +
          ", @loginid = " +
          this.state.loginId;
        await getDataTable(que3);
      }

      await json2xlsx(jobdt["details"]["items"], "JOB STATUS");
      this.setState({ loading: false });
    } else {
      await Swal.fire({
        icon: "info",
        title: "DATA INVALID PLEASE CHECK EXCEL FOR MORE DETAILS",
        showConfirmButton: false,
        timer: 1500,
      });
      await json2xlsx(finalRes["details"], "JOB STATUS");
    }

    this.setState({ loading: false });
  }

  async createDataForEasyEcomLive() {
    this.setState({
      loading: true,
    });

    let que =
      " EXEC frmExportData_CreateDataForEasyEcom  @artlive = " +
      "'" +
      this.state.liveArt +
      "'" +
      " , @PortalID = " +
      this.state.portalID;
    let CompleteDt = await getDataTable(que);

    let responseArray = [];

    for (let i = 0; i < CompleteDt.length; i++) {
      let skuData = CompleteDt[i];
      debugger;
      let EasyApi = await EasyEcomApi(
        skuData,
        EasyEcomToken,
        EasyEcomUrl,
        EasyEcomkey
      );

      let responseData = {
        Article: skuData.ModelNumber,
        SKU: skuData.Sku,
        message: EasyApi.message,
        product_id: EasyApi.data.product_id,
      };

      responseArray.push(responseData);

      if (
        EasyApi.message === "Product Created Successfully" ||
        EasyApi.code === 200
      ) {
        let que3 =
          "EXEC SaveDataForEasyEcomLive @pid = '" +
          responseData.SKU +
          "', @status = '" +
          responseData.message +
          "', @portalid = " +
          this.state.portalID +
          ", @loginid = " +
          this.state.loginId +
          ", @Product_id = " +
          responseData.product_id;

        await getDataTable(que3);

        await sleep(2000);
        await Swal.fire({
          icon: "info",
          title: "DATA POST SUCCESSFULLY PLEASE CHECK  EXCEL FOR MORE DETAILS",
          showConfirmButton: false,
          timer: 3500,
        });
      } else {
        await Swal.fire({
          icon: "error",
          title: "DATA INVALID PLEASE CHECK EXCEL FOR MORE DETAILS",
          showConfirmButton: false,
          timer: 3500,
        });
        this.setState({ loading: false });
      }
    }

    await json2xlsx(responseArray, "JOB STATUS");

    this.setState({
      loading: false,
    });
  }

  async createDataForEasyEcomLiveUpdate() {
    this.setState({
      loading: true,
    });
    let que =
      "EXEC frmExportData_CreateDataForUpdateEasyEcom @artlive = " +
      "'" +
      this.state.liveArt +
      "'" +
      " , @PortalID = " +
      this.state.portalID;

    let CompleteDt = await getDataTable(que);
    debugger;
    let responseArray = [];

    for (let i = 0; i < CompleteDt.length; i++) {
      let skuData = CompleteDt[i];

      let EasyApi = await EasyEcomApi(
        skuData,
        EasyEcomToken,
        EasyEcomUpdate,
        EasyEcomkey
      );
      debugger;
      let responseData = {
        SKU: skuData.Sku,
        productId: skuData.productId,
        message: EasyApi.message,
        product_id: EasyApi.data.productId,
      };

      responseArray.push(responseData);

      if (
        EasyApi.message === "Product Updated Successfully" ||
        EasyApi.code === 200
      ) {
        await sleep(2000);
        await Swal.fire({
          icon: "info",
          title: "DATA POST SUCCESSFULLY PLEASE CHECK  EXCEL FOR MORE DETAILS",
          showConfirmButton: false,
          timer: 3500,
        });
      } else {
        await Swal.fire({
          icon: "error",
          title: "DATA INVALID PLEASE CHECK EXCEL FOR MORE DETAILS",
          showConfirmButton: false,
          timer: 3500,
        });
        this.setState({ loading: false });
      }
    }
    await json2xlsx(responseArray, "JOB STATUS");

    this.setState({
      loading: false,
    });
  }

  async createDataForIncreffLive() {
    this.setState({
      loading: true,
    });
    let que =
      "EXEC frmExportData_CreateDataForIncreff @artlive = " +
      "'" +
      this.state.liveArt +
      "'" +
      " , @PortalID = " +
      this.state.portalID;
    let CompleteDt = await getDataTable(que);
    let finaldt = { articleMasters: CompleteDt };
    // console.log(finaldt);
    debugger;
    let authUsername = "BLU_MANGOES_ERP-1200049109";
    let authPassword = "4b6e1709-abcd-47f8-b707-64a51af2bc6e";
    let url =
      "https://staging-common-assure.increff.com/assure-magic2/master/articles";

    try {
      let callApi = await IncreffApi(finaldt, authUsername, authPassword, url);
      // console.log("API Response:", callApi);
      let dataArray = this.state.dataArray || [];
      for (let i = 0; i < CompleteDt.length; i++) {
        let SKU = CompleteDt[i].barcode;
        let ARTICLE = CompleteDt[i].styleCode;
        let STATUS = callApi.status;

        let newData = { SKU, ARTICLE, STATUS };
        dataArray.push(newData);
      }

      this.setState({
        dataArray,
      });

      // console.log(this.state.dataArray);
      if (callApi.status === "success") {
        let que3 =
          "EXEC SaveDataForIncreffApi @pid = '" +
          this.state.liveArt +
          "', @status = '" +
          callApi.status +
          "', @portalid = " +
          this.state.portalID +
          ", @loginid = " +
          this.state.loginId;

        await getDataTable(que3);
        debugger;
        await Swal.fire({
          icon: "info",
          title: "DATA POST SUCCESSFULLY PLEASE CHECK  EXCEL FOR MORE DETAILS",
          showConfirmButton: false,
          timer: 3500,
        });
        this.setState({
          loading: false,
        });
      } else {
        throw new Error(
          `API request failed with status code: ${callApi.status}`
        );
      }
    } catch (error) {
      console.error("Error:", error.message);
      await Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to create data. Please try again.",
      });
      this.setState({
        loading: false,
      });
    }
    await json2xlsx(this.state.dataArray, "JOB STATUS");
  }

  async updateDataForIncreffLive() {
    this.setState({
      loading: true,
    });
    let que =
      "EXEC frmExportData_CreateDataForIncreff @artlive = " +
      "'" +
      this.state.liveArt +
      "'" +
      " , @PortalID = " +
      this.state.portalID;
    let CompleteDt = await getDataTable(que);
    let finaldt = { articleMasters: CompleteDt };
    //console.log(finaldt);
    debugger;
    let authUsername = "BLU_MANGOES_ERP-1200049109";
    let authPassword = "4b6e1709-abcd-47f8-b707-64a51af2bc6e";
    let url =
      "https://staging-common-assure.increff.com/assure-magic2/master/articles";

    try {
      let callApi = await UpdateIncreffApi(
        finaldt,
        authUsername,
        authPassword,
        url
      );
      //console.log("API Response:", callApi);
      let dataArray = this.state.dataArray || [];
      for (let i = 0; i < CompleteDt.length; i++) {
        let SKU = CompleteDt[i].barcode;
        let ARTICLE = CompleteDt[i].styleCode;
        let STATUS = callApi.status;

        let newData = { SKU, ARTICLE, STATUS };
        dataArray.push(newData);
      }

      this.setState({
        dataArray,
      });

      //console.log(this.state.dataArray);
      if (callApi.status === "success") {
        let que3 =
          "EXEC SaveDataForIncreffUpdateApi @pid = '" +
          this.state.liveArt +
          "', @status = '" +
          callApi.status +
          "', @portalid = " +
          this.state.portalID +
          ", @loginid = " +
          this.state.loginId;

        await getDataTable(que3);
        debugger;
        await Swal.fire({
          icon: "info",
          title: "DATA POST SUCCESSFULLY PLEASE CHECK EXCEL FOR MORE DETAILS",
          showConfirmButton: false,
          timer: 3500,
        });
        this.setState({
          loading: false,
        });
      } else {
        throw new Error(
          `API request failed with status code: ${callApi.status}`
        );
      }
    } catch (error) {
      console.error("Error:", error.message);
      await Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to create data. Please try again.",
      });
      this.setState({
        loading: false,
      });
    }
    await json2xlsx(this.state.dataArray, "JOB STATUS");
  }

  async skuListingForIncreffLive() {
    this.setState({
      loading: true,
    });
    let que =
      "EXEC frmExportData_CreateDataForIncreffSkuListing @artlive = " +
      "'" +
      this.state.liveArt +
      "'" +
      " , @PortalID = " +
      this.state.portalID;
    let CompleteDt = await getDataTable(que);
    let finaldt = {
      channelName: "ADIDAS",
      skuListings: CompleteDt,
    };

    //console.log(finaldt);
    debugger;
    let authUsername = "BLU_MANGOES_ERP-1200049109";
    let authPassword = "4b6e1709-abcd-47f8-b707-64a51af2bc6e";
    let url =
      "https://staging-common-assure.increff.com/assure-magic2/master/sku-listings";

    try {
      let callApi = await IncreffApi(finaldt, authUsername, authPassword, url);
      //console.log("API Response:", callApi);
      let dataArray = this.state.dataArray || [];
      for (let i = 0; i < CompleteDt.length; i++) {
        let SKU = CompleteDt[i].barcode;
        let ARTICLE = CompleteDt[i].styleCode;
        let STATUS = callApi.status;

        let newData = { SKU, ARTICLE, STATUS };
        dataArray.push(newData);
      }

      this.setState({
        dataArray,
      });

      //console.log(this.state.dataArray);
      if (callApi.status === "success") {
        let que3 =
          "EXEC SaveDataForIncreffUpdateApi @pid = '" +
          this.state.liveArt +
          "', @status = '" +
          callApi.status +
          "', @portalid = " +
          this.state.portalID +
          ", @loginid = " +
          this.state.loginId;

        await getDataTable(que3);
        debugger;
        await Swal.fire({
          icon: "info",
          title: "DATA POST SUCCESSFULLY PLEASE CHECK EXCEL FOR MORE DETAILS",
          showConfirmButton: false,
          timer: 3500,
        });
        this.setState({
          loading: false,
        });
      } else {
        throw new Error(
          `API request failed with status code: ${callApi.status}`
        );
      }
    } catch (error) {
      console.error("Error:", error.message);
      await Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to create data. Please try again.",
      });
      this.setState({
        loading: false,
      });
    }
    await json2xlsx(this.state.dataArray, "JOB STATUS");
  }

  async ShowDataForEasyEcomUpdate() {
    let que = "EXEC ShowDataForEasyEcomUpdate @lotid= " + this.state.lotID;
    let dt = await getDataTable(que);
    debugger;
    this.setState({
      artData: dt,
      productid: dt.producid,
    });

    Modal.info({
      title: "Select Article for Push To Live",
      okText: "CLOSE",
      width: "950px",
      closable: true,
      okButtonProps: { style: { display: "none" } },
      footer: null,
      bodyStyle: { maxHeight: "500px" },
      style: { padding: "0px" },
      centered: true,
      maskClosable: true,
      content: (
        <div style={{ maxHeight: "500px", overflowY: "scroll" }}>
          <table className="table table-hover">
            <thead style={{ position: "sticky" }}>
              <tr>
                <th>SR NO.</th>
                <th>ArticleNumber</th>
                <th>POST DATA</th>
              </tr>
            </thead>
            <tbody>
              {this.state.artData.map((value, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{value.article}</td>
                    <td>
                      <input
                        type="checkbox"
                        onChange={this.chkArtLive.bind(this)}
                        value={value.productid}
                        defaultChecked={value.Status === "YES"}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              onClick={this.createDataForEasyEcomLiveUpdate.bind(this)}
              className="btn btn-primary"
            >
              POST
            </button>
          </div>
        </div>
      ),
    });
  }

  async ShowDataForLive(e) {
    debugger;
    let ltque =
      "EXEC ShowArticleDataForProduction @lotid = " +
      this.state.lotID +
      ", @countryCode = '" +
      e +
      "'";
    let dt = await getDataTable(ltque);
    this.setState({
      artData: dt,
      productid: dt.producid,
    });

    Modal.info({
      title: "Select Article for Push To Live",
      okText: "CLOSE",
      width: "950px",
      closable: true,
      okButtonProps: { style: { display: "none" } },
      footer: null,
      bodyStyle: { maxHeight: "500px" },
      style: { padding: "0px" },
      centered: true,
      maskClosable: true,
      content: (
        <div style={{ maxHeight: "500px", overflowY: "scroll" }}>
          <table className="table table-hover">
            <thead style={{ position: "sticky" }}>
              <tr>
                <th>SR NO.</th>
                <th>ArticleNumber</th>
                <th>{e}</th>
              </tr>
            </thead>
            <tbody>
              {this.state.artData.map((value, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{value.article}</td>
                    <td>
                      <input
                        type="checkbox"
                        onChange={this.chkArtLive.bind(this)}
                        value={value.productid}
                        defaultChecked={value.Status === "YES"}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              onClick={this.createDataForNamshiLive.bind(this, e)}
              className="btn btn-primary"
            >
              POST
            </button>
          </div>
        </div>
      ),
    });
  }

  async ShowDataForImageUpdate() {
    let ltque =
      "EXEC ShowArticleDataForNamshiUpdate @lotid = " + this.state.lotID;
    let dt = await getDataTable(ltque);
    this.setState({
      artData: dt,
      productid: dt.producid,
    });

    Modal.info({
      title: "Select Article for Update Images",
      okText: "CLOSE",
      width: "950px",
      closable: true,
      okButtonProps: { style: { display: "none" } },
      footer: null,
      bodyStyle: { maxHeight: "500px" },
      style: { padding: "0px" },
      centered: true,
      maskClosable: true,
      content: (
        <div style={{ maxHeight: "500px", overflowY: "scroll" }}>
          <div className="pull-right">
            <input type="checkbox" id="showMrpCheckbox" />
            <label for="showMrpCheckbox">Images</label>
            <span className="vcode">*</span>
          </div>
          <table className="table table-hover">
            <thead style={{ position: "sticky" }}>
              <tr>
                <th>SR NO.</th>
                <th>ArticleNumber</th>
                <th>Please Select</th>
              </tr>
            </thead>
            <tbody>
              {this.state.artData.map((value, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{value.article}</td>
                    <td>
                      <input
                        type="checkbox"
                        onChange={this.chkArtLive.bind(this)}
                        value={value.productid}
                        defaultChecked={value.Status === "YES"}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              onClick={this.createImageDataForNamshiLive.bind(this)}
              className="btn btn-primary"
            >
              UPDATE IMAGE
            </button>
          </div>
        </div>
      ),
    });
  }

  async ShowDataForEasyecomLive() {
    let ltque = "EXEC ShowArticleDataForEasyEcom @lotid = " + this.state.lotID;
    let dt = await getDataTable(ltque);
    this.setState({
      artData: dt,
      productid: dt.producid,
    });
    Modal.info({
      title: "Select Article for EasyEcom live",
      okText: "CLOSE",
      width: "950px",
      closable: true,
      okButtonProps: { style: { display: "none" } },
      footer: null,
      bodyStyle: { maxHeight: "500px" },
      style: { padding: "0px" },
      centered: true,
      maskClosable: true,
      content: (
        <div style={{ maxHeight: "500px", overflowY: "scroll" }}>
          <table className="table table-hover">
            <thead style={{ position: "sticky" }}>
              <tr>
                <th>SR NO.</th>
                <th>ArticleNumber</th>
                <th>Please Select</th>
              </tr>
            </thead>
            <tbody>
              {this.state.artData.map((value, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{value.article}</td>
                    <td>
                      <input
                        type="checkbox"
                        onChange={this.chkArtLive.bind(this)}
                        value={value.productid}
                        defaultChecked={value.Status === "YES"}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              onClick={this.createDataForEasyEcomLive.bind(this)}
              className="btn btn-primary"
            >
              POST
            </button>
          </div>
        </div>
      ),
    });
  }

  async ShowDataForIncreffLive() {
    let ltque = "EXEC ShowArticleDataForIncreff @lotid = " + this.state.lotID;
    let dt = await getDataTable(ltque);
    this.setState({
      artData: dt,
      productid: dt.producid,
    });
    Modal.info({
      title: "Select Article for Increff live",
      okText: "CLOSE",
      width: "950px",
      closable: true,
      okButtonProps: { style: { display: "none" } },
      footer: null,
      bodyStyle: { maxHeight: "500px" },
      style: { padding: "0px" },
      centered: true,
      maskClosable: true,
      content: (
        <div style={{ maxHeight: "500px", overflowY: "scroll" }}>
          <table className="table table-hover">
            <thead style={{ position: "sticky" }}>
              <tr>
                <th>SR NO.</th>
                <th>ArticleNumber</th>
                <th>Please Select</th>
              </tr>
            </thead>
            <tbody>
              {this.state.artData.map((value, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{value.article}</td>
                    <td>
                      <input
                        type="checkbox"
                        onChange={this.chkArtLive.bind(this)}
                        value={value.productid}
                        defaultChecked={value.Status === "YES"}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              onClick={this.createDataForIncreffLive.bind(this)}
              className="btn btn-primary"
            >
              POST
            </button>
          </div>
        </div>
      ),
    });
  }

  async ShowDataForIncreffLiveUpdate() {
    let ltque =
      "EXEC ShowArticleDataForIncreffUpdate @lotid = " + this.state.lotID;
    let dt = await getDataTable(ltque);
    this.setState({
      artData: dt,
      productid: dt.producid,
    });
    Modal.info({
      title: "Select Article for Increff live",
      okText: "CLOSE",
      width: "950px",
      closable: true,
      okButtonProps: { style: { display: "none" } },
      footer: null,
      bodyStyle: { maxHeight: "500px" },
      style: { padding: "0px" },
      centered: true,
      maskClosable: true,
      content: (
        <div style={{ maxHeight: "500px", overflowY: "scroll" }}>
          <table className="table table-hover">
            <thead style={{ position: "sticky" }}>
              <tr>
                <th>SR NO.</th>
                <th>ArticleNumber</th>
                <th>Please Select</th>
              </tr>
            </thead>
            <tbody>
              {this.state.artData.map((value, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{value.article}</td>
                    <td>
                      <input
                        type="checkbox"
                        onChange={this.chkArtLive.bind(this)}
                        value={value.productid}
                        defaultChecked={value.Status === "YES"}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              onClick={this.updateDataForIncreffLive.bind(this)}
              className="btn btn-primary"
            >
              POST
            </button>
          </div>
        </div>
      ),
    });
  }

  async ShowDataForIncreffSkuListing() {
    let ltque = "EXEC ShowArticleDataForIncreff @lotid = " + this.state.lotID;
    let dt = await getDataTable(ltque);
    this.setState({
      artData: dt,
      productid: dt.producid,
    });
    Modal.info({
      title: "Select Article for Increff live",
      okText: "CLOSE",
      width: "950px",
      closable: true,
      okButtonProps: { style: { display: "none" } },
      footer: null,
      bodyStyle: { maxHeight: "500px" },
      style: { padding: "0px" },
      centered: true,
      maskClosable: true,
      content: (
        <div style={{ maxHeight: "500px", overflowY: "scroll" }}>
          <table className="table table-hover">
            <thead style={{ position: "sticky" }}>
              <tr>
                <th>SR NO.</th>
                <th>ArticleNumber</th>
                <th>Please Select</th>
              </tr>
            </thead>
            <tbody>
              {this.state.artData.map((value, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{value.article}</td>
                    <td>
                      <input
                        type="checkbox"
                        onChange={this.chkArtLive.bind(this)}
                        value={value.productid}
                        defaultChecked={value.Status === "YES"}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              onClick={this.skuListingForIncreffLive.bind(this)}
              className="btn btn-primary"
            >
              POST
            </button>
          </div>
        </div>
      ),
    });
  }

  handleOAuthButtonClick() {
    if (this.state.portalID === 67) {
      window.location.href =
        "https://sellercentral.amazon.ae/apps/authorize/consent?application_id=amzn1.sp.solution.39fb100d-18e4-4915-b60c-edaab2bb6e28&state=stateexample&version=beta";
    } else {
      window.location.href =
        "https://sandbox-api.flipkart.net/oauth-service/oauth/authorize?client_id=95872337bb14143aa0b966049b11528b322&redirect_uri=https://app.blumangoes.ai/&response_type=code&scope=Seller_Api&state=stateexample";
    }
  }

  handleTokenGenerateFK = async () => {
    let que =
      "EXEC frmExportData_CreateDataForFK @lotId = " +
      this.state.lotID +
      " , @PortalID = " +
      this.state.portalID;
    let data = await getDataTable(que);
    let payload = [];
    data.forEach((item) => {
      let sku = item["Seller SKU ID"]; // Assuming 'Article Number' is a unique identifier for each product

      payload[sku] = {
        product_id: item["Seller SKU ID"] || "", // Assuming 'Seller SKU ID' is the product ID
        price: {
          mrp: item["MRP (INR)"] || 0,
          selling_price: item["Your selling price (INR)"] || 0,
          currency: "INR",
        },
        tax: {
          hsn: item["HSN"] || "",
          tax_code: item["Tax Code"] || "",
        },
        listing_status: "ACTIVE",
        shipping_fees: {
          local: 0,
          zonal: 0,
          national: 0,
          currency: "INR",
        },
        fulfillment_profile: "NON_FBF",
        fulfillment: {
          dispatch_sla: 1,
          shipping_provider: "FLIPKART",
          procurement_type: "REGULAR",
        },
        packages: [
          {
            name: "Default",
            dimensions: {
              length: 1,
              breadth: 1,
              height: 1,
            },
            weight: 1,
            description: "",
            handling: {
              fragile: false,
            },
            notional_value: {
              amount: 1,
              unit: "PERCENTAGE",
            },
          },
        ],
        locations: [
          {
            id: "",
            status: "ENABLED",
            inventory: 0,
          },
        ],
        address_label: {
          manufacturer_details: [""],
          importer_details: [""],
          packer_details: [""],
          countries_of_origin: [""],
        },
        dating_label: {
          mfg_date: "",
          shelf_life: "",
        },
      };
    });
    //console.log(payload);
    debugger;
    this.setState({
      loading: true,
    });

    const urlParams = new URLSearchParams(window.location.search);
    const authCode = urlParams.get("code");

    if (!authCode) {
      await Swal.fire({
        icon: "warning",
        title: "Authorization code is missing",
        showConfirmButton: false,
        timer: 2500,
      });
      this.setState({
        loading: false,
      });
      return;
    }

    const username = "95872337bb14143aa0b966049b11528b322";
    const password = "34553198abb417f5fe369921659fcc8ae";
    let url = `https://sandbox-api.flipkart.net/oauth-service/oauth/token?redirect_uri=https://app.blumangoes.ai/&grant_type=authorization_code&state=<state>&code=${authCode}`;

    let tokenGenrate = await flipkartTokenGen(username, password, url);
    if (tokenGenrate.access_token) {
      await Swal.fire({
        icon: "success",
        title: "token genrated successfully",
        showConfirmButton: false,
        timer: 2500,
      });
      this.setState({
        loading: false,
      });
      return;
    } else {
      await Swal.fire({
        icon: "error",
        title: "some error",
        showConfirmButton: false,
        timer: 2500,
      });
      this.setState({
        loading: false,
      });
      return;
    }
  };

  handleGenerateTokenButtonClick = async () => {
    this.setState({
      loading: true,
    });
    let que =
      " EXEC frmExportData_CreateDataForAmazon @lotid = " +
      this.state.lotID +
      " , @PortalID = " +
      this.state.portalID;
    let skuDataList = await getDataTable(que);
    //console.log(skuDataList);
    debugger;
    const { orgID } = this.state;
    const urlParams = new URLSearchParams(window.location.search);
    const oauthCode = urlParams.get("spapi_oauth_code");
    const sellerId = urlParams.get("selling_partner_id");

    if (!oauthCode) {
      await Swal.fire({
        icon: "warning",
        title: "Authorization code is missing",
        showConfirmButton: false,
        timer: 2500,
      });
      this.setState({
        loading: false,
      });
      return;
    }

    try {
      const response = await axios.post(
        "https://api.amazon.com/auth/o2/token",
        {
          grant_type: "authorization_code",
          code: oauthCode,
          client_id:
            "amzn1.application-oa2-client.83799e097f8945cdbe3f4996a29dd6d0",
          client_secret:
            "amzn1.oa2-cs.v1.8a5bee8d25ef736ecf4296df56bfe810cc1776926ae4cf4fb0a8bbe6d137af49",
          redirect_uri: "https://app.blumangoes.ai",
        }
      );

      const accessToken = response.data.access_token;
      //console.log("Access Token:", accessToken);

      await Swal.fire({
        icon: "success",
        title: "Access Token generated successfully!",
        showConfirmButton: false,
        timer: 2500,
      });

      const allResponses = [];

      for (const skuData of skuDataList) {
        const marketplaceId =
          this.state.portalID === 20 ? "A21TJRUUN4KGV" : "A2VIGQ35RCS4UG";

        const apiUrl = `https://sandbox.sellingpartnerapi-eu.amazon.com/listings/2021-08-01/items/${sellerId}/${skuData.sku}?marketplaceids=${marketplaceId}&issueLocale=en_GB`;
        //console.log(skuData);
        try {
          let createListingApi = await CreateListingAmazon(
            skuData,
            accessToken,
            apiUrl
          );
          allResponses.push({ skuData, response: createListingApi });
        } catch (error) {
          console.error("Error creating listing:", error);
          allResponses.push({
            skuData,
            error: "Failed to create listing. Please try again.",
          });
        }
      }

      const successResponses = allResponses.filter(
        (entry) =>
          entry.response &&
          (entry.response.status === "ACCEPTED" || entry.response.code === 200)
      );

      if (successResponses.length === skuDataList.length) {
        await sleep(2000);
        await Swal.fire({
          icon: "info",
          title:
            "ALL DATA POSTED SUCCESSFULLY. PLEASE CHECK EXCEL FOR MORE DETAILS",
          showConfirmButton: false,
          timer: 3500,
        });
      } else {
        await Swal.fire({
          icon: "error",
          title:
            "SOME DATA FAILED TO POST. PLEASE CHECK EXCEL FOR MORE DETAILS",
          showConfirmButton: false,
          timer: 3500,
        });
      }

      //console.log("All Responses:", allResponses);
      await json2xlsx(allResponses, "JOB STATUS");
      this.setState({ loading: false });
    } catch (error) {
      console.error("Error generating access token:", error);

      await Swal.fire({
        icon: "error",
        title: "Failed to generate access token. Please try again.",
        showConfirmButton: false,
        timer: 2500,
      });
      this.setState({
        loading: false,
      });
    }
  };

  myFileChangeHandler = (event) => {
    files = event.target.files[0];
  };

  async uploadFile() {
    let MandatoryArray = [
      { Category: this.state.catID },
      { Portal: this.state.portalID },
      { Lot: this.state.lotID },
      { file: files },
    ];
    let check = MandatoryFormFields(MandatoryArray);
    if (check === false) {
      return false;
    }
    this.setState({
      loading: true,
    });

    const accessToken = "1f0179c4-6fe0-4f78-b805-184ac896abb0";
    const formData = new FormData();
    formData.append("file", files);

    try {
      const response = await CreateListingYoox(files, accessToken);
      //console.log("Response:", response);

      if (response.import_id !== undefined) {
        // Assuming a valid import_id indicates success
        await Swal.fire({
          icon: "success",
          title: "File Posted Successfully.",
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        await Swal.fire({
          icon: "error",
          title: "SOME ERROR OCCURRED.",
          showConfirmButton: false,
          timer: 2500,
        });
      }

      this.setState({
        loading: false,
      });
    } catch (error) {
      console.error("Error uploading file:", error);

      await Swal.fire({
        icon: "error",
        title: "SOME ERROR OCCURRED.",
        showConfirmButton: false,
        timer: 2500,
      });

      this.setState({
        loading: false,
      });
    }
  }

  adidasDiscountChannelSelectionModal() {
    Modal.info({
      title: "Select Template To Post To API",
      okText: "Close",
      width: "950px",
      closable: true,
      okButtonProps: { style: { display: "none" } },
      footer: null,
      bodyStyle: { maxHeight: "500px" },
      style: { padding: "0px" },
      centered: true,
      maskClosable: true,
      content: (
        <div style={{ maxHeight: "500px", overflowY: "scroll" }}>
          <table className="table table-hover">
            <thead style={{ position: "sticky" }}>
              <tr>
                <th>Check</th>
                <th>Sr No.</th>
                <th>Channel Name</th>
              </tr>
            </thead>
            <tbody>
              {["Namshi"].map((value, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <input
                        type="checkbox"
                        onChange={(ev) => {
                          let temp = [
                            ...this.state.adidasChannelSelectedForDiscount,
                          ];
                          temp.push(ev.target.value);
                          this.setState({
                            adidasChannelSelectedForDiscount: [
                              ...new Set(temp),
                            ],
                          });
                          console.log(temp);
                        }}
                        value={value || []}
                        defaultChecked={value.Status === "YES"}
                      />
                    </td>
                    <td>{index + 1}</td>
                    <td>{value}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              onClick={(ev) => {
                // this.templateSelectionModalVisible = false;
                Modal.destroyAll();
                this.setState({ loading: true });
                this.postDiscountAdidas();
              }}
              className="btn btn-primary"
            >
              Update
            </button>
          </div>
        </div>
      ),
      onCancel() {
        Modal.destroyAll(); // Close all modals
      },
    });
  }

  async postDiscountAdidas() {
    console.log(this.state.adidasChannelSelectedForDiscount);
    let productList = this.state.adata[0]["#PList"];
    console.log(productList);
    let requestBody = [];
    for (
      let i = 0;
      i < this.state.adidasChannelSelectedForDiscount.length;
      i++
    ) {
      const Que = `EXEC frmExportData_GenerateDataForPriceDiscountRetailLogistics @pList = '${productList}', @channel = '${this.state.adidasChannelSelectedForDiscount[i]}'`;
      console.log(Que);
      let jsonData = await getDataTable(Que);
      jsonData.map((data) => requestBody.push(data));
    }

    console.log(requestBody);
    try {
      fetch("https://api.blumangoes.ai:6143/adidas_price_discount_update", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: requestBody,
          auth: this.state.retailAuth,
        }),
      }).then((response) =>
        response.json().then(async (data) => {
          if (data.responseCode === "200") {
            console.log("Updated with success");
          }
          if (data.errorMessage.length > 0) {
            await json2xlsx(data, "Price Update Errors");
          }
        })
      );
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    return (
      <div className="content-wrapper">
        <div
          className="loader"
          style={{ display: this.state.loading ? "block" : "none" }}
        >
          <div className="loader-item" style={{ marginLeft: "75vh" }}>
            <Spin tip="We are preparing your data! please do not refresh page..." />
          </div>
        </div>
        <section className="content">
          <div
            id="ctl00_CPHMaincontent_divclient"
            className="box box-success"
            style={{ marginTop: "-13px" }}
          >
            <div className="box-header with-border">
              <h3 className="box-title">
                EXPORT DATA
                <span
                  id="ctl00_CPHMaincontent_DivOrdNo"
                  style={{ display: "inline" }}
                ></span>
              </h3>
              {(this.state.loginId === "10" ||
                this.state.loginId === "1" ||
                this.state.orgID === 1) && (
                <div className="pull-right">
                  <TEMP width="800px" logid={this.state.loginId} />
                </div>
              )}
              <div className="pull-right" style={{ marginRight: "2px" }}>
                <label>&nbsp;</label>
                <button
                  className="btn btn-primary"
                  onClick={this.help.bind(this)}
                >
                  HELP
                </button>
              </div>
              {(this.state.loginId === "10" || this.state.loginId === "1") && (
                <div className="pull-right" style={{ marginRight: "2px" }}>
                  <label>&nbsp;</label>
                  <button
                    className="btn btn-danger"
                    onClick={this.removeLotData.bind(this)}
                  >
                    REMOVE LOT
                  </button>
                </div>
              )}
              {this.state.portalID === 52 && this.state.adata.length > 0 && (
                <div className="pull-right" style={{ marginRight: "2px" }}>
                  <label>&nbsp;</label>
                  <button
                    className="btn btn-primary"
                    onClick={this.createDataForNamshi.bind(this)}
                  >
                    POST API STAGGING
                  </button>
                </div>
              )}
              {this.state.portalID === 73 && this.state.adata.length > 0 && (
                <>
                  <div className="pull-right" style={{ marginRight: "2px" }}>
                    <label>&nbsp;</label>
                    <button
                      className="btn btn-primary"
                      onClick={this.createDataForTrendyol.bind(this, "new")}
                    >
                      POST API TRENDYOL
                    </button>
                  </div>
                  <div className="pull-right" style={{ marginRight: "2px" }}>
                    <label>&nbsp;</label>
                    <button
                      className="btn btn-primary"
                      onClick={this.updateTrendyolAttributeData.bind(this)}
                    >
                      Refresh Trendyol Attributes
                    </button>
                  </div>
                </>
              )}
              {this.state.portalID === 63 && this.state.adata.length > 0 && (
                <div className="pull-right" style={{ marginRight: "2px" }}>
                  <label>&nbsp;</label>
                  <button
                    className="btn btn-primary"
                    onClick={this.ShowDataForEasyecomLive.bind(this)}
                  >
                    POST API EASYECOM
                  </button>
                </div>
              )}
              {this.state.portalID === 52 && this.state.adata.length > 0 && (
                <div
                  className="btn-group pull-right"
                  style={{ marginRight: "2px" }}
                >
                  <label>&nbsp;</label>
                  <button
                    className="btn btn-primary"
                    // onClick={this.ShowDataForLive.bind(this)}
                  >
                    POST API LIVE
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    <span class="caret"></span>
                    <span class="sr-only">Toggle Dropdown</span>
                  </button>
                  <ul class="dropdown-menu" role="menu">
                    <li>
                      <a onClick={this.ShowDataForLive.bind(this, "UAE")}>
                        FOR UAE
                      </a>
                    </li>
                    <li>
                      <a onClick={this.ShowDataForLive.bind(this, "KSA")}>
                        FOR KSA
                      </a>
                    </li>
                  </ul>
                </div>
              )}
              {this.state.portalID === 52 && this.state.adata.length > 0 && (
                <div className="pull-right" style={{ marginRight: "2px" }}>
                  <label>&nbsp;</label>
                  <button
                    className="btn btn-primary"
                    onClick={this.ShowDataForImageUpdate.bind(this)}
                  >
                    UPDATE DATA
                  </button>
                </div>
              )}
              {this.state.portalID === 73 && this.state.adata.length > 0 && (
                <div className="pull-right" style={{ marginRight: "2px" }}>
                  <label>&nbsp;</label>
                  <button
                    className="btn btn-primary"
                    onClick={this.createDataForTrendyol.bind(this, "existing")}
                  >
                    UPDATE DATA
                  </button>
                </div>
              )}
              {this.state.portalID === 52 &&
                this.state.orgID === 10067 &&
                this.state.adata.length > 0 && (
                  <div className="pull-right" style={{ marginRight: "2px" }}>
                    <label>&nbsp;</label>
                    <button
                      className="btn btn-primary"
                      onClick={() => this.adidasDiscountChannelSelectionModal()}
                    >
                      POST DISCOUNT
                    </button>
                  </div>
                )}
              {this.state.portalID === 63 && this.state.adata.length > 0 && (
                <div className="pull-right" style={{ marginRight: "2px" }}>
                  <label>&nbsp;</label>
                  <button
                    className="btn btn-primary"
                    onClick={this.ShowDataForEasyEcomUpdate.bind(this)}
                  >
                    UPDATE DATA
                  </button>
                </div>
              )}
              {this.state.portalID === 57 && this.state.adata.length > 0 && (
                <div className="pull-right" style={{ marginRight: "2px" }}>
                  <label>&nbsp;</label>
                  <button
                    className="btn btn-primary"
                    onClick={this.ShowDataForIncreffLive.bind(this)}
                  >
                    Post Api Increff
                  </button>
                </div>
              )}
              {this.state.portalID === 57 && this.state.adata.length > 0 && (
                <div className="pull-right" style={{ marginRight: "2px" }}>
                  <label>&nbsp;</label>
                  <button
                    className="btn btn-primary"
                    onClick={this.ShowDataForIncreffLiveUpdate.bind(this)}
                  >
                    Update Api Increff
                  </button>
                </div>
              )}
              {this.state.portalID === 57 && this.state.adata.length > 0 && (
                <div className="pull-right" style={{ marginRight: "2px" }}>
                  <label>&nbsp;</label>
                  <button
                    className="btn btn-primary"
                    onClick={this.ShowDataForIncreffSkuListing.bind(this)}
                  >
                    SKU-Listing Api Increff
                  </button>
                </div>
              )}
              {this.state.portalID === 52 && this.state.adata.length > 0 && (
                <div className="pull-right" style={{ marginRight: "2px" }}>
                  <SELECTEDATTRIBUTES width="800px" />
                </div>
              )}
              {this.state.portalID === 52 && this.state.adata.length > 0 && (
                <div className="pull-right" style={{ marginRight: "2px" }}>
                  <SETPRIORITY width="800px" />
                </div>
              )}
              {this.state.portalID === 67 && this.state.adata.length > 0 && (
                <div className="pull-right" style={{ marginRight: "2px" }}>
                  <button
                    className="btn btn-primary"
                    onClick={this.handleOAuthButtonClick.bind(this)}
                  >
                    Authorize with Amazon
                  </button>
                </div>
              )}
              {this.state.portalID === 67 && this.state.adata.length > 0 && (
                <div className="pull-right" style={{ marginRight: "2px" }}>
                  <button
                    className="btn btn-primary"
                    onClick={this.handleGenerateTokenButtonClick}
                  >
                    Post Data
                  </button>
                </div>
              )}
              {this.state.portalID === 20 && this.state.adata.length > 0 && (
                <div className="pull-right" style={{ marginRight: "2px" }}>
                  <button
                    className="btn btn-primary"
                    onClick={this.handleOAuthButtonClick.bind(this)}
                  >
                    Authorize with Amazon
                  </button>
                </div>
              )}
              {this.state.portalID === 20 && this.state.adata.length > 0 && (
                <div className="pull-right" style={{ marginRight: "2px" }}>
                  <button
                    className="btn btn-primary"
                    onClick={this.handleGenerateTokenButtonClick}
                  >
                    Post Data
                  </button>
                </div>
              )}
              {this.state.portalID === 17 && this.state.adata.length > 0 && (
                <div className="pull-right" style={{ marginRight: "2px" }}>
                  <button
                    className="btn btn-primary"
                    onClick={this.handleOAuthButtonClick.bind(this)}
                  >
                    Authorize with flipkart
                  </button>
                </div>
              )}
              {this.state.portalID === 17 && this.state.adata.length > 0 && (
                <div className="pull-right" style={{ marginRight: "2px" }}>
                  <button
                    className="btn btn-primary"
                    onClick={this.handleTokenGenerateFK}
                  >
                    Generate Access Token FK
                  </button>
                </div>
              )}
              {this.state.portalID === 71 && this.state.adata.length > 0 && (
                <div className="pull-right" style={{ marginRight: "2px" }}>
                  <GETDETAILSYOOX width="800px" />
                </div>
              )}
              {this.state.portalID === 71 && this.state.adata.length > 0 && (
                <div className="pull-right" style={{ marginRight: "2px" }}>
                  <div className="form-group">
                    <input
                      type="button"
                      value="POST API"
                      onClick={this.uploadFile.bind(this)}
                      className="btn btn-block btn-primary"
                    />
                  </div>
                </div>
              )}
              {this.state.portalID === 71 && this.state.adata.length > 0 && (
                <div className="pull-right" style={{ marginRight: "2px" }}>
                  <div className="form-group">
                    <input
                      name="fldname"
                      type="file"
                      accept=".xls,.xlsx"
                      id="tp1"
                      onChange={this.myFileChangeHandler}
                      placeholder="Enter FieldName"
                      tabIndex={1}
                      className="form-control"
                    />
                  </div>
                </div>
              )}
            </div>
            <div
              className="box-body"
              id="orderDetails"
              style={{ display: "block" }}
            >
              <div className="row">
                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                  <label>Select Block</label>
                  <span className="vcode">*</span>
                  <AutoCompleteCascad
                    id="catid"
                    frmNm="FRMALLDATA"
                    quryNm="FILLORGBLOCKS"
                    db="IMAGEDB"
                    filter1="orgid"
                    filterId1={this.state.orgID}
                    filter2=""
                    filterId2=""
                    placeholder="Please Select Block"
                    onAfterSelect={(e) =>
                      this.onAfterSelect(e, "catID", "CatNAME")
                    }
                    isValid={this.state.isValid}
                  ></AutoCompleteCascad>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                  <label>Select Portal</label>
                  <span className="vcode">*</span>
                  <AutoCompleteCascad
                    id="catid"
                    frmNm="FRMATTRIBUTEALIASE"
                    quryNm="FILLPORTAL"
                    db="IMAGEDB"
                    filter1="OrgId"
                    filterId1={this.state.orgID}
                    filter2="BlockID"
                    filterId2={this.state.catID}
                    placeholder="Please Select Portal"
                    onAfterSelect={(e) =>
                      this.onAfterSelect(e, "portalID", "Pname")
                    }
                    isValid={this.state.isValid}
                  ></AutoCompleteCascad>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                  <label>Select Lot</label>
                  <span className="vcode">*</span>
                  <AutoCompleteCascad
                    id="catid"
                    frmNm="FRMEXPORTDATA"
                    quryNm="FILLLOTALL"
                    db="IMAGEDB"
                    filter1="OrgId"
                    filterId1={this.state.orgID}
                    filter2="CategoryId"
                    filterId2={this.state.catID}
                    placeholder="PLEASE SELECT LOT"
                    onAfterSelect={(e) =>
                      this.onAfterSelect(e, "lotID", "LotName")
                    }
                    isValid={this.state.isValid}
                  ></AutoCompleteCascad>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                  <label>&nbsp;</label>
                  <div className="form-group">
                    <button
                      className="btn btn-block btn-primary"
                      onClick={this.GetTemplates.bind(this)}
                    >
                      Get Templates
                    </button>
                  </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                  <label>&nbsp;</label>
                  <div className="form-group">
                    <button
                      className="btn btn-block btn-primary"
                      onClick={this.getLotData.bind(this)}
                    >
                      Get Lot Data
                    </button>
                  </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                  <label>&nbsp;</label>
                  <div className="form-group">
                    <button
                      className="btn btn-block btn-danger"
                      onClick={this.getErrorData.bind(this)}
                    >
                      GET ERROR SHEET
                    </button>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 margintop">
                  {this.state.adata.length > 0 &&
                    this.state.adata != undefined && (
                      <Table
                        data={[...this.state.adata]}
                        downloadRowFn={(e) => this.GetData(e)}
                        downloadRow="true"
                        height="63vh"
                        id="fielddata"
                      ></Table>
                    )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
