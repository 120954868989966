import React, {Component, Fragment} from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import requestDetail from './requestDetail';
import RequestList from './requestList';

class RequestContainer extends Component{
    render(){
        return(
            <Fragment>
                <div style={{backgroundColor:'#f4f6f9', minHeight: '100vh'}}>
                    <Switch>
                        <Route exact path="/approve-request/list" component={RequestList} />
                        <Route exact path="/approve-request/detail/:requestId" component={requestDetail} />
                        <Redirect from="/approve-request" to="/approve-request/list" />
                    </Switch>
                    {/* <PricingDetail /> */}
                </div>
            </Fragment>
        )
    }
}

export default RequestContainer;