import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
//import Dashboard from './Dashboard';
import frmproductfield from "./IMAGE/frmAttributeMaster";
import frmValueAliasingMaster from "./IMAGE/frmValueAliasingMaster";
import frmproductcategory from "../src/IMAGE/frmproductcategory";
import frmorganization from "../src/IMAGE/frmorganization";
import frmuser from "../src/IMAGE/frmuser";
import frmproductdetails from "../src/IMAGE/frmproductdetails";
import frmExportData from "../src/Updated/frmExportTemplate";
import frmMulProduct from "../src/IMAGE/frmMultipleProduct";
import frmUpdateAttributeAliasing from "../src/IMPORTDATA/UpdateAttributeAliasing";
import frmUpdateValueAliasing from "../src/IMPORTDATA/frmUpdateValueAliasing";
import frmUserRights from "../src/IMAGE/frmUserRights";
import frmStatusMarking from "../src/IMAGE/frmStatusMarking";
import frmCreateTemplate from "../src/IMAGE/frmCreatetemplate";
import frmImageUpdated from "../src/IMAGEPROCESS/frmImageProcessUpdate";
import frmReportAdhc from "../src/REPORT/frmReportADhoc";
import frmReportRight from "../src/REPORT/frmReportADhocRight";
import frmrelational from "../src/RELATIONSHIP/frmRelationshipMaster";
import frmImportCustomData from "../src/IMPORTDATA/frmImportCustomData";
import frmlotMaster from "../src/IMPORTDATA/frmlotMaster";
import frmRelationMerging from "../src/RELATIONSHIP/frmMergingNew";
import frmcolorcheck from "../src/IMAGEPROCESS/frmColorCheck";
import frmMultipleValueAliasing from "../src/IMPORTDATA/frmMultipleValueAliasing";
import frmDefaultValueMaster from "./IMPORTDATA/frmDefaultValueMaster";
import frmOrgnisationProfile from "./Users/frmOrgnisationProfile";
import frmSubmitOrderdata from "./PAYMENTRECO/frmSubmitOrderlData";
import Login from "./Login";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import frmMultiCountry from "./IMAGE/frmMultiCountry";
import frmApiMaster from "./IMAGE/MODAL/frmApiMaster";
import ForgotPassword from "./ForgotPassword";
import frmPriceUpdate from "./IMAGE/frmPriceUpdate";
import frmVCR from "./IMAGE/frmVCR";
import frmVcrDetails from "./IMAGE/frmVcrDetails";
import frmImportPriceMaster from "./IMAGE/MODAL/frmImportPriceMaster";

// SubModule Pages
import Dashboard from "./SubModule/Main/Dashboard/index";
import ChangePassword from "./SubModule/Main/Change-Password/index";
import History from "./SubModule/Main/History/index";
import Category from "./SubModule/Main/Category/index";
import Notifications from "./SubModule/Main/Notification/index";
import Invoice from "./SubModule/Main/Invoice/index";
import PrintInvoice from "./SubModule/Main/Invoice/printInvoice";
import Checkout from "./SubModule/Main/Checkout/index";
import TroubleTicket from "./SubModule/Main/Trouble-Ticket/index";
import Pricing from "./SubModule/Main/Pricing";
import RequestContainer from "./SubModule/Main/Price-Request";
import Broadcast_Notification from "./SubModule/Main/Broadcast-Notification";
import Brand from "./SubModule/Main/Brand";
import Import_Sheet from "./SubModule/Main/Import-Sheet/index";
import ImagePosition from "./SubModule/Main/Image-Position/index";
import AdminRoute from "./AdminRoute";

// browse app through routing

export default () => {
  return (
    <HashRouter>
      <Switch>
        {/* <Route path='/viewer' component={Viewer} />
                <Route path='/builder' component={Designer} /> */}
        <PublicRoute path="/" exact component={Login} />
        <PublicRoute path="/login" component={Login} />
        <PrivateRoute exact path="/Dashboard" component={Dashboard} />
        <PrivateRoute path="/frmproductdetails" component={frmproductdetails} />
        <PrivateRoute path="/frmuser" component={frmuser} />
        <PrivateRoute path="/frmMulProduct" component={frmMulProduct} />
        <PrivateRoute
          path="/frmproductcategory"
          component={frmproductcategory}
        />
        <PrivateRoute path="/frmproductfield" component={frmproductfield} />
        <PrivateRoute path="/frmExportData" component={frmExportData} />
        <PrivateRoute path="/frmorganization" component={frmorganization} />
        <PrivateRoute path="/frmAlise" component={frmUpdateAttributeAliasing} />
        <PrivateRoute
          path="/frmValueAlise"
          component={frmUpdateValueAliasing}
        />
        <PrivateRoute path="/frmUserRights" component={frmUserRights} />
        <PrivateRoute path="/frmStatusMarking" component={frmStatusMarking} />
        <PrivateRoute path="/frmCreateTemplate" component={frmCreateTemplate} />
        <PrivateRoute
          path="/frmValueAliasingMaster"
          component={frmValueAliasingMaster}
        />
        <PrivateRoute path="/frmReportAdhc" component={frmReportAdhc} />
        <PrivateRoute path="/frmReportRight" component={frmReportRight} />
        <PrivateRoute path="/frmRelationshipMaster" component={frmrelational} />
        <PrivateRoute
          path="/frmRelationMerging"
          component={frmRelationMerging}
        />
        <PrivateRoute
          path="/frmMultipleValueAliasing"
          component={frmMultipleValueAliasing}
        />
        <PublicRoute path="/frmcolorcheck" component={frmcolorcheck} />
        <PrivateRoute
          path="/frmDefaultValueMaster"
          component={frmDefaultValueMaster}
        />
        <PrivateRoute
          path="/frmSubmitOrderdata"
          component={frmSubmitOrderdata}
        />
        <PrivateRoute path="/frmCustomImport" component={frmImportCustomData} />
        <PrivateRoute path="/frmlotMaster" component={frmlotMaster} />
        <PrivateRoute path="/frmImageUpdated" component={frmImageUpdated} />
        <PrivateRoute
          path="/frmOrgnisationProfile"
          component={frmOrgnisationProfile}
        />
        <PrivateRoute path="/frmMultiCountry" component={frmMultiCountry} />
        <PrivateRoute path="/frmApiMaster" component={frmApiMaster} />
        <PrivateRoute path="/ForgotPassword" component={ForgotPassword} />
        <PrivateRoute path="/frmPriceUpdate" component={frmPriceUpdate} />
        <PrivateRoute path="/frmVCR" component={frmVCR} />
        <PrivateRoute path="/frmVcrDetails" component={frmVcrDetails} />
        <PrivateRoute
          path="/frmImportPriceMaster"
          component={frmImportPriceMaster}
        />
        {/* <PrivateRoute path='/Payment' component={dashboardApp} /> */}

        {/* Dashboard Module Pages */}
        <PrivateRoute
          path="/change-password"
          exact
          component={ChangePassword}
        />
        <PrivateRoute path="/History" exact component={History} />
        <PrivateRoute path="/Category" exact component={Category} />
        <PrivateRoute path="/Notifications" exact component={Notifications} />
        <PrivateRoute path="/invoice/:id" component={Invoice} />
        <PrivateRoute path="/print-invoice/:id" component={PrintInvoice} />
        <PrivateRoute path="/checkout" component={Checkout} />
        <PrivateRoute path="/ticket" component={TroubleTicket} />
        <PrivateRoute
          path="/broadcast-notification"
          component={Broadcast_Notification}
        />
        <PrivateRoute path="/Brand" component={Brand} />
        <PrivateRoute path="/Pricing" component={Pricing} />
        <PrivateRoute path="/Import-sheet" component={Import_Sheet} />
        <PrivateRoute path="/Image-position" component={ImagePosition} />

        {/* For Admin User Only */}
        <AdminRoute path="/approve-request" component={RequestContainer} />
      </Switch>
    </HashRouter>

    //  You can try with your mobile also but you also with this ne
  );
};
