import moment from "moment";
import React, { Component } from 'react';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import { Modal } from 'antd';
import { getDataTable } from './Configuration';
import { configure } from '@testing-library/react';
import { split, startsWith } from 'lodash';



export const ConvBoolTOInt = (value) => {

    return value ? 1 : 0
}

export function OnlyNumberKey(evt) {
    // Only ASCII charactar in that range allowed 
    var ASCIICode = (evt.which) ? evt.which : evt.keyCode
    if (ASCIICode > 47 && ASCIICode < 58) {
        return true;
    }
    else {
        evt.preventDefault();
    }
}

export function allowOnlyOneDotOrNum(evt) {
    var ASCIICode = (evt.which) ? evt.which : evt.keyCode
    if ((ASCIICode > 47 && ASCIICode < 58) || ASCIICode === 46) {

    }
    else {
        evt.preventDefault();
        return false;
    }
    var dots = 1;
    var length = evt.target.value.length;
    var text = evt.target.value;
    for (var i = 0; i < length; i++) {
        if (text[i] == '.') dots++;
        if (dots > 1 && ASCIICode === 46) {
            evt.preventDefault();
        }
    }
}

export function getQueryStr_Comma(FldNm, USerInput, LikeYesNo = true, StartsWith = true) {
    let value = ''
    if (USerInput === '' || USerInput === undefined) {
        return value = '';
    }
    
    let QStr = ''
    let spl = split(USerInput, ',')
    for (let i = 0; i < spl.length; i++) {
        if (QStr === '') {
            if (LikeYesNo) {
                if (StartsWith) {
                    QStr = FldNm + " Like''" + (spl[i].trim()) + "%''"
                }
                else {
                    QStr = FldNm + " Like''%" + (spl[i].trim()) + "%''"
                }
            }
            else {
                QStr = FldNm + "=" + "''" + (spl[i].trim()) + "''"
            }
        }
        else {
            if (LikeYesNo) {
                if (StartsWith) {
                    QStr = QStr + " Or " + FldNm + " Like''" + (spl[i].trim()) + "%''"
                }
                else {
                    QStr = QStr + " Or " + FldNm + " Like''%" + (spl[i].trim()) + "%''"
                }
            }
            else {
                QStr = QStr + " Or " + FldNm + "=" + "''" + (spl[i].trim()) + "''"
            }
        }
    }

    if (QStr === "") {
        return "";
    }
    else {
        return "and (" + QStr + ")";
    }

}

export function MandatoryFormFields(fildsArray) {
    if (fildsArray.length > 0 && fildsArray != undefined) {
        for (let index = 0; index < fildsArray.length; index++) {
            const element = fildsArray[index];
            let Name = Object.keys(fildsArray[index]);
            if (element[Name] == "" || element[Name] == "0" || element[Name] == undefined) {

                Swal.fire({
                    icon: 'error',
                    title: "PLEASE ENTER " + Name[0].toUpperCase(),
                    showConfirmButton: false,
                    timer: 1500
                })

                return false;
            }

        }
    }
}

export async function AttributeRules() {
    
    let dt = await getDataTable("select * from Rules")
    if (dt.length < 1) {
        Swal.fire({
            icon: 'info',
            okButtonProps: { style: { display: 'none' } },
            closeButtonProps: { style: { display: 'none' } },
            title: "No Rules Found",
            showConfirmButton: false,
            timer: 1500
        })
        return;
    }
    Modal.info({
        title: 'IMPORTANT RULES',
        okText: 'CLOSE',
        width: '700px',
        closable: true,
        footer: null,
        bodyStyle: { maxHeight: '500px' },
        style: { padding: '0px' },
        centered: true,
        maskClosable: true,
        content: (
            <div style={{maxHeight:'400px',overflowY:'scroll'}}>
                <ol>
                    {dt.map((value, index) => {
                        return <li><b>{value.AttributeName}</b> - {value.Rules}</li>
                    })}
                </ol>
            </div>
        )
    });

}

export async function multipleProduct(logid) {
    
    let dt = []
    let rs = await getDataTable("select distinct productid,productName from productmaster where orgID = (select distinct orgid from users where userid = " + logid + ")")
    const addproduct = () => {
       dt = []
        let val = document.getElementById('pdname').value
        if (!val) {
            return;
        }
        for (let i = 0; i < rs.length; i++) {
            let sts = rs[i].productName;
            if (sts.includes(val)) {
                dt.push(rs[i])
            }
        }
        this.forceUpdate();
        console.log(dt);
    }
    console.log(rs);
    Modal.info({
        title: 'Select Article',
        okText: 'CLOSE',
        width: '700px',
        closable: true,
        footer: null,
        bodyStyle: { maxHeight: '500px' },
        style: { padding: '0px' },
        centered: true,
        maskClosable: true,
        content: (
            <div className='row'>
                <div className='col-xs-12 col-sm-12 col-md-6 margintop'>
                    <div className='row'>
                        <div className='col-xs-9 col-sm-9 col-md-9'>
                            <label>Enter Name</label>
                            <div className='form-group'>
                                <input type='text' id='pdname' className='form-control'></input>
                            </div>
                        </div>
                        <div className='col-xs-3 col-sm-3 col-md-3'>
                            <label>Enter Name</label>
                            <div className='form-group'>
                                <input type='button' onClick={addproduct} value='Get' className='btn btn-primary'></input>
                            </div>
                        </div>
                        <div className='col-xs-12 col-sm-12 col-md-12'>
                            { dt.length > 0 && dt != undefined &&
                                <div className="table-responsive" style={{ maxHeight: '40vh' }}>
                                <table id="filetable" className="table table-hover">
                                    <thead style={{ position: "sticky" }}>
                                        <tr> <th>SR NO.</th>
                                            {/* <th>AID</th> */}
                                            <th>ATTRIBUTENAME</th>
                                            {/* <th>MANDATORY</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dt.map((value, index) => {
                                            return <tr key={index}>
                                                <td>{index + 1}&nbsp;<input type="checkbox" value={value.productid} ></input></td>
                                                {/* <td>{value.AID}</td> */}
                                                <td>{value.productName.toUpperCase()}</td>
                                                {/* <td><input type="checkbox" value={value.AID} onChange={this.SelectFN.bind(this, value)}></input></td> */}
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            }
                        </div>
                    </div>
                </div>
                <div className='col-xs-12 col-sm-12 col-md-6 margintop'>

                </div>
            </div>
        )
    });
}

export async function DisclaimerBlock() {
    Modal.info({
        title: 'DISCLAIMER',
        okText: 'CLOSE',
        width: '800px',
        closable: true,
        footer: null,
        bodyStyle: { maxHeight: '400px' },
        style: { padding: '0px' },
        centered: true,
        maskClosable: true,
        content: (
            <div style={{height: '400px',overflowY:'scroll'}}>
               <p>This Portal has been developed by Tri-Continental Impex India with custom codes, machine learning and artificial intelligence. </p>
               <p>Though all efforts have been made to ensure the accuracy of the content and final outcomes on this Portal, the same should not be construed as a statement of law or used for any legal purposes. Tri-Continental Impex India accepts no responsibility in relation to the accuracy, completeness, usefulness or otherwise, of the contents. Users are advised to verify/check any information, and to obtain any appropriate professional advice before acting on the information provided in the Portal.</p>
               <p>You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or the Website will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to Our site for any reconstruction of any lost data. We will not be liable for any loss or damage caused by a distributed denial-of-service attack, viruses or other technologically harmful material that may infect Your computer equipment, computer programs, data or other proprietary material due to Your use of the Website or any Services or items obtained through the Website or to Your downloading of any material posted on it, or on any Website linked to it.</p>
               <p>Neither We nor any person associated with Us represents or warrants that the Website and its contents will be accurate, reliable, error-free or uninterrupted, that defects will be corrected, that Our site or the server that makes it available are free of viruses or other harmful components or that the Website or any Services or items obtained through the Website will otherwise meet Your needs or expectations.</p>
            </div>
        )
    });

}

export async function ReturnBlock() {
    Modal.info({
        title: 'RETURN & REFUND POLICY',
        okText: 'CLOSE',
        width: '700px',
        closable: true,
        footer: null,
        bodyStyle: { maxHeight: '400px' },
        style: { padding: '0px' },
        centered: true,
        maskClosable: true,
        content: (
            <div style={{height: '400px',overflowY:'scroll'}}>
              <ul>
                <li>You may cancel your account at anytime by emailing <a target="_blank">support@blumangoes.in</a></li>
                <li>Once your account is cancelled all of your Content will be immediately deleted from the Service. Since deletion of all data is final please be sure that you do in fact want to cancel your account before doing so.</li>
                <li>If you cancel the Service in the middle of the month, you will receive one final invoice via email. Once that invoice has been paid you will not be charged again.</li>
                <li>We reserve the right to modify or terminate the Blu Mangoes service for any reason, without notice at any time.</li>
                <li><b>Fraud</b> : Without limiting any other remedies, Blu Mangoes may suspend or terminate your account if we suspect that you (by conviction, settlement, insurance or escrow investigation, or otherwise) have engaged in fraudulent activity in connection with the Site.</li>
              </ul>
            </div>
        )
    });
}

export async function TermsConditionBlock() {
    Modal.info({
        title: 'TERMS OF SERVICES',
        okText: 'CLOSE',
        width: '700px',
        closable: true,
        footer: null,
        bodyStyle: { maxHeight: '400px' },
        style: { padding: '0px' },
        centered: true,
        maskClosable: true,
        content: (
            <div style={{height: '400px',overflowY:'scroll'}}>
             <p>Any person logging on to or using the Website (even when such person does not avail of any Services provided in the Website) shall be presumed to have read these Terms of Service and unconditionally accepted the terms and conditions set out herein and this constitutes a binding and enforceable agreement between the user and Us. These Terms of Service does not alter in any way the terms of conditions of any other written agreement You may have with Us for other Services.</p>
             <p>We provide Services subject to the terms and conditions of these Terms of Service (this "Agreement"). To obtain the Services, this Agreement must be reviewed and accepted by the party that will use the Service.</p>
             <p>The terms and conditions may be revised or altered by Us at Our sole discretion at any time without any prior intimation to the User. The latest Terms of Service will be posted here. Any such changes will be effective immediately. By continuing to use this Website or access/ usage of Our Services after changes are made, You agree to be bound by the revised/ amended Terms of Services. You are solely responsible for understanding and complying with all applicable laws of Your specific jurisdiction that may be applicable to You in connection with Your business and use of Our Services.</p>
             <p>In consideration of the foregoing and the terms below, the parties hereby agree as follows:</p>
                <ul>
                    <li><b>Our Service</b>
                        <p>1.	Service Generally. Customer wishes to use the Services in connection with its services and properties (the "Customer Properties"). We will provide the Services in accordance with the terms of this Agreement.</p>
                        <p>2.	We will provide Customer application programming interfaces (collectively, the "APIs") to enable Customer to access and implement the Service in connection with the Customer Properties. Through the APIs and management console, Customer may submit data and information to the Service as determined by Customer (collectively, the "Raw Data"). Based on Our analysis of the Raw Data alone and in combination with other data in the Service, We will provide Customer with analytical results (the "Analytical Results").</p>
                        <p>In order to access certain Services, You may be required to open a user account with Us by providing information about yourself (Personal Information/ Data) as part of the registration process for the Services, or as part of Your continued use of the Services. You also agree that We have the right to collect Your navigational or payment information. Navigational information refers to information about Your computer and Your visits to this Website such as Your IP address, geographical location, browser type, referral source and pages viewed. When You use Our Services, We automatically collect information about Your computer hardware and software. This information can include Your IP address, browser type, domain names, internet service provider, the file viewed on Our website, operating system, clickstream data, access times and referring website addresses.</p>
                        <p>We use the Navigational Information to operate and improve the Websites and the Service. We may also use the Navigational Information alone or in combination with personal information to provide You with the personalized information about Us.</p>
                        <p>The payment information pertaining to Your payment details shall be used to check Your financial qualification and collect payment from You.</p>
                        <p>You agree that any data provided by You shall always be accurate, correct, complete and up to date. If You provide any information that is untrue, inaccurate, incomplete, or not current or if We have reasonable grounds to suspect that such information is not in accordance with the Terms of Service, We reserve the right to reject Your registration and/or indefinitely suspend or terminate Your user account and to refuse to provide You access to the Website. You further acknowledge and agree that if We disable access to Your account You may be prevented from accessing the Services, Your account details or any files or other content which is contained in Your account and We shall intimate to You regarding the same. Further, You agree to indemnify and keep Us indemnified from and against all claims resulting from the use of any details/ information/ data that You post and / or supply to Us. We shall be entitled to remove any such detail/ information/ data posted by You without any prior intimation to You. In order to ensure that We are not violating any right that You might have in Your Data, You hereby grant Us a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sub-licensable right to exercise the copyright, publicity, and database rights that You have in the Data in any media now or in future known, with respect to Your data solely to enable Us to use such Data that You have supplied to Us.</p>
                        <p>Any amendment or rectification of Your Data in the user account can be carried out by accessing the “User Account” section on the Website. You may delete Your user content or user account at any time. Processing the deletion may take some time, but the same shall be done by Us. We may maintain backup of all user content for such time as may be required under applicable laws and for Our operational purposes. You are solely responsible for maintaining the confidentiality of Your account and password and for any activity that occurs in or through Your account. We will not be liable to any person for any loss or damage which may arise as a result of any failure by You to protect Your password or account. You should take all necessary steps to ensure that the password is kept confidential and secure. In case You have any reason to believe that Your password has become known to anyone else, or if the password is being, or is likely to be, used in an unauthorised manner, please change Your password immediately. In the event of any dispute between two or more parties as to ownership of any particular account with us, You agree that We will be the sole arbitrator of such a dispute and that Our decision in this regard will be final and binding on all parties.</p>
                        <p>Further, You have the right to be deleted, wherein You may stop using the Services at any point of time and have the right to delete all Your Data.</p>
                    </li>
                    <li><b>Protection of Data</b>
                        <p>1.	<b>General</b>. As between the parties, You own the Data and We own the Analytical Results. We will use the Data only as described in this Agreement and will maintain appropriate administrative, technical and physical security measures to protect the Data against unauthorized access, disclosure and loss. You will be notified promptly in the event of any suspected or actual breach of the security of Your account. We will comply with all applicable laws, rules and regulations (collectively, "Laws") in connection with its response and remediation of any security breach and will keep You informed of those activities.</p>
                        <p>2.	<b>Additional Use of Raw Data</b>. Additional Use of Data. Subject to the terms of this Section, You agree that We may use the Data on a perpetual basis in connection with fraud detection and prevention Services offered through the Service, as determined by Us in its reasonable discretion. In connection with such use, the Data will be aggregated with data from the other customers of the Service. In addition, any reports provided to other third party customers resulting from use of the Service will not include any identifiers of customer as the source of such data. We may promote the use of Our Services to You and share promotional and information content with You in accordance with Your communication preferences.</p>
                        <p>3.	<b>Social Media Features</b>.Our website includes social Media Features, such as Facebook, and twitter button and widgets, such as the “share” button. These features may collect Your IP address, which page you are visiting on Our website, and may set a cookie to enable the features to function properly. Social media features are either hosted by a third party or hosted directly on Our platform. Your interactions with these features are governed by the privacy policy of the company providing it.</p>
                        <p>4.	<b>Deletion of Data</b>. Upon termination of this Agreement, Customer may request deletion of the Raw Data. Subject to the provisions of Section 1.2(b) (Additional Use of Raw Data), We will perform such deletion within ninety days.</p>
                        <p>5.	<b>Marketing</b>. Subject to Customer’s consent and any trademark usage guidelines provided by Customer, we may use Customer’s name and logo in any customer list promoting the Service.</p>
                    </li>
                    <li><b>Additional Terms</b>
                        <p>1.	<b>Restrictions.</b> You will use the Service (for clarity, including the Analytical Results) only: (a) for its internal fraud detection and prevention purposes; (b) in accordance with the terms of this Agreement; and (c) in accordance with reasonable instructions given by Us, including compliance with the Acceptable Use Policy located here, which We may update reasonably from time to time. You will not engage in any activity that violates any contractual or other rights of any third parties or that disrupts the Service, including circumvention of any access or use restrictions. You will not transfer, resell, license or otherwise make the Service available to third parties. For clarity, the foregoing limitation applies to the Analytics Results and any other information derived from use of the Service, as well as the service itself. You will be solely responsible for all use of the Service under its account, including the acts and omissions of its users. You will notify Us immediately if You become aware of any unauthorized use or other compromise of Your account. You agree not to access (or attempt to access) any of the Services by any means other than through the interface that is provided by Us, unless You have been specifically allowed to do so in a separate agreement with Us. You specifically agree not to access (or attempt to access) any of the Services through any automated means (including use of scripts or web crawlers) and shall ensure that You comply with the instructions set out in any robots.text file present on the Services. You agree that You will not engage in any activity that interferes with or disrupts the Services (or the servers and networks which are connected to the Services) on this Website.</p>
                        <p>2.	<b>Compliance.</b> In connection with its activities hereunder, each party will comply with all applicable Laws, including those concerning the use of personal information and data protection, as well as any contractual or other obligation to any third party. You are solely responsible for ensuring that its use of the Service does not violate the Laws of the jurisdictions in which You carry on Your business.</p>
                        <p>3.	<b>Privacy Policies.</b> Each party will collect, process and store data in accordance with the terms of its privacy policy and will ensure that its privacy policy is readily accessible on its website(s) and at all times provides accurate disclosures concerning its respective activities in connection with this Agreement, including the collection and processing of personal information for fraud detection and prevention purposes. However, We may share the information collected from You with trusted partners to contact You based on Your request to receive such communication, help us perform statistical analysis, or provide customer support. Such third parties are prohibited from using Your personal information except for these purposes and they are required to maintain the confidentiality of Your information. We may transfer Your personal information to Our sister concerns to provide You with better Services or for troubleshooting purposes. Our sister concerns are bound by the principles of standard contractual clauses and the obligations under the privacy policy. We may share any content generated by the User or their data with governmental agencies that are lawfully authorised for investigative, protective, cyber security activities. Such information may be transferred for the purposes of verification of identity, or for prevention, detection, investigation, prosecution, cyber security incidents and punishment of offences under any law for the time being in force. Further we reserve the right to disclose any information pertaining to You, if it is necessary :
                            <ul>
                                <li>To satisfy or comply with any law, regulation or legal process or to respond to lawful requests, including subpoenas, warrants or court orders,</li>
                                <li>To protect Our property, rights, safety and the rights, property and safety of the third parties or the public in general; and</li>
                            </ul>
                        </p>
                        <p>To prevent or stop activity we consider to be illegal or unethical. We also may be required to disclose an individual’s personal information in response to a lawful request by public documents You should be aware that content presented to You as part of the Services, including but not limited to advertisements in the Services and sponsored content within the Services may be protected by intellectual property rights which are owned by the sponsors or advertisers who provide that content to Us (or by other persons or companies on their behalf). You may not modify, rent, lease, loan, sell, distribute or create derivative works based on this content (either in whole or in part) unless You have been specifically told that You may do so by Us or by the owners of that content, in writing and in a separate agreement.</p>
                    </li>
                    <li><b>Payment</b>
                        <p><b>Fees and Payment Terms.</b> For 30 Days from Project start date there will be a trial period in this period We will not charge any money, post the trial period fees will be 0.2% of transaction value subject to a maximum of Rs 20/transaction.</p>
                    </li>
                    <li><b>Confidentiality</b>
                        <p>1.	<b>Definitions.</b> "Confidential Information" means information provided to the receiving party ("Receiving Party") that is designated by the disclosing party ("Disclosing Party") as "confidential" or "proprietary" or that a reasonable person would understand to be confidential given the nature of the information and the circumstances of the disclosure. "Confidential Information" does not include information that: (a) is or becomes generally known to the public through no fault of the Receiving Party; (b) is in the Receiving Party’s possession prior to receipt from the Disclosing Party; (c) is acquired by the Receiving Party from a third party without breach of a confidentiality obligation; or (d) is independently developed by Receiving Party without reference to the Disclosing Party’s Confidential Information. Customer’s Confidential Information includes the Raw Data. Our Confidential Information includes the APIs, the Analytical Results and all documentation provided to Customer hereunder.</p>
                        <p>2.	<b>Confidentiality Obligations.</b> Receiving Party will use Confidential Information solely as contemplated by this Agreement and will disclose such information only to its employees, agents, and contractors who are bound by obligations of confidentiality at least as strict as those contained in this Section. Receiving Party will use reasonable care to protect Disclosing Party’s Confidential Information and to prevent unauthorized disclosure of Confidential Information. Confidential Information is and will remain the exclusive property of the Disclosing Party. Subject to the terms and conditions of this Agreement, Receiving Party will, upon written request, destroy all copies of the Disclosing Party’s Confidential Information that are in its possession or control.</p>
                        <p>3.	<b>Product Input.</b> Notwithstanding any provision in this Terms of Service to the contrary, if You submit any material on the Website, You agree thereby to grant us the right to use, develop and implement any information, suggestions, comments or other input provided by You or any of its users (collectively, "Input") in connection with the development, operation, marketing and sale of the Service, in its discretion and with no compensation/ royalty shall be provided to any person providing Input. You represents that it has not, and will not, knowingly provide Input that is subject to any third party intellectual property rights. Such being the case, You agree that You are solely responsible for (and that We are not responsible to You or to any third party for) any Content that You create, transmit or display while using the Services and for the consequences of Your actions (including any loss or damage which We may suffer) by doing so.</p>
                    </li>
                    <li><b>Proprietary Rights</b>
                        <p>You acknowledge and agree that We (or our licensors) own all legal right, title and interest pertaining to the Services, including any intellectual property rights which subsist in the Services (whether those rights happen to be registered or not, and wherever in the world those rights may exist). You further acknowledge that the Services may contain information which is designated confidential by Us and that You shall not disclose such information without Our prior written consent.</p>
                    </li>
                    <li><b>Indemnification</b>
                    <p>You will defend, indemnify and hold harmless us, Our officers, directors and employees against any third-party claim, demand, suit, investigation or proceeding relating to any violation or alleged violation of the terms of this Agreement.</p>
                    </li>
                    <li><b>Limitation of Liability</b>
                        <p>EXCEPT AS DESCRIBED IN THIS PARAGRAPH, UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY, WHETHER IN TORT, CONTRACT, OR OTHERWISE, WILL EITHER PARTY BE LIABLE TO THE OTHER FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES OF ANY CHARACTER, INCLUDING DAMAGES FOR LOSS OF GOODWILL, LOST PROFITS, LOST SALES OR BUSINESS, WORK STOPPAGE, COMPUTER FAILURE OR MALFUNCTION, LOST DATA, OR FOR ANY AND ALL OTHER DAMAGES OR LOSSES, EVEN IF SUCH PARTY HAS BEEN ADVISED, KNEW OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES. THE FOREGOING EXCLUSIONS WILL NOT APPLY TO ANY BREACH OF SECTION 2 (ADDITIONAL TERMS), SECTION 4 (CONFIDENTIALITY) OR ANY AMOUNTS PAYABLE TO A THIRD PARTY UNDER SECTION 5 (INDEMNIFICATION).</p>
                        <p>UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY, WHETHER IN TORT, CONTRACT, OR OTHERWISE, WE WILL BE LIABLE TO YOU FOR ANY DIRECT DAMAGES, COSTS, OR LIABILITIES IN AGGREGATE IN EXCESS OF THE AMOUNTS PAID BY YOU TWELVE MONTH PERIOD PRIOR TO THE YOUR INITIAL CLAIM.</p>
                    </li>
                    <li><b>Governing Law and Arbitration:</b>
                        <p>Any claim, differences or dispute arising under or in connection with or in relation hereto </p>
                    </li>
                    <li><b>Notice</b>
                        <p>All notices to Us will be served by email or letter. Any notice provided to Us pursuant to the Terms of Service and Privacy Policy should be sent to Our address as mentioned in the Terms of Service/Privacy Policy.</p>
                    </li>
                    <li><b>Questions</b>
                        <p>We welcome Your questions and comments regarding our Privacy Policy. You may contact us at <a>support@blumangoes.ai</a></p>
                    </li>
                </ul>
                <h4>DISCLAIMER</h4>
                <p>You understand that We cannot and do not guarantee or warrant that files available for downloading from the internet or the Website will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy Your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to Our site for any reconstruction of any lost data. We will not be liable for any loss or damage caused by a distributed denial-of-service attack, viruses or other technologically harmful material that may infect Your computer equipment, computer programs, data or other proprietary material due to Your use of the Website or any Services or items obtained through the Website or to Your downloading of any material posted on it, or on any Website linked to it.</p>
                <p>Neither We nor any person associated with Us represents or warrants that the Website and its contents will be accurate, reliable, error-free or uninterrupted, that defects will be corrected, that Our site or the server that makes it available are free of viruses or other harmful components or that the Website or any Services or items obtained through the Website will otherwise meet Your needs or expectations.</p>
                <p>We hereby disclaims all warranties of any kind, whether express or implied, statutory or otherwise, including but not limited to any warranties of merchantability, non-infringement and fitness for particular purpose.</p>
            </div>
        )
    });

}

export async function PrivacyBlock() {
    Modal.info({
        title: 'Application privacy policy',
        okText: 'CLOSE',
        width: '700px',
        closable: true,
        footer: null,
        bodyStyle: { maxHeight: '400px' },
        style: { padding: '0px' },
        centered: true,
        maskClosable: true,
        content: (
            <div style={{height: '400px',overflowY:'scroll'}}>
                <p>Tri-Continental Impex India built its AI based Catalog Management tool ‘Blumangoes’ which is a SaaS and is intended to be used by anyone. This page is used to inform website visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service.</p>
                <p>If you choose to use our Service, then you agree to the collection and use of information concerning this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.</p>
                <h4>Information Collection and Use</h4>
                <p>For a better experience, we may require you to provide us with certain personally identifiable information, including but not limited to e.g. users name, address, location, mobile number, etc. The information that we request will be retained by us and used as described in this privacy policy.The app does use third-party services that may collect information used to identify you. We use Google Analytics to track usage.</p>
                <h4>Log Data</h4>
                <p>We want to inform you that whenever someone uses our Service, in case of an error in the app we collect data and information (through third-party products) on your phone/desktop called Log Data. This Log Data may include information such as your devices’ Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics.</p>
                <h4>Cookies</h4>
                <p>Cookies are files with small amount of data that is commonly used as an anonymous unique identifier. These are sent to your browser from the website that you visit and are stored on your devices’ internal memory. This service does not use these “cookies” explicitly. However, the app may use third party code and libraries that use “cookies” to collection information and to improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.</p>
                <h4>Service Providers</h4>
                <p>We may employ third-party companies and individuals due to the following reasons:</p>
                <ul>
                    <li>To facilitate our Service;</li>
                    <li>To provide the Service on our behalf;</li>
                    <li>To perform Service-related services; or</li>
                    <li>To assist us in analyzing how our Service is used.</li>
                </ul>
                <p>We want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.</p>
                <h4>Security</h4>
                <p>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>
                <h4>Links to Other Sites</h4>
                <p>This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, I strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>
                <h4>Children’s Privacy</h4>
                <p>These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.</p>
                <h4>Changes to This Privacy Policy</h4>
                <p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.</p>
                <h4>Contact Us</h4>
                <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us on <a href='email:support@blumangoes.ai'>support@blumangoes.ai</a></p>
            </div>
        )
    });
}

export async function AboutusBlock() {
    Modal.info({
        title: 'ABOUT US',
        okText: 'CLOSE',
        width: '600px',
        closable: true,
        footer: null,
        bodyStyle: { maxHeight: '400px' },
        style: { padding: '0px' },
        centered: true,
        maskClosable: true,
        content: (
            <div style={{height: '400px',overflowY:'scroll'}}>
              <p>Cataloging is a major challenge in the E-commerce world, and It is no secret that the world is moving towards e-commerce and is getting faster and faster. So we ‘Tri-Continental Impex India’ have come up with the platform ‘Blumangoes’ for simplifying and overcoming challenges that come up for cataloging. It creates catalog for multiple portals and sorts images as per e-commerce portal’s specifications. Hence It saves time by giving immediate results and easy corrections makes it simple to use. No need of experts, cataloging is as simple as one click. It works on Artificial intelligence and analyze/process images as per portals guidelines so no need to spend money on multiple shoots, software will modify images for you.</p>
            </div>
        )
    });
}

export async function ContactUsBlock() {
    Modal.info({
        title: 'CONTACT US',
        okText: 'CLOSE',
        width: '500px',
        closable: true,
        footer: null,
        bodyStyle: { maxHeight: '300px' },
        style: { padding: '0px' },
        centered: true,
        maskClosable: true,
        content: (
            <div style={{height: '300px',overflowY:'scroll'}}>
              <h4>Registered Office:</h4>
              <p>Tri-continental Impex</p>
              <p>603, KAVERI GRAND, KAILASH VIHAR,</p>
              <p>AGRA-282 007 (U.P.), INDIA</p>
              <h4>Email</h4>
              <a href='mailto:Support@blumangoes.ai'>Support@blumangoes.ai</a>
              <h4>Phone No:</h4>
              <a href='tel:Phone No: +91 9105399099'> +91 9105399099</a>
            </div>
        )
    });
}