import React, {Component, Fragment} from 'react';
import DashboardDetail from './dashboardDetail';
import { APIService } from '../../../Config/action/apiAction';
import { TokenService } from '../../../Config/action/tokenAction';
import { connect } from 'react-redux';

class Dashboard extends Component{

    componentDidMount(){
        this.props.dispatch(APIService('POST', 'GetPaymentData?query=getuserdata @ID='+JSON.parse(localStorage.getItem("UserId")).value)).then((data) => {
            console.log(data);
            if(data.status === 200){
                this.setState({
                    userData: data.data.data[0]
                }, () => {
                    this.props.dispatch(TokenService('setToken', {key: 'auth_user', value: data.data.data[0]})).then(() => {
                        console.log('api fetched');
                    })
                })
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    render(){
        return(
            <Fragment>
                <div style={{backgroundColor:'#f4f6f9', minHeight: '100vh'}}>
                    <DashboardDetail />
                </div>
            </Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: () => {
            dispatch({
                type: 'loginUser'
            });
        },
        logoutUser: () => {
            dispatch({
                type: 'logoutUser'
            });
        },
        dispatch
    }
}

export default connect(null, mapDispatchToProps)(Dashboard);