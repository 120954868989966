import React, { Component } from 'react';
import { Configuration, getDataTable } from '../Configuration';
import '../ORDER/OrderMaster/style.css'
import { Spin } from 'antd';
import 'sweetalert2/src/sweetalert2.scss'



export default class frmOrgnisationProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginId: 0,
            OrgData: [],
            organizationID: '',
            orID: 0,
            orgname: '',
            orgcode: '',
            userID: '',
            userName: '',
            uEmail: '',
            uPhone: '',
            logDate: '',
            Token: '',
            usertime: '',
            isAgent: "0",
            cntno: '',
            mail: '',
            city: '',
            stt: '',
            pin: '',
            add: '',
            cntry: '',
            loading: false,
            gstno: ''
        };
    }
    async componentDidMount() {
        let q = ""
        q += " SELECT top(2) Users.userId, Users.orgid, Users.username, FORMAT(TokenAuth.usertime,'dd-MMM-yyyy hh:mm tt') as lastLogin"
        q += " ,OrganizationMaster.OrgName, OrganizationMaster.Address, Users.Email AS Umail, Users.Phone AS Uphone"
        q += " , OrganizationMaster.City, OrganizationMaster.State, OrganizationMaster.Country, DropBoxAccessToken.DrpToken"
        q += " , OrganizationMaster.PIN, OrganizationMaster.Phone, OrganizationMaster.OrgCode"
        q += " , OrganizationMaster.email, OrganizationMaster.GSTNO,  Users.IsAgent"
        q += " FROM Users INNER JOIN"
        q += " OrganizationMaster ON Users.OrgID = OrganizationMaster.OrgID"
        q += " inner join TokenAuth on Users.userId = TokenAuth.userID"
        q += " left outer join DropBoxAccessToken on Users.OrgID = DropBoxAccessToken.OrgID"
        q += "  where Users.userid = " + Configuration.USER_ID + ""
        q += " order by usertime desc"

        let rs1 = await getDataTable(q)
        await this.setState({
            loginId: Configuration.USER_ID,
            userID: rs1[0].userId,
            orID: rs1[0].orgid,
            orgname: rs1[0].OrgName,
            userName: rs1[0].username,
            uEmail: rs1[0].Umail,
            uPhone: rs1[0].Uphone,
            orgcode: rs1[0].OrgCode,
            logDate: rs1[0].logdate,
            usertime: rs1[1].lastLogin,
            Token: rs1[0].DrpToken,
            isAgent: rs1[0].IsAgent,
            add: rs1[0].Address,
            city: rs1[0].City,
            stt: rs1[0].State,
            cntry: rs1[0].Country,
            pin: rs1[0].PIN,
            cntno: rs1[0].Phone,
            mail: rs1[0].email,
            gstno: rs1[0].GSTNO
        });
    }
    myChangeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    async SaveToken() {
        let que =
            "DELETE FROM DropBoxAccessToken WHERE OrgID = " +
            this.state.orID;
        await getDataTable(que);

        let Query1 =
            "INSERT INTO DropBoxAccessToken (OrgID, DrpToken, logDate) VALUES (" + this.state.orID + "," + this.state.Token + ",cast(getdate() as date))"
        await getDataTable(Query1);
    }

    render() {
        return (

            <div className="content-wrapper">
                <div className='loader' style={{ display: this.state.loading ? "block" : "none" }}>
                    <div className='loader-item'>
                        <Spin />
                    </div>
                </div>
                <section className="content">
                    <div id="divclient" className="box box-success" style={{ marginTop: '-13px' }}>
                    <div className="box-header with-border">
                            <h3 className="box-title">ORGANIZATION PROFILE
                                <span id="ctl00_CPHMaincontent_DivOrdNo" style={{ display: 'inline' }}>
                                </span>
                            </h3>
                        </div>
                        <div className="box-body" id="orderDetails" style={{ display: 'block' }}>
                            <div className="row">
                                <div className="container user-profile " >
                                    <form method="">
                                        <div className="row">
                                            <div className="col-md-4" >
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <label >Name</label>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p >{this.state.orgname}</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <label >Code</label>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p >{this.state.orgcode}</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <label >Address</label>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p >{this.state.add}</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <label >City</label>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p >{this.state.city}</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <label >State</label>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p >{this.state.stt}</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <label >Pin Code</label>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p >{this.state.pin}</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <label >Phone</label>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p >{this.state.cntno}</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <label >Email</label>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p >{this.state.mail}</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <label >GSTNO</label>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p >{this.state.gstno}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6" >
                                                <div className="profile-head">
                                                    <br></br>
                                                    <br></br>
                                                    <h4>User Profile</h4>
                                                    <div >
                                                        <ul class="nav nav-tabs">
                                                            <li class="active" style={{ fontWeight: 'bold' }}><a href="#home" data-toggle="tab">About</a></li>
                                                            <li><a href="#profile" style={{ fontWeight: 'bold' }} data-toggle="tab">Timeline</a></li>
                                                        </ul>
                                                        <div id="myTabContent" class="tab-content">
                                                            <div class="tab-pane active in" id="home">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <label style={{ fontWeight: 'bold' }}>Name</label>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <p>{this.state.userName}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <label style={{ fontWeight: 'bold' }}>Email</label>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <p>{this.state.uEmail}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <label style={{ fontWeight: 'bold' }}>Phone</label>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <p>{this.state.uPhone}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <label style={{ fontWeight: 'bold' }}>User Type</label>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <p>{this.state.isAgent}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <br></br>
                                                                        <label style={{ fontWeight: 'bold' }}>Access Token</label>
                                                                    </div>


                                                                    <div className="box-body" id="orderDetails" style={{ display: 'block' }}>
                                                                        <div className="row">
                                                                            <div className="col-xs-6 col-sm-6 col-md-4 margintop">
                                                                                <label>&nbsp;</label>
                                                                                <div className="form-group">
                                                                                    <input name="Token" id="Token" defaultValue={this.state.Token} onChange={this.myChangeHandler} className="form-control" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                                                                <label>&nbsp;</label>
                                                                                <div className="form-group">
                                                                                    <button onClick={this.SaveToken.bind(this)} className='btn btn-primary'>Save Token</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="tab-pane fade" id="profile">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <label style={{ fontWeight: 'bold' }}>Last LogIN</label>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <p >{this.state.usertime}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </div>
        )
    }
}
