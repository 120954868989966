import { Modal, Button } from 'antd';
import React, { Component } from 'react';
import '../../ORDER/OrderMaster/style.css'
import Table from '../../Actions/Controls/Table/AntDTable'
import { Configuration, MandatoryFormFields, getDataTable, OnlyNumberKey } from '../../Configuration';
import AutoCompleteCascad from '../../Actions/Controls/AutoComplete/autoComplete.cascad';
import AutoComplete from '../../Actions/Controls/AutoComplete/autoComplete';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

export default class frmSetPaymentFee extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            modal1Visible: false,
            modal2Visible: false,
            loginId: 0,
            orgid: 0,
            RSDATA:[],
            TierID:0
        };
    }


    async componentDidMount() {
        let rs = await getDataTable("select orgid from users where userid = " + Configuration.USER_ID)
        await this.setState({
            loginId: Configuration.USER_ID,
            orgid: rs[0].orgid
        });
        await this.getdata(1)
    }

    setModal1Visible(modal1Visible) {
        this.setState({ modal1Visible });
    }

    setModal2Visible(modal2Visible) {
        this.setState({ modal2Visible});
    }

    myChangeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
        this.forceUpdate();
    }


    async getdata(val=1) {
        let Que = "  SELECT Portals.PortalName, BlockMaster.BlockName, PaymentRecoPaymentFee.PaymentPercent,pt.TierName,   "
        Que += " FORMAT(PaymentRecoPaymentFee.Logdate, 'dd-MMM-yyyy') AS Logdate, PaymentRecoPaymentFee.tblID as [#tblID] "
        Que += "  FROM   PaymentRecoPaymentFee INNER JOIN  Portals ON PaymentRecoPaymentFee.PortalID = Portals.PID INNER JOIN "
        Que += " BlockMaster ON PaymentRecoPaymentFee.BlockId = BlockMaster.CategoryID "
        Que += " INNER JOIN PortalTierMaster pt ON pt.tierId = PaymentRecoPaymentFee.TierID "
        let rs = await getDataTable(Que)
        if (rs.length > 0) {
            this.setState({ RSDATA: rs })
        }
        else {
            this.setState({ RSDATA: [] })
            return;
        }
    }



    async onAfterSelect(selectedOptions, id, name) {
        if (selectedOptions.length > 0) {
            this.setState({
                [id]: selectedOptions[0].DisplayId,
                [name]: selectedOptions[0].DisplayName,
            })
        }
        else {
            this.setState({
                [id]: '',
                [name]: '',
            })
        }
        this.forceUpdate();
    }



    async SaveTamplate() {
        let MandatoryArray = [
            { "PORTAL": this.state.PortalID },
            { "BLOCK": this.state.BlockID },
            { "TierID": this.state.TierID },
            { "PAYMENT %": this.state.fldFixedFee },
        ]
        let check = MandatoryFormFields(MandatoryArray);
        if (check == false) {
            return false;
        }
        let que = "select * from PaymentRecoPaymentFee where PortalID = "+this.state.PortalID+" and BlockId =  "+this.state.BlockID + " and TierID = "+this.state.TierID
        let rs = await getDataTable(que)
        if (rs.length > 0) {
            Swal.fire({
                icon: 'error',
                title: "ALREADY EXIST",
                showConfirmButton: false,
                timer: 1500
            })

            return false;
        }
        que = ""
        que += " INSERT INTO PaymentRecoPaymentFee ( PortalID, BlockId, PaymentPercent, loginID,TierID ) VALUES "
        que += "   ("+this.state.PortalID+","+this.state.BlockID+","+this.state.fldFixedFee+","+this.state.loginId+","+this.state.TierID+") "
        let rs1 = await getDataTable(que)
        Swal.fire({
            icon: 'success',
            title: "SUCCESSFULLY ADDED",
            showConfirmButton: false,
            timer: 1500
        })
        await this.getdata(this.state.orgid)
    }
    myChangeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    async deleteRow(e) {
        let st = await Swal.fire({ title: 'ARE YOU SURE WANT TO DELETE?', showCancelButton: true, confirmButtonText: 'YES', cancelButtonText: 'NO', })
        if (!st.isConfirmed) {
            return false;
        }
        let que = "Delete from PaymentRecoPaymentFee where tblID = "+e['#tblID']
        let dt = await getDataTable(que)
        Swal.fire({
            icon: 'info',
            title: "SUCCESSFULLY REMOVED",
            showConfirmButton: false,
            timer: 1500
        })
        await this.getdata(this.state.orgid)
    }


    render() {

        return (
            <div>
                <button type="button" onClick={() => this.setModal2Visible(true)} className="btn btn-block btn-default">SET PAYMENT FEE</button>

                <Modal
                    title="SET PAYMENT GATEWAY FEE"
                    width={this.props.width}
                    visible={this.state.modal2Visible}
                    onOk={() => this.setModal2Visible(false)}
                    onCancel={() => this.setModal2Visible(false)}
                    destroyOnClose={true}
                    maskClosable={false}
                    footer={null}
                >
                    <div className='row'>
                        <div className="col-xs-6 col-sm-6 col-md-3 margintop">
                            <label>SELECT PORTAL</label><span className="vcode">*</span>
                            <AutoCompleteCascad
                                id="portalID"
                                frmNm="FRMATTRIBUTEALIASE"
                                quryNm="FILLPORTAL"
                                db="IMAGEDB"
                                filter2=""
                                filterId2=""
                                filter1="orgid"
                                filterId1={this.state.orgid}
                                placeholder="Please Select Portal"
                                onAfterSelect={(e) => this.onAfterSelect(e, "PortalID", "PortalName")}
                                isValid={this.state.isValid}
                            ></AutoCompleteCascad>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                            <label>Select Block</label><span className="vcode">*</span>
                            <AutoCompleteCascad
                                id="catid"
                                frmNm="FRMALLDATA"
                                quryNm="FILLORGBLOCKS"
                                db="IMAGEDB"
                                filter1="orgid"
                                filterId1={this.state.orgid}
                                filter2=""
                                filterId2=""
                                placeholder="Please Select Block"
                                onAfterSelect={(e) => this.onAfterSelect(e, "BlockID", "BlockName")}
                                isValid={this.state.isValid}
                            ></AutoCompleteCascad>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-md-3 margintop">
                            <label>SELECT TIER</label><span className="vcode">*</span>
                            <AutoComplete
                                id="tierID"
                                frmNm="FRMSETFIXEDFEE"
                                quryNm="FILLTIER"
                                db="IMAGEDB"
                                placeholder="Please Select Tier"
                                onAfterSelect={(e) => this.onAfterSelect(e, "TierID", "TierName")}
                                isValid={this.state.isValid}
                            ></AutoComplete>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                            <label>ENTER PAYMENT(%)</label><span className="vcode">*</span>
                            <div className="form-group">
                                <input name="fldFixedFee" id="fldFixedFee" onChange={this.myChangeHandler} placeholder="ENTER PAYMENT(%)" onKeyPress={OnlyNumberKey} autoComplete="off" className="form-control" />
                            </div>
                        </div>
                        <div className='col-md-2 col-xs-6 col-sm-2 margintop'  >
                            <label>&nbsp;</label>
                            <div className='form-group'>
                                <button className='btn btn-block btn-primary' value='template' onClick={this.SaveTamplate.bind(this)}>Save</button>
                            </div>
                        </div>

                        <div className='col-md-12 col-xs-12 col-sm-12 margintop'>
                            {this.state.RSDATA.length > 0 && this.state.RSDATA != undefined &&
                                <Table
                                    data={this.state.RSDATA}
                                    Delete="true"
                                    DeleteFn={(e) => this.deleteRow(e)}
                                    height='63vh'
                                    id='fielddata'>
                                </Table>}
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }

}