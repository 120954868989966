import { Modal } from 'antd';
import React, { Component } from 'react';
import '../ORDER/OrderMaster/style.css'
import Table from '../Actions/Controls/Table/AntDTable'
import { Configuration, MandatoryFormFields, getDataTable, arrUnique, GetNewDrpSheet, exceltodb, GetDropdownDynamicNew } from '../Configuration';
import AutoCompleteInput from '../Actions/Controls/AutoComplete/autoComplete';
import AutoCompleteCascad from '../Actions/Controls/AutoComplete/autoComplete.cascad';
import AutoCompletemulti from '../Actions/Controls/AutoComplete/autoCompleteMultiple';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import { Spin } from 'antd';
const dataBase = "IMAGEDB";

export default class frmVCR extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      orgid: [],
      loading: false,
      loginId: 0,
      RSDATA: [],
      ResData: [],
      ResAID: 0,
      ResAVID: 0,
      AID: 0,
      Catid: 0,
      files: null,
      upload: 0,
      RelationShipName: '',
      showRelationshipData: false,
      RelData: [],
      selectedRowKeys: [],
      excelData: null,
    };
  }

  async componentDidMount() {
    let rs = await getDataTable("select orgid from users where userid = " + Configuration.USER_ID)
    await this.setState({
      loginId: Configuration.USER_ID,
    });
    await this.showRelationshipDataHandler();
  }

  async fetchRelationshipData() {
    try {
      const relationshipData = await getDataTable("EXEC ShowRelationshipData");

      if (relationshipData) {
        this.setState({
          RelData: relationshipData,
        });
      } else {

      }
    } catch (error) {
      console.error(error);
    }
  }

  showRelationshipDataHandler = () => {
    this.fetchRelationshipData();

    this.setState({
      showRelationshipData: true,
    });
  };

  hideRelationshipDataHandler = () => {
    this.setState({
      showRelationshipData: false,
    });
  };

  async generateExcel() {
    if (this.state.RSDATA.length === 0 || this.state.ResData.length === 0) {
      Swal.fire({
        icon: "error",
        title: "PLEASE ADD INPUT AND OUTPUT ATTRIBUTE FIRST THEN GENERATE EXCEL",
        showConfirmButton: false,
        timer: 1500,
      });
      return false;
    }

    this.setState({ loading: true });

    // Get the selected input and output attribute IDs
    const inputAttributeIds = this.getSelectedInputAttributeIds();
    const outputAttributeIds = this.getSelectedOutputAttributeIds();

    let que2 = "EXEC SaveValueIdForTextVcr @aid ='" + inputAttributeIds.join(",") + "', @BlockId =" + this.state.Catid + ",@loginId =" + this.state.loginId
    let saveValueIdForText = await getDataTable(que2)

    // Call the stored procedure with the input and output attribute IDs
    let que = "EXEC ExportDataVcr @aid = '" + inputAttributeIds.join(",") + "', @aid2 = '" + outputAttributeIds.join(",") + "', @BlockID = " + this.state.Catid + ", @loginID = " + this.state.loginId;
    let rs = await getDataTable(que);

    debugger;
    await GetDropdownDynamicNew(this.state.Catid, rs, 'Master Data.xlsx');
    this.setState({ loading: false });
  }

  getSelectedInputAttributeIds() {
    return this.state.RSDATA.map((row) => row["#AID"]);
  }

  getSelectedOutputAttributeIds() {
    return this.state.ResData.map((row) => row["#ResAID"]);
  }

  async onAfterSelect(selectedOptions, id, name) {
    if (selectedOptions.length > 0) {
      this.setState({
        [id]: selectedOptions[0].DisplayId,
        [name]: selectedOptions[0].DisplayName,
      })
    }
    else {
      this.setState({
        [id]: '',
        [name]: '',
      })
    }
  }

  myFileChangeHandler = (event) => {
    const file = event.target.files[0];
    this.setState({ files: file, upload: 1 });
  };


  async onAutoCOmpletMultiPleSelect(selectedOptions, name) {
    if (selectedOptions.length > 0) {
      this.setState({
        [name]: selectedOptions
      });
    }
    else {
      this.setState({
        [name]: ""
      })
    }
  }

  async addData() {
    let MandatoryArray = [
      { "Block Name": this.state.Catid },
      { "Attribute": this.state.AID },
      { "RelationShip Name": this.state.RelationShipName },

    ];
    let check = MandatoryFormFields(MandatoryArray);
    if (check == false) {
      return false;
    }

    let existingData = this.state.RSDATA.find(
      (ele) =>
        ele["#BlockId"] === this.state.Catid &&
        ele["#OrgId"] === this.state.orgid[0].DisplayId &&
        ele["#AID"] === this.state.AID
    );

    if (existingData) {
      Swal.fire({
        icon: "error",
        title: "Input attribute already exists",
        showConfirmButton: false,
        timer: 1500,
      });
      return false;
    }

    let dt = this.state.RSDATA;

    for (let k = 0; k < this.state.orgid.length; k++) {
      let org = this.state.orgid[k];
      let st = {
        "#AID": this.state.AID,
        "#BlockId": this.state.Catid,
        "#OrgId": org.DisplayId,
        "AttributeName": this.state.ANAME,
        "RelationShipName": this.state.RelationShipName,
        "Orgnization": org.DisplayName,
        "BlockName": this.state.catname,
      };

      dt.push(st);
      let tempData = await arrUnique(dt);
      this.setState({
        RSDATA: tempData,
      });
    }
  }


  async addResultantData() {
    if (this.state.RSDATA.length === 0) {
      Swal.fire({
        icon: "error",
        title: "PLEASE ADD RELATIONSHIP FIRST THEN ADD RESULTANT DATA",
        showConfirmButton: false,
        timer: 1500,
      });
      return false;
    }

    let MandatoryArray = [
      { "Block Name": this.state.Catid },
      { "Attribute": this.state.AID },
    ];
    let check = MandatoryFormFields(MandatoryArray);
    if (check == false) {
      return false;
    }

    let existingData = this.state.ResData.find(
      (ele) =>
        ele["#BlockId"] === this.state.Catid &&
        ele["#OrgId"] === this.state.orgid[0].DisplayId &&
        ele["#ResAID"] === this.state.ResAID
    );

    if (existingData) {
      Swal.fire({
        icon: "error",
        title: "Output Attribute already exist",
        showConfirmButton: false,
        timer: 1500,
      });
      return false;
    }

    if (
      this.state.RSDATA.some((res) => res["#AID"] === this.state.ResAID)
    ) {
      Swal.fire({
        icon: "error",
        title: "Output attribute must be different from input attributes",
        showConfirmButton: false,
        timer: 1500,
      });
      return false;
    }

    let dt = this.state.ResData;
    let cnt = 0;
    dt.forEach((ele) => {
      if (
        this.state.ResAID === ele["#ResAID"] &&
        this.state.Catid === ele["#BlockId"] &&
        this.state.orgid.some((org) => org.DisplayId === ele["#OrgId"])
      ) {
        cnt = cnt + 1;
      }
    });
    if (cnt > 0) {
      return false;
    }

    for (let k = 0; k < this.state.orgid.length; k++) {
      let org = this.state.orgid[k];

      let st = {
        "#ResAID": this.state.ResAID,
        "#BlockId": this.state.Catid,
        "#OrgId": org.DisplayId,
        "AttributeName": this.state.ResANAME,
        "RelationShipName": this.state.RelationShipName,
        "Orgnization": org.DisplayName,
        "BlockName": this.state.catname,
      };

      dt.push(st);
      let tempData = await arrUnique(dt);
      this.setState({
        ResData: tempData,
      });
    }
  }

  async uploadFile() {
    let MandatoryArray = [{ "File": this.state.files }];
    let check = MandatoryFormFields(MandatoryArray);
    if (check === false) {
      return false;
    }
    this.setState({loading: true})

    let rs = await exceltodb(this.state.files, dataBase, this.state.loginId);
    debugger
    if (rs) {
      let queCheck = "EXEC ExportDataVcrOutput @tblName = '" + rs + "'"
      let rschk = await getDataTable(queCheck)
      if (rschk.length > 0) {
        Swal.fire({
          icon: "success",
          title: "Uploaded Successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        this.setState({
          excelData: rschk,
          loading: false
        });
      }
      this.setState({loading: false})
    }
  }


  async saveData() {
    let MandatoryArray = [{ "DATA": this.state.excelData }];
    let check = MandatoryFormFields(MandatoryArray);
    if (check === false) {
      return false;
    }
    this.setState({ loading: true });

    // Extract productId, outputConcatId, and loginId from state
    const productId = this.state.excelData.map(item => item.productid);
    const outputConcatId = this.state.excelData.map(item => item.Conoutvalue);
    const loginId = this.state.loginId;

    let que = "SaveDataProductDetailsVcr @pid = '" + productId.join(",") + "', @Outid = '" + outputConcatId.join(",") + "', @loginid = '" + loginId + "'";
    let saveData = await getDataTable(que);
    debugger
    if (saveData.length > 0) {
      Swal.fire({
        icon: "success",
        title: "Saved Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      this.setState({ loading: false });
    } else {
      Swal.fire({
        icon: "error",
        title: "Not Saved",
        showConfirmButton: false,
        timer: 1500,
      });
      this.setState({ loading: false });
    }
  }


  async saveRelationship() {
    if (
      this.state.RSDATA.length === 0 ||
      this.state.ResData.length === 0 ||
      this.state.orgid.length === 0 ||
      this.state.AID === 0 ||
      this.state.ResAID === 0
    ) {
      Swal.fire({
        icon: "error",
        title: "Please add input and output data first then click on save",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }

    this.setState({ loading: true });

    try {
      let insertedRows = [];
      let combinationSaved = false;

      for (let k = 0; k < this.state.orgid.length; k++) {
        let org = this.state.orgid[k];

        let checkQue = `SELECT COUNT(*) AS count FROM RelationshipAttributeMaster WHERE BlockId = ${this.state.Catid} AND OrgId = ${org.DisplayId} AND RelationshipName = '${this.state.RelationShipName}'`;
        let checkResult = await getDataTable(checkQue);
        if (checkResult && checkResult[0].count > 0) {
          Swal.fire({
            icon: "warning",
            title: "Relationship Already Exists",
            showConfirmButton: false,
            timer: 1500,
          });
          this.setState({ loading: false });
          return;
        } else {
          let que = `INSERT INTO RelationshipAttributeMaster (BlockId, OrgId, RelationshipName, LoginId, Logdate) output inserted.RID VALUES (${this.state.Catid}, ${org.DisplayId}, '${this.state.RelationShipName}', ${this.state.loginId}, cast(getdate() as date))`;
          let result = await getDataTable(que);

          if (result.length > 0) {
            insertedRows.push(result[0]);
          }
        }
      }

      if (!combinationSaved) {
        for (let i = 0; i < insertedRows.length; i++) {
          let row = insertedRows[i];

          let RID = row["RID"];

          for (let j = 0; j < this.state.RSDATA.length; j++) {
            let rsDataRow = this.state.RSDATA[j];
            let inputAID = rsDataRow["#AID"];

            for (let k = 0; k < this.state.ResData.length; k++) {
              let resDataRow = this.state.ResData[k];
              let outputAID = resDataRow["#ResAID"];

              let que = `INSERT INTO RelationshipAttributeDetail (RID, AttributeId, Type, LoginId, Logdate) VALUES (${RID}, ${inputAID}, 'Input', ${this.state.loginId}, cast(getdate() as date))`;
              let detailResult = await getDataTable(que);
              let que1 = `INSERT INTO RelationshipAttributeDetail (RID, AttributeId, Type, LoginId, Logdate) VALUES (${RID}, ${outputAID}, 'Output', ${this.state.loginId}, cast(getdate() as date))`;
              let detailResult1 = await getDataTable(que1);
              if (!detailResult || !detailResult1) {
                combinationSaved = false;
              }
            }
          }
        }
      }

      if (combinationSaved) {
        Swal.fire({
          icon: "warning",
          title: "Combination Not Saved",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        Swal.fire({
          icon: "success",
          title: "Successfully Saved",
          showConfirmButton: false,
          timer: 1500,
        });
      }

      this.setState({ loading: false });
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        title: "An error occurred while saving the data",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }


  myChangeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSelectedRows = (selectedRows) => {
    const selectedRowKeys = selectedRows.map((row) => row.key);
    this.setState({
      selectedRowKeys: selectedRowKeys,
    });
  };

  render() {
    return (
      <div className="content-wrapper">
        <div className='loader' style={{ display: this.state.loading ? "block" : "none" }}>
          <div className='loader-item'>
            <Spin />
          </div>
        </div>
        <section className="content">
          <div id="ctl00_CPHMaincontent_divclient" className="box box-success" style={{ marginTop: '-13px' }}>
            <div className="box-header with-border">
              <h3 className="box-title">VCR
                <sup>(<span className="vcode">*</span> Fields are mandatory)</sup>
                <span id="ctl00_CPHMaincontent_DivOrdNo" style={{ display: 'inline' }}>
                </span>
              </h3>
            </div>
            <div className="box-body" id="LotMaster" style={{ display: 'block' }}>
              <div className='row'>
                <div className="col-xs-6 col-sm-6 col-md-1 margintop">
                  <label>Select Block</label>
                  <span className="vcode">*</span>
                  <AutoCompleteInput
                    id="catid"
                    frmNm="FRMCATPRODUCTMASTER"
                    quryNm="FILLCAT"
                    db="IMAGEDB"
                    placeholder="Please Select Block"
                    onAfterSelect={(e) =>
                      this.onAfterSelect(e, "Catid", "catname")
                    }
                    isValid={this.state.isValid}
                  ></AutoCompleteInput>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-2 margintop" style={{ marginTop: '2px' }}>
                  <label>Select Organization</label><span className="vcode">*</span>
                  <AutoCompletemulti
                    id="articleid"
                    frmNm="FRMUSER"
                    quryNm="FILLORGANIZATION"
                    db="IMAGEDB"
                    placeholder="Please Select Organization"
                    onAfterSelect={(e) => this.onAutoCOmpletMultiPleSelect(e, "orgid")}
                    isValid={this.state.isValid}
                  ></AutoCompletemulti>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-1 margintop">
                  <label>RelationShip Name</label><span className="vcode">*</span>
                  <div className="form-group">
                    <input autoComplete="off" name="RelationShipName" id="RelationShipName" onChange={this.myChangeHandler} placeholder="Enter RelationShip Name" tabIndex={1} className="form-control" />
                  </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                  <label>Select Input Attribute</label>
                  <span className="vcode">*</span>
                  <AutoCompleteCascad
                    id="AID"
                    frmNm="FRMVCR"
                    quryNm="FILLATTRIBUTE"
                    db="IMAGEDB"
                    filter1="CatID"
                    filterId1={this.state.Catid}
                    filter2=""
                    filterId2=""
                    placeholder="Please Select Attribute"
                    onAfterSelect={(e) =>
                      this.onAfterSelect(e, "AID", "ANAME")
                    }
                    isValid={this.state.isValid}
                  ></AutoCompleteCascad>
                </div>
                <div className='col-md-2 col-xs-6 col-sm-1 margintop'  >
                  <label>&nbsp;</label>
                  <div className='form-group'>
                    <button className='btn btn-primary' value='template' onClick={this.addData.bind(this)}>ADD</button>
                  </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-2 margintop" style={{ marginLeft: "-120px" }}>
                  <label>Select Output Attribute</label><span className="vcode">*</span>
                  <AutoCompleteCascad
                    id="AttribiteID"
                    frmNm="FRMVCR"
                    quryNm="FILLATTRIBUTE"
                    db="IMAGEDB"
                    filter1="CatID"
                    filterId1={this.state.Catid}
                    filter2=""
                    filterId2=""
                    placeholder="Please Select Attribute"
                    onAfterSelect={(e) => this.onAfterSelect(e, "ResAID", "ResANAME")}
                  ></AutoCompleteCascad>
                </div>
                <div className='col-md-2 col-xs-6 col-sm-1 margintop'>
                  <label>&nbsp;</label>
                  <div className='form-group'>
                    <button className='btn btn-primary' value='template' onClick={this.addResultantData.bind(this)}>ADD</button>
                  </div>
                </div>
                <div className='col-xs-12 col-sm-12 col-md-6 margintop'>
                  <label>&nbsp;</label>
                  {this.state.RSDATA.length > 0 && this.state.RSDATA != undefined &&
                    <Table style="float: left"
                      data={[...this.state.RSDATA]}
                      Delete="true"
                      DeleteFn={(e) => this.deleteRow(e)}
                      height='63vh'
                      id='fielddata'>
                    </Table>}
                </div>
                <div className='col-xs-12 col-sm-12 col-md-6 margintop'>
                  <label>&nbsp;</label>
                  {this.state.ResData.length > 0 && this.state.ResData != undefined &&
                    <Table style="float: left"
                      data={[...this.state.ResData]}
                      Delete="true"
                      DeleteFn={(e) => this.deleteResultantRow(e)}
                      height='63vh'
                      id='fielddata'>
                    </Table>}
                </div>
              </div>
              <div className='col-md-2 col-xs-6 col-sm-1 margintop'>
                <label>&nbsp;</label>
                <div className='form-group'>
                  <button className='btn btn-primary' value='template' onClick={this.saveRelationship.bind(this)}>SAVE RELATIONSHIP</button>
                </div>
              </div>
              <div className='col-md-2 col-xs-6 col-sm-2 margintop'>
                <label>&nbsp;</label>
                <div className='form-group'>
                  <button className='btn btn-primary' onClick={this.generateExcel.bind(this)}>Generate Excel</button>
                </div>
              </div>
              <div className="col-xs-6 col-sm-6 col-md-2 margintop" >
                <label>UPLOAD FILE</label>
                <span className="vcode">*</span>
                <div className="form-group">
                  <input
                    name="fldname"
                    type="file"
                    accept=".xls,.xlsx,.xlsm,.csv"
                    id="tp1"
                    onChange={this.myFileChangeHandler}
                    placeholder="Enter FieldName"
                    className="form-control"
                  />
                </div>
              </div>
              <div className='col-md-2 col-xs-6 col-sm-2 margintop'>
                <label>&nbsp;</label>
                <div className='form-group'>
                  <button className='btn btn-primary' onClick={this.uploadFile.bind(this)}>Upload File</button>
                </div>
              </div>
              <div className='col-md-2 col-xs-6 col-sm-2 margintop'>
                <label>&nbsp;</label>
                <div className='form-group'>
                  <button className='btn btn-primary' onClick={this.saveData.bind(this)}>Save</button>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 margintop">
                {this.state.RelData.length > 0 && this.state.RelData !== undefined &&
                  <Table
                    data={[...this.state.RelData]}
                    EditRow='true'
                    EditRowFn={(e) => this.EditRowFn(e)}
                    Delete="true"
                    DeleteFn={(e) => this.deleteRelationship(e)}
                    height='63vh'
                    id='fielddata'
                    exportXL="true">
                  </Table>
                }
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }

  async deleteRow(e) {
    let dt = this.state.RSDATA
    dt.splice(e['key'], 1);
    this.setState({
      RSDATA: dt
    })
  }

  async EditRowFn(e) {
    if (this.state.loginId === "1" || this.state.loginId === "10" || this.state.loginId === "6") {
      let st = window.confirm("Are you sure want to Edit ?")
      if (!st) {
        return false;
      }
      let Que = "EXEC ShowRelationshipForEdit @rid = " + e["#RID"]
      let rs = await getDataTable(Que)
      debugger
      if (rs.length > 0) {
        this.setState({
          catname: rs[0].BlockName,
          Catid: rs[0]["#blockid"],
          RelationShipName: rs[0].RelationshipName
        })
      }
      let orgque = "SELECT   OrgID as DisplayId,OrgCode+'-'+OrgName as DisplayName"
      orgque += " FROM OrganizationMaster "
      orgque += " where OrgID = " + rs[0]["#orgid"]
      let orgRs = await getDataTable(orgque)
      this.setState({
        orgid: orgRs
      })
    }
    else {
      alert("You are not allowed to edit")
      return;
    }
  }

  async deleteResultantRow(e) {
    let dt = this.state.ResData
    dt.splice(e['key'], 1);
    this.setState({
      ResData: dt
    })
  }


  async deleteRelationship(e) {
    console.log(e);
    if (this.state.loginId === "1" || this.state.loginId === "10" || this.state.loginId === "6") {
        let st = window.confirm("Are you sure want to delete ?")
        if (!st) {
            return false;
        }
        console.log(e);
        let Que = " delete from RelationshipAttributeMaster output DELETED.RID where RID = " + e["#RID"]
        let rs = await getDataTable(Que)
        let Que1 = " delete from RelationshipAttributeDetail output DELETED.RID where RID = " + e["#RID"]
        let rs1 = await getDataTable(Que1)
        if (rs.length > 0 && rs1.length > 0) {
            Swal.fire({
                position: 'top-end',
                icon: 'info',
                title: 'Successfully Deleted',
                showConfirmButton: false,
                timer: 1500
            })
            await this.fetchRelationshipData()
        }
        else {
            alert("Some Error Occurred")
        }
    }
    else {
        alert("You are not allowed to delete")
        return;
    }
}


}