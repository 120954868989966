import React, { Component } from 'react';
import CompanyLogo from '../../assets/bluMangoes.png';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import SaveIcon from '@material-ui/icons/Save';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { APIService } from '../../../Config/action/apiAction';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import PageTitle from '../../Common/pageTitle';
import { Spin } from 'antd';
import GetAppIcon from '@material-ui/icons/GetApp';

class InvoiceDetail extends Component {

    constructor(props){
        super(props);
        this.state = {
            data: null,
            billingInfo: null,
            loading: false
        }
    }

    componentDidMount(){
        this.setState({
            loading: true
        }, () => {
            let orderId = this.props.match.params.id;
            this.props.dispatch(APIService('POST', `GetPaymentData?query= TRANSACTION_DETAILS @ORDERID = ${orderId}`, '')).then((data) => {
                console.log(data);
                if(data.data.status === 1000){
                    this.setState({
                        data: data.data.data[0],
                        loading: false
                    }, () => {
                        if(this.state.data.Customer_name){
                            this.setState({
                                ...this.state,
                                billingInfo: {
                                    name: this.state.data.Customer_name || '',
                                    email: this.state.data.Customer_mail || '',
                                    organisation: this.state.data.Customer_org || '',
                                    GSTNO: this.state.data.GSTNO || '',
                                    ADDRESS: this.state.data.ADDRESS || '',
                                    PIN: this.state.data.PIN || '',
                                    PHONE: this.state.data.PHONE || ''
                                }
                            })
                        }
                        else{
                            this.setState({
                                ...this.state,
                                billingInfo: {
                                    name: this.state.data.Payee_name || '',
                                    email: this.state.data.Payee_mail || '',
                                    organisation: this.state.data.Payee_org || '',
                                    GSTNO: this.state.data.GSTNO || '',
                                    ADDRESS: this.state.data.ADDRESS || '',
                                    PIN: this.state.data.PIN || '',
                                    PHONE: this.state.data.PHONE || ''
                                }
                            })
                        }
                    })
                }
            }).catch((e) => {
                this.setState({
                    loading: false
                })
                console.log(e);
            })
        })
    }

    printInvoice = () => {
        let orderId = this.props.match.params.id;
        window.open(window.location.origin+'/#/print-invoice/'+orderId, "_blank", "width=800,height=500");
    }

    formatDate = (date) => {
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
      
      var d = new Date(date);
      return (monthNames[d.getMonth()] + ' ' + d.getDate() + ', ' + d.getFullYear())
    }

    downloadInvoice = () => {
        // this.props.history.push('/print-invoice')
        const input = document.getElementById('invoice');
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            // this.setState({
            //     ...this.state,
            //     src: imgData
            // })
            const pdf = new jsPDF();
            pdf.addImage(imgData, 'JPEG', -10, 10, 270, 110);
            // pdf.output('dataurlnewwindow');
            pdf.save(`${this.props.match.params.id}.pdf`);
        });
    }

    render() {
        return (
            <div className="content-wrapper">
                <div className='loader' style={{ display: this.state.loading ? "block" : "none" }}>
                    <div className='loader-item'>
                        <Spin />
                    </div>
                </div>
                <PageTitle title={"Reciept"} />
                <section className="content" style={{minHeight:'85%'}}>
                    <div className="container-fluid mt-3" style={{paddingBottom:'70px'}}>
                        {/* <div class="row">
                            <div class="col-12">
                                
                                <div class="callout callout-info">
                                    <h5 style={{fontSize:'18px', fontWeight:'normal'}}><i class="fas fa-info"></i> Note:</h5>
                                    <span style={{fontSize:'14.8px'}}>
                                        This page has been enhanced for printing. Click the print button at the bottom of the invoice to test.
                                    </span>
                                </div>
        
                                <div class="invoice p-3 mb-3">
                                    <div class="row">
                                        <div class="col-12">
                                            <h4 className="mt-2 mb-2 pl-2 pr-2">
                                                <i class="fas fa-globe"></i> BluMangoes
                                                <small class="float-right" style={{fontSize:'16px'}}>Date: 2/10/2014</small>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div>
                            <div id="invoice">

                                <div className="invoice-box">
                                    <table cellPadding="0" cellSpacing="0">
                                        <tbody>
                                            <tr className="top">
                                                <td colSpan="2">
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td className="title">
                                                                    <img src={CompanyLogo} style={{width:"100%", maxWidth:"110px"}} />
                                                                </td>
                                                                
                                                                <td>
                                                                    {this.state.data ? this.state.data.RCTNO : ''}<br />
                                                                    {this.state.data ? this.formatDate(this.state.data.TXNDATE) : ''}<br />
                                                                    {/* Due: February 1, 2015 */}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            
                                            <tr className="information">
                                                <td colSpan="2">
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    {/* BluMangoes,<br /> */}
                                                                    BluMangoes ventures Pvt. Ltd.<br />
                                                                    404, Kaveri Greens, Kailash Vihar,<br />
                                                                    Agra-282 007 (U.P), India
                                                                </td>
                                                                
                                                                <td>
                                                                {this.state.billingInfo ? this.state.billingInfo.organisation : ''}<br />
                                                                {this.state.billingInfo ? this.state.billingInfo.PHONE : ''}<br />
                                                                {this.state.billingInfo ? this.state.billingInfo.email : ''}<br />
                                                                {this.state.billingInfo ? this.state.billingInfo.ADDRESS : ''}<br />
                                                                {this.state.billingInfo ? this.state.billingInfo.PIN : ''}<br />
                                                                {this.state.billingInfo ? this.state.billingInfo.GSTNO : ''}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
            
                                            <tr className="heading">
                                                <td>
                                                    Payment Method
                                                </td>
                                                
                                                <td>
                                                    <span className="txnID">
                                                        Transaction ID
                                                    </span>
                                                </td>
                                            </tr>
            
                                            <tr className="details" style={{fontSize:'14px'}}>
                                                <td>
                                                    {this.state.data ? this.state.data.BANKNAME : ''}
                                                </td>
                                                
                                                <td>
                                                    <span className="txnID">
                                                        {this.state.data ? this.state.data.TXNID : ''}
                                                    </span>
                                                </td>
                                            </tr>
                                            
                                            <tr className="heading">
                                                <td>
                                                    Payment Status
                                                </td>
                                                
                                                <td>
                                                    Price
                                                </td>
                                            </tr>
                                            
                                            <tr className="item last">
                                                <td>
                                                    {this.state.data ? this.state.data.STATUS === 'TXN_SUCCESS' ? "Success" : "Failure" : ""}
                                                </td>
                                                
                                                <td>
                                                    &#8377;{this.state.data ? this.state.data.TXNAMOUNT : ''}
                                                </td>
                                            </tr>
                                            
                                            <tr className="total">
                                                <td></td>
                                                
                                                <td>
                                                Total: &#8377;{this.state.data ? this.state.data.TXNAMOUNT : ''}<br />
                                                (Total includes GST 18%)
                                                </td>
                                            </tr>
                                        </tbody>
                                        
                                    </table>
                                </div>
                            </div>
                            <div className="invoice-box-footer">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{margin:'1px', fontSize:'13px'}}
                                    startIcon={<SaveIcon />}
                                    onClick={this.printInvoice}>
                                    Print
                                </Button>
        
                                {/* <Button
                                    variant="contained"
                                    style={{marginLeft:'10px', color: 'white', backgroundColor:'#dc3545', fontSize:'13px'}}
                                    startIcon={<GetAppIcon />}
                                    onClick={this.downloadInvoice}
                                    >
                                    Download
                                </Button> */}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: () => {
            dispatch({
                type: 'loginUser'
            });
        },
        logoutUser: () => {
            dispatch({
                type: 'logoutUser'
            });
        },
        dispatch
    }
}

export default connect(null, mapDispatchToProps)(withRouter(InvoiceDetail));