import React, { Component, Fragment } from "react";
import { Redirect, Route, Switch } from "react-router";
import ImportSheetDetail from "./importSheetDetail";
import importSheetList from "./importSheetList";

class Import_Sheet extends Component {
  render() {
    return (
      <Fragment>
        <div style={{ backgroundColor: "#f4f6f9", minHeight: "100vh" }}>
          <Switch>
            <Route
              exact
              path="/Import-sheet/templates"
              component={importSheetList}
            />
            <Route
              path="/Import-sheet/templates/edit/:id"
              component={ImportSheetDetail}
            />
            <Route
              exact
              path="/Import-sheet/templates/create"
              component={ImportSheetDetail}
            />
            <Redirect from="/Import-sheet" to="/Import-sheet/templates" />
          </Switch>
          {/* <PricingDetail /> */}
        </div>
      </Fragment>
      // <Fragment>
      //     <div style={{backgroundColor:'#f4f6f9', minHeight: '100vh'}}>
      //         <ImportSheetDetail />
      //     </div>
      // </Fragment>
    );
  }
}

export default Import_Sheet;
