import React, { useState, useEffect } from "react";
import { setUserSession, Configuration } from "./Configuration";
import {
  DisclaimerBlock,
  ReturnBlock,
  TermsConditionBlock,
  PrivacyBlock,
  AboutusBlock,
  ContactUsBlock,
} from "./CommanFunc";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Swal from "sweetalert2/dist/sweetalert2.js";
import CryptoService from "./Config/action/crypto";
import ForgotPassword from "./ForgotPassword";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

function Login(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [loginVisible, setLoginVisible] = useState(true);
  const [forgotPasswordVisible, setForgotPasswordVisible] = useState(false);
  const username = useFormInput("");
  const password = useFormInput("");
  const factory = { value: "IMAGEDB" }; //useFormInput('');
  const [error, setError] = useState(null);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [loginAttempts, setLoginAttempts] = useState(0);
  const [accountLocked, setAccountLocked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const checkAccountLock = () => {
    const lockedAccounts =
      JSON.parse(localStorage.getItem("lockedAccounts")) || {};

    if (lockedAccounts[username.value]) {
      const unlockTime = parseInt(lockedAccounts[username.value]);

      if (Date.now() < unlockTime) {
        setAccountLocked(true);

        setTimeout(() => {
          setAccountLocked(false);
          removeLockedAccount(username.value);
        }, unlockTime - Date.now());
      } else {
        setAccountLocked(false);
        removeLockedAccount(username.value);
      }
    }
  };

  const addLockedAccount = (username, unlockTime) => {
    const lockedAccounts =
      JSON.parse(localStorage.getItem("lockedAccounts")) || {};
    lockedAccounts[username] = unlockTime;
    localStorage.setItem("lockedAccounts", JSON.stringify(lockedAccounts));
  };

  const removeLockedAccount = (username) => {
    const lockedAccounts =
      JSON.parse(localStorage.getItem("lockedAccounts")) || {};
    delete lockedAccounts[username];
    localStorage.setItem("lockedAccounts", JSON.stringify(lockedAccounts));
  };

  // Check if the account is locked on page load
  useEffect(() => {
    checkAccountLock();
  }, [username.value]);

  const handleForgotPassword = () => {
    setLoginVisible(false);
    setForgotPasswordVisible(true);
  };

  const handleForgotPasswordGoBack = () => {
    setLoginVisible(true);
    setForgotPasswordVisible(false);
  };

  // handle button click of login form
  const handleLogin = () => {
    if (username.value === "") {
      Swal.fire({
        icon: "info",
        title: "PLEASE ENTER USER NAME",
        showConfirmButton: false,
        timer: 1500,
      });
      return false;
    }
    if (password.value === "") {
      Swal.fire({
        icon: "info",
        title: "PLEASE ENTER PASSWORD",
        showConfirmButton: false,
        timer: 1500,
      });
      return false;
    }

    if (username.value === "Manish") {
      Swal.fire({
        icon: "error",
        title: "Account Suspended",
        text: "The account is suspended. Please contact the administrator.",
      });
      return;
    }

    setError(null);

    if (accountLocked) {
      Swal.fire({
        icon: "info",
        title: "Account locked. Please try again later.",
        showConfirmButton: false,
        timer: 1500,
      });
      window.location.reload();
      return;
    }

    setLoading(true);

    var raw = JSON.stringify({
      db: factory.value,
      user: username.value,
      password: password.value,
    });
    const config = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: raw,
    };
    try {
      fetch(Configuration.SYSTEM_SETTINGS.API_URL + "login", config)
        .then((response) => response.json())
        .then((data) => {
          if (data.result) {
            const remainingAttempts = 4 - loginAttempts;
            Swal.fire({
              icon: "info",
              title: `Wrong password. You have ${remainingAttempts} attempts remaining.`,
              showConfirmButton: false,
              timer: 1000,
            });
            setLoading(false);
            setLoginAttempts((prevAttempts) => prevAttempts + 1);
            checkAccountLock();
            if (loginAttempts === 4) {
              // Lock the account
              const unlockTime = Date.now() + 2 * 60 * 1000; // 30 minutes
              setAccountLocked(true);
              addLockedAccount(username.value, unlockTime);
              localStorage.setItem(`${username.value}_accountLocked`, "true");
              localStorage.setItem(
                `${username.value}_unlockTime`,
                unlockTime.toString()
              );
              setTimeout(() => {
                // Unlock the account after 30 minutes
                setAccountLocked(false);
                setLoginAttempts(0);
                setAccountLocked(false);
                removeLockedAccount(username.value);
                localStorage.removeItem(`${username.value}_accountLocked`);
                localStorage.removeItem(`${username.value}_unlockTime`);
              }, unlockTime - Date.now());
            }
            return;
          }
          setLoginAttempts(0); // Reset login attempts
          setUserSession("Token", data.token, 18000000);
          setUserSession("UserId", data.userid, 18000000);
          setUserSession("UserName", username.value, 18000000);
          setUserSession("Factory", factory.value, 18000000);
          setUserSession(
            "Authorization",
            CryptoService.encrypt({ isAdmin: data.is_admin }),
            18000000
          );

          const passwordExpirationDays = 30; // Set password expiration days
          const passwordExpirationDate = new Date();
          passwordExpirationDate.setDate(
            passwordExpirationDate.getDate() + passwordExpirationDays
          );
          const remainingDays = Math.ceil(
            (passwordExpirationDate - new Date()) / (1000 * 60 * 60 * 24)
          );
          if (remainingDays <= 0) {
            Swal.fire({
              title: "Password Expired",
              text: "Your password has expired. Please contact the administration.",
              icon: "warning",
              confirmButtonText: "OK",
            });
          } else if (remainingDays <= 7) {
            Swal.fire({
              title: "Password Expiration",
              html: `Your password will expire in ${passwordExpirationDays} day(s).`,
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Change Password Now",
              cancelButtonText: "I'll Do It Later",
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                // Redirect to password change page
                props.history.push("/change-password");
              } else {
                // Continue to dashboard
                props.history.push("/dashboard");
              }
            });
          } else {
            // Redirect to dashboard
            props.history.push("/dashboard");
          }
        })
        .catch((error) => {
          setLoading(false);
          // if (error.response.status) setError(error.response.data.message);
          // else setError("Something went wrong. Please try again later.");
        });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={6} className={classes.image} />
      <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          {loginVisible && (
            <form className={classes.form}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                {...username}
                id="usremail"
                placeholder="USERNAME"
                autoComplete="off"
                name="USERNAME"
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                {...password}
                name="PASSWORD"
                placeholder="PASSWORD"
                type={showPassword ? "text" : "password"}
                id="usrpassword"
                autoComplete="current-password"
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleLogin();
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <div style={{ fontSize: "15px" }}>
                <p>
                  <a href="#" onClick={handleForgotPassword}>
                    Forgot Password
                  </a>
                </p>
              </div>
              <Button
                fullWidth
                variant="contained"
                onClick={handleLogin}
                color="primary"
                className={classes.submit}
              >
                Sign In
              </Button>
              <Box mt={5}>
                <Copyright />
              </Box>
              <div
                className="row pull-down"
                style={{ width: "100%", marginLeft: "-32px", float: "right" }}
              >
                <Button onClick={DisclaimerBlock} variant="body2">
                  {"Disclaimer"}
                </Button>
                <Button onClick={ReturnBlock} variant="body2">
                  {"Return & Refund"}
                </Button>
                <Button onClick={TermsConditionBlock} variant="body2">
                  {"Terms and Condition"}
                </Button>
                <Button onClick={PrivacyBlock} variant="body2">
                  {"Privacy & Policy"}
                </Button>
                <Button onClick={AboutusBlock} variant="body2">
                  {"About Us"}
                </Button>
                <Button
                  onClick={ContactUsBlock}
                  style={{ paddingLeft: "inherit" }}
                  variant="body2"
                >
                  {"Contact Us"}
                </Button>
              </div>
            </form>
          )}
          {forgotPasswordVisible && (
            <ForgotPassword onGoBack={handleForgotPasswordGoBack} />
          )}
        </div>
      </Grid>
    </Grid>
  );
}

const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);
  const handleChange = (e) => {
    setValue(e.target.value);
  };
  return {
    value,
    onChange: handleChange,
  };
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(dist/img/backgound.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="/">
        blumangoes
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default Login;
