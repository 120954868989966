import React, { Component } from 'react';
import { Configuration, exceltodb, MandatoryFormFields, getDataTable, json2xlsx } from '../Configuration';
import AntD from '../Actions/Controls/Table/AntDTable'
import { Modal, Spin } from 'antd';
import '../ORDER/OrderMaster/style.css'
import "antd/dist/antd.css";
import AutoCompleteInput from '../Actions/Controls/AutoComplete/autoComplete';
import AutoCompleteCascad from '../Actions/Controls/AutoComplete/autoComplete.cascad';
import Swal, { swal } from 'sweetalert2/dist/sweetalert2.js'

const dataBase = "IMAGEDB";
let files = ''
var tb = ''
export default class frmrelational extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginId: 0,
            visible: false,
            relationdetails: [],
            file: null,
            loading: false,
        };
    }
    async componentDidMount() {
        let rs = await getDataTable("select orgid from users where userid = " + Configuration.USER_ID)
        await this.setState({
            loginId: Configuration.USER_ID,
            orgid: rs[0].orgid
        });
        await this.getdata();
    }

    myChangeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    onAfterSelect(selectedOptions, id, name) {
        if (selectedOptions.length > 0) {
            this.setState({
                [id]: selectedOptions[0].DisplayId,
                [name]: selectedOptions[0].DisplayName
            })
        }
        else {
            this.setState({
                [id]: '',
                [name]: ''
            })
        }
        this.forceUpdate();
    }

    // hide show div
    hideShowDiv(id, childId) {
        let div = document.getElementById(id);
        if (div !== null) {
            if (document.getElementById(id).style.display == "block") {
                document.getElementById(id).style.display = "none"
                let ele = document.getElementById(childId);
                ele.className = "fa fa-plus";
            }
            else {
                document.getElementById(id).style.display = "block"
                let ele = document.getElementById(childId);
                ele.className = "fa fa-minus";
            }
        }
    }

    // Create an object of formData 
    myFileChangeHandler = (event) => {
        files = event.target.files[0];
    }

    async getdata(e, z) {
        this.setState({ loading: true })
        let Que = "EXEC ShowRelationships"
        if (z) {
            Que = "EXEC ShowRelationships @rid = " + z
        }
        let rs = await getDataTable(Que)
        if (rs) {
            this.setState({
                relationdata: rs, loading: false
            })
        }
        else {
            this.setState({ loading: false })
            Swal.fire({
                position: 'top-end',
                icon: 'info',
                title: 'No Data Found',
                showConfirmButton: false,
                timer: 1500
            })
            return;
        }
    }

    async deleteRow(e) {
        if (this.state.loginId == 1) {
            let rst = window.confirm('Are you sure you want to delete?')
            if (!rst) {
                return false;
            }
            let rid = e["#RID"]
            this.setState({ loading: true })
            let Que = "EXEC DeleteRelation @rid=" + rid
            let rs = await getDataTable(Que)
            if (rs) {
                this.setState({ loading: false })
                Swal.fire({
                    position: 'top-end',
                    icon: 'info',
                    title: 'Relationship Deleted SuccessFully',
                    showConfirmButton: false,
                    timer: 2500
                })
                await this.getdata()
            }
        }
    }

    async downloadRow(e) {

        let que = "EXEC GetRelationLeaves @rid = " + e["#RID"]
        let rs = await getDataTable(que)

        if (rs.length > 0) {
            this.setState({ loading: true })
            await json2xlsx(rs, e["RelationshipName"])
            this.setState({ loading: false })
        }
        else {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Relationship cannot be exported.',
                showConfirmButton: false,
                timer: 1500
            })
        }
    }

    // Save New Relation
    async SaveRelation() {
        let MandatoryArray = [
            { "Block": this.state.bkid },
            { "Age Group": this.state.ageid },
            { "Product Type": this.state.ptid },
            { "Category": this.state.catid }
        ]
        let check = MandatoryFormFields(MandatoryArray);
        if (check == false) {
            return false;
        }

        let que = "EXEC CheckRelation @bkid = " + this.state.bkid + ", @ageid = " + this.state.ageid + ", @ptid = " + this.state.ptid + ", @cid = " + this.state.catid
        let rs = await getDataTable(que)
        let e = ''
        if (rs.length === 0) {
            let que1 = "INSERT INTO RelationshipMaster (RelationshipName, BlockID, Dis, loginID, logDate) output inserted.RelationshipID VALUES ('" + this.state.agename + "-" + this.state.ptname + "-" + this.state.catname + "', " + this.state.bkid + ", 0, " + this.state.loginId + ", CAST(GETDATE() as date))"
            let rs1 = await getDataTable(que1)
            if (rs1.length > 0) {
                let que2 = "INSERT INTO RelationshipController (RID, ControllerAttributeID, ControllerValueID, loginID, logDate) output inserted.RID VALUES  ( " + rs1[0].RelationshipID + ", 226, " + this.state.ageid + ", " + this.state.loginId + ", CAST(GETDATE() as date)), ( " + rs1[0].RelationshipID + ", 81, " + this.state.ptid + ", " + this.state.loginId + ", CAST(GETDATE() as date)), ( " + rs1[0].RelationshipID + ", 38, " + this.state.catid + ", " + this.state.loginId + ", CAST(GETDATE() as date))"
                let rs2 = await getDataTable(que2)
                if (rs2) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Relation successfully saved.',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
                else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: 'Relation could not be saved.',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            }
        }
        else {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Relationship already exists.',
                showConfirmButton: false,
                timer: 1500
            })
            await this.getdata(e, rs[0].RelationshipID)
        }
    }

    async UploadFile() {

        let MandatoryArray = [
            { "Block": this.state.bkid1 },
            { "Relationship": this.state.rid }
        ]
        let check = MandatoryFormFields(MandatoryArray);
        if (check == false) {
            return false;
        }
        tb = ''
        if (files) {
            this.setState({ loading: true })
            tb = await exceltodb(files, dataBase, this.state.loginId)
            if (tb) {
                let Que = "EXEC RelationLeavesVerticalTable @tblname = '" + tb + "' , @blockid=" + this.state.bkid1
                let rs = await getDataTable(Que)

                if (Array.isArray(rs)) {
                    if (rs.length > 0) {
                        this.setState({ relationdetails: rs, visible: true, loading: false })
                    }

                    else {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: 'File not Imported Properly',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        this.setState({ loading: false })
                    }
                }
                else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: 'File not Imported Properly',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    this.setState({ loading: false })
                }
                document.getElementById('tp1').value = ''
            }
            else {
                tb = ''
                this.setState({ loading: false })
                return;
            }
        }
        else {
            Swal.fire({
                position: 'top-end',
                icon: 'info',
                title: 'File does not contain Proper Data',
                showConfirmButton: false,
                timer: 1500
            })
            tb = '';
            this.setState({ loading: false })
            return false;
        }
    }

    async SaveData() {
        if (!tb) {
            Swal.fire({
                position: 'top-end',
                icon: 'info',
                title: 'File does not contain proper data',
                showConfirmButton: false,
                timer: 1500
            })
        }
        let s = false
        for (let i = 0; i < this.state.relationdetails.length; i++) {
            if (this.state.relationdetails[i].Allowed !== "True") {
                s = true
            }
        }

        if (s == false) {
            let que = "DELETE FROM RelationshipLeaves OUTPUT DELETED.[RID] WHERE RID = " + this.state.rid
            await getDataTable(que)

            let que1 = "INSERT INTO RelationshipLeaves (RID, AttributeID, ValueID, logID, logDate) output inserted.* SELECT " + this.state.rid + ", #AID, #VID, " + this.state.loginId + ", CAST(GETDATE() as DATE) FROM " + tb
            let rs = await getDataTable(que1)

            if (rs) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Relation saved successfully',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
            else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Relation could not be saved',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }
        else {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Please correct the entered data.',
                showConfirmButton: false,
                timer: 1500
            })
        }

        let que = "DROP TABLE " + tb
        await getDataTable(que)
    }

    render() {
        return (
            <div className="content-wrapper">
                <div className='loader' style={{ display: this.state.loading ? "block" : "none" }}>
                    <div className='loader-item'>
                        <Spin />
                    </div>
                </div>
                <section className="content">
                    <div id="ctl00_CPHMaincontent_divclient" className="box box-success" style={{ marginTop: '-13px' }}>
                        <div className="box-header with-border">
                            <h3 className="box-title">Relationship Controller
                                <span id="ctl00_CPHMaincontent_DivOrdNo" style={{ display: 'inline' }}>
                                </span>
                            </h3>
                            <div className="box-tools pull-right" style={{ marginTop: '5px' }}>
                                &nbsp;&nbsp;&nbsp; &nbsp;
                                <i className="fa fa-plus" id="I1" onClick={this.hideShowDiv.bind(this, 'report', 'I1')} />
                                &nbsp; &nbsp;
                            </div>
                        </div>
                        <div className="box-body" id="report" style={{ display: 'none' }}>
                            <div className='row'>
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                    <label>Select Block</label>
                                    <AutoCompleteCascad
                                        id="bkid"
                                        frmNm="FRMALLDATA"
                                        quryNm="FILLORGBLOCKS"
                                        db="IMAGEDB"
                                        filter1="orgid"
                                        filterId1={this.state.orgid}
                                        filter2=""
                                        filterId2=""
                                        placeholder="Please Select Block"
                                        onAfterSelect={(e) => this.onAfterSelect(e, "bkid", "bkname")}
                                        isValid={this.state.isValid}
                                    ></AutoCompleteCascad>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                    <label>Select Age Group</label>
                                    <AutoCompleteCascad
                                        id="aid"
                                        frmNm="FRMVALUEMAPPING"
                                        quryNm="FILLVALUES"
                                        db="IMAGEDB"
                                        filter2=""
                                        filterId2=""
                                        filter1="AtrributeID"
                                        filterId1='226'
                                        placeholder="Please Select Age Group"
                                        onAfterSelect={(e) => this.onAfterSelect(e, "ageid", "agename")}
                                        isValid={this.state.isValid}
                                    ></AutoCompleteCascad>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                    <label>Select Product Type</label>
                                    <AutoCompleteCascad
                                        id="ptid"
                                        frmNm="FRMVALUEMAPPING"
                                        quryNm="FILLVALUES"
                                        db="IMAGEDB"
                                        filter2=""
                                        filterId2=""
                                        filter1="AtrributeID"
                                        filterId1='81'
                                        placeholder="Please Select Product Type"
                                        onAfterSelect={(e) => this.onAfterSelect(e, "ptid", "ptname")}
                                        isValid={this.state.isValid}
                                    ></AutoCompleteCascad>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                    <label>Select Category</label>
                                    <AutoCompleteCascad
                                        id="catid"
                                        frmNm="FRMVALUEMAPPING"
                                        quryNm="FILLVALUES"
                                        db="IMAGEDB"
                                        filter2=""
                                        filterId2=""
                                        filter1="AtrributeID"
                                        filterId1='38'
                                        placeholder="Please Select Product Type"
                                        onAfterSelect={(e) => this.onAfterSelect(e, "catid", "catname")}
                                        isValid={this.state.isValid}
                                    ></AutoCompleteCascad>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                    <div className='form-group'>
                                        {<a id="refrsh" onClick={this.getdata.bind(this)} style={{ color: 'red' }}><span class="fa fa-refresh">Refresh</span></a>}
                                    </div>
                                    <div className="form-group">
                                        <input type='button' value='Save Relation' onClick={this.SaveRelation.bind(this)} className='btn btn-primary' />
                                    </div>

                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12 margintop">
                                    {this.state.relationdata != undefined && this.state.relationdata.length > 0 &&
                                        <AntD
                                            data={[...this.state.relationdata]}
                                            Delete="true"
                                            DeleteFn={(e) => this.deleteRow(e)}
                                            downloadRow="true"
                                            downloadRowFn={(e) => this.downloadRow(e)}
                                            exportXL="true">
                                        </AntD>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="ctl00_CPHMaincontent_div" className="box box-success" style={{ marginTop: '-13px' }}>
                        <div className="box-header with-border">
                            <h3 className="box-title">Create/Update Relationship
                                <span id="ctl00_CPHMaincontent_DivOrdNo" style={{ display: 'inline' }}>
                                </span>
                            </h3>
                            <div className="box-tools pull-right" style={{ marginTop: '5px' }}>
                                &nbsp;&nbsp;&nbsp; &nbsp;
                                <i className="fa fa-minus" id="I2" onClick={this.hideShowDiv.bind(this, 'relationDetails', 'I2')} />
                                &nbsp; &nbsp;
                            </div>
                        </div>
                        <div className="box-body" id="relationDetails" style={{ display: 'block' }}>
                            <div className="row">
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                    <label>Select Block</label>
                                    <AutoCompleteCascad
                                        id="bkid1"
                                        frmNm="FRMALLDATA"
                                        quryNm="FILLORGBLOCKS"
                                        db="IMAGEDB"
                                        filter1="orgid"
                                        filterId1={this.state.orgid}
                                        filter2=""
                                        filterId2=""
                                        placeholder="Please Select Block"
                                        onAfterSelect={(e) => this.onAfterSelect(e, "bkid1", "bkname1")}
                                        isValid={this.state.isValid}
                                    ></AutoCompleteCascad>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                    <label>Select Relationship</label>
                                    <AutoCompleteCascad
                                        id="rid"
                                        frmNm="FRMRELATIONSHIP"
                                        quryNm="FILLRELATION"
                                        db="IMAGEDB"
                                        filter2=""
                                        filterId2=""
                                        filter1="BlockID"
                                        filterId1={this.state.bkid1}
                                        placeholder="Please Select Relationship"
                                        onAfterSelect={(e) => this.onAfterSelect(e, "rid", "rname")}
                                        isValid={this.state.isValid}
                                    ></AutoCompleteCascad>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                    <label>Upload File</label><span className="vcode">*</span>
                                    <div className="form-group">
                                        <input name="fldname" type='file' accept=".xls,.xlsx" id="tp1" onChange={this.myFileChangeHandler} placeholder="Enter FieldName" tabIndex={1} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-1 margintop">
                                    <label>&nbsp;</label>
                                    <div className="form-group">
                                        <input type='button' value='Upload File' onClick={this.UploadFile.bind(this)} className='btn btn-block btn-primary' />
                                    </div>
                                </div>
                                <div className="col-xs-4 col-sm-4 col-md-1 margintop">
                                    <label>&nbsp;</label>
                                    <div className="form-group">
                                        {this.state.visible && <input type='button' value='Save Data' onClick={this.SaveData.bind(this)} className='btn btn-block btn-success' />}
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12 margintop">
                                    {this.state.relationdetails.length > 0 && //change it
                                        <AntD
                                            data={this.state.relationdetails}
                                            exportXL="true"
                                        ></AntD>
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
