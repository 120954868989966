import React, { Component } from 'react';
import AutoCompleteInput from '../Actions/Controls/AutoComplete/autoComplete';
import AutoCompleteCascad from '../Actions/Controls/AutoComplete/autoComplete.cascad'
import { Configuration, MandatoryFormFields, getDataTable } from '../Configuration';
import '../ORDER/OrderMaster/style.css'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import { Spin } from 'antd'
import "antd/dist/antd.css";
let rightsIDs = [1,10,"1","1O"]
export default class frmReportADhocRight extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginId: 0,
            USERID: 0,
            RWID: 0,
            ReportList: [],
            loading: false
        };
    }
    async componentDidMount() {
        let rs = await getDataTable("select orgid from users where userid = " + Configuration.USER_ID)
        await this.setState({
        loginId: Configuration.USER_ID,
        orgid: rs[0].orgid
        });
    }
    async getdata() {
        let MandatoryArray = [
            { "User": this.state.USERID }
        ]
        let check = MandatoryFormFields(MandatoryArray);
        if (check == false) {
            return false;
        }
        this.setState({loading:true})
        let que = "SELECT RWID, ReportName,ISNULL((select 'YES' from ReportUserRights where RWID = ReportAdhoc.RWID  and USERID = " + this.state.USERID + "),'NO') as Status FROM ReportAdhoc where forUser = 1 and Dis = 0 order by RWID"
        let dt = await getDataTable(que)
        this.setState({
            ReportList: dt,
            loading:false
        })
    }
    onAfterSelect(selectedOptions, id, name) {
        if (selectedOptions.length > 0) {
            this.setState({
                [id]: selectedOptions[0].DisplayId,
                [name]: selectedOptions[0].DisplayName,
                ReportList: []
            })
        }
        else {
            this.setState({
                [id]: '',
                [name]: '',
                ReportList: []
            })
        }
    }
    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({ [nam]: val });
        this.forceUpdate();
    }
    renderFileInTable() {
        debugger
        return (
            <div>
                <label>Master Images</label>
                <div className="table-responsive" style={{ maxHeight: '63vh' }}>
                    <table id="rptTable" className="table table-hover">
                        <thead style={{ position: "sticky" }}>
                            <tr> <th>SR NO.</th>
                                <th>Report Name</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.ReportList.map((value, index) => {
                                return <tr key={index}>
                                    <td>{index+1}&nbsp;<input type='checkbox' id={value.RWID} value={value.RWID} defaultChecked={value.Status === 'YES'} /></td>
                                    <td>{value.ReportName}</td>
                                    <td>{value.Status}</td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
    async getUserID() {
        let st = []
        var tbl = document.getElementById('rptTable');
        var inputs = tbl.getElementsByTagName('input')
        debugger
        for (let i = 0; i < inputs.length; i++) {
            if (inputs[i].checked) {
                st.push(inputs[i].value)
            }
        }
        return st;
    }
    async SaveRights() {
        if (rightsIDs.includes(parseInt(this.state.loginId))) {
        }
        else {
            alert("YOU ARE NOT ALLOWED TO MAKES CHANGES")
            return false;
        }
        let dt = []
        debugger
        var rptID = await this.getUserID();
        let MandatoryArray = [
            { "User": this.state.USERID }
            , { "Report": rptID.length }
        ]
        let check = MandatoryFormFields(MandatoryArray);
        if (check == false) {
            return false;
        }
        this.setState({loading:true})
        let Quer = "delete from ReportUserRights where USERID = " + this.state.USERID
        let res = await getDataTable(Quer)
        
        for (let i = 0; i < rptID.length; i++) {
            let Que = "INSERT INTO ReportUserRights (RWID, USERID, LOGINID, LOGDATE) VALUES (" + rptID[i] + "," + this.state.USERID + "," + this.state.loginId + ",getdate())"
            let resp = await getDataTable(Que)
        }
        this.setState({loading:false})
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: "SuccessFully Updated",
            showConfirmButton: false,
            timer: 1500
        })
        await this.getdata();
    }
    // Create an object of formData 
    myChangeHandlernew = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({ [nam]: val });
        this.forceUpdate();
    }
    render() {
        return (
            <div className="content-wrapper">
                <div
                    className="loader"
                    style={{display: this.state.loading ? "block" : "none"}}
                >
                    <div className="loader-item">
                        <Spin size="large" />
                    </div>
                </div>
                <section className="content">
                    <div id="ctl00_CPHMaincontent_divclient" className="box box-success" style={{ marginTop: "-12px" }}>
                        <div className="box-header with-border">
                            <h3 className="box-title">Report User Rights
               <span id="ctl00_CPHMaincontent_DivOrdNo" style={{ display: 'inline' }}>
                                </span>
                            </h3>
                        </div>
                        <div className="box-body" id="orderDetails" style={{ display: 'block' }}>
                            <div className="row">
                            <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                    <label>Select Organization</label><span className="vcode">*</span>
                                    <AutoCompleteInput
                                        id="articleid"
                                        frmNm="FRMUSER"
                                        quryNm="FILLORGANIZATION"
                                        db="IMAGEDB"
                                        placeholder="Please Select Organization"
                                        onAfterSelect={(e) => this.onAfterSelect(e, "DEPTID", "DEPTNAME")}
                                        isValid={this.state.isValid}
                                    ></AutoCompleteInput>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                    <label>Select User</label><span className='vcode'>*</span>
                                    <AutoCompleteCascad
                                        id="userid"
                                        frmNm="FRMUSERRIGHT"
                                        quryNm="FILLUSER"
                                        db="IMAGEDB"
                                        filter1="orgid"
                                        filterId1={this.state.DEPTID}
                                        filter2=""
                                        filterId2=""
                                        placeholder="Please Select User"
                                        onAfterSelect={(e) => this.onAfterSelect(e, "USERID", "USERNAME")}
                                    ></AutoCompleteCascad>
                                </div>
                                {/* <div className="col-xs-6 col-sm-5 col-md-2 margintop">
                                    <label>Select Department</label><span className="vcode">*</span>
                                    <AutoCompleteInput
                                        id="deptid"
                                        frmNm="FRMUSERRIGHTS"
                                        quryNm="FILLDEPARTMENT"
                                        placeholder="Please Select DepartMent"
                                        onAfterSelect={(e) => this.onAfterSelect(e, "DEPTID", "DEPTNAME")}
                                        isValid={this.state.isValid}
                                    ></AutoCompleteInput>
                                </div>
                                <div className="col-xs-6 col-sm-5 col-md-2 margintop">
                                    <label>Select User</label>
                                    <AutoCompleteCascad
                                        id="userid"
                                        frmNm="FRMUSERRIGHTS"
                                        quryNm="FILLUSER"
                                        filter1="DEPTID"
                                        filterId1={this.state.DEPTID}
                                        filter2=""
                                        filterId2=""
                                        placeholder="Please Select Shop"
                                        onAfterSelect={(e) => this.onAfterSelect(e, "USERID", "USERNAME")}
                                    ></AutoCompleteCascad>
                                </div> */}
                                <div className='col-xs-6 col-sm-2 col-md-2 margintop'>
                                    <label>&nbsp;</label>
                                    <div className='form-group'>
                                        <button className='btn btn-block btn-primary' onClick={this.getdata.bind(this)}>Show Reports</button>
                                    </div>
                                </div>
                                <div className='col-xs-6 col-sm-2 col-md-2 margintop'>
                                    <label>&nbsp;</label>
                                    <div className='form-group'>
                                        {this.state.ReportList.length > 0 && <button className='btn btn-block btn-success' onClick={this.SaveRights.bind(this)}>Save Reports</button>}
                                    </div>
                                </div>
                                {/* <div className="col-xs-6 col-sm-5 col-md-3 margintop">
                                    <label>Select Report</label>
                                    <AutoCompleteInput
                                        id="rptid"
                                        frmNm="FRMUSERRIGHTS"
                                        quryNm="FILLREPORTS"
                                        db={dataBase}
                                        placeholder="Please Select REPORT"
                                        onAfterSelect={(e) => this.onAfterSelect(e, "RWID", "RPTNAME")}
                                        isValid={this.state.isValid}
                                    ></AutoCompleteInput>
                                </div>
                                </div> */}
                                {this.state.ReportList.length > 0 && this.state.ReportList != undefined &&
                                    <div className="col-xs-6 col-sm-6 col-md-3 margintop pull-right">
                                        <label>&nbsp;</label>
                                        <div className="form-group">
                                            <input name="rptname" id="rptname" onKeyUp={this.myFunction.bind(this)} placeholder="Search Report" tabIndex={1} className="form-control" />
                                        </div>
                                    </div>}
                            </div>
                            <div className='row'>
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    {this.state.ReportList.length > 0 && this.renderFileInTable()}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
    myFunction(e) {
        var input, filter, table, tr, td, i, txtValue;
        input = document.getElementById('myInput');
        filter = e.target.value.toUpperCase();
        table = document.getElementById('rptTable');
        tr = table.getElementsByTagName("tr");
        for (i = 1; i < tr.length; i++) {
            let tds = tr[i].getElementsByTagName("td");
            var matches = false;
            for (let j = 0; j < tds.length; j++) {
                if (tds[j]) {
                    txtValue = tds[j].textContent || tds[j].innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        matches = true;
                    }
                }
            }
            if (matches == true) {
                tr[i].style.display = "";
            }
            else {
                tr[i].style.display = "none";
            }
        }
    }
}
