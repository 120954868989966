import React,{Component,useRef} from 'react';
import {Typeahead} from 'react-bootstrap-typeahead';  
import './style.css';
import jQuery from 'jquery';
import {Configuration} from '../../../Configuration';

const TOKEN = Configuration.USER_TOKEN.TOKEN;

const  API_URL = Configuration.SYSTEM_SETTINGS.API_URL;

//const autoCompleteCascadEl = React.createRef();

const ToggleButton = ({ isOpen, onClick }) => (
  <button
    className="toggle-button"
    onClick={onClick}
    onMouseDown={e => {
      // Prevent input from losing focus.
      e.preventDefault();
    }}>
    {isOpen ? '▲' : '▼'}
  </button>
);

export default  class AutoCompleteCascad extends Component {
  
  constructor(props) {
      super(props);
      this.wrapper = React.createRef();
      this.state= {
        cmbData:[{"DisplayId":1,"DisplayName":"No Data"}],
        mainQuery:"", 
        count:"15"
    };
    
    
  }

  async componentDidMount(){

    if(this.props.filterId1!==""){
     
    //await this.fillData();
   
     
    }
  }
 
async fillData(){
  const config = {
   
    headers: {
      'Content-Type': 'application/json',
      'Auth-Token' :  TOKEN
    }
    }
    if (this.props.filterId1 === "") {
     // this.props.filterId1 = 0;
      return;
    }
  let filter ="";
if(this.props.filter2!==""){
filter = " and "+ this.props.filter1 + "='" + this.props.filterId1 + "' and "+ this.props.filter2 + "='" + this.props.filterId2+ "'"
}

else{
 
filter = " and "+ this.props.filter1 + "='" + this.props.filterId1+ "'"
}

let SearchKey = '';

let qry = "formname="+this.props.frmNm+"&queryname="+this.props.quryNm+"&count="+this.state.count+"&searchtext="+SearchKey.trim()+"&filter="+filter+"&db="+this.props.db+" " ;

const response = await fetch(API_URL+'/api/v1/Common/GetFillComboQuery?'+qry+'',config);
const data = await response.json();

this.setState({
cmbData: data.data
})

}


onInputChangeHandler(evt){ 
 
  let filter ="";
  
  if(this.props.filter2!==""){
    filter = " and "+ this.props.filter1 + "='" + this.props.filterId1 + "' and "+ this.props.filter2 + "='" + this.props.filterId2+ "'"
  }
  else{
    filter = " and "+ this.props.filter1 + "='" + this.props.filterId1+ "'"
   
  }

  let SearchKey = evt;

  

  let qry = "formname="+this.props.frmNm+"&queryname="+this.props.quryNm+"&count="+this.state.count+"&searchtext="+SearchKey.trim()+"&filter="+filter+"&db="+this.props.db+" " ;
 
  console.log(qry);
  const config = {
   
    headers: {
      'Content-Type': 'application/json',
      'Auth-Token' :  TOKEN
    }
    }
  //this.state.query+" where " + this.props.filterBy +" like '"+SearchKey+"%'";
  fetch(API_URL+'/api/v1/Common/GetFillComboQuery?'+qry+'',config)
  .then(res => res.json())
  .then((data) => {
    
      this.setState({
        cmbData:data.data
      })
  })
   

 
}

onAfterSelect(selectedOptions){

  if(selectedOptions.length>0){
    //this.props.name[this.state.id]=selectedOptions[0].DisplayId;
  }
}

render() {
  //const ref = useRef();
  const {defauldVal} = this.props
  return (
   <Typeahead  
   clearButton
   id={this.props.id}
   labelKey="DisplayName"
   onFocus={this.fillData.bind(this)}
   onChange={this.props.onAfterSelect}
   multiple
   options={this.state.cmbData}
   placeholder={this.props.placeholder.toUpperCase()}
   onInputChange={this.onInputChangeHandler.bind(this)}
   defaultSelected={defauldVal}
   //selected={defauldVal}
   isValid={true}
   ref={this.wrapper}
   >

{({ isMenuShown, toggleMenu }) => (
      <ToggleButton isOpen={isMenuShown} onClick={e => toggleMenu()} />
    )}

   </Typeahead>
  );
}};