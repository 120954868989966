
import React, { Component } from 'react';
import {connect} from 'react-redux';
import {helloRedux} from '../../../Actions/actions';
import { QRCode } from 'react-qrcode-logo';
var Barcode = require('react-barcode');
class UserdDetails extends Component {
  
  render() {
    console.log(this.props);
    return (
      <div>
        <input type="button" onClick={()=> this.props.helloRedux() } value="Test Redux"/>
        <QRCode value="https://www.albertotorresi.com/collections/new-arrivals" logoImage="dist/img/download.png" />
        <Barcode value="123456789101112" format ="CODE128" />
      </div>
    );
  }
} 

const mapDispatchToProps = dispatch => {
  return {
    helloRedux: () => dispatch(helloRedux())
  }
}
const mapStateToProps  = state => {
{
return state 
}
}
const userComp = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserdDetails)

export default userComp;









































// import React, { Component } from 'react';

// import {connect} from 'react-redux'
// import {fetchItem} from '../../../Actions/GET_ACTIONS/ORDER_GET_ACTIONS/GetOrderActions';
// class UserdDetails extends Component {
//   constructor(props) {
//     super(props);
// } 
// componentWillMount()
// {
//  // this.props.fetchItem()
// }

//     render() {
//       console.log(this.props);
//         var {fetchItem, dataItem} = this.props
//         const colorStyle = {
//           backgroundColor: '#000'
//         };
//         return (
// <div>
//     <h1>hello</h1>
//     </div>
//             );
//         }}

//         function mapStateToProps(state) {

//             const { fetchItem, deleteItem } = state
//            // const { dataItem } = fetchItem
          
//             return {
//              // dataItem 
//             }
//           }
          
//           export default connect(mapStateToProps)(UserdDetails)