import React, { Component } from 'react';
import { Configuration, getDataTable } from '../Configuration';
import AntD from '../Actions/Controls/Table/AntDTable'
import '../ORDER/OrderMaster/style.css'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

export default class frmproductcategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginId: 0,
            categorydata: []
        };
    }
    async componentDidMount() {
        await this.setState({
            loginId: Configuration.USER_ID,
          });
        await this.getdata();
    }
    async getdata() {
        
        let Que = "Select BlockName,isnull((select count(*) from AttributeCategoryMap where AttributeCategoryMap.CatID = CategoryID),0) as Attribute,format(LogDate,'dd-MMM-yyyy') as date from BlockMaster where CatDis = 0"
        let rs = await getDataTable(Que)
        this.setState({
            categorydata: rs
        })
    }
    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({ [nam]: val });
        this.forceUpdate();
    }
    async SavePortal() {
        let Que = "Select * from BlockMaster where BlockName = '" + this.state.fldname + "'"
        let rs = await getDataTable(Que)
        if (rs.length > 0) {
            Swal.fire({
                position: 'top-end',
                icon: 'info',
                title: 'Already Exist',
                showConfirmButton: false,
                timer: 1500
            })
            return;
        }
        let Query = "INSERT INTO  BlockMaster( BlockName, LoginID, Logdate,CatDis) output inserted.BlockName VALUES       ('" + this.state.fldname + "'," + this.state.loginId + ",cast(getdate() as date),0)"
        let response = await getDataTable(Query)
        if (response.length > 0) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Successfully Saved',
                showConfirmButton: false,
                timer: 1500
            })
        }
        else {
            Swal.fire({
                position: 'top-end',
                icon: 'warning',
                title: 'Portal Not Saved',
                showConfirmButton: false,
                timer: 1500
            })
        }
        // console.log(OrderL = dataist);
        await this.getdata(this);
        this.forceUpdate();
    }
    render() {
        return (
            <div className="content-wrapper">
                <section className="content">
                    <div id="ctl00_CPHMaincontent_divclient" className="box box-success" style={{ marginTop: '-13px' }}>
                        <div className="box-header with-border">
                            <h3 className="box-title">BLOCK MASTER
               <span id="ctl00_CPHMaincontent_DivOrdNo" style={{ display: 'inline' }}>
                                </span>
                            </h3> 
                        </div>
                        <div className="box-body" id="orderDetails" style={{ display: 'block' }}>
                            <div className="row">
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                    <label>Add New Block</label><span className="vcode">*</span>
                                    <div className="form-group">
                                        <input name="fldname" id="tp1" onChange={this.myChangeHandler} placeholder="Enter Block Name" tabIndex={1} autocomplete="off" className="form-control" />
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                    <label>&nbsp;</label>
                                    <div className="form-group">
                                        <input type='button' value='Save Block' onClick={this.SavePortal.bind(this)} className='btn btn-primary' />
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12 margintop">
                                    {this.state.categorydata.length > 0 &&
                                        <AntD
                                            data={[...this.state.categorydata]}
                                            Delete="true"
                                            DeleteFn={(e) => this.deleteRow(e)}
                                            id='fielddata'
                                            exportXL="true">
                                        </AntD>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
    async deleteRow(e) {
        if (this.state.loginId  === "1" || this.state.loginId  === "10") {
            let st = window.confirm("Are you sure want to delete ?")
            if (!st) {
                return false;
            }
            console.log(e);
            let Que = "Update BlockMaster set CatDis = 1 output inserted.CategoryID where CategoryName = '"+e.CategoryName+"'"
            let rs = await getDataTable(Que)
            if (rs.length > 0) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'info',
                    title: 'Successfully Deleted',
                    showConfirmButton: false,
                    timer: 1500
                })
                await this.getdata()
            }
            else{
                alert("Some Error Occurred")
            }
        }
        else{
            alert("You are not allowed to delete")
            return;
        }
    }
}
