import React, { Component } from 'react';
import { connect } from 'react-redux';
import CompanyLogo from '../../assets/bluMangoes.png';
import { APIService } from '../../../Config/action/apiAction';

class PrintInvoice extends Component {

    constructor(props){
        super(props);
        this.state = {
            data: null,
            billingInfo: null
        }
    }

    componentDidMount(){
        let orderId = this.props.match.params.id;
        this.props.dispatch(APIService('POST', `GetPaymentData?query= TRANSACTION_DETAILS @ORDERID = ${orderId}`, '')).then((data) => {
            console.log(data);
            if(data.data.status === 1000){
                this.setState({
                    data: data.data.data[0]
                }, () => {
                    if(this.state.data.Customer_name){
                        this.setState({
                            ...this.state,
                            billingInfo: {
                                name: this.state.data.Customer_name || '',
                                email: this.state.data.Customer_mail || '',
                                organisation: this.state.data.Customer_org || '',
                                GSTNO: this.state.data.GSTNO || '',
                                ADDRESS: this.state.data.ADDRESS || '',
                                PIN: this.state.data.PIN || '',
                                PHONE: this.state.data.PHONE || ''
                            }
                        }, () => {
                            window.print();
                        })
                    }
                    else{
                        this.setState({
                            ...this.state,
                            billingInfo: {
                                name: this.state.data.Payee_name || '',
                                email: this.state.data.Payee_mail || '',
                                organisation: this.state.data.Payee_org || '',
                                GSTNO: this.state.data.GSTNO || '',
                                ADDRESS: this.state.data.ADDRESS || '',
                                PIN: this.state.data.PIN || '',
                                PHONE: this.state.data.PHONE || ''
                            }
                        }, () => {
                            window.print();
                        })
                    }
                })
            }
        }).catch((e) => {
            console.log(e);
        })
    }

    formatDate = (date) => {
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
      
      var d = new Date(date);
      return (monthNames[d.getMonth()] + ' ' + d.getDate() + ', ' + d.getFullYear())
    }

    render(){
        return (
            <div className="invoice-box">
                <table cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr className="top">
                            <td colSpan="2">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className="title">
                                                <img src={CompanyLogo} alt='none' style={{width:"100%", maxWidth:"110px"}} />
                                            </td>
                                            
                                            <td>
                                                {this.state.data ? this.state.data.RCTNO : ''}<br />
                                                {this.state.data ? this.formatDate(this.state.data.TXNDATE) : ''}<br />
                                                {/* Due: February 1, 2015 */}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        
                        <tr className="information">
                            <td colSpan="2">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                {/* BluMangoes,<br /> */}
                                                BluMangoes ventures Pvt. Ltd.<br />
                                                404, Kaveri Greens, Kailash Vihar,<br />
                                                Agra-282 007 (U.P), India
                                            </td>
                                            
                                            <td>
                                                {this.state.billingInfo ? this.state.billingInfo.organisation : ''}<br />
                                                {this.state.billingInfo ? this.state.billingInfo.PHONE : ''}<br />
                                                {this.state.billingInfo ? this.state.billingInfo.email : ''}<br />
                                                {this.state.billingInfo ? this.state.billingInfo.ADDRESS : ''}<br />
                                                {this.state.billingInfo ? this.state.billingInfo.PIN : ''}<br />
                                                {this.state.billingInfo ? this.state.billingInfo.GSTNO : ''}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>

                        <tr className="heading">
                            <td>
                                Payment Method
                            </td>
                            
                            <td>
                                <span className="txnID">
                                    Transaction ID
                                </span>
                            </td>
                        </tr>

                        <tr className="details" style={{fontSize:'14px'}}>
                            <td>
                                {this.state.data ? this.state.data.BANKNAME : ''}
                            </td>
                            
                            <td>
                                <span className="txnID">
                                    {this.state.data ? this.state.data.TXNID : ''}
                                </span>
                            </td>
                        </tr>
                        
                        <tr className="heading">
                            <td>
                                Payment Status
                            </td>
                            
                            <td>
                                Price
                            </td>
                        </tr>
                        
                        <tr className="item last">
                            <td>
                                {this.state.data ? this.state.data.STATUS === 'TXN_SUCCESS' ? "Success" : "Failure" : ""}
                            </td>
                            
                            <td>
                                &#8377;{this.state.data ? this.state.data.TXNAMOUNT : ''}
                            </td>
                        </tr>
                        
                        <tr className="total">
                            <td></td>
                            
                            <td>
                            Total: &#8377;{this.state.data ? this.state.data.TXNAMOUNT : ''}<br />
                            (Total includes GST 18%)
                            </td>
                        </tr>
                    </tbody>
                    
                </table>
            </div>
        )   
    }
    
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: () => {
            dispatch({
                type: 'loginUser'
            });
        },
        logoutUser: () => {
            dispatch({
                type: 'logoutUser'
            });
        },
        dispatch
    }
}

export default connect(null, mapDispatchToProps)(PrintInvoice);