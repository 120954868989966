import React, { Component } from "react";
import AutoCompletemulti from "../Actions/Controls/AutoComplete/autoCompleteMultiple";
import {
  Configuration,
  getDataTable,
  MandatoryFormFields,
} from "../Configuration";
import AntD from "../Actions/Controls/Table/AntDTable";
import AutoCompleteInput from "../Actions/Controls/AutoComplete/autoComplete";
import AutoCompleteCascad from "../Actions/Controls/AutoComplete/autoComplete.cascad";
import "../ORDER/OrderMaster/style.css";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Modal, Spin } from "antd";
import "sweetalert2/src/sweetalert2.scss";
import AttributeGroup from "./frmAttributeGroup"
export default class frmAttributeMasterWithBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginId: 0,
      fielddata: [],
      catid1: "",
      catid2: [],
      val: "",
      fldname: "",
      options: [],
      groupid1: 0,
      groupname1: '',
      updateData: false,
      updateid: "",
      Pid: [],
      loading: false,
      attributeSaved: false,
    };
  }
  async componentDidMount() {
    this.setState({
      loginId: Configuration.USER_ID,
    });
    await this.getdata();
  }
  async getdata() {
    this.setState({ loading: true });
    let Que = "exec GetAttributeMasterList";
    let rs = await getDataTable(Que);
    this.setState({
      fielddata: rs,
      updateid: "",
      updateData: false,
      fldname: "",
      discription1: "",
      discription2: "",
      discription3: "",
      Pid: [],
      catid2: [],
      loading: false,
    });
  }
  myChangeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  async onAutoCOmpletMultiPleSelect(selectedOptions, name) {
    if (selectedOptions.length > 0) {
      this.setState({
        [name]: selectedOptions
      });
    } else {
      this.setState({
        [name]: ""
      });
    }
  }

  async onAfterSelect(selectedOptions, id, name) {
    if (selectedOptions.length > 0) {
      this.setState({
        [id]: selectedOptions[0].DisplayId,
        [name]: selectedOptions[0].DisplayName,
      });
    } else {
      this.setState({
        [id]: "",
        [name]: "",
      });
    }

    if (id === "AID") {
      let aids =
        selectedOptions.length > 0 ? selectedOptions[0].DisplayId : "0";
      let Que =
        "Select Description1, Description2 from AttributeDescriptionMaster where (AID = " +
        aids +
        " AND BlockID = " +
        this.state.catid1 +
        ")";

      let rs = await getDataTable(Que);

      if (rs.length > 0) {
        this.setState({
          discription1: rs[0].Description1,
          discription2: rs[0].Description2,
        });
      } else {
        this.setState({
          discription1: "",
          discription2: "",
        });
      }
    }
  }

  async SavePortal() {
    this.setState({ loading: true, attributeSaved: false });
    let MandatoryArray = [
      { "Attribute Name": this.state.fldname },
      { "Value Type": this.state.val },
      { "Block": this.state.catid2 },
      { "Group Name": this.state.groupid1 }
    ];
    let check = MandatoryFormFields(MandatoryArray);
    if (check === false) {
      this.setState({ loading: false });
      return false;
    }
    if (this.state.val === "Option" && this.state.options.length <= 0) {
      Swal.fire({
        icon: "error",
        title: "PLEASE ADD VALUES FOR OPTION TYPE",
        showConfirmButton: false,
        timer: 1500,
      });
      this.setState({ loading: false });
      return;
    }


    let Que =
      "Select * from AttributeMaster where AttributeName = '" +
      this.state.fldname +
      "' and dis = 0";
    let rs = await getDataTable(Que);

    if (rs.length > 0) {
      Swal.fire({
        position: "top-end",
        icon: "info",
        title: "Already Exist",
        showConfirmButton: false,
        timer: 1500,
      });
      this.setState({ loading: false });
      return;
    }
    let Query =
      "INSERT INTO  AttributeMaster  (AttributeName, groupid, LoginID, Logdate,Dis,Validation) Output Inserted.AID VALUES ('" +
      this.state.fldname +
      "'," + this.state.groupid1 +
      "," + this.state.loginId +
      ",cast(getdate() as date),0,'" +
      this.state.val +
      "')";

    // "INSERT INTO  AttributeMaster  (AttributeName, LoginID, Logdate,Dis,Validation,Dailogue1,Dailogue2,Dailogue3) Output Inserted.AID VALUES ('" + this.state.fldname + "'," + this.state.loginId + ",cast(getdate() as date),0,'" + this.state.val + "','" + this.state.discription1 + "','" + this.state.discription2 + "','" + this.state.discription3 + "')"
    let response = await getDataTable(Query);
    if (response[0].AID) {
      await getDataTable("delete from AttributeCategoryMap where AttributeID = " + response[0].AID)
      for (let i = 0; i < this.state.catid2.length; i++) {
        let element = this.state.catid2[i];
        let Query1 = "INSERT INTO AttributeCategoryMap (CatID, AttributeID, loginID, logdate) VALUES (" + element['DisplayId'] + "," + response[0].AID + "," + this.state.loginId + ",cast(getdate() as date))"
        await getDataTable(Query1)
      }
      this.setState({ loading: false })
    }

    let res = response;
    if (res[0].AID) {
      if (this.state.options.length > 0 && this.state.val === "option") {
        for (let i = 0; i < this.state.options.length; i++) {
          let que1 =
            "INSERT INTO optionValue (AtrributeID, OptValue, logid, logdate) output inserted.OptValue VALUES (" +
            res[0].AID +
            ",'" +
            this.state.options[i].name +
            "'," +
            this.state.loginId +
            ",cast(getdate() as date))";
          await getDataTable(que1);

        }
      }
      if (this.state.Pid.length > 0) {
        let Que3 =
          "Delete from AttributeParentDetails where AttributeID = " +
          res[0].AID;
        await getDataTable(Que3);
        for (let i = 0; i < this.state.Pid.length; i++) {
          let que2 =
            "INSERT INTO AttributeParentDetails (AttributeID, ParentID, LoginID, LogDate) output inserted.APID VALUES (" +
            res[0].AID +
            "," +
            this.state.Pid[i].DisplayId +
            "," +
            this.state.loginId +
            ",cast(getdate() as date))";
          await getDataTable(que2);

        }
      }
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Successfully Saved",
        showConfirmButton: false,
        timer: 1500,
      });
      this.setState({
        fldname: "",
        discription1: "",
        discription2: "",
        discription3: "",
        Pid: [],
        catid2: [],
        groupid1: "",
        loading: false,
        attributeSaved: true,
      });
    } else {
      Swal.fire({
        position: "top-end",
        icon: "warning",
        title: "Attribute Not Saved",
        showConfirmButton: false,
        timer: 1500,
      });
    }

    await this.getdata(this);
    this.forceUpdate();
  }

  async SaveDescription() {

    this.setState({ loading: true, attributeSaved: true });
    let MandatoryArray = [
      { Block: this.state.catid1 },
      { AttributeID: this.state.AID },
      { Description1: this.state.discription1 },
      { Description2: this.state.discription2 },
    ];
    let check = MandatoryFormFields(MandatoryArray);
    if (check === false) {
      this.setState({ loading: false });
      return false;
    }


    let que1 =
      "Select * from AttributeDescriptionMaster where (AID = " +
      this.state.AID +
      " AND BlockID = " +
      this.state.catid1 +
      ")";

    let rs1 = await getDataTable(que1);
    if (rs1.length > 0) {

      let queDel =
        "Delete from AttributeDescriptionMaster where AID = " +
        this.state.AID +
        " AND BlockID = " +
        this.state.catid1;
      await getDataTable(queDel);
    }

    let que11 =
      "INSERT INTO  AttributeDescriptionMaster  (BlockID, AID, Description1, Description2,loginID, logdate) Output Inserted.AID VALUES (" +
      this.state.catid1 +
      ", " +
      this.state.AID +
      ",'" +
      this.state.discription1 +
      "','" +
      this.state.discription2 +
      "'," +
      this.state.loginId +
      ", getdate())";

    let response = await getDataTable(que11);
    if (response.length > 0) {
      Swal.fire({
        position: "top-end",
        icon: "info",
        title: "Successfully Saved",
        showConfirmButton: false,
        timer: 1500,
      });
      await this.getdata();
    } else {
      alert("Some Error Occurred");
      this.setState({ loading: false });
    }

    this.setState({ loading: false, attributeSaved: false });
  }

  async deleteRow(e) {

    if (this.state.loginId === "1" || this.state.loginId === "10" || this.state.loginId === "20036") {
      let st = window.confirm("Are you sure want to delete ?");
      if (!st) {
        return false;
      }
      this.setState({ loading: true });

      let Que =
        "Update AttributeMaster set Dis = 1 output inserted.AID where AttributeName = '" +
        e.AttributeName +
        "' and Validation='" +
        e["Value type"] +
        "'";
      let rs = await getDataTable(Que);
      if (rs.length > 0) {
        Swal.fire({
          position: "top-end",
          icon: "info",
          title: "Successfully Deleted",
          showConfirmButton: false,
          timer: 1500,
        });
        await this.getdata();
      } else {
        alert("Some Error Occurred");
        this.setState({ loading: false });
      }
    } else {
      alert("Only admin allow to delete Attribute");
      return;
    }
  }

  async EditRow(e) {
    this.setState({ loading: true });
    if (this.state.loginId === "1" || this.state.loginId === "10" || this.state.loginId === "20036" || this.state.loginId === "6") {
      let dt1 = await getDataTable(
        "SELECT AttributeParentDetails.ParentID AS DisplayId, AttributeMaster.AttributeName AS DisplayName FROM AttributeParentDetails INNER JOIN AttributeMaster ON AttributeParentDetails.ParentID = AttributeMaster.AID WHERE CatDis = 0 and (AttributeParentDetails.AttributeID = " +
        e["#AID"] +
        ")"
      );
      if (!dt1) {
        dt1 = [];
      }
      let dt3 = await getDataTable(
        "SELECT BlockMaster.CategoryID AS DisplayId, BlockMaster.BlockName AS DisplayName FROM AttributeCategoryMap INNER JOIN BlockMaster ON BlockMaster.CategoryID = AttributeCategoryMap.CatID WHERE (AttributeCategoryMap.AttributeID = " +
        e["#AID"] +
        ")"
      );
      if (!dt3) {
        dt3 = [];
      }
      let dt2 = await getDataTable("SELECT a.groupID,  groupName FROM  AttributeMaster a INNER JOIN AttributeGroupMaster ag ON a.groupID = ag.groupid WHERE (a.AID = " + e["#AID"] + ")")
      if (dt2.length > 0) {
        this.setState({
          groupid1: dt2[0]['groupID'],
          groupname1: dt2[0]['groupName']
        })
      }
      this.setState({
        val: e["Value type"],
        fldname: e.AttributeName,
        catid2: dt3,
        updateData: true,
        updateid: e["#AID"],
        Pid: dt1,
        loading: false
      })
    } else {
      Swal.fire({
        icon: "info",
        title: "Only Admin Can edit Attribute",
        showConfirmButton: false,
        timer: 1500,
      });
      this.setState({ loading: false });
      return false;
    }
  }

  async UpdateAttribute() {
    this.setState({ loading: true });
    let rs0 = [];
    let que0 =
      "SELECT AID FROM AttributeMaster WHERE (AttributeName = N'" +
      this.state.fldname +
      "') and dis = 0 and AID not in (" +
      this.state.updateid +
      ")";
    rs0 = await getDataTable(que0);

    if (rs0.length > 0) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Attribute Name Already Used",
        showConfirmButton: false,
        timer: 1500,
      });
      this.setState({ loading: false });
      return false;
    }
    let Que =
      "UPDATE AttributeMaster SET AttributeName ='" +
      this.state.fldname +
      "', Validation = '" +
      this.state.val +
      "', groupid = '" +
      this.state.groupid1 +
      "' output inserted.AID where AID = " +
      this.state.updateid;
    let rs = await getDataTable(Que);
    if (rs[0].AID) {
      await getDataTable("delete from AttributeCategoryMap where AttributeID = " + rs[0].AID)
      for (let i = 0; i < this.state.catid2.length; i++) {
        let element = this.state.catid2[i];
        let Query1 = "INSERT INTO AttributeCategoryMap (CatID, AttributeID, loginID, logdate) VALUES (" + element['DisplayId'] + "," + rs[0].AID + "," + this.state.loginId + ",cast(getdate() as date))"
        await getDataTable(Query1)
      }
      this.setState({ loading: false })
    }
    if (rs.length > 0) {
      if (this.state.options.length > 0 && this.state.val === "option") {
        for (let i = 0; i < this.state.options.length; i++) {
          let que10 =
            "Select * FROM optionValue WHERE  (AtrributeID = " +
            rs[0].AID +
            ") and OptValue = '" +
            this.state.options[i].name +
            "'";
          let rs10 = await getDataTable(que10);
          if (rs10.length <= 0) {
            let que1 =
              "INSERT INTO optionValue (AtrributeID, OptValue, logid, logdate) output inserted.OptValue VALUES (" +
              rs[0].AID +
              ",'" +
              this.state.options[i].name +
              "'," +
              this.state.loginId +
              ",cast(getdate() as date))";
            await getDataTable(que1);

          }
        }
      }
      if (this.state.Pid.length > 0) {
        let Que3 =
          "Delete from AttributeParentDetails where AttributeID = " + rs[0].AID;
        await getDataTable(Que3);
        for (let i = 0; i < this.state.Pid.length; i++) {
          let que2 =
            "INSERT INTO AttributeParentDetails (AttributeID, ParentID, LoginID, LogDate) output inserted.APID VALUES (" +
            rs[0].AID +
            "," +
            this.state.Pid[i].DisplayId +
            "," +
            this.state.loginId +
            ",cast(getdate() as date))";
          await getDataTable(que2);

        }
      }
    }
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Value Updated",
      showConfirmButton: false,
      timer: 1500,
    });
    this.setState({ loading: false });
    await this.getdata();
  }
  async getRowData(e) {
    if (this.state.loginId === "10" || this.state.loginId === "1") {
      let rsst = await getDataTable(
        "SELECT AttributAlias.aliasName, AttributeMaster.AttributeName, Portals.PortalName FROM AttributAlias INNER JOIN AttributeMaster ON AttributAlias.AttributeID = AttributeMaster.AID INNER JOIN Portals ON AttributAlias.PortalID = Portals.PID WHERE (AttributeMaster.AttributeName = '" +
        e.AttributeName +
        "')"
      );
      if (Array.isArray(rsst)) {
        Modal.info({
          title: "Aliasing Details",
          okText: "CLOSE",
          width: "600px",
          closable: true,
          footer: null,
          bodyStyle: { maxHeight: "500px" },
          style: { padding: "0px" },
          centered: true,
          maskClosable: true,
          content: (
            <div>
              <ul>
                {rsst.map((value, index) => {
                  return (
                    <li id={index}>
                      <b>{value.AttributeName}</b>-{value.aliasName}-
                      {value.PortalName}
                    </li>
                  );
                })}
              </ul>
            </div>
          ),
        });
      } else {
        return;
      }
    } else {
      Swal.fire({
        position: "top-end",
        icon: "info",
        title: "Not Allowed to Show Details",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }

  render() {
    return (
      <div className="content-wrapper">
        <div
          className="loader"
          style={{ display: this.state.loading ? "block" : "none" }}
        >
          <div className="loader-item">
            <Spin />
          </div>
        </div>
        <section className="content">
          <div
            id="ctl00_CPHMaincontent_divclient"
            className="box box-success"
            style={{ marginTop: "-13px" }}
          >
            <div className="box-header with-border">
              <h3 className="box-title">
                ATTRIBUTE MASTER
                <span
                  id="ctl00_CPHMaincontent_DivOrdNo"
                  style={{ display: "inline" }}
                ></span>
              </h3>
              <div className='pull-right' style={{ marginRight: '3px' }}>
                {<AttributeGroup width='800px' />}
              </div>
              <div className="pull-right">

              </div>
            </div>
            <div
              className="box-body"
              id="orderDetails"
              style={{ display: "block" }}
            >
              <div className="row">
                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                  <label>Add New Attribute</label>
                  <span className="vcode">*</span>
                  <div className="form-group">
                    <input
                      name="fldname"
                      id="tp1"
                      value={this.state.fldname}
                      defaultValue={this.state.fldname}
                      onChange={this.myChangeHandler}
                      autoComplete="off"
                      placeholder="Enter FieldName"
                      tabIndex={1}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                  <label>Value Type</label>
                  <span className="vcode">*</span>
                  <div className="form-group">
                    <select
                      name="val"
                      onChange={this.myChangeHandler}
                      id="val"
                      value={this.state.val}
                      defaultValue={this.state.val}
                      tabIndex={1}
                      className="form-control"
                    >
                      <option selected="true" disabled="disabled">
                        Value Type
                      </option>
                      <option value="number">Numeric</option>
                      <option value="Alpha-numeric">Alpha Numeric</option>
                      <option value="date">Date</option>
                      <option value="option">Option</option>
                      <option value="yes/no">yes/no</option>
                      <option value="url">URL</option>
                    </select>
                  </div>
                </div>
                {/* <div
                  className="col-xs-6 col-sm-6 col-md-3 margintop"
                  style={{
                    display: this.state.val === "option" ? "inline" : "none",
                  }}
                >
                  <label>&nbsp;</label>
                  <div className="form-group">
                    <MultipleDataNoparent
                      attributename="Options"
                      data={this.state.options}
                    />
                  </div>
                </div> */}

                <div className="col-xs-6 col-sm-6 col-md-3 margintop">
                  <label>Select Parent</label>
                  <AutoCompletemulti
                    id="portalID"
                    frmNm="FRMVALUEALIASING"
                    quryNm="FILLATTRIBUTE"
                    db="IMAGEDB"
                    placeholder="Please Select Attribute"
                    onAfterSelect={(e) =>
                      this.onAutoCOmpletMultiPleSelect(e, "Pid")
                    }
                    isValid={this.state.isValid}
                    defauldVal={this.state.Pid}
                  ></AutoCompletemulti>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                  <label>Select Group</label>
                  <AutoCompleteInput
                    id="groupIdup"
                    frmNm="FRMATTRIBUTEMASTER"
                    quryNm="FILLGROUP"
                    db="IMAGEDB"
                    placeholder="Please Select Group"
                    onAfterSelect={(e) => this.onAfterSelect(e, "groupid1", "groupname1")}
                    defaultSelected={[{ DisplayId: this.state.groupid1, DisplayName: this.state.groupname1 }]}
                  ></AutoCompleteInput>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                  <label>Select Block</label>
                  <AutoCompletemulti
                    id="catid"
                    frmNm="FRMCATPRODUCTMASTER"
                    quryNm="FILLCAT"
                    db="IMAGEDB"
                    placeholder="Please Select Block"
                    onAfterSelect={(e) => this.onAutoCOmpletMultiPleSelect(e, "catid2")}
                    isValid={this.state.isValid}
                    defauldVal={this.state.catid2}
                  >
                  </AutoCompletemulti>
                </div>
                <div
                  className="col-xs-6 col-sm-6 col-md-1 margintop"
                  style={{ display: this.state.updateData ? "none" : "block" }}
                >
                  <label>&nbsp;</label>
                  <div className="form-group">
                    <input
                      type="button"
                      value="Save Attribute"
                      onClick={this.SavePortal.bind(this)}
                      className="btn btn-primary"
                    />
                  </div>
                </div>

                <div
                  className="col-xs-6 col-sm-6 col-md-1 margintop"
                  onClick={this.UpdateAttribute.bind(this)}
                  style={{ display: this.state.updateData ? "block" : "none" }}
                >
                  <label>&nbsp;</label>
                  <div className="form-group">
                    <input
                      type="button"
                      value="Update Field"
                      className="btn btn-success"
                    />
                  </div>
                </div>

                <div
                  className="box-body"
                  id="orderDetails"
                  style={{ display: "block" }}
                >
                  <label>&nbsp; &nbsp;</label>
                  <div className="row"></div>

                  <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                    <label>Select Block &nbsp;</label>
                    <span className="vcode">*</span>
                    <AutoCompleteInput
                      id="catid"
                      frmNm="FRMCATPRODUCTMASTER"
                      quryNm="FILLCAT"
                      db="IMAGEDB"
                      placeholder="Please Select Block"
                      onAfterSelect={(e) =>
                        this.onAfterSelect(e, "catid1", "catname1")
                      }
                      isValid={this.state.isValid}
                    ></AutoCompleteInput>
                  </div>
                  <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                    <label>Select Attribute</label>
                    <span className="vcode">*</span>
                    <AutoCompleteCascad
                      id="AID"
                      frmNm="FRMMERGING"
                      quryNm="FILLATTRIBUTE"
                      db="IMAGEDB"
                      filter1="CatID"
                      filterId1={this.state.catid1}
                      filter2=""
                      filterId2=""
                      placeholder="Please Select Attribute"
                      onAfterSelect={(e) =>
                        this.onAfterSelect(e, "AID", "AName")
                      }
                      isValid={this.state.isValid}
                    ></AutoCompleteCascad>
                  </div>

                  <div className="col-xs-4 col-sm-4 col-md-2 margintop">
                    <label>Description 1</label>
                    <span className="vcode">*</span>
                    <div className="form-group">
                      <input
                        name="discription1"
                        id="discription1"
                        value={this.state.discription1}
                        defaultValue={this.state.discription1}
                        onChange={this.myChangeHandler}
                        autoComplete="off"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-xs-4 col-sm-4 col-md-2 margintop">
                    <label>Description 2</label>
                    <span className="vcode">*</span>
                    <div className="form-group">
                      <input
                        onChange={this.myChangeHandler}
                        value={this.state.discription2}
                        defaultValue={this.state.discription2}
                        name="discription2"
                        autoComplete="off"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div
                    className="col-xs-6 col-sm-6 col-md-2 margintop"
                    style={{
                      display: this.state.updateData ? "none" : "block",
                    }}
                  >
                    <label>&nbsp;</label>
                    <div className="form-group">
                      <input
                        type="button"
                        value="Save"
                        onClick={this.SaveDescription.bind(this)}
                        className="btn btn-primary"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12 margintop">
                  {
                    this.state.fielddata !== undefined &&
                    this.state.fielddata.length > 0 && (
                      <AntD
                        data={[...this.state.fielddata]}
                        exportXL="true"
                        Delete="true"
                        DeleteFn={(e) => this.deleteRow(e)}
                        EditRow="true"
                        EditRowFn={(e) => this.EditRow(e)}
                      ></AntD>
                    )

                    // <Table
                    //     //GetRow='true'
                    //     //GetRowFn={(e) => this.getRowData(e)}
                    //     deleteRow1="YES"
                    //     DeleteRowFn={(e) => this.deleteRow(e)}
                    //     EditRow="true"
                    //     EditRowFn={(e) => this.EditRow(e)}
                    //     height='72vh'
                    //     data={this.state.fielddata}
                    //     id='fielddata'
                    //     exportXL="true">
                    // </Table>
                  }
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
