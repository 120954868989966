import React, { Component } from "react";
//import xlsExport from 'xlsexport'
import {
  Configuration,
  exceltodb,
  MandatoryFormFields,
  getDataTable,
  json2xlsx,
} from "../Configuration";
import AntD from "../Actions/Controls/Table/AntDTable";
import { Spin, Modal } from "antd";
import "../ORDER/OrderMaster/style.css";
import "antd/dist/antd.css";
import AutoCompleteInput from "../Actions/Controls/AutoComplete/autoComplete";
import AutoCompleteCascad from "../Actions/Controls/AutoComplete/autoComplete.cascad";
import Swal, { swal } from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import VLAUEALSING from "./MODAL/frmcreateValueAliasing";
const dataBase = "IMAGEDB";
let files = "";
export default class frmValueAliasingMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginId: 0,
      visible: false,
      file: null,
      loading: false,
      aData: [],
      tptype: "0",
      options: [],
      stsdup: 0,
      AIDM: "",
      ANAME: "",
      mshow: 0,
      selectedrows: [],
      stsMandatory: 0,
      OnlyPending: 0,
      alisedAtbte: 0,
      merge: "0",
      ImportSheetId: "",
      ImportData: [],
    };
  }

  async componentDidMount() {
    let rs = await getDataTable(
      "select orgid from users where userid = " + Configuration.USER_ID
    );
    await this.setState({
      loginId: Configuration.USER_ID,
      orgid: rs[0].orgid,
    });
  }

  myChangeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onAfterSelect(selectedOptions, id, name) {
    if (selectedOptions.length > 0) {
      this.setState({
        [id]: selectedOptions[0].DisplayId,
        [name]: selectedOptions[0].DisplayName,
      });
    } else {
      this.setState({
        [id]: "",
        [name]: "",
      });
    }
  }

  async UploadFile() {
    this.setState({
      loading: true,
      aData: [],
      stsdup: 0,
      OnlyPending: 0,
      stsMandatory: 0,
    });
    let MandatoryArray = [
      { Files: files },
      { portal: this.state.portalid },
      { BLOCK: this.state.catid1 },
      { TEMPLATE: this.state.tempId },
    ];
    let check = MandatoryFormFields(MandatoryArray);
    if (check === false) {
      this.setState({ loading: false });
      return false;
    }
    let tblname = await exceltodb(files, dataBase, this.state.loginId);

    try {
      let que = `EXEC DATA_FOR_VALUE_ALIASING_NEW @tblname='${tblname}' , @loginid=${this.state.loginId} , @portalID=${this.state.portalid}`;
      console.log(que);
      let savedat = await getDataTable(que);
      console.log(savedat);

      let que10 = `EXEC DATA_FOR_VALUE_ALIASING_POP_UP @tblname='${tblname}' , @loginid=${this.state.loginId} , @portalID=${this.state.portalid} , @templateID=${this.state.tempId}`;
      let PopUpSp = await getDataTable(que10);

      debugger;
      console.log(PopUpSp);

      if (PopUpSp.length > 0) {
        this.setState({ loading: false });
        let result = await Swal.fire({
          title: `${PopUpSp.length} VALUE ARE MISSING. ARE YOU SURE WANT TO UPLOAD. IF YOU SELECT YES ,THESE MISSING VALUE AND THEIR ALAISING WILL BE PERMANETLY DELETED`,
          icon: "warning",
          showConfirmButton: true,
          showCancelButton: true,
          showDenyButton: true,
          confirmButtonText: "YES",
          cancelButtonText: "NO",
          denyButtonText: "Generate Excel",
        });

        if (result.isConfirmed) {
          this.setState({ loading: true });

          let st = JSON.stringify(PopUpSp);

          debugger;
          let que11 = `EXEC FrmValueAliasingDeleteValueSheetMismatch @tblname='${tblname}' , @loginid=${this.state.loginId} , @portalID=${this.state.portalid} , @templateID=${this.state.tempId}`;

          let DeleteData = await getDataTable(que11);
          console.log(DeleteData);
          debugger;
          let que1 = `EXEC UPDATEDATA_FOR_VALUEALIAS_NEW @onlyPending=${this.state.OnlyPending} ,@mandatory=${this.state.stsMandatory} ,@tblname='${tblname}' , @loginid=${this.state.loginId} , @portalID=${this.state.portalid} , @BlockID=${this.state.catid1} , @templateID=${this.state.tempId}`;
          let rs1 = await getDataTable(que1);
          console.log(rs1);

          if (rs1.length > 0) {
            this.setState({ loading: false, aData: rs1, tablename: tblname });
          } else {
            Swal.fire({
              icon: "info",
              title: "NO DATA FOUND",
              showConfirmButton: false,
              timer: 1500,
            });
            this.setState({ loading: false, aData: [], tablename: tblname });
          }
        } else if (result.isDenied) {
          const filteredData = PopUpSp.map((item) => {
            // Create a new object with only the desired columns
            return {
              TEMPLATENAME: item.tamplatename,
              PORTALNAME: item.portalname,
              PORTALHEADER: item.portalHeader,
              PORTALVALUE: item.PortalValue,
              ATTRIBUTENAME: item.attributename,
              ALIASEDVALUE: item.optvalue,
              // Exclude portalid and template
            };
          });

          // Generate Excel with the filtered data
          await json2xlsx(filteredData, "Mismatched Value list");
        } else {
          Swal.fire({
            title: "UPLOAD FAILED",
            icon: "warning",
          });
          this.setState({ loading: false });
        }
      } else {
        this.setState({ loading: true });
        let que1 = `EXEC UPDATEDATA_FOR_VALUEALIAS_NEW @onlyPending=${this.state.OnlyPending} ,@mandatory=${this.state.stsMandatory} ,@tblname='${tblname}' , @loginid=${this.state.loginId} , @portalID=${this.state.portalid} , @BlockID=${this.state.catid1} , @templateID=${this.state.tempId}`;
        let rs1 = await getDataTable(que1);
        console.log(rs1);

        if (rs1.length > 0) {
          this.setState({ loading: false, aData: rs1, tablename: tblname });
        } else {
          Swal.fire({
            icon: "info",
            title: "NO DATA FOUND",
            showConfirmButton: false,
            timer: 1500,
          });
          this.setState({ loading: false, aData: [], tablename: tblname });
        }
      }
    } catch (error) {
      // Handle any errors that might occur during execution
      console.error(error);
    }
  }

  async GetDataForImportAliasing() {
    this.setState({ loading: true });

    let MandatoryArray = [
      { BLOCK: this.state.catid1 },
      { TEMPLATE: this.state.ImportSheetId },
    ];

    let check = MandatoryFormFields(MandatoryArray);

    if (check === false) {
      this.setState({ loading: false });
      return false;
    }

    let que =
      "Exec FrmValueAliasing_ImportTemplate @templateID =" +
      this.state.ImportSheetId +
      ",@blockID =" +
      this.state.catid1;

    try {
      let rs = await getDataTable(que);
      debugger;
      if (rs.length > 0) {
        this.setState({ loading: false, ImportData: rs });
      } else {
        Swal.fire({
          icon: "info",
          title: "NO DATA FOUND",
          showConfirmButton: false,
          timer: 1500,
        });

        // Handle the case where no data is found
        this.setState({ loading: false, ImportData: [] });
      }
    } catch (error) {
      // Handle any errors that occur during the data fetch
      console.error("Error fetching data:", error);
      this.setState({ loading: false });
    }
  }

  async UpdateAliasing() {
    let st = this.state.selectedrows;
    this.setState({ loading: true });
    for (let i = 0; i < st.length; i++) {
      let ele = st[i];
      if (ele["#valueID"]) {
        if (ele["AliasValue"]) {
          // let queDel = " Delete from OptionValueAliasing where ValueID ="+ ele['#valueID'] + " and AliasedValue = '"+ele['AliasValue']+"' and PortalID ="+ele['#portalID']
          // let rsDel = await getDataTable(queDel)
        }
        this.setState({ loading: true });
        let quechk =
          "select UID,PortalHeader + ' - ' + AliasedValue AS DT from OptionValueAliasing where ValueID = " +
          ele["#valueID"] +
          " and PortalHeader = '" +
          ele["Portal Attribute"] +
          "' and ";
        quechk +=
          "  TemplateID = " + this.state.tempId + " and ValueID <> 23554";
        let rschk = await getDataTable(quechk);
        if (rschk.length > 0) {
          this.setState({ loading: false });
          let st = await Swal.fire({
            title:
              "ALREADY ALISED WITH " +
              rschk[0]["DT"] +
              " . <br> ARE YOU SURE WANT TO OVERWRITE DATA ?",
            showCancelButton: true,
            confirmButtonText: "YES",
            cancelButtonText: "NO",
          });
          if (st.isConfirmed) {
          } else {
            continue;
          }
        }
        this.setState({ loading: true });
        var Que =
          "EXEC SaveAttributeValue_Alias @ValueID=" +
          ele["#valueID"] +
          ", @portalID='" +
          this.state.portalname +
          "' , @loginID=" +
          this.state.loginId +
          ", @aliasName='" +
          ele["Portal Value"] +
          "' , @Header='" +
          ele["Portal Attribute"] +
          "'";
        Que += " , @templateID =" + this.state.tempId;
        let rs = await getDataTable(Que);
      }
    }
    await this.updateData();
  }

  async UpdateAliasingImport() {
    let st = this.state.selectedrows;
    this.setState({ loading: true });
    for (let i = 0; i < st.length; i++) {
      let ele = st[i];
      if (ele["#valueID"]) {
        if (ele["AliasValue"]) {
          // let queDel = " Delete from OptionValueAliasing where ValueID ="+ ele['#valueID'] + " and AliasedValue = '"+ele['AliasValue']+"' and PortalID ="+ele['#portalID']
          // let rsDel = await getDataTable(queDel)
        }
        this.setState({ loading: true });
        let quechk =
          "select UID,TemplateHeader + ' - ' + AliasedValue AS DT from ImportValueAlising where ValueID = " +
          ele["#valueID"] +
          " and TemplateHeader = '" +
          ele["Portal Attribute"] +
          "' and ";
        quechk +=
          "  TemplateID = " +
          this.state.ImportSheetId +
          " and ValueID <> 23554";
        let rschk = await getDataTable(quechk);
        if (rschk.length > 0) {
          this.setState({ loading: false });
          let st = await Swal.fire({
            title:
              "ALREADY ALISED WITH " +
              rschk[0]["DT"] +
              " . <br> ARE YOU SURE WANT TO OVERWRITE DATA ?",
            showCancelButton: true,
            confirmButtonText: "YES",
            cancelButtonText: "NO",
          });
          if (st.isConfirmed) {
          } else {
            continue;
          }
        }
        this.setState({ loading: true });
        var Que =
          "EXEC SaveImportValueAliasing @ValueID=" +
          ele["#valueID"] +
          ", @aliasName='" +
          ele["TemplateValue"] +
          "', @loginID=" +
          this.state.loginId +
          " , @Header='" +
          ele["Portal Attribute"] +
          "'";
        Que += " , @templateID =" + this.state.ImportSheetId;
        let rs = await getDataTable(Que);
      }
    }
    await this.updateImportData();
  }

  async DeleteAliasing() {
    let st = this.state.selectedrows;
    this.setState({ loading: true });
    for (let i = 0; i < st.length; i++) {
      let ele = st[i];
      if (ele["AliasValue"]) {
        let queDel =
          " Delete from OptionValueAliasing output Deleted.UID where ValueID =" +
          ele["#valueID"] +
          " and AliasedValue = '" +
          ele["AliasValue"] +
          "' and PortalID =" +
          ele["#portalID"] +
          " and PortalHeader = '" +
          ele["Portal Attribute"] +
          "'";
        queDel += " and TemplateID =" + this.state.tempId;
        let rsDel = await getDataTable(queDel);
      }
    }
    await this.updateData();
  }

  async updateData() {
    this.setState({ loading: true, aData: [] });
    //let s = document.getElementsByName('chkduplicate')
    let PrscName =
      "UPDATEDATA_FOR_VALUEALIAS_NEW @onlyPending = " +
      this.state.OnlyPending +
      " , @mandatory =" +
      this.state.stsMandatory +
      " ,";
    if (this.state.stsdup === 1) {
      PrscName = "DATA_FOR_REVERSE_ALIASING";
    }
    let que1 =
      "EXEC " +
      PrscName +
      " @tblname='" +
      this.state.tablename +
      "' , @loginid =" +
      this.state.loginId +
      " , @portalID =" +
      this.state.portalid +
      " , @BlockID = " +
      this.state.catid1 +
      " ,@templateID =" +
      this.state.tempId;
    que1 += " , @onlyAlisedAtrbt =" + this.state.alisedAtbte;
    let rs1 = await getDataTable(que1);
    if (rs1.length > 0) {
      this.setState({ loading: false, aData: rs1, selectedrows: [] });
    } else {
      Swal.fire({
        icon: "info",
        title: "NO DATA FOUND",
        showConfirmButton: false,
        timer: 1500,
      });
      this.setState({ loading: false, aData: [], selectedrows: [] });
    }
  }

  async updateImportData() {
    this.setState({ loading: true, aData: [] });
    //let s = document.getElementsByName('chkduplicate')
    let que =
      "Exec FrmValueAliasing_ImportTemplate @templateID =" +
      this.state.ImportSheetId +
      ",@blockID =" +
      this.state.catid1;
    let rs1 = await getDataTable(que);
    if (rs1.length > 0) {
      this.setState({ loading: false, ImportData: rs1, selectedrows: [] });
    } else {
      Swal.fire({
        icon: "info",
        title: "NO DATA FOUND",
        showConfirmButton: false,
        timer: 1500,
      });
      this.setState({ loading: false, ImportData: [], selectedrows: [] });
    }
  }

  async selectedDataFN(e) {
    if (e.length > 0) {
      this.setState({ selectedrows: e });
    } else {
      this.setState({ selectedrows: [] });
    }
  }

  async EditRow(e) {
    console.log(e);
    let index = e.key;
    let st = this.state.aData;
    st[index] = e;
    this.setState({ aData: st });
    if (this.state.selectedrows.length > 0 && this.state.stsdup === 1) {
      await this.updateMultipleReverseAlising(e, this);
    }
  }

  async EditRowImport(e) {
    console.log(e);
    let index = e.key;
    let st = this.state.ImportData;
    st[index] = e;
    this.setState({ ImportData: st });
    if (this.state.selectedrows.length > 0 && this.state.stsdup === 1) {
      await this.updateMultipleReverseAlising(e, this);
    }
  }

  async updateMultipleReverseAlising(e) {
    let dt = this.state.selectedrows;
    for (let i = 0; i < dt.length; i++) {
      const ele = dt[i];

      // let quechk = "select UID,PortalHeader + ' - ' + AliasedValue AS DT from OptionValueAliasing where ValueID = " + ele['#valueID'] + " and PortalHeader = '" + ele['Portal Attribute'] + "' and "
      // quechk += "  TemplateID = " + this.state.tempId + " and ValueID <> 23554"
      // let rschk = await getDataTable(quechk)
      // if (rschk.length > 0) {
      //     let st = await Swal.fire({
      //         title: 'ALREADY ALISED WITH ' + rschk[0]['DT'] + ' . <br> ARE YOU SURE WANT TO OVERWRITE DATA ?',
      //         showCancelButton: true,
      //         confirmButtonText: 'YES',
      //         cancelButtonText: 'NO'
      //     })
      //     if (st.isConfirmed) { }
      //     else {
      //         continue;
      //     }
      // }

      var Que =
        "EXEC SaveAttributeValue_Alias @ValueID=" +
        ele["#valueID"] +
        ", @portalID='" +
        this.state.portalname +
        "' , @loginID=" +
        this.state.loginId +
        ", @aliasName='" +
        e["Portal Value"] +
        "' , @Header='" +
        e["Portal Attribute"] +
        "'";
      Que += " , @templateID =" + this.state.tempId;
      let rs = await getDataTable(Que);
    }
    await this.updateData();
  }

  deleteRow(e) {
    let st = this.state.aData;
    this.setState({ aData: [] });
    let index = e.key;
    st.splice(index, 1);
    this.setState({ aData: st });
    this.forceUpdate();
  }

  deleteRowImport(e) {
    let st = this.state.ImportData;
    this.setState({ ImportData: [] });
    let index = e.key;
    st.splice(index, 1);
    this.setState({ ImportData: st });
    this.forceUpdate();
  }

  hideShowDiv(id, childId) {
    let div = document.getElementById(id);
    if (div !== null) {
      if (document.getElementById(id).style.display == "block") {
        document.getElementById(id).style.display = "none";
        let ele = document.getElementById(childId);
        ele.className = "fa fa-plus";
      } else {
        document.getElementById(id).style.display = "block";
        let ele = document.getElementById(childId);
        ele.className = "fa fa-minus";
      }
    }
  }

  myFileChangeHandler = (event) => {
    files = event.target.files[0];
  };

  async alwdreverseAliasing(e) {
    if (e.target.checked) {
      this.setState({ stsdup: 1 });
    } else {
      this.setState({ stsdup: 0 });
    }
    //await this.updateData()
  }

  async alwdOnlyAlisedAtrbte(e) {
    if (e.target.checked) {
      this.setState({ alisedAtbte: 1 });
    } else {
      this.setState({ alisedAtbte: 0 });
    }
  }

  async alwdMandatory(e) {
    if (e.target.checked) {
      this.setState({ stsMandatory: 1 });
    } else {
      this.setState({ stsMandatory: 0 });
    }
    //await this.updateData()
  }

  async OnlyPendingSel(e) {
    if (e.target.checked) {
      this.setState({ OnlyPending: 1 });
    } else {
      this.setState({ OnlyPending: 0 });
    }
    //await this.updateData()
  }

  async UpdateAliasingToNull() {
    let st = await Swal.fire({
      title: "ARE YOU SURE WANT TO UPDATE NULL DATA ?",
      showCancelButton: true,
      confirmButtonText: "YES",
      cancelButtonText: "NO",
    });
    if (st.isConfirmed) {
      this.setState({ loading: true, aData: [] });
      let que1 =
        "EXEC UPDATEDATA_FOR_VALUEALIAS_NEW @mandatory =" +
        this.state.stsMandatory +
        " ,@tblname='" +
        this.state.tablename +
        "' , @loginid =" +
        this.state.loginId +
        " , @portalID =" +
        this.state.portalid;
      que1 +=
        " , @BlockID =" +
        this.state.catid1 +
        " , @templateID =" +
        this.state.tempId;
      let rs1 = await getDataTable(que1);

      que1 = "";
      que1 +=
        " EXEC frmValueAlisingMaster_UpdateAlisingToNullValues @tblName ='" +
        this.state.tablename +
        "' , @loginID =" +
        this.state.loginId;
      let rs = await getDataTable(que1);

      let que2 =
        "EXEC UPDATEDATA_FOR_VALUEALIAS_NEW @mandatory =" +
        this.state.stsMandatory +
        " ,@tblname='" +
        this.state.tablename +
        "' , @loginid =" +
        this.state.loginId +
        " , @portalID =" +
        this.state.portalid;
      que2 +=
        " , @BlockID =" +
        this.state.catid1 +
        " , @templateID =" +
        this.state.tempId;
      let rs2 = await getDataTable(que2);

      this.setState({ loading: false, aData: rs2 });
    } else {
      return false;
    }
  }

  async UpdateReverseAliasingToNull() {
    let st = await Swal.fire({
      title: "ARE YOU SURE WANT TO UPDATE NULL DATA ?",
      showCancelButton: true,
      confirmButtonText: "YES",
      cancelButtonText: "NO",
    });
    if (st.isConfirmed) {
      this.setState({ loading: true, aData: [] });
      let que1 =
        "EXEC UPDATEDATA_FOR_VALUEALIAS_NEW @mandatory =" +
        this.state.stsMandatory +
        " ,@tblname='" +
        this.state.tablename +
        "' , @loginid =" +
        this.state.loginId +
        " , @portalID =" +
        this.state.portalid;
      que1 +=
        " , @BlockID =" +
        this.state.catid1 +
        " , @templateID =" +
        this.state.tempId;
      let rs1 = await getDataTable(que1);

      que1 = "";
      que1 +=
        " EXEC frmValueAlisingMaster_UpdateReverseAlsingToNull @tblName ='" +
        this.state.tablename +
        "' , @loginID =" +
        this.state.loginId +
        " , @TemplateID = " +
        this.state.tempId;
      let rs = await getDataTable(que1);

      let que2 =
        "EXEC UPDATEDATA_FOR_VALUEALIAS_NEW @mandatory =" +
        this.state.stsMandatory +
        " ,@tblname='" +
        this.state.tablename +
        "' , @loginid =" +
        this.state.loginId +
        " , @portalID =" +
        this.state.portalid;
      que2 +=
        " , @BlockID =" +
        this.state.catid1 +
        " , @templateID =" +
        this.state.tempId;
      let rs2 = await getDataTable(que2);

      this.setState({ loading: false, aData: rs2 });
    } else {
      return false;
    }
  }

  async UpdateReferenceAlising() {
    let MandatoryArray = [{ "REFERENCE TEMPLATE": this.state.ReftempId }];
    let check = MandatoryFormFields(MandatoryArray);
    if (check == false) {
      return false;
    }
    let st = await Swal.fire({
      title: "ARE YOU SURE WANT TO UPDATE REFERENCE TEMPLATE DATA ?",
      showCancelButton: true,
      confirmButtonText: "YES",
      cancelButtonText: "NO",
    });
    if (st.isConfirmed) {
      this.setState({ loading: true, aData: [] });

      let que2 =
        "EXEC FrmValueAliasingMaster_UpdateReferenceData @tblname='" +
        this.state.tablename +
        "' , @loginID =" +
        this.state.loginId +
        " , @tempId =" +
        this.state.tempId;
      que2 += " ,  @refTempId =" + this.state.ReftempId;
      let rs2 = await getDataTable(que2);

      await this.updateData();

      // this.setState({ loading: false, aData: rs2 })
    } else {
      return false;
    }
  }

  async GetReference(e) {
    Modal.info({
      title: "GET TEMPLATE REFERENCE",
      okText: "CLOSE",
      width: "600px",
      closable: true,
      footer: null,
      bodyStyle: { maxHeight: "500px" },
      style: { padding: "0px" },
      centered: true,
      maskClosable: true,
      content: (
        <div>
          <div className="col-xs-6 col-sm-6 col-md-7 margintop">
            <label>Select Template</label>
            <AutoCompleteCascad
              id="catid"
              frmNm="FRMUPDATEATTRIBUTEALIASING"
              quryNm="FILLTEMPLATE"
              db="IMAGEDB"
              filter1="CatID"
              filterId1={this.state.catid1}
              filter2="PortalID"
              filterId2={this.state.portalid}
              placeholder="Please Select Template"
              onAfterSelect={(e) =>
                this.onAfterSelect(e, "ReftempId", "ReftempName")
              }
              isValid={this.state.isValid}
            ></AutoCompleteCascad>
          </div>
          <div className="col-xs-6 col-sm-6 col-md-4 margintop">
            <label>&nbsp;</label>
            <div className="form-group">
              <input
                type="button"
                value="SAVE"
                onClick={this.UpdateReferenceAlising.bind(this)}
                className="btn btn-block btn-danger"
              />
            </div>
          </div>
        </div>
      ),
    });
  }

  async UpdateSelectedAlisingNULL() {
    let st = await Swal.fire({
      title: "ARE YOU SURE WANT TO UPDATE NULL DATA ?",
      showCancelButton: true,
      confirmButtonText: "YES",
      cancelButtonText: "NO",
    });
    if (st.isConfirmed) {
      let st = this.state.selectedrows;
      this.setState({ loading: true });
      for (let i = 0; i < st.length; i++) {
        let ele = st[i];
        if (this.state.stsdup === 1) {
          var Que =
            "EXEC SaveAttributeValue_Alias @ValueID= " +
            ele["#valueID"] +
            " , @portalID='" +
            this.state.portalname +
            "' , @loginID=" +
            this.state.loginId +
            ", @aliasName='NULL VALUE' , @Header='NO OPT'";
          Que += " , @templateID =" + this.state.tempId;
          let rs = await getDataTable(Que);
        } else {
          if (ele["AliasValue"]) {
            let queDel =
              " Delete from OptionValueAliasing output Deleted.UID where ValueID =" +
              ele["#valueID"] +
              " and AliasedValue = '" +
              ele["AliasValue"] +
              "' and PortalID =" +
              ele["#portalID"] +
              " and PortalHeader = '" +
              ele["Portal Attribute"] +
              "'";
            queDel += " and TemplateID =" + this.state.tempId;
            let rsDel = await getDataTable(queDel);
          }
          console.log(ele);

          var Que =
            "EXEC SaveAttributeValue_Alias @ValueID= 23554 , @portalID='" +
            this.state.portalname +
            "' , @loginID=" +
            this.state.loginId +
            ", @aliasName='" +
            ele["Portal Value"] +
            "' , @Header='" +
            ele["Portal Attribute"] +
            "'";
          Que += " , @templateID =" + this.state.tempId;
          let rs = await getDataTable(Que);
        }
      }
      await this.updateData();
    }
  }

  render() {
    //lt = this.state
    return (
      <div className="content-wrapper" style={{ maxHeight: "100%" }}>
        <div
          className="loader"
          style={{ display: this.state.loading ? "block" : "none" }}
        >
          <div className="loader-item">
            <Spin />
          </div>
        </div>
        {/* <div>
                    {this.state.mshow === 1 && this.CreateAttribute(this)}
                </div> */}
        <section className="content">
          <div
            id="ctl00_CPHMaincontent_div"
            className="box box-success"
            style={{ marginTop: "-13px" }}
          >
            <div className="box-header with-border">
              <h3 className="box-title">
                VALUE ALIASING MASTER
                <span
                  id="ctl00_CPHMaincontent_DivOrdNo"
                  style={{ display: "inline" }}
                ></span>
              </h3>
              <div class="btn-group pull-right">
                <button type="button" class="btn btn-danger">
                  Select Action
                </button>
                <button
                  type="button"
                  class="btn btn-danger dropdown-toggle"
                  data-toggle="dropdown"
                >
                  <span class="caret"></span>
                  <span class="sr-only">Toggle Dropdown</span>
                </button>
                <ul class="dropdown-menu" role="menu">
                  <li>
                    <a onClick={this.UpdateAliasingToNull.bind(this)}>
                      Update Remain Aliasing to NULL
                    </a>
                  </li>
                  {(this.state.loginId === "1" || this.state.orgid === 1) && (
                    <li>
                      <a onClick={this.UpdateReverseAliasingToNull.bind(this)}>
                        Update Reverse Aliasing to NULL
                      </a>
                    </li>
                  )}
                  {this.state.aData.length > 0 && (
                    <li>
                      <a onClick={this.GetReference.bind(this)}>
                        GET REFERENCE
                      </a>
                    </li>
                  )}
                  <li>
                    <a onClick={this.UpdateSelectedAlisingNULL.bind(this)}>
                      Update Selected Aliasing to NULL
                    </a>
                  </li>
                  <li>
                    <VLAUEALSING
                      templateID={this.state.tempId}
                      width="750px"
                      BlockID={this.state.catid1}
                    />
                  </li>
                  {/* <li class="divider"></li>
                                <li><a href="#">Separated link</a></li> */}
                </ul>
              </div>
            </div>

            <div
              className="box-body"
              id="orderDetails"
              style={{ display: "block" }}
            >
              <div className="row">
                <div className="col-xs-6 col-sm-6 col-md-2">
                  <label>Select Type</label>
                  <span className="vcode">*</span>
                  <div className="form-group">
                    <select
                      name="merge"
                      onChange={this.myChangeHandler}
                      id=""
                      tabIndex={1}
                      className="form-control"
                    >
                      <option selected="true" disabled="disabled">
                        {" "}
                        No Selection{" "}
                      </option>
                      <option value="1">IMPORT TEMPLATE</option>
                      <option value="2">EXPORT TEMPLATE</option>
                    </select>
                  </div>
                </div>
                <div
                  className="col-xs-6 col-sm-6 col-md-2 margintop"
                  style={{
                    display: this.state.merge === "2" ? "block" : "none",
                  }}
                >
                  <label>Select Portal</label>
                  <AutoCompleteInput
                    id="portalid"
                    frmNm="FRMATTRIBUTEALIASE"
                    quryNm="FILLPORTAL"
                    db="IMAGEDB"
                    placeholder="Please Select Portal"
                    onAfterSelect={(e) =>
                      this.onAfterSelect(e, "portalid", "portalname")
                    }
                    isValid={this.state.isValid}
                  ></AutoCompleteInput>
                </div>
                <div
                  className="col-xs-6 col-sm-6 col-md-2 margintop"
                  style={{
                    display: this.state.merge === "1" ? "block" : "none",
                  }}
                >
                  <label>Select Block</label>
                  <AutoCompleteCascad
                    id="catid"
                    frmNm="FRMALLDATA"
                    quryNm="FILLORGBLOCKS"
                    db="IMAGEDB"
                    filter1="orgid"
                    filterId1={this.state.orgid}
                    filter2=""
                    filterId2=""
                    placeholder="Please Select Block"
                    onAfterSelect={(e) =>
                      this.onAfterSelect(e, "catid1", "catname1")
                    }
                    isValid={this.state.isValid}
                  ></AutoCompleteCascad>
                </div>
                <div
                  className="col-xs-6 col-sm-6 col-md-2 margintop"
                  style={{
                    display: this.state.merge === "1" ? "block" : "none",
                  }}
                >
                  <label>Select Import Sheet</label>
                  <AutoCompleteCascad
                    id="CTID"
                    frmNm="FRMIMPORTDATA"
                    quryNm="FILLCUSTOMSHEETALIAS"
                    db="IMAGEDB"
                    filter1="BlockID"
                    filterId1={this.state.catid1}
                    filter2="OrgID"
                    filterId2={this.state.orgid}
                    placeholder="Please Select Custom Template"
                    onAfterSelect={(e) =>
                      this.onAfterSelect(e, "ImportSheetId", "ImportSheetName")
                    }
                    isValid={this.state.isValid}
                  ></AutoCompleteCascad>
                </div>
                <div
                  className="col-xs-6 col-sm-6 col-md-2 margintop"
                  style={{
                    display: this.state.merge === "2" ? "block" : "none",
                  }}
                >
                  <label>Select Block</label>
                  <AutoCompleteCascad
                    id="catid"
                    frmNm="FRMALLDATA"
                    quryNm="FILLORGBLOCKS"
                    db="IMAGEDB"
                    filter1="orgid"
                    filterId1={this.state.orgid}
                    filter2=""
                    filterId2=""
                    placeholder="Please Select Block"
                    onAfterSelect={(e) =>
                      this.onAfterSelect(e, "catid1", "catname1")
                    }
                    isValid={this.state.isValid}
                  ></AutoCompleteCascad>
                </div>
                <div
                  className="col-xs-6 col-sm-6 col-md-2 margintop"
                  style={{
                    display: this.state.merge === "2" ? "block" : "none",
                  }}
                >
                  <label>Select Template</label>
                  <AutoCompleteCascad
                    id="catid"
                    frmNm="FRMUPDATEATTRIBUTEALIASING"
                    quryNm="FILLTEMPLATE"
                    db="IMAGEDB"
                    filter1="CatID"
                    filterId1={this.state.catid1}
                    filter2="PortalID"
                    filterId2={this.state.portalid}
                    placeholder="Please Select Template"
                    onAfterSelect={(e) =>
                      this.onAfterSelect(e, "tempId", "tempName")
                    }
                    isValid={this.state.isValid}
                  ></AutoCompleteCascad>
                </div>

                <div
                  className="col-xs-6 col-sm-6 col-md-2 margintop"
                  style={{
                    display: this.state.merge === "2" ? "block" : "none",
                  }}
                >
                  <label>Upload File</label>
                  <span className="vcode">
                    *
                    <input
                      type="checkbox"
                      onClick={this.OnlyPendingSel.bind(this)}
                      checked={this.state.OnlyPending === 1}
                      defaultChecked={this.state.OnlyPending === 1}
                      name="chkonlyPend"
                    ></input>
                    <label>Show Pending</label>
                  </span>
                  <div className="form-group">
                    <input
                      name="fldname"
                      type="file"
                      accept=".xls,.xlsx,.xlsm"
                      id="tp1"
                      onChange={this.myFileChangeHandler}
                      placeholder="Enter FieldName"
                      tabIndex={1}
                      className="form-control"
                    />
                  </div>
                </div>
                <div
                  className="col-xs-6 col-sm-6 col-md-1 margintop"
                  style={{
                    display: this.state.merge === "1" ? "block" : "none",
                  }}
                >
                  <label>&nbsp;</label>
                  <div className="form-group">
                    <button
                      onClick={this.GetDataForImportAliasing.bind(this)}
                      className="btn  btn-block btn-primary"
                      text=""
                    >
                      {" "}
                      GET DATA{" "}
                    </button>
                  </div>
                </div>
                <div
                  className="col-xs-6 col-sm-6 col-md-1 margintop"
                  style={{
                    display: this.state.merge === "2" ? "block" : "none",
                  }}
                >
                  <label>&nbsp;</label>
                  <span className="vcode">
                    <input
                      type="checkbox"
                      onClick={this.alwdMandatory.bind(this)}
                      checked={this.state.stsMandatory === 1}
                      defaultChecked={this.state.stsMandatory === 1}
                      name="chkonlymand"
                    ></input>
                    <label>Only Mandatory</label>
                  </span>
                  <div className="form-group">
                    <input
                      type="button"
                      value="Upload File"
                      onClick={this.UploadFile.bind(this)}
                      className="btn btn-block btn-primary"
                    />
                  </div>
                </div>
                {this.state.merge === "2" &&
                  this.state.selectedrows.length > 0 && (
                    <div className="col-xs-6 col-sm-6 col-md-1 margintop">
                      <label>&nbsp;</label>
                      <div className="form-group">
                        <input
                          type="button"
                          value="Update Aliasing"
                          onClick={this.UpdateAliasing.bind(this)}
                          className="btn btn-block btn-success"
                        />
                      </div>
                    </div>
                  )}
                {this.state.merge === "1" &&
                  this.state.selectedrows.length > 0 && (
                    <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                      <label>&nbsp;</label>
                      <div className="form-group">
                        <input
                          type="button"
                          value="Update Import Aliasing"
                          onClick={this.UpdateAliasingImport.bind(this)}
                          className="btn btn-block btn-success"
                        />
                      </div>
                    </div>
                  )}
                <div className="col-xs-4 col-sm-4 col-md-1 pull-right">
                  {this.state.aData.length > 0 && (
                    <span className="vcode">
                      <input
                        type="checkbox"
                        onClick={this.alwdreverseAliasing.bind(this)}
                        checked={this.state.stsdup === 1}
                        defaultChecked={this.state.stsdup === 1}
                        name="chkduplicate"
                      ></input>
                      <label>Reverse Aliasing</label>
                    </span>
                  )}
                  <br />
                  {this.state.stsdup === 1 && (
                    <span className="vcode">
                      <input
                        type="checkbox"
                        onClick={this.alwdOnlyAlisedAtrbte.bind(this)}
                        checked={this.state.alisedAtbte === 1}
                        defaultChecked={this.state.alisedAtbte === 1}
                        name="chkduplicate"
                      ></input>
                      <label>Alised Attribute</label>
                    </span>
                  )}
                  <div className="form-group">
                    {this.state.aData.length > 0 && (
                      <a
                        id="refrsh"
                        onClick={this.updateData.bind(this)}
                        style={{ color: "red" }}
                      >
                        <span class="fa fa-refresh">Refresh Data</span>
                      </a>
                    )}
                  </div>
                </div>
                {(this.state.orgid === 1 || this.state.loginId === "1") &&
                  this.state.selectedrows.length > 0 && (
                    <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                      <label>&nbsp;</label>
                      <div className="form-group">
                        <input
                          type="button"
                          value="Delete Aliasing"
                          onClick={this.DeleteAliasing.bind(this)}
                          className="btn btn-block btn-danger"
                        />
                      </div>
                    </div>
                  )}
                <div className="col-xs-12 col-sm-12 col-md-12 margintop">
                  {this.state.aData.length > 0 && (
                    <AntD
                      data={[...this.state.aData]}
                      exportXL="true"
                      Delete="true"
                      DeleteFn={(e) => this.deleteRow(e)}
                      EditBlock="true"
                      EditBlockFn={this.EditRow.bind(this)}
                      rvrs={this.state.stsdup}
                      tblname={this.state.tablename}
                      templteID={this.state.tempId}
                      selected="true"
                      SelectedFn={(e) => this.selectedDataFN(e)}
                    ></AntD>
                  )}
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 margintop">
                  {this.state.ImportData.length > 0 && (
                    <AntD
                      data={[...this.state.ImportData]}
                      exportXL="true"
                      Delete="true"
                      DeleteFn={(e) => this.deleteRowImport(e)}
                      EditBlockImport="true"
                      EditBlockFn={this.EditRowImport.bind(this)}
                      rvrs={this.state.stsdup}
                      templteID={this.state.ImportSheetId}
                      selected="true"
                      SelectedFn={(e) => this.selectedDataFN(e)}
                    ></AntD>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
