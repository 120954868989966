import { Modal } from "antd";
import React, { Component } from "react";
import "../ORDER/OrderMaster/style.css";
import {
  Configuration,
  getDataTable,
  MandatoryFormFields,
  UpdatePriceYoox,
  UpdatePrice,
  sleep,
  getNamshiJobDetails,
  json2xlsx,
  PriceAndDis,
  exceltodb,
  PriceUpdateAmazon,
} from "../Configuration";
import AutoCompleteCascad from "../Actions/Controls/AutoComplete/autoComplete.cascad";
import AutoCompletemulti from "../Actions/Controls/AutoComplete/autoCompleteMultiple";
import axios from "axios";
import Swal, { swal } from "sweetalert2/dist/sweetalert2.js";
import { Spin } from "antd";
import "sweetalert2/src/sweetalert2.scss";
import _, { bind } from "lodash";
const dataBase = "IMAGEDB";
let files = "";
var tb = "";
const NamshiCredStg = "772a0098-3579-11ec-a267-42010a6a0014";
const NamshiCredPrd = "b4eb442e-9a18-11ec-98ed-42010a590054";
const NamshiStgURL = "https://marketplace.namstg.net";
const NamshiPrdURL = "https://marketplace.namshi.net";
const token =
  "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJBUElVU0VSIiwiZXhwIjoxNzA2OTQ2MzE5LCJpYXQiOjE3MDYwODIzMTl9.1mifGZujfEMqC_yfqlPzBoVChWLP4qYsXTMebITl6PI";
const BaseUrl = "http://151.253.158.213:15100/ws/adidas-namshi/v1/item-price";

export default class frmDirectUploadExcel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal1Visible: false,
      modal2Visible: false,
      loading: false,
      BlockID: "",
      PortalID: "",
      CountryId: [],
    };
  }

  async componentDidMount() {
    let rs = await getDataTable(
      "select orgid from users where userid = " + Configuration.USER_ID
    );
    await this.setState({
      loginId: Configuration.USER_ID,
      orgid: rs[0].orgid,
    });
  }

  setModal1Visible(modal1Visible) {
    this.setState({ modal1Visible });
  }

  setModal2Visible(modal2Visible) {
    this.setState({ modal2Visible, adata: [] });
  }

  onAfterSelect(selectedOptions, id, name) {
    if (selectedOptions.length > 0) {
      this.setState({
        [id]: selectedOptions[0].DisplayId,
        [name]: selectedOptions[0].DisplayName,
      });
    } else {
      this.setState({
        [id]: "",
        [name]: "",
      });
    }
  }

  myFileChangeHandler = (event) => {
    files = event.target.files[0];
  };

  async onAutoCOmpletMultiPleSelect(selectedOptions, name) {
    if (selectedOptions.length > 0) {
      this.setState({
        [name]: selectedOptions,
      });
    } else {
      this.setState({
        [name]: [],
      });
    }
  }

  PostToNoonSFTP = async () => {
    let MandatoryArray = [
      { Category: this.state.BlockID },
      { Portal: this.state.PortalID },
      { file: files },
    ];
    let check = MandatoryFormFields(MandatoryArray);
    if (check === false) {
      return false;
    }
    this.setState({
      loading: true,
    });

    if (!files) {
      console.error("No file selected");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("file", files);

      const response = await fetch(
        "https://api.blumangoes.ai:6143/upload_sftp",
        {
          method: "POST",
          body: formData,
        }
      );

      const result = await response.json();
      console.log(result);
      if (result.status === "success") {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Uploaded To SFTP Successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        this.setState({
          loading: false,
        });
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Error uploading file",
          showConfirmButton: false,
          timer: 1500,
        });
        this.setState({
          loading: false,
        });
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
    this.setState({
      loading: false,
    });
  };

  async uploadFileYoox() {
    let MandatoryArray = [
      { Category: this.state.BlockID },
      { Portal: this.state.PortalID },
      { file: files },
    ];
    let check = MandatoryFormFields(MandatoryArray);
    if (check === false) {
      return false;
    }
    this.setState({
      loading: true,
    });
    debugger;
    const accessToken = "1f0179c4-6fe0-4f78-b805-184ac896abb0";
    const formData = new FormData();
    formData.append("file", files);

    try {
      const response = await UpdatePriceYoox(files, accessToken);
      console.log("Response:", response);

      if (response.import_id !== undefined) {
        // Assuming a valid import_id indicates success
        await Swal.fire({
          icon: "success",
          title: "File Posted Successfully.",
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        await Swal.fire({
          icon: "error",
          title: "SOME ERROR OCCURRED.",
          showConfirmButton: false,
          timer: 2500,
        });
      }

      this.setState({
        loading: false,
      });
    } catch (error) {
      console.error("Error uploading file:", error);

      await Swal.fire({
        icon: "error",
        title: "SOME ERROR OCCURRED.",
        showConfirmButton: false,
        timer: 2500,
      });

      this.setState({
        loading: false,
      });
    }
  }

  async UploadFileNamshi() {
    let countryData = this.state.CountryId.map((country) => country.DisplayId);
    this.setState({
      loading: true,
    });

    let MandatoryArray = [
      { portal: this.state.PortalID },
      { BLOCK: this.state.BlockID },
      { COUNTRY: this.state.CountryId },
      { File: files },
    ];

    let check = MandatoryFormFields(MandatoryArray);

    if (check == false) {
      this.setState({ loading: false });
      return false;
    }

    tb = "";
    tb = await exceltodb(files, dataBase, this.state.loginId);
    let res = await getDataTable("select * from " + tb);
    debugger;

    for (let i = 0; i < countryData.length; i++) {
      const rawData = res; // Assuming res is an array of objects from the stored procedure

      // Transform the data to create the payload
      const transformedData = rawData.map((item) => ({
        barcode: item.Barcode,
        price: item.Price,
        special_price: item.Special_Price,
      }));

      const payload = {
        items: transformedData,
      };

      console.log(payload);
      debugger;
      let finalRes = await UpdatePrice(
        payload,
        NamshiCredStg,
        NamshiStgURL,
        countryData[i]
      );
      console.log(finalRes);
      if (finalRes.status === "success") {
        await sleep(2000);
        this.setState({ loading: false });
        await Swal.fire({
          icon: "info",
          title:
            "DATA POST SUCCESSFULLY FOR " +
            countryData[i] +
            " PLEASE CHECK JOB EXCEL FOR MORE DETAILS",
          showConfirmButton: false,
          timer: 3500,
        });
        this.setState({ loading: true });
        let jobdt = await getNamshiJobDetails(
          finalRes.job_id,
          NamshiCredStg,
          NamshiStgURL
        );

        for (let k = 0; k < jobdt["details"]["items"].length; k++) {
          jobdt["details"]["items"][k]["job_id"] = finalRes.job_id;
          const item = jobdt["details"]["items"][k];
          let que =
            " EXEC SaveDataForPriceUpdate @sku = " +
            jobdt["details"]["items"][k].barcode +
            ", @mrp = " +
            jobdt["details"]["items"][k]["price"] +
            " , @sprice = " +
            jobdt["details"]["items"][k]["special_price"] +
            " , @status = " +
            jobdt["details"]["items"][k].status +
            " , @jobid = " +
            "'" +
            jobdt["details"]["items"][k]["job_id"] +
            "'" +
            " , @loginid = " +
            this.state.loginId +
            " , @country = " +
            jobdt["details"]["items"][k].country +
            " , @portalid = " +
            this.state.PortalID;
          await getDataTable(que);
        }

        debugger;
        await json2xlsx(jobdt["details"]["items"], "JOB STATUS");
        this.setState({ loading: false });
      } else {
        await Swal.fire({
          icon: "info",
          title: "DATA INVALID PLEASE CHECK EXCEL FOR MORE DETAILS",
          showConfirmButton: false,
          timer: 1500,
        });
        await json2xlsx(finalRes["details"], "JOB STATUS");
      }
      this.setState({ loading: false });
    }
    const uniqueArticles = new Set();

    // Extract distinct articles from the Excel data
    const uniqueProcessedData = res.filter((item) => {
      const article = String(item.Article).replace(/\\/g, "");
      if (!uniqueArticles.has(article)) {
        uniqueArticles.add(article);
        return true;
      }
      return false;
    });

    for (let i = 0; i < uniqueProcessedData.length; i++) {
      const item = uniqueProcessedData[i];
      const article = String(item.Article).replace(/\\/g, "");
      for (let i = 0; i < countryData.length; i++) {
        const country = countryData[i];
        const portalName = this.state.Pname;
        const payload = {
          style: article,
          mrsp: Number(item.Price).toFixed(0),
          discount: Number(item.Discount).toFixed(0),
          currency: "AED",
          channel: `${portalName}-${country}`,
        };
        this.setState({ loading: true });
        debugger;
        const PriceApi2 = await PriceAndDis([payload], token, BaseUrl);

        if (PriceApi2.messageStatus === "Success") {
          await sleep(2000);
          this.setState({ loading: false });
          await Swal.fire({
            icon: "info",
            title: "UPDATE PRICE SUCCESSFUL for " + country,
            showConfirmButton: false,
            timer: 3500,
          });

          const payloadDetails = {
            Article: payload.style,
            mrp: payload.mrsp,
            discount: payload.discount,
            country: country,
            status: PriceApi2.messageStatus,
            date: new Date().toLocaleDateString(),
          };

          let que1 =
            " EXEC SaveDataForRetailPriceAndDiscount @Article = '" +
            payloadDetails.Article +
            "', @PortalId = " +
            this.state.PortalID +
            " , @UpdatedMRP = " +
            payloadDetails.mrp +
            " , @Discount = " +
            payloadDetails.discount +
            " , @Country = " +
            "'" +
            payloadDetails.country +
            "'" +
            " , @Status = " +
            "'" +
            payloadDetails.status +
            "'" +
            " , @Loginid = " +
            this.state.loginId;
          await getDataTable(que1);
          let successfulUpdates = [];
          successfulUpdates.push(payloadDetails);
          await json2xlsx(successfulUpdates, "PRICE STATUS");
          this.setState({ loading: false });
          console.log(PriceApi2);
        } else {
          await Swal.fire({
            icon: "info",
            title: "DATA INVALID PLEASE CHECK EXCEL FOR MORE DETAILS",
            showConfirmButton: false,
            timer: 1500,
          });
          await json2xlsx(PriceApi2, "messageStatus");
          this.setState({ loading: false });
        }
      }
    }
  }

  handleOAuthButtonClick() {
    if (this.state.PortalID === 67) {
      window.location.href =
        "https://sellercentral.amazon.ae/apps/authorize/consent?application_id=amzn1.sp.solution.39fb100d-18e4-4915-b60c-edaab2bb6e28&state=stateexample&version=beta";
    } else {
      window.location.href =
        "https://api.flipkart.net/oauth-service/oauth/authorize?client_id=284a4602b2879b66aa287411491864214259&redirect_uri=https://app.blumangoes.ai/&response_type=code&scope=Seller_Api&state=stateexample";
    }
  }

  handleGenerateTokenButtonClick = async () => {
    this.setState({
      loading: true,
    });
    tb = await exceltodb(files, dataBase, this.state.loginId);
    let skuDataList = await getDataTable("select * from " + tb);
    console.log(skuDataList);
    debugger;
    const { orgID } = this.state;
    const urlParams = new URLSearchParams(window.location.search);
    const oauthCode = urlParams.get("spapi_oauth_code");
    const sellerId = urlParams.get("selling_partner_id");

    if (!oauthCode) {
      await Swal.fire({
        icon: "warning",
        title: "Authorization code is missing",
        showConfirmButton: false,
        timer: 2500,
      });
      this.setState({
        loading: false,
      });
      return;
    }

    try {
      const response = await axios.post(
        "https://api.amazon.com/auth/o2/token",
        {
          grant_type: "authorization_code",
          code: oauthCode,
          client_id:
            "amzn1.application-oa2-client.83799e097f8945cdbe3f4996a29dd6d0",
          client_secret:
            "amzn1.oa2-cs.v1.8a5bee8d25ef736ecf4296df56bfe810cc1776926ae4cf4fb0a8bbe6d137af49",
          redirect_uri: "https://app.blumangoes.ai",
        }
      );

      const accessToken = response.data.access_token;
      console.log("Access Token:", accessToken);

      await Swal.fire({
        icon: "success",
        title: "Access Token generated successfully!",
        showConfirmButton: false,
        timer: 2500,
      });

      const allResponses = [];

      for (const skuData of skuDataList) {
        const marketplaceId =
          this.state.PortalID === 20 ? "A21TJRUUN4KGV" : "A2VIGQ35RCS4UG";

        const apiUrl = `https://sandbox.sellingpartnerapi-eu.amazon.com/listings/2021-08-01/items/${sellerId}/${skuData.sku}`;
        console.log(skuData);
        let payload = {
          MarketplaceId: marketplaceId,
          Skus: [skuData.sku],
          ItemType: "Sku",
          ItemCondition: "NEW",
          Prices: [
            {
              price: {
                ListingPrice: {
                  amount: skuData.price,
                  currencyCode: "AED",
                },
              },
            },
          ],
        };

        console.log(payload);
        try {
          let createListingApi = await PriceUpdateAmazon(
            payload,
            accessToken,
            apiUrl
          );
          allResponses.push({ skuData, response: createListingApi });
        } catch (error) {
          console.error("Error creating listing:", error);
          allResponses.push({
            skuData,
            error: "Failed to create listing. Please try again.",
          });
        }
      }

      const successResponses = allResponses.filter(
        (entry) =>
          entry.response &&
          (entry.response.status === "ACCEPTED" || entry.response.code === 200)
      );

      if (successResponses.length === skuDataList.length) {
        await sleep(2000);
        await Swal.fire({
          icon: "info",
          title:
            "ALL DATA POSTED SUCCESSFULLY. PLEASE CHECK EXCEL FOR MORE DETAILS",
          showConfirmButton: false,
          timer: 3500,
        });
      } else {
        await Swal.fire({
          icon: "error",
          title:
            "SOME DATA FAILED TO POST. PLEASE CHECK EXCEL FOR MORE DETAILS",
          showConfirmButton: false,
          timer: 3500,
        });
      }

      console.log("All Responses:", allResponses);
      await json2xlsx(allResponses, "JOB STATUS");
      this.setState({ loading: false });
    } catch (error) {
      console.error("Error generating access token:", error);

      await Swal.fire({
        icon: "error",
        title: "Failed to generate access token. Please try again.",
        showConfirmButton: false,
        timer: 2500,
      });
      this.setState({
        loading: false,
      });
    }
  };

  render() {
    return (
      <div>
        <button
          type="button"
          onClick={() => this.setModal2Visible(true)}
          className="btn btn-primary"
        >
          Direct Post To API
        </button>
        <Modal
          title="Direct Post To API"
          width={this.props.width}
          visible={this.state.modal2Visible}
          onOk={() => this.setModal2Visible(false)}
          onCancel={() => this.setModal2Visible(false)}
          destroyOnClose={true}
          maskClosable={false}
          footer={null}
        >
          <div
            className="box-body"
            id="orderDetails"
            style={{ display: "block" }}
          >
            <div
              className="loader"
              style={{ display: this.state.loading ? "block" : "none" }}
            >
              <div className="loader-item">
                <Spin />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                <label>SELECT BLOCK</label>
                <span className="vcode">*</span>
                <AutoCompleteCascad
                  id="id"
                  frmNm="FRMALLDATA"
                  quryNm="FILLORGBLOCKS"
                  db="IMAGEDB"
                  filter1="orgid"
                  filterId1={this.state.orgid}
                  filter2=""
                  filterId2=""
                  placeholder="Please Select Block"
                  onAfterSelect={(e) =>
                    this.onAfterSelect(e, "BlockID", "BlockName")
                  }
                ></AutoCompleteCascad>
              </div>
              <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                <label>SELECT PORTAL</label>
                <span className="vcode">*</span>
                <AutoCompleteCascad
                  id="portalID"
                  frmNm="FRMATTRIBUTEALIASE"
                  quryNm="FILLPORTAL"
                  db="IMAGEDB"
                  filter2="BlockID"
                  filterId2={this.state.BlockID}
                  filter1="orgid"
                  filterId1={this.state.orgid}
                  placeholder="Please Select Portal"
                  onAfterSelect={(e) =>
                    this.onAfterSelect(e, "PortalID", "Pname")
                  }
                  isValid={this.state.isValid}
                ></AutoCompleteCascad>
              </div>
              <div className="col-xs-6 col-sm-6 col-md-3 margintop">
                <label>UPLOAD FILE</label>
                <span className="vcode">*</span>
                <div className="form-group">
                  <input
                    name="fldname"
                    type="file"
                    accept=".xls,.xlsx"
                    id="tp1"
                    onChange={this.myFileChangeHandler}
                    placeholder="Enter FieldName"
                    tabIndex={1}
                    className="form-control"
                  />
                </div>
              </div>
              {this.state.PortalID === 52 && (
                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                  <label>&nbsp;</label>
                  <label>SELECT COUNTRY</label>
                  <span className="vcode">*</span>
                  <AutoCompletemulti
                    id="PID"
                    frmNm="FRMPRICEUPDATE"
                    quryNm="FILLCOUNTRY"
                    db="IMAGEDB"
                    placeholder="Please Select Country"
                    onAfterSelect={(e) =>
                      this.onAutoCOmpletMultiPleSelect(e, "CountryId")
                    }
                    isValid={this.state.isValid}
                  ></AutoCompletemulti>
                </div>
              )}
              {this.state.PortalID === 64 && (
                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                  <label></label>
                  <span className="vcode"></span>
                  <div className="form-group">
                    <input
                      type="button"
                      value="POST TO NOON"
                      onClick={this.PostToNoonSFTP.bind(this)}
                      className="btn btn-block btn-primary"
                    />
                  </div>
                </div>
              )}
              {this.state.PortalID === 71 && (
                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                  <label></label>
                  <span className="vcode"></span>
                  <div className="form-group">
                    <input
                      type="button"
                      value="POST TO YOOX"
                      onClick={this.uploadFileYoox.bind(this)}
                      className="btn btn-block btn-primary"
                    />
                  </div>
                </div>
              )}
              {this.state.PortalID === 52 && (
                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                  <label></label>
                  <span className="vcode"></span>
                  <div className="form-group">
                    <input
                      type="button"
                      value="POST TO NAMSHI"
                      onClick={this.UploadFileNamshi.bind(this)}
                      className="btn btn-block btn-primary"
                    />
                  </div>
                </div>
              )}
              {this.state.PortalID === 67 && (
                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                  <label></label>
                  <span className="vcode"></span>
                  <div className="form-group">
                    <input
                      type="button"
                      value="AUTHORIZE"
                      onClick={this.handleOAuthButtonClick.bind(this)}
                      className="btn btn-block btn-primary"
                    />
                  </div>
                </div>
              )}
              {this.state.PortalID === 67 && (
                <div className="col-xs-6 col-sm-6 col-md-3 margintop">
                  <label></label>
                  <span className="vcode"></span>
                  <div className="form-group">
                    <input
                      type="button"
                      value="POST TO AMAZON"
                      onClick={this.handleGenerateTokenButtonClick.bind(this)}
                      className="btn btn-block btn-primary"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
