 
import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getToken } from './Configuration';
 
import SideMenu from "./SideMenu";
import Header from "./Header";
import Menu from "./Menu";
import Footer from "./Footer";
import { getAuthorization } from "./Configuration";
import CryptoService from './Config/action/crypto';
// handle the private routes
function AdminRoute({ component: Component, ...rest }) {

  const [activeNotification, setActiveNotification] = useState(false);

  return (
    <Route
      {...rest}
      render={(props) => CryptoService.decrypt(getAuthorization()).isAdmin ? <div><Menu></Menu><SideMenu></SideMenu><Component setActiveNotification={setActiveNotification} {...props} /><Header activeNotification={activeNotification} setActiveNotification={setActiveNotification}></Header></div>: <Redirect to={{ pathname: '/dashboard' }} />}
    />
  )
}
 
export default AdminRoute;