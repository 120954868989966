import React, { Component } from 'react';

class DashboardSearch extends Component {
    render() {
        return (
            <div>
                     
              {/* DIRECT CHAT */}
              <div className="box box-success ">
                <div className="box-header with-border">
                  <h3 className="box-title">Direct Search</h3>
                  <div className="box-tools pull-right">
                    <button type="button" className="btn btn-box-tool" data-widget="collapse"><i className="fa fa-minus" />
                    </button>
                    <button type="button" className="btn btn-box-tool" data-widget="remove"><i className="fa fa-times" />
                    </button>
                  </div>
                </div>
                {/* /.box-header */}
                <div className="box-body">

                <form action="#" method="post">
                 {/* Order Search */}
                 <span className="label text-red">Order Search</span>
                    <div className="input-group">
                      <input type="text" name="message" placeholder="Enter Order No." className="form-control" />
                      <span className="input-group-btn">
                        <button type="button" className="btn btn-primary btn-flat">Search</button>
                      </span>
                    </div>

                    <span className="label text-red">PO Search</span>
                    <div className="input-group">
                      <input type="text" name="message" placeholder="Enter PO No." className="form-control" />
                      <span className="input-group-btn">
                        <button type="button" className="btn btn-primary btn-flat">Search</button>
                      </span>
                    </div>
                    
                    <span className="label text-red">GRN Search</span>
                    <div className="input-group">
                      <input type="text" name="message" placeholder="Enter Grn No." className="form-control" />
                      <span className="input-group-btn">
                        <button type="button" className="btn btn-primary btn-flat">Search</button>
                      </span>
                    </div>

                    <span className="label text-red">SRN Search</span>
                    <div className="input-group">
                      <input type="text" name="message" placeholder="Enter SRN No." className="form-control" />
                      <span className="input-group-btn">
                        <button type="button" className="btn btn-primary btn-flat">Search</button>
                      </span>
                    </div>
                  </form>

                </div>
                {/* /.box-body */}
                <div className="box-footer">




                </div>
                {/* /.box-footer*/}
              </div>
              {/*/.direct-chat */}
         
            {/* /.col */}
            
            </div>
        );
    }
}

export default DashboardSearch;