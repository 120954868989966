import React, { Component } from 'react';
import axios from "axios";
import { Boolean } from 'core-js';
import { Spin } from 'antd';
import {
  Configuration,
  sleep,
  getDataTable,
  MandatoryFormFields,
  ProductVisiblity,
  getNamshiJobDetails,
  json2xlsx,
} from "../Configuration";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import AutoCompleteCascad from '../Actions/Controls/AutoComplete/autoComplete.cascad'
const cred = 'b4eb442e-9a18-11ec-98ed-42010a590054'
const NamshiPrdURL = "https://marketplace.namshi.net"


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orgID: 0,
      portalID: 0,
      articleList: [],
      selectedCountryIDs: [],
      searchQuery: '',
      selectedCountryData: [],
      selectedCountries: [],
      markingActInct: '',
      loading: false
    };
  }

  async componentDidMount() {
    let rs = await getDataTable("select orgid from users where userid = " + Configuration.USER_ID)
    await this.setState({
      loginId: Configuration.USER_ID,
      orgID: rs[0].orgid
    });
  }


  myChangeHandler = (event) => {

    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ [nam]: val });

    this.forceUpdate();
  }

  handleRefreshSelectedCheckboxes = () => {
    this.setState({
      selectedCountries: [],
      searchArticle: '',
    });
  };

  onAfterSelect(selectedOptions, id, name) {
    if (selectedOptions.length > 0) {
      this.setState({
        [id]: selectedOptions[0].DisplayId,
        [name]: selectedOptions[0].DisplayName,
        articleList: []
      })
    }
    else {
      this.setState({
        [id]: '',
        [name]: '',
        articleList: []
      })
    }
  }

  async getdata() {
    let MandatoryArray = [
      { "Portal": this.state.portalID },
      { "Mark Active Or InActive": this.state.markingActInct }
    ];

    let check = MandatoryFormFields(MandatoryArray);

    if (check == false) {
      return false;
    }

    this.setState({ loading: true });

    let que = "Exec ShowArticleForMultiCountry @loginid = " + this.state.loginId + ", @portalid = " + this.state.portalID;
    let dt = await getDataTable(que);

    if (dt.length > 0) {
      this.setState({
        articleList: dt,
        country: dt[0].Country,
        selectedCountries: [],
        loading: false
      }, () => {
        // Call the renderFileInTable function to refresh the table
        this.renderFileInTable();
      });
    } else {
      this.setState({ loading: false });
      Swal.fire({
        icon: 'info',
        title: 'NO DATA FOUND',
        showConfirmButton: false,
        timer: 1500
      });
      return;
    }
  }

  renderFileInTable() {
    const { articleList, searchQuery, selectedCountries } = this.state;
    const countries = Object.keys(articleList[0] || {});

    // Filter the articleList based on the search query
    const filteredArticleList = articleList.filter(value =>
      value.Article.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
      <div>
        <label>Select Country For Push</label>
        <div className="table-responsive" style={{ maxHeight: '63vh' }}>
          <table id="rptTable" className="table table-hover">
            <thead style={{ position: "sticky" }}>
              <tr>
                <th>SR NO.</th>
                {countries.map((country, index) => (
                  <th key={index}>{country}</th>
                ))}
              </tr>
              <tr>
                <th></th>
                {countries.map((country, index) => (
                  <th key={index} className={index === 0 ? "article-column" : ""}>
                    {index === 0 && (
                      <div className="form-group">
                        <label>Search Article:</label>
                        <input
                          type="text"
                          className="form-control"
                          value={searchQuery}
                          onChange={this.handleSearchChange}
                        />
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredArticleList.map((value, rowIndex) => {
                const selectedArticle = selectedCountries.find(
                  item => item.Article === value.Article
                );

                const originalRowIndex = articleList.findIndex(
                  item => item.Article === value.Article
                );

                return (
                  <tr key={originalRowIndex}>
                    <td>{originalRowIndex + 1}</td>
                    {countries.map((country, colIndex) => {
                      const isChecked = selectedArticle && selectedArticle.countries.includes(country);

                      return (
                        <td
                          key={colIndex}
                          className={colIndex === 0 ? "article-column" : ""}
                        >
                          {colIndex === 0 ? (
                            <span>{value.Article}</span>
                          ) : (
                            <input
                              type="checkbox"
                              value={country}
                              checked={isChecked}
                              onChange={() => this.handleCheckboxChange(originalRowIndex, country)}
                            />
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className='form-group' style={{ textAlign: "center" }}>
          <button className='btn btn-primary' onClick={this.handleSubmit.bind(this)}>SUBMIT</button>
        </div>
      </div>
    );
  }

  handleSearchChange = event => {
    const searchQuery = event.target.value;
    this.setState({ searchQuery });
  }


  handleCheckboxChange = (rowIndex, country) => {
    this.setState(prevState => {
      const { articleList, selectedCountries } = prevState;
      const selectedArticle = articleList[rowIndex].Article;

      // Check if the selected article already exists in selectedCountries state
      const existingSelectedArticle = selectedCountries.find(item => item.Article === selectedArticle);

      if (existingSelectedArticle) {
        // If the selected article exists, update its selected countries
        const updatedSelectedCountries = existingSelectedArticle.countries.includes(country)
          ? existingSelectedArticle.countries.filter(c => c !== country)
          : [...existingSelectedArticle.countries, country];

        let updatedSelectedArticle;

        if (updatedSelectedCountries.length === 0) {
          // If all countries are unchecked, remove the article from selectedCountries
          updatedSelectedArticle = null;
        } else {
          updatedSelectedArticle = {
            Article: selectedArticle,
            countries: updatedSelectedCountries
          };
        }

        // Update the selectedCountries state with the updated selected article
        const updatedSelectedCountryList = selectedCountries.map(item =>
          item.Article === selectedArticle ? updatedSelectedArticle : item
        ).filter(Boolean); // Remove null entries

        return { selectedCountries: updatedSelectedCountryList };
      } else {
        // If the selected article doesn't exist, create a new entry in selectedCountries state
        const newSelectedArticle = {
          Article: selectedArticle,
          countries: [country]
        };

        const updatedSelectedCountryList = [...selectedCountries, newSelectedArticle];

        return { selectedCountries: updatedSelectedCountryList };
      }
    }, () => {
      // Callback function to handle article removal after state update
      this.removeEmptyArticles();
    });
  };

  removeEmptyArticles = () => {
    this.setState(prevState => {
      const { selectedCountries, articleList } = prevState;

      // Remove articles from selectedCountries that don't have any selected countries
      const updatedSelectedCountries = selectedCountries.filter(item => {
        const articleExists = articleList.some(article => article.Article === item.Article);
        return articleExists && item.countries.length > 0;
      });

      return { selectedCountries: updatedSelectedCountries };
    });
  };



  async handleSubmit() {
    this.setState({
      loading: true,
    });

    let { selectedCountries, markingActInct } = this.state;

    // Create the data object in the desired format
    let dataToSend = {
      items: selectedCountries.map((selectedCountry) => ({
        product_id: selectedCountry.Article,
        country: selectedCountry.countries,
        is_active: markingActInct ? 1 : 0,
      })),
    };

    // Do something with the dataToSend
    console.log("Data to send:", dataToSend);

    try {
      const response = await axios.post(
        "https://api.blumangoes.ai:6143/ProductVisiblity",
        dataToSend,
        {
          headers: {
            cred: cred,
            "Content-Type": "application/json",
          },
        }
      );

      const finalRes = response.data;

      if (finalRes.status === "success") {
        await sleep(2000);
        this.setState({ loading: false });
        await Swal.fire({
          icon: "info",
          title: "DATA POST SUCCESSFULLY PLEASE CHECK JOB EXCEL FOR MORE DETAILS",
          showConfirmButton: false,
          timer: 3500,
        });
        this.setState({ loading: true });
        let jobdt = await getNamshiJobDetails(finalRes.job_id, cred, NamshiPrdURL);

        for (let k = 0; k < jobdt["details"]["items"].length; k++) {
          jobdt["details"]["items"][k]["job_id"] = finalRes.job_id;
          const item = jobdt["details"]["items"][k];
          const product_id = item.product_id;
          const countries = item.country;
          const is_active = item.is_active;
          item.country = countries.join(", ");
          for (let i = 0; i < countries.length; i++) {
            const country = countries[i];
            console.log(country);
            let que = 'EXEC SaveMultiCountryData @artlive = ' + jobdt['details']['items'][k].product_id + ' , @jobid =  ' + "'" + jobdt['details']['items'][k]['job_id'] + "'" + ', @bidc =  ' + "'" + jobdt['details']['items'][k].bidc + "'" + ', @status  = ' + jobdt['details']['items'][k].status + ', @loginid = ' + this.state.loginId + ' , @isactive = ' + this.state.markingActInct + ' , @country = ' + country + ' , @portalid = ' + this.state.portalID
            await getDataTable(que)
          }
        }
        debugger;
        await json2xlsx(jobdt["details"]["items"], "JOB STATUS");
        this.setState({ selectedCountries: [], searchQuery: "", showSearchBox: false, loading: false }, () => {
          // Call the getdata function to fetch the updated data and render the table
          this.getdata();
        });
      } else {
        await Swal.fire({
          icon: "info",
          title: "DATA INVALID PLEASE CHECK EXCEL FOR MORE DETAILS",
          showConfirmButton: false,
          timer: 1500,
        });
        await json2xlsx(finalRes["details"], "JOB STATUS");
      } this.setState({ loading: false });
    } catch (error) {
      console.error("Error occurred while making the API request.", error);
    }
  }



  render() {
    return (
      <div className="content-wrapper">
        <div className='loader' style={{ display: this.state.loading ? "block" : "none" }}>
          <div className='loader-item'>
            <Spin />
          </div>
        </div>
        <section className="content">
          <div id="ctl00_CPHMaincontent_divclient" className="box box-success" style={{ marginTop: '-13px' }}>
            <div className="box-header with-border">
              <h3 className="box-title">Multi Country Article Pushing
                <span id="ctl00_CPHMaincontent_DivOrdNo" style={{ display: 'inline' }}>
                </span>
              </h3>
            </div>
            <div className='col-xs-4 col-sm-4 col-md-1 pull-right'>
              {/* {this.state.aData.length > 0 &&<span className='vcode'><input type='checkbox' onClick={this.alwdreverseAliasing.bind(this)} checked={this.state.stsdup === 1} defaultChecked={this.state.stsdup === 1} name='chkduplicate'></input><label>Reverse Aliasing</label></span>} */}
              <div className='form-group'>
                {this.state.articleList.length > 0 && <a id="refrsh" onClick={this.handleRefreshSelectedCheckboxes} style={{ color: 'red', cursor: 'pointer' }}>
                  <span className="fa fa-refresh" /> Refresh Data
                </a>
                }
              </div>
            </div>
            <div className="box-body" id="orderDetails" style={{ display: 'block' }}>
              <div className="row">
                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                  <label>Select Portal</label><span className='vcode'>*</span>
                  <AutoCompleteCascad
                    id="portalID"
                    frmNm="FRMATTRIBUTEALIASE"
                    quryNm="FILLPORTAL"
                    db="IMAGEDB"
                    filter2=""
                    filterId2=""
                    filter1="orgid"
                    filterId1={this.state.orgID}
                    placeholder="Please Select Portal"
                    onAfterSelect={(e) => this.onAfterSelect(e, "portalID", "Pname")}
                  ></AutoCompleteCascad>
                </div>
                <div className="col-xs-4 col-sm-4 col-md-2 margintop">
                  <label>Mark Active Or InActive</label><span className='vcode'>*</span>
                  <div className='form-group'>
                    <select className='form-control' onChange={this.myChangeHandler} name='markingActInct'>
                      <option value='Please Select'>Please Select</option>
                      <option value='1'>Mark Active</option>
                      <option value='0'>Mark InActive</option>
                    </select>
                  </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-2 margintop" >
                  <label>&nbsp;</label>
                  <div className='form-group'>
                    <button className='btn btn-block btn-primary' onClick={this.getdata.bind(this)}>Get</button>
                  </div>
                </div>
                <div className='row'>
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    {this.state.articleList.length > 0 && this.renderFileInTable()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default App;
