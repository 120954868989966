// fetchUSERS.js

// import {fetchUSERSPending, fetchUSERSSuccess, fetchUSERSError} from './index';

// function fetchUSERS() {
//     return dispatch => {
//         dispatch(fetchUSERSPending());
//         fetch('https://jsonplaceholder.typicode.com/todos/1')
//         .then(res => res.json())
//         .then(res => {
//             if(res.error) {
//                 throw(res.error);
//             }
//             dispatch(fetchUSERSSuccess(res.userId);
//             return res.userId;
//         })
//         .catch(error => {
//             dispatch(fetchUSERSError(error));
//         })
//     }
// }

export const helloRedux=()=>(dispatch:any)=>{
alert("You Are set to Global Functions Using Redux")
}

// function requestFetchItem(idItem) {
//     return {
//       type: FETCH_ITEM_REQUEST,
//       isFetching: true,
//       isAuthenticated: false,
//       idItem
//     }
//   }
//export default fetchUSERS;
