import React, {Component, Fragment} from 'react';
import InvoiceDetail from './invoiceDetail';

class Invoice extends Component{
    render(){
        return(
            <Fragment>
                <div style={{backgroundColor:'#f4f6f9', minHeight: '100vh'}}>
                    <InvoiceDetail />
                </div>
            </Fragment>
        )
    }
}

export default Invoice;