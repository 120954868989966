import React, { Component } from "react";
import {
  Configuration,
  getDataTable,
  MandatoryFormFields,
} from "../Configuration";
import moment from "moment";
import AutoCompleteInput from "../Actions/Controls/AutoComplete/autoComplete";
import Swal from "sweetalert2/dist/sweetalert2.js";
import AntdTableResize from "../Actions/Controls/Table/AntDTable";
import "sweetalert2/src/sweetalert2.scss";
import { Spin } from "antd";
import "antd/dist/antd.css";
const StartDate = moment().startOf("month").format("yyyy-MM-DD");
const LastDate = moment().endOf("month").format("yyyy-MM-DD");
export default class frmReportAdhoc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginId: 0,
      loading: false,
      rptData: [],
      resultData: [],
      rptname: "",
      rptid: "",
      p: 0,
      paramData: [],
    };
  }
  async componentDidMount() {
    let rs = await getDataTable(
      "select orgid from users where userid = " + Configuration.USER_ID
    );
    await this.setState({
      loginId: Configuration.USER_ID,
      orgid: rs[0].orgid,
    });
    await this.getReport();
  }
  async getReport() {
    let arr = ["1", "6", "10"];
    let Que = "";
    if (this.state.orgid === 1 || arr.includes(this.state.loginId)) {
      Que =
        "Select RWID,ReportName,Que from ReportAdhoc where Que is not null and Dis = 0 order by RWID";
    } else {
      // Que = "Select RWID,ReportName,Que from ReportAdhoc where Que is not null and Dis = 0 and forUser = 1 order by RWID"
      Que =
        " SELECT    ReportAdhoc.RWID, ReportAdhoc.ReportName, ReportAdhoc.Que ";
      Que +=
        " FROM  ReportAdhoc INNER JOIN ReportUserRights ON ReportAdhoc.RWID = ReportUserRights.RWID ";
      Que +=
        " WHERE  (ReportUserRights.USERID = " +
        this.state.loginId +
        ") AND (ReportAdhoc.Dis = 0) ";
    }
    // console.log(Que);
    let rs = await getDataTable(Que);
    this.setState({ rptData: rs });
  }
  onAfterSelect(selectedOptions, name, id) {
    if (selectedOptions.length > 0 && name !== "") {
      this.setState({
        [id]: selectedOptions[0].DisplayId,
        [name]: selectedOptions[0].DisplayName,
      });
    }
    if (selectedOptions.length > 0 && name === "") {
      this.setState({
        [id]: selectedOptions[0].DisplayId,
      });
    } else {
      if (selectedOptions.length > 0 && name !== "") {
        this.setState({
          [id]: "",
          [name]: "",
        });
      }
      if (selectedOptions.length > 0 && name === "") {
        this.setState({
          [id]: "",
        });
      }
    }
  }
  myChangeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  async GetReport(e, f) {
    var grid = document.getElementById("filetable");
    var inputs = grid.getElementsByTagName("input");
    for (var i = 0; i < inputs.length; i++) {
      if (inputs[i].type === "checkbox") {
        if (f.target.checked && inputs[i] !== f.target && inputs[i].checked) {
          inputs[i].checked = false;
        }
      }
    }

    this.setState({
      resultData: [],
      p: 0,
      rptname: e.ReportName,
      rptid: e.RWID,
      loading: true,
    });
    if (f.target.checked) {
      let query = "";
      query +=
        " SELECT  P.name AS [ParameterName] ,TYPE_NAME(P.user_type_id) AS [ParameterDataType]";
      query += " FROM sys.objects AS SO";
      query += " INNER JOIN sys.parameters AS P ON SO.OBJECT_ID = P.OBJECT_ID";
      query += " where SO.name = '" + e.Que + "'";
      query += " ORDER BY   P.parameter_id";
      let rs = await getDataTable(query);
      if (rs.length > 1) {
        this.setState({ d: 0, p: 1, spName: e.Que, loading: false });
        this.getReportParams(e.Que);
      } else {
        let query1 = "EXEC " + e.Que + " @loginID = " + this.state.loginId;
        let rs1 = await getDataTable(query1);
        if (rs1.length > 0) {
          this.setState({
            d: 1,
            p: 0,
            rptname: e.ReportName,
            rptid: e.RWID,
            resultData: rs1,
            loading: false,
          });
        } else {
          Swal.fire({
            icon: "info",
            title: "NO DATA FOUND",
            showConfirmButton: false,
            timer: 1500,
          });
          this.setState({ loading: false });
          return false;
        }
      }
    } else {
      this.setState({ resultData: [], p: 0, loading: false, rptname: "" });
      return;
    }
  }

  hideShowDiv(id) {
    if (document.getElementById("fstdiv").style.display === "block") {
      document.getElementById("fstdiv").style.display = "none";
      document.getElementById("snddiv").className =
        "col-sm-12 col-xs-12 col-md-12 margintop";
      document.getElementById("arrowicon").className =
        "fa fa-angle-double-right";
    } else {
      document.getElementById("fstdiv").style.display = "block";
      document.getElementById("snddiv").className =
        "col-sm-12 col-xs-12 col-md-9 margintop";
      document.getElementById("arrowicon").className =
        "fa fa-angle-double-left";
    }
  }

  renderFileInTable() {
    return (
      <div>
        <div className="table-responsive" style={{ maxHeight: "78vh" }}>
          <table id="filetable" className="table table-hover">
            <thead style={{ position: "sticky" }}>
              <tr>
                {" "}
                <th>SR NO.</th>
                <th>Report No.</th>
                <th>REPORTNAME</th>
              </tr>
            </thead>
            <tbody>
              {this.state.rptData.map((value, index) => {
                return (
                  <tr key={index}>
                    <td>
                      {index + 1}&nbsp;
                      <input
                        type="checkbox"
                        id={value.RWID}
                        onChange={this.GetReport.bind(this, value)}
                      ></input>
                    </td>
                    <td>{"R-" + value.RWID}</td>
                    <td>{value.ReportName.toUpperCase()}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
  myFunction(e) {
    var filter, table, tr, i, txtValue;

    filter = e.target.value.toUpperCase();
    table = document.getElementById("filetable");
    tr = table.getElementsByTagName("tr");
    for (i = 1; i < tr.length; i++) {
      let tds = tr[i].getElementsByTagName("td");
      var matches = false;

      for (let j = 0; j < tds.length; j++) {
        if (tds[j]) {
          txtValue = tds[j].textContent || tds[j].innerText;
          if (txtValue.toUpperCase().indexOf(filter) > -1) {
            matches = true;
          }
        }
      }

      if (matches === true) {
        tr[i].style.display = "";
      } else {
        tr[i].style.display = "none";
      }
    }
  }
  render() {
    return (
      <div className="content-wrapper">
        <div
          className="loader"
          style={{ display: this.state.loading ? "block" : "none" }}
        >
          <div className="loader-item">
            <Spin size="large" />
          </div>
        </div>
        <section
          id="FormName"
          className="content-header"
          style={{ marginTop: "-12px" }}
        ></section>
        <section className="content">
          <div
            id="divEntryBalancer"
            className="box box-success"
            style={{ marginTop: "-13px" }}
          >
            <div className="box-header with-border">
              <h3
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  lineHeight: "1.1",
                  color: "inherit",
                  padding: "2px",
                }}
              >
                REPORT
                <i
                  id="arrowicon"
                  onClick={this.hideShowDiv.bind(this)}
                  className="fa fa-angle-double-left"
                ></i>
              </h3>
            </div>
            <div
              className="box-body"
              id="GrnDetailList"
              style={{ display: "block" }}
            >
              <div className="row">
                <div
                  className="col-sm-12 col-xs-12 col-md-3 margintop"
                  id="fstdiv"
                  style={{ display: "block" }}
                >
                  <div className="col-xs-12 col-sm-12 col-md-12 margintop">
                    <div className="form-group">
                      <input
                        name="prdName"
                        id="prdName"
                        onKeyUp={this.myFunction.bind(this)}
                        autoComplete="off"
                        placeholder="Search Report"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-12 margintop">
                    {this.state.rptData.length > 0 && this.renderFileInTable()}
                  </div>
                </div>
                <div
                  className="col-sm-12 col-xs-12 col-md-9 margintop"
                  id="snddiv"
                >
                  <label>{this.state.rptname}</label>
                  {this.state.p === 1 && this.renderParaMeterView()}
                  {this.state.p === 1 && (
                    <div className="box-tools margintop">
                      <label>&nbsp;</label>
                      <div className="form-group">
                        <input
                          id="fetchReportData"
                          className={"btn btn-primary"}
                          type="button"
                          defaultValue="GET DATA"
                          onClick={(e) => this.getReportData(e)}
                        />
                      </div>
                    </div>
                  )}
                  {this.state.d === 1 && this.state.resultData.length > 0 && (
                    <AntdTableResize
                      data={this.state.resultData}
                      exportXL="true"
                      XLName={this.state.rptname}
                      XLNo={this.state.rptid}
                    ></AntdTableResize>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
  async getReportParams(spName) {
    let query = "";
    query +=
      " SELECT  P.name AS [ParameterName] ,TYPE_NAME(P.user_type_id) AS [ParameterDataType]";
    query += " FROM sys.objects AS SO";
    query += " INNER JOIN sys.parameters AS P ON SO.OBJECT_ID = P.OBJECT_ID";
    query += " where SO.name = '" + spName + "'";
    query += " ORDER BY   P.parameter_id";
    // console.log(query);

    let array = [];
    array = await getDataTable(query);
    for (let index = 0; index < array.length; index++) {
      array[index]["ParamDetails"] = await this.checkParamFileds(
        array[index]["ParameterName"]
      );
    }
    this.setState({
      paramData: array,
    });
  }
  async checkParamFileds(param) {
    let query = "";
    query +=
      " SELECT ReportParameters.ParameterId, ReportParameters.ParameterName";
    query +=
      " , ReportParameters.UserEdit, ReportParameters.CmbFillQueryId, ReportParameters.ParaMeterDataType";
    query +=
      " ,   ReportParameters.ReportColName, ReportParameters.Multiple, M_FillCmbQuery.FormName";
    query +=
      " , M_FillCmbQuery.QueryName FROM            ReportParameters LEFT OUTER JOIN";
    query +=
      " M_FillCmbQuery ON ReportParameters.CmbFillQueryId = M_FillCmbQuery.cmbqueryId";
    query += " WHERE   ReportParameters.ParameterName = '" + param + "'";
    let array1 = [];
    array1 = await getDataTable(query);
    if (array1.length > 0) {
      return array1[0];
    } else {
      return {};
      // this.setState({ loading: true, p: 0 })
      // let query = "";
      // query = "exec " + this.state.spName;
      // let array = [];
      // array = await getDataTable(query)
      // if (array.length > 0) {
      //   this.setState({
      //     resultData: array, d: 1, p: 0, loading: false
      //   });
      // }
      // else {
      //   Swal.fire({
      //     icon: 'info',
      //     title: "NO DATA FOUND",
      //     showConfirmButton: false,
      //     timer: 1500
      //   })
      //   this.setState({ loading: false });
      //   return false;
      // }
    }
  }
  renderParaMeterView() {
    let colClass =
      this.state.paramData.length > 2
        ? "col-xs-2 col-sm-2 col-md-2 margintop"
        : "col-xs-4 col-sm-4 col-md-4 margintop";
    return this.state.paramData.map((param, index) => {
      let paramFields = param.ParamDetails;
      let date = "";
      if (!paramFields.ParaMeterDataType) {
        return false;
      }
      if (paramFields.ParaMeterDataType == "DATE") {
        if (paramFields.ReportColName.includes("FROM")) {
          date = StartDate;
        }
        if (paramFields.ReportColName.includes("TO")) {
          date = LastDate;
        }
      }
      return (
        <div key={index}>
          <div className={colClass}>
            <label>{paramFields.ReportColName}</label>
            <span className="vcode">*</span>
            {paramFields.Multiple === 1 && (
              <span className="vcode pull-right">, SEPERATED</span>
            )}
            <div className="form-group">
              {paramFields.UserEdit === 1 && (
                <input
                  className="form-control"
                  type={paramFields.ParaMeterDataType}
                  id={paramFields.ReportColName}
                  name={param.ParameterName}
                  style={{ padding: "0px" }}
                  onChange={(e) => this.onStateChange(e)}
                ></input>
              )}
              {paramFields.UserEdit === 0 && (
                <AutoCompleteInput
                  id={"txt" + index + 1 + 9}
                  frmNm={paramFields.FormName}
                  quryNm={paramFields.QueryName}
                  db="IMAGEDB"
                  placeholder={"PLEASE SELECT " + paramFields.ReportColName}
                  onAfterSelect={(e) =>
                    this.onAfterSelect(e, "", param.ParameterName)
                  }
                ></AutoCompleteInput>
              )}
            </div>
          </div>
        </div>
      );
    });
  }
  async getReportData() {
    let dynamicQuery = "";
    let obj = Object.keys(this.state);
    let obj1 = this.state.paramData;
    let MandatoryArray = [];
    // obj.map((o, index) => {
    //   if (index > 8) {
    //     if (index === 8) {
    //       dynamicQuery += "" + o + "='" + this.state[o] + "'";
    //     }
    //     if (index > 8) {
    //       dynamicQuery += "," + o + "='" + this.state[o] + "'";
    //     }
    //     MandatoryArray.push({ [o.replace("@", "")]: this.state[o] });
    //   }
    // });
    for (let i = 0; i < obj1.length; i++) {
      this.setState({ loading: true, resultData: [] });
      let o = obj1[i].ParameterName;
      if (o === "@loginID") {
        continue;
      }
      if (dynamicQuery === "") {
        dynamicQuery += "" + o + "='" + this.state[o] + "'";
      } else {
        dynamicQuery += "," + o + "='" + this.state[o] + "'";
      }
      MandatoryArray.push({ [o.replace("@", "")]: this.state[o] });
    }
    // console.log(dynamicQuery);
    let check = MandatoryFormFields(MandatoryArray);
    if (check == false) {
      this.setState({ loading: false });
      return false;
    }

    let query = "";
    query =
      "exec " +
      this.state.spName +
      " " +
      dynamicQuery +
      " ,@loginID = " +
      this.state.loginId;
    // console.log(query);
    let array = [];
    array = await getDataTable(query);
    if (array.length > 0) {
      this.setState({
        resultData: array,
        d: 1,
        p: 1,
        loading: false,
      });
    } else {
      Swal.fire({
        icon: "info",
        title: "NO DATA FOUND",
        showConfirmButton: false,
        timer: 1500,
      });
      this.setState({ loading: false });
      return false;
    }
  }
  onStateChange(evt) {
    this.setState({
      [evt.target.name]: evt.target.value,
    });
    this.forceUpdate();
  }
}
