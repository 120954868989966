import React, { Component } from "react";
//import xlsExport from 'xlsexport'
import {
  Configuration,
  exceltodb,
  MandatoryFormFields,
  getDataTable,
  json2xlsx,
  SaveTemplateFile,
} from "../Configuration";
import AntD from "../Actions/Controls/Table/AntDTable";
import { Spin } from "antd";
import "../ORDER/OrderMaster/style.css";
import "antd/dist/antd.css";
import AutoCompleteInput from "../Actions/Controls/AutoComplete/autoComplete";
import AutoCompleteCascad from "../Actions/Controls/AutoComplete/autoComplete.cascad";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import TFilter from "./../IMAGE/MODAL/frmTemplateFilter";
import VLAUEALSING from "./../IMAGE/MODAL/frmcreateValueAliasing";
let files = "";
var tb = "";
export default class frmUpdateValueAliasing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginId: 0,
      visible: false,
      file: null,
      loading: false,
      aData: [],
      tptype: "0",
      options: [],
      stsdup: 0,
      AIDM: "",
      ANAME: "",
      mshow: 0,
      selectedrows: [],
      stsdup: 0,
      fldt: [],
      merge: "0",
      ImportSheetId: "",
    };
  }
  async componentDidMount() {
    let rs = await getDataTable(
      "select orgid from users where userid = " + Configuration.USER_ID
    );
    await this.setState({
      loginId: Configuration.USER_ID,
      orgid: rs[0].orgid,
    });
  }

  myChangeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onAfterSelect(selectedOptions, id, name) {
    if (selectedOptions.length > 0) {
      this.setState({
        [id]: selectedOptions[0].DisplayId,
        [name]: selectedOptions[0].DisplayName,
      });
    } else {
      this.setState({
        [id]: "",
        [name]: "",
      });
    }
    this.forceUpdate();
  }

  async UploadFile() {
    this.setState({
      loading: true,
      aData: [],
    });
    let MandatoryArray = [
      { portal: this.state.portalid },
      { BLOCK: this.state.catid1 },
    ];
    let check = MandatoryFormFields(MandatoryArray);
    if (check == false) {
      this.setState({ loading: false });
      return false;
    }
    let que1 =
      "EXEC frmValueAliasingUpdate_GetAliasedData  @portalID =" +
      this.state.portalid +
      " , @templateID = " +
      this.state.tempId;
    let rs1 = await getDataTable(que1);
    this.setState({ loading: false, aData: rs1 });

    let que2 =
      "SELECT AttributeName, Value, AttributeID AS #AID, ValueID AS #AVID, Condition FROM TemplateFilterData INNER JOIN AttributeMaster ON TemplateFilterData.AttributeID = AttributeMaster.AID WHERE TemplateID = " +
      this.state.tempId;
    this.setState({ fldt: await getDataTable(que2) });
  }

  async GetDataImport() {
    this.setState({
      loading: true,
      aData: [],
    });
    let MandatoryArray = [{ BLOCK: this.state.catid1 }];
    let check = MandatoryFormFields(MandatoryArray);
    if (check == false) {
      this.setState({ loading: false });
      return false;
    }
    let que1 =
      "EXEC frmValueAliasingUpdate_GetAliasedDataImport @templateID = " +
      this.state.ImportSheetId;
    let rs1 = await getDataTable(que1);
    this.setState({ loading: false, aData: rs1 });
  }

  async UpdateAliasing() {
    let st = this.state.selectedrows;
    this.setState({ loading: true });
    for (let i = 0; i < st.length; i++) {
      let ele = st[i];
      if (ele["#valueID"]) {
        if (ele["AliasValue"]) {
          // let queDel = " Delete from OptionValueAliasing where ValueID ="+ ele['#valueID'] + " and AliasedValue = '"+ele['AliasValue']+"' and PortalID ="+ele['#portalID']
          // let rsDel = await getDataTable(queDel)
        }
        this.setState({ loading: true });
        let quechk =
          "select UID,PortalHeader + ' - ' + AliasedValue AS DT from OptionValueAliasing where ValueID = " +
          ele["#valueID"] +
          " and PortalHeader = '" +
          ele["Portal Attribute"] +
          "' and ";
        quechk +=
          "  TemplateID = " + this.state.tempId + " and ValueID <> 23554";
        let rschk = await getDataTable(quechk);
        if (rschk.length > 0) {
          this.setState({ loading: false });
          let st = await Swal.fire({
            title:
              "ALREADY ALISED WITH " +
              rschk[0]["DT"] +
              " . <br> ARE YOU SURE WANT TO OVERWRITE DATA ?",
            showCancelButton: true,
            confirmButtonText: "YES",
            cancelButtonText: "NO",
          });
          if (st.isConfirmed) {
          } else {
            continue;
          }
        }
        this.setState({ loading: true });
        var Que =
          "EXEC SaveAttributeValue_Alias @ValueID=" +
          ele["#valueID"] +
          ", @portalID='" +
          this.state.portalname +
          "' , @loginID=" +
          this.state.loginId +
          ", @aliasName='" +
          ele["Portal Value"] +
          "' , @Header='" +
          ele["Portal Attribute"] +
          "'";
        Que += " , @templateID =" + this.state.tempId;
        let rs = await getDataTable(Que);
      }
    }
    await this.updateData();
  }

  async DeleteAliasing() {
    let st = this.state.selectedrows;
    this.setState({ loading: true });
    for (let i = 0; i < st.length; i++) {
      let ele = st[i];
      if (ele["AliasValue"]) {
        let queDel =
          " Delete from OptionValueAliasing output Deleted.UID where ValueID =" +
          ele["#valueID"] +
          " and AliasedValue = '" +
          ele["AliasValue"] +
          "' and PortalID =" +
          ele["#portalID"] +
          " and PortalHeader = '" +
          ele["Portal Attribute"] +
          "'";
        // queDel += " and TemplateID ="+this.state.tempId
        let rsDel = await getDataTable(queDel);
      }
    }
    await this.updateData();
  }

  async updateData() {
    this.setState({ loading: true, aData: [] });
    //let s = document.getElementsByName('chkduplicate')
    // let PrscName = 'UPDATEDATA_FOR_VALUEALIAS_NEW'
    // if (this.state.stsdup === 1){
    //     PrscName = 'DATA_FOR_REVERSE_ALIASING'
    // }
    // let que1 = "EXEC "+PrscName+" @tblname='" + this.state.tablename + "' , @loginid =" + this.state.loginId + " , @portalID =" + this.state.portalid
    let que1 =
      "EXEC frmValueAliasingUpdate_GetAliasedData  @portalID =" +
      this.state.portalid +
      " , @templateID = " +
      this.state.tempId;
    let rs1 = await getDataTable(que1);
    this.setState({ loading: false, aData: rs1, selectedrows: [] });
  }

  async selectedDataFN(e) {
    if (e.length > 0) {
      this.setState({ selectedrows: e });
    } else {
      this.setState({ selectedrows: [] });
    }
  }
  EditRow(e) {
    console.log(e);
    let index = e.key;
    let st = this.state.aData;
    st[index] = e;
    this.setState({ aData: st });
    if (this.state.selectedrows.length > 0 && this.state.stsdup === 1) {
    }
  }

  deleteRow(e) {
    let st = this.state.aData;
    this.setState({ aData: [] });
    let index = e.key;
    st.splice(index, 1);

    this.setState({ aData: st });
    this.forceUpdate();
  }

  hideShowDiv(id, childId) {
    let div = document.getElementById(id);
    if (div !== null) {
      if (document.getElementById(id).style.display == "block") {
        document.getElementById(id).style.display = "none";
        let ele = document.getElementById(childId);
        ele.className = "fa fa-plus";
      } else {
        document.getElementById(id).style.display = "block";
        let ele = document.getElementById(childId);
        ele.className = "fa fa-minus";
      }
    }
  }

  myFileChangeHandler = (event) => {
    files = event.target.files[0];
  };

  async alwdreverseAliasing(e) {
    if (e.target.checked) {
      this.setState({ stsdup: 1 });
    } else {
      this.setState({ stsdup: 0 });
    }
    //await this.updateData()
  }

  async SaveFilters() {
    this.setState({ loading: true });
    let MandatoryArray = [
      { BLOCK: this.state.catid1 },
      { portal: this.state.portalid },
      { Template: this.state.tempId },
    ];

    let check = MandatoryFormFields(MandatoryArray);
    if (check == false) {
      this.setState({ loading: false });
      return false;
    }

    let tempdata1 = this.state.fldt;
    let aids = "";
    let vids = "";
    let values = "";
    let count = 0;
    const cndtn = [...new Set(tempdata1.map((item) => item.Condition))];

    let q1 =
      "EXEC frmupdateTemplate_DeleteFilters @TemplateID = " +
      this.state.tempId +
      " , @LoginID = " +
      this.state.loginId;
    let r1 = await getDataTable(q1);

    for (let j = 0; j < cndtn.length; j++) {
      aids = "";
      vids = "";
      values = "";
      count = 0;
      for (let i = 0; i < tempdata1.length; i++) {
        if (tempdata1[i]["Condition"] == cndtn[j]) {
          aids = aids + tempdata1[i]["#AID"] + ",";
          vids = vids + tempdata1[i]["#AVID"] + ",";
          values = values + tempdata1[i]["Value"] + ",";
          count += 1;
        }
      }

      aids = aids.substring(0, aids.length - 1);
      vids = vids.substring(0, vids.length - 1);
      values = values.substring(0, values.length - 1);

      let q =
        "EXEC SaveTemplateFilters @loginId = " +
        this.state.loginId +
        ", @condition = " +
        cndtn[j] +
        ", @catId = " +
        this.state.catid1 +
        ", @tid = " +
        this.state.tempId +
        ", @aids = '" +
        aids +
        "', @vids = '" +
        vids +
        "', @values = '" +
        values +
        "', @count = " +
        count;
      let r = await getDataTable(q);
      if (r[0][""] == "Done") {
        Swal.fire({
          icon: "success",
          title: "Filters Updated",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        let que2 =
          "SELECT AttributeName, Value, AttributeID AS #AID, ValueID AS #AVID, Condition FROM TemplateFilterData INNER JOIN AttributeMaster ON TemplateFilterData.AttributeID = AttributeMaster.AID WHERE TemplateID = " +
          this.state.tempId;
        this.setState({ fldt: await getDataTable(que2) });

        Swal.fire({
          icon: "error",
          title:
            "Same filters exists for condition " +
            cndtn[j] +
            " for template " +
            r[0][""],
          showConfirmButton: false,
          timer: 3000,
        });
      }
    }

    this.setState({ loading: false });
  }

  render() {
    //lt = this.state
    return (
      <div className="content-wrapper" style={{ maxHeight: "100%" }}>
        <div
          className="loader"
          style={{ display: this.state.loading ? "block" : "none" }}
        >
          <div className="loader-item">
            <Spin />
          </div>
        </div>
        {<div>{this.state.mshow === 1 && this.CreateAttribute(this)}</div>}
        <section className="content">
          <div
            id="ctl00_CPHMaincontent_div"
            className="box box-success"
            style={{ marginTop: "-13px" }}
          >
            <div className="box-header with-border">
              <h3 className="box-title">
                UPDATE VALUE ALIASING
                <span
                  id="ctl00_CPHMaincontent_DivOrdNo"
                  style={{ display: "inline" }}
                ></span>
              </h3>
              <div class="btn-group pull-right">
                <button type="button" class="btn btn-danger">
                  Select Action
                </button>
                <button
                  type="button"
                  class="btn btn-danger dropdown-toggle"
                  data-toggle="dropdown"
                >
                  <span class="caret"></span>
                  <span class="sr-only">Toggle Dropdown</span>
                </button>
                <ul class="dropdown-menu" role="menu">
                  <li>
                    <VLAUEALSING
                      templateID={this.state.tempId}
                      width="750px"
                      BlockID={this.state.catid1}
                    />
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="box-body"
              id="orderDetails"
              style={{ display: "block" }}
            >
              <div className="row">
                <div className="col-xs-6 col-sm-6 col-md-2">
                  <label>Select Type</label>
                  <span className="vcode">*</span>
                  <div className="form-group">
                    <select
                      name="merge"
                      onChange={this.myChangeHandler}
                      id=""
                      tabIndex={1}
                      className="form-control"
                    >
                      <option selected="true" disabled="disabled">
                        {" "}
                        No Selection{" "}
                      </option>
                      <option value="1">IMPORT TEMPLATE</option>
                      <option value="2">EXPORT TEMPLATE</option>
                    </select>
                  </div>
                </div>
                <div
                  className="col-xs-6 col-sm-6 col-md-2 margintop"
                  style={{
                    display: this.state.merge === "2" ? "block" : "none",
                  }}
                >
                  <label>Select Portal</label>
                  <AutoCompleteInput
                    id="portalid"
                    frmNm="FRMATTRIBUTEALIASE"
                    quryNm="FILLPORTAL"
                    db="IMAGEDB"
                    placeholder="Please Select Portal"
                    onAfterSelect={(e) =>
                      this.onAfterSelect(e, "portalid", "portalname")
                    }
                    isValid={this.state.isValid}
                  ></AutoCompleteInput>
                </div>
                <div
                  className="col-xs-6 col-sm-6 col-md-2 margintop"
                  style={{
                    display: this.state.merge === "2" ? "block" : "none",
                  }}
                >
                  <label>Select Block</label>
                  <AutoCompleteCascad
                    id="catid"
                    frmNm="FRMALLDATA"
                    quryNm="FILLORGBLOCKS"
                    db="IMAGEDB"
                    filter1="orgid"
                    filterId1={this.state.orgid}
                    filter2=""
                    filterId2=""
                    placeholder="Please Select Block"
                    onAfterSelect={(e) =>
                      this.onAfterSelect(e, "catid1", "catname1")
                    }
                    isValid={this.state.isValid}
                  ></AutoCompleteCascad>
                </div>

                <div
                  className="col-xs-6 col-sm-6 col-md-2 margintop"
                  style={{
                    display: this.state.merge === "2" ? "block" : "none",
                  }}
                >
                  <label>Select Template</label>
                  <AutoCompleteCascad
                    id="catid"
                    frmNm="FRMUPDATEATTRIBUTEALIASING"
                    quryNm="FILLTEMPLATE"
                    db="IMAGEDB"
                    filter1="CatID"
                    filterId1={this.state.catid1}
                    filter2="PortalID"
                    filterId2={this.state.portalid}
                    placeholder="Please Select Template"
                    onAfterSelect={(e) =>
                      this.onAfterSelect(e, "tempId", "tempName")
                    }
                    isValid={this.state.isValid}
                  ></AutoCompleteCascad>
                </div>
                <div
                  className="col-xs-6 col-sm-6 col-md-2 margintop"
                  style={{
                    display: this.state.merge === "1" ? "block" : "none",
                  }}
                >
                  <label>Select Block</label>
                  <AutoCompleteCascad
                    id="catid"
                    frmNm="FRMALLDATA"
                    quryNm="FILLORGBLOCKS"
                    db="IMAGEDB"
                    filter1="orgid"
                    filterId1={this.state.orgid}
                    filter2=""
                    filterId2=""
                    placeholder="Please Select Block"
                    onAfterSelect={(e) =>
                      this.onAfterSelect(e, "catid1", "catname1")
                    }
                    isValid={this.state.isValid}
                  ></AutoCompleteCascad>
                </div>

                <div
                  className="col-xs-6 col-sm-6 col-md-2 margintop"
                  style={{
                    display: this.state.merge === "1" ? "block" : "none",
                  }}
                >
                  <label>Select Import Template</label>
                  <AutoCompleteCascad
                    id="CTID"
                    frmNm="FRMIMPORTDATA"
                    quryNm="FILLCUSTOMSHEETALIAS"
                    db="IMAGEDB"
                    filter1="BlockID"
                    filterId1={this.state.catid1}
                    filter2="OrgID"
                    filterId2={this.state.orgid}
                    placeholder="Please Select Custom Template"
                    onAfterSelect={(e) =>
                      this.onAfterSelect(e, "ImportSheetId", "ImportSheetName")
                    }
                    isValid={this.state.isValid}
                  ></AutoCompleteCascad>
                </div>
                <div
                  className="col-xs-6 col-sm-6 col-md-1 margintop"
                  style={{
                    display: this.state.merge === "2" ? "block" : "none",
                  }}
                >
                  <label>&nbsp;</label>
                  <div className="form-group">
                    <input
                      type="button"
                      value="GET DATA"
                      onClick={this.UploadFile.bind(this)}
                      className="btn btn-block btn-primary"
                    />
                  </div>
                </div>
                <div
                  className="col-xs-6 col-sm-6 col-md-1 margintop"
                  style={{
                    display: this.state.merge === "1" ? "block" : "none",
                  }}
                >
                  <label>&nbsp;</label>
                  <div className="form-group">
                    <input
                      type="button"
                      value="GET DATA"
                      onClick={this.GetDataImport.bind(this)}
                      className="btn btn-block btn-primary"
                    />
                  </div>
                </div>
                {["1", "10", "6", "10019"].includes(this.state.loginId) && (
                  <div>
                    <div
                      style={{
                        display: this.state.fldt.length > 0 ? "block" : "none",
                      }}
                      className="col-xs-6 col-sm-3 col-md-1 margintop"
                    >
                      <label>&nbsp;</label>
                      <div className="form-group">
                        <TFilter
                          width="800px"
                          data={this.state.fldt}
                          logid={this.state.loginId}
                          catid={this.state.catid1}
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        display: this.state.fldt.length > 0 ? "block" : "none",
                      }}
                      className="col-xs-6 col-sm-3 col-md-1 margintop"
                    >
                      <label>&nbsp;</label>
                      <div className="form-group">
                        <input
                          type="button"
                          value="Save Filters"
                          onClick={this.SaveFilters.bind(this)}
                          className="btn btn-success btn-block"
                        />
                      </div>
                    </div>
                  </div>
                )}
                {this.state.selectedrows.length > 0 && (
                  <div className="col-xs-6 col-sm-6 col-md-1 margintop">
                    <label>&nbsp;</label>
                    <div className="form-group">
                      <input
                        type="button"
                        value="Update Aliasing"
                        onClick={this.UpdateAliasing.bind(this)}
                        className="btn btn-block btn-success"
                      />
                    </div>
                  </div>
                )}
                <div className="col-xs-4 col-sm-4 col-md-1 pull-right">
                  {/* {this.state.aData.length > 0 &&<span className='vcode'><input type='checkbox' onClick={this.alwdreverseAliasing.bind(this)} checked={this.state.stsdup === 1} defaultChecked={this.state.stsdup === 1} name='chkduplicate'></input><label>Reverse Aliasing</label></span>} */}
                  <div className="form-group">
                    {this.state.aData.length > 0 && (
                      <a
                        id="refrsh"
                        onClick={this.updateData.bind(this)}
                        style={{ color: "red" }}
                      >
                        <span class="fa fa-refresh">Refresh Data</span>
                      </a>
                    )}
                  </div>
                </div>
                {(this.state.loginId === "10" ||
                  this.state.loginId === "1" ||
                  this.state.loginId === "6") &&
                  this.state.selectedrows.length > 0 && (
                    <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                      <label>&nbsp;</label>
                      <div className="form-group">
                        <input
                          type="button"
                          value="Delete Aliasing"
                          onClick={this.DeleteAliasing.bind(this)}
                          className="btn btn-block btn-danger"
                        />
                      </div>
                    </div>
                  )}
                <div
                  className="col-xs-12 col-sm-12 col-md-12 margintop"
                  style={{
                    display: this.state.merge === "2" ? "block" : "none",
                  }}
                >
                  {this.state.aData.length > 0 && (
                    // this.storeImageTable()
                    <AntD
                      data={[...this.state.aData]}
                      exportXL="true"
                      Delete="true"
                      DeleteFn={(e) => this.deleteRow(e)}
                      EditBlock="true"
                      EditBlockFn={this.EditRow.bind(this)}
                      EditBlockReverse="true"
                      rvrs={this.state.stsdup}
                      tblname={this.state.tablename}
                      templteID={this.state.tempId}
                      selected="true"
                      SelectedFn={(e) => this.selectedDataFN(e)}
                    ></AntD>
                  )}
                </div>
                <div
                  className="col-xs-12 col-sm-12 col-md-12 margintop"
                  style={{
                    display: this.state.merge === "1" ? "block" : "none",
                  }}
                >
                  {this.state.aData.length > 0 && (
                    // this.storeImageTable()
                    <AntD
                      data={[...this.state.aData]}
                      exportXL="true"
                      Delete="true"
                      DeleteFn={(e) => this.deleteRow(e)}
                      EditBlock="true"
                      EditBlockFn={this.EditRow.bind(this)}
                      EditBlockReverse="true"
                      rvrs={this.state.stsdup}
                      tblname={this.state.tablename}
                      templteID={this.state.tempId}
                    ></AntD>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
