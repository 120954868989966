import {config} from '../../../Configuration';
import {CALL_API} from '../../../middleware/api';
import React, { Component, PropTypes } from 'react'
import { connect } from 'react-redux'
import {Configuration} from '../../../Configuration';
export const FETCH_ORDER_REQUEST = 'FETCH_ORDER_REQUEST'
export const FETCH_ORDER_SUCCESS = 'FETCH_ORDER_SUCCESS'
export const FETCH_ORDER_FAILURE = 'FETCH_ORDER_FAILURE'

export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'
const API_URL = Configuration.SYSTEM_SETTINGS.API_URL;

export default function fetchORDER() {
    return {
      [CALL_API]: {
        endpoint: 'api/v1/Common/GetDynamicQuery?query=select userid,username from users&db=VI',
        authenticated: false,
        types: [FETCH_ORDER_REQUEST, FETCH_ORDER_SUCCESS, FETCH_ORDER_FAILURE]
      }
    }
  }
  // export function fetchUSER(idUSER, history) {
  //   let token = localStorage.getUSER('id_token') || null
  
  //   let config = {
  //     method: 'POST',
  //     headers: {'Content-Type':'application/x-www-form-urlencoded', 'Authorization': `Bearer ${token}` },
  //     body: `id=${idUSER}`
  //   }

    
function requestFetchUSER(idUSER) {
  return {
    type: FETCH_USER_REQUEST,
    isFetching: true,
    isAuthenticated: false,
    idUSER
  }
}

function receiveFetchUSER(USER) {
  return {
    type: FETCH_USER_SUCCESS,
    isFetching: false,
    isAuthenticated: false,
    dataItem: USER
  }
}

function fetchUSERError(message) {
  return {
    type: FETCH_USER_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    message
  }
}


export function fetchUSER(idUSER, history) {
  let token = localStorage.getItem('id_token') || null

  let config = {
    method: 'GET',
    headers: {'Content-Type':'application/x-www-form-urlencoded'//, 'Authorization': `Bearer ${token}`
   },
    body: `id=${idUSER}`
  }

  return dispatch => {
    dispatch(requestFetchUSER(idUSER))

    return fetch(API_URL+'api/v1/Common/GetDynamicQuery?query=select userid,username from users&db=VI', config)
      .then(response =>
        response.json().then(USER => ({ USER, response }))
            ).then(({ USER, response }) =>  {
        if (!response.ok) {
          dispatch(fetchUSERError("Exception Found"))
          return Promise.reject(USER)
        } else {
          dispatch(receiveFetchUSER(USER))
        }
      }).catch(err => console.log("Error: ", err))
  }
}

  