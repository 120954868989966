import React from "react"; 
import '../../../ORDER/OrderMaster/style.css'
import "antd/dist/antd.css";
import "./AntdTable.css";
import { Table, Button, Space, Select, Spin,Checkbox,Dropdown, Image,Menu } from "antd";
import { Resizable } from "react-resizable";
import Highlighter from "react-highlight-words";
import { DownloadOutlined, SearchOutlined,EllipsisOutlined } from "@ant-design/icons"; 
import { json2xlsx } from "../../../Configuration";
import Moment from "moment";
import ReactDragListView from "react-drag-listview";
import ALIS from './../../../IMAGE/MODAL/frmValueAliasAction'
import UPDATEALIS from './../../../IMAGE/MODAL/frmUpdateValueAliasAction'
import MULTIPLEALIS from './../../../IMAGE/MODAL/frmMultipleValueAliasAction'
import ALISIMPORT from './../../../IMAGE/MODAL/frmValueAliasImportAction'
const { Option } = Select;
const currentDate = Moment().format("DD-MMM-YYYY hh:mm:ss");
let state = {
  bordered: true,
  title: undefined,
  showHeader: true,
  footer: false,
  scroll: {},
  hasData: true,
  tableLayout: "fixed",
  top: "none",
  bottom: "bottomCenter",
  xScroll: "scroll",
  yScroll: true,
  ellipsis: true, 
  scrollToFirstRowOnChange: true,
  initialColumns: [],
};

const scroll = { y: 800, x: 800 };
const ResizableTitle = (props) => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

function beginsWithFloat(val) {
  val = parseFloat(val);
  return !isNaN(val);
}

class AntdTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columnWidth: 300,
      selectedRows: [],
      checkedColumns: [],
      columns: [],
      searchText: "",
      searchedColumn: "",
      columnValues: {},
      loading: false,
      initialColumns:[],
      visibleMenuSettings: false,
    };

    const that = this;
    this.dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const columns = [...that.state.columns];
        const item = columns.splice(fromIndex, 1)[0];
        columns.splice(toIndex, 0, item);
        that.setState({
          columns
        });
      },
      nodeSelector: "th",
      handleSelector: ".dragHandler",
      ignoreSelector: "react-resizable-handle"
    };
  }
  
  columnWidth = 10;
  components = {
    header: {
      cell: ResizableTitle,
    },
  };
  Child = React.createRef();
  data = [];
  cols=[];

  handleAll = (values, setSelectedKeys, column) => {
    if (values.includes("all")) {
      let list = [];
      this.state.columnValues[column].map((key, index) => {
        if (list.indexOf(key) === -1) {
          list.push(key);
        }
      });
      setSelectedKeys([...list]);
    } else {
      setSelectedKeys([...values]);
    }
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Cancel
          </Button>
        </Space>
        <Select
          mode="multiple"
          ref={(node) => {
            this.searchInput = node;
          }}
          allowClear
          showArrow
          style={{ width: 188, marginTop: "6%", display: "block" }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys}
          //onChange={values => setSelectedKeys([...values])}
          onChange={(values) =>
            this.handleAll(values, setSelectedKeys, dataIndex)
          }
        >
          <Option key="all" value="all">
            Select All
          </Option>
          {this.state.columnValues[dataIndex].sort().map((key, index) => {
            return <Option key={key}>{key}</Option>;
          })}
        </Select>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
    record[dataIndex] !== undefined && record[dataIndex] !== null
      ? String(record[dataIndex]).toLowerCase() === String(value).toLowerCase()
      : false,  
  
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        //setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={this.state.searchText ? [this.state.searchText.toString()] : []}
        autoEscape
        textToHighlight={text ? text.toString() : ""}
      />
    ) : (
      text
    ),  
  });


  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  handleResize = (index) => (e, { size }) => {
    this.setState(({ columns }) => {
      const nextColumns = [...columns];
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      };
      return { columns: nextColumns };
    });
  };

  handleDelete = (row,index) => {
    const dataSource = [...this.data];
    dataSource.splice(index, 1);
    for (let index = 0; index < dataSource.length; index++) {
      dataSource[index]["key"] = index;
    }  
    this.data= dataSource;
    this.props.DeleteFn(row);
    this.forceUpdate();
  };

  GetParentColumms(data) {
    let cols = Object.keys(data[0]);
    //let cols = Object.keys(data[0]);
    let values = {}
    cols.map((col, index) => {
      data.map((item, pos) => {
        // if(!values[col].includes(item[col])){
        if (values[col] && values[col].indexOf(item[col]) === -1) {
          values[col].push(item[col]);
        }
        else if (!values[col]) {
          values[col] = [item[col]]
        }
      })
    })
    let d = [];

    let col = {
      title: () => {
        return (
          <div style={{display:'grid'}}>
            SRNO &nbsp;{" "}
            {this.props.exportXL === "true" && (
              <Button
                type="primary"
                shape="round"
                icon={<DownloadOutlined />}
                size="small"
                onClick={this.exportExcel.bind(this)}
              />
            )}
          </div>
        );
      },
      width: 60,
      render: (text, record, index) =>
        this.data.length >= 1 ? (
          <span>
            {index + 1} &nbsp;
            {this.props.Delete === "true" &&
              this.props.DeleteFn !== undefined && (
                <i
                  className="fa fa-trash vcode"
                  onClick={() => this.handleDelete(record,index)}
                />
              )}
               &nbsp;&nbsp;
            {this.props.EditRow === "true" && (
              <i
                className="fa fa-edit"
                style={{ color: "green" }}
                onClick={(e) => this.props.EditRowFn(record)}
              ></i>
            )}
            {this.props.PostData === "true" && (
              <i
                style={{ color: "green" }}
                onClick={(e) => this.props.PostDataFn(record)}
              >Post To SFTP</i>
            )}
            {this.props.PostApiAmazon === "true" && (
              <i
                style={{ color: "green" }}
                onClick={(e) => this.props.PostApiAmazonFn(record)}
              >Post API Amazon</i>
            )}   
             &nbsp;
            {this.props.ShowRow == "true" && (
              <i
                className="fa fa-eye"
                style={{ color: "blue" }}
                onClick={(e) => this.props.ShowRowFn(record)}
              ></i>
            )}
            {this.props.EditBlock === "true" && (
              <ALIS 
                dt={record} 
                tblname={this.props.tblname} 
                templateid={this.props.templteID} 
                rvrs={this.props.rvrs} 
                ChngFn={this.props.EditBlockFn} 
                width='800px' 
              />
            )}
            &nbsp;&nbsp;
            {this.props.EditBlockImport === "true" && (
              <ALISIMPORT 
                dt={record} 
                templateid={this.props.templteID} 
                rvrs={this.props.rvrs} 
                ChngFn={this.props.EditBlockFn} 
                width='800px' 
              />
            )}
            &nbsp;&nbsp;
            {this.props.EditBlockReverse === "true" && (
              <UPDATEALIS 
                dt={record} 
                tblname={this.props.tblname} 
                templateid={this.props.templteID} 
                rvrs={1} 
                ChngFn={this.props.EditBlockFn} 
                width='800px' 
              />
            )} &nbsp;&nbsp;
            
            {this.props.downloadRow === "true" && (
              <i
                className="fa fa-download"
                style={{ color: "red" }}
                onClick={(e) => this.props.downloadRowFn(record)}
              ></i>
            )}
            &nbsp;&nbsp;
            {this.props.EditMultiBlock === "true" && (
              <MULTIPLEALIS
              dt={record} 
              tblname={this.props.tblname} 
              templateid={this.props.templteID} 
              rvrs={this.props.rvrs} 
              ChngFn={this.props.EditMultiBlockFn} 
              width='800px'
           />
            )}
          </span>
        ) : null,
    };

    d.push(col);

    cols.map((col, index) => {
      let c;
      if (col === "Image" || col === "IMAGE") {
        c = {
          title: <span className="dragHandler" style={{ padding: "0px !important" }}>{col.toUpperCase()}</span>,
          dataIndex: col,
          width: 60,
          render: (text, record) => {
            return (
              <Image
                src={text} height={'inherit'} width={'inherit'}
              />
            );
          },
        }
        d.push(c);
      }
      if (col === "Status") {
        c = {
          title: col.toUpperCase(),
          dataIndex: col,
          width: 100,
          ...this.getColumnSearchProps(col),
          render(text, record) {
            if (text.includes("False")) {
              return {
                props: {
                  style: { background: "#FA5D5D" },
                },
                children: <div>{text}</div>,
              };
            }
            else{
              return {
                props: {
                  style: { background: "#B9FEF5" },
                },
                children: <div>{text}</div>,
              };
            }  
          },
        };
        d.push(c);
      }

      if (col !== "key" && col !== "GRNNo" && col !== "PurNo" && col !== "Action" && col !== "Status" && !col.startsWith('#') && col !== "Image" && col !== "IMAGE") {
        c = {
          title: <span className="dragHandler" style={{ padding: "0px !important" }}>{col.toUpperCase()}</span>,
          dataIndex: col,
          width: 110,
          sorter: {
            compare: (a, b) => {
              a = beginsWithFloat(data[0][col]) === true ? a[col] || 0 : a[col] || "";
              b = beginsWithFloat(data[0][col]) === true ? b[col] || 0 : b[col] || "";
              return beginsWithFloat(data[0][col]) === true ? a[col] - b[col] : a.length - b.length;
            },
            multiple: cols.length - 1,
          },
          ellipsis: state.ellipsis,
          ...this.getColumnSearchProps(col),
        };
        d.push(c);
      }
    });
    this.setState({ columnValues: values })
    return d;
  }

  async componentDidMount() { 
    if (this.props.data.length > 0) {
      this.setState({
        columns: await this.GetParentColumms(this.props.data),
        initialColumns: await this.GetParentColumms(this.props.data),
      }); 
      this.data = this.props.data;
    }
    this.forceUpdate();
  }
 
  async exportExcel() {
    this.setState({
      loading:true
    })
    
    await json2xlsx(this.data, "Export.xlsx"+ "_" + currentDate + "_" + "1.xlsx");
    // const xls = new xlsExport(this.data, "Export");
    // xls.exportToXLS("StatusReport.xls");
    this.setState({
      loading:false
    })
  }

  handleVisibleChange = (flag) => {
    this.setState({ visibleMenuSettings: flag });
  };

  onChange = (e) => {
    var checkedColumns = this.state.checkedColumns;
    if (e.target.checked) {
      checkedColumns = checkedColumns.filter((id) => {
        return id !== e.target.id;
      });
    } else if (!e.target.checked) {
      checkedColumns.push(e.target.id);
    }

    var filtered = this.state.initialColumns;
    for (var i = 0; i < checkedColumns.length; i++)
      filtered = filtered.filter((el) => {
        return el.dataIndex !== checkedColumns[i];
      });

    this.setState({ columns: filtered, checkedColumns: checkedColumns });
  };
  
  render() {
    let d = this.props.data;
    if (this.props.data.length > 0) {
      for (let index = 0; index < this.props.data.length; index++) {
        d[index]["key"] = index;
      }
    }
    this.data = d;
    const columns = this.state.columns.map((col, index) => ({
      ...col,
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: this.handleResize(index),
      }),
    }));

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.props.SelectedFn(selectedRows);
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === "Disabled User",
        // Column configuration not to be checked
        name: record.name,
      }),
    };

    const menu = (
      <div
        className="table datatable table-responsive table-bordered table-hover"
        style={{ maxHeight: "60vh" }}
      >
        <Menu>
          <Menu.ItemGroup
            title="COLUMNS"
            style={{ maxHeight: "60vh !important", height: "auto !important" }}
          >
            {this.state.initialColumns.sort().map((col, i) => {
              if (i > 0) {
                return (
                  <Menu.Item key={i}>
                    <Checkbox
                      id={col.dataIndex}
                      onChange={this.onChange.bind(this)}
                      defaultChecked
                    >
                      {col.dataIndex}
                    </Checkbox>
                  </Menu.Item>
                );
              }
            })}
          </Menu.ItemGroup>
        </Menu>
      </div>
    );

    return (
      <div className="col-md-12 col-xs-12 col-sm-12 col-xl-12">
        <div
          className="loader"
          style={{ display: this.state.loading ? "block" : "none" }}
        >
          <div className="loader-item">
            <Spin size="large" />
          </div>
        </div>

        <Dropdown
          className="pull-right"
          style={{ maxHeight: "50vh !important" }}
          overlay={menu}
          onVisibleChange={this.handleVisibleChange}
          visible={this.state.visibleMenuSettings}
        >
          <EllipsisOutlined rotate={90} style={{ fontSize: "20px" }} />
        </Dropdown>
        <ReactDragListView.DragColumn {...this.dragProps}

          refs={this.Child}>
          <Table

            rowKey="key"
            {...state}
            size={this.props.selected === "true" ? "small" : "middle"}
            components={this.components}
            columns={columns}
            dataSource={this.data}
            scroll={scroll}
            pagination={{
              defaultPageSize: 200,
              pageSizeOptions: [
                "100",
                "200",
                "300",
                "400",
                "500",
                "1000",
                "2000",
              ],
            }}
            rowSelection={
              this.props.selected === "true" ? rowSelection : undefined
            }
            refs={this.Child}
            rowClassName={(record) =>
              (record.HurdleDoneSts !== 0 &&
                record.HurdleDoneSts !== undefined &&
                "hurdleDone-row") ||
              (record.HurdleSts !== 0 &&
                record.HurdleDoneSts !== undefined &&
                "hurdleOpen-row") ||
              (record.enabled === "true" && "disabled-row")
            }
          />
        </ReactDragListView.DragColumn>
      </div>
    );
  }
}

export default AntdTable;
