import React, { Component } from "react";
import _, { initial, keyBy, values } from "lodash";
import {
  Configuration,
  MandatoryFormFields,
  getDataTable,
  json2xlsx,
  table2xlsx,
  sleep,
} from "../Configuration";
import AutoCompleteCascad from "../Actions/Controls/AutoComplete/autoComplete.cascad";
import AutoCompletemulti from "../Actions/Controls/AutoComplete/autoCompleteMultiple";
import { Image2Drplink, ProcessAI } from "../Mltfunc";
import PortalConfig from "../Actions/Controls/Modal/PortalConfig";
import ImageConfig from "../Actions/Controls/Modal/ImageConfig";
import "../ORDER/OrderMaster/style.css";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Progress, Modal, Spin } from "antd";
import "antd/dist/antd.css";
import JSZip from "jszip";
import saveAs from "save-as";
let portaldata = [];
//let uploadfiles = ''
let fields = { AttributeToBeShowToUser: 1 };
let Anglers = [];
let SubCategories = [];
export default class frmImageProcessUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginId: 0,
      ImageData: [],
      loading: false,
      loadingspin: false,
      prs: "",
      ldsts: "",
      portals: [],
      portlsData: [],
      accesstoken: "",
      stImages: [],
      RejectedImage: [],
      finalImg: [],
      cmbdigit: "All",
      cmbprsctype: "2",
      profileID: [],
      prcstime: "",
      profiledt: [],
    };
  }

  async componentDidMount() {
    let rs1 = await getDataTable(
      "select orgid from users where userid = " + Configuration.USER_ID
    );
    await this.setState({
      loginId: Configuration.USER_ID,
      orgid: rs1[0].orgid,
    });
    Anglers = await getDataTable("select Angle from Anglemaster");
    SubCategories = await getDataTable(
      "SELECT DIstinct OptValue FROM optionValue WHERE (AtrributeID = 39)"
    );
    let rs = await getDataTable(
      "SELECT DrpToken FROM DropBoxAccessToken WHERE (OrgID = " +
        rs1[0].orgid +
        ")"
    );
    await this.varifyDropBoxToken(rs.length > 0 ? rs[0].DrpToken : "");
    if (rs.length > 0) {
      this.setState({ accesstoken: rs[0].DrpToken });
    } else {
    }
  }

  myChangeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onAfterSelect(selectedOptions, id, name, index = 0) {
    if (selectedOptions.length > 0) {
      this.setState({
        [id]: selectedOptions[0].DisplayId,
        [name]: selectedOptions[0].DisplayName,
      });
    } else {
      this.setState({
        [id]: "",
        [name]: "",
      });
    }
    if (id === "ValueID") {
      this.changeParent(index, selectedOptions);
    }
  }

  changeParent(index, values) {
    let st = this.state.groupedImg;
    if (values.length > 0) {
      for (let i = 0; i < st.length; i++) {
        const element = st[i];
        if (element.productid === values[0].DisplayId) {
          Swal.fire({
            position: "top-end",
            icon: "info",
            title: "Product Already Assinged To Another Folder",
            showConfirmButton: false,
            timer: 1500,
          });
          return false;
        }
      }
      st[index].productname = values[0].DisplayName;
      st[index].productid = values[0].DisplayId;
    } else {
      st[index].productname = "";
      st[index].productid = "";
    }
    this.setState({ groupedImg: st });
  }

  async FileHandler(e) {
    if (e.target.files.length > 350) {
      Swal.fire({
        position: "top-end",
        icon: "info",
        title: "CAN NOT UPLOAD MORE THEN 350 IMAGES",
        showConfirmButton: false,
        timer: 1500,
      });
      document.getElementById("imgUpload").value = "";
      return;
    }

    if (e.target.files.length > 0) {
      let st = e.target.files;
      let img = [];
      let RejImg = [];
      for (let i = 0; i < st.length; i++) {
        let name = st[i].webkitRelativePath;
        console.log(name);
        let sts = name.split("/");
        console.log(sts);
        if (sts.length === 3 && st[i].type.startsWith("image")) {
          let avl = await this.imageVarify(st[i]);
          if (avl) {
            img.push({ file: st[i], folder: sts[1], flname: sts[1] });
          } else {
            RejImg.push({ folder: sts[1], flname: sts[2] });
          }
        }
      }
      this.setState({ stImages: img, RejectedImage: RejImg });
      console.log({ stImages: img, RejectedImage: RejImg });
      document.getElementById("imgUpload").value = "";
    } else {
      this.setState({ stImages: [], RejectedImage: [] });
    }
  }

  async imageVarify(img) {
    let sts = true;
    let fileurl = URL.createObjectURL(img);
    let w = await this.GetImageDimension(fileurl, "width");
    let h = await this.GetImageDimension(fileurl, "height");
    let sz = Math.round(img.size / 1024);
    if (w < 800 || h < 800) {
      sts = false;
    }
    if (sz < 50 || sz > 8000) {
      sts = false;
    }
    return sts;
  }

  async storeImages(e) {
    if (this.state.stImages.length < 1) {
      Swal.fire({
        position: "top-end",
        icon: "info",
        title: "No Image Folder selected",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    this.setState({
      loadingspin: true,
    });
    let imgdata = this.state.stImages;
    var grouped = _.mapValues(_.groupBy(imgdata, "folder"), (clist) =>
      clist.map((imgdata) => _.omit(imgdata, "folder"))
    );
    let Imagessts = [];

    const objectArray = Object.entries(grouped);
    objectArray.forEach(([key, value]) => {
      Imagessts.push({ key: key, Files: value });
    });
    let numdigit = 0;
    if (this.state.cmbdigit === "All") {
    } else {
      numdigit = parseInt(this.state.cmbdigit);
    }
    for (let i = 0; i < Imagessts.length; i++) {
      let name = "";
      if (numdigit > 0) {
        name = Imagessts[i].key.substring(0, numdigit);
      } else {
        name = Imagessts[i].key;
      }
      let rs = await getDataTable(
        "select Parent,ParentID from ParentMaster where Parent like '" +
          name +
          "%' and orgId =" +
          this.state.orgid
      );
      if (Array.isArray(rs) && rs.length > 0) {
        Imagessts[i].productname = rs[0].Parent;
        Imagessts[i].productid = rs[0].ParentID;
      } else {
        Imagessts[i].productname = "";
        Imagessts[i].productid = "";
      }
    }
    this.setState({ groupedImg: Imagessts, stImages: [], loadingspin: false });
    document.getElementById("imgUpload").value = "";
    Swal.fire({
      position: "top-end",
      icon: "info",
      title: "Images Added Successfully",
      showConfirmButton: false,
      timer: 1500,
    });
  }

  async varifyDropBoxToken(tkn) {
    if (!tkn) {
      return false;
    }
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + tkn);
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      path: "/home",
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    let rs = await fetch(
      "https://api.dropboxapi.com/2/sharing/get_shared_links",
      requestOptions
    );
    if (rs.status !== 200) {
      Swal.fire({
        icon: "info",
        title: "DROPBOX TOKEN EXPIRED PLEASE UPDATE",
        showConfirmButton: false,
        timer: 3000,
      });
      return false;
    } else {
      return true;
    }
  }

  async UploadImages() {
    if (!this.state.accesstoken) {
      Swal.fire({
        icon: "info",
        title: "DROPBOX NOT CONFIGURED",
        showConfirmButton: false,
        timer: 1500,
      });
      return false;
    }
    let chk = await this.varifyDropBoxToken(this.state.accesstoken);
    if (!chk) {
      return false;
    }
    let ImagesWithurl = [];
    if (this.state.ImageData.length < 1) {
      alert("None Of File Ready To Upload On Dropbox");
      return;
    }
    this.setState({ loading: true, prs: 0, ldsts: "Image Uploading" });
    let cnt = 0;
    for (let i = 0; i < this.state.ImageData.length; i++) {
      if (
        this.state.ImageData[i].Status === "True" &&
        this.state.ImageData[i].allowdropbox === 1
      ) {
        cnt = cnt + 1;
        let val = this.state.ImageData[i];
        let b = await this.dataURLtoFile(
          val["data"],
          val.ProductName + "-" + val.Angle + ".jpg"
        );
        let url = await Image2Drplink(
          b,
          val.ProductName,
          val.PortalName,
          this.state.accesstoken
        );
        console.log(url);
        let Query =
          "exec StoreImageUrlInDb @portalid = " +
          val.prtlID +
          " ,@attribute ='" +
          val.Angle +
          "', @productId=" +
          val.ProductID +
          ",@url='" +
          url +
          "',@loginid=" +
          this.state.loginId +
          " , @SUBCAT ='" +
          val.SubCategory +
          "'";
        if (url) {
          let rs = await getDataTable(Query);
          console.log(rs);
          debugger;
        }
        ImagesWithurl.push({
          Product: val.ProductName,
          Angle: val.Angle,
          Portal: val.PortalName,
          url: url,
        });
      }
      let sts = ((i + 1) * 100) / this.state.ImageData.length;
      this.setState({
        prs: Math.round(sts),
        ldsts:
          "Image Uploading " +
          String(i) +
          " / " +
          String(this.state.ImageData.length) +
          "",
      });
    }
    if (cnt === 0) {
      Swal.fire({
        icon: "info",
        title: "None Of Image Ready To Upload",
        showConfirmButton: false,
        timer: 1500,
      });
      this.setState({ loading: false, prs: 0, ldsts: "Image Uploading" });
      return false;
    }
    await json2xlsx(ImagesWithurl, "ImageLinks.xlsx");
    alert("Data Saved Successfully");
    this.setState({ loading: false, prs: 0, ldsts: "Image Uploading" });
  }

  hideShowDiv(id, childId) {
    let div = document.getElementById(id);
    if (div !== null) {
      if (document.getElementById(id).style.display == "block") {
        document.getElementById(id).style.display = "none";
        let ele = document.getElementById(childId);
        ele.className = "fa fa-plus";
      } else {
        document.getElementById(id).style.display = "block";
        let ele = document.getElementById(childId);
        ele.className = "fa fa-minus";
      }
    }
  }

  // function To get Image Width and Height
  async GetImageDimension(src, type) {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = () => resolve(img[type]);
      img.onerror = reject;
      img.src = src;
    });
  }

  async onAutoCOmpletMultiPleSelect(selectedOptions, name) {
    if (selectedOptions.length > 0) {
      this.setState({
        [name]: selectedOptions,
      });
    } else {
      this.setState({
        [name]: "",
      });
    }
  }

  TOProcessImage() {
    if (
      this.state.groupedImg === undefined ||
      this.state.groupedImg.length === 0
    ) {
      Swal.fire({
        icon: "error",
        title: "PLEASE ADD IMAGES",
        showConfirmButton: false,
        timer: 1500,
      });
      return false;
    }
    for (let j = 0; j < this.state.groupedImg.length; j++) {
      const element = this.state.groupedImg[j];
      if (element.productname === "" || element.productname === undefined) {
        Swal.fire({
          icon: "error",
          title: "PLEASE COMPLETE MAPPING OR REMOVE UN-MAPPED FOLDER",
          showConfirmButton: false,
          timer: 1500,
        });
        return false;
      }
    }
    Modal.info({
      title: "PROCCED FOR IMAGE PROCESS",
      okText: "CLOSE",
      width: "700px",
      closable: true,
      footer: null,
      bodyStyle: { maxHeight: "300px" },
      style: { padding: "0px" },
      centered: true,
      maskClosable: false,
      content: (
        <div className="row">
          <div>
            <div className="col-md-4 col-sm-6 col-sm-6 margintop">
              <label>Select Image Profile</label>
              <span className="vcode">*</span>
              <AutoCompletemulti
                id="PID"
                frmNm="FRMIMAGE"
                quryNm="FRMIMAGEPROFILE"
                db="IMAGEDB"
                filter1="OrgID"
                filterId1={this.state.orgid}
                filter2=""
                filterId2=""
                placeholder="Please Select Profile"
                onAfterSelect={(e) =>
                  this.onAutoCOmpletMultiPleSelect(e, "profileID")
                }
                isValid={this.state.isValid}
              ></AutoCompletemulti>
            </div>
            <div className="col-md-4 col-sm-6 col-sm-6 margintop">
              <label>&nbsp;</label>
              <div className="form-group">
                <button
                  onClick={this.processImages.bind(this)}
                  className="btn btn-success btn-block"
                >
                  NEXT
                </button>
              </div>
            </div>
            <div className="col-md-12 col-sm-12 col-sm-12 margintop">
              {this.state.profileID.length > 0 && (
                <label>{this.state.profileID[0]["DisplayName"]}</label>
              )}
            </div>
          </div>
        </div>
      ),
    });
  }

  RejectedImageFunc() {
    Modal.info({
      title: "REJECTED IMAGE LIST",
      okText: "CLOSE",
      width: "700px",
      closable: true,
      footer: null,
      bodyStyle: { maxHeight: "500px" },
      style: { padding: "0px" },
      centered: true,
      maskClosable: true,
      content: (
        <div style={{ maxHeight: "400px", overflowY: "scroll" }}>
          <ul>
            <li>
              <b>Uploaded images should be between 50kb - 7MB</b>
            </li>
            <li>
              <b>
                Uploaded images should not be less the (800 X 800) dimension
              </b>
            </li>
          </ul>

          <ol>
            {this.state.RejectedImage.map((value, index) => {
              return (
                <li>
                  <b>{value.flname}</b> - {value.folder}
                </li>
              );
            })}
          </ol>
        </div>
      ),
    });
  }

  async processImages() {
    let MandatoryArray = [
      { Profile: this.state.profileID },
      { "Process Type": this.state.cmbprsctype },
    ];
    let check = MandatoryFormFields(MandatoryArray);
    if (check == false) {
      return false;
    }

    if (this.state.profileID[0].DisplayId === 11) {
      await this.CompleteProcessNoAI();
    } else {
      await this.CompleteProcess(true);
    }
  }

  async CompleteProcessNoAI() {
    let FinalImages = [];
    let uploadfiles = this.state.groupedImg;

    for (let j = 0; j < this.state.profileID.length; j++) {
      let dt = this.state.profileID[j];

      for (let s = 0; s < uploadfiles.length; s++) {
        let file = uploadfiles[s]["Files"];
        for (let l = 0; l < file.length; l++) {
          let SFile = file[l].file;
          debugger;
          let base64Image = await this.convertToBase64(SFile);
          let imageInfo = {
            data: "data:image/jpg;base64," + base64Image,
            "#areacoverage": "",
            "#aspectratio": "",
            "#blurring": "",
            "#correction_log": "",
            "#object_aspect": "",
            "#objectcentering": "",
            "#resolution": "",
            Angle: "",
            Height: 0,
            PortalName: dt.DisplayName,
            ProductID: uploadfiles[s]["productid"],
            ProductName: uploadfiles[s]["productname"],
            Status: "True",
            SubCategory: "",
            Width: 0,
            allowdropbox: 1,
            image_file: SFile.name,
            prtlID: dt.DisplayId,
            size: { size: parseInt(SFile.size) },
          };

          FinalImages.push(imageInfo);
        }
      }
    }
    this.setState({
      ImageData: FinalImages,
      loading: false,
      prs: 0,
    });
  }

  convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        const base64Image = base64String.split(",")[1];
        resolve(base64Image);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  async CompleteProcess(AI) {
    // let st = true
    // let ProccessedImages = []
    let FinalImages = [];
    let uploadfiles = this.state.groupedImg;
    let ttlcnt = this.state.profileID.length * uploadfiles.length;
    let intialcnt = 0;
    this.setState({ loading: true, prs: 0 });
    for (let j = 0; j < this.state.profileID.length; j++) {
      let dt = this.state.profileID[j];
      let prfileData = await getDataTable(
        "exec GetPortaLImageConfig @pid = '" + dt.DisplayId + "'"
      );
      for (let s = 0; s < uploadfiles.length; s++) {
        debugger;
        let rs = await ProcessAI(
          uploadfiles[s]["Files"],
          parseInt(prfileData[0].height),
          parseInt(prfileData[0].width),
          parseInt(prfileData[0].Resolution),
          prfileData[0].minarea,
          prfileData[0].maxarea,
          parseInt(prfileData[0].maxsize) * 1000,
          parseInt(prfileData[0].Min) * 1000
        );
        if (rs !== undefined) {
          const keys = Object.keys(rs);
          keys.forEach((key, index) => {
            rs[key]["ProductName"] = uploadfiles[s]["productname"];
            rs[key]["ProductID"] = uploadfiles[s]["productid"];
            rs[key]["Height"] = prfileData[0].height;
            rs[key]["Width"] = prfileData[0].width;
            rs[key]["PortalName"] = prfileData[0].ProfileName;
            rs[key]["allowdropbox"] = prfileData[0].allowdropbox;
            rs[key]["prtlID"] = dt.DisplayId;
            rs[key]["Status"] = "True";
            rs[key]["image_file"] = uploadfiles[s]["Files"][index].file.name;
            FinalImages.push(rs[key]);
          });
        }
        intialcnt += 1;
        this.setState({ prs: Math.round((intialcnt * 100) / ttlcnt) });
      }
    }
    this.setState({
      ImageData: FinalImages,
      loading: false,
      prs: 0,
    });
  }

  help() {
    Modal.info({
      title: "INSTRUCTION WHILE IMAGE IDENTIFICATION",
      okText: "CLOSE",
      width: "600px",
      closable: true,
      footer: null,
      bodyStyle: { maxHeight: "500px" },
      style: { padding: "0px" },
      centered: true,
      maskClosable: true,
      content: (
        <div>
          <ul>
            <li>Upload folder of images of a particular product</li>
            <li>
              Check <strong>Auto Correction</strong> to resize images with
              processing
            </li>
            <li>
              Images should be in <strong>JPEG,JPG</strong>
            </li>
            <li>
              Image background color should be <strong>white</strong>
            </li>
            <li>
              <span className="vcode">*</span>Indentification of Sub category
              Still under dovelopment
            </li>
            <li>Uploaded images should be between 50kb - 7MB</li>
            <li>
              Uploaded images should not be less the (800 X 800) dimension
            </li>
            <li>User can not upload more then 350 images at once</li>
          </ul>
        </div>
      ),
    });
  }

  async downloadRectifiedImage() {
    let ImgData = this.state.ImageData;
    // for (let i = 0; i < ImgData.length; i++) {
    //     if (ImgData[i].Status === 'True') {
    //         var a = document.createElement("a");
    //         a.href = ImgData[i]['IMAGE'];
    //         a.download = ImgData[i].ProductName + '-' + ImgData[i].Angle + '.jpg';
    //         a.click();
    //         await sleep(1000)
    //     }
    // }
    var grouped = _.mapValues(_.groupBy(ImgData, "ProductName"), (clist) =>
      clist.map((ImgData) => _.omit(ImgData, "ProductName"))
    ); // group images as per product Name
    let Imagessts = [];

    const objectArray = Object.entries(grouped);
    objectArray.forEach(([key, value]) => {
      Imagessts.push({ key: key, Files: value });
    });

    var zip = new JSZip();
    var zipmain = zip.folder("Images");
    for (let i = 0; i < Imagessts.length; i++) {
      var ele = Imagessts[i];
      let imgdt = ele.Files;
      var img = zipmain.folder(ele.key);
      var groupProfile = _.mapValues(_.groupBy(imgdt, "PortalName"), (clist) =>
        clist.map((imgdt) => _.omit(imgdt, "PortalName"))
      ); // group images as per Profile Name
      let profileImages = [];
      let profileArray = Object.entries(groupProfile);
      profileArray.forEach(([key, value]) => {
        profileImages.push({ profile: key, Images: value });
      });
      console.log(profileImages);
      console.log(this.state.profiledt);
      for (let j = 0; j < profileImages.length; j++) {
        const ele1 = profileImages[j];
        if (!this.state.profiledt.includes(ele1.profile)) {
          continue;
        }
        var subimg = img.folder(ele1.profile);

        for (let k = 0; k < ele1.Images.length; k++) {
          const ele2 = ele1.Images[k];
          subimg.file(
            ele.key + "-" + ele2.Angle + ".jpg",
            ele2["data"].replace("data:image/jpg;base64,", ""),
            { base64: true }
          );
        }
      }
    }

    zip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(content, "Rectified Images.zip");
    });
    this.setState({
      profiledt: [],
    });
  }

  storeImageTable() {
    return (
      <div>
        <div className="table-responsive" style={{ height: "65vh" }}>
          <table id="ft" className="table table-hover">
            <thead style={{ position: "sticky" }}>
              <tr>
                {" "}
                <th>SR NO.</th>
                <th>Folder Name</th>
                <th>Files</th>
                <th>Product</th>
                <th>Change Product</th>
              </tr>
            </thead>
            <tbody>
              {this.state.groupedImg.map((value, index) => {
                return (
                  <tr key={index}>
                    <td>
                      {index + 1}&nbsp;&nbsp;
                      <i
                        className="fa fa-trash vcode"
                        onClick={(e) => this.deleteRow(index)}
                      ></i>
                    </td>
                    <td>{value.key}</td>
                    <td>{value.Files.length}</td>
                    <td id={value.productid}>{value.productname}</td>
                    <td>
                      <AutoCompleteCascad
                        id="ValueID"
                        frmNm="FRMIMAGE"
                        quryNm="FILLPARENT"
                        db="IMAGEDB"
                        filter1="OrgID"
                        filterId1={this.state.orgid}
                        filter2=""
                        filterId2=""
                        placeholder="Please Select Parent"
                        onAfterSelect={(e) =>
                          this.onAfterSelect(e, "ValueID", "FValue", index)
                        }
                      ></AutoCompleteCascad>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  deleteRow(index) {
    let st = this.state.groupedImg;
    st.splice(index, 1);
    this.setState({ groupedImg: st });
  }

  changeAngle(i, nameAngle, e) {
    let Angle = e.target.value;
    let st = this.state.ImageData;
    st[i].Angle = Angle;
    this.setState({ ImageData: st });
  }

  changeSubCategory(i, e) {
    let st = this.state.ImageData;
    st[i].SubCategory = e.target.value;
    this.setState({ ImageData: st });
  }

  chkProfileChange(evt) {
    let facIds = this.state.profiledt;
    if (evt.target.checked) {
      facIds.push(evt.target.value);
      this.setState({
        profiledt: facIds,
      });
    } else {
      facIds.pop(evt.target.value);
      this.setState({
        profiledt: facIds,
      });
    }
    this.forceUpdate();
  }

  renderFileInTable1() {
    Modal.info({
      title: "Choose Profile",
      okText: "CLOSE",
      width: "600px",
      closable: true,
      okButtonProps: { style: { display: "none" } },
      footer: null,
      bodyStyle: { maxHeight: "500px" },
      style: { padding: "0px" },
      centered: true,
      maskClosable: true,
      content: (
        <div>
          <div className="table-responsive" style={{ maxHeight: "63vh" }}>
            <table id="profile" className="table table-hover">
              <thead style={{ position: "sticky" }}>
                <tr>
                  {" "}
                  <th>SR NO.</th>
                  <th>Profile Name</th>
                </tr>
              </thead>
              <tbody>
                {this.state.profileID.map((value, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {index + 1}&nbsp;
                        <input
                          type="checkbox"
                          onChange={this.chkProfileChange.bind(this)}
                          id={value.RWID}
                          value={value.DisplayName}
                          defaultChecked={value.Status === "YES"}
                        />
                      </td>
                      <td>{value.DisplayName}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <label>
              <button
                onClick={this.downloadRectifiedImage.bind(this)}
                className="btn btn-block btn-primary"
              >
                Download Image{" "}
              </button>
            </label>
          </div>
        </div>
      ),
    });
  }

  render() {
    return (
      <div className="content-wrapper">
        <div
          className="loader"
          style={{ display: this.state.loading ? "block" : "none" }}
        >
          <div className="loader-item">
            <h3>{this.state.ldsts}</h3>
            <Progress type="circle" percent={this.state.prs} />
          </div>
        </div>
        <div
          className="loader"
          style={{ display: this.state.loadingspin ? "block" : "none" }}
        >
          <div className="loader-item">
            <Spin />
          </div>
        </div>
        <section className="content">
          <div
            id="ctl00_CPHMaincontent_divclient"
            className="box box-success"
            style={{ marginTop: "-13px" }}
          >
            <div className="box-header with-border">
              <h3 className="box-title">
                IMAGE IDENTIFICATION & CORRECTION
                <span
                  id="ctl00_CPHMaincontent_DivOrdNo"
                  style={{ display: "inline" }}
                ></span>
              </h3>
              <div className="pull-right">
                <button className="btn btn-info" onClick={this.help.bind(this)}>
                  Help
                </button>
              </div>
              <div className="pull-right" style={{ marginRight: "3px" }}>
                {(this.state.loginId === "10" ||
                  this.state.loginId === "1" ||
                  this.state.loginId === "6") && (
                  <ImageConfig logid={this.state.loginId} width="850px" />
                )}
              </div>
              <div className="pull-right" style={{ marginRight: "3px" }}>
                {(this.state.loginId === "10" ||
                  this.state.loginId === "1" ||
                  this.state.loginId === "6") && (
                  <PortalConfig logid={this.state.loginId} width="800px" />
                )}
              </div>
            </div>
            <div
              className="box-body"
              id="orderDetails"
              style={{ display: "block" }}
            >
              <div className="row">
                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                  <label>Select Image</label>
                  <span className="vcode">*</span>
                  <div className="form-group">
                    <input
                      accept="image/*"
                      name="imgUpload"
                      webkitdirectory=""
                      directory=""
                      className="form-control"
                      type="file"
                      id="imgUpload"
                      onChange={this.FileHandler.bind(this)}
                      multiple
                    />
                  </div>
                </div>
                <div className="col-xs-4 col-sm-4 col-md-2 margintop">
                  <label>Search digit</label>
                  <span className="vcode">*</span>
                  <div className="form-group">
                    <select
                      className="form-control"
                      onChange={this.myChangeHandler}
                      name="cmbdigit"
                    >
                      <option value="All">All</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                    </select>
                  </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-1 margintop">
                  <label>&nbsp;</label>
                  <div className="form-group">
                    <input
                      type="button"
                      value="Add"
                      onClick={this.storeImages.bind(this)}
                      className="btn btn-block btn-primary"
                    />
                  </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-1 margintop">
                  <label>&nbsp;</label>
                  <div className="form-group">
                    <input
                      type="button"
                      value="Next >"
                      onClick={this.TOProcessImage.bind(this)}
                      className="btn btn-block btn-primary"
                    />
                  </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                  <label>&nbsp;</label>
                  <div className="form-group">
                    {this.state.ImageData.length > 0 && (
                      <input
                        type="button"
                        value="Upload DropBox"
                        onClick={this.UploadImages.bind(this)}
                        className="btn btn-block btn-primary"
                      />
                    )}
                  </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                  <label>&nbsp;</label>
                  <div className="form-group">
                    {this.state.RejectedImage.length > 0 && (
                      <input
                        type="button"
                        value="Rejected Image"
                        onClick={this.RejectedImageFunc.bind(this)}
                        className="btn btn-block btn-primary"
                      />
                    )}
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 margintop">
                  {this.state.loading === false &&
                    this.state.ImageData.length > 0 &&
                    this.renderFileInTable()}
                  {/* {this.state.loading === false && this.state.ImageData.length > 0 &&
                                     <AntD 
                                     data={[...this.state.ImageData]}
                                     >
                                    </AntD>} */}
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 margintop">
                  {this.state.loading === false &&
                    this.state.groupedImg !== undefined &&
                    this.state.ImageData.length === 0 &&
                    this.storeImageTable()}
                </div>
                <div className="col-xs-6 col-sm-6 col-md-2 margintop pull-right">
                  {this.state.loading === false &&
                    this.state.ImageData.length > 0 && (
                      <button
                        onClick={this.renderFileInTable1.bind(this)}
                        className="btn btn-block btn-success"
                      >
                        Download Processed Image
                      </button>
                    )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }

  async dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  renderFileInTable() {
    //let rs = await getDataTable("select Angle from Anglemaster")
    return (
      <div>
        <div className="pull-right" style={{ marginTop: "-30px" }}></div>
        <div className="table-responsive" style={{ maxHeight: "61vh" }}>
          <table id="filetable" className="table table-hover">
            <thead style={{ position: "sticky" }}>
              <tr>
                {" "}
                <th>SR NO.</th>
                <th>Image</th>
                <th>Old Name</th>
                <th>Name</th>
                <th>Size</th>
                <th>W X H</th>
                <th>Angle</th>
                {/* <th>Sub Category<sup>beta</sup></th> */}
                <th>Image Profile</th>
                <th>Show Action</th>
              </tr>
            </thead>
            <tbody>
              {this.state.ImageData.map((value, index) => {
                return (
                  <tr
                    key={index}
                    style={{
                      backgroundColor:
                        value.Status === "False" ? "#ba5d65" : "white",
                    }}
                  >
                    <td>{index + 1}&nbsp;</td>
                    <td>
                      <a target="_blank" href={value["data"]}>
                        <img
                          style={{ height: "70px", width: "70px" }}
                          className="zoom"
                          src={value["data"]}
                        />
                      </a>
                    </td>
                    <td>{value.ProductName + "-" + value.image_file}</td>
                    <td>{value.ProductName + "-" + value.Angle + ".jpg"}</td>
                    <td>{parseInt(value.size["size"]) / 1000} KB</td>
                    <td>{value.Height + "X" + value.Width}</td>
                    <td>
                      <select
                        id={index}
                        disabled={value.Status === "False"}
                        onChange={this.changeAngle.bind(
                          this,
                          index,
                          value.name
                        )}
                      >
                        <option value="">None</option>
                        {Anglers.map((opn, i) => {
                          let d = opn.Angle;
                          return (
                            <option
                              value={d}
                              selected={d === value.Angle}
                              key={i}
                            >
                              {d}
                            </option>
                          );
                        })}
                      </select>
                    </td>
                    {/* <td>{value.SubCategory !== "Reject" &&
                                        <select id={index} disabled={value.Status === "False"} onChange={this.changeSubCategory.bind(this, index)} >
                                            <option value=''>None</option>
                                            {
                                                SubCategories.map((opn, i) => {
                                                    let d = opn.OptValue;
                                                    return (
                                                        <option value={d} selected={d === value.SubCategory} key={i}>{d}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    }</td> */}
                    <td>{value.PortalName}</td>
                    <td>
                      <div className="form-group">
                        <input
                          type="button"
                          value="Show Action"
                          onClick={this.ActionPreview.bind(this, value)}
                          className="btn btn-block btn-danger"
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  ActionPreview(dt) {
    const keys = Object.keys(dt);
    Modal.info({
      title: "ACTION TAKEN LIST",
      okText: "CLOSE",
      width: "600px",
      okButtonProps: { style: { display: "none" } },
      closable: true,
      footer: null,
      bodyStyle: { maxHeight: "500px" },
      style: { padding: "0px" },
      centered: true,
      maskClosable: true,
      content: (
        <div style={{ maxHeight: "400px", overflowY: "scroll" }}>
          <ol>
            {keys.map((value, index) => {
              if (value.startsWith("#")) {
                return (
                  <li>
                    <b>{value}</b> - {String(dt[value])}
                  </li>
                );
              }
            })}
          </ol>
        </div>
      ),
    });
  }
}
