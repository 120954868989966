import { Modal } from 'antd';
import React, { Component } from 'react';
import '../../ORDER/OrderMaster/style.css'
import Table from '../../Actions/Controls/Table/AntDTable'
import TFilter from './frmTemplateFilter'
import { Configuration, MandatoryFormFields, getDataTable, arrUnique } from '../../Configuration';
import AutoCompleteInput from '../../Actions/Controls/AutoComplete/autoComplete';
import AutoCompleteCascad from '../../Actions/Controls/AutoComplete/autoComplete.cascad';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

export default class frmCloneTemplate extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            modal1Visible: false,
            modal2Visible: false,
            loginId: 0,
            fldt: []
        };
    }

    async componentDidMount() {
        let rs = await getDataTable("select orgid from users where userid = " + Configuration.USER_ID)
        await this.setState({
            loginId: Configuration.USER_ID,
            orgid: rs[0].orgid
        });
    }

    setModal1Visible(modal1Visible) {
        this.setState({ modal1Visible });
    }

    setModal2Visible(modal2Visible) {
        this.setState({ modal2Visible });
    }

    async onAfterSelect(selectedOptions, id, name) {
        if (selectedOptions.length > 0) {
            this.setState({
                [id]: selectedOptions[0].DisplayId,
                [name]: selectedOptions[0].DisplayName,
            })
        }
        else {
            this.setState({
                [id]: '',
                [name]: '',
            })
        }
    }


    myChangeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    async SaveTamplate(e) {
        let MandatoryArray = [
            { "Template": this.state.tempId },
            { "Template Name": this.state.tempName },
            { "description": this.state.tempDiscrption },
            { "Filters": this.state.fldt }
        ]
        let check = MandatoryFormFields(MandatoryArray);
        if (check == false) {
            return false;
        }
        let que = "EXEC frmCloneTemplate_CloneTemplate @templateID = " + this.state.tempId
        que += " , @TemplateName = '" + this.state.tempName + "' "
        que += " , @description = '" + this.state.tempDiscrption + "'"
        que += " , @loginID = " + this.state.loginId

        let rs = await getDataTable(que)
        if (rs.length > 0) {
            var requestOptions = {
                method: 'POST',
                redirect: 'follow'
            };
            let qrystr = "MainTemplateId=" + this.state.tempId + "&NewTemplateId=" + rs[0].TemplateId + "&PortalName=" + this.state.portalname
            let rs1 = await fetch("https://api.blumangoes.ai:6143//GetExcel/cloneTemplateExcel?" + qrystr, requestOptions)
            let dt2 = await rs1.json()

            /////////////////////////////////////////////////////////////////////////////// filter saving
            let arr = ["10", "6", "1", "10019"]
            if (arr.includes(this.state.loginId)) {
                let tempdata1 = this.state.fldt
                let filterSTR = JSON.stringify(tempdata1)
                let q = "EXEC SaveTemplateFilters @loginId = " + this.state.loginId + ", @dtJSON = '" + filterSTR + "' , @tid = " + this.state.tempId
                let r = await getDataTable(q)
                if (r[0]["Status"] == "Done") {
                    await Swal.fire({
                        icon: 'success',
                        title: "Filters Updated",
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            }




            //////////////////////////////////////////////////////////
            await Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'SUCCESSFULLY CREATED',
                showConfirmButton: false,
                timer: 2000
            })
            await this.setModal2Visible(false)
        }

    }

    render() {

        return (
            <div>
                <button type="button" onClick={() => this.setModal2Visible(true)} className="btn btn-success btn-block">CLONE TEMPLATE</button>

                <Modal
                    title="CLONE TEMPLATE"
                    width={this.props.width}
                    visible={this.state.modal2Visible}
                    onOk={() => this.setModal2Visible(false)}
                    onCancel={() => this.setModal2Visible(false)}
                    destroyOnClose={true}
                    maskClosable={false}
                    footer={null}
                >
                    <div className='row'>
                        <div className="col-xs-6 col-sm-6 col-md-3 margintop">
                            <label>Select Portal</label>
                            <AutoCompleteInput
                                id="portalid"
                                frmNm="FRMATTRIBUTEALIASE"
                                quryNm="FILLPORTAL"
                                db="IMAGEDB"
                                placeholder="Please Select Portal"
                                onAfterSelect={(e) => this.onAfterSelect(e, "portalid", "portalname")}
                                isValid={this.state.isValid}
                            ></AutoCompleteInput>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-md-3 margintop">
                            <label>Select Block</label>
                            <AutoCompleteCascad
                                id="catid"
                                frmNm="FRMALLDATA"
                                quryNm="FILLORGBLOCKS"
                                db="IMAGEDB"
                                filter1="orgid"
                                filterId1={this.state.orgid}
                                filter2=""
                                filterId2=""
                                placeholder="Please Select Block"
                                onAfterSelect={(e) => this.onAfterSelect(e, "catid1", "catname1")}
                                isValid={this.state.isValid}
                            ></AutoCompleteCascad>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-md-3 margintop">
                            <label>Select Template</label>
                            <AutoCompleteCascad
                                id="catid"
                                frmNm="FRMUPDATEATTRIBUTEALIASING"
                                quryNm="FILLTEMPLATE"
                                db="IMAGEDB"
                                filter1='CatID'
                                filterId1={this.state.catid1}
                                filter2='PortalID'
                                filterId2={this.state.portalid}
                                placeholder="Please Select Template"
                                onAfterSelect={(e) => this.onAfterSelect(e, "tempId", "templateName")}
                                isValid={this.state.isValid}
                            ></AutoCompleteCascad>
                        </div>
                        <div className='col-xs-6 col-sm-4 col-md-3 margintop'>
                            <label>Template Name</label><span className="vcode">*</span>
                            <div className="form-group">
                                <input type='Text' id='tempName' value={this.state.tempName} defaultValue={this.state.tempName} autoComplete='off' name='tempName' onChange={this.myChangeHandler} className="form-control" ></input>
                            </div>
                        </div>
                        <div className='col-xs-6 col-sm-4 col-md-4 margintop'>
                            <label>Discription</label>
                            <div className="form-group">
                                <input type='Text' id='tempDiscrption' value={this.state.tempDiscrption} defaultValue={this.state.tempDiscrption} autoComplete='off' name='tempDiscrption' onChange={this.myChangeHandler} className="form-control" ></input>
                            </div>
                        </div>
                        <div className='col-xs-6 col-sm-3 col-md-2 margintop' >
                            <label>&nbsp;</label>
                            <div className='form-group'>
                                <TFilter width='800px' data={this.state.fldt} logid={this.state.loginId} catid={this.state.catid1} />
                            </div>
                        </div>
                        <div className='col-xs-6 col-sm-3 col-md-2 margintop' >
                            <label>&nbsp;</label>
                            <div className='form-group'>
                                <button className='btn btn-primary' onClick={this.SaveTamplate.bind(this)}>Save Template</button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }

}