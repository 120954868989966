import React, { Component } from 'react';
import { Configuration, getDataTable, MandatoryFormFields, arrUnique,exceltodb,json2xlsx} from '../Configuration';
import Swal, { swal } from 'sweetalert2/dist/sweetalert2.js'
import Moment from "moment";
import AntDTable from '../Actions/Controls/Table/AntDTable'
import AutoCompleteCascad from '../Actions/Controls/AutoComplete/autoComplete.cascad'
import 'sweetalert2/src/sweetalert2.scss'
import "antd/dist/antd.css";
const dataBase = "IMAGEDB";
const currentDate = Moment().format("DD-MMM-YYYY hh:mm:ss");


export default class frmStatusMarking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginId: 0,
            dt: [],
            lotID:0,
            orgid: "",
            Pid:0,
            data: [],
            atList: [],
            status: 'Submit',
            today : new Date().toISOString().split('T')[0],
            files: ""
        };
        
    }

    async componentDidMount() {
        let rs = await getDataTable("select orgid from users where userid = " + Configuration.USER_ID)
        await this.setState({ loginId: Configuration.USER_ID, orgid: rs[0].orgid });
    }

    myChangeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    onAfterSelect(selectedOptions, id, name) {
        if (selectedOptions.length > 0) {
            this.setState({
                [id]: selectedOptions[0].DisplayId,
                [name]: selectedOptions[0].DisplayName,
                dt:[]
            })
        }
        else {
            this.setState({ [id]: '', [name]: '',dt:[] })
        }
    }

    async selectedDataFN(e) {
        if (e.length > 0) {
            this.setState({ data: e })
        }
        else {
            this.setState({ data: [] ,
                            status: 'Submit',
                            plid: '',
                            statusdate: this.state.today })
        }
    }

    async getProduct() {
        let MandatoryArray = [
            { "Portal": this.state.Pid },
            { "Lot": this.state.lotID}
        ]
        let check = MandatoryFormFields(MandatoryArray);
        if (check == false) {
            this.setState({ loading: false })
            return false;
        }
        this.setState({ dt: [],
                        data: [],
                        status: 'Submit',
                        plid: '',
                        statusdate: this.state.today })
        this.setState({ loading: true});
        let que = "EXEC FrmStatusMarking_GetProductListNew @LotID = "+this.state.lotID+" , @PortalID = "+this.state.Pid
        let rs = await getDataTable(que)
        this.setState({ loading: false});
        this.setState({ dt: rs })
        if(this.state.dt.length==0){
            Swal.fire({
                position: 'top-end',
                icon: 'info',
                title: 'Products on this lot are already marked.',
                showConfirmButton: false,
                timer: 1500
            })
        }
    }

    async SaveValues() {
        let MandatoryArray = [
            { "Portal": this.state.Pid },
            { "Portal Lot No": this.state.plid }
        ]
        let check = MandatoryFormFields(MandatoryArray);
        if (check == false) {
            return false;
        }

        if (this.state.data.length == 0){
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'PLEASE SELECT PRODUCTS',
                showConfirmButton: false,
                timer: 1500
            })
            return false;
        }

        let proid = ''
        for (let i = 0; i < this.state.data.length; i++) {
            if (proid === '') {
                proid = this.state.data[i]['#prodID']
            } else {
                proid = proid + ',' + this.state.data[i]['#prodID']
            }
        }
        this.setState({ loading: true});
        let Que = "EXEC UpDateLiveStatusNEW @plist='" + proid + "' , @portalID=" + this.state.Pid + ", @logid=" + this.state.loginId + ", @status='" + this.state.status + "', @plid = '" + this.state.plid + "', @lotid = " + this.state.lotID + ", @statusdate = '" + this.state.statusdate + "'"
        let rs = await getDataTable(Que)
        this.setState({dt:[],
                        data:[],
                        loading: false
                    })
        if (Array.isArray(rs)) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Marked Successfully',
                showConfirmButton: false,
                timer: 1500
            })
            }
        else{
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Marking could not be done.',
                showConfirmButton: false,
                timer: 1500
            })
        }
    }

    myFileChangeHandler = (event) => {
        const file = event.target.files[0];
        console.log('File type:', Object.prototype.toString.call(file));
        this.setState({ files: file, upload: 1 });
    };

    async UploadFile() {
        this.setState({ loading: true });
        let MandatoryArray = [ { "Portal": this.state.Pid },{"File": this.state.files}];
        let check = MandatoryFormFields(MandatoryArray);
        if (check === false) {
            this.setState({ loading: false });
            return false;
        }
        let rs = await exceltodb(this.state.files, dataBase, this.state.loginId);
        let que = "	Select productID AS #prodID,p.[SKU CODE] AS [Product Name] from " + rs
        que += " p inner join productmaster on p.[SKU CODE] = Productmaster.ProductName where orgid ="+this.state.orgid+" and ( "
        que += " ProductMaster.ProductID NOT IN (Select distinct productid from LiveStatus where portalID ="+this.state.Pid+" and (FldVal = 'Live' or FldVal = 'Submit')) "
        que += " or ProductMaster.ProductID NOT IN (select distinct productID from PortalDataMaster where PortalID ="+this.state.Pid+")) "
        let dt = await getDataTable(que);
        debugger
        if(dt.length> 2000){
            Swal.fire({
                icon: "error",
                title: "SKU List can not be more then 2000",
                showConfirmButton: false,
                timer: 1500,
            });
            return false;
        }
        this.setState({ data: dt, dt:dt,loading:false})
        Swal.fire({
            icon: "success",
            title: dt.length+" SKU UPLOADED",
            showConfirmButton: false,
            timer: 3000,
        });
    }

    async DownloadSKUTemplate() {
        let arr = [{
            "SKU CODE": ""
        }]
        await json2xlsx(arr, "SKU_TEMPLATE.xlsx"+ "_" + currentDate + "_" + "1.xlsx");

    }

    render() {
        return (
            <div className="content-wrapper">
                <section className="content">
                    <div id="ctl00_CPHMaincontent_divclient" className="box box-success" style={{ marginTop: '-13px' }}>
                        <div className="box-header with-border">
                            <h3 className="box-title">STATUS MARKING
                                <span id="ctl00_CPHMaincontent_DivOrdNo" style={{ display: 'inline' }}>
                                </span>
                            </h3>
                            <div className='pull-right' style={{ marginRight: '2px' }}>
                                <label>&nbsp;</label>
                                <button className='btn btn-primary' onClick={this.DownloadSKUTemplate.bind(this)}>SKU TEMPLATE</button>
                            </div>
                        </div>
                        <div className="box-body" id="orderDetails" style={{ display: 'block' }}>
                            <div className="row">
                                <div className='col-xs-12 col-sm-12 col-md-6 margintop'>
                                    <div className='row'>
                                   
                                        <div className="col-xs-6 col-sm-6 col-md-3 margintop">
                                            <label>Select Portal</label><span className='vcode'>*</span>
                                            <AutoCompleteCascad
                                                id="Pid"
                                                frmNm="FRMSTATUSMARKING"
                                                quryNm="FILLPORTAL"
                                                db="IMAGEDB"
                                                filter2=""
                                                filterId2=""
                                                filter1="em.OrgID"
                                                filterId1={this.state.orgid}
                                                placeholder="Please Select Portal"
                                                onAfterSelect={(e) => this.onAfterSelect(e, "Pid", "Pname")}
                                                isValid={this.state.isValid}
                                            ></AutoCompleteCascad>
                                        </div>
                                        <div className='col-xs-6 col-sm-6 col-md-3'>
                                            <label>Select Submit Lot</label><span className="vcode">*</span>
                                            <AutoCompleteCascad
                                                id="catid"
                                                frmNm="FRMSTATUSMARKING"
                                                quryNm="FILLLOT"
                                                db="IMAGEDB"
                                                filter1="portalID"
                                                filterId1={this.state.Pid}
                                                filter2="em.OrgID"
                                                filterId2={this.state.orgid}
                                                placeholder="Please Select Lot"
                                                onAfterSelect={(e) => this.onAfterSelect(e, "lotID", "LotName")}
                                                isValid={this.state.isValid}
                                            ></AutoCompleteCascad>
                                        </div>
                                        <div className='col-xs-6 col-sm-6 col-md-3'>
                                            <label>&nbsp;</label>
                                            <div className='form-group'>
                                                <input type='button' onClick={this.getProduct.bind(this)} value='Get' className='btn btn-block btn-primary'></input>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="col-xs-6 col-sm-6 col-md-3 margintop" >
                                    <label>UPLOAD FILE</label>
                                    <span className="vcode">*</span>
                                    <div className="form-group">
                                        <input
                                            name="fldname"
                                            type="file"
                                            accept=".xls,.xlsx,.xlsm,.csv"
                                            id="tp1"
                                            onChange={this.myFileChangeHandler}
                                            placeholder="Enter FieldName"
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                                <label>&nbsp;</label>
                                                <div className="form-group">
                                                    <button onClick={this.UploadFile.bind(this)} className="btn  btn-block btn-primary" text=""> Upload File </button>
                                                </div>
                                            </div>
                                        <div style={{ display: this.state.dt.length > 0 ? 'block' : 'none' }}>
                                            {/* <div  className='col-xs-6 col-sm-6 col-md-3'>
                                                <label>Select Status</label><span className='vcode'>*</span>
                                                <div className='form-group'>
                                                    <select name='status' onChange={this.myChangeHandler.bind(this)} className='form-control' value = {this.state.status} defaultValue = {this.state.status}>
                                                        <option disabled value="">Select Value</option>
                                                        <option value='Submit'>Submit</option>
                                                        <option value='Live'>Live</option>
                                                        <option value='Submit Not Live'>Submit Not Live</option>
                                                    </select>
                                                </div>
                                            </div> */}
                                            <div  className='col-xs-6 col-sm-6 col-md-3'>
                                                <label>Enter Portal Lot No.</label><span className="vcode">*</span>
                                                <div className="form-group">
                                                    <input type = "text" onChange={this.myChangeHandler.bind(this)} name="plid" id="plid" placeholder="Enter Portal Lot No." tabIndex={1} className="form-control" value = {this.state.plid} defaultValue = {this.state.plid}/>
                                                </div>
                                            </div>
                                            <div  className='col-xs-6 col-sm-6 col-md-3'>
                                                <label>Enter Date</label><span className="vcode">*</span>
                                                <div className="form-group">
                                                    <input type = "date" max = {this.state.today} onChange={this.myChangeHandler.bind(this)} name="statusdate" id="statusdate" placeholder="Enter Date" tabIndex={1} className="form-control"  value = {this.state.statusdate} defaultValue = {this.state.today}/>
                                                </div>
                                            </div>
                                            <div className='col-xs-6 col-sm-6 col-md-3'>
                                                <label>&nbsp;</label>
                                                <div className='form-group'>
                                                    <input type='button' value='Submit' onClick={this.SaveValues.bind(this)} className='btn btn-block btn-success'></input>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                        <div className="col-xs-12 col-sm-12 col-md-12 margintop" style={{width:"800px"}}>
                                            {this.state.dt.length > 0 && this.state.dt != undefined &&
                                                <AntDTable
                                                data={[...this.state.dt]}
                                                selected="true"
                                                SelectedFn={(e) => this.selectedDataFN(e)}>
                                                </AntDTable>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

    // renderFileInTable() {
    //     return (
    //         <div>
    //             <div className="table-responsive" style={{ maxHeight: '74vh' }}>
    //                 <table id="filetable" className="table table-hover">
    //                     <thead style={{ position: "sticky" }}>
    //                         <tr> <th>SR NO. &nbsp; <input type='checkbox' onChange={this.SelectAllFN.bind(this)} /></th>
    //                             <th>ProductName</th>
    //                         </tr>
    //                     </thead>
    //                     <tbody>
    //                         {this.state.dt.map((value, index) => {
    //                             return <tr key={index}>
    //                                 <td>{index + 1}&nbsp;<input type="checkbox" value={value.productid} onChange={this.SelectFN.bind(this, value)}></input></td>
    //                                 <td>{value.productName.toUpperCase()}</td>
    //                             </tr>
    //                         })}
    //                     </tbody>
    //                 </table>
    //             </div>
    //         </div>
    //     )

    // }

    // renderFinalTable() {
    //     return (
    //         <div>
    //             <div className="table-responsive" style={{ maxHeight: '67vh' }}>
    //             {
    //                                             <AntD
    //                                             data={this.state.data}
    //                                             Delete="true"
    //                                             DeleteFn={(e) => this.deleteRow(e)}
    //                                             selected="true"
    //                                             SelectedFn={(e) => this.selectedDataFN(e)}>
    //                                             </AntD>
    //                                         }
    //             </div>
    //         </div>
    //     )

    // }

    // deleteRow(e) {
    //     let tdt = this.state.data
    //     tdt.splice(e, 1);
    //     this.setState({ data: tdt })
    // }

    

    // SelectFN(e, f) {
    //     let alist = this.state.atList
    //     if (f.target.checked) {
    //         alist.push(e.productid)
    //     }
    //     else {
    //         var index = alist.indexOf(e.productid);
    //         if (index > -1) {
    //             alist.splice(index, 1);
    //         }
    //     }
    //     this.setState({
    //         atList: alist
    //     })
    // }

    // SelectAllFN(e) {
    //     let alist = []
    //     var tbl = document.getElementById('filetable');
    //     var inputs = tbl.getElementsByTagName('input')
    //     if (e.target.checked) {
    //         for (let i = 0; i < inputs.length; i++) {
    //             alist.push(parseInt(inputs[i].value))
    //             inputs[i].checked = "true"
    //         }
    //     }
    //     else {
    //         for (let i = 0; i < inputs.length; i++) {
    //             inputs[i].checked = false
    //         }
    //     }
    //     this.setState({
    //         atList: alist
    //     })
    // }

        // cleanList() {
    //     this.setState({ data: [] })
    // }

    // async addProduct() {

    //     if (this.state.dt.length > 0 && this.state.atList.length > 0) {
    //         let tempData = this.state.data

    //         for (let i = 0; i < this.state.atList.length; i++) {
    //             for (let j = 0; j < this.state.dt.length; j++) {
    //                 if (this.state.dt[j]['#prodID'] === this.state.atList[i]['#prodID']) {
    //                     tempData.push(this.state.dt[j])
    //                     break;
    //                 }
    //             }
    //         }
    //         tempData = await arrUnique(tempData)
    //         this.setState({ data: tempData })
    //     }
    //     else {
    //         Swal.fire({
    //             position: 'top-end',
    //             icon: 'warning',
    //             title: 'Product Not Saved',
    //             showConfirmButton: false,
    //             timer: 1500
    //         })
    //     }

    //     // this.props.data = tempData
    //     // this.props.data.splice(0, this.props.data.length)
    //     // for (let j = 0; j < tempData.length; j++) {
    //     //     this.props.data.push(tempData[j])
    //     // }
    //     // // this.setState({ finallist: tempData })
    //     // this.forceUpdate();
    //     // console.log(tempData);
    // }
