
import { FETCH_ORDER_REQUEST, FETCH_ORDER_SUCCESS, FETCH_ORDER_FAILURE } from '../../Actions/GET_ACTIONS/ORDER_GET_ACTIONS/GetOrderActions'

import {FETCH_USER_REQUEST, FETCH_USER_SUCCESS, FETCH_USER_FAILURE} from  '../../Actions/GET_ACTIONS/ORDER_GET_ACTIONS/GetOrderActions'

export default function fetchUSER(state = {
   isFetching: false
 }, action) {
 switch (action.type) {
   case FETCH_USER_REQUEST:
     return Object.assign({}, state, {
       isFetching: true
     })
   case FETCH_USER_SUCCESS:
     return Object.assign({}, state, {
       isFetching: false,
       dataItem: action.dataUSER
     })    
   case FETCH_USER_FAILURE:
     return Object.assign({}, state, {
       isFetching: false
     })
   default:
     return state
 }
}
// export default function  fetchORDER(state = {
//    isFetching: false
//  }, action) {
//  switch (action.type) {
//    case FETCH_ORDER_REQUEST:
//      return Object.assign({}, state, {
//        isFetching: true
//      })
//    case FETCH_ORDER_SUCCESS:
//      return Object.assign({}, state, {
//        isFetching: false,
//        dataORDER: action.response
//      })    
//    case FETCH_ORDER_FAILURE:
//      return Object.assign({}, state, {
//        isFetching: false
//      })
//    default:
//      return state
//  }
// }
