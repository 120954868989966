import { Dropdown, Spin } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router";
import PageTitle from "../../Common/pageTitle";
import { Table, Card, Button, Space, Select } from "antd";
import { APIService } from "../../../Config/action/apiAction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EditOutlined, SearchOutlined } from "@ant-design/icons";
import { stat } from "fs";


function ImportSheetList(props) {
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState("");
  const [userDataId, setUserId] = useState(0);
  const [listData, setListData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const history = useHistory();


  useEffect(() => {
    defaultUseEffect();
  }, []);

  const deleteTemplate = (e, temp) => {
    e.stopPropagation();
    e.preventDefault();
    setLoading(true);
    let payload = {
      query: `exec frmImportSheetPortal_DeleteImportSheetAPI @templateID = ${temp.TemplateID}`,
    };
    props
      .dispatch(APIService("DYNAMIC_NEW_POST", "", payload))
      .then((data) => {
        setLoading(false);
        if (data.status === 200 && data.data.status === 1000) {
          showToast("Template deleted successfully");
          defaultUseEffect();
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const showToast = (message) => {
    toast(message, {
      position: "top-right",
      type: "success",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  };

  const defaultUseEffect = () => {
    setLoading(true);
    let userId = JSON.parse(localStorage.getItem("auth_user")).ID;
    setUserId(userId);
    let payload = {
      query: "frmImortSheet_GetImportSheet",
    };
    props
      .dispatch(APIService("DYNAMIC_NEW_POST", "", payload))
      .then((data) => {
        setLoading(false);
        if (data.status === 200 && data.data.status === 1000) {
          setListData(data.data.data);
          let values = {};

          // Unique columns name
          let keys = Object.keys(data.data.data[0]);
          keys.map((col, index) => {
            data.data.data &&
              data.data.data.map((item, pos) => {
                if (values[col] && values[col].indexOf(item[col]) === -1) {
                  values[col].push(item[col]);
                } else if (!values[col]) {
                  values[col] = [item[col]];
                }
              });
          });

          // Defining dynamic columns
          let columns = [];
          keys.map((item, index) => {
            let payload = {
              title: item,
              dataIndex: item,
              key: item,
              ...getColumnSearchProps(item, values),
            };
            columns.push(payload);
          });
          columns.push({
            title: "Action",
            dataIndex: "",
            key: "x",
            render: (text, record) => (
              <>
                <button
                  onClick={(e) =>
                    props.history.push(
                      `/Import-sheet/templates/edit/${record.TemplateID}`
                    )
                  }
                  className="btn btn-primary"
                  style={{ marginRight: "10px" }}
                >
                  View
                </button>

                <button
                  onClick={(e) => deleteTemplate(e, record)}
                  className="btn btn-danger"
                >
                  Delete
                </button>
              </>
            ),
          });
          setColumns(columns);
        } else {
          throw 500;
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getColumnSearchProps = (dataIndex, val) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Select
          mode="multiple"
          // ref={(node) => {
          //    this.searchInput = node;
          // }}
          allowClear
          style={{ width: 188, marginBottom: 8, display: "block" }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys}
          onChange={(values) => setSelectedKeys([...values])}
        >
          {val &&
            val[dataIndex].map((key, index) => {
              return <Option key={key}>{key}</Option>;
            })}
        </Select>
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      if (record[dataIndex]) {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  return (
    <div className="content-wrapper">
      <div className="loader" style={{ display: loading ? "block" : "none" }}>
        <div className="loader-item">
          <Spin />
        </div>
      </div>
      <ToastContainer />

      <section className="content">
        <div
          id="ctl00_CPHMaincontent_divclient"
          className="box box-success"
          style={{ marginTop: "-13px" }}
        >
          <div className="box-header with-border">
            <h3 className="box-title">
              Template Listing
              {/* <sup>
            (<span className="vcode">*</span> Fields are mandatory)
          </sup> */}
              <span
                id="ctl00_CPHMaincontent_DivOrdNo"
                style={{ display: "inline" }}
              ></span>
            </h3>
            <div className="pull-right">
              <button
                onClick={() => history.push("/Import-sheet/templates/create")}
                className="btn btn-primary"
              >
                Create
              </button>
            </div>
          </div>
          <br /> <br />
          <div
            className="box-body"
            id="orderDetails"
            style={{ display: "block" }}
          >
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 margintop">
                {listData.length > 0 && listData != undefined && (
                  <Table
                    style={{ width: "100%", cursor: "pointer" }}
                    rowKey={(record) => record.OrganizationCode}
                    columns={columns}
                    dataSource={listData}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: () => {
      dispatch({
        type: "loginUser",
      });
    },
    logoutUser: () => {
      dispatch({
        type: "logoutUser",
      });
    },
    dispatch,
  };
};

export default connect(null, mapDispatchToProps)(withRouter(ImportSheetList));
