import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { span, withRouter, useHistory } from "react-router-dom";
import { APIService } from "../../../Config/action/apiAction";
import PageTitle from "../../Common/pageTitle";
import { Spin, Table, Card, Modal, Select, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
// import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function RequestDetail(props) {
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState([]);
  const [charges, setCharges] = useState([]);
  const [title, setTitle] = useState("");

  const history = useHistory();

  useEffect(() => {
    const orgID = props.match.params.requestId;
    if (orgID) {
      getPlan(orgID);
    } else {
      props.goBack();
    }
  }, []);

  const getPlan = (orgID) => {
    setLoading(true);
    let payload = {
      query: `PricingModule_UnApprovedPlanOrgWise @orgID = ${orgID}`,
    };
    props
      .dispatch(APIService("DYNAMIC_NEW_POST", "", payload))
      .then((data) => {
        setLoading(false);
        if (data.status === 200 && data.data.status === 1000) {
          if (data.data.data.length > 0) {
            setTitle(data.data.data[0].OrgName);
          }
          setPlans(data.data.data);

          getCharges(orgID);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getCharges = (orgID) => {
    setLoading(true);
    let payload = {
      query: `PricingModule_UnapprovedchargeOrgWise @orgID = ${orgID}`,
    };
    props
      .dispatch(APIService("DYNAMIC_NEW_POST", "", payload))
      .then((data) => {
        setLoading(false);
        if (data.status === 200 && data.data.status === 1000) {
          if (data.data.data.length > 0) {
            setTitle(data.data.data[0].OrgName);
          }
          // if(data.data.data.length === 0 && plans.length === 0) {
          //    props.history.push("/approve-request/list");
          // }
          setCharges(data.data.data);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const approveRejectPlan = (e, item, isApproved) => {
    e.stopPropagation();
    e.preventDefault();
    setLoading(true);
    let userId = JSON.parse(localStorage.getItem("auth_user")).ID;
    let payload = {
      query: `PricingModule_PlanApprovedRejectAPI @orgid = ${item.OrgID},@planid = ${item.PlanID},@newprice = ${item["New Price"]},
            @isApproved = ${isApproved},@loginID = ${userId}`,
    };
    props
      .dispatch(APIService("DYNAMIC_NEW_POST", "", payload))
      .then((data) => {
        setLoading(false);
        if (data.status === 200 && data.data.status === 1000) {
          showToast(isApproved ? "Request approved" : "Request Rejected");
          const orgID = props.match.params.requestId;
          if (orgID) {
            getPlan(orgID);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const approveRejectCharge = (e, item, isApproved) => {
    e.stopPropagation();
    e.preventDefault();
    setLoading(true);
    let userId = JSON.parse(localStorage.getItem("auth_user")).ID;
    let payload = {
      query: `exec PricingModule_ChargeApprovedRejectAPI @orgid = ${item.OrgID}, @chargeid  = ${item.chargeID}, @newcharge = ${item["New Charge"]}, 
            @isApproved = ${isApproved},@loginID = ${userId}`,
    };
    props
      .dispatch(APIService("DYNAMIC_NEW_POST", "", payload))
      .then((data) => {
        setLoading(false);
        if (data.status === 200 && data.data.status === 1000) {
          showToast(isApproved ? "Request approved" : "Request Rejected");
          const orgID = props.match.params.requestId;
          if (orgID) {
            getCharges(orgID);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const showToast = (message) => {
    toast(message, {
      position: "top-right",
      type: "success",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  };

  return (
    <div className="content-wrapper">
      <div className="loader" style={{ display: loading ? "block" : "none" }}>
        <div className="loader-item">
          <Spin />
        </div>
      </div>
      <section className="content" style={{ background: "white" }}>
        <ToastContainer />
        <div
          id="ctl00_CPHMaincontent_divclient"
          className="box box-success"
          style={{ marginTop: "-13px" }}
        >
          <div className="box-header with-border">
            <h3 className="box-title">
              Request Details
              {/* <sup>
             (<span className="vcode">*</span> Fields are mandatory)
           </sup> */}
              <span
                id="ctl00_CPHMaincontent_DivOrdNo"
                style={{ display: "inline" }}
              ></span>
            </h3>
            <div className="pull-right" style={{ marginLeft: "10px" }}>
              <button
                onClick={() => history.push("/approve-request/list")}
                className="btn btn-primary"
              >
                Go Back
              </button>
            </div>{" "}
          </div>
          {plans.length > 0 && (
            <>
              <div
                className="box-body"
                id="orderDetails"
                style={{ display: "block" }}
              >
                <label>Plan Details</label>
                <div className="row">
                  {plans.length > 0 &&
                    plans.map((item, index) => (
                      <>
                        <div
                          key={index}
                          className="d-flex inputClassHandler"
                          style={{ marginBottom: "10px" }}
                        >
                          <div
                            className="form-group"
                            style={{ marginRight: "10px" }}
                          >
                            <input
                              name="Plan"
                              id="Plan"
                              disabled
                              value={item.PlanName || ""}
                              // onChange={this.myChangeHandler}
                              placeholder="Enter Plan"
                              tabIndex={1}
                              className="form-control"
                            />
                          </div>
                          <div
                            className="form-group"
                            style={{ marginRight: "10px" }}
                          >
                            <input
                              name="Price"
                              id="Price"
                              disabled
                              value={item["New Price"] || ""}
                              // onChange={this.myChangeHandler}
                              placeholder="Enter Price"
                              tabIndex={1}
                              className="form-control"
                            />
                          </div>
                          <button
                            onClick={(e) => approveRejectPlan(e, item, 1)}
                            style={{ marginRight: "10px" }}
                            className="btn btn-primary"
                          >
                            Approve
                          </button>
                          <button
                            onClick={(e) => approveRejectPlan(e, item, 0)}
                            className="btn btn-danger"
                          >
                            Reject
                          </button>
                        </div>
                      </>
                    ))}
                </div>
              </div>
            </>
          )}

          <br />
          {charges.length > 0 && (
            <>
              {" "}
              <div
                className="box-body"
                id="orderDetails"
                style={{ display: "block" }}
              >
                <label>Charge Details</label>
                <div className="row">
                  {charges.length > 0 &&
                    charges.map((item, index) => (
                      <>
                        <div
                          key={index}
                          className="d-flex inputClassHandler"
                          style={{ marginBottom: "10px" }}
                        >
                          <div
                            className="form-group"
                            style={{ marginRight: "10px" }}
                          >
                            <input
                              name="Type"
                              id="Type"
                              disabled
                              value={item["Chargetype"] || ""}
                              // onChange={this.myChangeHandler}
                              placeholder="Enter Type"
                              tabIndex={1}
                              className="form-control"
                            />
                          </div>
                          <div
                            className="form-group"
                            style={{ marginRight: "10px" }}
                          >
                            <input
                              name="New Charge"
                              id="New Charge"
                              disabled
                              value={item["newcharge"] || ""}
                              // onChange={this.myChangeHandler}
                              placeholder="Enter New Charge"
                              tabIndex={1}
                              className="form-control"
                            />
                          </div>
                          <button
                            onClick={(e) => approveRejectCharge(e, item, 1)}
                            style={{ marginRight: "10px" }}
                            className="btn btn-primary"
                          >
                            Approve
                          </button>
                          <button
                            onClick={(e) => approveRejectCharge(e, item, 0)}
                            className="btn btn-danger"
                          >
                            Reject
                          </button>
                        </div>
                      </>
                    ))}
                </div>
              </div>
            </>
          )}
        </div>
        {charges.length === 0 && plans.length === 0 ? (
          <div className="no-request">No Request Pending</div>
        ) : null}
      </section>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: () => {
      dispatch({
        type: "loginUser",
      });
    },
    logoutUser: () => {
      dispatch({
        type: "logoutUser",
      });
    },
    dispatch,
  };
};

export default connect(null, mapDispatchToProps)(withRouter(RequestDetail));
