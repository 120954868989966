import React, { Component } from "react";
import "./ORDER/OrderMaster/style.css";
import {
  Configuration,
  removeUserSession,
  getDataTable,
  MandatoryFormFields,
} from "./Configuration";
import Badge from "@material-ui/core/Badge";
import AutoCompleteInputcased from "../src/Actions/Controls/AutoComplete/autoComplete.cascad";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import { withRouter } from "react-router";
import { APIService } from "./Config/action/apiAction";
import { connect } from "react-redux";
import { Modal } from "antd";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { TokenService } from "./Config/action/tokenAction";
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNotification: false,
      orgName: "",
      Username: "",
      IsAgent: "",
      unreadDot: false,
    };
  }

  async componentDidMount() {
    this.setState({
      showNotification: localStorage.getItem("notify_user") ? true : false,
    });
    let user = JSON.parse(localStorage.getItem("auth_user")) || 0;
    this.props
      .dispatch(
        APIService(
          "POST",
          "GetPaymentData?query= GET_NOTIFICATION @ID = " + user.ID,
          ""
        )
      )
      .then((data) => {
        if (data.data.status === 1000) {
          if (data.data.data.length > 0) {
            data.data.data[0].unreadCount > 0
              ? this.props.setActiveNotification(true)
              : this.props.setActiveNotification(false);
          } else {
            throw 500;
          }
        }
      })
      .catch((err) => {
        this.setState({
          ...this.state,
          unreadDot: false,
        });
      });
    const userid = Configuration.USER_ID;
    let Query =
      "select Username,og.OrgName,IsAgent from users inner join OrganizationMaster og on users.orgid = og.orgid  where users.userid = " +
      userid;
    let rs = await getDataTable(Query);
    //Username = rs[0].Username
    this.setState({
      Username: rs[0]?.Username,
      orgName: rs[0]?.OrgName,
      IsAgent: rs[0]?.IsAgent,
    });
    this.forceUpdate();
  }

  openNotification = (e) => {
    e.preventDefault();
    e.stopPropagation();
    localStorage.removeItem("notify_user");
    // this.props.history.push('/notifications');
    window.open("#/notifications", "_blank");
  };

  async onAfterSelect(selectedOptions, id, name) {
    if (selectedOptions.length > 0) {
      this.setState({
        [id]: selectedOptions[0].DisplayId,
        [name]: selectedOptions[0].DisplayName,
      });
    } else {
      this.setState({
        [id]: 0,
        [name]: "",
      });
    }
  }

  async UPDATEORG() {
    let MandatoryArray = [{ ORGANIZATION: this.state.ORGID }];
    let check = MandatoryFormFields(MandatoryArray);
    if (check == false) {
      return false;
    }

    let que =
      "UPDATE  Users  SET OrgID = " +
      this.state.ORGID +
      " output inserted.userid WHERE (userId = " +
      Configuration.USER_ID +
      ")";
    let rs = await getDataTable(que);
    if (Array.isArray(rs)) {
      Swal.fire({
        icon: "success",
        title: "SUCCESSFULLY UPDATED",
        showConfirmButton: false,
        timer: 1500,
      });
      this.props
        .dispatch(
          APIService(
            "POST",
            "GetPaymentData?query=getuserdata @ID=" +
              (localStorage.getItem("UserId")
                ? JSON.parse(localStorage.getItem("UserId")).value
                : "10017")
          )
        )
        .then((data) => {
          console.log(data);
          if (data.status === 200) {
            this.props
              .dispatch(
                TokenService("setToken", {
                  key: "auth_user",
                  value: data.data.data[0],
                })
              )
              .then(() => {
                window.location.reload(true);
              });
          }
        })
        .catch((err) => {
          window.location.reload(true);
          console.log(err);
        });
    }
  }

  async help() {
    Modal.info({
      title: "CHANGE ORGANIZATION",
      okText: "CLOSE",
      width: "600px",
      closable: true,
      footer: null,
      okButtonProps: { style: { display: "none" } },
      bodyStyle: { maxHeight: "500px" },
      style: { padding: "0px" },
      centered: true,
      maskClosable: true,
      content: (
        <div className="row">
          <div className="col-xs-6 col-sm-5 col-md-6 margintop">
            <label>SELECT ORGANIZATION</label>
            <AutoCompleteInputcased
              id="articleid"
              frmNm="HEADER"
              quryNm="FILLORGNIZATION"
              db="IMAGEDB"
              filter2=""
              filterId2=""
              filter1="UserID"
              filterId1={Configuration.USER_ID}
              placeholder="Please Select Organization"
              onAfterSelect={(e) => this.onAfterSelect(e, "ORGID", "ORGNAME")}
              isValid={this.state.isValid}
            ></AutoCompleteInputcased>
          </div>
          <div className="col-md-1 col-xs-6 col-sm-3 margintop">
            <label>&nbsp;</label>
            <div className="form-group">
              <button
                className="btn btn-primary"
                onClick={this.UPDATEORG.bind(this)}
              >
                UPDATE
              </button>
            </div>
          </div>
        </div>
      ),
    });
  }

  render() {
    return (
      <div id="mainHeader">
        <header className="main-header">
          {/* Logo */}
          <nav
            className="navbar navbar-static-top"
            role="navigation"
            style={{ marginLeft: "0px !important" }}
          >
            <a
              className="sidebar-toggle sidebar-collapse"
              id="menudrp"
              data-toggle="push-menu"
              role="button"
            >
              <span className="sr-only">Toggle navigation</span>
            </a>
            {/* Header Navbar: style can be found in header.less */}

            {/* Sidebar toggle button*/}
            {/* <a  className="sidebar-toggle" id='menudrp' data-toggle="push-menu" role="button">
        <span className="sr-only">Toggle navigation</span>
      </a> */}
            <a href="/" className="logo hidden-xs">
              <span className="logo-lg">
                <img src="dist/img/logos.png" />{" "}
              </span>
            </a>
            {/* Navbar Right Menu */}
            <div className="navbar-custom-menu">
              <ul className="nav navbar-nav">
                <li>
                  <a
                    href="/"
                    className="hidden-sm hidden-md hidden-lg hidden-xl"
                  >
                    <span>
                      <img src="dist/img/logos.png" style={{ height: "9vh" }} />
                    </span>
                  </a>
                </li>
                <li className="dropdown user user-menu">
                  <a className="dropdown-toggle" data-toggle="dropdown">
                    <img
                      src="dist/img/avatar5.png"
                      className="user-image"
                      alt="User"
                    />
                    <span className="hidden-xs">{Configuration.USER_NAME}</span>
                  </a>
                  <ul className="dropdown-menu">
                    {/* User image */}
                    <li className="user-header">
                      <img
                        src="dist/img/avatar5.png"
                        className="img-circle"
                        alt="User"
                      />
                      <p>
                        {this.state.Username}
                        <small>
                          {this.state.orgName}&nbsp;
                          {this.state.IsAgent == 1 && (
                            <i
                              className="fa fa-edit"
                              onClick={(e) => this.help()}
                            ></i>
                          )}
                        </small>
                      </p>
                    </li>
                    <li className="user-footer">
                      <div className="pull-left">
                        <a href="#/ticket" className="btn btn-default btn-flat">
                          Ticket
                        </a>
                      </div>
                      <div className="pull-right">
                        <a
                          href="#/Login"
                          onClick={removeUserSession}
                          className="btn btn-default btn-flat"
                        >
                          Sign out
                        </a>
                      </div>
                    </li>
                  </ul>
                </li>
                {/* Control Sidebar Toggle Button */}
                <li>
                  <a href="#/change-password" data-toggle="">
                    <i className="fa fa-gears" />
                  </a>
                </li>

                <li
                  className="notificationHover"
                  style={{ cursor: "pointer", padding: "13px" }}
                >
                  <Badge
                    color="secondary"
                    onClick={this.openNotification}
                    variant="dot"
                    invisible={
                      !this.state.showNotification &&
                      !this.props.activeNotification
                    }
                    style={{ color: "white" }}
                  >
                    <NotificationsNoneIcon style={{ fontSize: "22px" }} />
                  </Badge>
                  {/* <a href="/notifications" ><i className="fa fa-gears" /></a> */}
                </li>
              </ul>
            </div>
          </nav>
        </header>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: () => {
      dispatch({
        type: "loginUser",
      });
    },
    logoutUser: () => {
      dispatch({
        type: "logoutUser",
      });
    },
    dispatch,
  };
};

export default connect(null, mapDispatchToProps)(withRouter(Header));
