import React, {Component, Fragment} from 'react';
// import NavBar from '../../Common/navbar';
// import PageTitle from '../../Common/pageTitle';
// import Sidenav from '../../Common/sidenav';
import CategoryDetail from './categoryDetail';

class Category extends Component{
    render(){
        return(
            <Fragment>
                <div style={{backgroundColor:'#f4f6f9', minHeight: '100vh'}}>
                    {/* <NavBar />
                    <Sidenav active="Category" /> */}
                    <CategoryDetail />
                </div>
            </Fragment>
        )
    }
}

export default Category;