import { Modal, Button } from 'antd';
import React, { Component } from 'react';
import '../../ORDER/OrderMaster/style.css'
import Table from '../../Actions/Controls/Table/AntDTable'
import { Configuration, MandatoryFormFields, getDataTable, OnlyNumberKey } from '../../Configuration';
import AutoCompleteInput from '../../Actions/Controls/AutoComplete/autoComplete';
import AutoCompleteCased from '../../Actions/Controls/AutoComplete/autoComplete.cascad';
import AutoCompletemulti from '../../Actions/Controls/AutoComplete/autoCompleteMultiple';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

export default class frmOrgPOrtalMapping extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            modal1Visible: false,
            modal2Visible: false,
            loginId: 0,
            RSDATA: [],
            ProfileId: []
        };
    }


    async componentDidMount() {
        await this.setState({
            loginId: Configuration.USER_ID,
        });
    }

    setModal1Visible(modal1Visible) {
        this.setState({ modal1Visible });
    }

    setModal2Visible(modal2Visible) {
        this.setState({ modal2Visible, RSDATA: [] });
    }

    myChangeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
        this.forceUpdate();
    }


    async getdata(val) {
        if (val === '') {
            this.setState({ RSDATA: [] })
            return;
        }
        let Que = "EXEC Show_OrganizationPortal @ORGID = " + this.state.orgid + " , @BLOCKID = " + val
        let rs = await getDataTable(Que)
        if (rs.length > 0) {
            this.setState({ RSDATA: rs })
        }
        else {
            this.setState({ RSDATA: [] })
            return;
        }
    }



    async onAfterSelect(selectedOptions, id, name) {
        if (selectedOptions.length > 0) {
            this.setState({
                [id]: selectedOptions[0].DisplayId,
                [name]: selectedOptions[0].DisplayName,
            })
            if (name === "BlockName") {
                await this.getdata(selectedOptions[0].DisplayId)
            }
        }
        else {
            this.setState({
                [id]: '',
                [name]: '',
            })
            if (name === "orgname" || name === "BlockName") {
                await this.getdata('')
            }
        }
        this.forceUpdate();
    }



    async SaveTamplate() {
        let MandatoryArray = [
            { "ORGANIZATION": this.state.orgid },
            { "BLOCK": this.state.BlockID },
            { "PORTAL": this.state.PID },
            { "PROFILE": this.state.ProfileId }
        ]
        let check = MandatoryFormFields(MandatoryArray);
        if (check == false) {
            return false;
        }
        let que = "select * from PortalOrgnisationMapping where orgid = " + this.state.orgid + " and portalid = " + this.state.PID[0].DisplayId + " And BlockID = " + this.state.BlockID + " And ProfileId = " + this.state.ProfileId
        let rs = await getDataTable(que)
        if (rs.length > 0) {
            Swal.fire({
                icon: 'error',
                title: "ALREADY EXIST",
                showConfirmButton: false,
                timer: 1500
            })

            return false;
        }
        for (let i = 0; i < this.state.PID.length; i++) {
            que = ""
            que += "INSERT INTO PortalOrgnisationMapping (orgid, portalid, loginID, logdate,BlockID, ProfileId) VALUES (" + this.state.orgid + "," + this.state.PID[i].DisplayId + "," + this.state.loginId + ",getdate()," + this.state.BlockID + "," + this.state.ProfileId + ")"
            let rs1 = await getDataTable(que)
        }
        Swal.fire({
            icon: 'success',
            title: "SUCCESSFULLY ADDED",
            showConfirmButton: false,
            timer: 1500
        })
        await this.getdata(this.state.BlockID)
    }
    myChangeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }
    async onAutoCOmpletMultiPleSelect(selectedOptions, name) {
        if (selectedOptions.length > 0) {
            this.setState({
                [name]: selectedOptions
            });
        } else {
            this.setState({
                [name]: ""
            });
        }
    }


    render() {

        return (
            <div>
                <button type="button" onClick={() => this.setModal2Visible(true)} className="btn btn-primary">PORTAL MAPPING</button>

                <Modal
                    title="PORTAL ORGANIZATION MAPPING"
                    width={this.props.width}
                    visible={this.state.modal2Visible}
                    onOk={() => this.setModal2Visible(false)}
                    onCancel={() => this.setModal2Visible(false)}
                    destroyOnClose={true}
                    maskClosable={false}
                    footer={null}
                >
                    <div className='row'>
                        <div className="col-xs-6 col-sm-6 col-md-3 margintop">
                            <label>SLEECT ORGANIZATION</label><span className="vcode">*</span>
                            <AutoCompleteInput
                                id="articleid"
                                frmNm="FRMUSER"
                                quryNm="FILLORGANIZATION"
                                db="IMAGEDB"
                                placeholder="Please Select Organization"
                                onAfterSelect={(e) => this.onAfterSelect(e, "orgid", "orgname")}
                                isValid={this.state.isValid}
                            ></AutoCompleteInput>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-md-3 margintop">
                            <label>Select Block</label><span className="vcode">*</span>
                            <AutoCompleteCased
                                id="catid"
                                frmNm="FRMALLDATA"
                                quryNm="FILLORGBLOCKS"
                                db="IMAGEDB"
                                filter1="orgid"
                                filterId1={this.state.orgid}
                                filter2=""
                                filterId2=""
                                placeholder="Please Select Block"
                                onAfterSelect={(e) => this.onAfterSelect(e, "BlockID", "BlockName")}
                                isValid={this.state.isValid}
                            ></AutoCompleteCased>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-md-3 margintop">
                            <label>SELECT PORTAL</label><span className="vcode">*</span>
                            <AutoCompletemulti
                                id="articleid"
                                frmNm="FRMIMAGEIDENTIFICATION"
                                quryNm="FILLPORTAL"
                                db="IMAGEDB"
                                placeholder="Please Select Portal"
                                onAfterSelect={(e) => this.onAutoCOmpletMultiPleSelect(e, "PID")}
                                isValid={this.state.isValid}
                            ></AutoCompletemulti>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-md-3 margintop">
                            <label>SELECT IMAGE PROFILE</label><span className="vcode">*</span>
                            <AutoCompleteInput
                                id="profileid"
                                frmNm="FRMPORTALCONFIG"
                                quryNm="FILLPROFILE"
                                db="IMAGEDB"
                                placeholder="Please Select Profile"
                                onAfterSelect={(e) => this.onAfterSelect(e, "ProfileId", "ProfileName")}
                                isValid={this.state.isValid}
                                defauldVal={this.state.ProfileId}
                            ></AutoCompleteInput>
                        </div>
                        <div className='col-md-2 col-xs-6 col-sm-2 margintop'  >
                            <label>&nbsp;</label>
                            <div className='form-group'>
                                <button className='btn btn-primary' value='template' onClick={this.SaveTamplate.bind(this)}>Save</button>
                            </div>
                        </div>

                        <div className='col-md-12 col-xs-12 col-sm-12 margintop'>
                            {this.state.RSDATA.length > 0 && this.state.RSDATA != undefined &&
                                <Table
                                    data={this.state.RSDATA}
                                    Delete="true"
                                    DeleteFn={(e) => this.deleteRow(e)}
                                    height='63vh'
                                    id='fielddata'>
                                </Table>}
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }




    async deleteRow(e) {
        let st = await Swal.fire({ title: 'ARE YOU SURE WANT TO DELETE?', showCancelButton: true, confirmButtonText: 'YES', cancelButtonText: 'NO', })
        if (!st.isConfirmed) {
            return false;
        }
        let que = "Delete from PortalOrgnisationMapping where orgid = " + this.state.orgid + " and portalid =" + e['#PID']
        let dt = await getDataTable(que)
        Swal.fire({
            icon: 'info',
            title: "SUCCESSFULLY REMOVED",
            showConfirmButton: false,
            timer: 1500
        })
        await this.getdata(this.state.orgid)
    }


}