import React, { useState } from "react";
import { Fragment } from "react";
import { withRouter } from "react-router-dom";

import TicketModal from "../createTicketModal/index";

function PageTitle(props) {
  const goBack = () => {
    props.history.goBack(props.backURL);
  };

  return (
    <Fragment>
      <section className="content-header">
        <h1
          className="title-content"
          style={
            props.title === "Tickets" || props.goBack
              ? { padding: "15px" }
              : { padding: "4px" }
          }
        >
          {props.title}
        </h1>
        <ol className="breadcrumb-items">
          {props.title === "Tickets" ? (
            <div
              className="col-6"
              style={{ border: "0px solid black", height: "36px" }}
            >
              <button
                onClick={props.openModal}
                style={{
                  border: "0px",
                  backgroundColor: "#FFCC00",
                  float: "right",
                  padding: "7px",
                  paddingLeft: "12px",
                  paddingRight: "12px",
                  fontWeight: "bold",
                  color: "white",
                  outline: "none",
                }}
              >
                Add Ticket
              </button>
            </div>
          ) : props.showBack ? (
            <div
              className="col-6"
              style={{ border: "0px solid black", height: "36px" }}
            >
              <button
                onClick={goBack}
                style={{
                  border: "0px",
                  backgroundColor: "rgb(0, 123, 255)",
                  float: "right",
                  padding: "7px",
                  paddingLeft: "12px",
                  paddingRight: "12px",
                  fontWeight: "bold",
                  color: "white",
                  outline: "none",
                }}
              >
                Go Back
              </button>
            </div>
          ) : props.showNewBrandModal ? (
            <div
              className="col-6"
              style={{ border: "0px solid black", height: "36px" }}
            >
              <button
                onClick={props.openBrandModal}
                style={{
                  border: "0px",
                  backgroundColor: "rgb(0, 123, 255)",
                  float: "right",
                  padding: "7px",
                  paddingLeft: "12px",
                  paddingRight: "12px",
                  fontWeight: "bold",
                  color: "white",
                  outline: "none",
                }}
              >
                Create Brand
              </button>
            </div>
          ) : null}
        </ol>
        {props.title === "Tickets" ? (
          <TicketModal handleClose={props.handleClose} open={props.open} />
        ) : null}
      </section>
    </Fragment>
    // <div className="content-header">
    //     <div className="container-fluid">
    //         <div className="row mb-2">
    /* <div className="col-6">
                        <h1 className="m-0 text-dark" style={{fontFamily: "Source Sans Pro, sans-serif"}}>{props.title}</h1>
                    </div> */
    //     {
    //         (props.title === "Tickets") ? (
    //             <div className="col-6">
    //                 <button onClick={props.openModal} style={{border: "0px", backgroundColor: "#FFCC00", float: 'right', padding:'7px', paddingLeft:'12px', paddingRight:'12px', fontWeight:'bold', color:'white', outline: "none"}}>Add Ticket</button>
    //             </div>
    //         ) : null
    //     }
    // </div>
    // {
    // (props.title === "Tickets") ? (
    // <TicketModal handleClose={props.handleClose} open={props.open} />
    // ) : null
    //         }
    //     </div>
    // </div>
  );
}

export default withRouter(PageTitle);
