import React, { Component } from "react";
import {
  Configuration,
  exceltodb,
  MandatoryFormFields,
  getDataTable,
  GetNewDrpSheet,
  json2xlsx,
} from "../Configuration";
import { Modal, Spin,DatePicker,Space } from "antd";
import FRMSETFIXED from './MODEL/frmSetFixedFee'
import FRMTIERMASTR from './MODEL/frmTierMaster'
import FRMSETPAYMENTFEE from './MODEL/frmSetPaymentFee'
import FRMSETFREIGHTFEE from './MODEL/frmSetFreightFee'
import FRMSETPORTALCOMMISSION from './MODEL/frmSetPortalCommission'
import "../ORDER/OrderMaster/style.css";
import "antd/dist/antd.css";
import Moment from "moment";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import AutoCompleteCascad from "../Actions/Controls/AutoComplete/autoComplete.cascad";
import "sweetalert2/src/sweetalert2.scss";
const dataBase = "IMAGEDB";
let files = "";
const currentDate = Moment().format("DD-MMM-YYYY hh:mm:ss");

export default class frmSubmitOrderdata extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginId: 0,
      loading: false,
      orgid: 0,
      DataMonth: "",
      PFiles: []
    }
  }
  async componentDidMount() {
    let rs = await getDataTable(
      "select orgid from users where userid = " + Configuration.USER_ID
    );
    this.setState({
      loginId: Configuration.USER_ID,
      orgid: rs[0].orgid,
    });
  }
  myChangeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  onAfterSelect(selectedOptions, id, name) {
    if (selectedOptions.length > 0) {
      this.setState({
        [id]: selectedOptions[0].DisplayId,
        [name]: selectedOptions[0].DisplayName
      })
    }
    else {
      this.setState({
        [id]: '',
        [name]: ''
      })
    }
  }
  async onMonthChange(date, dateString) {
    this.setState({
      DataMonth: dateString
    })
    this.forceUpdate();
  }
  myFileChangeHandler = (event) => {
    files = event.target.files[0];
    this.setState({ PFiles: event.target.files })
    console.log(event.target.files)
  };

  help() {
    Modal.info({
      title: "INSTRUCTION WHILE IMPORT DATA",
      okText: "CLOSE",
      width: "600px",
      closable: true,
      footer: null,
      okButtonProps: { style: { display: 'none' } },
      bodyStyle: { maxHeight: "500px" },
      style: { padding: "0px" },
      centered: true,
      maskClosable: true,
      content: (
        <div>
          <ul>
            <li>Excel Sheet Name Must be "Sheet1"</li>
            <li>Headers should be in first row</li>
          </ul>
        </div>
      ),
    });
  }

  

  async getMasterSheet() {
    this.setState({ loading: true });
    let que = " EXEC frmSubmitOrderData_getMasterSheet @orgid = " + this.state.orgid
    let rs = await getDataTable(que)
    await GetNewDrpSheet(this.state.catid1, rs, 'Master data Sheet -' + currentDate + '.xlsx')
    this.setState({ loading: false });
  }

  async getRTVSheet() {
    this.setState({ loading: true });
    let que = " EXEC frmSubmitOrderData_getRTVSheet @orgid = " + this.state.orgid
    let rs = await getDataTable(que)
    await GetNewDrpSheet(this.state.catid1, rs, 'Master RTV Sheet -' + currentDate + '.xlsx')
    this.setState({ loading: false });
  }

  async getInVoiceFormat(){
    let MandatoryArray = [
      { "FILE TYPE": this.state.filetype },
      { "Month": this.state.DataMonth },
    ];
    let check = MandatoryFormFields(MandatoryArray);
    if (check == false) {
      this.setState({ loading: false });
      return false;
    }
    let que = " EXEC frmSubmitOrderData @mothyr = '"+this.state.DataMonth+"' , @loginID = "+this.state.loginId
    let rs = await getDataTable(que)
    await json2xlsx(rs,'INVOICEDATA_FORMAT')
    this.setState({loading:false})
  }


  async uploadInvoiceData(){
    this.setState({ loading: true });
    let MandatoryArray = [
      { "PORTAL": this.state.PortalID },
      { "FILE TYPE": this.state.filetype },
      { "FILE": files },
    ];
    let check = MandatoryFormFields(MandatoryArray);
    if (check == false) {
      this.setState({ loading: false });
      return false;
    }

    for (let i = 0; i < this.state.PFiles.length; i++) {
      let element = this.state.PFiles[i];

      let tblName = await exceltodb(element, dataBase, this.state.loginId)
      if (tblName) {
        let Que = "EXEC frmSubmitOrderdata_SAVEINVOICEDATA @tblName = '" + tblName + "' "
        Que += " , @PortalID = " + this.state.PortalID
        Que += " , @OrgID = " + this.state.orgid + " "
        Que += " , @LoginID = " + this.state.loginId + " "

        let rs = await getDataTable(Que)
        console.log(rs)
      }
    }
    Swal.fire({
      icon: 'success',
      title: 'DATA UPDATED SUCCESSFULLY',
      showConfirmButton: false,
      timer: 2000
    })
    this.setState({ loading: false });

  }


  async UploadSubmitData() {
    if (this.state.filetype === "Master") {
      await this.uploadMasterData()
      return;
    }
    if (this.state.filetype === "invoice") {
      await this.uploadInvoiceData()
      return;
    }
    this.setState({ loading: true });
    let MandatoryArray = [
      { "PORTAL": this.state.PortalID },
      { "FILE TYPE": this.state.filetype },
      { "FILE": files },
    ];
    let check = MandatoryFormFields(MandatoryArray);
    if (check == false) {
      this.setState({ loading: false });
      return false;
    }

    for (let i = 0; i < this.state.PFiles.length; i++) {
      let element = this.state.PFiles[i];

      let tblName = await exceltodb(element, dataBase, this.state.loginId)
      if (tblName) {
        let Que = "EXEC frmSubmitOrderdata_SAVEFILEDATA @tblName = '" + tblName + "' "
        Que += " , @PortalID = " + this.state.PortalID
        Que += " , @FileType = '" + this.state.filetype + "' "
        Que += " , @OrgID = " + this.state.orgid + " "
        Que += " , @LoginID = " + this.state.loginId + " "

        let rs = await getDataTable(Que)
        console.log(rs)
      }
    }
    Swal.fire({
      icon: 'success',
      title: 'DATA UPDATED SUCCESSFULLY',
      showConfirmButton: false,
      timer: 2000
    })
    this.setState({ loading: false });
  }

  async uploadMasterData() {
    let MandatoryArray = [
      { "BLOCK": this.state.BlockID },
      { "FILE TYPE": this.state.filetype },
      { "FILE": files },
    ];
    let check = MandatoryFormFields(MandatoryArray);
    if (check == false) {
      this.setState({ loading: false });
      return false;
    }
    this.setState({ loading: true });
    let element = this.state.PFiles[0];
    let tb = await exceltodb(element, dataBase, this.state.loginId)
    if (tb) {
      let queCheck = "EXEC frmProductDetails_checkSKULimit @tblName = '" + tb + "' , @loginID = " + this.state.loginId
      let rschk = await getDataTable(queCheck)
      if (rschk[0]['RES'] != 'OK') {
        Swal.fire({
          icon: 'error',
          title: rschk[0]['RES'],
          showConfirmButton: false,
          timer: 2000
        })
        this.setState({ loading: false })
        return;
      }

      let Que = "EXEC SaveProductImportDetailsforTest @loginId = " + this.state.loginId + ",@tblname = '" + tb + "' , @categoryid=" + this.state.BlockID
      let rs = await getDataTable(Que)

      if (Array.isArray(rs)) {
        if (rs.length > 0) {
          let Que = "EXEC SaveDataInMasterTable @loginid = " + this.state.loginId + ",@tblname = '" + tb + "'"
          let rsq = await getDataTable(Que)
          if (rsq.length > 0) {
            Swal.fire({
              icon: 'success',
              title: 'Data Saved SuccessFully',
              showConfirmButton: false,
              timer: 1500
            })
            //alert("Data Saved SuccessFully")
            this.setState({ loading: false })
            return false;
          }
          else {
            Swal.fire({
              icon: 'error',
              title: 'SOME ERROR OCCURED',
              showConfirmButton: false,
              timer: 1500
            })
            //alert("Data Saved SuccessFully")
            this.setState({ loading: false })
            return false;
          }
        }
        else {
          Swal.fire({
            icon: 'error',
            title: 'File not Imported Properly',
            showConfirmButton: false,
            timer: 1500
          })
          //alert("File not contain Proper Data")
          this.setState({ loading: false })
        }
      }
      else {
        Swal.fire({
          icon: 'error',
          title: 'File not Imported Properly',
          showConfirmButton: false,
          timer: 1500
        })
        //alert("File not contain Proper Data")
        this.setState({ loading: false })
      }
      document.getElementById('tp1').value = ''
    }
    else {
      tb = ''
      this.setState({ loading: false })
      return;
    }
  }

  render() {
    return (
      <div className="content-wrapper">
        <div className='loader' style={{ display: this.state.loading ? "block" : "none" }}>
          <div className='loader-item'>
            <Spin />
          </div>
        </div>
        <section className="content">
          <div id="ctl00_CPHMaincontent_divclient" className="box box-success" style={{ marginTop: '-13px' }}>
            <div className="box-header with-border">
              <h3 className="box-title">IMPORT ORDER DATA
                <span id="ctl00_CPHMaincontent_DivOrdNo" style={{ display: 'inline' }}>
                </span>
              </h3>
              <div className="pull-right">
                <button
                  className="btn btn-primary"
                  onClick={this.help.bind(this)}
                >
                  Help
                </button>
              </div>
              {/* <div className="pull-right" style={{ marginRight: '7px' }}>
                <button
                  className="btn btn-danger"

                  onClick={this.getMasterSheet.bind(this)}
                >
                  MASTER SHEET FORMAT
                </button>
              </div> */}
              <div className="btn-group pull-right" style={{ marginRight: '7px' }}>
                <button type="button" className="btn btn-danger">SET MASTER</button>
                <button type="button" className="btn btn-danger dropdown-toggle" data-toggle="dropdown">
                  <span className="caret"></span>
                  <span className="sr-only">Toggle Dropdown</span>
                </button>
                <ul className="dropdown-menu" role="menu">
                <li><FRMTIERMASTR width='800px' logid={this.state.loginId} /></li>
                  <li><FRMSETFIXED width='800px' logid={this.state.loginId} /></li>
                  <li><FRMSETPORTALCOMMISSION  width='1200px' logid={this.state.loginId} /></li>
                  <li><FRMSETPAYMENTFEE width='800px' logid={this.state.loginId} /></li>
                  <li><FRMSETFREIGHTFEE  width='1200px' logid={this.state.loginId} /></li>
                </ul>
              </div>
              <div className="btn-group pull-right" style={{ marginRight: '7px' }}>
                <button type="button" className="btn btn-danger">Select FORMAT</button>
                <button type="button" className="btn btn-danger dropdown-toggle" data-toggle="dropdown">
                  <span className="caret"></span>
                  <span className="sr-only">Toggle Dropdown</span>
                </button>
                <ul className="dropdown-menu" role="menu">
                  <li><a onClick={this.getMasterSheet.bind(this)}>MASTER SHEET FORMAT</a></li>
                  <li><a onClick={this.getRTVSheet.bind(this)}>MASTER RTV FORMAT</a></li>
                  <li><a onClick={this.getInVoiceFormat.bind(this)}>INVOICE FORMAT</a></li>
                </ul>
              </div>
            </div>
            <div className="box-body" id="orderDetails" style={{ display: 'block' }}>
              <div className='row'>
                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                  <label>File Type</label><span className='vcode'>*</span>
                  <div className="form-group">
                    <select className="form-control" name='filetype' defaultValue={this.state.filetype} value={this.state.filetype} onChange={this.myChangeHandler}>
                      <option value="">PLEASE SELECT</option>
                      <option value="Order">ORDER FLOW FILE</option>
                      <option value="RTV">RTV FILE</option>
                      <option value="Payment">PAYMENT FILE</option>
                      <option value="Ticket">RTV TICKET FILE</option>
                      <option value="Master">MASTER FILE</option>
                      <option value="invoice">INVOICE FILE</option>
                    </select>
                  </div>
                </div>
                {this.state.filetype === "Master" &&
                  <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                    <label>Select Block</label><span className="vcode">*</span>
                    <AutoCompleteCascad
                      id="catid"
                      frmNm="FRMALLDATA"
                      quryNm="FILLORGBLOCKS"
                      db="IMAGEDB"
                      filter1="orgid"
                      filterId1={this.state.orgid}
                      filter2=""
                      filterId2=""
                      placeholder="Please Select Block"
                      onAfterSelect={(e) => this.onAfterSelect(e, "BlockID", "BlockName")}
                      isValid={this.state.isValid}
                    ></AutoCompleteCascad>
                  </div>}
                {this.state.filetype !== "Master" && <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                  <label>Select Portal</label><span className='vcode'>*</span>
                  <AutoCompleteCascad
                    id="portalID"
                    frmNm="FRMATTRIBUTEALIASE"
                    quryNm="FILLPORTAL"
                    db="IMAGEDB"
                    filter2=""
                    filterId2=""
                    filter1="orgid"
                    filterId1={this.state.orgid}
                    placeholder="Please Select Portal"
                    onAfterSelect={(e) => this.onAfterSelect(e, "PortalID", "PortalName")}
                    isValid={this.state.isValid}
                  ></AutoCompleteCascad>
                </div>}
                { this.state.filetype === "invoice" &&
                  <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                  <label>Select Month</label><span className='vcode'>*</span>
                  <div className="form-group">
                    <Space direction="vertical" style={{ display: "block" }}>
                      <DatePicker onChange={this.onMonthChange.bind(this)} className="form-control" name='DtMonth' picker="month" />
                    </Space>
                  </div>
                </div>}

                <div className="col-xs-6 col-sm-6 col-md-2 margintop" style={{ "display": this.state.filetype === "Master" ? 'none' : 'block' }}>
                  <label>Upload File</label>
                  <span className="vcode">*</span>
                  <div className="form-group">
                    <input
                      name="fldname"
                      type="file"
                      accept=".xls,.xlsx,.xlsm,.csv"
                      id="tp1"
                      onChange={this.myFileChangeHandler}
                      placeholder="Enter FieldName"
                      tabIndex={1}
                      multiple
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-2 margintop" style={{ "display": this.state.filetype === "Master" ? 'block' : 'none' }}>
                  <label>Upload File</label>
                  <span className="vcode">*</span>
                  <div className="form-group">
                    <input
                      name="fldname"
                      type="file"
                      accept=".xls,.xlsx,.xlsm,.csv"
                      id="tp1"
                      onChange={this.myFileChangeHandler}
                      placeholder="Enter FieldName"
                      tabIndex={1}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-2 margintop" >
                  <label>&nbsp;</label>
                  <div className='form-group'>
                    <button className='btn btn-block btn-primary' onClick={this.UploadSubmitData.bind(this)}>UPLOAD FILE</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }

 

}