import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { span, withRouter } from "react-router-dom";
import { APIService } from "../../../Config/action/apiAction";
import PageTitle from "../../Common/pageTitle";
import { Spin, Table, Card, Button, Modal, Select, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
// import Button from '@material-ui/core/Button';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
let userData = [
  {
    name: "kunjan",
    status: 1,
    address: "JND",
  },
];
function RequestListing(props) {
  const { Option } = Select;

  const [columns, setColumns] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    defaultUseEffect();
  }, []);

  const viewDetail = (e, item) => {
    e.stopPropagation();
    e.preventDefault();
    props.history.push(`/approve-request/detail/${item.OrgID}`);
  };

  const defaultUseEffect = () => {
    setLoading(true);
    let payload = {
      query: "exec PricingModule_ListOfOrganizationsUpdatedRequestChargePlan",
    };
    props
      .dispatch(APIService("DYNAMIC_NEW_POST", "", payload))
      .then((data) => {
        console.log("DATA", data);
        setUserData(data.data.data);

        if (data.data.data && data.data.data.length > 0) {
          // Calculating unique values
          let values = {};

          // Unique columns name
          let keys = Object.keys(data.data.data[0]);

          keys.map((col, index) => {
            data.data.data &&
              data.data.data.map((item, pos) => {
                if (values[col] && values[col].indexOf(item[col]) === -1) {
                  values[col].push(item[col]);
                } else if (!values[col]) {
                  values[col] = [item[col]];
                }
              });
          });

          // Defining dynamic columns
          let columns = [];
          keys.map((item, index) => {
            let payload = {
              title: item,
              dataIndex: item,

              key: item,
              ...getColumnSearchProps(item, values),
            };
            columns.push(payload);
          });
          columns.push({
            title: "Action",
            dataIndex: "",
            key: "x",
            render: (text, record) => (
              <button
                onClick={(e) => viewDetail(e, record)}
                className="btn btn-primary"
              >
                View
              </button>
            ),
          });
          setColumns(columns);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        } else {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getColumnSearchProps = (dataIndex, val) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Select
          mode="multiple"
          allowClear
          style={{ width: 188, marginBottom: 8, display: "block" }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys}
          onChange={(values) => setSelectedKeys([...values])}
        >
          {val &&
            val[dataIndex].map((key, index) => {
              return <Option key={key}>{key}</Option>;
            })}
        </Select>
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      if (record[dataIndex]) {
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    },
  });
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  return (
    <div className="content-wrapper">
      <div className="loader" style={{ display: loading ? "block" : "none" }}>
        <div className="loader-item">
          <Spin />
        </div>
      </div>
      <section className="content">
        <div
          id="ctl00_CPHMaincontent_divclient"
          className="box box-success"
          style={{ marginTop: "-13px" }}
        >
          <div className="box-header with-border">
            <h3 className="box-title">
              Request Listing
              {/* <sup>
            (<span className="vcode">*</span> Fields are mandatory)
          </sup> */}
              <span
                id="ctl00_CPHMaincontent_DivOrdNo"
                style={{ display: "inline" }}
              ></span>
            </h3>
          </div>
          <br /> <br />
          <div
            className="box-body"
            id="orderDetails"
            style={{ display: "block" }}
          >
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 margintop">
                {userData.length > 0 && userData != undefined && (
                  <Table
                    style={{ width: "100%" }}
                    rowKey={(record) => record.UserName}
                    columns={columns}
                    dataSource={userData}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: () => {
      dispatch({
        type: "loginUser",
      });
    },
    logoutUser: () => {
      dispatch({
        type: "logoutUser",
      });
    },
    dispatch,
  };
};

export default connect(null, mapDispatchToProps)(withRouter(RequestListing));
