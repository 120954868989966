import { Modal } from 'antd';
import React, { Component } from 'react';
import '../../ORDER/OrderMaster/style.css'
import Table from '../../Actions/Controls/Table/AntDTable'
import { Configuration, MandatoryFormFields, getDataTable, arrUnique } from '../../Configuration';
import AutoCompleteInput from '../../Actions/Controls/AutoComplete/autoComplete';
import AutoCompleteCascad from '../../Actions/Controls/AutoComplete/autoComplete.cascad';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

export default class frmTemplate extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            modal1Visible: false,
            modal2Visible: false,
            loginId:0,
            RSDATA: [],
            condition:1
        };
    }

    async componentDidMount() {
        await this.setState({
            loginId: Configuration.USER_ID,
        });
    }

    static getDerivedStateFromProps(nextProps, prevState){
        return {
           RSDATA: nextProps.data
       };
     }


    setModal1Visible(modal1Visible) {
        this.setState({ modal1Visible });
    }

    setModal2Visible(modal2Visible) {
        this.setState({ modal2Visible, RSDATA: [] });
    }

   async onAfterSelect(selectedOptions, id, name) {
        if (selectedOptions.length > 0) {
            this.setState({
                [id]: selectedOptions[0].DisplayId,
                [name]: selectedOptions[0].DisplayName,
            })
        }
        else {
            this.setState({
                [id]: '',
                [name]: '',
            })
        }
    }

    async SaveTamplate() {
        let dt = this.state.RSDATA
        let cnt = 0
        dt.forEach(ele => {
            if (this.state.AVID === ele['#AVID'] && this.state.condition === ele['Condition']) {
                cnt = cnt+1
            }
        });
        if (cnt > 0) {
            return false;
        }
        let st = {
            "#AID":this.state.AID,
            "AttributeName":this.state.ANAME,
            "#AVID":this.state.AVID,
            "Value":this.state.AVALUE,
            "Condition":this.state.condition
        }
        dt.push(st)
        let tempData = await arrUnique(dt)
        this.setState({
            RSDATA:tempData
        })
    }

    myChangeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }


    render() {

        return (
            <div>
                <button type="button" onClick={() => this.setModal2Visible(true)} className="btn btn-success btn-block">FILTER</button>

                <Modal
                    title="TEMPLATE FILTER"
                    width={this.props.width}
                    visible={this.state.modal2Visible}
                    onOk={() => this.setModal2Visible(false)}
                    onCancel={() => this.setModal2Visible(false)}
                    // destroyOnClose={true}
                    maskClosable={false}
                    footer={null}
                >
                    <div className='row'>
                        
                        <div className="col-xs-6 col-sm-6 col-md-3 margintop">
                            <label>Select Attribute</label><span className="vcode">*</span>
                            <AutoCompleteCascad
                                id="AttribiteID"
                                frmNm="FRMTEMPLATEFILTER"
                                quryNm="FILLATTRIBUTE"
                                db="IMAGEDB"
                                filter1="CatID"
                                filterId1={this.props.catid}
                                filter2=""
                                filterId2=""
                                placeholder="Please Select Attribute"
                                onAfterSelect={(e) => this.onAfterSelect(e, "AID", "ANAME")}
                            ></AutoCompleteCascad>
                        </div>
                        <div className="col-xs-4 col-sm-4 col-md-3 margintop">
                            <label>Select Value</label><span className="vcode">*</span>
                            <AutoCompleteCascad
                                id="tempID"
                                frmNm="FRMVALUEMAPPING"
                                quryNm="FILLVALUES"
                                db="IMAGEDB"
                                filter1="AtrributeID"
                                filterId1={this.state.AID}
                                filter2="BlockID"
                                filterId2={this.props.catid}
                                placeholder="Please Select Value"
                                onAfterSelect={(e) => this.onAfterSelect(e, "AVID", "AVALUE")}
                            ></AutoCompleteCascad>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                            <label>Condition No.</label><span className="vcode">*</span>
                            <div className="form-group">
                                {/* <input name="condition" id="condition" onChange={this.myChangeHandler} placeholder="Enter Condition Number" className="form-control" /> */}
                                <select name="condition" className="form-control" id="condition" onChange={this.myChangeHandler}>
                                    <option value='1'>1</option>
                                    <option value='2'>2</option>
                                    <option value='3'>3</option>
                                    <option value='4'>4</option>
                                    <option value='5'>5</option>
                                    <option value='6'>6</option>
                                    <option value='7'>7</option>
                                </select>
                            </div>
                        </div>
                        <div className='col-md-2 col-xs-6 col-sm-2 margintop'  >
                            <label>&nbsp;</label>
                            <div className='form-group'>
                                <button className='btn btn-primary' value='template' onClick={this.SaveTamplate.bind(this)}>ADD</button>
                            </div>
                        </div>

                        <div className='col-md-12 col-xs-12 col-sm-12 margintop'>
                            {this.state.RSDATA.length > 0 && this.state.RSDATA != undefined &&
                                <Table
                                    data={[...this.state.RSDATA]}
                                    Delete="true"
                                    DeleteFn={(e) => this.deleteRow(e)}
                                    height='63vh'
                                    id='fielddata'>
                                </Table>}
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }




    async deleteRow(e) {
       let dt  = this.state.RSDATA
       dt.splice(e['key'], 1);
       this.setState({
           RSDATA:dt
       })
    }


}