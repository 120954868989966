import React, { Component } from "react";
import {
  Configuration,
  getDataTable,
  MandatoryFormFields,
  UpdatePrice,
  sleep,
  getNamshiJobDetails,
  json2xlsx,
  PriceAndDis,
  json2portalExport,
  PriceUpdateAmazon,
  UpdatePriceYoox,
} from "../Configuration";
import Table from "../Actions/Controls/Table/AntDTable";
import "../ORDER/OrderMaster/style.css";
import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import AutoCompleteCascad from "../Actions/Controls/AutoComplete/autoComplete.cascad";
import AutoCompletemulti from "../Actions/Controls/AutoComplete/autoCompleteMultiple";
import { Spin } from "antd";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactDOMServer from "react-dom/server";
import schedule from "node-schedule";
import "react-datepicker/dist/react-datepicker.css";
import UPLOADDIRECTEXCEL from "./frmDirectUploadExcel";
const NamshiCredStg = "772a0098-3579-11ec-a267-42010a6a0014";
const NamshiCredPrd_UAE = "b4eb442e-9a18-11ec-98ed-42010a590054";
const NamshiCredPrd = "c6348e57-87c1-11ef-8822-42010a590025";
const NamshiStgURL = "https://marketplace.namstg.net";
const NamshiPrdURL = "https://marketplace.namshi.net";
const token =
  "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJBUElVU0VSIiwiZXhwIjoxNzMyMDgwNDM5LCJpYXQiOjE3MzEyMTY0Mzl9.QRBD6Xms8G78Hi_4RGdwQ326t4006xkB2a0mmWGxpmM";
const token_UAE =
  "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJBUElVU0VSIiwiZXhwIjoxNzMyMjQ5NTU5LCJpYXQiOjE3MzEzODU1NTl9.SggnKza4uwlA0rftQqjQ_YKARx2V0QUFRMxzWxrDwnY";
const BaseUrl =
  "https://prodelksa.emirateslogistics.com:15100/ws/adidas-namshi/v1/item-price";
const BaseUrl_UAE =
  "http://151.253.159.104:31800/ws/adidas-namshi/v1/item-price";
let files = "";

export default class frmlotMasternew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginId: 0,
      loading: false,
      productList: [],
      orgid: "",
      lotID: 0,
      merge: "0",
      ProductName: "",
      ArticleNumber: "",
      CountryId: [],
      StyleCode: "",
      BlockID: "",
      files: null,
      AID: "",
      VName1: "",
      List: [],
      PortalID: [],
      excelData: [],
      tblname: "",
      upload: 0,
      adata: [],
      selectedDate: null,
      selectedTime: "",
      scheduledData: [],
      scheduledJobs: [],
    };
  }

  async componentDidMount() {
    // Fetch user data
    let rs = await getDataTable(
      "SELECT orgid FROM users WHERE userid = " + Configuration.USER_ID
    );
    await this.setState({
      loginId: Configuration.USER_ID,
      orgid: rs[0].orgid,
    });

    const storedFileData = localStorage.getItem("scheduledFiles");
    if (storedFileData) {
      const parsedFileData = JSON.parse(storedFileData);

      // Convert lastModified to a Date object
      const lastModified = new Date(parsedFileData.lastModified);

      // Create a Blob object with the actual file data
      const blob = new Blob([parsedFileData], { type: parsedFileData.type });

      // Create the File object directly
      files = new File([blob], parsedFileData.name, {
        lastModified: parsedFileData.lastModified, // Convert back to timestamp
        lastModifiedDate: parsedFileData.lastModifiedDate,
        type: parsedFileData.type,
        size: parsedFileData.size,
        webkitRelativePath: parsedFileData.webkitRelativePath,
      });

      // Log the File object to verify its properties
      console.log(files);
    }

    const scheduledJobsQuery =
      "SELECT * FROM schedulerPriceUpdate WHERE loginid = " +
      this.state.loginId +
      " and CONVERT(DATETIME, CONVERT(VARCHAR, logdate, 23) + ' ' + CONVERT(VARCHAR, SchTime, 8)) >= GETDATE();";
    console.log("Scheduled Jobs Query:", scheduledJobsQuery);
    const storedScheduledData = await getDataTable(scheduledJobsQuery);
    console.log("Stored Scheduled Data:", storedScheduledData);

    try {
      if (
        Array.isArray(storedScheduledData) &&
        storedScheduledData.length > 0
      ) {
        // Map over fetched scheduled jobs and extract relevant information
        const updatedScheduledData = storedScheduledData.map((job) => ({
          ...job,
        }));

        // Extracting country IDs along with display IDs and names
        const countryIds = updatedScheduledData.map((job) => ({
          DisplayId: job.CountryId,
        }));

        // Set state with fetched scheduled data, portal ID, block ID, lot ID, country IDs
        this.setState({
          scheduledData: updatedScheduledData,
          PortalID: updatedScheduledData[0].PortalId, // Assuming there's only one item in the array
          BlockID: updatedScheduledData[0].BlockId, // Assuming there's only one item in the array
          lotID: updatedScheduledData[0].Lotid, // Assuming there's only one item in the array
          CountryId: countryIds, // Storing array of country IDs with display IDs and names
        });

        // Execute any scheduled jobs with future dates and times
        updatedScheduledData.forEach((job) => {
          const scheduledDateTime = new Date(job.SchDate);
          scheduledDateTime.setHours(
            job.SchTime.split(":")[0],
            job.SchTime.split(":")[1]
          );

          const currentTime = new Date();

          if (scheduledDateTime > currentTime) {
            const scheduledJob = schedule.scheduleJob(
              scheduledDateTime,
              async () => {
                await this.handleScheduledJob();
                // Handle job completion or any additional logic here
              }
            );
            // Store the scheduled job in the state if needed
            this.setState((prevState) => ({
              scheduledJobs: [...prevState.scheduledJobs, scheduledJob],
            }));
            console.log(`Scheduled job executed for ${scheduledDateTime}`);
          }
        });
      } else {
        console.log("No scheduled data found or invalid data returned.");
      }
    } catch (error) {
      console.error("Error parsing stored scheduled data:", error);
    }
  }

  myChangeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onAfterSelect(selectedOptions, id, name, index = 0) {
    if (selectedOptions.length > 0) {
      this.setState({
        [id]: selectedOptions[0].DisplayId,
        [name]: selectedOptions[0].DisplayName,
      });
    } else {
      this.setState({
        [id]: "",
        [name]: "",
      });
    }
  }

  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
  };

  handleTimeChange = (event) => {
    this.setState({ selectedTime: event.target.value });
  };

  async onAutoCOmpletMultiPleSelect(selectedOptions, name) {
    if (selectedOptions.length > 0) {
      this.setState({
        [name]: selectedOptions,
      });
    } else {
      this.setState({
        [name]: [],
      });
    }
  }

  async GetTemplates() {
    this.setState({
      adata: [],
    });

    let MandatoryArray = [
      { Category: this.state.BlockID },
      { Portal: this.state.PortalID },
      { Lot: this.state.lotID },
    ];
    let check = MandatoryFormFields(MandatoryArray);
    if (check === false) {
      this.setState({ adata: [] });
      return false;
    }

    this.setState({ loading: true });
    let Spname = "ExportdataTemplateGenPrice";

    let qry =
      "EXEC " +
      Spname +
      " @catID=" +
      this.state.BlockID +
      ",@portalID=" +
      this.state.PortalID +
      ",@lotID=" +
      this.state.lotID +
      ",@orgID=" +
      this.state.orgid;
    let rs = await getDataTable(qry);
    debugger;
    if (rs.length > 0) {
      this.setState({
        adata: rs,
        loading: false,
      });
    } else {
      Swal.fire({
        position: "top-end",
        icon: "info",
        title: "NO DATA FOUND",
        showConfirmButton: false,
        timer: 1500,
      });
      this.setState({
        adata: [],
        loading: false,
      });
    }
  }

  async UploadFile() {
    let countryData = this.state.CountryId.map((country) => country.DisplayId);
    this.setState({
      loading: true,
    });

    let MandatoryArray = [
      { portal: this.state.PortalID },
      { BLOCK: this.state.BlockID },
    ];

    let check = MandatoryFormFields(MandatoryArray);

    if (check == false) {
      this.setState({ loading: false });
      return false;
    }

    let que =
      "EXEC frmExportData_CreateDataForNamshiPriceUpdate @lotid = " +
      this.state.lotID +
      ", @PortalID = " +
      this.state.PortalID;
    let res = await getDataTable(que);
    debugger;

    for (let i = 0; i < countryData.length; i++) {
      const rawData = res; // Assuming res is an array of objects from the stored procedure

      // Transform the data to create the payload
      const transformedData = rawData.map((item) => ({
        barcode: item.barcode,
        price: parseInt(item.price),
        special_price: parseInt(item.special_price),
      }));

      const payload = {
        items: transformedData,
      };

      console.log(payload);
      let cntcd = countryData[i].toLowerCase();
      debugger;
      let finalRes = await UpdatePrice(
        payload,
        cntcd == "ae" ? NamshiCredPrd_UAE : NamshiCredPrd,
        NamshiPrdURL,
        cntcd
      );
      console.log(finalRes);
      if (finalRes.status === "success") {
        await sleep(20000);
        this.setState({ loading: false });
        await Swal.fire({
          icon: "info",
          title:
            "DATA POST SUCCESSFULLY FOR " +
            countryData[i] +
            " PLEASE CHECK JOB EXCEL FOR MORE DETAILS",
          showConfirmButton: false,
          timer: 3500,
        });
        this.setState({ loading: true });
        let jobdt = await getNamshiJobDetails(
          finalRes.job_id,
          cntcd == "ae" ? NamshiCredPrd_UAE : NamshiCredPrd,
          NamshiPrdURL
        );

        for (let k = 0; k < jobdt["details"]["items"].length; k++) {
          jobdt["details"]["items"][k]["job_id"] = finalRes.job_id;
          const item = jobdt["details"]["items"][k];
          let que =
            " EXEC SaveDataForPriceUpdate @sku = " +
            jobdt["details"]["items"][k].barcode +
            ", @mrp = " +
            jobdt["details"]["items"][k]["price"] +
            " , @sprice = " +
            jobdt["details"]["items"][k]["special_price"] +
            " , @status = " +
            jobdt["details"]["items"][k].status +
            " , @jobid = " +
            "'" +
            jobdt["details"]["items"][k]["job_id"] +
            "'" +
            " , @loginid = " +
            this.state.loginId +
            " , @country = " +
            jobdt["details"]["items"][k].country +
            " , @portalid = " +
            this.state.PortalID;
          await getDataTable(que);
        }

        debugger;
        await json2xlsx(jobdt["details"]["items"], "JOB STATUS");
        this.setState({ loading: false });
      } else {
        await Swal.fire({
          icon: "info",
          title: "DATA INVALID PLEASE CHECK EXCEL FOR MORE DETAILS",
          showConfirmButton: false,
          timer: 1500,
        });
        await json2xlsx(finalRes["details"], "JOB STATUS");
      }
      this.setState({ loading: false });
    }
    const uniqueArticles = new Set();

    // Extract distinct articles from the Excel data
    const uniqueProcessedData = res.filter((item) => {
      const article = String(item.Article).replace(/\\/g, "");
      if (!uniqueArticles.has(article)) {
        uniqueArticles.add(article);
        return true;
      }
      return false;
    });

    let successfulUpdates = [];
    let failureUpdates = [];
    for (let i = 0; i < uniqueProcessedData.length; i++) {
      const item = uniqueProcessedData[i];
      const article = String(item.Article).replace(/\\/g, "");
      for (let i = 0; i < countryData.length; i++) {
        const country = countryData[i];
        let cntcd = countryData[i].toLowerCase();
        const portalName = this.state.Pname;
        const payload = {
          style: article,
          mrsp: Number(item.price).toFixed(0),
          discountedPrice: Number(item.Discount).toFixed(0),
          currency: cntcd == "ae" ? "AED" : "SAR",
          channel: `${portalName}-${country}`,
          countryCode: country,
        };
        this.setState({ loading: true });
        debugger;
        const PriceApi2 = await PriceAndDis(
          [payload],
          cntcd == "ae" ? token_UAE : token,
          cntcd == "ae" ? BaseUrl_UAE : BaseUrl
        );

        if (PriceApi2.messageStatus === "Success") {
          await sleep(2000);
          this.setState({ loading: false });
          await Swal.fire({
            icon: "info",
            title: "UPDATE PRICE SUCCESSFUL for " + country,
            showConfirmButton: false,
            timer: 3500,
          });

          const payloadDetails = {
            Article: payload.style,
            mrp: payload.mrsp,
            discount: payload.discount,
            country: country,
            status: PriceApi2.messageStatus,
            date: new Date().toLocaleDateString(),
          };

          let que1 =
            " EXEC SaveDataForRetailPriceAndDiscount @Article = '" +
            payloadDetails.Article +
            "', @PortalId = " +
            this.state.PortalID +
            " , @UpdatedMRP = " +
            payloadDetails.mrp +
            " , @Discount = " +
            payloadDetails.discount +
            " , @Country = " +
            "'" +
            payloadDetails.country +
            "'" +
            " , @Status = " +
            "'" +
            payloadDetails.status +
            "'" +
            " , @Loginid = " +
            this.state.loginId;
          await getDataTable(que1);

          successfulUpdates.push(payloadDetails);
          // await json2xlsx(successfulUpdates, "PRICE STATUS");
          this.setState({ loading: false });
          console.log(PriceApi2);
        } else {
          await Swal.fire({
            icon: "info",
            title: "DATA INVALID PLEASE CHECK EXCEL FOR MORE DETAILS",
            showConfirmButton: false,
            timer: 1500,
          });
          failureUpdates.push(PriceApi2);
          // await json2xlsx(PriceApi2, "messageStatus");
          this.setState({ loading: false });
        }
      }
    }
    await json2xlsx(successfulUpdates, "PRICE STATUS");
    await json2xlsx(failureUpdates, "messageStatus");
  }

  async GetData(e) {
    console.log(e);
    debugger;
    let prdct = e["#PList"];
    let list = "";
    let sheet = 1;
    let r = 1;
    let MandatoryArray = [{ Product: this.state.adata.length }];
    let check = MandatoryFormFields(MandatoryArray);
    if (check === false) {
      return false;
    }

    this.setState({ loading: true });

    if (sheet == 10) {
      this.setState({ loading: false });
      return;
    }

    if (sheet == 1) {
      let Que =
        "EXEC ExportTamplateTest @loginid=" +
        this.state.loginId +
        ", @alist='" +
        list +
        "',@filter='" +
        prdct +
        "', @tempID=" +
        e["#tid"] +
        " ,@catID=" +
        this.state.BlockID;
      let rs = await getDataTable(Que);
      debugger;
      if (Array.isArray(rs)) {
        let que1 =
          "Select SheetName,CellAddress from TemplateMaster where TemplateID = " +
          e["#tid"];
        let rs0 = await getDataTable(que1);
        await json2portalExport(
          rs,
          rs0[0].SheetName,
          rs0[0].CellAddress,
          this.state.Pname,
          e["#tid"],
          this.state.orgid
        );
        let que2 =
          "EXEC FrmExportData_ExportLotCreation @loginID = " +
          this.state.loginId +
          ", @tid = " +
          e["#tid"] +
          ", @plist = '" +
          prdct.slice(1, -1) +
          "' , @importLot = '" +
          this.state.LotName +
          "'";
        await getDataTable(que2);
        Swal.fire({
          icon: "info",
          title: "DATA EXPORTED SUCCESSFULLY",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        Swal.fire({
          icon: "info",
          title: "NO DATA FOUND",
          showConfirmButton: false,
          timer: 1500,
        });
      }
      this.setState({ loading: false });
    }
  }

  PostToNoonSFTP = async () => {
    this.setState({
      loading: true,
    });

    let MandatoryArray = [{ Files: this.state.files }];

    if (this.state.PortalID == 0) {
      MandatoryArray = [
        { Files: this.state.files },
        { BLOCK: this.state.BlockID },
      ];
    }

    let check = MandatoryFormFields(MandatoryArray);

    if (check == false) {
      this.setState({ loading: false });
      return false;
    }

    const { files } = this.state;

    if (!files) {
      console.error("No file selected");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("file", files);

      const response = await fetch(
        "https://api.blumangoes.ai:6143/upload_sftp",
        {
          method: "POST",
          body: formData,
        }
      );

      const result = await response.json();
      console.log(result);
      if (result.status === "success") {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Uploaded To SFTP Successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        this.setState({
          loading: false,
        });
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Error uploading file",
          showConfirmButton: false,
          timer: 1500,
        });
        this.setState({
          loading: false,
        });
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
    this.setState({
      loading: false,
    });
  };

  myFileChangeHandler = (event) => {
    files = event.target.files[0];
  };

  handleOAuthButtonClick() {
    if (this.state.PortalID === 67) {
      window.location.href =
        "https://sellercentral.amazon.ae/apps/authorize/consent?application_id=amzn1.sp.solution.39fb100d-18e4-4915-b60c-edaab2bb6e28&state=stateexample&version=beta";
    } else {
      window.location.href =
        "https://api.flipkart.net/oauth-service/oauth/authorize?client_id=284a4602b2879b66aa287411491864214259&redirect_uri=https://app.blumangoes.ai/&response_type=code&scope=Seller_Api&state=stateexample";
    }
  }

  handleGenerateTokenButtonClick = async () => {
    this.setState({
      loading: true,
    });
    let que =
      " EXEC frmExportData_CreateDataForAmazonPriceUpdate @lotid = " +
      this.state.lotID +
      " , @PortalID = " +
      this.state.PortalID;
    let skuDataList = await getDataTable(que);
    console.log(skuDataList);
    debugger;
    const { orgID } = this.state;
    const urlParams = new URLSearchParams(window.location.search);
    const oauthCode = urlParams.get("spapi_oauth_code");
    const sellerId = urlParams.get("selling_partner_id");

    if (!oauthCode) {
      await Swal.fire({
        icon: "warning",
        title: "Authorization code is missing",
        showConfirmButton: false,
        timer: 2500,
      });
      this.setState({
        loading: false,
      });
      return;
    }

    try {
      const response = await axios.post(
        "https://api.amazon.com/auth/o2/token",
        {
          grant_type: "authorization_code",
          code: oauthCode,
          client_id:
            "amzn1.application-oa2-client.83799e097f8945cdbe3f4996a29dd6d0",
          client_secret:
            "amzn1.oa2-cs.v1.8a5bee8d25ef736ecf4296df56bfe810cc1776926ae4cf4fb0a8bbe6d137af49",
          redirect_uri: "https://app.blumangoes.ai",
        }
      );

      const accessToken = response.data.access_token;
      console.log("Access Token:", accessToken);

      await Swal.fire({
        icon: "success",
        title: "Access Token generated successfully!",
        showConfirmButton: false,
        timer: 2500,
      });

      const allResponses = [];

      for (const skuData of skuDataList) {
        const marketplaceId =
          this.state.PortalID === 20 ? "A21TJRUUN4KGV" : "A2VIGQ35RCS4UG";

        const apiUrl = `https://sandbox.sellingpartnerapi-eu.amazon.com/listings/2021-08-01/items/${sellerId}/${skuData.sku}`;
        console.log(skuData);
        let payload = {
          MarketplaceId: marketplaceId,
          Skus: [skuData.sku],
          ItemType: "Sku",
          ItemCondition: "NEW",
          Prices: [
            {
              price: {
                ListingPrice: {
                  amount: skuData.price,
                  currencyCode: "AED",
                },
              },
            },
          ],
        };

        console.log(payload);
        try {
          let createListingApi = await PriceUpdateAmazon(
            payload,
            accessToken,
            apiUrl
          );
          allResponses.push({ skuData, response: createListingApi });
        } catch (error) {
          console.error("Error creating listing:", error);
          allResponses.push({
            skuData,
            error: "Failed to create listing. Please try again.",
          });
        }
      }

      const successResponses = allResponses.filter(
        (entry) =>
          entry.response &&
          (entry.response.status === "ACCEPTED" || entry.response.code === 200)
      );

      if (successResponses.length === skuDataList.length) {
        await sleep(2000);
        await Swal.fire({
          icon: "info",
          title:
            "ALL DATA POSTED SUCCESSFULLY. PLEASE CHECK EXCEL FOR MORE DETAILS",
          showConfirmButton: false,
          timer: 3500,
        });
      } else {
        await Swal.fire({
          icon: "error",
          title:
            "SOME DATA FAILED TO POST. PLEASE CHECK EXCEL FOR MORE DETAILS",
          showConfirmButton: false,
          timer: 3500,
        });
      }

      console.log("All Responses:", allResponses);
      await json2xlsx(allResponses, "JOB STATUS");
      this.setState({ loading: false });
    } catch (error) {
      console.error("Error generating access token:", error);

      await Swal.fire({
        icon: "error",
        title: "Failed to generate access token. Please try again.",
        showConfirmButton: false,
        timer: 2500,
      });
      this.setState({
        loading: false,
      });
    }
  };

  async uploadFileYoox() {
    let MandatoryArray = [
      { Category: this.state.BlockID },
      { Portal: this.state.PortalID },
      { Lot: this.state.lotID },
      { file: files },
    ];
    let check = MandatoryFormFields(MandatoryArray);
    if (check === false) {
      return false;
    }
    this.setState({
      loading: true,
    });
    debugger;
    const accessToken = "1f0179c4-6fe0-4f78-b805-184ac896abb0";
    const formData = new FormData();
    formData.append("file", files);

    try {
      const response = await UpdatePriceYoox(files, accessToken);
      console.log("Response:", response);

      if (response.import_id !== undefined) {
        // Assuming a valid import_id indicates success
        await Swal.fire({
          icon: "success",
          title: "File Posted Successfully.",
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        await Swal.fire({
          icon: "error",
          title: "SOME ERROR OCCURRED.",
          showConfirmButton: false,
          timer: 2500,
        });
      }

      this.setState({
        loading: false,
      });
    } catch (error) {
      console.error("Error uploading file:", error);

      await Swal.fire({
        icon: "error",
        title: "SOME ERROR OCCURRED.",
        showConfirmButton: false,
        timer: 2500,
      });

      this.setState({
        loading: false,
      });
    }
  }

  scheduleAPICall = () => {
    const { selectedDate, selectedTime, scheduledData } = this.state;
    const mandatoryArray = [
      { name: "Portal", value: this.state.PortalID },
      { name: "Block", value: this.state.BlockID },
      { name: "Time", value: selectedTime },
      { name: "Date", value: selectedDate },
    ];

    let errorMessage = "";
    let hasError = false;

    mandatoryArray.forEach((field) => {
      if (!field.value) {
        errorMessage = `Please enter ${field.name}`;
        hasError = true;
        return;
      }
    });

    if (hasError) {
      Swal.fire({
        icon: "error",
        title: errorMessage,
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }

    if (selectedDate) {
      const scheduledDateTime = new Date(selectedDate);
      scheduledDateTime.setHours(
        selectedTime.split(":")[0],
        selectedTime.split(":")[1]
      );

      const currentTime = new Date();

      if (scheduledDateTime > currentTime) {
        const job = schedule.scheduleJob(scheduledDateTime, () => {
          this.handleScheduledJob(); // Call a new function to handle scheduled jobs
        });
        console.log(`Scheduled API call for ${scheduledDateTime}`);
      } else {
        Swal.fire({
          icon: "error",
          title: "Selected date and time should be in the future",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Please select a date and time",
        showConfirmButton: false,
        timer: 1500,
      });
    }
    console.log(files);
    debugger;
    const fileData = {
      name: files.name,
      lastModified: files.lastModified,
      lastModifiedDate: files.lastModifiedDate,
      size: files.size,
      type: files.type,
      webkitRelativePath: files.webkitRelativePath,
    };

    // Save the file data to localStorage
    localStorage.setItem("scheduledFiles", JSON.stringify(fileData));

    const newScheduledJob = {
      date: selectedDate,
      time: selectedTime,
      files: files,
      portalID: this.state.PortalID,
      blockID: this.state.BlockID,
      countryID: this.state.CountryId,
      lotID: this.state.lotID,
      // Add more properties if needed
    };

    // Update the state with newScheduledJob
    const updatedScheduledData = [...scheduledData, newScheduledJob];

    // Convert the file data to a data URL before storing
    const updatedScheduledDataWithURL = updatedScheduledData.map((job) => ({
      ...job,
    }));

    let query =
      "INSERT INTO schedulerPriceUpdate (SchDate, SchTime, logdate, loginid, BlockId, PortalId, Lotid, CountryId) output inserted.SchDate VALUES ('" +
      selectedDate +
      "','" +
      selectedTime +
      "'," +
      "cast(getdate() as date) ," +
      this.state.loginId +
      "," +
      this.state.BlockID +
      "," +
      this.state.PortalID +
      "," +
      this.state.lotID +
      ",'" +
      this.state.CountryId[0].DisplayId +
      "')";
    let query1 = getDataTable(query);
    this.setState({
      scheduledData: updatedScheduledData,
    });

    // Show success message
    Swal.fire({
      icon: "success",
      title: "Scheduled successfully",
      text: `The job has been scheduled for ${selectedDate} at ${selectedTime}.`,
      showConfirmButton: false,
      timer: 1500,
    });
  };

  handleScheduledJob = () => {
    switch (this.state.PortalID) {
      case 52:
        this.UploadFile();
        break;
      case 64:
        this.PostToNoonSFTP();
        break;
      case 67:
        this.handleGenerateTokenButtonClick();
        break;
      case 71:
        this.uploadFileYoox();
        break;
      default:
        // Handle default case if necessary
        break;
    }

    // You can also delete the executed job from the database if needed
  };

  showScheduledJobs = async () => {
    const query =
      "SELECT * FROM schedulerPriceUpdate WHERE loginid = " +
      this.state.loginId +
      " and CONVERT(DATETIME, CONVERT(VARCHAR, logdate, 23) + ' ' + CONVERT(VARCHAR, SchTime, 8)) >= GETDATE();";
    const tableData = await getDataTable(query);

    let jobList = "";

    if (tableData.length > 0) {
      jobList = tableData.map((job, index) => (
        <li key={index}>
          {`Job ${index + 1}: ${job.SchDate.toString()} ${job.SchTime}`}
        </li>
      ));
    } else {
      jobList = <li>No jobs scheduled</li>;
    }

    const jobListElement = <ul>{jobList}</ul>;

    Swal.fire({
      title: "Scheduled Jobs",
      html: ReactDOMServer.renderToString(jobListElement),
      showConfirmButton: false,
    });
  };

  render() {
    const { selectedTime } = this.state;
    return (
      <div className="content-wrapper">
        <div
          className="loader"
          style={{ display: this.state.loading ? "block" : "none" }}
        >
          <div className="loader-item">
            <Spin />
          </div>
        </div>
        <section className="content">
          <div
            id="ctl00_CPHMaincontent_divclient"
            className="box box-success"
            style={{ marginTop: "-13px" }}
          >
            <div className="box-header with-border">
              <h3 className="box-title">
                EXPORT PRICING MASTER
                <span
                  id="ctl00_CPHMaincontent_DivOrdNo"
                  style={{ display: "inline" }}
                ></span>
              </h3>
              {/* <div className="pull-right">
                <button
                  className="btn btn-block btn-primary"
                  onClick={this.showScheduledJobs}
                >
                  View Scheduled Jobs
                </button>
              </div> */}
              {1 == 0 && (
                <div className="pull-right" style={{ marginRight: "2px" }}>
                  <UPLOADDIRECTEXCEL width="800px" />
                </div>
              )}
              {(this.state.PortalID === 52 || this.state.PortalID === 77) &&
                this.state.adata.length > 0 && (
                  <div className="pull-right" style={{ marginRight: "2px" }}>
                    <label>&nbsp;</label>
                    <button
                      className="btn btn-primary"
                      onClick={this.UploadFile.bind(this)}
                    >
                      POST API
                    </button>
                  </div>
                )}
              {this.state.PortalID === 64 && this.state.adata.length > 0 && (
                <div className="pull-right" style={{ marginRight: "2px" }}>
                  <div className="form-group">
                    <input
                      type="button"
                      value="POST"
                      onClick={this.PostToNoonSFTP.bind(this)}
                      className="btn btn-block btn-primary"
                    />
                  </div>
                </div>
              )}
              {this.state.PortalID === 64 && this.state.adata.length > 0 && (
                <div className="pull-right" style={{ marginRight: "2px" }}>
                  <div className="form-group">
                    <input
                      name="fldname"
                      type="file"
                      accept=".xls,.xlsx"
                      id="tp1"
                      onChange={this.myFileChangeHandler}
                      placeholder="Enter FieldName"
                      tabIndex={1}
                      className="form-control"
                    />
                  </div>
                </div>
              )}
              {this.state.PortalID === 67 && this.state.adata.length > 0 && (
                <div className="pull-right" style={{ marginRight: "2px" }}>
                  <button
                    className="btn btn-primary"
                    onClick={this.handleOAuthButtonClick.bind(this)}
                  >
                    Authorize with Amazon
                  </button>
                </div>
              )}
              {this.state.PortalID === 67 && this.state.adata.length > 0 && (
                <div className="pull-right" style={{ marginRight: "2px" }}>
                  <button
                    className="btn btn-primary"
                    onClick={this.handleGenerateTokenButtonClick}
                  >
                    Post API
                  </button>
                </div>
              )}
              {this.state.PortalID === 71 && this.state.adata.length > 0 && (
                <div className="pull-right" style={{ marginRight: "2px" }}>
                  <div className="form-group">
                    <input
                      type="button"
                      value="POST API"
                      onClick={this.uploadFileYoox.bind(this)}
                      className="btn btn-block btn-primary"
                    />
                  </div>
                </div>
              )}
              {this.state.PortalID === 71 && this.state.adata.length > 0 && (
                <div className="pull-right" style={{ marginRight: "2px" }}>
                  <div className="form-group">
                    <input
                      name="fldname"
                      type="file"
                      accept=".xls,.xlsx"
                      id="tp1"
                      onChange={this.myFileChangeHandler}
                      placeholder="Enter FieldName"
                      tabIndex={1}
                      className="form-control"
                    />
                  </div>
                </div>
              )}
            </div>
            <div
              className="box-body"
              id="LotMaster"
              style={{ display: "block" }}
            >
              <div className="row">
                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                  <label>SELECT BLOCK</label>
                  <span className="vcode">*</span>
                  <AutoCompleteCascad
                    id="id"
                    frmNm="FRMALLDATA"
                    quryNm="FILLORGBLOCKS"
                    db="IMAGEDB"
                    filter1="orgid"
                    filterId1={this.state.orgid}
                    filter2=""
                    filterId2=""
                    placeholder="Please Select Block"
                    onAfterSelect={(e) =>
                      this.onAfterSelect(e, "BlockID", "BlockName")
                    }
                  ></AutoCompleteCascad>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                  <label>SELECT PORTAL</label>
                  <span className="vcode">*</span>
                  <AutoCompleteCascad
                    id="portalID"
                    frmNm="FRMATTRIBUTEALIASE"
                    quryNm="FILLPORTAL"
                    db="IMAGEDB"
                    filter2="BlockID"
                    filterId2={this.state.BlockID}
                    filter1="orgid"
                    filterId1={this.state.orgid}
                    placeholder="Please Select Portal"
                    onAfterSelect={(e) =>
                      this.onAfterSelect(e, "PortalID", "Pname")
                    }
                    isValid={this.state.isValid}
                  ></AutoCompleteCascad>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                  <label>Select Lot</label>
                  <span className="vcode">*</span>
                  <AutoCompleteCascad
                    id="catid"
                    frmNm="FRMPRICEUPDATE"
                    quryNm="FILLPRICELOT"
                    db="IMAGEDB"
                    filter1="OrgId"
                    filterId1={this.state.orgid}
                    filter2="CategoryId"
                    filterId2={this.state.BlockID}
                    placeholder="PLEASE SELECT LOT"
                    onAfterSelect={(e) =>
                      this.onAfterSelect(e, "lotID", "LotName")
                    }
                    isValid={this.state.isValid}
                  ></AutoCompleteCascad>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                  <label>&nbsp;</label>
                  <label>SELECT COUNTRY</label>
                  <span className="vcode">*</span>
                  <AutoCompletemulti
                    id="PID"
                    frmNm="FRMPRICEUPDATE"
                    quryNm="FILLCOUNTRY"
                    db="IMAGEDB"
                    placeholder="Please Select Country"
                    onAfterSelect={(e) =>
                      this.onAutoCOmpletMultiPleSelect(e, "CountryId")
                    }
                    isValid={this.state.isValid}
                  ></AutoCompletemulti>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                  <label>&nbsp;</label>
                  <div className="form-group">
                    <button
                      className="btn btn-block btn-primary"
                      onClick={this.GetTemplates.bind(this)}
                    >
                      Get Templates
                    </button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                  <label htmlFor="date-input">SELECT DATE:</label>
                  <DatePicker
                    selected={this.state.selectedDate}
                    onChange={this.handleDateChange}
                  />
                </div>
                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                  <label htmlFor="time-input">SELECT TIME:</label>
                  <div className="form-group">
                    <input
                      type="time"
                      id="time-input"
                      value={selectedTime}
                      onChange={this.handleTimeChange}
                    />
                    <p>Selected Time: {selectedTime}</p>
                  </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                  <label>&nbsp;</label>
                  <button
                    className="btn  btn-block btn-primary"
                    onClick={this.scheduleAPICall}
                  >
                    SCHEDULE POSTING
                  </button>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 margintop">
                  {this.state.adata.length > 0 &&
                    this.state.adata != undefined && (
                      <Table
                        data={[...this.state.adata]}
                        downloadRowFn={(e) => this.GetData(e)}
                        downloadRow="true"
                        height="63vh"
                        id="fielddata"
                      ></Table>
                    )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
