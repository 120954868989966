import React from 'react';
import { Configuration, MandatoryFormFields, getDataTable } from '../Configuration';
import { Spin } from 'antd';
import AntD from '../Actions/Controls/Table/AntDTable'
import AutoCompleteCascad from '../Actions/Controls/AutoComplete/autoComplete.cascad';
import Swal from 'sweetalert2/dist/sweetalert2.js'

export default class frmMerging extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loginId: 0,
            loading: false,
            merge: 0,
            rcdata: [],
            rdata: [],
            selectedrows: [],
            bkid: ''
        }
    }

    async componentDidMount() {
        let rs = await getDataTable("select orgid from users where userid = " + Configuration.USER_ID)
        await this.setState({
            loginId: Configuration.USER_ID,
            orgid: rs[0].orgid
        });
    }

    myChangeHandler = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
            rcdata: [],
            rdata: []
        });
    }

    async onAfterSelect(selectedOptions, id, name, id1, cnt) {
        if (selectedOptions.length > 0) {
            await this.setState({
                [id]: selectedOptions[0].DisplayId,
                [name]: selectedOptions[0].DisplayName,
                [id1]: selectedOptions[0].GID1,
                [cnt]: selectedOptions[0].cnt,
                rcdata: [],
                rdata: []
            })
            if ([id] === 'GID') {
                await this.getRData()
            }
            else if (this.state.VID && this.state.VID1) {
                await this.getVData()
            }
        }
        else {
            this.setState({
                [id]: '',
                [name]: '',
                [id1]: '',
                [cnt]: ''
            })
        }
    }

    async getRData() {

        let MandatoryArray = [
            { "Block": this.state.bkid },
            { "RelationGroups": this.state.GName }
        ]

        let check = MandatoryFormFields(MandatoryArray);
        if (check === false) {
            return false;
        }

        let que0 = "SELECT GroupName AS 'Group Name', Name AS 'Relationship Name', A.Value AS 'Age-Group', B.Value AS 'Product Type', C.Value AS Category FROM RelationshipGroup rg OUTER Apply ( SELECT OptValue, RelationshipName FROM RelationshipController rc INNER JOIN RelationshipMaster rm ON rm.RelationshipID = rc.RID and rm.Rgroup = rg.TblID INNER JOIN optionValue ov ON ov.UID = rc.ControllerValueID and ov.AtrributeID = 226) A (Value, Name) OUTER Apply (SELECT OptValue FROM RelationshipController rc INNER JOIN RelationshipMaster rm ON rm.RelationshipID = rc.RID and rm.Rgroup = rg.TblID and rm.RelationshipName = A.Name INNER JOIN optionValue ov ON ov.UID = rc.ControllerValueID and ov.AtrributeID = 81) B (Value) OUTER Apply (SELECT OptValue FROM RelationshipController rc INNER JOIN RelationshipMaster rm ON rm.RelationshipID = rc.RID and rm.Rgroup = rg.TblID and rm.RelationshipName = A.Name INNER JOIN optionValue ov ON ov.UID = rc.ControllerValueID and ov.AtrributeID = 38) C (Value) WHERE rg.TblID in (" + this.state.GID + "," + this.state.GID1 + ")"
        this.setState({
            loading: true,
            rcdata: await getDataTable(que0)
        })
        let que = 'EXEC FrmRelationMerge_MismatchAttributes @bkid = ' + this.state.bkid + ', @gid = ' + this.state.GID + ', @gid1 = ' + this.state.GID1
        this.setState({
            rdata: await getDataTable(que),
            loading: false
        })

    }

    async getVData() {

        this.setState({ loading: true })
        let MandatoryArray = [
            { "Main Value": this.state.VID },
            { "Value to merge": this.state.VID1 }
        ]
        let check = MandatoryFormFields(MandatoryArray);
        if (check === false) {
            this.setState({ loading: false })
            return false;
        }

        if (this.state.VID === this.state.VID1) {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Please choose different values',
                showConfirmButton: false,
                timer: 1500
            })
            this.setState({ loading: false })
            return false;
        }

        let que = "EXEC FRMMERGING_GETALIASEDDATA @VID = " + this.state.VID + ",@VID1 = " + this.state.VID1
        this.setState({
            rdata: await getDataTable(que),
            loading: false
        })
    }

    async Merge() {
        if (this.state.merge == 1) {
            await this.RMerge()
        }
        else if (this.state.merge == 2) {
            await this.AMerge()
        }
        else if (this.state.merge == 3) {
            await this.VMerge()
        }
    }

    async RMerge() {

        let MandatoryArray = [
            { "Block": this.state.bkid },
            { "RelationGroups": this.state.GName }
        ]

        let check = MandatoryFormFields(MandatoryArray);
        if (check == false) {
            return false;
        }

        let a = 0

        for (let i = 0; i < this.state.rdata.length; i++) {
            if (this.state.rdata[i]['Status'] == 'False') {
                a = 1
            }
        }

        if (this.state.rdata.length > 0 && this.state.selectedrows.length == 0 && a == 1) {
            let rst = window.confirm('Are you sure you want to merge the relationships without adding the mismatch attributes?')
            if (!rst) {
                return false;
            }
        }

        let lst = []

        for (let i = 0; i < this.state.selectedrows.length; i++) {
            if (this.state.selectedrows[i]['Status'] == 'False') {
                lst.push(this.state.selectedrows[i])
            }
        }
        let que = ""
        let rs = ""

        if (lst.length > 0) {
            this.setState({ loading: true })
            for (let i = 0; i < lst.length; i++) {
                que = "INSERT INTO RelationshipLeaves SELECT RelationshipID, " + lst[i]['#AID'] + ", " + lst[i]['#VID'] + ", " + this.state.loginId + ", getdate() FROM RelationshipMaster WHERE Rgroup =" + this.state.GID
                rs = await getDataTable(que)
            }
            this.setState({ loading: false })
        }

        que = "EXEC FRMMERGING_RelationMerge @blockID = " + this.state.bkid + " ,@LogID = " + this.state.loginId + ",@GID = " + this.state.GID + ", @GID1 = " + this.state.GID1
        this.setState({ loading: true })
        rs = await getDataTable(que)
        this.setState({ loading: false })

        if (rs.length > 0) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Relationship Groups successfully merged',
                showConfirmButton: false,
                timer: 1500
            })
            this.setState({
                rcdata: [],
                rdata: []
            })
        }

        else {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Merging could not be done',
                showConfirmButton: false,
                timer: 1500
            })
        }
    }

    async AMerge() {

        this.setState({ loading: true })
        let MandatoryArray = [
            { "Main Attribute": this.state.AID },
            { "Attribute to merge": this.state.AID1 }
        ]
        let check = MandatoryFormFields(MandatoryArray);
        if (check == false) {
            this.setState({ loading: false })
            return false;
        }

        if (this.state.AID == this.state.AID1) {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Please choose different attributes',
                showConfirmButton: false,
                timer: 1500
            })
            this.setState({ loading: false })
            return false;
        }

        let que = "EXEC FRMMERGING_AttributeMerge @blockID = " + this.state.bkid + ", @AID = " + this.state.AID + ", @AID1 = " + this.state.AID1 + ", @LogID = " + this.state.loginId
        let rs = await getDataTable(que)
        this.setState({ loading: false })
        if (rs) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Merging Successful',
                showConfirmButton: false,
                timer: 1500
            })
        }
        else {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Merging could not be done',
                showConfirmButton: false,
                timer: 1500
            })
        }
    }

    async DeleteAliasing() {

        if (this.state.loginId == 1 || this.state.loginId == 10) {
            let rst = window.confirm('Are you sure you want to delete?')
            if (!rst) {
                return false;
            }

            let dt = []
            for (let i = 0; i < this.state.selectedrows.length; i++) {
                dt.push(this.state.selectedrows[i]['#UID'])
            }

            let que = "DELETE FROM OptionValueAliasing OUTPUT deleted.* into OptionValueAliasingHistory WHERE UID IN (" + dt + ")"
            this.setState({ loading: true })
            let rs = await getDataTable(que)
            this.setState({ loading: false })

            Swal.fire({
                position: 'top-end',
                icon: 'info',
                title: 'Aliasing Deleted SuccessFully',
                showConfirmButton: false,
                timer: 2500
            })

            await this.getVData()
        }
    }

    async Aliastonull() {

        if (this.state.loginId == 1 || this.state.loginId == 10) {
            let rst = window.confirm('Are you sure you want to alias to NULL VALUE?')
            if (!rst) {
                return false;
            }

            let dt = []
            for (let i = 0; i < this.state.selectedrows.length; i++) {
                dt.push(this.state.selectedrows[i]['#UID'])
            }

            let que = "UPDATE OptionValueAliasing SET ValueID = 23554 OUTPUT deleted.* into OptionValueAliasingHistory WHERE UID IN (" + dt + ")"
            this.setState({ loading: true })
            let rs = await getDataTable(que)
            this.setState({ loading: false })

            Swal.fire({
                position: 'top-end',
                icon: 'info',
                title: 'Aliasing Updated SuccessFully',
                showConfirmButton: false,
                timer: 2500
            })

            await this.getVData()
        }
    }

    async VMerge() {
        this.setState({ loading: true })
        let MandatoryArray = [
            { "Main Value": this.state.VID },
            { "Value to merge": this.state.VID1 }
        ]
        let check = MandatoryFormFields(MandatoryArray);
        if (check == false) {
            this.setState({ loading: false })
            return false;
        }

        if (this.state.VID == this.state.VID1) {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Please choose different values',
                showConfirmButton: false,
                timer: 1500
            })
            this.setState({ loading: false })
            return false;
        }

        if (this.state.rdata.length == 0) {
            let que1 = "EXEC FRMMERGING_ValueMerge @blockID = " + this.state.bkid + ", @AID1 = " + this.state.AID2 + ", @AID2 = " + this.state.AID3 + ", @VID = " + this.state.VID + ", @VID1 = " + this.state.VID1 + ", @Vname = '" + this.state.VName + "', @Vname1 = '" + this.state.VName1 + "', @LogID = " + this.state.loginId
            this.setState({ loading: true })
            let rs1 = await getDataTable(que1)
            this.setState({ loading: false });

            if (rs1.length > 0) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Merging Successful',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
            else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Merging could not be done',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }
        else {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Please check the Value Aliasing first.',
                showConfirmButton: false,
                timer: 1500
            })
        }
    }

    async AVList() {

        this.setState({
            loading: true,
            ldata: []
        })

        let MandatoryArray = [
            { "Block": this.state.bkid }
        ]
        let check = MandatoryFormFields(MandatoryArray);
        if (check == false) {
            this.setState({ loading: false })
            return false;
        }

        let que = "EXEC FRMMERGING_MergedAttrList @blockID = " + this.state.bkid

        if (this.state.merge == 3) {
            que = "EXEC FRMMERGING_MergedValueList @blockID = " + this.state.bkid
        }

        let rs = await getDataTable(que)

        this.setState({ loading: false })
        if (rs.length > 0) {
            this.setState({ rcdata: rs })
        }
        else {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'No data found',
                showConfirmButton: false,
                timer: 1500
            })
        }
    }

    async refresh() {
        this.setState({
            rdata: [],
            rcdata: []
        })
    }

    async selectedDataFN(e) {
        if (e.length > 0) {
            this.setState({ selectedrows: e })
        }
        else {
            this.setState({ selectedrows: [] })
        }
    }

    render() {
        return (
            <div className="content-wrapper">
                <div className='loader' style={{ display: this.state.loading ? "block" : "none" }}>
                    <div className='loader-item'>
                        <Spin />
                    </div>
                </div>
                <section className="content">
                    <div id="ctl00_CPHMaincontent_div" className="box box-success" style={{ marginTop: '-13px' }}>
                        <div className="box-header with-border">
                            <h3 className="box-title">Merging Master
                                <span id="ctl00_CPHMaincontent_DivOrdNo" style={{ display: 'inline' }}>
                                </span>
                            </h3>
                            <div className='pull-right' style={{ display: this.state.merge == 2 || this.state.merge == 3 ? "block" : "none" }}>
                                <div className='form-group'>
                                    <button className='btn btn-primary' onClick={this.AVList.bind(this)}>List</button>
                                </div>
                            </div>
                            <div className='pull-right'>
                                <div className='form-group'>
                                    {<a id="refrsh" onClick={this.refresh.bind(this)} style={{ color: 'green' }}><span class="fa fa-refresh">Refresh</span></a>}
                                </div>
                            </div>
                        </div>
                        <div className="box-body" id="merge" style={{ display: 'block' }}>
                            <div className="row">
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                    <label>Select Block</label>
                                    <AutoCompleteCascad
                                        id="bkid"
                                        frmNm="FRMALLDATA"
                                        quryNm="FILLORGBLOCKS"
                                        db="IMAGEDB"
                                        filter1="orgid"
                                        filterId1={this.state.orgid}
                                        filter2=""
                                        filterId2=""
                                        placeholder="Please Select Block"
                                        onAfterSelect={(e) => this.onAfterSelect(e, "bkid", "bkname")}
                                        isValid={this.state.isValid}
                                    ></AutoCompleteCascad>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                    <label>Select Merging</label><span className="vcode">*</span>
                                    <div className="form-group">
                                        <select name="merge" onChange={this.myChangeHandler} id="val" tabIndex={1} className="form-control">
                                            <option selected="true" disabled="disabled">No Selection</option>
                                            {/* <option value="1">Relationship</option> */}
                                            <option value="2">Attribute</option>
                                            <option value="3">Value</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop" style={{ display: this.state.merge == 1 ? "block" : "none" }}>
                                    <label>Select Relations</label>
                                    <AutoCompleteCascad
                                        id="RID"
                                        frmNm="FRMRMERGE"
                                        quryNm="FILLRELATIONS"
                                        db="IMAGEDB"
                                        filter1='BlockID'
                                        filterId1={this.state.bkid}
                                        filter2=''
                                        filterId2=''
                                        placeholder="Please Select Relation Group"
                                        onAfterSelect={(e) => this.onAfterSelect(e, "GID", "GName", "GID1", "cnt")}
                                        isValid={this.state.isValid}
                                    ></AutoCompleteCascad>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop" style={{ display: this.state.merge == 2 ? "block" : "none" }}>
                                    <label>Select Main Attribute</label>
                                    <AutoCompleteCascad
                                        id="AID"
                                        frmNm="FRMMERGING"
                                        quryNm="FILLATTRIBUTE"
                                        db="IMAGEDB"
                                        filter1='CatID'
                                        filterId1={this.state.bkid}
                                        filter2=''
                                        filterId2=''
                                        placeholder="Please Select Attribute"
                                        onAfterSelect={(e) => this.onAfterSelect(e, "AID", "AName")}
                                        isValid={this.state.isValid}
                                    ></AutoCompleteCascad>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop" style={{ display: this.state.merge == 2 ? "block" : "none" }}>
                                    <label>Select Attribute to Merge</label>
                                    <AutoCompleteCascad
                                        id="AID1"
                                        frmNm="FRMMERGING"
                                        quryNm="FILLATTRIBUTE"
                                        db="IMAGEDB"
                                        filter1='CatID'
                                        filterId1={this.state.bkid}
                                        filter2=''
                                        filterId2=''
                                        placeholder="Please Select Attribute"
                                        onAfterSelect={(e) => this.onAfterSelect(e, "AID1", "AName1")}
                                        isValid={this.state.isValid}
                                    ></AutoCompleteCascad>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop" style={{ display: this.state.merge == 3 ? "block" : "none" }}>
                                    <label>Select Attribute</label>
                                    <AutoCompleteCascad
                                        id="AID2"
                                        frmNm="FRMMERGING"
                                        quryNm="FILLATTRIBUTE"
                                        db="IMAGEDB"
                                        filter1='CatID'
                                        filterId1={this.state.bkid}
                                        filter2=''
                                        filterId2=''
                                        placeholder="Please Select Attribute"
                                        onAfterSelect={(e) => this.onAfterSelect(e, "AID2", "AName2")}
                                        isValid={this.state.isValid}
                                    ></AutoCompleteCascad>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop" style={{ display: this.state.merge == 3 ? "block" : "none" }}>
                                    <label>Select Main Value</label>
                                    <AutoCompleteCascad
                                        id="VID"
                                        frmNm="FRMMERGING"
                                        quryNm="FILLVALUES"
                                        db="IMAGEDB"
                                        filter1='AID'
                                        filterId1={this.state.AID2}
                                        filter2='BlockID'
                                        filterId2={this.state.bkid}
                                        placeholder="Please Select Value"
                                        onAfterSelect={(e) => this.onAfterSelect(e, "VID", "VName")}
                                        isValid={this.state.isValid}
                                    ></AutoCompleteCascad>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop" style={{ display: this.state.merge == 3 ? "block" : "none" }}>
                                    <label>Select Attribute</label>
                                    <AutoCompleteCascad
                                        id="AID3"
                                        frmNm="FRMMERGING"
                                        quryNm="FILLATTRIBUTE"
                                        db="IMAGEDB"
                                        filter1='CatID'
                                        filterId1={this.state.bkid}
                                        filter2=''
                                        filterId2=''
                                        placeholder="Please Select Attribute"
                                        onAfterSelect={(e) => this.onAfterSelect(e, "AID3", "AName3")}
                                        isValid={this.state.isValid}
                                    ></AutoCompleteCascad>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop" style={{ display: this.state.merge == 3 ? "block" : "none" }}>
                                    <label>Select Value to Merge</label>
                                    <AutoCompleteCascad
                                        id="VID1"
                                        frmNm="FRMMERGING"
                                        quryNm="FILLVALUES"
                                        db="IMAGEDB"
                                        filter1='AID'
                                        filterId1={this.state.AID3}
                                        filter2='BlockID'
                                        filterId2={this.state.bkid}
                                        placeholder="Please Select Value"
                                        onAfterSelect={(e) => this.onAfterSelect(e, "VID1", "VName1")}
                                        isValid={this.state.isValid}
                                    ></AutoCompleteCascad>
                                </div>

                                <div className='pull-right' style={{ display: this.state.merge == 1 || this.state.merge == 2 ? "block" : "none" }}>
                                    <label>&nbsp;</label>
                                    <div className='form-group'>
                                        <button className='btn btn-primary' onClick={this.Merge.bind(this)}>Merge</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box-body" id="merge2">
                            <div className="row">
                                <div className='pull-right' style={{ display: this.state.merge == 3 ? "block" : "none" }}>
                                    <label>&nbsp;</label>
                                    <div className='form-group'>
                                        <button className='btn btn-primary' onClick={this.Merge.bind(this)}>Merge</button>
                                    </div>
                                </div>
                                {/* <div className='pull-right' style={{ display: this.state.merge == 3 && this.state.rdata.length > 0 ? "block" : "none" }}>
                                    <label>&nbsp;</label>
                                    <div className='form-group'>
                                        <button className='btn btn-primary' onClick={this.Aliastonull.bind(this)}>Alias to Null</button>
                                    </div>
                                </div> */}
                                {/* <div className='pull-right' style={{ display: this.state.merge == 3 && this.state.rdata.length > 0 ? "block" : "none" }}>
                                    <label>&nbsp;</label>
                                    <div className='form-group'>
                                        <button className='btn btn-primary' onClick={this.DeleteAliasing.bind(this)}>Delete</button>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="box-body" id="merge1">
                            <div className="row">
                                <div >
                                    <div className="col-xs-12 col-sm-12 col-md-12 margintop">
                                        {this.state.rcdata != undefined && this.state.rcdata.length > 0 &&
                                            <AntD
                                                data={[...this.state.rcdata]}
                                            ></AntD>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div >
                                    <div className="col-xs-12 col-sm-12 col-md-12 margintop">
                                        {this.state.rdata != undefined && this.state.rdata.length > 0 &&
                                            <AntD
                                                data={[...this.state.rdata]}
                                                selected="true"
                                                SelectedFn={(e) => this.selectedDataFN(e)}
                                            ></AntD>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}