import { TextField } from "@material-ui/core";
import { Spin } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PageTitle from "../../Common/pageTitle";
import Button from "@material-ui/core/Button";
import { APIService } from "../../../Config/action/apiAction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ImageDetail(props) {
  const [loading, setLoading] = useState(false);
  const [selectedPortal, setSelectedPortal] = useState(-1);
  const [portalList, setPortalList] = useState([]);
  const [portalBrandList, setPortalBrandList] = useState([]);
  const [attributedList, setAttributeList] = useState([]);
  const [lotList, setLotList] = useState([]);
  const [blockList, setBlockList] = useState([]);
  const [selectedBlock, setSelectedBlock] = useState(0);
  const [isFetched, setIsFetched] = useState(false);

  useEffect(() => {
    defaultUseEffect();
  }, []);

  const defaultUseEffect = () => {
    setLoading(true);
    let userId = JSON.parse(localStorage.getItem("auth_user")).ID;
    let payload = {
      query: `exec frmImagePosition_GetPortalBrandAPI @userid = ${userId}`,
    };
    props
      .dispatch(APIService("DYNAMIC_NEW_POST", "", payload))
      .then((data) => {
        setLoading(false);
        if (data.status === 200 && data.data.status === 1000) {
          setPortalList(data.data.data);
          getAllBlocks();
        } else {
          throw 500;
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getAllBlocks = () => {
    setLoading(true);
    let payload = {
        "query":"frmBlockMaster_GetBlockData"
    };
    props.dispatch(APIService("DYNAMIC_NEW_POST", "", payload)).then((data) => {
        setLoading(false);
        if (data.status === 200 && data.data.status === 1000) {
          setBlockList(data.data.data);
        }
        else{
            throw 500;
        }
    }).catch((err) => {
        setLoading(false);
    })
  }

  const blockChangeHandler = (e) => {
    if(e.currentTarget.value) {
      setSelectedBlock(e.currentTarget.value);
    }
  }

  const fetchAllData = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    if(selectedBlock && selectedPortal) {
      let orgId = JSON.parse(localStorage.getItem("auth_user")).OrgId;
      let payload = {
        query: `exec frmImagePositioning_GetAllFeildsAllrdyMappedLocalFields @portalid = ${selectedPortal}, @orgid = ${orgId}, @blockid = ${selectedBlock}`,
      };
      props.dispatch(APIService("DYNAMIC_NEW_POST", "", payload)).then((data) => {
        setLoading(false);
        if (data.status === 200 && data.data.status === 1000) {
          setIsFetched(true);
          if (data.status === 200 && data.data.status === 1000) {
            setPortalBrandList(data.data?.data);
            setLoading(true);
            let payload = {
              query: "exec frmImagePosition_GetImagesAttributesToBeMapped",
            };
            props
              .dispatch(APIService("DYNAMIC_NEW_POST", "", payload))
              .then((data) => {
                setLoading(false);
                if (data.status === 200 && data.data.status === 1000) {
                  setAttributeList(data.data?.data);
                  getLoadData();
                }
              });
          }
        } else {
          throw 500;
        }
      })
      .catch((err) => {
        setLoading(false);
      });
    }
    else {
      if(selectedBlock <= 0) {
        toast("Please select a block", {
          position: "top-right",
          type: 'error',
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      }
      if(selectedPortal <= 0) {
        toast("Please select a Portal", {
          position: "top-right",
          type: 'error',
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      }
    }
  }

  const portalHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let val = e.currentTarget.value;
    if (val) {
      setSelectedPortal(val);
    }
  };

  

  const getLoadData = () => {
    let userId = JSON.parse(localStorage.getItem("auth_user")).ID;
    let payload = {
      "query": `EXEC frmImagePositioning_GetLotData  @userid = '${userId}'`
    }
    props.dispatch(APIService("DYNAMIC_NEW_POST", "", payload)).then((data) => {
      setLoading(false);
      if (data.status === 200 && data.data.status === 1000) {
        setLotList(data.data.data);
      }
    });
  }

  const attributeHandler = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    let arr = [...portalBrandList];
    if (e.currentTarget.value) {
      arr.map((x, index) => {
        if (x.HeaderName === item.HeaderName) {
          x.AttributeID = e.currentTarget.value;
        }
      });
      setPortalBrandList(arr);
    }
  };
  const lotHandler = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    let arr = [...portalBrandList];
    if (e.currentTarget.value) {
      arr.map((x, index) => {
        if (x.HeaderName === item.HeaderName) {
          x.LotID = e.currentTarget.value;
        }
      });
      setPortalBrandList(arr);
    }
  }
  const handleSubmit = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);

    let userId = JSON.parse(localStorage.getItem("auth_user")).ID;
    let orgId = JSON.parse(localStorage.getItem("auth_user")).OrgId;
    let payload = {
      query: `frmImagePositioning_SaveAPI @attributeid = ${
        item.AttributeID
      }, @portalid = ${item.PortalID.toString()}, @LotID = '${item.LotID || 0}', @portalheader  = '${
        item.HeaderName
      }',@orgID = ${orgId},@loginID = ${userId}`,
    };

    props
      .dispatch(APIService("DYNAMIC_NEW_POST", "", payload))
      .then((data) => {
        setLoading(false);
        if (data.status === 200 && data.data.status === 1000) {
          toast("Portal updated successfully", {
            position: "top-right",
            type: "success",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          defaultUseEffect();
        } else {
          throw 500;
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <div className="content-wrapper">
      <div className="loader" style={{ display: loading ? "block" : "none" }}>
        <div className="loader-item">
          <Spin />
        </div>
        <ToastContainer />
      </div>
      <section className="content" style={{ background: "white" }}>
        <div
          id="ctl00_CPHMaincontent_divclient"
          className="box box-success"
          style={{ marginTop: "-13px" }}
        >
          <div className="box-header with-border">
            <h3 className="box-title">
              Image Positioning
              {/* <sup>
            (<span className="vcode">*</span> Fields are mandatory)
          </sup> */}
              <span
                id="ctl00_CPHMaincontent_DivOrdNo"
                style={{ display: "inline" }}
              ></span>
            </h3>
            <div className="pull-right" style={{ marginLeft: "10px" }}>
              {/* <button onClick={() => openModal()} className="btn btn-primary">
              Create Brand
            </button> */}
            </div>{" "}
            <ToastContainer />
            {/* <Modal
            title="Create Brand"
            visible={newBrandModal}
            onOk={handleOk}
            onCancel={() => setNewBrandModal(false)}
          >
            <div className="form-group" style={{ marginRight: "10px" }}>
              <input
                name="Name"
                id="Name"
                onChange={(e) => setNewBrand(e.currentTarget.value)}
                value={newBrand || ""}
                // onChange={this.myChangeHandler}
                placeholder="Enter Name"
                tabIndex={1}
                className="form-control"
              />
            </div>

            {error ? (
              <span style={{ color: "red", paddingTop: "30px" }}>
                This field is required
              </span>
            ) : (
              <></>
            )}
          </Modal> */}
          </div>
          <div className="d-flex">
            {/* <div
              className="box-body"
              id="orderDetails"
              style={{ display: "block" }}
            >
              <label>Select Portal</label>
              <div className="row">
                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                  <select
                    className="currency-select"
                    value={selectedPortal}
                    onChange={(e) => portalHandler(e)}
                  >
                    <option value="">Select Portal</option>
                    {portalList.length > 0 && (
                      <Fragment>
                        {portalList.map((item, index) => {
                          return (
                            <option key={index} value={item.portalid}>
                              {item.PortalName}
                            </option>
                          );
                        })}
                      </Fragment>
                    )}
                  </select>
                  <div className="form-group"></div>
                </div>
              </div>
            </div> */}
            <div className="col-xs-6 col-sm-6 col-md-2 margintop">
              <label>Select Portal<span className='errClass'>*</span></label>
              <div className="form-group">
                <select
                  className="importSheetSelect"
                  value={selectedPortal}
                  style={{ height: "30px", marginTop: "0px" }}
                  onChange={(e) => portalHandler(e)}
                >
                  <option value="">Select Portal</option>
                  {portalList.length > 0 && (
                    <Fragment>
                      {portalList.map((item, index) => {
                        return (
                          <option key={index} value={item.portalid}>
                            {item.PortalName}
                          </option>
                        );
                      })}
                    </Fragment>
                  )}
                </select>
              </div>
            </div>

            <div className="col-xs-6 col-sm-6 col-md-2 margintop">
              <label>Select Block<span className='errClass'>*</span></label>
              <div className="form-group">
                <select
                  className="importSheetSelect"
                  value={selectedBlock}
                  style={{ height: "30px", marginTop: "0px" }}
                  onChange={(e) => blockChangeHandler(e)}
                >
                  <option value="">Select Block</option>
                  {blockList.length > 0 && (
                    <Fragment>
                      {blockList.map((item, index) => {
                        return (
                          <option key={index} value={item.BlockID}>
                            {item.BlockName}
                          </option>
                        );
                      })}
                    </Fragment>
                  )}
                </select>
              </div>
            </div>

            <div className="col-xs-6 col-sm-6 col-md-2 margintop">
              <label className="opacity0">Select Block<span className='errClass'>*</span></label>
              <div className="form-group">
                <button
                  onClick={(e) => fetchAllData(e)}
                  className="btn btn-primary"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
          <br />
          <div
            className="box-body"
            id="orderDetails"
            style={{ display: "block" }}
          >
            <div className="row">
              {portalBrandList.length && portalBrandList.length > 0 ? (
                <>
                  {" "}
                  {portalBrandList.map((item, index) => (
                    <Fragment key={index}>
                      <div
                        className="d-flex inputClassHandler"
                        style={{ marginBottom: "10px" }}
                      >
                        <div
                          className="form-group"
                          style={{ marginRight: "10px" }}
                        >
                          <p style={{"width": "150px"}}>{item.HeaderName}</p>
                        </div>
                        <div
                          className="form-group"
                          style={{ marginRight: "10px" }}
                        >
                          <select
                            className="currency-select"
                            value={item.AttributeID || ""}
                            onChange={(e) => attributeHandler(e, item)}
                          >
                            <option value="">Select Attribute</option>
                            {attributedList.length > 0 && (
                              <Fragment>
                                {attributedList.map((item, index) => {
                                  return (
                                    <option key={index} value={item.AID}>
                                      {item.AttributeName}
                                    </option>
                                  );
                                })}
                              </Fragment>
                            )}
                          </select>
                        </div>
                        <div
                          className="form-group"
                          style={{ marginRight: "10px" }}
                        >
                          <select
                            className="currency-select"
                            value={item.LotID || ""}
                            onChange={(e) => lotHandler(e, item)}
                          >
                            <option value="">Select Data Lot</option>
                            {lotList.length > 0 && (
                              <Fragment>
                                {lotList.map((item, index) => {
                                  return (
                                    <option key={index} value={item.LOTID}>
                                      {item.LotName}
                                    </option>
                                  );
                                })}
                              </Fragment>
                            )}
                          </select>
                        </div>

                        <button
                          onClick={(e) => handleSubmit(e, item)}
                          style={{ marginLeft: "15px", height: "46px" }}
                          className="btn btn-primary"
                          disabled={item.AttributeID == null ? true : false}
                        >
                          Update
                        </button>
                      </div>
                    </Fragment>
                  ))}
                </>
              ) : (
                  <>
                    {
                        isFetched ? (
                            <div className="text-center">
                                No Data Found
                            </div>
                        ) : null
                    }
                  </>
              )}

              {/* <div className="col-xs-6 col-sm-6 col-md-2 margintop">
              <div className="form-group">
                <button
                  //   onClick={(e) => addCharge(e)}
                  className="btn btn-primary"
                >
                  Create
                </button>
              </div>
            </div> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: () => {
      dispatch({
        type: "loginUser",
      });
    },
    logoutUser: () => {
      dispatch({
        type: "logoutUser",
      });
    },
    dispatch,
  };
};

export default connect(null, mapDispatchToProps)(withRouter(ImageDetail));
