import { Modal } from 'antd';
import React, { Component } from 'react';
import '../ORDER/OrderMaster/style.css'
import Table from '../Actions/Controls/Table/AntDTable'
import { Configuration, MandatoryFormFields, getDataTable, OnlyNumberKey } from '../Configuration';
import AutoCompleteInput from '../Actions/Controls/AutoComplete/autoComplete';
import AutoCompleteCascad from '../Actions/Controls/AutoComplete/autoComplete.cascad';
import Swal, { swal } from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import _, { bind } from 'lodash';
const dataBase = "IMAGEDB";

let SequenceList = [];
export default class frmSetPriorityImage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            modal1Visible: false,
            modal2Visible: false,
            AID: 0,
            UID: 0,
            Priority: '',
            loading: false,
            list: [],
            rwid: 0
        };
    }

    async componentDidMount() {
        let rs = await getDataTable("select orgid from users where userid = " + Configuration.USER_ID)
        await this.setState({
            loginId: Configuration.USER_ID,
            orgid: rs[0].orgid
        });
        await this.getData(this);
    }

    async getData() {
        let que3 = await getDataTable("select RWID as [#rwid], category, AttributeName,priority from AttributeCatPriorityMapping inner join attributemaster on AttributeCatPriorityMapping.attributeid = attributemaster.aid")
        SequenceList = que3
        this.setState({
            list: que3
        })
        this.forceUpdate();
    }


    setModal1Visible(modal1Visible) {
        this.setState({ modal1Visible });
    }
    setModal2Visible(modal2Visible) {
        this.setState({ modal2Visible, adata: [] });
    }

    onAfterSelect(selectedOptions, id, name) {
        if (selectedOptions.length > 0) {
            this.setState({
                [id]: selectedOptions[0].DisplayId,
                [name]: selectedOptions[0].DisplayName,
            })
        }
        else {
            this.setState({
                [id]: '',
                [name]: '',
            })
        }
    }

    async saveData() {
        let MandatoryArray = [
            { "Sequence": this.state.VName },
            { "Attribute": this.state.AID },
            { "Priority": this.state.Priority }
        ]
        let check = MandatoryFormFields(MandatoryArray);
        if (check === false) {
            this.setState({ adata: [] })
            return false;
        }
        this.setState({
            loading: true,
        });

        if (this.state.rwid > 0) {
            await this.updateData()
            return;
        }

        let que2 = await getDataTable("select * from AttributeCatPriorityMapping where AttributeId =" + this.state.AID + " and Category = '" + this.state.VName + "' and Priority =" + this.state.Priority)
        if (que2.length > 0) {
            Swal.fire({
                icon: 'info',
                title: 'ALREADY EXIST',
                showConfirmButton: false,
                timer: 1500
            })
            return;
        }
        let Que = "INSERT INTO AttributeCatPriorityMapping(AttributeId, Category, Priority ) output inserted.RWID VALUES (" + this.state.AID + ",'" + this.state.VName + "'," + this.state.Priority + ")";
        let que1 = await getDataTable(Que);
        if (que1.length > 0) {
            Swal.fire({
                icon: "success",
                title: "Saved Successfully",
                showConfirmButton: false,
                timer: 1500
            }); this.setState({
                loading: true,
            });
        } else {
            Swal.fire({
                icon: "error",
                title: "Not Saved",
                showConfirmButton: false,
                timer: 1500
            }); this.setState({
                loading: true,
            });
        }
        await this.getData(this);
        this.forceUpdate();
    }

    myChangeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }


    async deleteRow(e) {
        console.log(e);
        if (this.state.loginId === "1" || this.state.loginId === "10" || this.state.loginId === "6") {
            let st = window.confirm("Are you sure want to delete ?")
            if (!st) {
                return false;
            }
            console.log(e);
            let Que = "delete from AttributeCatPriorityMapping where rwid = " + e["#rwid"]
            let rs = await getDataTable(Que)
            Swal.fire({
                position: 'top-end',
                icon: 'info',
                title: 'Successfully Deleted',
                showConfirmButton: false,
                timer: 1500
            })
            await this.getData()
        }
        else {
            alert("You are not allowed to delete")
            return;
        }
    }

    async EditRowFn(e) {
        if (this.state.loginId === "1" || this.state.loginId === "10" || this.state.loginId === "6") {
            let st = window.confirm("Are you sure you want to Edit?");
            if (!st) {
                return false;
            }
            let Que = "select RWID as [#rwid], aid as [#aid], category, AttributeName, priority from AttributeCatPriorityMapping inner join attributemaster on AttributeCatPriorityMapping.attributeid = attributemaster.aid where rwid = " + e["#rwid"];
            let rs = await getDataTable(Que);
            if (rs.length > 0) {
                this.setState({
                    AID: rs[0]["#aid"],
                    VName: rs[0].category,
                    AttName: rs[0].AttributeName,
                    Priority: rs[0].priority,
                    rwid: rs[0]["#rwid"],
                    modal2Visible: true, // Open the modal when editing
                });
            }
        } else {
            alert("You are not allowed to edit");
            return;
        }
    }

    async updateData() {
        this.setState({ loading: true })
        let que4 = "UPDATE AttributeCatPriorityMapping SET AttributeId =" + this.state.AID + ", Category ='" + this.state.VName + "', Priority =" + this.state.Priority + " OUTPUT inserted.rwid WHERE (rwid = " + this.state.rwid + ")"
        let que5 = await getDataTable(que4);
        if (que5.length > 0) {
            Swal.fire({
                position: 'top-end',
                icon: 'info',
                title: 'Successfully Updated',
                showConfirmButton: false,
                timer: 1500
            })
            await this.getData()
        } else {
            alert("Some Error Occurred")
            return;
        }
    }

    render() {
        return (
            <div>
                <button type="button" onClick={() => this.setModal2Visible(true)} className="btn btn-primary">SET IMAGE SEQUENCE</button>
                <Modal
                    title="Set Image Sequence"
                    width={this.props.width}
                    visible={this.state.modal2Visible}
                    onOk={() => this.setModal2Visible(false)}
                    onCancel={() => this.setModal2Visible(false)}
                    destroyOnClose={true}
                    maskClosable={false}
                    footer={null}
                >
                    <div className='row'>
                        <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                            <label>Select Sequence</label>
                            <AutoCompleteInput
                                id="UID"
                                frmNm="FRMSETPRIORITYIMAGE"
                                quryNm="FILLSEQUENCES"
                                db="IMAGEDB"
                                placeholder="Please Select Sequence"
                                onAfterSelect={(e) => this.onAfterSelect(e, "UID", "VName")}
                                isValid={this.state.isValid}
                                value={this.state.UID}
                            ></AutoCompleteInput>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-md-3 margintop">
                            <label>Select Attributes</label>
                            <AutoCompleteInput
                                id="AID"
                                frmNm="FRMSETPRIORITYIMAGE"
                                quryNm="FILLATTRIBUTES"
                                db="IMAGEDB"
                                placeholder="Please Select Attribute"
                                onAfterSelect={(e) => this.onAfterSelect(e, "AID", "AttName")}
                                isValid={this.state.isValid}
                                value={this.state.AID}
                            ></AutoCompleteInput>
                        </div>
                        <div className='col-xs-6 col-sm-6 col-md-2'>
                            <label>Select Priority</label>
                            <span className="vcode">*</span>
                            <div className="form-group">
                                <select name="Priority" onChange={this.myChangeHandler} value={this.state.Priority} id="" tabIndex={1} className="form-control"  >
                                    <option selected="true">Please Select</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                </select>
                            </div>
                        </div>
                        <div className='col-md-2 col-xs-6 col-sm-2 margintop'>
                            <label>&nbsp;</label>
                            <div className='form-group'>
                                <button className='btn btn-primary' value='template' onClick={this.saveData.bind(this)}>Save</button>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 margintop">
                            {this.state.list.length > 0 && this.state.list !== undefined &&
                                <Table
                                    data={[...this.state.list]}
                                    Delete="true"
                                    EditRow='true'
                                    EditRowFn={(e) => this.EditRowFn(e)}
                                    DeleteFn={(e) => this.deleteRow(e)}
                                    height='63vh'
                                    id='fielddata'
                                    exportXL="true">
                                </Table>
                            }
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}