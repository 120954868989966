import React, { useEffect, useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Avatar from '@material-ui/core/Avatar';
import SendIcon from '@material-ui/icons/Send';
// import WorkIcon from '@material-ui/icons/Work';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import LockIcon from '@material-ui/icons/Lock';
import { makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { APIService } from '../../../Config/action/apiAction';
import moment from 'moment';
import { TokenService } from '../../../Config/action/tokenAction';
import PageTitle from '../../Common/pageTitle';
import { Modal, Spin } from 'antd';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    //   marginBottom:'60px',
      backgroundColor: theme.palette.background.paper,
    },
}));

function NotificationDetail(props) {

    const [state, setState] = useState({
        today: "",
        later: ""
    });
    const [modal, setModal] = useState(false);
    const [activeNotification, setActiveNotification] = useState(null);
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    
    // Notification Types
    // Security - Lock
    // Payment - Wallet
    // Category - Send
    // admin_request - LibraryBooks
    const sortDate = (a,b) => {  
        var dateA = new Date(a.date).getTime();
        var dateB = new Date(b.date).getTime();
        return dateA > dateB ? -1 : 1;  
    }; 

    useEffect(() => {
        defaultUseEffect();
    }, []);

    const defaultUseEffect = () => {
        setLoading(true);
        props.dispatch(TokenService("getToken", "auth_user")).then((token) => {
            props.dispatch(APIService('POST', "GetPaymentData?query= GET_NOTIFICATION @ID = "+token.ID, "")).then((data) => {
                if(data.data.status === 1000 && data.data.data.length > 0) {
                    let resp = data.data.data;
                    props.setActiveNotification(resp[0].unreadCount > 0);
                    let today = [];
                    let later = [];
                    let cd = new Date();
                    let currentDate = {
                        year: cd.getFullYear(),
                        month: cd.getMonth(),
                        date: cd.getDate()
                    }
                    resp.map((item, index) => {
                        let d = new Date(item.date);
                        let itemDate = {
                            date: d.getDate(),
                            month: d.getMonth(),
                            year: d.getFullYear()
                        }
                        if(currentDate.year === itemDate.year && currentDate.month === itemDate.month && currentDate.date === itemDate.date){
                            today.push(item)
                        }
                        else{
                            later.push(item)
                        }
                    })
                    
                    let t = today.sort(sortDate);
                    let l = later.sort(sortDate);
                    
                    setLoading(false);
                    setState({
                        ...state,
                        today: t,
                        later: l
                    })
                }
                else{
                    setLoading(false);
                }
            })
        })
    }

    const openInvoice = (orderId) => {
        props.history.push(`/invoice/${orderId}`);
    }

    const viewNotification = (e, item, value) => {
        e.preventDefault();
        e.stopPropagation();
        let payload = {
            "query":`BroadcastNotification_UpdateStatus @NotifyID = ${item.NotifyID}`
        };
        setLoading(true);
        props.dispatch(APIService("DYNAMIC_POST", "", payload)).then((data) => {
            setLoading(false);
            if (data.status === 200 && data.data.status === 1000) {
                setActiveNotification(item);
                setModal(true);
            }
            else {
                setActiveNotification(item);
                setModal(true);
            }
        }).catch((err) => {
            setLoading(false);
            setActiveNotification(item);
            setModal(true);
        })
    }

    const format = (date, time) => {
        if(time){
            return moment(date).format("h:mm a")
        }
        else{
            return moment(date).format('DD/MM/YYYY');
        }
    }

    const formatBoth = (date) => {
        return moment(date).format('DD/MM/YYYY h:mm a');
    }

    return (
        <div className="content-wrapper wrapped" style={{paddingBottom:'40px'}}>
            <div className='loader' style={{ display: loading ? "block" : "none" }}>
                <div className='loader-item'>
                    <Spin />
                </div>
            </div>
            <PageTitle title={"Notifications"} />
            <Modal
                title="Notification"
                centered
                visible={modal}
                closable={false}
                onOk={() => {
                    defaultUseEffect();
                    setModal(false)
                }}
                cancelButtonProps={{ style: { display: 'none' } }}
                >
                <h5 style={{fontWeight: "bold"}}>
                    Type
                </h5>
                <p>{activeNotification ? activeNotification.type : ''}</p>
                <h5 style={{fontWeight: "bold"}}>
                    Description
                </h5>
                <p>{activeNotification ? activeNotification.title : ''}</p>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div style={{width: "50%"}}>
                        <h5 style={{fontWeight: "bold"}}>
                            Date
                        </h5>
                        <p>{activeNotification ? formatBoth(activeNotification.date) : ''}</p>
                    </div>
                    {
                        (activeNotification && activeNotification.type.toLowerCase() === 'payment' && activeNotification.attechment) ? (
                            <div style={{width: "50%"}}>
                                <h5 style={{fontWeight: "bold"}}>
                                    Attachment
                                </h5>
                                <p>
                                    <a onClick={() => openInvoice(activeNotification.attechment)}>
                                        {activeNotification ? activeNotification.attechment : ''}
                                    </a>
                                </p>
                            </div>
                        ) : (activeNotification && activeNotification.type.toLowerCase() === 'request') ? (
                            <div style={{width: "50%"}}>
                                <h5 style={{fontWeight: "bold"}}>
                                    Attachment
                                </h5>
                                <p>
                                    <a onClick={() => props.history.push("/approve-request/list")}>
                                        Approve/Reject Requests
                                    </a>
                                </p>
                            </div>
                        ) : null
                    }
                </div>
            </Modal>
            <section className="content" style={{paddingBottom: '60px'}}>
                <div className="container-fluid" style={{paddingLeft:'15%', paddingRight:'15%'}}>
                    <div>

                    </div>
                    {
                        state.today.length > 0 ? (
                            <List className={classes.root} style={{marginTop:'20px'}}>
                                <ListItem style={{borderBottom:'1px solid #F1F1F1', boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}>    
                                    <h3 style={{fontSize:'26px', fontWeight:'bold', marginTop:'20px'}} className="mb-3 ml-2">Today</h3>
                                </ListItem>
                                {
                                    state.today.map((item, index) => {
                                        return (
                                            <ListItem key={index} className={item.isRead ? "bgWhite" : "bgUnread"} style={{borderBottom:'1px solid #F1F1F1', boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}>
                                                <ListItemAvatar>
                                                    <Avatar style={{backgroundColor:'#0FB8C9'}}>
                                                        {
                                                            item.type.toLowerCase() === 'security' ? (<LockIcon />) : item.type.toLowerCase() === 'payment' ? 
                                                                (<AccountBalanceWalletIcon />) : item.type.toLowerCase() === 'request' ? (<AssignmentIndIcon />) : (<SendIcon />)
                                                        }
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary={item.title} secondary={format(item.date, true)} />
                                                <ListItemSecondaryAction>
                                                    <Tooltip title="View">
                                                        <IconButton edge="end" onClick={(e) => viewNotification(e, item, "today")} style={{outline:'none'}}>
                                                            <ArrowForwardIosIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        )
                                    })
                                }
                            </List>
                        ) : null
                    }
                    
                    {
                        state.later.length > 0 ? (
                            <List className={classes.root} style={{marginTop:'60px'}}>
                                <ListItem style={{borderBottom:'1px solid #F1F1F1', boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}>
                                    
                                    <h3 style={{fontSize:'26px', fontWeight:'bold', marginTop:'20px'}} className="mb-3 ml-2">Earlier</h3>
                                    
                                </ListItem>
                                {
                                    state.later.map((item, index) => {
                                        return (
                                            <ListItem key={index} className={item.isRead ? "bgWhite" : "bgUnread"} style={{borderBottom:'1px solid #F1F1F1', boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}>
                                                <ListItemAvatar>
                                                    <Avatar style={{backgroundColor:'#0FB8C9'}}>
                                                        {
                                                            item.type.toLowerCase() === 'security' ? (<LockIcon />) : item.type.toLowerCase() === 'payment' ? (<AccountBalanceWalletIcon />) : 
                                                                item.type.toLowerCase() === 'request' ? (<AssignmentIndIcon />) : (<SendIcon />)
                                                        }
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary={item.title} secondary={format(item.date, false)} />
                                                <ListItemSecondaryAction>
                                                    <Tooltip title="View">
                                                        <IconButton edge="end" onClick={(e) => viewNotification(e, item, "later")} style={{outline:'none'}}>
                                                            <ArrowForwardIosIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        )
                                    })
                                }
                            </List>
                        ) : null
                    }
                </div>
            </section>
        </div>  
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: () => {
            dispatch({
                type: 'loginUser'
            });
        },
        logoutUser: () => {
            dispatch({
                type: 'logoutUser'
            });
        },
        dispatch
    }
}

export default connect(null, mapDispatchToProps)(withRouter(NotificationDetail));