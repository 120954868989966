import React, { Component } from "react";
import {
  Configuration,
  getDataTable,
  MandatoryFormFields,
  exceltodb,
  json2xlsx,
} from "../Configuration";
import Table from "../Actions/Controls/Table/AntDTable";
import "../ORDER/OrderMaster/style.css";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Moment from "moment";
import "sweetalert2/src/sweetalert2.scss";
import AutoCompleteInput from "../Actions/Controls/AutoComplete/autoComplete";
import AutoCompleteCascad from "../Actions/Controls/AutoComplete/autoComplete.cascad";
import { Spin } from "antd";
const dataBase = "IMAGEDB";
const currentDate = Moment().format("DD-MMM-YYYY hh:mm:ss");

export default class frmlotMasternew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginId: 0,
      loading: false,
      productList: [],
      orgid: "",
      LotId: "",
      merge: "0",
      ProductName: "",
      ArticleNumber: "",
      StyleCode: "",
      BlockID: "",
      AID: "",
      VName1: "",
      List: [],
      selectedrows: [],
      status: "Not Live",
      PortalID: 0,
      files: "",
      excelSKUList: [],
    };
  }

  async componentDidMount() {
    let rs = await getDataTable(
      "select orgid from users where userid = " + Configuration.USER_ID
    );
    await this.setState({
      loginId: Configuration.USER_ID,
      orgid: rs[0].orgid,
    });
  }

  myChangeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onAfterSelect(selectedOptions, id, name, index = 0) {
    if (selectedOptions.length > 0) {
      this.setState({
        [id]: selectedOptions[0].DisplayId,
        [name]: selectedOptions[0].DisplayName,
      });
    } else {
      this.setState({
        [id]: "",
        [name]: "",
      });
    }
  }

  async selectedDataFN(e) {
    if (e.length > 0) {
      this.setState({ selectedrows: e });
    } else {
      this.setState({ selectedrows: [] });
    }
  }

  async GetProductData() {
    let MandatoryArray = [
      { Block: this.state.BlockID },
      { "Search Type": this.state.merge },
    ];

    let check = MandatoryFormFields(MandatoryArray);

    if (check === false) {
      this.setState({ loading: false });
      return false;
    }

    this.setState({
      productList: [],
      loading: true,
    });

    let st = [];
    let que = "";

    if (this.state.merge === "1") {
      que =
        " select ProductID as [#pid], ProductName as [SKU CODE], c.AttributeValue as article from ";
      que +=
        " ProductMaster outer apply (select AttributeValue from ProductDetails ";
      que +=
        " where ProductID= ProductMaster.ProductID  and AttributeID = 245) c ";
      que += " where CategoryId = " + this.state.BlockID;
      que += " and OrgID = " + this.state.orgid;
      que += " and ProductName like '" + this.state.ProductName + "%' ";
      st = await getDataTable(que, "IMAGEDB");
      debugger;
    } else if (this.state.merge === "2") {
      que =
        "SELECT distinct LotDetails.productID as [#pid], ProductMaster.ProductName as [SKU CODE], ";
      que +=
        " c.AttributeValue as article FROM LotDetails INNER JOIN ProductMaster ON ";
      que += " LotDetails.productID = ProductMaster.ProductID ";
      que +=
        " outer apply (select attributevalue from ProductDetails where productID = ProductMaster.ProductID ";
      que += " and AttributeID = 245)c ";
      que += " WHERE ProductMaster.CategoryId = " + this.state.BlockID;
      que += " and LotDetails.LotID = " + this.state.LotId;
      st = await getDataTable(que, "IMAGEDB");
      debugger;
    } else if (this.state.merge === "4") {
      que =
        " select productid as [#pid], ProductName as [SKU CODE], c.AttributeValue as article from ";
      que +=
        " ProductMaster outer apply (select attributevalue from ProductDetails ";
      que +=
        " where ProductID = ProductMaster.ProductID and AttributeID = 245 )c ";
      que += " where OrgId = " + this.state.orgid;
      que += " and c.AttributeValue = '" + this.state.ArticleNumber + "'";
      st = await getDataTable(que, "IMAGEDB");
      debugger;
    } else if (this.state.merge === "5") {
      que =
        " select ProductMaster.productid as [#pid], ProductName as [SKU CODE], d.AttributeValue as article from ";
      que +=
        " ProductMaster outer apply (select ProductID , AttributeValue from ProductDetails ";
      que +=
        " where ProductID = ProductMaster.ProductID and AttributeID = 95 )c ";
      que += " outer apply ";
      que += " (select attributevalue from ProductDetails ";
      que += " where ProductID = c.ProductID and AttributeID = 245 )d ";
      que += " where OrgId = " + this.state.orgid;
      que += " and c.AttributeValue = '" + this.state.StyleCode + "'";
      st = await getDataTable(que, "IMAGEDB");
      debugger;
    } else {
      que =
        "select  ProductMaster.ProductID as[#pid], ProductMaster.ProductName as [SKU CODE], c.AttributeValue as article from ";
      que +=
        " ProductMaster inner join ProductDetails on ProductMaster.ProductID = ProductDetails.ProductID ";
      que +=
        " outer apply (select attributevalue from ProductDetails where productID = ProductMaster.ProductID ";
      que += " and AttributeID = 245)c ";
      que +=
        " where AttributeID = " +
        this.state.AID +
        " and ProductDetails.AttributeValue like '" +
        this.state.VName1 +
        "%' ";
      que +=
        " and OrgId = " +
        this.state.orgid +
        " and CategoryId = " +
        this.state.BlockID +
        "";
      st = await getDataTable(que, "IMAGEDB");
      debugger;
    }

    this.setState({
      productList: st,
      loading: false,
    });

    // if (st.length > 0) {
    //   console.log("get Table");
    // } else {
    //   Swal.fire({
    //     position: "top-end",
    //     icon: "error",
    //     title: "Product Not Found",
    //     showConfirmButton: false,
    //     timer: 1500,
    //   });
    // }
  }

  async AddData() {
    if (this.state.selectedrows.length === 0) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Please select data then press add",
        showConfirmButton: false,
        timer: 1500,
      });
    }

    let dt = this.state.List;
    this.setState({ loading: true });

    for (var i = 0; i < this.state.selectedrows.length; i++) {
      if (dt.includes(this.state.selectedrows[i])) {
      } else {
        dt.push(this.state.selectedrows[i]);
      }
    }

    this.setState({
      List: dt,
      loading: false,
    });
  }

  async SaveData() {
    if (this.state.List.length === 0) {
      Swal.fire({
        icon: "error",
        title: "PLEASE ADD SELECTED DATA THEN PRESS SAVE",
        showConfirmButton: false,
        timer: 1500,
      });
      return false;
    }

    if (this.state.List.length > 2000) {
      Swal.fire({
        icon: "info",
        title: "CANNOT CREATE MORE THEN 2000 PRODUCT LOT",
        showConfirmButton: false,
        timer: 1500,
      });
      return false;
    }

    let ltque =
      " SELECT LotID, CAST(LotNo as nvarchar)+'-'+LotName as LotName FROM  LotMaster  ";
    ltque +=
      " OUTER APPLY ( select distinct CategoryId from LotDetails inner join ProductMaster on  ";
    ltque +=
      " 	ProductMaster.ProductID = LotDetails.ProductID where LotID = LotMaster.LotID ) C (blckid) ";
    ltque +=
      " WHERE OrgID = " +
      this.state.orgid +
      " and (DATEDIFF(week,logDate, getDate())<10) and c.blckid =  " +
      this.state.BlockID;
    let lt = await getDataTable(ltque);
    let inputoption = {};
    lt.forEach((ele) => {
      inputoption[ele["LotID"]] = ele["LotName"];
    });
    let ltname = "";
    let ltid = 0;
    let sel = 0;
    if (lt.length <= 0) {
      await Swal.fire({
        title: "ENTER LOT NAME",
        input: "text",
        allowOutsideClick: false,
      }).then(function (result) {
        if (result.isConfirmed) {
          ltname = result.value;
        }
      });
    } else {
      let mrgcnf = await Swal.fire({
        title: "Do you want to merge the data into existing lot?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        allowOutsideClick: false,
      });
      if (mrgcnf.isConfirmed) {
        sel = 1;
        await Swal.fire({
          title: "SELECT LOT NAME",
          input: "select",
          inputOptions: inputoption,
          allowOutsideClick: false,
        }).then(function (result) {
          if (result.isConfirmed) {
            ltid = result.value;
          }
        });
      } else {
        await Swal.fire({
          title: "ENTER LOT NAME",
          input: "text",
          allowOutsideClick: false,
        }).then(function (result) {
          if (result.isConfirmed) {
            ltname = result.value;
          }
        });
      }
    }

    if (sel == 0 && ltname == "") {
      Swal.fire({
        icon: "error",
        title: "Please Enter Lot Name",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }

    let q =
      "insert into LotMaster(LotName,orgID,loginID,LogDate) output INSERTED.LotID values('" +
      ltname +
      "'," +
      this.state.orgid +
      "," +
      this.state.loginId +
      ",getdate()" +
      "  )";
    let sv = await getDataTable(q);
    q =
      "UPDATE LotMaster SET LotNo = (SELECT COUNT(LotID) FROM LotMaster  WHERE (OrgID = " +
      this.state.orgid +
      ") ) WHERE (OrgID = " +
      this.state.orgid +
      " and LotID = " +
      sv[0]["LotID"] +
      ") ";
    await getDataTable(q);
    let sb = this.state.List;
    sb.map((i) => (i.LotID = sv[0]["LotID"]));
    console.log(this.state);

    let dtJSON = JSON.stringify(sb);
    let que = " EXEC FrmLotMaster_SaveLotDetails @dtJSON = '" + dtJSON + "' ";
    await getDataTable(que);
    // for (let i = 0; i < this.state.selectedrows.length; i++) {
    //     let que = "insert into LotDetails(LotID,ProductID) values(" + sv[0]["LotID"] + ", " + this.state.selectedrows[i]["#pid"] + ")";
    //     sb = await getDataTable(que);
    // }

    this.setState({ productList: [], List: [] });

    if (sv.length > 0) {
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Lot create successfully ",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Lot creation unsuccessful",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }

  async Mark() {
    if (
      this.state.status === "Not Live" ||
      this.state.status === "Submit Not Live"
    ) {
      let MandatoryArray = [{ Portal: this.state.PortalID }];

      let check = MandatoryFormFields(MandatoryArray);

      if (check === false) {
        this.setState({ loading: false });
        return false;
      }
    }

    let pid = "";
    for (let i = 0; i < this.state.List.length; i++) {
      pid = pid + this.state.List[i]["#pid"] + ",";
    }
    pid = pid.substring(0, pid.length - 1);

    this.setState({ loading: true });
    let que =
      "EXEC FrmLotMasterArchiving @pid = '" +
      pid +
      "', @status = '" +
      this.state.status +
      "', @logid = " +
      this.state.loginId +
      ", @portalid = " +
      this.state.PortalID;
    let rs = await getDataTable(que);
    this.setState({
      loading: false,
      PortalID: 0,
    });

    if (rs.length > 0) {
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Marking successful",
        showConfirmButton: false,
        timer: 1500,
      });
      this.setState({
        productList: [],
        List: [],
      });
    } else {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Marking unsuccessful",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }

  async LotAsNoIssue() {
    this.setState({ loading: true });
    let MandatoryArray = [{ LOT: this.state.LotId }];
    let check = MandatoryFormFields(MandatoryArray);
    if (check === false) {
      this.setState({ loading: false });
      return false;
    }
    let que =
      " Update LotMaster SET NoIssue = 1 where LotID = " + this.state.LotId;
    await getDataTable(que);
    Swal.fire({
      icon: "success",
      title: "MARKED SUCCESSFULLY",
      showConfirmButton: false,
      timer: 1500,
    });
    this.setState({ loading: false });
  }

  myFileChangeHandler = (event) => {
    const file = event.target.files[0];
    console.log("File type:", Object.prototype.toString.call(file));
    this.setState({ files: file, upload: 1 });
  };

  async UploadFile() {
    this.setState({ loading: true });
    let MandatoryArray = [
      { BlockId: this.state.BlockID },
      { File: this.state.files },
    ];
    let check = MandatoryFormFields(MandatoryArray);
    if (check === false) {
      this.setState({ loading: false });
      return false;
    }
    let rs = await exceltodb(this.state.files, dataBase, this.state.loginId);
    let que =
      "select ProductID as [#pid],ProductMaster.ProductName as [ProductName],Parent as [article] from " +
      rs;
    que +=
      " p inner join ProductMaster on p.[SKU CODE] = ProductMaster.ProductName ";
    que +=
      " inner join ParentMaster on ProductMaster.ParentID = ParentMaster.ParentID ";
    que +=
      " where ProductMaster.OrgId =" +
      this.state.orgid +
      " and CategoryId =" +
      this.state.BlockID;
    let dt = await getDataTable(que);
    if (dt.length > 2000) {
      Swal.fire({
        icon: "error",
        title: "SKU List can not be more then 2000",
        showConfirmButton: false,
        timer: 1500,
      });
      return false;
    }
    this.setState({ productList: dt, List: dt, loading: false });
    Swal.fire({
      icon: "success",
      title: dt.length + " SKU UPLOADED",
      showConfirmButton: false,
      timer: 3000,
    });
  }

  async DownloadSKUTemplate() {
    let arr = [
      {
        "SKU CODE": "",
      },
    ];
    await json2xlsx(
      arr,
      "SKU_TEMPLATE.xlsx" + "_" + currentDate + "_" + "1.xlsx"
    );
  }

  render() {
    return (
      <div className="content-wrapper">
        <div
          className="loader"
          style={{ display: this.state.loading ? "block" : "none" }}
        >
          <div className="loader-item">
            <Spin />
          </div>
        </div>
        <section className="content">
          <div
            id="ctl00_CPHMaincontent_divclient"
            className="box box-success"
            style={{ marginTop: "-13px" }}
          >
            <div className="box-header with-border">
              <h3 className="box-title">
                Lot Master
                <sup>
                  (<span className="vcode">*</span> Fields are mandatory)
                </sup>
                <span
                  id="ctl00_CPHMaincontent_DivOrdNo"
                  style={{ display: "inline" }}
                ></span>
              </h3>
              {(["10", "6", "1", "10019"].includes(this.state.loginId) ||
                this.state.loginId === "1") && (
                <div className="pull-right" style={{ marginRight: "2px" }}>
                  <label>&nbsp;</label>
                  <button
                    className="btn btn-danger"
                    onClick={this.LotAsNoIssue.bind(this)}
                  >
                    MARK NO-ISSUE LOT
                  </button>
                </div>
              )}
              <div className="pull-right" style={{ marginRight: "2px" }}>
                <label>&nbsp;</label>
                <button
                  className="btn btn-primary"
                  onClick={this.DownloadSKUTemplate.bind(this)}
                >
                  SKU TEMPLATE
                </button>
              </div>
            </div>
            <div
              className="box-body"
              id="LotMaster"
              style={{ display: "block" }}
            >
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-6 margintop">
                  <div className="row">
                    <div>
                      <div className="col-xs-6 col-sm-6 col-md-3 margintop">
                        <label>Select Block</label>
                        <span className="vcode">*</span>
                        <span className="vcode">*</span>
                        <AutoCompleteCascad
                          id="id"
                          frmNm="FRMALLDATA"
                          quryNm="FILLORGBLOCKS"
                          db="IMAGEDB"
                          filter1="orgid"
                          filterId1={this.state.orgid}
                          filter2=""
                          filterId2=""
                          placeholder="Please Select Block"
                          onAfterSelect={(e) =>
                            this.onAfterSelect(e, "BlockID", "BlockName")
                          }
                        ></AutoCompleteCascad>
                      </div>
                      <div className="col-xs-6 col-sm-6 col-md-3">
                        <label>Search Type</label>
                        <span className="vcode">*</span>
                        <div className="form-group">
                          <select
                            name="merge"
                            onChange={this.myChangeHandler}
                            id=""
                            tabIndex={1}
                            className="form-control"
                          >
                            <option selected="true" disabled="disabled">
                              {" "}
                              No Selection{" "}
                            </option>
                            <option value="1">SKU CODE</option>
                            <option value="2">SelectLot</option>
                            <option value="3">Attribute</option>
                            <option value="4">Article Number</option>
                            <option value="5">Style Code</option>
                          </select>
                        </div>
                      </div>

                      <div
                        className="col-xs-6 col-sm-6 col-md-2 margintop"
                        style={{
                          display: this.state.merge === "1" ? "block" : "none",
                        }}
                      >
                        <label>Product Name</label>
                        <div className="form-group">
                          <input
                            autoComplete="off"
                            name="ProductName"
                            id="ProductName"
                            onChange={this.myChangeHandler}
                            placeholder="Enter Product Name"
                            tabIndex={1}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div
                        className="col-xs-6 col-sm-6 col-md-2 margintop"
                        style={{
                          display: this.state.merge === "2" ? "block" : "none",
                        }}
                      >
                        <label>Select Lot</label>
                        <AutoCompleteCascad
                          id="id"
                          name="SelectLot"
                          frmNm="FRMLOTMASTER"
                          quryNm="FILLLOT"
                          db="IMAGEDB"
                          filter1="OrgId"
                          filterId1={this.state.orgid}
                          filter2="CategoryId"
                          filterId2={this.state.BlockID}
                          placeholder="Select Lot"
                          onAfterSelect={(e) =>
                            this.onAfterSelect(e, "LotId", "LotName")
                          }
                          isValid={this.state.isValid}
                        ></AutoCompleteCascad>
                      </div>
                      <div
                        className="col-xs-6 col-sm-6 col-md-2 margintop"
                        style={{
                          display: this.state.merge == "3" ? "block" : "none",
                        }}
                      >
                        <label>Select Attributes</label>
                        <AutoCompleteCascad
                          id="AID"
                          frmNm="FRMLOTMASTER"
                          quryNm="FILLATTRIBUTES"
                          db="IMAGEDB"
                          filter1="CatID"
                          filterId1={this.state.BlockID}
                          filter2=""
                          filterId2=""
                          placeholder="Please Select Attribute"
                          onAfterSelect={(e) =>
                            this.onAfterSelect(e, "AID", "AttName")
                          }
                          isValid={this.state.isValid}
                        ></AutoCompleteCascad>
                      </div>
                      <div
                        className="col-xs-6 col-sm-6 col-md-2 margintop"
                        style={{
                          display: this.state.merge == "3" ? "block" : "none",
                        }}
                      >
                        <label>Select Value</label>
                        <AutoCompleteCascad
                          id="VID2"
                          frmNm="FRMVALUEMAPPING"
                          quryNm="FILLVALUES"
                          db="IMAGEDB"
                          filter1="BlockID"
                          filterId1={this.state.BlockID}
                          filter2="AtrributeID"
                          filterId2={this.state.AID}
                          placeholder="Please Select Value"
                          onAfterSelect={(e) =>
                            this.onAfterSelect(e, "VID2", "VName1")
                          }
                          isValid={this.state.isValid}
                        ></AutoCompleteCascad>
                      </div>
                      <div
                        className="col-xs-6 col-sm-6 col-md-2 margintop"
                        style={{
                          display: this.state.merge === "4" ? "block" : "none",
                        }}
                      >
                        <label>Article Number</label>
                        <div className="form-group">
                          <input
                            autoComplete="off"
                            name="ArticleNumber"
                            id="ArticleNumber"
                            onChange={this.myChangeHandler}
                            placeholder="Enter Article Number"
                            tabIndex={1}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div
                        className="col-xs-6 col-sm-6 col-md-2 margintop"
                        style={{
                          display: this.state.merge === "5" ? "block" : "none",
                        }}
                      >
                        <label>Style Code</label>
                        <div className="form-group">
                          <input
                            autoComplete="off"
                            name="StyleCode"
                            id="StyleCode"
                            onChange={this.myChangeHandler}
                            placeholder="Enter Style Code"
                            tabIndex={1}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-xs-3 col-sm-3 col-md-2 margintop">
                        <label>&nbsp;</label>
                        <div className="form-group">
                          <input
                            type="button"
                            onClick={this.GetProductData.bind(this)}
                            className="btn btn-block btn-primary"
                            text="search"
                            value="Search Now"
                          />
                        </div>
                      </div>

                      <div className="col-xs-6 col-sm-6 col-md-1 margintop">
                        <label>&nbsp;</label>
                        <div className="form-group">
                          <button
                            onClick={this.AddData.bind(this)}
                            className="btn  btn-block btn-primary"
                            text=""
                          >
                            {" "}
                            ADD{" "}
                          </button>
                        </div>
                      </div>
                      <div className="col-xs-6 col-sm-3 col-md-1 margintop">
                        <label>&nbsp;</label>
                        <div className="form-group">
                          <button
                            onClick={this.SaveData.bind(this)}
                            className="btn  btn-block btn-primary"
                            text=""
                          >
                            {" "}
                            Save{" "}
                          </button>
                        </div>
                      </div>
                      <div className="col-xs-6 col-sm-6 col-md-3 margintop">
                        <label>UPLOAD FILE</label>
                        <span className="vcode">*</span>
                        <div className="form-group">
                          <input
                            name="fldname"
                            type="file"
                            accept=".xls,.xlsx,.xlsm,.csv"
                            id="tp1"
                            onChange={this.myFileChangeHandler}
                            placeholder="Enter FieldName"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                        <label>&nbsp;</label>
                        <div className="form-group">
                          <button
                            onClick={this.UploadFile.bind(this)}
                            className="btn  btn-block btn-primary"
                            text=""
                          >
                            {" "}
                            Upload File{" "}
                          </button>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-12 margintop">
                        {this.state.productList.length > 0 &&
                          this.state.productList != undefined && (
                            <Table
                              style="float: left"
                              data={[...this.state.productList]}
                              id="fielddata"
                              selected="true"
                              SelectedFn={(e) => this.selectedDataFN(e)}
                            ></Table>
                          )}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="col-xs-12 col-sm-12 col-md-6 margintop"
                  style={{
                    display: this.state.List.length > 0 ? "block" : "none",
                  }}
                >
                  <div className="col-xs-6 col-sm-6 col-md-3 margintop">
                    <label>Select Status</label>
                    <span className="vcode">*</span>
                    <div className="form-group">
                      <select
                        name="status"
                        onChange={this.myChangeHandler.bind(this)}
                        className="form-control"
                        value={this.state.status}
                        defaultValue={this.state.status}
                      >
                        <option disabled value="">
                          Select Value
                        </option>
                        <option value="Not Live">Not Live</option>
                        <option value="Submit Not Live">Submit Not Live</option>
                        <option value="Archive">Archive</option>
                        <option value="Not Archive">Not Archive</option>
                      </select>
                    </div>
                  </div>
                  <div
                    className="col-xs-6 col-sm-6 col-md-3 margintop"
                    style={{
                      display:
                        this.state.status != "Archive" ||
                        this.state.status != "Not Archive"
                          ? "block"
                          : "none",
                    }}
                  >
                    <label>Select Portal</label>
                    <span className="vcode">*</span>
                    <AutoCompleteCascad
                      id="PortalID"
                      frmNm="FRMSTATUSMARKING"
                      quryNm="FILLPORTAL"
                      db="IMAGEDB"
                      filter2=""
                      filterId2=""
                      filter1="em.OrgID"
                      filterId1={this.state.orgid}
                      placeholder="Please Select Portal"
                      onAfterSelect={(e) =>
                        this.onAfterSelect(e, "PortalID", "Pname")
                      }
                      isValid={this.state.isValid}
                    ></AutoCompleteCascad>
                  </div>
                  <div className="col-xs-3 col-sm-3 col-md-2 margintop">
                    <label>&nbsp;</label>
                    <div className="form-group">
                      <input
                        type="button"
                        onClick={this.Mark.bind(this)}
                        className="btn btn-block btn-primary"
                        text="Mark"
                        value="Mark"
                      />
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-12 margintop">
                    {this.state.List.length > 0 && (
                      <Table
                        style="float: left"
                        id="ResultData"
                        data={[...this.state.List]}
                      ></Table>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
