import { Modal, Button } from 'antd';
import React, { Component } from 'react';
import '../../../ORDER/OrderMaster/style.css'
import { getDataTable, MandatoryFormFields,Configuration } from '../../../Configuration';
import AutoCompleteInput from '../AutoComplete/autoComplete.cascad';
import Swal, { swal } from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'


let portalList = [];
let portalid = [];
export default class ImageConfig extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            modal1Visible: false,
            modal2Visible: false,
            fname: "",
            FValue: '',
            portal: "",
            prtl: ''
        };
    }


    async componentDidMount() {
        let rs = await getDataTable("select orgid from users where userid = " + Configuration.USER_ID)
        await this.setState({
            loginId: Configuration.USER_ID,
            orgid: rs[0].orgid
        });
        await this.fillPortaldata();
    }

    async fillPortaldata() {
        let Query = "exec GetImageConfig"
        let dt = await getDataTable(Query)
        portalList = dt
        this.forceUpdate();
    }

    setModal1Visible(modal1Visible) {
        this.setState({ modal1Visible });
    }

    setModal2Visible(modal2Visible) {
        this.setState({ modal2Visible });
    }

    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({ [nam]: val });

        this.forceUpdate();
    }

    async SavePortal() {

        let MandatoryArray = [
            { "Profile": this.state.portal },
            { "Allow dropbox": this.state.allowdrp },
            { "Block": this.state.catid }
        ]
        let check = MandatoryFormFields(MandatoryArray);
        if (check == false) {
            return false;
        }

        let Que = "Select * from ImageProfile where ProfileName = '" + this.state.portal + "'"
        let rs = await getDataTable(Que)
        if (rs.length > 0) {
            Swal.fire({
                position: 'top-end',
                icon: 'info',
                title: 'Profile Already Exist',
                showConfirmButton: false,
                timer: 1500
            })
            return;
        }

        let Query = " INSERT INTO ImageProfile( ProfileName,AllowDropBox,CatId, LoginId, LogDate) output inserted.PID VALUES ('" + this.state.portal + "'," + this.state.allowdrp + "," + this.state.catid + "," + this.props.logid + ",cast(getdate() as date))"
        let data = await getDataTable(Query)
        if (data) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Successfully Saved',
                showConfirmButton: false,
                timer: 1500
            })
        }
        else {
            Swal.fire({
                position: 'top-end',
                icon: 'warning',
                title: 'Portal Not Saved',
                showConfirmButton: false,
                timer: 1500
            })
        }
        await this.fillPortaldata(this);
        this.forceUpdate();

    }

    async Saveprtl() {
        let data = [];
        let Query = "Delete from portals where portalName = '" + this.state.prtl + "'  INSERT INTO portals( portalname, LoginId, LogDate) VALUES        ('" + this.state.prtl + "',2333,cast(getdate() as date))"
        data = await getDataTable(Query)
        if (data) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Successfully Saved',
                showConfirmButton: false,
                timer: 1500
            })
        }
        else {
            Swal.fire({
                position: 'top-end',
                icon: 'warning',
                title: 'Portal Not Saved',
                showConfirmButton: false,
                timer: 1500
            })
        }
        document.getElementById('prtl').value = ''
        this.forceUpdate();

    }


    chkFacChange(evt) {
        if (evt.target.checked) {
            let facIds = portalid;
            facIds.push(evt.target.value);

            portalid = facIds


        }
        else {
            let facIds = portalid;
            facIds.splice(facIds.indexOf(evt.target.value), 1);


            portalid = facIds

        }

        this.forceUpdate();

    }

    onAfterSelect(selectedOptions, id, name) {
        if (selectedOptions.length > 0) {

            this.setState({
                [id]: selectedOptions[0].DisplayId,
                [name]: selectedOptions[0].DisplayName
            })

        }
        else {
            this.setState({
                [id]: '',
                [name]: ''
            })
        }
        this.forceUpdate();
    }

    async updateData() {
        if (!this.state.fname && !this.state.FValue) {
            Swal.fire({
                position: 'top-end',
                icon: 'warning',
                title: 'Select FieldName & field Value First',
                showConfirmButton: false,
                timer: 1500
            })
            return;
        }
        let arr = portalid
        if (arr.length > 0) {
            for (let index = 0; index < arr.length; index++) {
                let Query = "delete from ImageProfileConfig where PID = " + arr[index] + " and FieldName = '" + this.state.fname + "'  INSERT INTO ImageProfileConfig (PID, FieldName, FieldValue, LoginID, LogDate) VALUES (" + arr[index] + ",'" + this.state.fname + "','" + this.state.FValue + "'," + this.props.logid + ",cast(getdate() as date))"
                let response = await getDataTable(Query)
            }

            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Successfully Saved',
                showConfirmButton: false,
                timer: 1500
            })
            document.getElementById('FValue').value = ''
        }
        else {
            Swal.fire({
                position: 'top-end',
                icon: 'warning',
                title: 'Select Portal first',
                showConfirmButton: false,
                timer: 1500
            })
            return;
        }
        await this.fillPortaldata(this);
        this.forceUpdate();
    }

    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({ [nam]: val });

        this.forceUpdate();
    }


    render() {
        return (
            <div>
                <button type="button" onClick={() => this.setModal2Visible(true)} className="btn btn-info">Image Configuration</button>

                <Modal
                    title="Image Profile Configuration"
                    width={this.props.width}
                    visible={this.state.modal2Visible}
                    onOk={() => this.setModal2Visible(false)}
                    onCancel={() => this.setModal2Visible(false)}
                    destroyOnClose={true}
                    maskClosable={false}
                    footer={null}
                >
                    <div className='row'>
                        <div className='col-xs-6 col-sm-5 col-md-3 margintop'>
                            <label>Add New Profile</label><span className="vcode">*</span>
                            <input type='text' id='portal' name='portal' autoComplete='off' onChange={this.myChangeHandler} className="form-control" ></input>
                        </div>
                        <div className="col-xs-6 col-sm-5 col-md-3 margintop">
                            <label>Select Block</label>
                            <AutoCompleteInput
                                id="articleid"
                                frmNm="FRMALLDATA"
                                quryNm="FILLORGBLOCKS"
                                db="IMAGEDB"
                                filter1="orgid"
                                filterId1={this.state.orgid}
                                filter2=""
                                filterId2=""
                                placeholder="Please Select Block"
                                onAfterSelect={(e) => this.onAfterSelect(e, "catid", "catname")}
                                isValid={this.state.isValid}
                            ></AutoCompleteInput>
                        </div>
                        <div className='col-xs-6 col-sm-4 col-md-3 margintop' >
                            <label>Allow DropBox</label><span className="vcode">*</span>
                            <div className="form-group">
                                <select onChange={this.myChangeHandler} name='allowdrp' id="allowdrp" tabIndex={1} className="form-control">
                                    <option value="">--Select Field--</option>
                                    <option value="1">YES</option>
                                    <option value="0">NO</option>
                                </select>
                            </div>
                        </div>
                        <div className='col-md-1 col-xs-6 col-sm-2 margintop' >
                            <label>&nbsp;</label>
                            <div className='form-group'>
                                <button className='btn btn-primary' onClick={this.SavePortal.bind(this)}>Save Profile</button>
                            </div>
                        </div>

                        <div className='col-md-12 col-xs-12 col-sm-12 margintop'>
                            <div className="table table-hover datatable table-responsive hover" style={{ height: "200px" }}>
                                <table className="table table-border table-fixed datatable" id="tblFactoryList">
                                    <thead>
                                        <tr>
                                            <th>Action</th>
                                            <th>ProfileName</th>
                                            <th>Block Name</th>
                                            <th>AllowDropBox</th>
                                            <th>Height</th>
                                            <th>Width</th>
                                            <th>Bit Depth</th>
                                            <th>Resolution</th>
                                            <th>Format</th>
                                            <th>Min size</th>
                                            <th>Max size</th>
                                            <th>Min area</th>
                                            <th>Max area</th>
                                            <th>Min Quality</th>
                                            <th>Max Quality</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {portalList.map((fac, key) => {
                                            return (<tr key={key}>
                                                <td><input key={key} value={fac.PID} id={fac.PID} type="checkbox" onChange={this.chkFacChange.bind(this)}></input>&nbsp;{key + 1}</td>
                                                <td>{fac.ProfileName}</td>
                                                <td>{fac.BlockName}</td>
                                                <td>{fac.Allowdropbox}</td>
                                                <td>{fac.height}</td>
                                                <td>{fac.width}</td>
                                                <td>{fac["Bit Depth"]}</td>
                                                <td>{fac.Resolution}</td>
                                                <td>{fac.Format}</td>
                                                <td>{fac.Min}</td>
                                                <td>{fac.maxsize}</td>
                                                <td>{fac.minarea}</td>
                                                <td>{fac.maxarea}</td>
                                                <td>{fac['Min Quality']}</td>
                                                <td>{fac['Max Quality']}</td>
                                            </tr>)

                                        })}
                                    </tbody>

                                </table>

                            </div>

                        </div>
                        <div className='col-xs-6 col-sm-4 col-md-3 margintop' >
                            <label>Select Field</label><span className="vcode">*</span>
                            <div className="form-group">
                                <select onChange={this.myChangeHandler} name='fname' id="fname" tabIndex={1} className="form-control">
                                    <option value="">--Select Field--</option>
                                    <option value="height">Height</option>
                                    <option value="width">Width</option>
                                    <option value="Resolution">Resolution</option>
                                    <option value="Format">Format</option>
                                    <option value="Format">Color Representaion</option>
                                    <option value="Bit Depth">Bit Depth</option>
                                    <option value="minarea">Min. Area Covrage</option>
                                    <option value="maxarea">Max. Area Covrage</option>
                                    <option value="maxsize">Max. Out size (KB)</option>
                                    <option value="Min">Min. Out size (KB)</option>
                                    <option value="Min Quality">Min. Quality</option>
                                    <option value="Max Quality">Max. Quality</option>
                                </select>
                            </div>
                        </div>
                        <div className='col-xs-6 col-sm-4 col-md-3 margintop'>
                            <label>Enter Field Value</label><span className="vcode">*</span>
                            <div className="form-group">
                                <input type='Text' id='FValue' autoComplete='off' name='FValue' onChange={this.myChangeHandler} className="form-control" ></input>
                            </div>
                        </div>
                        <div className='col-xs-6 col-sm-3 col-md-3 margintop' >
                            <label>&nbsp;</label>
                            <div className='form-group'>
                                <button className='btn btn-primary' onClick={this.updateData.bind(this)} >Save Value</button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}