import React, { Component } from 'react';
import AutoCompleteInput from '../Actions/Controls/AutoComplete/autoComplete';
import AutoCompletemulti from '../Actions/Controls/AutoComplete/autoCompleteMultiple';
import AutoCompleteCascad from '../Actions/Controls/AutoComplete/autoComplete.cascad'
import { Configuration,getDataTable,MandatoryFormFields } from '../Configuration';
import AntD from '../Actions/Controls/Table/AntDTable'
import '../ORDER/OrderMaster/style.css'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
export default class frmUserRights extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginId: 0,
            userData:[],
            userid:''
        };
    }
    async componentDidMount() {
        await this.setState({
            loginId: Configuration.USER_ID,
          });
    }
    async getdata(val) {
         
        if (val==='') {
            this.setState({userData: []})
            return;
        }
        let Que = "EXEC ShowUser_Rights @userid = "+val
        let rs = await getDataTable(Que)
        if (rs.length > 0) {
            this.setState({userData: rs})
        }
        else{
            this.setState({userData: []})
            return;
        }
    }
    myChangeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }
   async onAfterSelect(selectedOptions, id, name) {
        if (selectedOptions.length > 0) {
            this.setState({
                [id]: selectedOptions[0].DisplayId,
                [name]: selectedOptions[0].DisplayName
            })
            if (name === "username") {
                await this.getdata(selectedOptions[0].DisplayId)
            }
        }
        else {
            this.setState({
                [id]: '',
                [name]: ''
            })
            if (name === "username") {
                await this.getdata('')
            }
        }
        this.forceUpdate();
        
    }
    async SaveUser() {

        let MandatoryArray = [
            { "User": this.state.userid },
            { "Menu": this.state.menuid.length },
            { "Type": this.state.rpttype }
        ]
        let check = MandatoryFormFields(MandatoryArray);
        if (check === false) {
            return false;
        }
       
        
        let st = []
        for (let i = 0; i < this.state.menuid.length; i++) {
            let Query = "delete from UserRights where userID = "+this.state.userid+" and menuID = "+this.state.menuid[i].DisplayId
        Query = Query + " INSERT INTO UserRights (userID, menuID, Rights, loginID, logdate) output inserted.UID"
        Query = Query + " VALUES ("+this.state.userid+","+this.state.menuid[i].DisplayId+",'"+this.state.rpttype+"',"+this.state.loginId+",CAST(getdate() as date))"
        const response = await getDataTable(Query)
        st.push(response)  
        }
        if (st.length > 0) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Successfully Saved',
                showConfirmButton: false,
                timer: 1500
            })
        }
        else {
            Swal.fire({
                position: 'top-end',
                icon: 'warning',
                title: 'Not Saved',
                showConfirmButton: false,
                timer: 1500
            })
        }
        await this.getdata(this.state.userid);
    }
    
    render() {
        return (
            <div className="content-wrapper">
                <section className="content">
                    <div id="ctl00_CPHMaincontent_divclient" className="box box-success" style={{ marginTop: '-13px' }}>
                        <div className="box-header with-border">
                            <h3 className="box-title">User Rights
                                <span id="ctl00_CPHMaincontent_DivOrdNo" style={{ display: 'inline' }}>
                                </span>
                            </h3>
                        </div>
                        <div className="box-body" id="orderDetails" style={{ display: 'block' }}>
                            <div className="row">
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                    <label>Select Organization</label><span className="vcode">*</span>
                                    <AutoCompleteInput
                                        id="articleid"
                                        frmNm="FRMUSER"
                                        quryNm="FILLORGANIZATION"
                                        db="IMAGEDB"
                                        placeholder="Please Select Organization"
                                        onAfterSelect={(e) => this.onAfterSelect(e, "orgid", "orgname")}
                                        isValid={this.state.isValid}
                                    ></AutoCompleteInput>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                    <label>Select User</label><span className='vcode'>*</span>
                                    <AutoCompleteCascad
                                        id="userid"
                                        frmNm="FRMUSERRIGHT"
                                        quryNm="FILLUSER"
                                        db="IMAGEDB"
                                        filter1="orgid"
                                        filterId1={this.state.orgid}
                                        filter2=""
                                        filterId2=""
                                        placeholder="Please Select User"
                                        onAfterSelect={(e) => this.onAfterSelect(e, "userid", "username")}
                                    ></AutoCompleteCascad>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-4 margintop">
                                    <label>Select Menu</label><span className="vcode">*</span>
                                    <AutoCompletemulti
                                        id="menuid"
                                        frmNm="FRMUSERRIGHT"
                                        quryNm="FILLMENU"
                                        db="IMAGEDB"
                                        placeholder="Please Select Menu"
                                        onAfterSelect={(e) => this.onAutoCOmpletMultiPleSelect(e, "menuid")}
                                        isValid={this.state.isValid}
                                    ></AutoCompletemulti>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                            <label>Select Type</label><span className='vcode'>*</span>
                                            <div className='form-group'>
                                                <select name='rpttype' onChange={this.myChangeHandler.bind(this)} className='form-control'>
                                                    <option value="None">None</option>
                                                    <option value="Read Only">Read Only</option>
                                                    <option value="ALL">ALL</option>
                                                </select>
                                            </div>
                                        </div>
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                    <label>&nbsp;</label>
                                    <div className="form-group">
                                        <button onClick={this.SaveUser.bind(this)} className='btn btn-primary'>Save User</button>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12 margintop">
                                    {this.state.userData.length > 0 && this.state.userData !== undefined &&
                                        // <Table
                                        //     data={this.state.userData}
                                        //     deleteRow1="YES"
                                        //     DeleteRowFn={(e) => this.deleteRow(e)}
                                        //     height='63vh'
                                        //     id='fielddata'
                                        //     exportXL="true">
                                        // </Table>
                                        <AntD
                                        data={[...this.state.userData]}
                                        exportXL="true"
                                        Delete="true"
                                        DeleteFn={(e) => this.deleteRow(e)}
                                        //EditRowFn={(e) => this.EditRow(e)}
                                    ></AntD>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
    async deleteRow(e) {
        console.log(e);
        if (this.state.loginId  === "1" || this.state.loginId === "10") {
            let st = window.confirm("Are you sure want to delete ?")
            if (!st) {
                return false;
            }
            console.log(e);
            let Que = "delete FROM UserRights output deleted.userid WHERE UID = "+e["#UID"]
            let rs = await getDataTable(Que)
            if (rs.length > 0) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'info',
                    title: 'Successfully Deleted',
                    showConfirmButton: false,
                    timer: 1500
                })
                await this.getdata(rs[0].userid)
            }
            else{
                alert("Some Error Occurred")
            }
        }
        else{
            alert("You are not allowed to delete")
            return;
        }
    }
    async onAutoCOmpletMultiPleSelect(selectedOptions, name){ 
        if (selectedOptions.length > 0) {
            this.setState({ 
                [name]: selectedOptions 
            });  

        }
        else {
            this.setState({ 
                [name]: ""  
            })
        }
       
    }
}
