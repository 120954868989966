import React, { Component } from "react";
//import xlsExport from 'xlsexport'
import {
  Configuration,
  MandatoryFormFields,
  getDataTable,
  SaveTemplateFile,
} from "../Configuration";
import TFilter from "./MODAL/frmTemplateFilter";
import CLONETEMPLATE from "./MODAL/frmCloneTemplate";
import AMAZONPARENT from "./MODAL/frmAmazonParent";
import { Modal, Spin } from "antd";
import "../ORDER/OrderMaster/style.css";
import "antd/dist/antd.css";
import AutoCompleteInput from "../Actions/Controls/AutoComplete/autoComplete";
import AutoCompleteCascad from "../Actions/Controls/AutoComplete/autoComplete.cascad";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
let files = "";
export default class frmCreateTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginId: 0,
      visible: false,
      file: null,
      loading: false,
      aData: [],
      tptype: "0",
      exportTyp: "0",
      options: [],
      stsdup: 0,
      btType: "B2C",
      fldt: [],
      ExportTemId: 0,
    };
  }

  async componentDidMount() {
    let rs = await getDataTable(
      "select orgid from users where userid = " + Configuration.USER_ID
    );
    await this.setState({
      loginId: Configuration.USER_ID,
      orgid: rs[0].orgid,
    });
  }

  myChangeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onAfterSelect(selectedOptions, id, name, index = 0) {
    if (selectedOptions.length > 0) {
      this.setState({
        [id]: selectedOptions[0].DisplayId,
        [name]: selectedOptions[0].DisplayName,
      });
    } else {
      this.setState({
        [id]: "",
        [name]: "",
      });
    }
    if (id === "ValueID") {
      this.changeAlias(index, selectedOptions);
    }
  }

  async UploadFile() {
    this.setState({
      loading: true,
      aData: [],
    });
    let MandatoryArray = [
      { Files: files },
      { portal: this.state.portalid },
      { BLOCK: this.state.catid1 },
      { "sheet no": this.state.sindex },
      { "row no": this.state.rindex },
    ];
    let check = MandatoryFormFields(MandatoryArray);
    if (check === false) {
      this.setState({ loading: false });
      return false;
    }
    var formdata = new FormData();
    formdata.append("file", files);
    formdata.append("sindex", this.state.sindex);
    formdata.append("rindex", this.state.rindex);
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    let rs = await fetch(
      "https://api.blumangoes.ai:6143/GetSheetAttribute",
      requestOptions
    );
    let dt = await rs.json();
    // console.log(dt.data);
    await this.GetdataAliasing(dt.data);
  }

  async GetdataAliasing(dt) {
    for (let i = 0; i < dt.length; i++) {
      let Que =
        "select AID,AttributeName,mandatory from AttributeMaster inner join TemplateDetails on TemplateDetails.AttributeID = aid ";
      Que +=
        " where HeaderName = '" +
        dt[i].key +
        "' and templateID IN (select distinct id from dbo.getTemplateID(" +
        this.state.catid1 +
        "," +
        this.state.portalid +
        ")) ";
      Que += " order by tempDetailID desc";
      // Que += "select AID,AttributeName from AttributAlias inner join AttributeMaster on AttributeMaster.AID = AttributAlias.AttributeID where PortalID = " + this.state.portalid + " and aliasName = '" + dt[i].key + "' and dis = 0"
      let rs = await getDataTable(Que);
      if (rs.length > 0) {
        dt[i].AttributeID = rs[0].AID;
        dt[i].AttributeName = rs[0].AttributeName;
        dt[i].mandatory = rs[0].mandatory;
      } else {
        let Que0 =
          "SELECT AID, AttributeName FROM AttributeMaster inner join AttributeCategoryMap on AttributeCategoryMap.AttributeID = ";
        Que0 +=
          "AttributeMaster.AID where dis = 0 and AttributeName='" +
          dt[i].key +
          "' and CatID = " +
          this.state.catid1;
        let rs0 = await getDataTable(Que0);
        if (rs0.length > 0) {
          dt[i].AttributeID = rs0[0].AID;
          dt[i].AttributeName = rs0[0].AttributeName;
          dt[i].mandatory = 0;
        } else {
          dt[i].AttributeID = 0;
          dt[i].AttributeName = "";
          dt[i].mandatory = 0;
        }
      }
    }
    console.log(dt);
    this.setState({
      loading: false,
      aData: dt,
    });
  }

  async CreateAttribute(i) {
    let pData = this.state.aData[i];
    this.setState({ Aname: pData["key"] });
    Modal.info({
      title: "CREATE ATTRIBUTE",
      okText: "CLOSE",
      width: "700px",
      closable: true,
      footer: null,
      bodyStyle: { maxHeight: "300px" },
      style: { padding: "0px" },
      centered: true,
      maskClosable: false,
      content: (
        <div className="row">
          <div>
            <div className="col-xs-6 col-sm-6 col-md-4 margintop">
              <label>Add New Attribute</label>
              <span className="vcode">*</span>
              <div className="form-group">
                <input
                  name="Aname"
                  id="Aname"
                  value={pData["key"]}
                  defaultValue={pData["key"]}
                  onChange={this.myChangeHandler}
                  autocomplete="off"
                  placeholder="Enter FieldName"
                  tabIndex={1}
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-xs-6 col-sm-6 col-md-4 margintop">
              <label>Value Type</label>
              <span className="vcode">*</span>
              <div className="form-group">
                <select
                  name="val"
                  onChange={this.myChangeHandler}
                  id="val"
                  tabIndex={1}
                  className="form-control"
                >
                  <option selected="true" disabled="disabled">
                    Value Type
                  </option>
                  <option value="number">Numeric</option>
                  <option value="Alpha-numeric">Alpha Numeric</option>
                  <option value="date">Date</option>
                  <option value="option">Option</option>
                  <option value="yes/no">yes/no</option>
                  <option value="NULL">No Value</option>
                  <option value="url">URL</option>
                </select>
              </div>
            </div>
            <div className="col-xs-6 col-sm-6 col-md-2 margintop">
              <label>&nbsp;</label>
              <div className="form-group">
                <input
                  type="button"
                  value="Save Field"
                  onClick={this.SaveAttributeWithMap.bind(this)}
                  className="btn btn-block btn-primary"
                />
              </div>
            </div>
          </div>
        </div>
      ),
    });
  }

  async SaveAttributeWithMap() {
    this.setState({ loading: true });
    let MandatoryArray = [{ "Value Type": this.state.val }];
    let check = MandatoryFormFields(MandatoryArray);
    if (check === false) {
      this.setState({ loading: false });
      return false;
    }
    let Que =
      "Select * from AttributeMaster where AttributeName = '" +
      this.state.Aname +
      "' and dis = 0";
    let rs = await getDataTable(Que);
    if (rs.length > 0) {
      Swal.fire({
        position: "top-end",
        icon: "info",
        title: "Already Exist",
        showConfirmButton: false,
        timer: 1500,
      });
      this.setState({ loading: false });
      return false;
    }
    let Query =
      "INSERT INTO  AttributeMaster  (AttributeName, LoginID, Logdate,Dis,Validation) Output Inserted.AID VALUES ('" +
      this.state.Aname +
      "'," +
      this.state.loginId +
      ",cast(getdate() as date),0,'" +
      this.state.val +
      "')";
    let res = await getDataTable(Query);
    if (res[0].AID) {
      let Query1 =
        "INSERT INTO AttributeCategoryMap (CatID, AttributeID, loginID, logdate) VALUES (" +
        this.state.catid1 +
        "," +
        res[0].AID +
        "," +
        this.state.loginId +
        ",cast(getdate() as date))";
      await getDataTable(Query1);
      this.setState({ loading: false });
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Successfully Saved",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      this.setState({ loading: false });
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Some Issue Occured",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }

  // hide show div
  hideShowDiv(id, childId) {
    let div = document.getElementById(id);
    if (div !== null) {
      if (document.getElementById(id).style.display === "block") {
        document.getElementById(id).style.display = "none";
        let ele = document.getElementById(childId);
        ele.className = "fa fa-plus";
      } else {
        document.getElementById(id).style.display = "block";
        let ele = document.getElementById(childId);
        ele.className = "fa fa-minus";
      }
    }
  }

  myFileChangeHandler = (event) => {
    files = event.target.files[0];
  };

  render() {
    return (
      <div className="content-wrapper" style={{ maxHeight: "100vh" }}>
        <div
          className="loader"
          style={{ display: this.state.loading ? "block" : "none" }}
        >
          <div className="loader-item">
            <Spin />
          </div>
        </div>
        <section className="content">
          <div
            id="ctl00_CPHMaincontent_div"
            className="box box-success"
            style={{ marginTop: "-13px" }}
          >
            <div className="box-header with-border">
              <h3 className="box-title">
                Template Master
                <span
                  id="ctl00_CPHMaincontent_DivOrdNo"
                  style={{ display: "inline" }}
                ></span>
              </h3>

              <div className="pull-right">
                <CLONETEMPLATE width="800px" logid={this.state.loginId} />
              </div>
              {(this.state.loginId === "10" ||
                this.state.loginId === "1" ||
                this.state.loginId === "6") && (
                <div className="pull-right" style={{ marginRight: "2px" }}>
                  <AMAZONPARENT width="800px" logid={this.state.loginId} />
                </div>
              )}
            </div>
            <div
              className="box-body"
              id="orderDetails"
              style={{ display: "block" }}
            >
              <div className="row">
                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                  <label>Select Portal</label>
                  <AutoCompleteInput
                    id="portalid"
                    frmNm="FRMATTRIBUTEALIASE"
                    quryNm="FILLPORTAL"
                    db="IMAGEDB"
                    placeholder="Please Select Portal"
                    onAfterSelect={(e) =>
                      this.onAfterSelect(e, "portalid", "portalname")
                    }
                    isValid={this.state.isValid}
                  ></AutoCompleteInput>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                  <label>Select Block</label>
                  <AutoCompleteCascad
                    id="catid"
                    frmNm="FRMALLDATA"
                    quryNm="FILLORGBLOCKS"
                    db="IMAGEDB"
                    filter1="orgid"
                    filterId1={this.state.orgid}
                    filter2=""
                    filterId2=""
                    placeholder="Please Select Block"
                    onAfterSelect={(e) =>
                      this.onAfterSelect(e, "catid1", "catname1")
                    }
                    isValid={this.state.isValid}
                  ></AutoCompleteCascad>
                </div>
                <div className="col-xs-4 col-sm-4 col-md-2 margintop">
                  <label>Sheet Name</label>
                  <span className="vcode">*</span>
                  <div className="form-group">
                    <input
                      type="Text"
                      id="sindex"
                      value={this.state.sindex}
                      defaultValue={this.state.sindex}
                      autoComplete="off"
                      name="sindex"
                      onChange={this.myChangeHandler}
                      className="form-control"
                    ></input>
                  </div>
                </div>
                <div className="col-xs-4 col-sm-4 col-md-1 margintop">
                  <label>Row no.</label>
                  <span className="vcode">*</span>
                  <div className="form-group">
                    <select
                      className="form-control"
                      onChange={this.myChangeHandler}
                      name="rindex"
                    >
                      <option value="">Select Value</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                    </select>
                  </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                  <label>Upload File</label>
                  <span className="vcode">*</span>
                  <div className="form-group">
                    <input
                      name="fldname"
                      type="file"
                      accept=".xls,.xlsx,.xlsm"
                      id="tp1"
                      onChange={this.myFileChangeHandler}
                      placeholder="Enter FieldName"
                      tabIndex={1}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                  <label>&nbsp;</label>
                  <span className="vcode">
                    <input
                      type="checkbox"
                      onClick={this.alwduplicate.bind(this)}
                      checked={this.state.stsdup === 1}
                      defaultChecked={this.state.stsdup === 1}
                      name="chkduplicate"
                    ></input>
                    <label>Allow Duplicates</label>
                  </span>
                  <div className="form-group">
                    <input
                      type="button"
                      value="Upload File"
                      onClick={this.UploadFile.bind(this)}
                      className="btn btn-block btn-primary"
                    />
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 margintop">
                  {
                    this.state.aData.length > 0 && this.storeImageTable()
                    // <AntD
                    //     data={this.state.productdetailsdata}
                    //     exportXL="true"
                    // ></AntD>
                  }
                </div>
                <div
                  style={{
                    display: this.state.aData.length > 0 ? "Block" : "none",
                  }}
                >
                  <div className="col-xs-6 col-sm-4 col-md-2 margintop">
                    <label>Template Name</label>
                    <span className="vcode">*</span>
                    <div className="form-group">
                      <input
                        type="Text"
                        id="tempName"
                        value={this.state.tempName}
                        defaultValue={this.state.tempName}
                        autoComplete="off"
                        name="tempName"
                        onChange={this.myChangeHandler}
                        className="form-control"
                      ></input>
                    </div>
                  </div>
                  {(this.state.loginId === "10" ||
                    this.state.loginId === "1" ||
                    this.state.loginId === "6" ||
                    this.state.orgid === 1 ||
                    this.state.loginId === "20057") && (
                    <div>
                      <div className="col-xs-6 col-sm-6 col-md-1 margintop">
                        <label>Select Type</label>
                        <span className="vcode">*</span>
                        <div className="form-group">
                          <select
                            name="tptype"
                            onChange={this.myChangeHandler}
                            className="form-control"
                          >
                            <option value="0">Normal</option>
                            <option value="1">Generic</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-xs-6 col-sm-6 col-md-1 margintop">
                        <label>B2B/B2C</label>
                        <span className="vcode">*</span>
                        <div className="form-group">
                          <select
                            name="btType"
                            onChange={this.myChangeHandler}
                            className="form-control"
                          >
                            <option value="B2C">B2C</option>
                            <option value="B2B">B2B</option>
                          </select>
                        </div>
                      </div>
                      {this.state.portalid === 33 && (
                        <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                          <label>Export Type</label>
                          <span className="vcode">*</span>
                          <div className="form-group">
                            <select
                              name="exportTyp"
                              onChange={this.myChangeHandler}
                              className="form-control"
                            >
                              <option value="0">Base Template</option>
                              <option value="1">Other Template</option>
                            </select>
                          </div>
                        </div>
                      )}
                      <div
                        className="col-xs-6 col-sm-6 col-md-2 margintop"
                        style={{
                          display:
                            this.state.exportTyp === "1" ? "block" : "none",
                        }}
                      >
                        <label>Selecr Target Template</label>
                        <AutoCompleteInput
                          id="id"
                          name="SelectLot"
                          frmNm="FRMCREATETEMPLATE"
                          quryNm="FILLBASETEMPLATE"
                          db="IMAGEDB"
                          placeholder="Selecr Target Template"
                          onAfterSelect={(e) =>
                            this.onAfterSelect(
                              e,
                              "ExportTemId",
                              "ExportTemName"
                            )
                          }
                          isValid={this.state.isValid}
                        ></AutoCompleteInput>
                      </div>
                    </div>
                  )}
                  <div className="col-xs-2 col-sm-2 col-md-1 margintop">
                    <label>Cell Address</label>
                    <div className="form-group">
                      <input
                        type="Text"
                        id="cellAddress"
                        value={this.state.cellAddress}
                        defaultValue={this.state.cellAddress}
                        autoComplete="off"
                        name="cellAddress"
                        onChange={this.myChangeHandler}
                        className="form-control"
                      ></input>
                    </div>
                  </div>
                  <div className="col-xs-6 col-sm-4 col-md-4 margintop">
                    <label>Discription</label>
                    <div className="form-group">
                      <input
                        type="Text"
                        id="tempDiscrption"
                        value={this.state.tempDiscrption}
                        defaultValue={this.state.tempDiscrption}
                        autoComplete="off"
                        name="tempDiscrption"
                        onChange={this.myChangeHandler}
                        className="form-control"
                      ></input>
                    </div>
                  </div>
                  {["1", "6", "10", "10019"].includes(this.state.loginId) && (
                    <div className="col-xs-6 col-sm-3 col-md-1 margintop">
                      <label>&nbsp;</label>
                      <div className="form-group">
                        <TFilter
                          width="800px"
                          data={this.state.fldt}
                          logid={this.state.loginId}
                          catid={this.state.catid1}
                        />
                      </div>
                    </div>
                  )}
                  <div className="col-xs-6 col-sm-3 col-md-2 margintop">
                    <label>&nbsp;</label>
                    <div className="form-group">
                      <button
                        className="btn btn-primary"
                        onClick={this.SaveTamplate.bind(this)}
                      >
                        Save Template
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }

  async alwduplicate(e) {
    if (e.target.checked) {
      this.setState({ stsdup: 1 });
    } else {
      this.setState({ stsdup: 0 });
    }
  }

  storeImageTable() {
    return (
      <div>
        <div className="pull-right" style={{ marginTop: "-30px" }}></div>
        <div className="table-responsive" style={{ maxHeight: "66vh" }}>
          <table id="ft" className="table table-hover">
            <thead style={{ position: "sticky" }}>
              <tr>
                <th>SR NO.</th>
                <th>MANDATORY</th>
                <th>PORTAL KEY</th>
                <th>MAPPED WITH</th>
                <th>CHANGE MAPPING</th>
              </tr>
            </thead>
            <tbody>
              {this.state.aData.map((value, index) => {
                return (
                  <tr key={index}>
                    <td>
                      {index + 1}&nbsp;&nbsp;
                      <i
                        className="fa fa-trash vcode"
                        onClick={(e) => this.deleteRow(index)}
                      ></i>
                      &nbsp;&nbsp;
                      <i
                        className="fa fa-save"
                        style={{ color: "blue" }}
                        onClick={(e) => this.CreateAttribute(index)}
                      ></i>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        id={index}
                        defaultChecked={value.mandatory === 1}
                        onClick={this.chnagemandatory.bind(this, index)}
                        name={value.key}
                      ></input>
                    </td>
                    <td>{value.key}</td>
                    <td>{value.AttributeName}</td>
                    <td>
                      <AutoCompleteCascad
                        id="ValueID"
                        frmNm="FRMCREATETEMPLATE"
                        quryNm="FILLATTRIBUTE"
                        db="IMAGEDB"
                        filter1="CatID"
                        filterId1={this.state.catid1}
                        filter2=""
                        filterId2=""
                        placeholder="Please Select Attribute"
                        onAfterSelect={(e) =>
                          this.onAfterSelect(e, "ValueID", "FValue", index)
                        }
                      ></AutoCompleteCascad>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  changeAlias(index, values) {
    let st = this.state.aData;
    if (values.length > 0) {
      for (let i = 0; i < st.length; i++) {
        const element = st[i];
        if (
          element.AttributeID === values[0].DisplayId &&
          this.state.stsdup === 0
        ) {
          Swal.fire({
            position: "top-end",
            icon: "info",
            title: "Attribute Already Assinged To Another Header",
            showConfirmButton: false,
            timer: 1500,
          });
          return false;
        }
      }
      st[index].AttributeName = values[0].DisplayName;
      st[index].AttributeID = values[0].DisplayId;
    } else {
      st[index].AttributeName = "";
      st[index].AttributeID = 0;
    }
    this.setState({ aData: st });
  }

  chnagemandatory(index, e) {
    let st = this.state.aData;
    if (e.target.checked) {
      st[index].mandatory = 1;
    } else {
      st[index].mandatory = 0;
    }
    this.setState({ aData: st });
  }

  deleteRow(index) {
    let st = this.state.aData;
    st.splice(index, 1);
    this.setState({ groupedImg: st });
  }

  async SaveTamplate() {
    this.setState({ loading: true });
    let MandatoryArray = [
      { "Template Name": this.state.tempName },
      { BLOCK: this.state.catid1 },
      { portal: this.state.portalid },
    ];
    let check = MandatoryFormFields(MandatoryArray);
    if (check === false) {
      this.setState({ loading: false });
      return false;
    }
    let tempdata = this.state.aData;
    for (let j = 0; j < this.state.aData.length; j++) {
      const element = this.state.aData[j];
      if (
        element.AttributeID === "0" ||
        element.AttributeID === 0 ||
        element.AttributeID === undefined
      ) {
        Swal.fire({
          icon: "error",
          title: "PLEASE COMPLETE MAPPING OR REMOVE UN-MAPPED ATTRIBUTE",
          showConfirmButton: false,
          timer: 1500,
        });
        this.setState({ loading: false });
        return false;
      }
    }

    let query =
      "Select * from TemplateMaster where orgid=" +
      this.state.orgid +
      " and CatID = " +
      this.state.catid1 +
      " and TemplateDis= 0 and TamplateName='" +
      this.state.tempName +
      "'";
    let result = await getDataTable(query);
    if (result.length > 0) {
      this.setState({ loading: false });
      let ss = await Swal.fire({
        title: "TEMPLATE ALREADY EXIST DO YOU WANT OVERWRITE ?",
        //showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "YES",
        cancelButtonText: "NO",
      });
      if (ss.isConfirmed) {
        let Que12 =
          "UPDATE TemplateMaster SET TemplateDis = 1 WHERE TamplateName = '" +
          this.state.tempName +
          "'";
        await getDataTable(Que12);
        this.setState({ loading: true });
      } else {
        this.setState({ loading: false });
        return false;
      }
    }

    let Que =
      "EXEC CreatePortalTemplate  @tmpName='" +
      this.state.tempName +
      "', @loginId=" +
      this.state.loginId +
      ", @catID=" +
      this.state.catid1 +
      ", @generic = " +
      this.state.tptype +
      ", @portalid = " +
      this.state.portalid +
      " ,@dis='" +
      this.state.tempDiscrption +
      "'";
    let rs = await getDataTable(Que);
    console.log(rs);
    if (rs[0].templateID) {
      let que01 =
        "UPDATE TemplateMaster SET SheetName = '" +
        this.state.sindex +
        "',TemplateType = '" +
        this.state.btType +
        "', CellAddress = '" +
        this.state.cellAddress +
        "' output inserted.TemplateID where TemplateID = " +
        rs[0].templateID;
      await getDataTable(que01);

      // let que0 = "Delete from TemplateDetails where templateID = " + rs[0].templateID
      // let dt = await getDataTable(que0)
      for (let i = 0; i < tempdata.length; i++) {
        tempdata[i]["Priority"] = i + 1;
        // let que1 = "INSERT INTO TemplateDetails (templateID, AttributeID, LoginID, Logdate,Priority,mandatory,HeaderName) VALUES (" + rs[0].templateID + "," + tempdata[i].AttributeID + "," + this.state.loginId + ",cast(getdate() as date)," + (i + 1) + "," + tempdata[i].mandatory + ",'" + tempdata[i].key + "')"
        // let dt1 = await getDataTable(que1)
      }

      let dtstr = JSON.stringify(tempdata);
      let que0 =
        "EXEC frmTemplateAlising_SaveUpdateTemplatedetails @dtJSON = '" +
        dtstr +
        "' , @templateID = " +
        rs[0].templateID +
        " , @loginID = " +
        this.state.loginId;
      await getDataTable(que0);

      let sts = await SaveTemplateFile(
        files,
        this.state.portalname,
        rs[0].templateID
      );
      console.log(sts);

      // this.state.fldt.forEach(async ele => {
      //     let query = " INSERT INTO TemplateFilterData (TemplateID, AttributeID, ValueID, Value, loginID,Condition) "
      //     query += " VALUES        (" + rs[0].templateID + "," + ele['#AID'] + "," + ele['#AVID'] + ",'" + ele['AVALUE'] + "'," + this.state.loginId + "," + ele['Condition'] + ")"
      //     await getDataTable(query)
      // });

      let tempdata1 = this.state.fldt;
      let arr = ["10", "6", "1", "10019"];
      if (arr.includes(this.state.loginId)) {
        let filterSTR = JSON.stringify(tempdata1);
        let q =
          "EXEC SaveTemplateFilters @loginId = " +
          this.state.loginId +
          ", @dtJSON = '" +
          filterSTR +
          "' , @tid = " +
          rs[0].templateID;
        let r = await getDataTable(q);
        if (r[0]["Status"] == "Done") {
          await Swal.fire({
            icon: "success",
            title: "Filters Updated",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Tamplate Saved Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      this.setState({
        loading: false,
        aData: [],
        tempName: "",
        tempDiscrption: "",
        cellAddress: "",
        fldt: [],
      });
      const keys = this.state.aData.map((element) => element.key).join(",");
      debugger;

      // Conditionally set ExportTemId based on the exportTyp state
      if (this.state.exportTyp == "0") {
        this.setState({ ExportTemId: 0 });
      }

      let a =
        "EXEC SaveTargetTemplate @header = '" +
        keys +
        "', @templateid = " +
        this.state.ExportTemId +
        ", @loginID = " +
        this.state.loginId +
        ", @Newtemplateid = " +
        rs[0].templateID;
      let b = await getDataTable(a);
    } else {
      Swal.fire({
        position: "top-end",
        icon: "info",
        title: "Some Error Occured",
        showConfirmButton: false,
        timer: 1500,
      });
      this.setState({ loading: false });
    }
  }
}
