import React, { Component } from 'react';
import { createStore, applyMiddleware } from "redux";
import {Provider} from 'react-redux';
import AppReducer from './Reducers';
import Header from './Header';
import Menu from './Menu';
import Footer from './Footer';
import Content from './Content';
import Login from './Login';
import SideMenu from './SideMenu';
import Counter from './Components/Counter';
import UserdDetails from './Components/ORDER/OrderDisplay/UserdDetails';
import DashboardSearch from './Components/COMMON/DashBoardUtility/DashboardSearch';
import AppRoute from './app.route';
import './App.css';

const store = createStore(AppReducer);
//console.log(store.getState());
class App extends Component {


  render() {
    return (
      <div>
        <AppRoute/> 
      </div>
    );
  }
}

export default App;