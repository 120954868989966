 
import React, { useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getToken } from './Configuration';
 
import SideMenu from "./SideMenu";
import Header from "./Header";
import Menu from "./Menu";
// handle the private routes
function PrivateRoute({ component: Component, ...rest }) {

  const [activeNotification, setActiveNotification] = useState(false);

  return (
    <Route
      {...rest}
      render={(props) => getToken() ? <div><Menu></Menu><SideMenu></SideMenu><Component setActiveNotification={setActiveNotification} {...props} /><Header activeNotification={activeNotification} setActiveNotification={setActiveNotification}></Header></div>: <Redirect to={{ pathname: '/login', state: { from: props.location } }} />}
    />
  )
}
 
export default PrivateRoute;