import { Modal } from 'antd';
import React, { Component } from 'react';
import '../../ORDER/OrderMaster/style.css'
import Table from '../../Actions/Controls/Table/AntDTable'
import { Configuration, MandatoryFormFields, getDataTable, OnlyNumberKey } from '../../Configuration';
import AutoCompleteInput from '../../Actions/Controls/AutoComplete/autoComplete';
import AutoCompleteCascad from '../../Actions/Controls/AutoComplete/autoComplete.cascad';
import Swal, { swal } from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import _ from 'lodash';
const dataBase = "IMAGEDB";

export default class frmTemplate extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            modal1Visible: false,
            modal2Visible: false,
            adata: [],
            tdata: [],
            atList: [],
            tptype: '0'
        };
    }

    async componentDidMount() {
        let rs = await getDataTable("select orgid from users where userid = " + Configuration.USER_ID)
        await this.setState({
            loginId: Configuration.USER_ID,
            orgid: rs[0].orgid
        });
        await this.fillPortaldata();
    }
    async fillPortaldata() {
        this.setState({
            adata: [],
            tdata: [],
            tempName: '',
            atList: [],
            catid: '',
            catname: '',
            tempDiscrption: 'NULL'
        })
    }
    setModal1Visible(modal1Visible) {
        this.setState({ modal1Visible });
    }
    setModal2Visible(modal2Visible) {
        this.setState({ modal2Visible, adata: [] });
    }


    async gettemplate() {
        let dts = [];
        let Que = "GETTemplatesList @loginId =" + this.props.logid + " , @BlockID = " + this.state.catid
        let dt = await getDataTable(Que)
        dts = dt
        if (dts.length > 0) {
            this.setState({
                adata: [],
                tdata: dts
            })
        }
    }
    onAfterSelect(selectedOptions, id, name) {
        if (selectedOptions.length > 0) {
            this.setState({
                [id]: selectedOptions[0].DisplayId,
                [name]: selectedOptions[0].DisplayName,
            })
        }
        else {
            this.setState({
                [id]: '',
                [name]: '',
            })
        }
    }



    render() {
        return (
            <div>
                <button type="button" onClick={() => this.setModal2Visible(true)} className="btn btn-primary">PORTAL TEMPLATE</button>
                <Modal
                    title="Portal Template"
                    width={this.props.width}
                    visible={this.state.modal2Visible}
                    onOk={() => this.setModal2Visible(false)}
                    onCancel={() => this.setModal2Visible(false)}
                    destroyOnClose={true}
                    maskClosable={false}
                    footer={null}
                >
                    <div className='row'>
                        <div className="col-xs-6 col-sm-5 col-md-3 margintop">
                            <label>Select Block</label><span className='vcode'>*</span>
                            <AutoCompleteCascad
                                id="articleid"
                                frmNm="FRMALLDATA"
                                quryNm="FILLORGBLOCKS"
                                db="IMAGEDB"
                                filter1="orgid"
                                filterId1={this.state.orgid}
                                filter2=""
                                filterId2=""
                                placeholder="Please Select Block"
                                onAfterSelect={(e) => this.onAfterSelect(e, "catid", "catname")}
                                isValid={this.state.isValid}
                            ></AutoCompleteCascad>
                        </div> 
                        <div className='col-md-2 col-xs-6 col-sm-2 margintop'  >
                            <label>&nbsp;</label>
                            <div className='form-group'>
                                <button className='btn btn-primary' value='template' onClick={this.gettemplate.bind(this)}>Show Template</button>
                            </div>
                        </div>
                        <div className='col-md-12 col-xs-12 col-sm-12 margintop'>
                            {this.state.tdata.length > 0 && this.state.tdata != undefined &&
                                <Table
                                    data={this.state.tdata}
                                    Delete="true"
                                    DeleteFn={(e) => this.deleteRow(e)}
                                    height='63vh'
                                    id='fielddata'>
                                </Table>}
                        </div>

                    </div>
                </Modal>
            </div>
        )
    }
    

    async deleteRow(e) {
        if (e["#generic"] === 1 && (this.props.logid != "1" && this.props.logid != "10" && this.props.logid != "6")) {
            Swal.fire({
                position: 'top-end',
                icon: 'info',
                title: 'Cannot delete Generic Template',
                showConfirmButton: false,
                timer: 1000
            })
            return;
        }
        var st = window.confirm("Are you Sure to Delete That ?")
        if (!st) {
            return false;
        }
        let dts = [];
        let Que = " Update TemplateMaster set TemplateDis = 1 output inserted.TemplateID where TemplateID =" + e["#TemplateID"]
        let dt = await getDataTable(Que)
        dts = dt
        if (dts) {
            Swal.fire({
                position: 'top-end',
                icon: 'info',
                title: 'Deleted Successfully',
                showConfirmButton: false,
                timer: 1500
            })
            await this.gettemplate()
            return;
        }
        else {
            alert(dt)
        }
    }
    


 
}