import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
// import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { APIService } from '../../../Config/action/apiAction';
import { connect } from 'react-redux';
import LoaderGif from '../../assets/loader.gif';
import { TokenService } from '../../../Config/action/tokenAction';
import PageTitle from '../../Common/pageTitle';
import PaytmChecksum from './paytmChecksum'
//const PaytmChecksum = require('./paytmChecksum');
const https = require('https');
// import BluMangoes from '../../../assets/images/bluMangoes.png';

class CheckoutDetail extends Component {

    constructor(props){
        super(props);
        this.state = {
            loader: true,
            paymentDetails: JSON.parse(sessionStorage.getItem('transactionDetails')) || '',
            paymentObject: {}
        }
    }

    printInvoice = () => {
        window.open(window.location.origin+'/#/print-invoice', "_blank", "width=800,height=500");
    }

    componentDidMount(){
        let orderId = 'Order_'+new Date().getTime();
        
        // Production Credentials
        let mid = "TriCon43051370876787";
        let mkey = "c2khS694eFo8IHoi";

        // Sandbox Credentials
        //  let mid = "lXCTyh30644485641900";
        //  let mkey = "TGeFaPrZ_3@NOk49";
        var paytmParams = {};

        this.props.dispatch(TokenService("getToken", "auth_user")).then((token) => {
            paytmParams.body = {
                "requestType"   : "Payment",
                "mid"           : mid,
            //     "websiteName"   : "WEBSTAGING",
                "websiteName"   : "DEFAULT",
                "orderId"       : orderId,
                "callbackUrl"   : "https://merchant.com/callback",
                "txnAmount"     : {
                    "value"     : this.state.paymentDetails.amount,
                    "currency"  : "INR",
                },
                "userInfo"      : {
                    "custId"    : this.state.paymentDetails.id || token.ID,
                }
            };
    
            let that = this;
            PaytmChecksum.generateSignature(JSON.stringify(paytmParams.body), mkey).then(function(checksum){
                console.log(checksum);
                paytmParams.head = {
                    "signature"    : checksum
                };
    
                var post_data = JSON.stringify(paytmParams);
    
                var options = {
    
                    /* for Staging */
            //         hostname: 'securegw-stage.paytm.in',
                    
    
                    /* for Production */
                    hostname: 'securegw.paytm.in',
    
                    port: 443,
                    path: `/theia/api/v1/initiateTransaction?mid=${mid}&orderId=${orderId}`,
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Content-Length': post_data.length
                    }
                };
    
                var response = "";
                var post_req = https.request(options, function(post_res) {
                    post_res.on('data', function (chunk) {
                        console.log(chunk, "got")
                        response += chunk;
                    });
    
                    post_res.on('end', function(){
                        console.log('Response: ', response);
                        // res.json({data: JSON.parse(response), orderId: orderId, mid: mid, amount: amount});
                        that.setState({
                            ...that.state,
                            loader: false,
                            paymentObject: {
                                token: JSON.parse(response).body.txnToken, 
                                order: orderId, 
                                mid: mid,
                                amount: that.state.paymentDetails.amount || ''
                            }
                        })
                    });
                });
    
                post_req.write(post_data);
                post_req.end();
            });
        })
    }

    createPayment = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.dispatch(TokenService("getToken", "auth_user")).then((token) => {

            let that = this;
            var config = {
                "root":"",
                "style": {
                  "bodyBackgroundColor": "#fafafb",
                  "bodyColor": "",
                  "themeBackgroundColor": "#0FB8C9",
                  "themeColor": "#ffffff",
                  "headerBackgroundColor": "#284055",
                  "headerColor": "#ffffff",
                  "errorColor": "",
                  "successColor": "",
                  "card": {
                    "padding": "",
                    "backgroundColor": ""
                  }
                },
                "data": {
                  "orderId": this.state.paymentObject.order,
                  "token": this.state.paymentObject.token,
                  "tokenType": "TXN_TOKEN",
                  "amount": this.state.paymentObject.amount /* update amount */
                },
                "payMode": {
                  "labels": {},
                  "filter": {
                    "exclude": []
                  },
                  "order": [
                      "CC",
                      "DC",
                      "NB",
                      // "CARD",
                      // "LOGIN",
                      "UPI",
                      "PPBL",
                      "PPI",
                      "BALANCE"
                  ]
                },
                "website": "WEBSTAGING",
                "flow": "DEFAULT",
                "merchant": {
                  "mid": this.state.paymentObject.mid,
                  "name": "BluMangoes",
                  "logo": "https://scontent.famd13-1.fna.fbcdn.net/v/t1.6435-9/34203651_167307043966441_5573007060569686016_n.png?_nc_cat=105&ccb=1-3&_nc_sid=973b4a&_nc_ohc=xpaQQE4LbZ0AX_5cA8V&_nc_ht=scontent.famd13-1.fna&oh=9f8e56a1b6294ee70d66550fe775a02c&oe=60B94089",
                  "redirect": false
                },
                "handler": {
                  "transactionStatus":function transactionStatus(paymentStatus){
                    console.log("paymentStatus => ",paymentStatus);
                    let requestData = {
                        "BANKNAME": paymentStatus.BANKNAME,
                        "BANKTXNID": paymentStatus.BANKTXNID,
                        "CHECKSUMHASH": paymentStatus.CHECKSUMHASH,
                        "CURRENCY": paymentStatus.CURRENCY,
                        "GATEWAYNAME": paymentStatus.GATEWAYNAME,
                        "ORDERID": paymentStatus.ORDERID,
                        "PAYMENTMODE": paymentStatus.PAYMENTMODE,
                        "RESPCODE": paymentStatus.RESPCODE,
                        "RESPMSG": paymentStatus.RESPMSG,
                        "STATUS": paymentStatus.STATUS,
                        "TXNAMOUNT": paymentStatus.TXNAMOUNT,
                        "TXNDATE": paymentStatus.TXNDATE,
                        "TXNID": paymentStatus.TXNID,
                        "Payee_id": token.ID.toString(),
                        "Customer_id": that.state.paymentDetails.id || '',
                        "PLANID": that.state.paymentDetails["planId"] || ''
                    };
    
                    console.log(requestData);
                    if(requestData.Customer_id === ""){
                        let u = token;
                        u["ACTIVE PLAN"] = that.state.paymentDetails.category;
                        u["PLAN PRICE"] = that.state.paymentDetails.amount;
                        that.props.dispatch(TokenService("setToken", {key: "auth_user", value: u})).then((token) => {})
                        // localStorage.setItem("auth_user", JSON.stringify(u));
                    }
                    console.log("data");
                    that.props.dispatch(APIService('POST', 'SavePaymentDetails?query=SAVE_PURCHASE_DATA', requestData)).then((res) => {
                        console.log(res);
                        if(res.data.status === 1000){
                            localStorage.setItem("notify_user", true);
                            window.Paytm.CheckoutJS.close();
                            that.props.history.push('/invoice/'+paymentStatus.ORDERID);
                        }
                        else{
                            window.Paytm.CheckoutJS.close();
                            that.props.history.push('/invoice/'+paymentStatus.ORDERID);
                        }
                    }).catch((e) => {
                        console.log("Error");
                        window.Paytm.CheckoutJS.close();
                        that.props.history.push('/invoice/'+paymentStatus.ORDERID);              
                    });
                  },
                  "notifyMerchant":function notifyMerchant(eventName,data){
                    console.log(eventName, data);
                    console.log("notify merchant about the payment state");
                  }
                }
            };
          
            if (window.Paytm && window.Paytm.CheckoutJS) {
            // initialze configuration using init method
            window.Paytm.CheckoutJS.init(config).then(function onSuccess() {
                console.log('Before JS Checkout invoke');
                // after successfully update configuration invoke checkoutjs
                window.Paytm.CheckoutJS.invoke();
            }).catch(function onError(error) {
                console.log("Error => ", error);
            });
            }
        })
    }

    render() {
        return (
            <div className="content-wrapper">
                <PageTitle title={"Checkout"} />
                <section className="content">
                    <div className="container-fluid">
                        <main className="mt-3 pt-4" style={{position:'absolute', left:'50%', transform:'translate(-50%)'}}>
                            <div className="container wow fadeIn">

                            {/* <h2 className="my-5 h2 text-center">Checkout form</h2> */}

                            <div className="row">

                                <div className="col-md-6 mb-4" style={{backgroundColor:'white', padding:'25px'}}>

                                <div className="card">

                                    <form className="card-body">

                                        <h3>Billing Details</h3>
                                        <br />

                                        <span style={{fontFamily: '15px'}}>{this.state.paymentDetails.name || ''}</span><br />
                                        <span style={{fontFamily: '15px'}}>{this.state.paymentDetails.email || ''}</span><br />
                                        <span style={{fontFamily: '15px'}}>{this.state.paymentDetails.company || ''}</span>

                                        <br />
                                        <br />
                                        
                                        <span style={{fontFamily: '15px', marginTop:'20px', fontWeight:'bold'}}>Category</span><br />
                                        <span style={{fontWeight: '14px', marginTop:'8px'}}>{this.state.paymentDetails.category || ''}</span>

                                        <hr className="mb-4" />
                                        {
                                            this.state.loader ? (
                                                <div style={{textAlign: 'center'}}>
                                                    <img src={LoaderGif} style={{height: '42px'}} />
                                                </div>
                                            ) : (
                                                <button className="btn btn-primary btn-lg btn-block" type="submit" onClick={this.createPayment}>Continue to checkout</button>
                                                )
                                            }

                                    </form>

                                </div>

                                </div>

                                <div className="col-md-6 mb-4" style={{padding:'10px'}}>

                                <h4 className="d-flex justify-content-between align-items-center mb-3">
                                    <span className="text-muted" style={{marginRight:'10px'}}>Your cart</span>
                                    <span className="badge badge-secondary badge-pill">1</span>
                                </h4>

                                <ul className="list-group mb-3 z-depth-1">
                                    <li className="list-group-item d-flex justify-content-between lh-condensed">
                                        <div>
                                            <h6 className="my-0">{this.state.paymentDetails.category || ''}</h6>
                                            <small className="text-muted">Category</small>
                                        </div>
                                        <span className="text-muted">&#8377;{this.state.paymentDetails.amount || ''}</span>
                                    </li>
                                    {/* <li className="list-group-item d-flex justify-content-between lh-condensed">
                                    <div>
                                    <h6 className="my-0">Second product</h6>
                                    <small className="text-muted">Brief description</small>
                                    </div>
                                    <span className="text-muted">$8</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between lh-condensed">
                                    <div>
                                    <h6 className="my-0">Third item</h6>
                                    <small className="text-muted">Brief description</small>
                                    </div>
                                    <span className="text-muted">$5</span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between bg-light">
                                    <div className="text-success">
                                    <h6 className="my-0">Promo code</h6>
                                    <small>EXAMPLECODE</small>
                                    </div>
                                    <span className="text-success">-$5</span>
                                </li> */}
                                    <li className="list-group-item d-flex justify-content-between">
                                        <span style={{marginRight:'20px'}}>Total (INR)</span>
                                        <strong>&#8377;{this.state.paymentDetails.amount || ''}</strong>
                                    </li>
                                </ul>

                                </div>

                            </div>

                            </div>
                        </main>

                    </div>
                </section>
            </div>        
        )
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: () => {
            dispatch({
                type: 'loginUser'
            });
        },
        logoutUser: () => {
            dispatch({
                type: 'logoutUser'
            });
        },
        dispatch
    }
}

export default connect(null, mapDispatchToProps)(withRouter(CheckoutDetail));