import React, { Component } from "react";
import {
  Configuration,
  exceltodb,
  MandatoryFormFields,
  getDataTable,
  GetNewDrpSheet,
  json2xlsx,
} from "../Configuration";
import AutoCompletemulti from "../Actions/Controls/AutoComplete/autoCompleteMultipleCascad";
import AutoCompletemultiNew from "../Actions/Controls/AutoComplete/autoCompleteMultiple";
import { AttributeRules } from "../CommanFunc";
import { Modal, Spin } from "antd";
import "../ORDER/OrderMaster/style.css";
import "antd/dist/antd.css";
import Moment from "moment";
import AutoCompleteCascad from "../Actions/Controls/AutoComplete/autoComplete.cascad";
import Swal, { swal } from "sweetalert2/dist/sweetalert2.js";
const dataBase = "IMAGEDB";
let files = "";
var tb = "";
const currentDate = Moment().format("DD-MMM-YYYY hh:mm:ss");

export default class frmproductdetails extends Component {
  //Declaring initial State
  constructor(props) {
    super(props);
    this.state = {
      loginId: 0,
      orgid: 0,
      catid1: 0,
      visible: false,
      productdetailsdata: [],
      file: null,
      loading: false,
      portalList: [],
      GroupList: [],
      lotID: "",
      ImportSheetId: "",
      alwCustomSheetval: 0,
    };
  }

  //getting organization id from server on component load
  async componentDidMount() {
    let rs = await getDataTable(
      "select orgid from users where userid = " + Configuration.USER_ID
    );
    //updating state values for loginID and orgID from data from server
    this.setState({
      loginId: Configuration.USER_ID,
      orgid: rs[0].orgid,
    });
    // await this.getdata(this.state.loginId, '');
  }

  myChangeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  onAfterSelect(selectedOptions, id, name) {
    if (selectedOptions.length > 0) {
      this.setState({
        [id]: selectedOptions[0].DisplayId,
        [name]: selectedOptions[0].DisplayName,
      });
    } else {
      this.setState({
        [id]: "",
        [name]: "",
      });
    }
    this.forceUpdate();
  }

  async UploadFile() {
    let MandatoryArray = [{ Category: this.state.catid1 }];
    let check = MandatoryFormFields(MandatoryArray);
    if (check == false) {
      return false;
    }

    tb = "";
    if (files) {
      this.setState({ loading: true });
      tb = await exceltodb(files, dataBase, this.state.loginId);
      debugger;
      if (tb) {
        let queCheck =
          "EXEC frmProductDetails_checkSKULimit @tblName = '" +
          tb +
          "' , @loginID = " +
          this.state.loginId;
        let rschk = await getDataTable(queCheck);
        if (rschk[0]["RES"] != "OK") {
          Swal.fire({
            icon: "error",
            title: rschk[0]["RES"],
            showConfirmButton: false,
            timer: 2000,
          });
          this.setState({ loading: false });
          return;
        }
        debugger;
        let PopUpData =
          "EXEC frmProductDetailsPopUpForImportData @tblname =  '" +
          tb +
          "' , @loginID = " +
          this.state.loginId +
          ", @categoryid=" +
          this.state.catid1;
        let ShowPop = await getDataTable(PopUpData);
        if (ShowPop.length > 0) {
          this.setState({ loading: false });
          let result1 = await Swal.fire({
            title: `${ShowPop.length}  SKU ARE ALREADY EXITS DO YOU WANT TO UPDATE IF YES , THEN THE EARLIER DATA WILL BE OVERWRITE `,
            icon: "warning",
            showConfirmButton: true,
            showCancelButton: true,
            showDenyButton: true,
            confirmButtonText: "YES",
            cancelButtonText: "NO",
            denyButtonText: "Generate Excel",
          });

          if (result1.isConfirmed) {
            this.setState({ loading: true });
            let Que =
              "EXEC SaveProductImportDetailsforTest @loginId = " +
              this.state.loginId +
              ",@tblname = '" +
              tb +
              "' , @categoryid=" +
              this.state.catid1;
            let rs = await getDataTable(Que);

            if (Array.isArray(rs)) {
              if (rs.length > 0) {
                this.setState({
                  productdetailsdata: rs,
                  visible: true,
                  loading: false,
                });
                await this.SaveData(this);
              } else {
                Swal.fire({
                  icon: "error",
                  title: "File not Imported Properly",
                  showConfirmButton: false,
                  timer: 1500,
                });
                //alert("File not contain Proper Data")
                this.setState({ loading: false });
              }
            } else {
              Swal.fire({
                icon: "error",
                title: "File not Imported Properly",
                showConfirmButton: false,
                timer: 1500,
              });
              //alert("File not contain Proper Data")
              this.setState({ loading: false });
            }
          } else if (result1.isDenied) {
            await json2xlsx(ShowPop, "EXISTING SKU DATA");
            this.setState({ loading: false });
          } else {
            Swal.fire({
              title: "UPLOAD FAILED",
              icon: "warning",
            });
            this.setState({ loading: false });
          }
          document.getElementById("tp1").value = "";
        } else {
          let Que =
            "EXEC SaveProductImportDetailsforTest @loginId = " +
            this.state.loginId +
            ",@tblname = '" +
            tb +
            "' , @categoryid=" +
            this.state.catid1;
          let rs = await getDataTable(Que);
          debugger;
          if (Array.isArray(rs)) {
            if (rs.length > 0) {
              this.setState({
                productdetailsdata: rs,
                visible: true,
                loading: false,
              });
              await this.SaveData(this);
            } else {
              Swal.fire({
                icon: "error",
                title: "File not Imported Properly",
                showConfirmButton: false,
                timer: 1500,
              });
              //alert("File not contain Proper Data")
              this.setState({ loading: false });
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "File not Imported Properly",
              showConfirmButton: false,
              timer: 1500,
            });
            //alert("File not contain Proper Data")
            this.setState({ loading: false });
          }
          document.getElementById("tp1").value = "";
        }
      } else {
        tb = "";
        this.setState({ loading: false });
        return;
      }
    } else {
      Swal.fire({
        icon: "info",
        title: "File not contain Proper Data",
        showConfirmButton: false,
        timer: 1500,
      });
      this.setState({ loading: false });
      tb = "";
      return false;
    }
  }

  async SaveData() {
    if (!tb) {
      Swal.fire({
        icon: "info",
        title: "File not contain Proper Data",
        showConfirmButton: false,
        timer: 1500,
      });
      //alert("No Data To Save")
    }

    let ltque =
      " SELECT LotID, CAST(LotNo as nvarchar)+'-'+LotName as LotName FROM  LotMaster  ";
    ltque +=
      " OUTER APPLY ( select distinct CategoryId from LotDetails inner join ProductMaster on  ";
    ltque +=
      " 	ProductMaster.ProductID = LotDetails.ProductID where LotID = LotMaster.LotID ) C (blckid) ";
    ltque +=
      " WHERE OrgID = " +
      this.state.orgid +
      " and (DATEDIFF(week,logDate, getDate())<10) and c.blckid =  " +
      this.state.catid1;
    console.log(ltque);
    let lt = await getDataTable(ltque);
    let inputoption = {};
    lt.forEach((ele) => {
      inputoption[ele["LotID"]] = ele["LotName"];
    });
    let ltname = "";
    let ltid = 0;
    let sel = 0;
    if (lt.length <= 0) {
      await Swal.fire({
        title: "ENTER LOT NAME",
        input: "text",
        allowOutsideClick: false,
      }).then(function (result) {
        if (result.isConfirmed) {
          ltname = result.value;
        }
      });
    } else {
      let mrgcnf = await Swal.fire({
        title: "Do you want to merge the data into existing lot?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        allowOutsideClick: false,
      });
      if (mrgcnf.isConfirmed) {
        sel = 1;
        await Swal.fire({
          title: "SELECT LOT NAME",
          input: "select",
          inputOptions: inputoption,
          allowOutsideClick: false,
        }).then(function (result) {
          if (result.isConfirmed) {
            ltid = result.value;
          }
        });
      } else {
        await Swal.fire({
          title: "ENTER LOT NAME",
          input: "text",
          allowOutsideClick: false,
        }).then(function (result) {
          if (result.isConfirmed) {
            ltname = result.value;
          }
        });
      }
    }

    if (sel == 0 && ltname == "") {
      Swal.fire({
        icon: "error",
        title: "Please Enter Lot Name",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }

    /////////////////////////////////////////////

    let Queery =
      "EXEC Lotcreateandmerge @loginid = " +
      this.state.loginId +
      ",@tblname = '" +
      tb +
      "' , @LotName = '" +
      ltname +
      "' , @Lotid = " +
      ltid;
    this.setState({ loading: true });
    let rss = await getDataTable(Queery);
    let Que =
      "EXEC SaveDataInMasterTable @loginid = " +
      this.state.loginId +
      ",@tblname = '" +
      tb +
      "'";
    let rs = await getDataTable(Que);
    debugger;
    if (rs) {
      Swal.fire({
        icon: "success",
        title: "Data Saved SuccessFully",

        showConfirmButton: false,
        timer: 1500,
      });
      //alert("Data Saved SuccessFully")
      this.setState({ loading: false });
    } else {
      Swal.fire({
        icon: "error",
        title: "SOME ERROR OCCURED",
        showConfirmButton: false,
        timer: 1500,
      });
      //alert("Data Saved SuccessFully")
      this.setState({ loading: false });
    }
  }

  async onAutoCOmpletMultiPleSelect(selectedOptions, name) {
    if (selectedOptions.length > 0) {
      this.setState({
        [name]: selectedOptions,
      });
    } else {
      this.setState({
        [name]: [],
      });
    }
  }

  async ExportErrordata() {
    this.setState({ loading: true });
    let Que =
      "EXEC ExportErrorDataOFExcel @loginID = " +
      this.state.loginId +
      ",@tblNm = '" +
      tb +
      "'";
    let rs = await getDataTable(Que);
    if (rs.length > 0) {
      await GetNewDrpSheet(
        this.state.catid1,
        rs,
        "Error Sheet -" + currentDate + ".xlsx"
      );
      // let que1 = "Drop table " + tb
      // let res = await getDataTable(que1)
      // console.log(res);
      this.setState({ loading: false, productdetailsdata: [] });
    } else {
      Swal.fire({
        icon: "info",
        title: "No Data Found",
        showConfirmButton: false,
        timer: 1500,
      });
      this.setState({ loading: false });
      //alert('No Data Found')
    }
  }

  async ExportRelErrordata() {
    this.setState({ loading: true });
    let Que =
      "EXEC ProductDetails_addRelationShipError @BlockID = " +
      this.state.catid1 +
      ",@tblName = '" +
      tb +
      "'";
    let rs = await getDataTable(Que);
    if (rs.length > 0) {
      await GetNewDrpSheet(
        this.state.catid1,
        rs,
        "Error Rel Sheet -" + currentDate + ".xlsx"
      );
      // let que1 = "Drop table " + tb
      // let res = await getDataTable(que1)
      // console.log(res);
      this.setState({ loading: false, productdetailsdata: [] });
    } else {
      Swal.fire({
        icon: "info",
        title: "No Data Found",
        showConfirmButton: false,
        timer: 1500,
      });
      this.setState({ loading: false });
      //alert('No Data Found')
    }
  }

  // Get Data Of Product
  async getdata(logid, pname) {
    this.setState({ loading: true });
    let Que =
      "EXEC ShowProductList_OrgWise @loginid=" +
      logid +
      " , @productname='" +
      pname +
      "'";
    let rs = await getDataTable(Que);
    if (rs) {
      this.setState({
        productdata: rs,
        loading: false,
      });
    } else {
      this.setState({ loading: false });
      Swal.fire({
        icon: "info",
        title: "No Data Found",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
  }

  async getMappingSheet() {
    let MandatoryArray = [{ BLOCK: this.state.catid1 }];
    let check = MandatoryFormFields(MandatoryArray);
    if (check == false) {
      return false;
    }
    this.setState({
      loading: true,
    });
    let ltid = this.state.lotID == "" ? "0" : this.state.lotID;
    let que =
      "EXEC frmProductDetails_getSkuMappingSheet @orgid = " +
      this.state.orgid +
      " , @BlockID = " +
      this.state.catid1 +
      " , @lotName = '" +
      this.state.LotName +
      "' , @LotID = " +
      ltid;
    let rs = await getDataTable(que);
    await GetNewDrpSheet(
      this.state.catid1,
      rs,
      "SKU MAPPING Sheet -" + currentDate + ".xlsx"
    );
    this.setState({
      loading: false,
    });
  }

  async getCustomTemplate() {
    if (this.state.portalList.length + this.state.ImportSheetId.length <= 0) {
      await this.downloadCustomFormat(this);
      return;
    }

    let MandatoryArray = [
      { BLOCK: this.state.catid1 },
      // { "LOT": this.state.lotID }
    ];
    let check = MandatoryFormFields(MandatoryArray);
    if (check == false) {
      return false;
    }

    let brandCheckQuery =
      "SELECT COUNT(*) AS brandCount FROM BrandMaster WHERE OrgID = " +
      this.state.orgid;
    let brandCheckResult = await getDataTable(brandCheckQuery);

    if (brandCheckResult[0].brandCount === 0) {
      // Show an error message if the brand is not created
      debugger;
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Brand Not Created",
        text: "Please Create The Brand First From Brand Master",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }

    this.setState({
      loading: true,
    });

    let portalIds = this.state.portalList
      .map((ele) => ele["DisplayId"])
      .join(",");
    let importSheetIds = this.state.ImportSheetId.map(
      (ele) => ele["DisplayId"]
    ).join(",");

    let ltid = this.state.lotID === "" ? "0" : this.state.lotID;

    // Set @PortalIDList to '0' if no portal ID is selected
    let portalIDListParam = portalIds !== "" ? portalIds : "0";

    let que =
      "frmProductDetails_getCustomImportSheet @BlockID = " +
      this.state.catid1 +
      " , @PortalIDList ='" +
      portalIDListParam +
      "' , @GroupIDList = '" +
      importSheetIds +
      "' , @LotID = " +
      ltid +
      " , @lotName = '" +
      this.state.LotName +
      "' , @loginID = " +
      this.state.loginId;

    let rs = await getDataTable(que);

    debugger;

    await GetNewDrpSheet(
      this.state.catid1,
      rs,
      "Custom Sheet -" + currentDate + ".xlsx"
    );

    this.setState({
      loading: false,
    });
  }

  myFileChangeHandler = (event) => {
    files = event.target.files[0];
  };

  rulesForNamshi() {
    Modal.info({
      title: "RULES",
      okText: "CLOSE",
      width: "600px",
      closable: true,
      footer: null,
      bodyStyle: { maxHeight: "500px" },
      style: { padding: "0px" },
      centered: true,
      maskClosable: true,
      content: (
        <div>
          <ul>
            <li>Ean code must be fed into Sku Code.</li>
            <li>
              While the colour is changing, select the closest one from the
              dropdown.
            </li>
            <li>
              While feeding the category and gender, select the combination from
              the Namshi sheet.
            </li>
            <li>For Size Run- Refer to final adidas size chart sheet.</li>
            <li>
              For imaging - always select the "other" profile and create links
              only from the adidas GCC organisation.
            </li>
            <li>
              Image angles should be checked during the image process (which
              angle refers to which angle).
            </li>
            <li>
              If the product refers to unisex, then you can select Yes or No
              from the unisex dropdown and then fill in the gender.
            </li>
            <li>
              If a product is only for unisex, then the gender should have a
              "Male" feed.
            </li>
            <li>
              If the age group is "kids," then boys or girls could be selected.
            </li>
          </ul>
        </div>
      ),
    });
  }

  help() {
    Modal.info({
      title: "INSTRUCTION WHILE IMPORT DATA",
      okText: "CLOSE",
      width: "600px",
      closable: true,
      okButtonProps: { style: { display: "none" } },
      footer: null,
      bodyStyle: { maxHeight: "500px" },
      style: { padding: "0px" },
      centered: true,
      maskClosable: true,
      content: (
        <div>
          <ul>
            <li>Excel Sheet Name Must be "Sheet1"</li>
            <li>Format of Product Excel should be Same as Example</li>
            <li>
              If Excel Contain Any new Product that Not Exist Yet it will
              Created Directly
            </li>
          </ul>
        </div>
      ),
    });
  }

  async ImageHelp() {
    let rs = await getDataTable("EXEC frmProductDetails_ImageHelp");
    const keys = Object.keys(rs[0]);
    Modal.info({
      title: "INSTRUCTION FOR IMAGE ATTRIBUTE",
      okText: "CLOSE",
      width: "950px",
      closable: true,
      okButtonProps: { style: { display: "none" } },
      footer: null,
      bodyStyle: { maxHeight: "500px" },
      style: { padding: "0px" },
      centered: true,
      maskClosable: true,
      content: (
        <div style={{ maxHeight: "500px", overflowY: "scroll" }}>
          <table width="100%">
            <tr>
              {keys.map((value, index) => {
                return <th>{value}</th>;
              })}
            </tr>
            {rs.map((value, index) => {
              return (
                <tr>
                  {keys.map((value1, index1) => {
                    if (value1 === "Image") {
                      return (
                        <td>
                          <img
                            src={value[value1]}
                            height="100"
                            width="100"
                          ></img>
                        </td>
                      );
                    } else {
                      return <td>{value[value1]}</td>;
                    }
                  })}
                </tr>
              );
            })}
          </table>
        </div>
      ),
    });
  }

  async alwCustomSheet(e) {
    if (e.target.checked) {
      this.setState({ alwCustomSheetval: 1 });
    } else {
      this.setState({ alwCustomSheetval: 0 });
    }
    //await this.updateData()
  }

  render() {
    return (
      <div className="content-wrapper">
        <div
          className="loader"
          style={{ display: this.state.loading ? "block" : "none" }}
        >
          <div className="loader-item" style={{ marginLeft: "75vh" }}>
            <Spin tip="We are Processing your data! please do not refresh page..." />
          </div>
        </div>
        <section className="content">
          <div
            id="ctl00_CPHMaincontent_div"
            className="box box-success"
            style={{ marginTop: "-13px" }}
          >
            <div className="box-header with-border">
              <h3 className="box-title">
                IMPORT DATA MASTER
                <span
                  id="ctl00_CPHMaincontent_DivOrdNo"
                  style={{ display: "inline" }}
                ></span>
              </h3>
              <div className="pull-right">
                {(this.state.loginId === "1" || this.state.loginId === "6") && (
                  <a className="btn btn-primary" onClick={this.rulesForNamshi}>
                    Rules
                  </a>
                )}
              </div>
              <div className="pull-right" style={{ marginRight: "7px" }}>
                <a className="btn btn-primary" onClick={AttributeRules}>
                  Rules
                </a>
              </div>
              <div className="pull-right" style={{ marginRight: "7px" }}>
                <button
                  className="btn btn-primary"
                  onClick={this.help.bind(this)}
                >
                  Help
                </button>
              </div>
              <div className="pull-right" style={{ marginRight: "7px" }}>
                <button
                  className="btn btn-primary"
                  onClick={this.ImageHelp.bind(this)}
                >
                  Image Help
                </button>
              </div>
            </div>
            <div
              className="box-body"
              id="orderDetails"
              style={{ display: "block" }}
            >
              <div className="row">
                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                  <label>
                    Select Block
                    <span className="vcode">
                      *&nbsp;
                      <input
                        type="checkbox"
                        onClick={this.alwCustomSheet.bind(this)}
                        name="alwCustomSheetval"
                      ></input>
                      Custom Sheet
                    </span>
                  </label>
                  <AutoCompleteCascad
                    id="catid"
                    frmNm="FRMALLDATA"
                    quryNm="FILLORGBLOCKS"
                    db="IMAGEDB"
                    filter1="orgid"
                    filterId1={this.state.orgid}
                    filter2=""
                    filterId2=""
                    placeholder="Please Select Block"
                    onAfterSelect={(e) =>
                      this.onAfterSelect(e, "catid1", "catname1")
                    }
                    isValid={this.state.isValid}
                  ></AutoCompleteCascad>
                </div>
                {this.state.alwCustomSheetval === 1 && (
                  <div>
                    <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                      <label>Select Portal</label>
                      <AutoCompletemulti
                        id="articleid"
                        frmNm="FRMATTRIBUTEALIASE"
                        quryNm="FILLPORTAL"
                        db="IMAGEDB"
                        filter2=""
                        filterId2=""
                        filter1="orgid"
                        filterId1={this.state.orgid}
                        placeholder="Please Select Portal"
                        onAfterSelect={(e) =>
                          this.onAutoCOmpletMultiPleSelect(e, "portalList")
                        }
                        isValid={this.state.isValid}
                      ></AutoCompletemulti>
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                      <label>Select Import Sheet</label>
                      <AutoCompletemulti
                        id="catid"
                        frmNm="FRMPRODUCTDETAILS"
                        quryNm="FILLIMPORTTEMPLATE"
                        db="IMAGEDB"
                        filter2=""
                        filterId2=""
                        filter1="blockid"
                        filterId1={this.state.catid1}
                        placeholder="Please Select Import Sheet"
                        onAfterSelect={(e) =>
                          this.onAutoCOmpletMultiPleSelect(e, "ImportSheetId")
                        }
                        isValid={this.state.isValid}
                      ></AutoCompletemulti>
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                      <label>Select Lot</label>
                      <span className="vcode">*</span>
                      <AutoCompleteCascad
                        id="catid"
                        frmNm="FRMEXPORTDATA"
                        quryNm="FILLLOTALL"
                        db="IMAGEDB"
                        filter1="OrgId"
                        filterId1={this.state.orgid}
                        filter2="CategoryId"
                        filterId2={this.state.catid1}
                        placeholder="Please Select Lot"
                        onAfterSelect={(e) =>
                          this.onAfterSelect(e, "lotID", "LotName")
                        }
                        isValid={this.state.isValid}
                      ></AutoCompleteCascad>
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                      <label>&nbsp;</label>
                      <div className="form-group">
                        <input
                          type="button"
                          value="GET IMPORT SHEET"
                          onClick={this.getCustomTemplate.bind(this)}
                          className="btn btn-block btn-danger"
                        />
                      </div>
                    </div>
                  </div>
                )}
                {this.state.alwCustomSheetval !== 1 && (
                  <div>
                    <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                      <label>&nbsp;</label>
                      <div className="form-group">
                        <input
                          type="button"
                          value="GET IMPORT SHEET"
                          onClick={this.downloadImportFormat.bind(this)}
                          className="btn btn-block btn-danger"
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                  <label>&nbsp;</label>
                  <div className="form-group">
                    <input
                      type="button"
                      value="GET MAPPING SHEET"
                      onClick={this.getMappingSheet.bind(this)}
                      className="btn btn-block btn-danger"
                    />
                  </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                  <label>Upload File</label>
                  <span className="vcode">*</span>
                  <div className="form-group">
                    <input
                      name="fldname"
                      type="file"
                      accept=".xls,.xlsx"
                      id="tp1"
                      onChange={this.myFileChangeHandler}
                      placeholder="Enter FieldName"
                      tabIndex={1}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-1 margintop">
                  <label>&nbsp;</label>
                  <div className="form-group">
                    <input
                      type="button"
                      value="Upload File"
                      onClick={this.UploadFile.bind(this)}
                      className="btn btn-block btn-primary"
                    />
                  </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                  <label>&nbsp;</label>
                  <div className="form-group">
                    {this.state.visible && (
                      <input
                        type="button"
                        value="Export Error Data"
                        onClick={this.ExportErrordata.bind(this)}
                        className="btn btn-block btn-danger"
                      />
                    )}
                  </div>
                </div>
                {(this.state.loginId == "10" || this.state.loginId == "1") && (
                  <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                    <label>&nbsp;</label>
                    <div className="form-group">
                      {this.state.visible && (
                        <button
                          onClick={this.ExportRelErrordata.bind(this)}
                          className="btn btn-block btn-danger"
                        >
                          Export Error<sup>(beta)</sup>
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }

  async updateVSDATA() {
    this.setState({ loading: true });
    let Que = "EXEC USP_SetVSData_ForMerc @loginid=" + this.state.loginId;
    let rs = await getDataTable(Que);
    if (rs) {
      this.setState({ loading: false });
      Swal.fire({
        icon: "info",
        title: "Data Successfully Updated",
        showConfirmButton: false,
        timer: 2500,
      });
    }
  }

  async deleteRow(e) {
    let rst = window.confirm("Are you sure want to delete ?");
    if (!rst) {
      return false;
    }
    let pid = e["#ParentID"];
    this.setState({ loading: true });
    let Que =
      "EXEC DeleteProduct @loginid=" + this.state.loginId + " , @pid=" + pid;
    let rs = await getDataTable(Que);
    if (rs) {
      this.setState({ loading: false });
      Swal.fire({
        icon: "info",
        title: "Product Deleted SuccessFully",
        showConfirmButton: false,
        timer: 2500,
      });
      await this.getdata(this.state.loginId, "");
    }
  }

  async downloadImportFormat() {
    let MandatoryArray = [{ Block: this.state.catid1 }];
    let check = MandatoryFormFields(MandatoryArray);
    if (check == false) {
      return false;
    }

    let brandCheckQuery =
      "SELECT COUNT(*) AS brandCount FROM BrandMaster WHERE OrgID = " +
      this.state.orgid;
    let brandCheckResult = await getDataTable(brandCheckQuery);

    if (brandCheckResult[0].brandCount === 0) {
      // Show an error message if the brand is not created
      debugger;
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Brand Not Created",
        text: "Please Create The Brand First From Brand Master",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }

    let rsst = await getDataTable(
      "SELECT templateid,TamplateName FROM ImportTemplateMaster where TemplateDis = 0 and selectType in (0,1) and CatID = " +
        this.state.catid1
    );
    if (Array.isArray(rsst)) {
      await Modal.info({
        title: "Import Format List",
        okText: "CLOSE",
        okButtonProps: { style: { display: "none" } },
        width: "600px",
        closable: true,
        footer: null,
        bodyStyle: { maxHeight: "400px" },
        style: { padding: "0px" },
        centered: true,
        maskClosable: true,
        content: (
          <div>
            {rsst.map((value, index) => {
              return (
                <div style={{ marginTop: "2px" }}>
                  <button
                    className="btn btn-success"
                    onClick={this.ExportFormat.bind(this, value.templateid)}
                  >
                    {value.TamplateName}
                  </button>
                  <br />
                </div>
              );
            })}
          </div>
        ),
      });
    }
  }

  async downloadCustomFormat() {
    let MandatoryArray = [
      { Block: this.state.catid1 },
      { "Import Sheet": this.state.ImportSheetId },
    ];
    let check = MandatoryFormFields(MandatoryArray);
    if (check == false) {
      return false;
    }

    let rsst = await getDataTable(
      "SELECT templateid,TamplateName FROM ImportTemplateMaster where TemplateDis = 0 and selectType in (0,2) and CatID = " +
        this.state.catid1
    );
    if (Array.isArray(rsst)) {
      await Modal.info({
        title: "Import Format List",
        okText: "CLOSE",
        okButtonProps: { style: { display: "none" } },
        width: "600px",
        closable: true,
        footer: null,
        bodyStyle: { maxHeight: "400px" },
        style: { padding: "0px" },
        centered: true,
        maskClosable: true,
        content: (
          <div>
            {rsst.map((value, index) => {
              return (
                <div style={{ marginTop: "2px" }}>
                  <button
                    className="btn btn-success"
                    onClick={this.ExportFormat.bind(this, value.templateid)}
                  >
                    {value.TamplateName}
                  </button>
                  <br />
                </div>
              );
            })}
          </div>
        ),
      });
    }
  }

  async ExportFormat(id) {
    this.setState({ loading: true });
    let sql = "Exec ExcelImportFormatExample @catid =" + id;
    let rs = await getDataTable(sql);
    if (Array.isArray(rs)) {
      let Quue =
        "EXEC GetOption_Value @tempid=" +
        id +
        " , @loginID = " +
        this.state.loginId;
      let rs1 = await fetch(
        "https://api.blumangoes.ai:6143/GetdrpExcel?que=" +
          Quue +
          "&BlockID=" +
          this.state.catid1
      );
      let str = await rs1.text();
      let link = document.createElement("a");
      link.href =
        "data:application/vndopenxmlformats-officedocumentspreadsheetmlsheet;base64," +
        str;
      link.setAttribute(
        "download",
        "IMPORTFORMAT-" + this.state.catname1 + ".xlsx"
      );
      document.body.appendChild(link);
      link.click();
      this.setState({ loading: false });
    } else {
      Swal.fire({
        icon: "info",
        title: "No attribute Found",
        showConfirmButton: false,
        timer: 1500,
      });
      this.setState({ loading: false });
    }
  }
}
