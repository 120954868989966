import React, {Component, useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { APIService } from '../../../Config/action/apiAction';
import { connect } from 'react-redux';
import { TokenService } from '../../../Config/action/tokenAction';
import moment from 'moment';
import CryptoService from '../../../Config/action/crypto';
import { getAuthorization } from '../../../Configuration';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function TicketModal(props) {

    const [state, setState] = useState({});

    const [errors, setErrors] = useState({
        Message: false,
        TicketType: false
    });
    const [formUpdated, setFormUpdated] = useState(false);

    const inputHandler = (value, key) => {
        if(key === "Status") {
            setFormUpdated(true);
        }
        console.log(key, value)
        setState({
            ...state,
            [key]: value
        })
    }

    useEffect(() => {
        console.log(state)
    }, [state])

    const [isAdmin, setAdmin] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if(state.TicketType && state.Message){
            setErrors({
                ...state,
                Message: false,
                TicketType: false
            });

            if(props.currentRecord){
                console.log("Edit Record");
            }
            else{
                // let user = JSON.parse(localStorage.getItem("auth_user"));
                props.dispatch(TokenService("getToken", "auth_user")).then((token) => {
                    let user = token;
                    let payload = {
                        "USERID": user.ID,
                        "Type": state.TicketType,
                        "Message": state.Message
                    }
                    props.dispatch(APIService("POST", "SavePaymentDetails?query= SAVE_TROUBLE_TICKET", payload)).then((data) => {
                        console.log(data);
                        if(data.data.data[0].TicketID){
                            handleClose(true);
                        }
                    }).catch((e) => {
                        console.log(e)
                    })
                });
            }
        }
        else{
            setErrors({
                ...state,
                Message: state.Message ? false : true,
                TicketType: state.TicketType ? false : true
            })
        }
    }

    useEffect(() => {
        if(props.currentRecord){
            setAdmin(CryptoService.decrypt(getAuthorization()).isAdmin);
            let type = props.currentRecord["TicketType"].includes("Authentication") ? "Authentication" : 
                            props.currentRecord["TicketType"].includes("Payment") ? "Payment" : "Other";
            setState({
                ...state,
                CreateDate: props.currentRecord["CREATE DATE"] || "",
                Message: props.currentRecord["Message"] || "",
                Status: props.currentRecord["Status"] || "",
                TicketID: props.currentRecord["TicketID"] || "",
                TicketType: type,
                Remark: props.currentRecord["Remark"] || ""
            })
        }
    }, [props]);

    const handleClose = (reload) => {
        setState({});
        setErrors({
            Message: false,
            TicketType: false
        });
        props.handleClose(reload);
    }

    const handleReinitiate = (e, reInitiate) => {
        e.preventDefault();
        e.stopPropagation();
        let object = {
            "TICKETID": state.TicketID,
            "STATUS": reInitiate ? "Initialized" : state.Status,
            // "REMARK": state.Remark
        };

        props.dispatch(APIService("POST", "SavePaymentDetails?query=UPDATE_TICKET_STATUS", object)).then((data) => {
            showToast("Ticket status has been updated");
            handleClose(true);
        })
    }

    const renderOptions = () => {
        let data = [{
                key: "Completed",
                value: "Completed"
            },
            {
                key: "Close",
                value: "Close"
            },
            {
                key: "Cancel",
                value: "Cancel"
            }
        ];
        return data.map((dt, i) => {
         //console.log(dt);
            return (
                <MenuItem
                    label="Select status"
                    value={dt.value}
                key={i} name={dt.value}>{dt.key}</MenuItem>
                
            );
        });
    }

    const showToast = (message) => {
        toast(message, {
           position: "top-right",
           type: 'success',
           autoClose: 2000,
           hideProgressBar: true,
           closeOnClick: true,
           pauseOnHover: true,
           draggable: false,
           progress: undefined,
       });
     }

    const getCurrentDate = (date) => {
        if(date){
            let d = new Date(date);
            return moment(d).format('DD/MM/YYYY mm:ss a')
        }
        else{
            return '--'
        }
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{padding:'10px', minWidth:'50%'}}
        >
            <DialogTitle id="alert-dialog-title" style={{textAlign:'center'}}><span style={{fontWeight:'bold', marginTop:'20px'}}>{"Add Ticket"}</span></DialogTitle>
            <DialogContent>
                <ToastContainer />
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <div style={{width:'170px'}}>
                            <small style={{fontSize:'12px', fontWeight:'bold'}}>Ticket ID</small><br />
                            <p style={{fontSize:'20px'}}>{state.TicketID || '--'}</p>
                        </div>
                        {/* <TextField id="standard-basic" value={state.TicketID || ''} style={{width:'100%', marginBottom:'20px'}} disabled={true} onChange={(e) => inputHandler(e.target.value, "TicketID")} label="Ticket ID" /> */}
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div style={{width:'170px'}}>
                            <small style={{fontSize:'12px', fontWeight:'bold'}}>Status</small><br />
                            {
                                ((props.currentRecord?.Status != "Completed"  && props.currentRecord?.Status != "Close" && props.currentRecord?.Status != "Cancel") && state.TicketID && isAdmin) ? (
                                    <Select style={{fontSize:'18px', width: "100%"}} value={state.Status} 
                                    onChange={(e) => inputHandler(e.target.value, "Status")} >
                                        {renderOptions()}
                                    </Select>
                                ) : (
                                    <p style={{fontSize:'20px'}}>{state.Status || '--'}</p>
                                )
                            }
                        </div>
                        {/* <TextField id="standard-basic" value={state.Status || ''} style={{width:'100%', marginBottom:'20px'}} disabled={true} onChange={(e) => inputHandler(e.target.value, "Status")} label="Status" /> */}
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <div style={{width:'100%'}}>
                            <small style={{fontSize:'12px', fontWeight:'bold'}}>Created Date</small><br />
                            <p style={{fontSize:'18px'}}>{getCurrentDate(state.CreateDate)}</p>
                        </div>
                        {/* <TextField id="standard-basic" value={state.CreateDate || ''} onChange={(e) => inputHandler(e.target.value, "CREATE DATE")} label="Created Date" style={{width:'100%', marginBottom:'20px'}} disabled={true} /> */}
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        {
                            props.currentRecord && props.currentRecord.Status ? (
                                // <div style={{width:'100%'}}>
                                //     <small style={{fontSize:'12px', fontWeight:'bold'}}>Ticket Type</small><br />
                                //     <p style={{fontSize:'20px'}}>{state.TicketType || ''}</p>
                                // </div>
                                <TextField id="standard-basic" value={state.TicketType || ''} onChange={(e) => inputHandler(e.target.value, "TicketType")} label="Type" style={{width:'100%', marginBottom:'20px'}} disabled={true} />
                            ) : (
                                <FormControl style={{width:'100%'}}>
                                    <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                    <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={state.TicketType || ''}
                                    onChange={(e) => inputHandler(e.target.value, "TicketType")}
                                    >
                                        <MenuItem value={"Payment"}>Payment</MenuItem>
                                        <MenuItem value={"Authentication"}>Authentication</MenuItem>
                                        <MenuItem value={"Other"}>Other</MenuItem>
                                    </Select>
                                </FormControl>
                            )
                        }
                    </div>
                    {
                        errors.TicketType ? (
                            <span style={{color:'red', padding:'8px'}}>Ticket type is required*</span>
                        ) : null
                    }
                </div>

                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        {
                            props.currentRecord && props.currentRecord.Status ? (
                                <TextField
                                    id="standard-multiline-static"
                                    label="Description"
                                    multiline
                                    disabled={true}
                                    rows={4}
                                    onChange={(e) => inputHandler(e.target.value, "Message")}
                                    value={state.Message || ''}
                                    style={{width:'100%', marginTop:'20px', fontSize:'12px'}}
                                />
                            ) : (
                                <TextField
                                    id="standard-multiline-static"
                                    label="Description"
                                    multiline
                                    rows={4}
                                    onChange={(e) => inputHandler(e.target.value, "Message")}
                                    value={state.Message || ''}
                                    style={{width:'100%', marginTop:'20px', fontSize:'12px'}}
                                />
                            )
                        }
                    </div>
                    {
                        errors.Message ? (
                            <span style={{color:'red', padding:"8px"}}>Description is required*</span>
                        ) : null
                    }
                </div>
                
                {
                    props.currentRecord && props.currentRecord.Status === "Completed" ? (
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <TextField
                                    id="standard-multiline-static"
                                    label="Remark"
                                    multiline
                                    rows={4}
                                    onChange={(e) => inputHandler(e.target.value, "Remark")}
                                    value={state.Remark || ''}
                                    style={{width:'100%', marginTop:'15px'}}
                                />
                            </div>
                        </div>
                    ) : null
                }
            {/* </DialogContentText> */}
            </DialogContent>
            <DialogActions style={{marginBottom:'10px'}}>
                <Button onClick={() => handleClose(false)} color="secondary" style={{fontSize:'12px'}}>
                    Cancel
                </Button>
                {
                    // console.log(props.currentRecord)
                    // console.log(props.currentRecord)
                    (!props.currentRecord) ? (
                        <Button color="primary" onClick={handleSubmit} style={{fontSize:'12px'}}>
                            Submit
                        </Button>
                    ) : (props.currentRecord && (props.currentRecord.Status === "Completed" || props.currentRecord.Status === "Close" || props.currentRecord.Status === "Cancel")) ? (
                        <Button color="primary" onClick={(e) => handleReinitiate(e, true)} style={{fontSize:'12px'}}>
                            Re-Initiate
                        </Button>
                    ) : null
                }
                {
                    formUpdated ? (
                        <Button color="primary" onClick={(e) => handleReinitiate(e, false)} style={{fontSize:'12px'}}>
                            Update
                        </Button>
                    ) : null
                }
            </DialogActions>
        </Dialog>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: () => {
            dispatch({
                type: 'loginUser'
            });
        },
        logoutUser: () => {
            dispatch({
                type: 'logoutUser'
            });
        },
        dispatch
    }
}

export default connect(null, mapDispatchToProps)(TicketModal);