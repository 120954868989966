import { Spin } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PageTitle from "../../Common/pageTitle";
import Button from "@material-ui/core/Button";
import { APIService } from "../../../Config/action/apiAction";
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  TableHead,
  Typography,
} from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router";
import DraggedTable from "./draggedTable";

// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import td from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
// import Tooltip from '@mui/material/Tooltip';

function ImportSheetDetail(props) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [selectedBlock, setSelectedBlock] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("");
  const [selectedChildAttribute, setSelectedChildAttribute] = useState("");
  const [blockList, setBlockList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [priorityList, setPriorityList] = useState([]);
  const [usedPriorityList, setUsedPriorityList] = useState([]);
  const [templateName, setTemplateName] = useState("");
  const [sheetName, setSheetName] = useState("");
  const [templateInfo, setTemplateInfo] = useState({
    isDraft: "",
    showValueSheet: "",
    selectType: "",
  });
  const [tempUpdateData, setTempUpdateData] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const history = useHistory();

  const colorList = [
    {
      name: "Blue",
      code: "#007bff",
    },
    {
      name: "Light Blue",
      code: "#17a2b8",
    },
    {
      name: "Blue (Lighter 40%)",
      code: "#9CDAEE",
    },
    {
      name: "Blue (Lighter 60%)",
      code: "#AEDCEB",
    },
    {
      name: "Gray",
      code: "#6c757d",
    },
    {
      name: "Green",
      code: "#28a745",
    },
    {
      name: "Green (Lighter 40%)",
      code: "#8EC895",
    },
    {
      name: "Green (Lighter 60%)",
      code: "#9ACBA0",
    },
    {
      name: "Red",
      code: "#dc3545",
    },
    {
      name: "Red (Lighter 40%)",
      code: "#D88276",
    },
    {
      name: "Red (Lighter 60%)",
      code: "#DA968C",
    },
    {
      name: "Yellow",
      code: "#ffc107",
    },
    {
      name: "No Fill",
      code: "#ffffff",
    },
  ];

  useEffect(() => {
    if (props.match.params && props.match.params.id) {
      setSelectedTemplate(props.match.params.id);
    } else {
      setSelectedTemplate(-1);
    }
    defaultUseEffect();
  }, []);

  useEffect(() => {
    console.log(data);
  }, [data]);

  const blockChangeHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedBlock(e.currentTarget.value);
    // getColumnData(e.currentTarget.value, "");
  };

  const fetchColumnData = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (selectedBlock) {
      getColumnData(selectedBlock, "");
    } else {
      toast("Please select a block", {
        position: "top-right",
        type: "error",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  };

  const groupChangeHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedGroup(e.currentTarget.value);
  };

  const defaultUseEffect = () => {
    setLoading(true);
    let payload = {
      query: "frmBlockMaster_GetBlockData",
    };
    props
      .dispatch(APIService("DYNAMIC_NEW_POST", "", payload))
      .then((data) => {
        setLoading(false);
        if (data.status === 200 && data.data.status === 1000) {
          setBlockList(data.data.data);
          if (props.match.params.id) {
            fetchTemplateData();
          } else {
            getGroupData();
          }
        } else {
          throw 500;
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getGroupData = () => {
    let payload = {
      query: "EXEC FrmImportSheetMaster_GetAttributeGroup",
    };
    setLoading(true);
    props
      .dispatch(APIService("DYNAMIC_NEW_POST", "", payload))
      .then((resp) => {
        setLoading(false);
        if (resp.status === 200 && resp.data.status === 1000) {
          setGroupList(resp.data.data);
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const fetchTemplateData = () => {
    let payload = {
      query: `frmImportSheet_getImportSheetDeatils @templateid = ${props.match.params.id}`,
    };
    setLoading(true);
    props
      .dispatch(APIService("DYNAMIC_NEW_POST", "", payload))
      .then((resp) => {
        setLoading(false);
        if (resp.status === 200 && resp.data.status === 1000) {
          if (resp.data.data.length > 0) {
            setTemplateName(resp.data.data[0].TamplateName);
            setSheetName(resp.data.data[0].SheetName);
            setTempUpdateData(resp.data.data[0]);
            // Block ID is required from response
            setSelectedBlock(resp.data.data[0].BlockID);
            setSelectedTemplate(resp.data.data[0].TemplateID);
            setTemplateInfo({
              ...templateInfo,
              isDraft: resp.data.data[0].isDraft.toString(),
              showValueSheet: resp.data.data[0].showValueSheet.toString(),
            });
            getColumnData(
              resp.data.data[0].BlockID,
              JSON.parse(resp.data.data[0].Columns)
            );
          } else {
            throw 500;
          }
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const getColumnData = (blockID, updateData) => {
    setLoading(true);
    let query = `frmImportSheet_GetAllPossibleColumn @BlockID = ${blockID}`;
    if (selectedGroup) {
      query = `${query}, @GroupID = ${selectedGroup}`;
    } else {
      query = `${query}, @GroupID = 0`;
    }

    if (selectedChildAttribute) {
      query = `${query}, @ShowChildAttribute = ${selectedChildAttribute}`;
    } else {
      query = `${query}, @ShowChildAttribute = 0`;
    }
    let payload = {
      query: query,
    };
    console.log(query);
    props
      .dispatch(APIService("DYNAMIC_NEW_POST", "", payload))
      .then((data) => {
        setLoading(false);
        if (data.status === 200 && data.data.status === 1000) {
          if (props.match.params && props.match.params.id) {
            // update
            let list = [];
            let usedList = [];
            let usedPriority = [];
            data.data.data.map((item) => {
              updateData.map((x) => {
                if (x.AID === item.AID && !usedList.includes(x.AID)) {
                  item["Priority"] = x.Priority;
                  item["COLOR"] = x.COLOR || "#ffffff";
                  item["Checked"] = 1;
                  item["isMandatory"] = x.isMandatory || 0;
                  item["key"] = x.AID;
                  list.push(item);
                  if (x.Priority) {
                    usedPriority.push(x.Priority);
                  }
                  usedList.push(parseInt(x.AID));
                }
              });
            });
            data.data.data.map((item) => {
              if (!usedList.includes(parseInt(item.AID))) {
                item["Priority"] = "";
                item["COLOR"] = "#ffffff";
                item["Checked"] = 0;
                item["isMandatory"] = 0;
                item["key"] = item.AID;
                list.push(item);
                usedList.push(item.AID);
              }
            });
            let priority = [];
            for (let i = 1; i <= list.length; i++) {
              priority.push(i);
            }
            setPriorityList(priority);
            let nonPriority = [];
            let itemList = list.sort((a, b) => {
              return a.Priority - b.Priority;
            });
            let newListing = itemList.filter((x) => {
              if (x.Priority) {
                return x;
              } else {
                nonPriority.push(x);
              }
            });
            newListing.map((item, index) => {
              item["Priority"] = index + 1;
            });
            setData(newListing.concat(nonPriority));
            setUsedPriorityList(usedPriority);
          } else {
            // create
            let listedList = [];
            data.data.data.map((x, index) => {
              x["Priority"] = "";
              x["COLOR"] = "#ffffff";
              x["Checked"] = 0;
              x["isMandatory"] = 0;
              x["key"] = index;
              listedList.push(x);
            });
            let priority = [];
            for (let i = 1; i <= listedList.length; i++) {
              priority.push(i);
            }
            setPriorityList(priority);
            setData(listedList);
          }
        } else {
          throw 500;
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const checkboxHandler = (value, item) => {
    if (value) {
      let list = [...data];
      let usedValues = [...usedPriorityList];
      let p = 0;
      var largest = 0;
      for (let i = 0; i <= largest; i++) {
        if (usedValues[i] > largest) {
          var largest = usedValues[i];
        }
      }
      let nextVal = parseInt(largest) + 1;
      if (priorityList.includes(nextVal)) {
        p = nextVal;
        usedValues.push(p);
      } else {
        for (let j = 0; j < priorityList.length; j++) {
          if (!usedValues.includes(priorityList[j])) {
            p = priorityList[j];
            usedValues.push(p);
            break;
          }
        }
      }
      let tempIndex = -1;
      list.map((x, index) => {
        if (x.AID === item.AID) {
          x["Checked"] = 1;
          x["Priority"] = p;
          tempIndex = index;
        }
      });
      let tempStorage = list.splice(tempIndex, 1)[0];
      list.splice(p - 1, 0, tempStorage);
      setData(list);
      setUsedPriorityList(usedValues);
    } else {
      let list = [...data];
      let currentIndex = -1;
      list.map((x, index) => {
        if (x.AID === item.AID) {
          currentIndex = index;
          x["Checked"] = 0;
          x["isMandatory"] = 0;
          x["COLOR"] = "#ffffff";
          if (x.Priority) {
            x["Priority"] = "";
          }
        }
      });
      let tempStorage = list.splice(currentIndex, 1)[0];
      let listing = [];
      list.push(tempStorage);
      list.map((val, index) => {
        if (val.Priority) {
          val["Priority"] = index + 1;
          listing.push(index + 1);
        }
      });
      setUsedPriorityList(listing);
      setData(list);
    }
  };

  const colorHandler = (e, item) => {
    let list = [...data];
    list.map((x) => {
      if (x.AID === item.AID) {
        x["COLOR"] = e.currentTarget.value;
      }
    });
    setData(list);
  };

  const mandatoryHandler = (value, item) => {
    let list = [...data];
    list.map((x) => {
      if (x.AID === item.AID) {
        x["isMandatory"] = value ? 1 : 0;
      }
    });
    setData(list);
  };

  const saveData = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    let valid = true;
    let payload = [];
    if (!templateInfo.isDraft || !templateInfo.showValueSheet) {
      toast(
        templateInfo.isDraft
          ? "Show Value Sheet field is required"
          : "Is Draft field is required",
        {
          position: "top-right",
          type: "error",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        }
      );
      setLoading(false);
      return false;
    }
    if (!templateName) {
      toast("Template name is required", {
        position: "top-right",
        type: "error",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
      setLoading(false);
      return false;
    }
    if (!sheetName) {
      toast("Sheet name is required", {
        position: "top-right",
        type: "error",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
      setLoading(false);
      return false;
    }
    if (!templateInfo.selectType) {
      toast("Select Type is required", {
        position: "top-right",
        type: "error",
        autoClose: "2500",
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
      setLoading(false);
      return false;
    }

    data.map((x) => {
      if (x.Checked) {
        if (x.Priority) {
          x["TemplateName"] = templateName;
          x["BlockID"] = selectedBlock;
          x["TemplateID"] = selectedTemplate > 0 ? selectedTemplate : 0;
          x["isDraft"] = parseInt(templateInfo.isDraft);
          x["showValueSheet"] = parseInt(templateInfo.showValueSheet);
          x["selectType"] = parseInt(templateInfo.selectType);
          x["sheetName"] = sheetName;
          payload.push(x);
        } else {
          toast("Please set the priority for all the selected columns", {
            position: "top-right",
            type: "error",
            autoClose: 2500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          valid = false;
          setLoading(false);
        }
      }
    });
    if (valid) {
      let userId = JSON.parse(localStorage.getItem("auth_user")).ID;
      let requestBody = {
        query: `EXEC frmImportSheet_SaveUpdateTemplate @dtJSON = '${JSON.stringify(
          payload
        )}' , @loginID = ${userId}`,
      };
      props
        .dispatch(APIService("DYNAMIC_NEW_POST", "", requestBody))
        .then((data) => {
          setLoading(false);
          if (data.status === 200 && data.data.status === 1000) {
            createToast(
              selectedTemplate
                ? "Template updated successfully"
                : "Template created successfully"
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          createToast("Something went wrong.");
        });
    }
  };

  useEffect(() => {
    if (
      usedPriorityList.length > 0 &&
      usedPriorityList.length === priorityList.length
    ) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [usedPriorityList]);

  const createToast = (msg) => {
    toast(msg, {
      position: "top-right",
      type: "error",
      autoClose: 2500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  };

  const selectAllChange = (globalChecked) => {
    setLoading(true);
    setSelectAll(globalChecked);
    let list = [...data];
    let l = [];
    if (globalChecked) {
      list.map((item, index) => {
        item.Priority = index + 1;
        item.Checked = 1;
        l.push(index + 1);
      });
      setUsedPriorityList(l);
      setData(list);
      setLoading(false);
    } else {
      list.map((item) => {
        item.Priority = "";
        item.Checked = 0;
      });
      setUsedPriorityList([]);
      setData(list);
      setLoading(false);
    }
  };

  return (
    <div className="content-wrapper">
      <div className="loader" style={{ display: loading ? "block" : "none" }}>
        <div className="loader-item">
          <Spin />
        </div>
      </div>
      <ToastContainer />
      <section className="content" style={{ background: "white" }}>
        <div
          id="ctl00_CPHMaincontent_divclient"
          className="box box-success"
          style={{ marginTop: "-13px" }}
        >
          <div className="box-header with-border">
            <h3 className="box-title">
              {selectedTemplate > 0 ? "Update Template" : "Create Template"}
              {/* <sup>
              (<span className="vcode">*</span> Fields are mandatory)
            </sup> */}
              <span
                id="ctl00_CPHMaincontent_DivOrdNo"
                style={{ display: "inline" }}
              ></span>
            </h3>
            <div className="pull-right" style={{ marginLeft: "10px" }}>
              <button
                onClick={() => history.push("/Import-sheet/templates")}
                className="btn btn-primary"
              >
                Go Back
              </button>
            </div>{" "}
          </div>
          <div
            className="box-body"
            id="orderDetails"
            style={{ display: "block" }}
          >
            <div className="row">
              <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                <label>
                  Select Block<span className="errClass">*</span>
                </label>
                <div className="form-group">
                  <select
                    className="importSheetSelect"
                    disabled={selectedTemplate > 0}
                    value={selectedBlock}
                    style={{ height: "30px", marginTop: "0px" }}
                    onChange={(e) => blockChangeHandler(e)}
                  >
                    <option value="">Select Block</option>
                    {blockList.length > 0 && (
                      <Fragment>
                        {blockList.map((item, index) => {
                          return (
                            <option key={index} value={item.BlockID}>
                              {item.BlockName}
                            </option>
                          );
                        })}
                      </Fragment>
                    )}
                  </select>
                </div>
              </div>
              {selectedTemplate === 0 || selectedTemplate < 0 ? (
                <>
                  <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                    <label>Select Group</label>
                    <div className="form-group">
                      <select
                        className="importSheetSelect"
                        disabled={selectedTemplate > 0}
                        value={selectedGroup}
                        style={{ height: "30px", marginTop: "0px" }}
                        onChange={(e) => groupChangeHandler(e)}
                      >
                        <option value="">Select Group</option>
                        {groupList.length > 0 && (
                          <Fragment>
                            {groupList.map((item, index) => {
                              return (
                                <option key={index} value={item.groupid}>
                                  {item.groupName}
                                </option>
                              );
                            })}
                          </Fragment>
                        )}
                      </select>
                    </div>
                  </div>
                  <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                    <label>Show Child Attribute</label>
                    <div className="form-group">
                      <select
                        className="importSheetSelect"
                        disabled={selectedTemplate > 0}
                        value={selectedChildAttribute}
                        style={{ height: "30px", marginTop: "0px" }}
                        onChange={(e) =>
                          setSelectedChildAttribute(e.currentTarget.value)
                        }
                      >
                        <option value="">Show Child Attribute</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                    <label className="opacity0">Select Group</label>
                    <div>
                      <button
                        onClick={(e) => fetchColumnData(e)}
                        className="btn btn-primary"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
          <br />
          {data.length > 0 && (
            <div
              className="box-body"
              id="orderDetails"
              style={{ display: "block" }}
            >
              <label className="staticTitle">Columns</label>
              <div className="pull-right" style={{ marginLeft: "10px" }}>
                <label className="opacity0">Button</label>
                <div>
                  {selectedTemplate > 0 ? (
                    <>
                      {" "}
                      <button onClick={saveData} className="btn btn-primary">
                        Update
                      </button>{" "}
                    </>
                  ) : (
                    <>
                      {" "}
                      <button onClick={saveData} className="btn btn-primary">
                        Save
                      </button>{" "}
                    </>
                  )}
                </div>
              </div>{" "}
              <div className="pull-right d-flex">
                <div className="form-group">
                  <label>
                    Select Type<span className="errClass">*</span>
                  </label>
                  <div>
                    <select
                      className="importSheetSelect"
                      value={templateInfo.selectType}
                      style={{
                        height: "30px",
                        marginTop: "0px",
                        width: "140px",
                        marginRight: "10px",
                      }}
                      onChange={(e) => {
                        if (e.currentTarget.value) {
                          setTemplateInfo({
                            ...templateInfo,
                            selectType: e.currentTarget.value,
                          });
                        }
                      }}
                    >
                      <option value="">Please Select</option>
                      <option value="0">Both</option>
                      <option value="1">Main</option>
                      <option value="2">Custom</option>
                      <option value="3">Nowhere</option>
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <label>
                    Is Draft<span className="errClass">*</span>
                  </label>
                  <div>
                    <select
                      className="importSheetSelect"
                      value={templateInfo.isDraft}
                      style={{
                        height: "30px",
                        marginTop: "0px",
                        width: "140px",
                        marginRight: "10px",
                      }}
                      onChange={(e) => {
                        if (e.currentTarget.value) {
                          setTemplateInfo({
                            ...templateInfo,
                            isDraft: e.currentTarget.value,
                          });
                        }
                      }}
                    >
                      <option value="">Is Draft</option>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <label>
                    Show Value Sheet<span className="errClass">*</span>
                  </label>
                  <div>
                    <select
                      className="importSheetSelect"
                      value={templateInfo.showValueSheet}
                      style={{
                        height: "30px",
                        marginTop: "0px",
                        width: "160px",
                        marginRight: "10px",
                      }}
                      onChange={(e) => {
                        if (e.currentTarget.value) {
                          setTemplateInfo({
                            ...templateInfo,
                            showValueSheet: e.currentTarget.value,
                          });
                        }
                      }}
                    >
                      <option value="">Show Value Sheet*</option>
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                  </div>
                </div>
                <div className="form-group" style={{ marginRight: "10px" }}>
                  <label>
                    Sheet Name<span className="errClass">*</span>
                  </label>
                  <div>
                    <input
                      name="Sheet Name"
                      id="SheetName"
                      onChange={(e) => setSheetName(e.currentTarget.value)}
                      value={sheetName}
                      // onChange={this.myChangeHandler}
                      placeholder="Sheet Name"
                      // tabIndex={1}
                      className="form-control"
                    />
                  </div>
                </div>
                <div
                  className="form-group"
                  //   style={{ marginRight: "10px" }}
                >
                  <label>
                    Template Name<span className="errClass">*</span>
                  </label>
                  <div>
                    <input
                      name="Template Name"
                      id="Template Name"
                      onChange={(e) => setTemplateName(e.currentTarget.value)}
                      value={templateName}
                      // onChange={this.myChangeHandler}
                      placeholder="Template Name"
                      // tabIndex={1}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>{" "}
              <div className="row" style={{ marginTop: "30px" }}>
                {data.length > 0 && (
                  <div className="p-5">
                    <DraggedTable
                      data={data}
                      setData={setData}
                      mandatoryHandler={mandatoryHandler}
                      checkboxHandler={checkboxHandler}
                      colorList={colorList}
                      colorHandler={colorHandler}
                      usedPriorityList={usedPriorityList}
                      selectAll={selectAll}
                      selectAllChange={selectAllChange}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: () => {
      dispatch({
        type: "loginUser",
      });
    },
    logoutUser: () => {
      dispatch({
        type: "logoutUser",
      });
    },
    dispatch,
  };
};

export default connect(null, mapDispatchToProps)(withRouter(ImportSheetDetail));
