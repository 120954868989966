import React, { useEffect, useState } from 'react';
import ReactDragListView from 'react-drag-listview/lib/index.js';
import { Table, Tag, Space } from 'antd';
import { DragOutlined } from "@ant-design/icons";
import { Card, CardActions, CardContent, Checkbox, TableHead, Typography } from '@material-ui/core';

function DraggedTable ({ data, setData, mandatoryHandler, checkboxHandler, colorList, colorHandler, usedPriorityList, selectAll, selectAllChange }) {

    const dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const d = [...data];
        const item = d.splice(fromIndex, 1)[0];
        d.splice(toIndex, 0, item);
        let largest = Math.max(...usedPriorityList);
        let tempCurrentIndex = -1;
        d.map((item, index) => {
            if(item.Priority) {
                if(index+1 > largest) {
                    item["Priority"] = largest;
                    tempCurrentIndex = index;
                }
                else {
                    item["Priority"] = index + 1;
                }
            }
        })
        if(tempCurrentIndex >= 0) {
            let tempStorage = d.splice(tempCurrentIndex, 1)[0]
            d.splice(largest-1, 0, tempStorage);
        }
        setData(d);
      },
      nodeSelector: 'tr',
      handleSelector: '.all-scroll'
    };

    useEffect(() => {
        console.log(selectAll)
    }, [selectAll])

    const columns = [
        {
            title: (_, record) => {
                return (
                    <Checkbox
                        checked={selectAll}
                        onChange={(e, val) => selectAllChange(val)}
                        style={{
                        height: "15px",
                        transform: "scale(1.5)",
                        paddingTop: "8px",
                        }}
                    />
                )
            },
            dataIndex: 'Checked',
            key: 'Checked',
            render: (_, record) => {
                return (
                    <Checkbox
                        checked={record.Checked > 0}
                        onChange={(e, val) =>
                            checkboxHandler(val, record)
                        }
                        style={{
                        height: "15px",
                        transform: "scale(1.5)",
                        paddingTop: "5px",
                        }}
                    />
                )
            },
        },
        {
          title: 'Field Name',
          dataIndex: 'AttributeName',
          key: 'AttributeName'
        },
        {
          title: 'Background Color',
          dataIndex: 'COLOR',
          key: 'COLOR',
          render: (_, record) => {
              return (
                <select disabled={!record.Checked} id="favcolor" value={record.COLOR} onChange={(e) => colorHandler(e, record)} style={{ width: "150px", height: "30px" }}>
                    {
                        colorList.map((item, index) => {
                            return (
                                <option key={index} value={item.code} style={{"color": `${item.code === "#ffffff" ? "black" : item.code}`}}>
                                    {item.name}
                                </option>
                            )
                        })
                    }
                </select>
              )
          }
        },
        {
          title: 'Priority',
          dataIndex: 'Priority',
          key: 'Priority',
        },
        {
            title: "Mandatory",
            dataIndex: "isMandatory",
            key: "isMandatory",
            render: (_, record) => {
                return (
                    <Checkbox
                        checked={record.isMandatory > 0}
                        onChange={(e, val) =>
                            mandatoryHandler(val, record)
                        }
                        disabled={!record.Checked}
                        style={{
                        height: "15px",
                        transform: "scale(1.5)",
                        paddingTop: "5px",
                        }}
                    />
                )
            }
        },
        {
          title: 'Drag',
          key: 'action',
          render: (text, record) => (
              <>
                {
                    record.Checked ? (
                        <DragOutlined style={{fontSize: "18px"}} className='all-scroll' />
                    ) : <></>
                }
              </>
          ),
        },
      ];

    return (
      <div className="simple simple1">
        <div className="simple-inner">
          <ReactDragListView {...dragProps}>
            <Table columns={columns} className='dragTable' dataSource={data} pagination={false} />
            {/* <ol>
              {data.map((item, index) => (
                <li key={index}>
                  {item.title}
                  <span>
                    <div>Drag</div>
                  </span>
                </li>
            ))}
            </ol> */}
          </ReactDragListView>
        </div>
      </div>
    );
  }

export default DraggedTable;