import React,{Component,useRef} from 'react';
import {Typeahead} from 'react-bootstrap-typeahead'; 
import 'react-bootstrap-typeahead/css/Typeahead.css';
import './style.css';
import {Configuration} from '../../../Configuration';

const TOKEN = Configuration.USER_TOKEN.TOKEN;
const  API_URL = Configuration.SYSTEM_SETTINGS.API_URL;

const autoCompleteInputEl = React.createRef(Typeahead);
 
const ToggleButton = ({ isOpen, onClick }) => (
  <button
    className="toggle-button"
    onClick={onClick}
    onMouseDown={e => {
      // Prevent input from losing focus.
      e.preventDefault();
    }}>
    {isOpen ? '▲' : '▼'}
  </button>
);


export default  class AutoCompleteInput extends Component {
  
  constructor(props) {
      super(props);
      this.myRef = React.createRef(Typeahead);
      this.state= {
        cmbData:[{"DisplayId":1,"DisplayName":"No Data"}],
        mainQuery:"", 
        count:"15",
        token:""
    };
  }

  componentDidMount(){  
  let qry = "formname="+this.props.frmNm+"&queryname="+this.props.quryNm+"&count="+this.state.count+"&searchtext=&filter=&db="+this.props.db+" " ;
 
  const config = {
   
    headers: {
      'Content-Type': 'application/json',
      'Auth-Token' :  TOKEN
    }
    }

 
  fetch(API_URL+'/api/v1/Common/GetFillComboQuery?'+qry+'',config)
  .then(res => res.json())
  .then((data) => {
      this.setState({
        cmbData:data.data
      })
  })
  .catch();
  }
 

onInputChangeHandler(evt){
  
  let SearchKey = evt;

  let qry = "formname="+this.props.frmNm+"&queryname="+this.props.quryNm+"&count="+this.state.count+"&searchtext="+SearchKey.trim()+"&filter=&db="+this.props.db+" " ;
 
  const config = {
   
    headers: {
      'Content-Type': 'application/json',
      'Auth-Token' :  TOKEN
    }
    }

 
  fetch(API_URL+'/api/v1/Common/GetFillComboQuery?'+qry+'',config)
  .then(res => res.json())
  .then((data) => {
      this.setState({
        cmbData:data.data
      })
  })
  .catch();

}

 

render() {

  const {onAfterSelect,onBlur,id,isValid,placeholder,defauldVal} = this.props
    return ( 
   <Typeahead
   clearButton
   id={id}
   labelKey="DisplayName"
   onChange={onAfterSelect}
   options={this.state.cmbData} 
   placeholder={placeholder.toUpperCase()}
   onInputChange={this.onInputChangeHandler.bind(this)}
   onBlur={onBlur}
   multiple
   isValid={isValid}
   ref={this.myRef}
   size='small'
   selectHintOnEnter={true}
  //  defaultSelected={defauldVal}
   selected={defauldVal}
  
   >

{({ isMenuShown, toggleMenu }) => (
      <ToggleButton isOpen={isMenuShown} onClick={e => toggleMenu()} />
    )}

   </Typeahead> 
  );
}};

