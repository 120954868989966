import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { APIService } from '../../../Config/action/apiAction';
import { TokenService } from '../../../Config/action/tokenAction';
import PageTitle from '../../Common/pageTitle/index';
import { Spin } from 'antd';
import { Configuration } from '../../../Configuration';
import "./dashboard.scss"; 

class DashboardDetail extends Component {

    constructor(props){
        super(props);
        this.state = {
            data: {},
            loginId: 2,
            loading: false
        }
    }

  async  componentDidMount(){
        this.setState({
            loginId: Configuration.USER_ID
        });
        // this.props.dispatch(TokenService("getToken", "auth_user")).then((token) => {
        this.setState({
            loading: true
        }, () => {
            this.props.dispatch(APIService("POST", "GetPaymentData?query=GET_DASHBOARD_DATA @ID = "+Configuration.USER_ID)).then((data) => {
                if(data.status === 200){
                    this.setState({
                        data: data.data.data[0],
                        loading: false
                    })
                }
            });
        })
    }

    checkout = (e) => {
        this.setState({
            loading: true
        }, () => {
            this.props.dispatch(APIService('POST', 'GetPaymentData?query=getuserdata @ID='+Configuration.USER_ID)).then((data) => {
                this.setState({
                    loading: false
                })
                if(data.status === 200){
                    this.props.dispatch(TokenService("getToken", "auth_user")).then((token) => {
                        let payment = {
                            name: data.data.data[0].USERNAME,
                            email: data.data.data[0].Email,
                            company: data.data.data[0].ORGANIZATION,
                            amount: token["PLAN PRICE"],
                            category: token["ACTIVE PLAN"],
                            planId: token["PLANID"] 
                        }
                        sessionStorage.setItem('transactionDetails', JSON.stringify(payment));
                        this.props.history.push('/checkout');
                    })
                }
            }).catch((err) => {
                this.setState({
                    loading: false
                })
                console.log(err);
            })
        })
    }

    render() {
        return (
            <div className="content-wrapper">
                <div className='loader' style={{ display: this.state.loading ? "block" : "none" }}>
                    <div className='loader-item'>
                        <Spin />
                    </div>
                </div>
                <PageTitle title={"Dashboard"} />
                <section className="content" style={{padding:'20px'}}>
                    {/* <div className="container-fluid"> */}
                        
                        <div className="row">
                            <div className="col-lg-3 col-6">
                                <div className="small-box bg-aqua">
                                    <div className="inner">
                                        <h3 style={{color:'white'}}>{this.state.data.USERCATEGORY}</h3>
                                        <p>Current Category</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-award"></i>
                                    </div>
                                    <a href="/#/Category" className="small-box-footer">Change category</a>
                                </div>
                            </div>
                            
                            <div className="col-lg-3 col-6">
                                
                                <div className="small-box bg-green">
                                    <div className="inner">
                                        <h3 style={{color:'white'}}>{this.state.data.CREDITREMAINING || 0}</h3>
                                        <p>Credit Remaining</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-wallet"></i>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-lg-3 col-6">
        
                                <div className="small-box bg-yellow">
                                    <div className="inner" style={{color:'white'}}>
                                        <h3 style={{color:'white'}}>{this.state.data.SKU || 0}</h3>
        
                                        <p>Number of SKU</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-barcode"></i>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-lg-3 col-6">
                                <div className="small-box bg-red">
                                    <div className="inner">
                                        <h3 style={{color:'white'}}>{this.state.data.TEMPLATE || 0}</h3>
        
                                        <p>Number of Template</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fas fa-copy"></i>
                                    </div>
                                </div>
                            </div>
                        
                        </div>
        
                        <div className="row">

                            <div className="col-md-6 col-sm-6 col-12">
                                <div className="info-box">
                                    <span className="info-box-icon bg-aqua"><i className="far fa-envelope"></i></span>

                                    <div className="info-box-content">
                                        <span className="info-box-number">Count of Rejection Reason</span>
                                        {/* <span className="info-box-number">90<small>%</small></span> */}
                                    </div>
                                    <div style={{display:'flex', flexDirection:'column', lineHeight:'120%', padding:"0 10px", justifyContent:"center"}}>
                                        <span className="info-box-number" style={{fontSize:'34px'}}>
                                            {this.state.data ? (this.state.data.REJECTIONCOUNT || "0") : '0'}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-12">
                                <div className="info-box">
                                    <span className="info-box-icon bg-green"><i className="fas fa-sync"></i></span>
        
                                    <div className="info-box-content">
                                        <span className="info-box-number">Count of Import/Export</span>
                                    </div>
                                    <div style={{display:'flex', flexDirection:'column', lineHeight:'120%', padding:"0 10px", justifyContent:"center"}}>
                                        <span className="info-box-number" style={{fontSize:'34px'}}>
                                            {this.state.data ? (this.state.data.IMPORT || "0")+'/'+(this.state.data.EXPORT || "0") : '0'}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-sm-6 col-12">
                                <div className="info-box">
                                    <span className="info-box-icon bg-red"><i className="fas fa-clock"></i></span>
        
                                    <div className="info-box-content">
                                        <span className="info-box-number">Recharge within</span>
                                    </div>
                                    <div style={{display:'flex', flexDirection:'column', lineHeight:'120%', padding:"0 10px", justifyContent:"center"}}>
                                        <span className="info-box-number" style={{fontSize:'34px'}}>
                                            24<sup style={{fontSize: "16px"}}> days</sup>
                                        </span>
                                    </div>
                                    <div style={{position:'absolute', marginTop:'-27px', right:'0px', padding:"0 10px", justifyContent:"center"}}>
                                        <button className="elevation-1" onClick={this.checkout} style={{backgroundColor:'#007bff', height:'40px', color:'white', border:'0px', paddingLeft:'10px', paddingRight:'10px'}}>Recharge Now</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6 col-12">
                                <div className="info-box">
                                    <span className="info-box-icon bg-yellow"><i style={{color:'white'}} className="fas fa-calendar-check"></i></span>
        
                                    <div className="info-box-content">
                                        <span className="info-box-number">Joined on</span>
                                    </div>
                                    <div style={{display:'flex', flexDirection:'column', lineHeight:'120%', padding:"0 10px", justifyContent:"center"}}>
                                        <span className="info-box-number" style={{fontSize:'34px'}}>
                                        {this.state.data.JOINDATE}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
        
                </section>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: () => {
            dispatch({
                type: 'loginUser'
            });
        },
        logoutUser: () => {
            dispatch({
                type: 'logoutUser'
            });
        },
        dispatch
    }
}

export default connect(null, mapDispatchToProps)(withRouter(DashboardDetail));