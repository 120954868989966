import React, { useState } from 'react';
import { setUserSession, Configuration, getDataTable } from './Configuration';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { Height, Style } from '@material-ui/icons';

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);
  const handleChange = e => {
    setValue(e.target.value);
  };
  return {
    value,
    onChange: handleChange
  };
};

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: '#2196f3',
    color: '#fff',
    width: '100%',
    margin: theme.spacing(3, 0, 2),
    border: 'none', // Remove border
  },
  form: {
    width: '200%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginRight: 'auto',
  },
}));

function ForgotPassword(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const email = useFormInput('');
  const otp = useFormInput('');
  const newPassword = useFormInput('');
  const cNewPassword = useFormInput('');
  const [error, setError] = useState(null);
  const [step, setStep] = useState(1);
  const [sentOTP, setSentOTP] = useState('');
  const [userId, setUserId] = useState('');
  const handleGoBack = () => {
    props.onGoBack();
  };


  const handleSendOTP = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null); // Clear any previous error

    try {
      const que = await getDataTable("select userId from Users where Email = '" + email.value + "'");
      setUserId(que[0].userId)
      if (que.length > 0) {
        const response = await fetch("https://api.blumangoes.ai:6143/reset-password", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          },
          body: `email=${email.value}`
        });

        if (response.ok) {
          const data = await response.text();
          setSentOTP(data); // Store the sent OTP value
          setStep(2);
        } else {
          throw new Error("Failed to send OTP");
        }
      } else {
        setError("Invalid Email Or Email is not registered with us");
      }
    } catch (error) {
      setError("Invalid Email Or Email is not registered with us");
    } finally {
      setLoading(false);
    }
  };


  const handleVerifyOTP = () => {
    setError(null); 
    if (otp.value == sentOTP) {
      setStep(3);
    } else {
      setError('Invalid OTP');
    }
  };

  const handleResetPassword = async () => {
    setError(null); 
    setLoading(true);
    var requestOptions = { method: 'POST' };
    let rs1 = await fetch("https://api.blumangoes.ai:6143/encrypt?password=" + newPassword.value, requestOptions);
    let psswrd = await rs1.text();
    let que = " UPDATE Users SET pswrd = '" + psswrd + "', logdate = getdate() OUTPUT inserted.userId WHERE (userId = " + userId + ")";
    await getDataTable(que);
  
    // Check if the password update was successful
    const updatedData = await getDataTable("select userId from Users where userId = " + userId + " and pswrd = '" + psswrd + "'");
    if (updatedData.length > 0) {
      setStep(4);
    } else {
      setError("Failed to update password");
    }
  
    setLoading(false);
  };
  
  
  return (
    <div className={classes.container}>
      <h2>Forgot Password</h2>
      {step === 1 && (
        <form className={classes.container}>
          <TextField className={classes.form}
            variant="outlined"
            required
            fullWidth
            type="email"
            placeholder="Email"
            {...email}
            autoComplete="off"
            name="email"
            autoFocus />
          <div style={{ display: 'flex', gap: '8px' }}>
            <button
              style={{height: '30px', width: '200px'}} variant="contained" onClick={handleSendOTP} className={classes.button} fullWidth>
              {loading ? 'Sending OTP...' : 'Send OTP'}
            </button>
            <button style={{height: '30px'}} variant="contained" onClick={handleGoBack} className={classes.button} >
              Go Back
            </button>
          </div>
        </form>
      )}
      {step === 2 && (
        <form>
          <input style={{height: '40px'}} className={classes.form} type="text" placeholder="OTP" {...otp} required />
          <div style={{ display: 'flex', gap: '8px' }}>
          <button style={{height: '30px', width: '200px'}} variant="contained" className={classes.button} onClick={handleVerifyOTP}>Verify OTP</button>
          <button style={{height: '30px'}} variant="contained" className={classes.button} onClick={handleGoBack} color="primary">
            Go Back
          </button>
          </div>
        </form>
      )}
      {step === 3 && (
        <form>
          <input style={{height: '50px'}} className={classes.form} type="password" placeholder="New Password" {...newPassword} required fullWidth/>
          <input style={{height: '50px'}} className={classes.form} type="password" placeholder="Confirm New Password" {...cNewPassword} required fullWidth/>
          <button style={{height: '30px'}} variant="contained" className={classes.button} onClick={handleResetPassword}>Reset Password</button>
        </form>
      )}
      {step === 4 && (
        <div>
          <h3>Password Reset Successful</h3>
          <p>Your password has been reset successfully.</p>
          <button style={{height: '30px'}} variant="contained" className={classes.button} onClick={handleGoBack} color="primary">
            Back To LogIn Page
          </button>
        </div>
      )}
      {error && <p>Error: {error}</p>}
    </div>
  );
}
export default ForgotPassword;
