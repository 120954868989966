import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { APIService } from "../../../Config/action/apiAction";
import Swal from "sweetalert2";
import Alert from "@material-ui/lab/Alert";
import { withRouter } from "react-router";
import PageTitle from "../../Common/pageTitle/index";
import { TokenService } from "../../../Config/action/tokenAction";
import { Spin } from "antd";

function ChangePasswordDetail(props) {
  const [error, setError] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [state, setState] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [loading, setLoading] = useState(false);

  const [globalError, setGlobalError] = useState("");

  const inputHandler = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  const showAlert = () => {
    Swal.fire({
      title: "Password updated successfully",
      text: "",
      icon: "success",
      showCancelButton: false,
      showConfirmButton: false,
      timer: 2000,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (
      state.oldPassword &&
      state.newPassword &&
      state.confirmPassword &&
      state.newPassword === state.confirmPassword
    ) {
      setError({
        ...error,
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
      setGlobalError("");
      setLoading(true);
      props.dispatch(TokenService("getToken", "auth_user")).then((token) => {
        let obj = {
          oldpassword: state.oldPassword,
          newpassword: state.newPassword,
          ID: token.ID,
        };
        props
          .dispatch(
            APIService("POST", "GetPaymentData?query=CHANGE_PASSWORD", obj)
          )
          .then((data) => {
            setLoading(false);
            if (data.data.data === "Invalid userid and password") {
              setGlobalError("Old password is incorrect");
            } else {
              setState({
                oldPassword: "",
                newPassword: "",
                confirmPassword: "",
              });
              showAlert();
              localStorage.setItem("notify_user", true);
              props.history.push("/");
            }
          })
          .catch((e) => {
            setLoading(false);
            console.log(e);
          });
      });
    } else {
      setError({
        ...error,
        oldPassword: state.oldPassword ? "" : "Old password is required",
        newPassword: state.newPassword ? "" : "New password is required",
        confirmPassword: state.confirmPassword
          ? state.newPassword
            ? state.newPassword != state.confirmPassword
              ? "Confirm password does not match"
              : ""
            : ""
          : "Confirm password is required",
      });
    }
  };

  return (
    <div className="content-wrapper">
      <div className="loader" style={{ display: loading ? "block" : "none" }}>
        <div className="loader-item">
          <Spin />
        </div>
      </div>
      <section className="content" style={{ background: "white" }}>
        <div
          id="ctl00_CPHMaincontent_divclient"
          className="box box-success"
          style={{ marginTop: "-13px" }}
        >
          <div className="box-header with-border">
            <h3 className="box-title">
              Change Password
              {/* <sup>
                (<span className="vcode">*</span> Fields are mandatory)
              </sup> */}
              <span
                id="ctl00_CPHMaincontent_DivOrdNo"
                style={{ display: "inline" }}
              ></span>
            </h3>
          </div>
          <div
            className="box-body"
            id="orderDetails"
            style={{ display: "block" }}
          >
            <div className="row">
              <div
                className="d-flex inputClassHandler"
                style={{ marginTop: "10px" }}
              >
                <div className="form-group">
                  <label for="inputEmail3">Old Password</label>
                </div>
                <div className="form-group" style={{ marginLeft: "37px" }}>
                  <input
                    type="password"
                    className="form-control"
                    // value={state.oldPassword}
                    onChange={(e) =>
                      inputHandler("oldPassword", e.currentTarget.value)
                    }
                    id="inputEmail3"
                    placeholder="Old Password"
                  />
                  {error.oldPassword ? (
                    <small style={{ color: "red", margin: "7px" }}>
                      {error.oldPassword}
                    </small>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className="d-flex inputClassHandler"
                style={{ marginTop: "10px" }}
              >
                <div className="form-group">
                  <label for="inputEmail3">New Password</label>
                </div>
                <div className="form-group" style={{ marginLeft: "33px" }}>
                  <input
                    type="password"
                    className="form-control"
                    // value={state.newPassword}
                    onChange={(e) =>
                      inputHandler("newPassword", e.currentTarget.value)
                    }
                    id="inputPassword3"
                    placeholder="New Password"
                  />
                  {error.newPassword ? (
                    <small style={{ color: "red", margin: "7px" }}>
                      {error.newPassword}
                    </small>
                  ) : null}
                </div>
              </div>

              {/* <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                <div className="form-group">
                  <button
                    // onClick={(e) => addPlan(e)}
                    className="btn btn-primary"
                  >
                    Create
                  </button>
                </div>
              </div> */}
            </div>
            <div className="row">
              <div
                className="d-flex inputClassHandler"
                style={{ marginTop: "10px" }}
              >
                <div className="form-group">
                  <label for="inputEmail3">Confirm Password</label>
                </div>
                <div className="form-group" style={{ marginLeft: "12px" }}>
                  <input
                    type="password"
                    className="form-control"
                    id="inputPassword3"
                    value={state.confirmPassword}
                    onChange={(e) =>
                      inputHandler("confirmPassword", e.currentTarget.value)
                    }
                    placeholder="Confirm Password"
                  />
                  {error.confirmPassword ? (
                    <small style={{ color: "red", margin: "7px" }}>
                      {error.confirmPassword}
                    </small>
                  ) : null}
                </div>
              </div>
              {globalError ? (
                <div className="form-group row">
                  <div className="col-sm-3"></div>
                  <div className="col-sm-8">
                    <Alert
                      severity="error"
                      style={{ width: "100%", fontSize: "14px" }}
                    >
                      {globalError}
                    </Alert>
                  </div>
                </div>
              ) : null}

              <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                <div className="form-group">
                  <button onClick={onSubmit} className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>
      </section>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: () => {
      dispatch({
        type: "loginUser",
      });
    },
    logoutUser: () => {
      dispatch({
        type: "logoutUser",
      });
    },
    dispatch,
  };
};

export default connect(
  null,
  mapDispatchToProps
)(withRouter(ChangePasswordDetail));
