import React, { Component } from "react";
import { Configuration, getDataTable, MandatoryFormFields, GetDropdownDynamicNew, json2xlsx } from "../Configuration";
import Table from "../Actions/Controls/Table/AntDTable";
import AntD from '../Actions/Controls/Table/AntDTable'
import "../ORDER/OrderMaster/style.css";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import AutoCompleteInput from "../Actions/Controls/AutoComplete/autoComplete";
import AutoCompleteCascad from "../Actions/Controls/AutoComplete/autoComplete.cascad";
import { Spin } from 'antd';

export default class frmlotMasternew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginId: 0,
            loading: false,
            productList: [],
            orgid: "",
            Catid: 0,
            VName1: "",
            List: [],
            selectedrows: [],
            RSDATA: [],
            ResData: [],
            Type: "0"
        };
    }

    async componentDidMount() {
        let rs = await getDataTable("select orgid from users where userid = " + Configuration.USER_ID)
        await this.setState({
            loginId: Configuration.USER_ID,
            orgid: rs[0].orgid
        });
    }

    async getdata() {
        let MandatoryArray = [
            { "Block Name": this.state.Catid },
            { "Orgnization Name": this.state.orgid },
            { "Show Pending": this.state.Type}
          ];
          let check = MandatoryFormFields(MandatoryArray);
          if (check == false) {
            return false;
          }
        this.setState({ loading: true })
        let Que = "EXEC GetRelationShipNameVcr @blockid =" + this.state.Catid + ", @orgid =" + this.state.orgid + ", @value = '" + this.state.Type + "'"
        let rs = await getDataTable(Que)
        debugger
        if (rs.length > 0) {
            this.setState({
                productList: rs, loading: false
            })
        }
        else {
            this.setState({ loading: false })
            Swal.fire({
                position: 'top-end',
                icon: 'info',
                title: 'No Data Found',
                showConfirmButton: false,
                timer: 1500
            })
            return;
        }
    }

    myChangeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    onAfterSelect(selectedOptions, id, name, index = 0) {
        if (selectedOptions.length > 0) {
            this.setState({
                [id]: selectedOptions[0].DisplayId,
                [name]: selectedOptions[0].DisplayName
            })
        }
        else {
            this.setState({
                [id]: '',
                [name]: ''
            })
        }
    }

    async selectedDataFN(e) {
        if (e.length > 0) {
            this.setState({
                selectedrows: e,
                Catid: e.Catid
            })
        }
        else {
            this.setState({ selectedrows: [] })
        }
    }


    async deleteRow(e) {
        if (this.state.loginId == 1) {
            let rst = window.confirm('Are you sure you want to delete?')
            if (!rst) {
                return false;
            }
            let rid = e["#RID"]
            this.setState({ loading: true })
            let Que = "EXEC DeleteRelationShipVcr @rid=" + rid
            let rs = await getDataTable(Que)
            debugger
            if (rs) {
                this.setState({ loading: false })
                Swal.fire({
                    position: 'top-end',
                    icon: 'info',
                    title: 'Relationship Deleted SuccessFully',
                    showConfirmButton: false,
                    timer: 2500
                })
                await this.getdata()
            }
        }
    }

    async downloadRow(e) {
        const rid = this.state.productList.map(item => item["#RID"]);
        this.setState({ loading: true })
        let que = "EXEC GetRelationShipDataVcr @rid =  " + e["#RID"]
        let rs = await getDataTable(que)

        if (rs.length > 0) {
            this.setState({ loading: true })
            await json2xlsx(rs, e["RelationshipName"])
            this.setState({ loading: false })
        }
        else {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Relationship cannot be exported.',
                showConfirmButton: false,
                timer: 1500
            })
        }
    }


    render() {
        return (
            <div className="content-wrapper">
                <div className='loader' style={{ display: this.state.loading ? "block" : "none" }}>
                    <div className='loader-item'>
                        <Spin />
                    </div>
                </div>
                <section className="content">
                    <div id="ctl00_CPHMaincontent_divclient" className="box box-success" style={{ marginTop: '-13px' }}>
                        <div className="box-header with-border">
                            <h3 className="box-title">RelationShip Details
                                <span id="ctl00_CPHMaincontent_DivOrdNo" style={{ display: 'inline' }}>
                                </span>
                            </h3>
                        </div>
                        <div className="box-body" id="LotMaster" style={{ display: 'block' }}>
                            <div className="row">
                                <div className='col-xs-12 col-sm-12 col-md-12 margintop'>
                                    <div className='row'>
                                        <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                            <label>Select Block</label>
                                            <span className="vcode">*</span>
                                            <AutoCompleteInput
                                                id="catid"
                                                frmNm="FRMCATPRODUCTMASTER"
                                                quryNm="FILLCAT"
                                                db="IMAGEDB"
                                                placeholder="Please Select Block"
                                                onAfterSelect={(e) =>
                                                    this.onAfterSelect(e, "Catid", "catname")
                                                }
                                                isValid={this.state.isValid}
                                            ></AutoCompleteInput>
                                        </div>
                                        <div className="col-xs-6 col-sm-6 col-md-2 margintop" style={{ marginTop: '2px' }}>
                                            <label>Select Organization</label><span className="vcode">*</span>
                                            <AutoCompleteInput
                                                id="articleid"
                                                frmNm="FRMUSER"
                                                quryNm="FILLORGANIZATION"
                                                db="IMAGEDB"
                                                placeholder="Please Select Organization"
                                                onAfterSelect={(e) => this.onAfterSelect(e, "orgid", "orgname")}
                                                isValid={this.state.isValid}
                                            ></AutoCompleteInput>
                                        </div>
                                        <div className='col-xs-6 col-sm-6 col-md-2'>
                                            <label>Show Pending</label>
                                            <span className="vcode">*</span>
                                            <div className="form-group">
                                                <select name="Type" onChange={this.myChangeHandler} id="" tabIndex={1} className="form-control"  >
                                                    <option selected="true" disabled="disabled"> No Selection </option>
                                                    <option value="YES">YES</option>
                                                    <option value="NO">NO</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-md-2 col-xs-6 col-sm-1 margintop'  >
                  <label>&nbsp;</label>
                  <div className='form-group'>
                    <button className='btn btn-primary' onClick={this.getdata.bind(this)}>Show Data</button>
                  </div>
                </div>
                                        <div>
                                            <div className="col-xs-12 col-sm-12 col-md-12 margintop">
                                                {this.state.productList != undefined && this.state.productList.length > 0 &&
                                                    <AntD
                                                        data={[...this.state.productList]}
                                                        Delete="true"
                                                        DeleteFn={(e) => this.deleteRow(e)}
                                                        downloadRow="true"
                                                        downloadRowFn={(e) => this.downloadRow(e)}
                                                        exportXL="true">
                                                    </AntD>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }

}