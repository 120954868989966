import React, { Component } from 'react';
import AutoCompletemulti from '../Actions/Controls/AutoComplete/autoCompleteMultiple';
import { Configuration, getDataTable, MandatoryFormFields } from '../Configuration';
import Table from '../Actions/Controls/Table/AntDTable'
import '../ORDER/OrderMaster/style.css'
import { Modal, Spin } from 'antd';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'


export default class frmuser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginId: 0,
            OrgData: [],
            orgid: [],
            usrname: '',
            cntno: '',
            Pmail: '',
            Smail: '',
            isValidEmail: true,
            fpass: '',
            cpass: '',
            isValid: false,
            errors: {
                uppercase: false,
                lowercase: false,
                digit: false,
                punctuation: false,
                length: false,
            },
            usertype: 'normal',
            loading: false,
            isAgent: "0",
            userid: ''
        };

    }

    async componentDidMount() {

        await this.setState({
            loginId: Configuration.USER_ID,
        });
        await this.getdata();

    }

    handleEmailChange = (e) => {
        const Pmail = e.target.value;
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValidEmail = emailPattern.test(Pmail);
        this.setState({ Pmail, isValidEmail });
      };

    handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        const validationErrors = this.validatePassword(newPassword);
        this.setState({
            fpass: newPassword,
            isValid: Object.values(validationErrors).every((error) => !error),
            errors: validationErrors,
        });
    };

    validatePassword = (password) => {
        const errors = {
            uppercase: !/(?=.*[A-Z])/.test(password),
            lowercase: !/(?=.*[a-z])/.test(password),
            digit: !/(?=.*\d)/.test(password),
            punctuation: !/(?=.[@$!%?&])/.test(password),
            length: !(password.length >= 8),
        };
        return errors;
    };

    async getdata() {
        this.setState({ loading: true })
        let Que = "EXEC ShowUsers"
        let rs = await getDataTable(Que)
        this.setState({ OrgData: rs, loading: false })
    }

    myChangeHandler = (event) => {

        let nam = event.target.name;
        let val = event.target.value;
        this.setState({ [nam]: val });

        this.forceUpdate();
    }


    async onAutoCOmpletMultiPleSelect(selectedOptions, name) {
        if (selectedOptions.length > 0) {
            this.setState({
                [name]: selectedOptions
            });
        }
        else {
            this.setState({
                [name]: ""
            })
        }
    }

    async UpdateUser() {
        if (this.state.orgid.length > 1 && this.state.isAgent === "0") {
            Swal.fire({
                icon: 'info',
                title: "NORMAL USER CAN'T BELONG TO MULTIPLE ORGNIZATION",
                showConfirmButton: false,
                timer: 1500
            })
            return;
        }
        this.setState({ loading: true })
        var requestOptions = { method: 'POST' };
        let rs1 = await fetch("https://api.blumangoes.ai:6143/encrypt?password=" + this.state.fpass, requestOptions)
        let psswrd = await rs1.text();

        let que = " UPDATE Users SET username = '" + this.state.usrname + "', OrgID = " + this.state.orgid[0]['DisplayId'] + ", Email = '" + this.state.mail + "', "
        que += " Phone = '" + this.state.cntno + "', Active = 1 , IsAgent = " + this.state.isAgent + ", logdate = getdate() , pswrd = '" + psswrd + "'"
        que += " OUTPUT inserted.userId WHERE (userId = " + this.state.userid + ")"
        const response = await getDataTable(que)
        if (response.length > 0) {
            que = ""
            que += " delete from AgentOrganizationMapping where userID = " + response[0]['userId']
            await getDataTable(que)
            for (let j = 0; j < this.state.orgid.length; j++) {
                const ele = this.state.orgid[j];
                let que = "Insert Into AgentOrganizationMapping(userID, orgID, loginID, logdate) output inserted.tblid Values "
                que += " (" + response[0]['userId'] + "," + ele['DisplayId'] + "," + this.state.loginId + ",getdate())"
                await getDataTable(que)
            }
            Swal.fire({
                icon: 'success',
                title: 'SUCCESSFULLY UPDATED',
                showConfirmButton: false,
                timer: 1500
            })

            // var ddt = document.getElementsByTagName('input')
            // for (let i = 0; i < ddt.length; i++) {
            //     if (ddt[i].className === 'form-control') {
            //         ddt[i].value = '';
            //     }
            // }
            this.setState({
                usrname: '',
                cntno: '',
                mail: '',
                fpass: '',
                cpass: '',
                orgid: [],
                userId: '',
                loading: false
            })
        }
        else {
            Swal.fire({
                position: 'top-end',
                icon: 'warning',
                title: 'Not Saved',
                showConfirmButton: false,
                timer: 1500
            })
            this.setState({
                loading: false
            })
        }
        await this.getdata(this);
        this.forceUpdate();
        return;
    }

    async SaveUser() {
        // Password validation condition
        const hasUpperCase = /[A-Z]/.test(this.state.fpass);
        const hasLowerCase = /[a-z]/.test(this.state.fpass);
        const hasDigit = /\d/.test(this.state.fpass);
        const hasPunctuation = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/.test(this.state.fpass);
        const isLengthValid = this.state.fpass.length >= 8;
        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.state.Pmail)

        if (!(hasUpperCase)) {
            alert('Password must contain at least one uppercase letter');
            return;
        } else if (!(hasLowerCase)) {
            alert('Password must contain at least one lowercase letter');
            return;
        } else if (!(hasDigit)) {
            alert('Password must contain at least one digit');
            return;
        } else if (!(hasPunctuation)) {
            alert('Password must contain at least one punctuation character');
            return;
        } else if (!(isLengthValid)) {
            alert('Password must be at least eight characters long');
            return;
        } else if (!(isValidEmail)) {
            alert('Please enter a valid email');
            return;
        }
        let MandatoryArray = [
            { "Username": this.state.usrname },
            { "Contact no": this.state.cntno },
            { "EMAIL": this.state.Pmail },
            { "Organization": this.state.orgid },
            { "Password": this.state.fpass },
            { "Confirm Password": this.state.cpass }
        ]
        let check = MandatoryFormFields(MandatoryArray);
        if (check === false) {
            return false;
        }
        if (this.state.fpass !== this.state.cpass) {
            Swal.fire({
                icon: 'info',
                title: 'PASSWORD NOT MATCH PLEASE CHECK',
                showConfirmButton: false,
                timer: 1500
            })
            return;
        }
        if (this.state.userid > 0) {
            await this.UpdateUser()
            return;
        }

        let Que = "Select * from Users where username = '" + this.state.usrname + "' and email = '" + this.state.Pmail + "'"
        let rs = await getDataTable(Que)
        if (rs.length > 0) {
            Swal.fire({
                icon: 'info',
                title: 'ALREADY EXIST',
                showConfirmButton: false,
                timer: 1500
            })
            return;
        }
        if (this.state.orgid.length > 1 && this.state.isAgent === "0") {
            Swal.fire({
                icon: 'info',
                title: "NORMAL USER CAN'T BELONG TO MULTIPLE ORGNIZATION",
                showConfirmButton: false,
                timer: 1500
            })
            return;
        }
        this.setState({ loading: true })
        var requestOptions = { method: 'POST' };
        let rs1 = await fetch("https://api.blumangoes.ai:6143/encrypt?password=" + this.state.fpass, requestOptions)
        let psswrd = await rs1.text();

        let Query = "INSERT INTO Users   (OrgID, username, Email, Phone, Active, logdate, pswrd,IsAgent) output inserted.userId VALUES "
        Query += "(" + this.state.orgid[0]['DisplayId'] + ",'" + this.state.usrname + "','" + this.state.Pmail + "','" + this.state.cntno + "',1,getdate(),'" + psswrd + "'," + this.state.isAgent + ")"
        const response = await getDataTable(Query)
        if (response.length > 0) {
            for (let j = 0; j < this.state.orgid.length; j++) {
                const ele = this.state.orgid[j];
                let que = "Insert Into AgentOrganizationMapping(userID, orgID, loginID, logdate) output inserted.tblid Values "
                que += " (" + response[0]['userId'] + "," + ele['DisplayId'] + "," + this.state.loginId + ",getdate())"
                await getDataTable(que)
            }
            Swal.fire({
                icon: 'success',
                title: 'SUCCESSFULLY SAVED',
                showConfirmButton: false,
                timer: 1500
            })

            // var ddt = document.getElementsByTagName('input')
            // for (let i = 0; i < ddt.length; i++) {
            //     if (ddt[i].className === 'form-control') {
            //         ddt[i].value = '';
            //     }
            // }
            this.setState({
                usrname: '',
                cntno: '',
                mail: '',
                fpass: '',
                cpass: '',
                orgid: [],
                userId: '',
                loading: false
            })

        }
        else {
            Swal.fire({
                position: 'top-end',
                icon: 'warning',
                title: 'Not Saved',
                showConfirmButton: false,
                timer: 1500
            })
            this.setState({ loading: false })
        }
        // console.log(OrderL = dataist);
        await this.getdata(this);
        this.forceUpdate();

    }

    myFunction(e, id) {

        var x = document.getElementById(e);
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
    }

    render() {
        const { fpass, isValid, errors, Pmail, isValidEmail } = this.state;
        return (
            <div className="content-wrapper">
                <div className='loader' style={{ display: this.state.loading ? "block" : "none" }}>
                    <div className='loader-item'>
                        <Spin />
                    </div>
                </div>
                <section className="content">
                    <div id="ctl00_CPHMaincontent_divclient" className="box box-success" style={{ marginTop: '-13px' }}>
                        <div className="box-header with-border">
                            <h3 className="box-title">User Master<sup>(<span className="vcode">*</span> Fields are mandatory)</sup>
                                <span id="ctl00_CPHMaincontent_DivOrdNo" style={{ display: 'inline' }}>
                                </span>
                            </h3>
                        </div>

                        <div className="box-body" id="orderDetails" style={{ display: 'block' }}>
                            <div className="row">
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                    <label>User Name</label><span className="vcode">*</span>
                                    <div className="form-group">
                                        <input name="usrname" id="usrname" defaultValue={this.state.usrname} value={this.state.usrname} onChange={this.myChangeHandler} placeholder="Enter User Name" tabIndex={1} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                    <label>Contact No.</label><span className="vcode">*</span>
                                    <div className="form-group">
                                        <input name="cntno" id="cntno" type="tel" defaultValue={this.state.cntno} value={this.state.cntno} onChange={this.myChangeHandler} placeholder="Enter Contact No." tabIndex={1} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-3 margintop">
                                    <label>Primary Email</label><span classNamer="vcode">*</span>
                                    <div className="form-group">
                                        <input name="Pmail" id="Pmail" type="text" defaultValue={this.state.Pmail} value={Pmail} onChange={this.handleEmailChange} placeholder="Enter Mail Address" tabIndex={1} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-3 margintop">
                                    <label>Secondary Email</label><span classNamer="vcode">*</span>
                                    <div className="form-group">
                                        <input name="Smail" id="Smail" type="text" defaultValue={this.state.Smail} value={this.state.Smail} onChange={this.myChangeHandler} placeholder="Enter Mail Address" tabIndex={1} className="form-control" />
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                    <label>Type</label><span className="vcode">*</span>
                                    <div className="form-group">
                                        <select name="isAgent" onChange={this.myChangeHandler} value={this.state.isAgent} id="val" tabIndex={1} className="form-control">
                                            <option selected value="0">Normal</option>
                                            <option value="1">Agent</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-3 margintop" style={{ marginTop: '2px' }}>
                                    <label>Organization</label><span className="vcode">*</span>
                                    <AutoCompletemulti
                                        id="articleid"
                                        frmNm="FRMUSER"
                                        quryNm="FILLORGANIZATION"
                                        db="IMAGEDB"
                                        placeholder="Please Select Organization"
                                        onAfterSelect={(e) => this.onAutoCOmpletMultiPleSelect(e, "orgid")}
                                        isValid={this.state.isValid}
                                        defauldVal={this.state.orgid}
                                    ></AutoCompletemulti>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-3 margintop">
                                    <label>Password</label><span className='vcode'>*<i class="fa fa-eye" id='seepass' onClick={this.myFunction.bind(this, 'fpass')} aria-hidden="true"></i></span>
                                    <div className="form-group">
                                        <input
                                            name="fpass"
                                            id="fpass"
                                            type="password"
                                            defaultValue={this.state.cpass}
                                            value={fpass}
                                            onChange={this.handlePasswordChange}
                                            onClick={() => this.setState({ errors: {} })}
                                            placeholder="Enter password"
                                            tabIndex={1}
                                            className="form-control"
                                        />
                                        {errors.uppercase && <span style={{ color: 'red' }}>Must contain at least one uppercase letter </span>}<br />
                                        {errors.lowercase && <span style={{ color: 'red' }}>Must contain at least one lowercase letter </span>}<br />
                                        {errors.digit && <span style={{ color: 'red' }}>Must contain at least one digit </span>}<br />
                                        {errors.punctuation && <span style={{ color: 'red' }}>Must contain at least one punctuation character </span>}<br />
                                        {errors.length && <span style={{ color: 'red' }}>Must be at least 8 characters long</span>}<br />
                                        {!isValidEmail && <span style={{ color: 'red' }}>Please Enter a Valid Email Address</span>}<br />
                                        {isValid && <span style={{ color: 'green' }}>Valid password</span>}   
                                                                    </div>
                                </div>

                                <div className="col-xs-6 col-sm-6 col-md-3 margintop">
                                    <label>Confirm Password</label><span className='vcode'>*<i class="fa fa-eye" id='seecpass' onClick={this.myFunction.bind(this, 'cpass')} aria-hidden="true"></i></span>
                                    <div className="form-group">
                                        <input name="cpass" id="cpass" type="password" defaultValue={this.state.cpass} value={this.state.cpass} onChange={this.myChangeHandler} placeholder="Enter Password" tabIndex={1} className="form-control" />
                                    </div>
                                </div>

                                <div className="col-xs-6 col-sm-6 col-md-2 margintop">
                                    <label>&nbsp;</label>
                                    <div className="form-group">
                                        <button onClick={this.SaveUser.bind(this)} className='btn btn-primary'>Save User</button>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12 margintop">
                                    {this.state.OrgData.length > 0 && this.state.OrgData !== undefined &&
                                        <Table
                                            data={[...this.state.OrgData]}
                                            Delete="true"
                                            ShowRow={this.state.loginId === "1" ? 'true' : 'false'}
                                            EditRow='true'
                                            EditRowFn={(e) => this.EditRowFn(e)}
                                            ShowRowFn={(e) => this.getRowData(e)}
                                            DeleteFn={(e) => this.deleteRow(e)}
                                            height='63vh'
                                            id='fielddata'
                                            exportXL="true">
                                        </Table>
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }


    async EditRowFn(e) {
        if (this.state.loginId === "1" || this.state.loginId === "10" || this.state.loginId === "6") {
            let st = window.confirm("Are you sure want to Edit ?")
            if (!st) {
                return false;
            }
            let Que = "select userId as [#userid], username, Phone as cntno, Email as mail, isnull(IsAgent,0) as isAgent, pswrd, OrgID from Users where userId = " + e["#userid"]
            let rs = await getDataTable(Que)
            if (rs.length > 0) {
                this.setState({
                    usrname: rs[0].username,
                    cntno: rs[0].cntno,
                    mail: rs[0].mail,
                    isAgent: rs[0].isAgent,
                    userid: e["#userid"]
                })
                var requestOptions = { method: 'POST' };
                let rs1 = await fetch("https://api.blumangoes.ai:6143/dcrypt?password=" + rs[0].pswrd, requestOptions)
                let psswrd = await rs1.text();
                this.setState({
                    fpass: psswrd,
                    cpass: psswrd
                })
                if (rs[0].isAgent === 1) {
                    let orgque = "SELECT  aom.OrgID as DisplayId,OrgCode+'-'+OrgName as DisplayName"
                    orgque += " FROM AgentOrganizationMapping aom INNER JOIN OrganizationMaster "
                    orgque += " ON aom.orgID = OrganizationMaster.OrgID where userID = " + e["#userid"]
                    let orgRs = await getDataTable(orgque)
                    this.setState({
                        orgid: orgRs
                    })
                } else {
                    let orgque = "SELECT   OrgID as DisplayId,OrgCode+'-'+OrgName as DisplayName"
                    orgque += " FROM OrganizationMaster "
                    orgque += " where OrgID = " + rs[0].OrgID
                    let orgRs = await getDataTable(orgque)
                    this.setState({
                        orgid: orgRs
                    })
                }
            }

        }
        else {
            alert("You are not allowed to edit")
            return;
        }
    }

    async deleteRow(e) {
        console.log(e);
        if (this.state.loginId === "1" || this.state.loginId === "10" || this.state.loginId === "6") {
            let st = window.confirm("Are you sure want to delete ?")
            if (!st) {
                return false;
            }
            console.log(e);
            let Que = "update  Users set Active = 0 output inserted.userid where userId = " + e["#userid"]
            let rs = await getDataTable(Que)
            if (rs.length > 0) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'info',
                    title: 'Successfully Deleted',
                    showConfirmButton: false,
                    timer: 1500
                })
                await this.getdata()
            }
            else {
                alert("Some Error Occurred")
            }
        }
        else {
            alert("You are not allowed to delete")
            return;
        }
    }

    async getRowData(e) {
        if (this.state.loginId === "10" || this.state.loginId === "1") {
            let rs = await getDataTable("select pswrd from users where userid = " + e["#userid"])
            var requestOptions = { method: 'POST' };
            let rs1 = await fetch("https://api.blumangoes.ai:6143/dcrypt?password=" + rs[0].pswrd, requestOptions)
            let psswrd = await rs1.text();
            Modal.info({
                title: 'User Details',
                okText: 'CLOSE',
                width: '600px',
                closable: true,
                footer: null,
                bodyStyle: { maxHeight: '500px' },
                style: { padding: '0px' },
                centered: true,
                maskClosable: true,
                content: (
                    <div>
                        <ul>
                            <li><b>UserName - </b> {e.username}</li>
                            <li><b>Password - </b> {psswrd}</li>
                            <li><b>Org - </b> {e.Organization}</li>

                        </ul>
                    </div>
                )
            });
        }
        else {
            Swal.fire({
                position: 'top-end',
                icon: 'info',
                title: 'Not Allowed to Show Details',
                showConfirmButton: false,
                timer: 1500
            })
        }
    }

}
