import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import Dropbox from "dropbox";
import { Configuration } from "./Configuration";
// import { PixelbinConfig, PixelbinClient } from "@pixelbin/admin";
import { ExploreOffSharp } from "@material-ui/icons";
const url = "https://api.blumangoes.ai:6143";

// Function To Import Excel File

export async function exceltodb(files, db, loginId) {
  let res = "";
  var formdata = new FormData();
  formdata.append("file", files);
  var requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };
  await fetch(url + "/SaveFile", requestOptions)
    .then((response) => response.text())
    .then((result) => (res = result))
    .catch((error) => alert("error", error));
  let filess = res;
  if (!filess) {
    alert("Unable To Import File To server");
    return false;
  }
  var d = new Date();
  var timeInMs =
    String(d.getUTCDate()) +
    String(d.getMonth() + 1) +
    String(d.getFullYear()) +
    String(d.getHours()) +
    String(d.getMinutes()) +
    String(d.getSeconds());
  let tablename = String("X_FileImport_" + timeInMs + "_" + loginId);
  console.log(tablename);

  let Sql = "";
  Sql = Sql + " SELECT        *";
  Sql = Sql + " INTO              " + tablename;
  Sql =
    Sql +
    " FROM            OPENROWSET('Microsoft.ACE.OLEDB.12.0', 'Excel 12.0; Database=" +
    filess +
    "'";
  Sql = Sql + " , [Sheet1$]) AS derivedtbl_1";

  const url =
    Configuration.SYSTEM_SETTINGS.API_URL + "api/v1/Common/GetDynamicQuery";

  try {
    const rs = await fetch(url + "?query=" + Sql + "&db=" + db, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Origin": "*",
        "Auth-Token": Configuration.USER_TOKEN.TOKEN,
      },
    });
    let dt = await rs.json();
    if (dt.data) {
      if (dt.data.length < 1) {
        alert("File Uploaded To Server");
      }
      return tablename;
    } else {
      alert("Some error occured");
      return false;
    }
  } catch (error) {
    alert(error);
  }
}

// Function To get Image Angle
export async function GetImageAngle(file) {
  const data = new FormData();
  data.append("file", file);
  let d;
  try {
    //let response = await fetch('http://SoftServer.Corp.virolaindia.com:8000/prediction', {
    let response = await fetch(url + "/Pred", {
      method: "POST",
      body: data,
    });
    d = await response.json();
    //e.state["prediciton"] = d['pred']
    return d["pred"];
  } catch (error) {
    //alert(error)
    return "none";
  }
}

// For Get SubCategory Of Object In Image
export async function GetImageSubCategory(file) {
  var formdata = new FormData();
  formdata.append("file", file);

  var requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };

  try {
    let res = await fetch(url + "/GetSubCategory", requestOptions);
    let st = await res.json();
    if (st.prediction) {
      return st.prediction;
    } else {
      return "Reject";
    }
  } catch (error) {
    // alert(error)
    return 0;
  }
}

// For Get Area Of Object In Image
export async function GetImageArea(file) {
  var formdata = new FormData();
  formdata.append("file", file);
  var requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };
  try {
    let res = await fetch(url + "/Area", requestOptions);
    let st = await res.json();
    console.log(st.ratio);
    var result = Math.round(st.ratio);
    return result;
  } catch (error) {
    // alert(error)
    return "";
  }
}

export async function AllAI(file) {
  var formdata = new FormData();
  formdata.append("file", file);
  var requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };
  try {
    let res = await fetch(url + "/ALLAI", requestOptions);
    let st = await res.json();
    return st;
  } catch (error) {
    // alert(error)
    return { pred: "", Color: [], subcategory: "" };
  }
}

export async function ProcessAI(
  files,
  height,
  width,
  dpi,
  minarea,
  maxarea,
  maxszie,
  minsize
) {
  let formdata = new FormData();
  for (let i = 0; i < files.length; i++) {
    let ele = files[i]["file"];
    formdata.append("file", ele, ele.name);
  }
  let strs =
    "height=" +
    height +
    "&width=" +
    width +
    "&max_area_coverage=" +
    maxarea +
    "&min_area_coverage=" +
    minarea +
    "";
  strs =
    strs +
    "&max_out_size=" +
    maxszie +
    "&min_out_size=" +
    minsize +
    "&out_dpi=" +
    dpi +
    "&background_color[0]=FFFFFF&background_color[1]=BBBBBB";
  var requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };

  try {
    let res = await fetch(url + "/ProcessImage/?" + strs, requestOptions);
    let st = await res.json();
    return st;
  } catch (error) {
    alert(error);
  }
}

export async function ProcessAIColor(files) {
  let formdata = new FormData();
  for (let i = 0; i < files.length; i++) {
    let ele = files[i]["file"];
    formdata.append("file", ele, ele.name);
  }
  let strs =
    "height=1536&width=2048&max_area_coverage=0.8&min_area_coverage=0.7";
  strs =
    strs +
    "&max_out_size=300000&out_dpi=72&max_quality=100&min_quality=50&background_color[0]=FFFFFF&background_color[1]=BBBBBB";
  var requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };
  try {
    let res = await fetch(
      url + "/ProcessImageforcolor/?" + strs,
      requestOptions
    );
    let st = await res.json();
    return st;
  } catch (error) {
    alert(error);
  }
}

// Resize Image With Python Tool

export async function ResizeImage(file, h, w) {
  var formdata = new FormData();
  formdata.append("file", file);
  let bimage = "";
  var requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };
  try {
    await fetch(
      url + "/Resize?height=" + w + "&width=" + h + "",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => (bimage = result))
      .catch((error) => console.log("error", error));
    const res = await fetch(bimage);
    const b = await res.blob();
    let nfile = new File([b], file.name, { type: "image/jpeg" });
    return nfile;
  } catch (error) {
    return false;
  }
}

export async function LeatherDefects(file) {
  var formdata = new FormData();
  formdata.append("file", file);
  let bimage = "";
  var requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };
  try {
    const res = await fetch(
      "https://api.blumangoes.ai:6143" + "/Defects",
      requestOptions
    );
    const b = await res.json();
    console.log(b);
    let st = b.data;
    //const c = await st.blob();
    //let nfile = new File([st], file.name, { type: file.type });
    return st;
  } catch (error) {
    return false;
  }
}

export async function BlurDetect(file) {
  var formdata = new FormData();
  formdata.append("file", file);
  let bimage = "";
  var requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };
  try {
    let res = await fetch(
      "https://api.blumangoes.ai:6143" + "/BlurDetect",
      requestOptions
    );
    let b = await res.text();
    return b;
  } catch (error) {
    return "Not Found";
  }
}

export async function WireFrame(file) {
  var formdata = new FormData();
  formdata.append("file", file);
  let bimage = "";
  var requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };
  try {
    const res = await fetch(
      "http://192.168.5.2:8080" + "/GetFile",
      requestOptions
    );
    const b = await res.json();
    console.log(b);
    let st = b.file;
    //const c = await st.blob();
    //let nfile = new File([st], file.name, { type: file.type });
    return b;
  } catch (error) {
    return false;
  }
}

// Upload Images to Dropbox and get Link

export async function Image2Drplink(file, product, portal, ACCESS_TOKEN) {
  var dbx = new Dropbox.Dropbox({ accessToken: ACCESS_TOKEN });
  let res = "";
  let res1 = "";
  try {
    res = await dbx.filesUpload({
      path: "/" + product + "/" + portal + "/" + file.name,
      contents: file,
      mode: { ".tag": "overwrite" },
    });
    let filePath = res.path_display;
    res1 = await dbx.sharingCreateSharedLinkWithSettings({ path: filePath });
    let fileURl = res1.url;
    return fileURl;
  } catch (error) {
    if (error.error.error.shared_link_already_exists.metadata.url) {
      let url = error.error.error.shared_link_already_exists.metadata.url;
      return url;
    } else {
      Swal.fire({
        icon: "info",
        title: error.error_summary,
        showConfirmButton: false,
        timer: 1500,
      });
      return "";
    }
  }
}

// export async function Image2PixelBinlink() {
//     debugger
//     var config = new PixelbinConfig({
//         domain: "https://api.pixelbin.io",
//         apiSecret: "b71d5967-2738-4041-a0dc-af849d62f4ca",
//     });
//     const pixelbin = new PixelbinClient(config);
//     try {
//         // list the assets stored on your organization's Pixelbin Storage
//         const explorer = await pixelbin.assets.listFilesPaginator({
//             onlyFiles: true,
//             pageSize: 5,
//         });

//         while (explorer.hasNext()) {
//             const { items, page } = await explorer.next();
//             console.log(page.current); // 1
//             console.log(page.hasNext); // false
//             console.log(page.size); // 3
//             console.log(items.length); // 3
//         }
//     } catch (err) {
//         console.log(err);
//     }
// }
