import { Modal, Button } from 'antd';

import React, { Component } from 'react';
import AutoCompleteInput from '../../src/Actions/Controls/AutoComplete/autoComplete';
import AutoCompletemulti from '../../src/Actions/Controls/AutoComplete/autoCompleteMultiple';
import AutoCompleteCascad from '../../src/Actions/Controls/AutoComplete/autoComplete.cascad';
import '../ORDER/OrderMaster/style.css'
import { Configuration,MandatoryFormFields,getDataTable } from '../Configuration';
import Swal, { swal } from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
let groupList = [];
export default class AttributeGroup extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loginId: 0,
            modal1Visible: false,
            modal2Visible: false,
            GroupName: "",
            GroupCode: "",
        };
    }


    async componentDidMount() {
        this.setState({
            loginId: Configuration.USER_ID,
          });
        await this.fillPortaldata(this);
    }

    async fillPortaldata() {
        let data = [];
        let Query = "select groupName, GroupCode from AttributeGroupMaster where groupdis = 0"
        data = await getDataTable(Query)
        groupList = data
        this.forceUpdate();
    }

    setModal2Visible(modal2Visible) {
        this.setState({ modal2Visible });
    }

    myChangeHandler = (event) => { 
        this.setState({ [event.target.name]: event.target.value });
        this.forceUpdate();
    }

    async SavePortal() {

        let MandatoryArray = [
            { "Group Name": this.state.GroupName }
            , { "Group Code": this.state.GroupCode }
        ]
        let check = MandatoryFormFields(MandatoryArray);
        if (check == false) {
            return false;
        }
        let data = []; 
        let Que = "Select * from AttributeGroupMaster where groupName = '" + this.state.GroupName + "'" 
        let dt = await getDataTable(Que)
        
        if (dt.length > 0) {
            Swal.fire({
                position: 'top-end',
                icon: 'info',
                title: 'Group Already Exist',
                showConfirmButton: false,
                timer: 1500
            })
            return;
        }

        let Query = " INSERT INTO AttributeGroupMaster( groupName, GroupCode, groupdis, LoginId, LogDate) output inserted.groupName VALUES ('" + this.state.GroupName + "','"+this.state.GroupCode+"'," + "0," + this.state.loginId+",cast(getdate() as date))"
        let rs = await getDataTable(Query)        
        if (rs) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Successfully Saved',
                showConfirmButton: false,
                timer: 1500
            })
        }
        else {
            Swal.fire({
                position: 'top-end',
                icon: 'warning',
                title: 'Group Not Saved',
                showConfirmButton: false,
                timer: 1500
            })
        }
        await this.fillPortaldata(this);
        this.forceUpdate();

    }

    myChangeHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({ [nam]: val });

        this.forceUpdate();
    }

    render() {
        return (
            <div>
                <button type="button" onClick={() => this.setModal2Visible(true)} className="btn btn-info">Create Attribute Group</button>

                <Modal
                    title="Create Attribute Group"
                    width={this.props.width}
                    visible={this.state.modal2Visible}
                    onOk={() => this.setModal2Visible(false)}
                    onCancel={() => this.setModal2Visible(false)}
                    destroyOnClose={true}
                    maskClosable={false}
                    footer = {null}
                >
                    <div className='row'>
                        <div className='col-xs-6 col-sm-6 col-md-3 margintop'>
                            <label>Enter Group Name</label><span className="vcode">*</span>
                            <input type='text' id='GroupName' name='GroupName' autoComplete='off' onChange={this.myChangeHandler} className="form-control" ></input>
                        </div>
                        <div className='col-xs-6 col-sm-6 col-md-3 margintop'>
                            <label>Enter Group Code</label><span className="vcode">*</span>
                            <input type='text' id='GroupCode' name='GroupCode' autoComplete='off' onChange={this.myChangeHandler} className="form-control" ></input>
                        </div>
                        <div className='col-md-1' >
                            <label>&nbsp;</label>
                            <button className='btn btn-primary' onClick={this.SavePortal.bind(this)}>Save Group</button>
                        </div>
                        <div className="col-md-12 form-group">
                            <div className="table table-hover datatable table-responsive hover" style={{ height: "200px" }}>
                                <table className="table table-border table-fixed datatable" id="tblFactoryList">
                                    <thead>
                                        <tr>
                                            <th>SN.</th>
                                            <th>GroupName</th>
                                            <th>GroupCode</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {groupList.map((fac, key) => {
                                            return (<tr key={key}>
                                                <td>&nbsp;{key + 1}</td>
                                                <td>{fac.groupName}</td>
                                                <td>{fac.GroupCode}</td>
                                            </tr>)
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}