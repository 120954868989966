import axios from 'axios';
import * as apiActions from '../reducers/apiReducer';

export const APIService = (type, url, data) => {

    let link = 'https://api.blumangoes.ai:6143/api/v1/Payment/' + url;
    let authCode = localStorage.getItem("authentication") ? JSON.parse(localStorage.getItem("authentication")).USERTOKEN : "c53fdd2cebc0552dbf994034ac9f4cca774c4ee609418a5a50954202acbf5e99366635c75518e8afb4a8c3432b79a13e4f5c6166bd7d836f0fad767edd3d1472"
    // c53fdd2cebc0552dbf994034ac9f4cca774c4ee609418a5a50954202acbf5e99366635c75518e8afb4a8c3432b79a13e4f5c6166bd7d836f0fad767edd3d1472
    let config = {
        headers: {
            "Authorization": authCode
        }
    }

    return async (dispatch) => {
        if(type === 'GET'){
            dispatch(apiActions.fetchDataRequest());
            let response;
            try{
                response = await axios.get(link, config);
                dispatch(apiActions.fetchDataComplete());
                return response;
            }
            catch(e) {
                dispatch(apiActions.fetchDataComplete());
                console.log('error', e.response);
                return e.response;
            }
        }

        else if(type === 'POST'){
            dispatch(apiActions.fetchDataRequest());
            let response;
            try{
                response = await axios.post(link, data, config);
                dispatch(apiActions.fetchDataComplete());
                return response;
            }
            catch(e) {
                dispatch(apiActions.fetchDataComplete());
                console.log('error', e.response);
                return e.response;
            }
        }

        else if(type === 'PUT'){
            dispatch(apiActions.fetchDataRequest());
            let response;
            try{
                response = await axios.put(link, data, config);
                dispatch(apiActions.fetchDataComplete());
                return response
            }
            catch(e) {
                dispatch(apiActions.fetchDataComplete());
                console.log('error', e.response);
                return e.response;
            }
        }

        else if(type === 'DYNAMIC_POST'){
            dispatch(apiActions.fetchDataRequest());
            let response;
            try{
                // let apiUrl = 'https://erp.virolaindia.com:7071/api/v2/Common/GetDynamicData';
                let apiUrl = 'https://api.blumangoes.ai:6143/api/v2/Common/GetDynamicData';
                response = await axios.post(apiUrl, data, config);
                dispatch(apiActions.fetchDataComplete());
                return response;
            }
            catch(e) {
                dispatch(apiActions.fetchDataComplete());
                console.log('error', e.response);
                return e.response;
            }
        }

        else if(type === 'DYNAMIC_NEW_POST'){
            dispatch(apiActions.fetchDataRequest());
            let response;
            try{
                let apiUrl = 'https://api.blumangoes.ai:6143/api/v2/Common/GetDynamicData';
                response = await axios.post(apiUrl, data, config);
                dispatch(apiActions.fetchDataComplete());
                return response;
            }
            catch(e) {
                dispatch(apiActions.fetchDataComplete());
                console.log('error', e.response);
                return e.response;
            }
        }

        else if(type === 'DELETE'){
            dispatch(apiActions.fetchDataRequest());
            let response;
            try{
                response = await axios.delete(link, config);
                dispatch(apiActions.fetchDataComplete());
                return response;
            }
            catch(e) {
                dispatch(apiActions.fetchDataComplete());
                console.log('error', e.response);
                return e.response;
            }
        }
    }
}